import React from 'react';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Button,
    TextField
} from '@material-ui/core';

export default (props) => (
    <Dialog
        open={props.openSell}
        onClose={props.closeSellPopup}
        fullWidth
        maxWidth={'lg'}
        >
        <DialogTitle>Express sell interest</DialogTitle>
        <DialogContent>
            <div className="row">
                <div className="col col-12">
                    <TextField
                        margin="dense"
                        id="company"
                        name="company"
                        value={props.company}
                        onChange={e => props.handleChange(e)}
                        label="Company Name"
                        fullWidth
                        />
                </div>
            </div>
            <div className="row">
                <div className="col col-12">
                    <TextField
                        margin="dense"
                        id="notional"
                        name="notional"
                        value={props.notional}
                        onChange={e => props.handleChange(e)}
                        label="Target Notional"
                        fullWidth
                        />
                </div>
            </div>
            <div className="row">
                <div className="col col-12">
                    <TextField
                        margin="dense"
                        id="price"
                        name="price"
                        value={props.price}
                        onChange={e => props.handleChange(e)}
                        label="Target Price"
                        fullWidth
                        />
                </div>
            </div>
            <div className="row">
                <div className="col col-12">
                    <TextField
                        margin="dense"
                        id="comment"
                        name="comment"
                        value={props.comment}
                        onChange={e => props.handleChange(e)}
                        label="Note to Gateway"
                        fullWidth
                        multiline={true}
                        rows={4}
                        rowsMax={4}
                        />
                </div>
            </div>
        </DialogContent>
        <DialogActions>
            <Button onClick={props.closeSellPopup} color="primary">Cancel</Button>
            <Button onClick={props.handleSubmitSellInterest}>Confirm</Button>
        </DialogActions>
    </Dialog>
)