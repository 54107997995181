import React, { Fragment } from 'react';

import {
    Typography,
    Grid,
    Card,
    makeStyles,
    Button,
    List,
    ListItem,
    useMediaQuery,
} from '@material-ui/core';
import {
    globalMD,
} from '../../../../modules/styles';
import {
    displayVal,
} from '../../../../utils/util';

const useStyles = makeStyles((theme) => ({
    SubTitle: {
        fontSize: 12,
        textAlign: 'center',
        display: 'block',
    },
    Sector: {
        display: 'block',
        background: 'rgba(38,138,199)',
        fontSize: 12,
        textAlign: 'center',
        color: '#FFFFFF',
        borderRadius: '4px',
        marginBottom: 16,
        '&:hover': {
            color: '#000000',
        },
        '&:last-child': {
            marginBottom: 0
        }
    },
    OfferTypo: {
        // color: 'rgba(221,235,247,1)',
        textAlign: 'center',
    },
    BidTypo: {
        // color: 'rgba(231,230,230,1)',
        textAlign: 'center',
    },
    Card: {
        padding: 16
    },
}));

export default (props) => {
    const { 
        demand, 
        offer, 
    } = props.dealMetricsResponse;
    const classes = useStyles();
    const isMobile = useMediaQuery(`(max-width:${globalMD}px)`);
    return (
        <Fragment>
            <Typography variant="h6" className="orderbook-paper-title">Marketplace Activity</Typography>
            <Grid container spacing={2}>
                <Grid item xs={isMobile ? 6 : 3}>
                    <Card className={classes.Card} >
                        <span className={classes.SubTitle}>Live Bid</span>
                        <Typography
                            className={classes.BidTypo}
                            variant="h6">
                            {demand ? demand.firm_count + demand.indication_count : '-'}
                        </Typography>
                    </Card>
                </Grid>
                <Grid item xs={isMobile ? 6 : 3}>
                    <Card className={classes.Card}>
                        <span className={classes.SubTitle}>Live Bid Notional</span>
                        <Typography
                            className={classes.BidTypo}
                            variant="h6">
                            {demand ? displayVal(demand.firm_volume + demand.indication_volume, 1, 1) : '-'}
                        </Typography>
                    </Card>
                </Grid>
                <Grid item xs={isMobile ? 6 : 3}>
                    {
                        <Card className={classes.Card}>
                            <span className={classes.SubTitle}>Live Offer</span>
                            <Typography
                                className={classes.OfferTypo}
                                variant="h6">
                                {offer ? offer.firm_count + offer.indication_count : '-'}
                            </Typography>
                        </Card>
                    }
                </Grid>
                <Grid item xs={isMobile ? 6 : 3}>
                    <Card className={classes.Card}>
                        <span className={classes.SubTitle}>Live Offer Notional</span>
                        <Typography
                            className={classes.OfferTypo}
                            variant="h6">
                            {offer ? displayVal(offer.firm_volume + offer.indication_volume, 1, 1) : '-'}
                        </Typography>
                    </Card>
                </Grid>
            </Grid>
        </Fragment>
    )
}
