import { get } from 'immutable';
import {
    get_average,
} from './util';

export function get_date_range(buffer){
    let now = new Date();
    let a_year_ago = new Date();
    a_year_ago.setDate(a_year_ago.getDate()-buffer);
    return { now, a_year_ago }
}

export function get_date_array(){
    let { now, a_year_ago } = get_date_range(365);
    const dates = [];
    for( let ii=a_year_ago; ii<=now; ii.setDate(ii.getDate() + 1) ){
        dates.push( get_date_string(ii) );
    }
    return dates;
}

export function get_date_string(ii){
    return ii.getFullYear() + '-' + ( ii.getMonth()+1 < 10 ? '0' + (ii.getMonth()+1) : ii.getMonth()+1 ) + '-' + ( ii.getDate() < 10 ? '0' + ii.getDate() : ii.getDate() );
}


export function rearrange_price_index(price_index){
	const new_payload = {}
    
    for( let key in price_index ){
        const company_item = price_index[key];
        new_payload[key] = [];

        let { now, a_year_ago } = get_date_range(365);
        let no_lag = true;
        for( let ii=a_year_ago; ii<=now; ii.setDate(ii.getDate() + 1) ){
            const forloop_date = get_date_string(ii);
            const matched_price_item = company_item.filter(item => item['date'] == forloop_date);
            // if( forloop_date == '2021-11-10' ){
            //     console.log( matched_price_item )
            // }
            
            
            let matched_item = {
                date: forloop_date,
                average_offer: undefined,
                average_bid: undefined,
                lag_bid: undefined,
                lag_offer: undefined,
                average_offer_growth: 0,
                average_bid_growth: 0,
                daily_volume: undefined,
            }

            if( matched_price_item.length ){
                for( let index=0; index<matched_price_item.length; index++ ){
                    const m_item = matched_price_item[index];
                
                    const order_key = m_item['targettable'].toLowerCase() == 'buy' ? 'bid' : 'offer';
                    matched_item[`average_${order_key}`] = m_item['average_price'];
                    matched_item[`lag_${order_key}`] = !no_lag ? m_item['lag_price'] : 0;
                    matched_item[`average_${order_key}_growth`] = !no_lag ? m_item['average_growth']:0;
                    matched_item[`daily_volume`] = m_item[`daily_volume`];
                }
            }

            new_payload[key].push(matched_item);
            no_lag = false;
        }
    }
    return new_payload;
}

function filter_for_price_index(price_index, company){
    const company_id = company.map(item => { return item.id });
    const pi = {}

    for( let key in price_index ){
        if( company_id.indexOf(key) > -1 ){
            pi[key] = price_index[key];
        }
    }
    return pi;
}


export function computeConsolidatedIndex(price_index, company){

    const company_with_target = company.filter(comp => comp.my_bid_price || comp.my_ask_price);
    const price_index_that_matters = filter_for_price_index(price_index, company_with_target);

    const chosen_price_index = price_index;

    const keys = Object.keys(chosen_price_index);
    const base_index_price = [];
    
    // Use first value as based value
    keys.forEach(key => {
        const price_index_by_company = chosen_price_index[key];
        const company_base_index = { offer: 0, bid: 0 }
        // price_index_by_company.forEach(index_item => {
        //     if( index_item.average_offer && !company_base_index.offer ) company_base_index.offer = index_item.average_offer;
        //     if( index_item.average_bid && !company_base_index.bid ) company_base_index.bid = index_item.average_bid;
        // });

        company_base_index.offer = price_index_by_company[0].average_offer;
        company_base_index.bid = price_index_by_company[0].average_bid;

        base_index_price.push( company_base_index );
    });

    const overall_indices = [];
    // const indices = [];
    const last_actual_data = base_index_price.map(() => { return {offer: 0, bid: 0} });

    if( keys.length ){
        for( let ii=0; ii<chosen_price_index[keys[0]].length; ii++ ){
            const offer_indices = [];
            const bid_indices = [];

            keys.forEach((key, index, arr) => {

                const price_index_item = chosen_price_index[key][ii];
                
                if( price_index_item['average_offer'] ){

                }

                if( price_index_item['average_offer'] || base_index_price[index]['offer'] ){



                    if( price_index_item['average_offer'] ){
                        base_index_price[index]['offer'] = price_index_item['average_offer'];
                    }
                }

                if( price_index_item['average_bid'] || base_index_price['bid'] ){

                    if( price_index_item['average_bid'] ){
                        base_index_price[index]['bid'] = price_index_item['average_bid'];
                    }
                }


                const ave_offer = chosen_price_index[key][ii]['average_offer'] ? chosen_price_index[key][ii]['average_offer'] :
                                    last_actual_data[index]['offer'] ? last_actual_data[index]['offer'] : 
                                    base_index_price[index]['offer'] ? base_index_price[index]['offer'] : 0;
                
                const ave_bid = chosen_price_index[key][ii]['average_bid'] ? chosen_price_index[key][ii]['average_bid'] :
                                    last_actual_data[index]['bid'] ? last_actual_data[index]['bid'] : 
                                    base_index_price[index]['bid'] ? base_index_price[index]['bid'] : 0;

                // offer_indices.push( ave_offer ? ave_offer / base_index_price[index]['offer'] * 100 : 100 );
                // bid_indices.push( ave_bid ? ave_bid / base_index_price[index]['bid'] * 100 : 100 );

                offer_indices.push( ave_offer ? ave_offer / base_index_price[index]['offer'] - 1 : 0 );
                bid_indices.push( ave_bid ? ave_bid / base_index_price[index]['bid'] - 1 : 0 );

                if( chosen_price_index[key][ii]['average_offer'] ) last_actual_data[index]['offer'] = chosen_price_index[key][ii]['average_offer'];
                if( chosen_price_index[key][ii]['average_bid'] ) last_actual_data[index]['bid'] = chosen_price_index[key][ii]['average_bid'];

            });

            const overall_index = { 
                date: chosen_price_index[keys[0]][ii]['date'],
                offer: get_average(offer_indices), 
                bid: get_average(bid_indices)
            }

            overall_indices.push( overall_index );
        }
    }
    
    return overall_indices;
}