import React from 'react';

import {
    makeStyles,
    Card,
    Grid,
    Typography,
    Button,
} from '@material-ui/core';

import {
    default as CardList,
} from './CardList';

export default (props) => {

    const { 
        permissioned_deals, 
        type,
        classes,
        doToggleWatchList
    } = props;

    return (
        <React.Fragment>
            <div className="market-intelligence">
                <h4 
                    style={{ marginBottom: 0, display: 'inline-block' }}
                    className="subheading-color company-item-title">
                    <span className="company-item-rectangle"></span>
                    Permissioned Deals
                </h4>
                <CardList
                    listArray={permissioned_deals}
                    {...{
                        type,
                        classes,
                        doToggleWatchList,
                    }}
                    />
            </div>
        </React.Fragment>
    )
}