import React, { Fragment } from 'react';
import ReactPlayer from 'react-player';
import {
    Dialog,
    // DialogActions,
    DialogContent,
    DialogTitle,
} from '@material-ui/core';

export default ( props ) => {
    return (
        <Dialog
            open={props.open}
            onClose={props.closePopup}
            fullWidth
            maxWidth="xl"
            className="video-dialog"
            >
                <DialogTitle>{props.link_name}</DialogTitle>
                <DialogContent className="video-dialog">
                    <ReactPlayer 
                        config={{ file: { attributes: { controlsList: 'nodownload' } } }}
                        onContextMenu={e => e.preventDefault()}
                        url={props.url} 
                        controls={true}
                        playing={true}
                        width={'100%'}
                        height={'700px'}
                        />
                </DialogContent>
        </Dialog>
    );
};