import React, { useState, useEffect } from 'react';

import {
    makeStyles,
} from '@material-ui/core';

import {
    Snackbar,
} from '../../../common';

const useStyles = makeStyles((theme) => ({

}));

export default (props) => {
    const classes = useStyles();
    const [open, setOpen] = useState(props.open ? props.open : false);
    const [error, setError] = useState(props.error ? props.error : false);
    const [message, setMessage] = useState(props.message ? props.message : '');
    const { uptime } = props;

    const handleSnackbarClose = (evt, reason) => {
        if( props.handleSnackbarClose ) props.handleSnackbarClose(evt, reason);
        setOpen(false);
        setError(false);
        setMessage('');
    }

    useEffect(() => {
        if(props.open != open) setOpen(props.open);
        if(props.error != error) setError(props.error);
        if(props.message != message) setMessage(props.message);
    }, [props.open, props.error, props.message]);

    return <Snackbar 
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
                openStyle={open}
                handleSnackbarClose={handleSnackbarClose}
                type={error ? "error": "success"}
                message={message}
                upTime={uptime ? uptime : 3000}
                />
}