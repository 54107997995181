import React from 'react';
// import { FilterSlider } from '../../../club_client/pipeline/components/Sliders';

import { 
    MenuItem,
    FormControl,
    Checkbox,
    ListItemText,
    Typography,
    Grid,
    Button,
    makeStyles,
    OutlinedInput,
} from '@material-ui/core';

import {
    StyledInputLabel,
    StyledSelect,
} from '../../common/Label';

import {
    SelectPlaceholder
} from '../../common';

import {
    ReScaleForDeviceScale
} from '../../../../utils/util';

import {
    global150BodyFont,
    globalBodyFont,
    globalFontFamily,
} from '../../../styles';

const FilterStyles = makeStyles((theme) => ({
    FormControl: {
        // position: 'relative'
    },
    ListItemText: {
        '& span': {
            fontSize: ReScaleForDeviceScale(1.5, global150BodyFont, globalBodyFont),
            fontFamily: globalFontFamily,
        }
    },
    Button: {
        fontSize: 8,
        fontFamily: globalFontFamily,
        position: 'absolute',
        top: -20,
        right: -5,
        zIndex: 100,
    },
    OutlinedInput: {
        '& .MuiOutlinedInput-inputMarginDense': {
            paddingTop: '8.5px !important',
            paddingBottom: '8.5px !important',
        }
    }
}));

export default (props) => {
    const classes = FilterStyles();
    const col_width = props.verticalPositioning ? 12 : 3;
    return (
        <Grid container spacing={2}>
            <Grid item xs={col_width}>
                <FormControl variant="outlined" className={classes.FormControl} size="small">
                    {props.selected_geography.length ? <Button onClick={() => props.handleResetFilter('selected_geography')} className={classes.Button}>Clear filter</Button> : ''}
                    {/* <StyledInputLabel shrink id='geography-label'>Geography</StyledInputLabel> */}
                    <StyledSelect
                        labelId="geography-label"
                        id="geography-select-outlined"
                        name="selected_geography"
                        multiple
                        value={props.selected_geography}
                        onChange={props.handleSelectChange}
                        displayEmpty
                        renderValue={(selected) => selected.length ? selected.join(', ') : <SelectPlaceholder>Geography</SelectPlaceholder>}
                        input={<OutlinedInput className={classes.OutlinedInput} notched placeholder="Geography" />} //label={"Geography"}
                        >
                            <MenuItem value="none" disabled>
                                <ListItemText className={classes.ListItemText} primary="Geography" />
                            </MenuItem>
                            {
                                props.geography.map((item, index) => {
                                    return (
                                        <MenuItem value={item} key={index}>
                                            <Checkbox checked={props.selected_geography.indexOf(item) > -1} />
                                            <ListItemText className={classes.ListItemText} primary={item} />
                                        </MenuItem>
                                    )
                                })
                            }
                    </StyledSelect>
                </FormControl>
            </Grid>
            <Grid item xs={col_width}>
                <FormControl variant="outlined" className={classes.FormControl} size="small">
                    {props.selected_vertical.length ? <Button onClick={() => props.handleResetFilter('selected_vertical')} className={classes.Button}>Clear filter</Button> : ''}
                    {/* <StyledInputLabel shrink id='verticals-label'>Vertical</StyledInputLabel> */}
                    <StyledSelect
                        labelId="verticals-label"
                        id="verticals-select"
                        name="selected_vertical"
                        multiple
                        value={props.selected_vertical}
                        onChange={props.handleSelectChange}
                        displayEmpty
                        renderValue={(selected) => selected.length ? selected.join(', ') : <SelectPlaceholder>Vertical</SelectPlaceholder>}
                        input={<OutlinedInput className={classes.OutlinedInput} notched placeholder="Vertical" />} // label={"Vertical"}
                        >
                            <MenuItem value="none" disabled>
                                <ListItemText className={classes.ListItemText} primary="Vertical" />
                            </MenuItem>
                            {
                                props.verticals.map((item, index) => {
                                    return (
                                        <MenuItem 
                                            value={item} 
                                            key={index}>
                                            <Checkbox checked={props.selected_vertical.indexOf(item) > -1} />
                                            <ListItemText className={classes.ListItemText} primary={item} />
                                        </MenuItem>
                                    )
                                })
                            }
                    </StyledSelect>
                </FormControl>
            </Grid>
            <Grid item xs={col_width}>
                <FormControl variant="outlined" className={classes.FormControl} size="small">
                    {props.selected_valuations.length ? <Button onClick={() => props.handleResetFilter('selected_valuations')} className={classes.Button}>Clear filter</Button> : ''}
                    {/* <StyledInputLabel shrink id='valuation-label'>Valuation</StyledInputLabel> */}
                    <StyledSelect
                        labelId="valuation-label"
                        id="valuation-select"
                        name="selected_valuations"
                        multiple
                        value={props.selected_valuations}
                        onChange={props.handleSelectChange}
                        displayEmpty
                        renderValue={(selected) => selected.length ? selected.join(', ') : <SelectPlaceholder>Valuation</SelectPlaceholder>}
                        input={<OutlinedInput className={classes.OutlinedInput} notched placeholder="Valuation" />} //label={"Valuation"}
                        >
                            <MenuItem value="none" disabled>
                                <ListItemText className={classes.ListItemText} primary="Valuation" />
                            </MenuItem>
                            {
                                props.valuations.map((item, index) => {
                                    return (
                                        <MenuItem 
                                            value={item} 
                                            key={index}>
                                            <Checkbox checked={props.selected_valuations.indexOf(item) > -1} />
                                            <ListItemText className={classes.ListItemText} primary={item} />
                                        </MenuItem>
                                    )
                                })
                            }
                    </StyledSelect>
                </FormControl>
            </Grid>
            <Grid item xs={col_width}>
                <FormControl variant="outlined" className={classes.FormControl} size="small">
                    {props.selected_tags.length ? <Button onClick={() => props.handleResetFilter('selected_tags')} className={classes.Button}>Clear filter</Button> : ''}
                    {/* <StyledInputLabel shrink id='tags-label'>Transaction Terms</StyledInputLabel> */}
                    <StyledSelect
                        labelId="tags-label"
                        id="tags-select"
                        name="selected_tags"
                        multiple
                        value={props.selected_tags}
                        onChange={props.handleSelectChange}
                        displayEmpty
                        renderValue={(selected) => selected.length ? selected.join(', ') : <SelectPlaceholder>Transaction Terms</SelectPlaceholder>}
                        input={<OutlinedInput className={classes.OutlinedInput} notched placeholder="transaction terms" />} //label={"Transaction Terms"}
                        >
                            <MenuItem value="none" disabled>
                                <ListItemText className={classes.ListItemText} primary="Transaction Terms" />
                            </MenuItem>
                            {
                                props.tags.map((item, index) => {
                                    return (
                                        <MenuItem 
                                            value={item} 
                                            key={index}>
                                            <Checkbox checked={props.selected_tags.indexOf(item) > -1} />
                                            <ListItemText className={classes.ListItemText} primary={item} />
                                        </MenuItem>
                                    )
                                })
                            }
                    </StyledSelect>
                </FormControl>
            </Grid>
        </Grid>
    )
}