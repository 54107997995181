import React from 'react';

import {
    Tooltip,
    List,
    ListItem,
    ListItemText,
    makeStyles,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    withStyles,
} from '@material-ui/core';

import {
    HeaderTableCell,
    StyledTableRow,
    TighterTableCell1,
    discPremClasses,
} from '../../common/Table';

import {
    global150BodyFont,
    globalBodyFont,
} from '../../../styles';

import {
    getImpliedVal,
    ReScaleForDeviceScale,
    roundNumber,
    displayVal,
    convertSizeLabel,
    numberWithCommas,
} from '../../../../utils/util';

import { sendGAEvent } from '../../../../utils/ga';


const useStyles = makeStyles((theme) => ({
    Popper: {
        '& .MuiTooltip-popper': {
            background: theme.palette.common.white,
            boxShadow: '0 0 3px #333',
            color: theme.palette.common.black,
            zIndex: 1000,
        }
    },
    ListItemText: {
        fontSize: ReScaleForDeviceScale(1.5, global150BodyFont, globalBodyFont),
        color: theme.palette.common.black,
    },
    ListItem: {
        padding: 0,
        margin: 0,
    },
    List: {
        padding: 0,
    },
    Table: {
        width: '100% !important'
    },
    container: {
        height: 150,
        width: '350px !important'
    },
    Tooltip: {
        width: 300
    },
    Cell: {
        textAlign: 'center !important'
    }
}));

const styles = {
    tooltip: {
        // width: '500px !important',
        maxWidth: 'none',
        padding: '10px !important',
        backgroundColor: 'white',
        boxShadow: '0 0 5px #CCC'
    }
}

const StyledTooltip = withStyles(styles)(Tooltip);

export default (props) => {
    // console.log( props );
    const { data } = props;
    const classes = useStyles();
    const dpc = discPremClasses();

    const lfr_text = data.selected_price && data.selected_post_money_valuation ? 
                        `Last funding round: $${numberWithCommas(data.selected_price, 2)}p.s. at ${displayVal(data.selected_post_money_valuation)} valuation` : 
                            data.selected_price ? `Last funding round: $${numberWithCommas(data.selected_price, 2)}p.s.` : 
                                data.selected_post_money_valuation ? `Last funding round: ${displayVal(data.selected_post_money_valuation)} valuation` : '';
    
    const handleOnOpen = (evt) => {
        sendGAEvent({
            type: 'orderbook_main_view_hover_row',
            data: data
        })
    }
    
    return (
        <StyledTooltip
            placement={props.placement}
            onOpen={handleOnOpen}
            // className={classes.Tooltip}
            title={
                <React.Fragment>
                    <TableContainer component={Paper} className={classes.container}>
                        <Table stickyHeader size="small" aria-label="sticky table" className={classes.Table}>
                            <TableHead>
                                <TableRow>
                                    <HeaderTableCell></HeaderTableCell>
                                    <HeaderTableCell className={classes.Cell}>Target*</HeaderTableCell>
                                    <HeaderTableCell className={classes.Cell} style={{width: '100px !important'}}>Disc / Prem*</HeaderTableCell>
                                    <HeaderTableCell className={classes.Cell}>Size*</HeaderTableCell>
                                    <HeaderTableCell className={classes.Cell}>No. of orders</HeaderTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <StyledTableRow>
                                    <TighterTableCell1>Bid</TighterTableCell1>
                                    <TighterTableCell1 className={classes.Cell}>
                                        {
                                            data.highest_bid && data.bid_implied_val ?
                                                `$${roundNumber(data.highest_bid)} p.s. (${getImpliedVal(data.highest_bid, data.selected_price, data.selected_post_money_valuation)})` :
                                                data.bid_implied_val ?
                                                    displayVal(data.bid_implied_val) : '-'
                                        }
                                    </TighterTableCell1>
                                    <TighterTableCell1 className={`${classes.Cell} ${dpc.discPremClassName(data.bid_disc_prem)}`}>{data.bid_disc_prem ? `${roundNumber(data.bid_disc_prem)}%` : '-'}</TighterTableCell1>
                                    <TighterTableCell1 className={classes.Cell}>
                                        {data.bid_size ? 
                                            <span className={data.bid_size.indexOf('<') == -1 ? 'left-gap' : ''}>
                                                {convertSizeLabel(data.bid_size)}
                                            </span>
                                         : '-'}</TighterTableCell1>
                                    <TighterTableCell1 className={classes.Cell}>{data.bid_count ? data.bid_count : 0}</TighterTableCell1>
                                </StyledTableRow>
                                <StyledTableRow>
                                    <TighterTableCell1>Offer</TighterTableCell1>
                                    <TighterTableCell1 className={classes.Cell}>
                                        {
                                            data.lowest_ask && data.ask_implied_val ?
                                                `$${roundNumber(data.lowest_ask)} p.s. (${getImpliedVal(data.lowest_ask, data.selected_price, data.selected_post_money_valuation)})` :
                                                data.ask_implied_val ?
                                                    displayVal(data.ask_implied_val) : '-'
                                        }
                                    </TighterTableCell1>
                                    <TighterTableCell1 className={`${classes.Cell} ${dpc.discPremClassName(data.ask_disc_prem)}`}>{data.ask_disc_prem ? `${roundNumber(data.ask_disc_prem)}%` : '-'}</TighterTableCell1>
                                    <TighterTableCell1 className={classes.Cell}>{data.ask_size ? 
                                    <span className={data.ask_size.indexOf('<') == -1 ? 'left-gap' : ''}>
                                        {convertSizeLabel(data.ask_size)}
                                    </span>  : '-'}</TighterTableCell1>
                                    <TighterTableCell1 className={classes.Cell}>{data.ask_count ? data.ask_count : 0}</TighterTableCell1>
                                </StyledTableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                    
                    <List className={classes.List}>
                        { lfr_text.trim() ?  <ListItemText classes={{ primary: classes.ListItemText }} primary={lfr_text} /> : '' }
                        <ListItemText classes={{ primary: classes.ListItemText }} primary={'*Data based on highest bid / lowest offer'} />
                    </List>
                    
                    
                </React.Fragment>
            }
            >
                {props.children}
        </StyledTooltip>
    )
}