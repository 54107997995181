import React from 'react';

import {
    Dialog,
    DialogContent,
    Slide,
    AppBar,
    Toolbar,
    IconButton,
    Table,
    TableCell,
    TableBody,
    TableContainer,
    TableHead,
    Typography,
    Paper,
    withStyles,
} from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';
import vci_logo from '../../../../assets/images/vci_logo.png';
import {
    StyledTableRow,
    TighterHeaderTableCell,
    TighterTableCell,
} from '../../../orderbook/common/Table';
import {
    numberWithCommas,
    displayVal,
} from '../../../../utils/util';
const DialogStyle = theme => ({
    AppBar: {
        background: theme.palette.common.white,
        boxShadow: 'none',
        position: 'relative',
    },
    IconButton: {
        position: 'absolute',
        right: 0,
    }
})

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default withStyles(DialogStyle)((props) => {
    const { resultList } = props;
    return (
        <Dialog
            open={props.open}
            fullScreen
            TransitionComponent={Transition}
        >
            <AppBar className={props.classes.AppBar}>
                <Toolbar>
                    {/* <a href="https://www.venturecapinsights.com"  target="_blank" style={{position: 'absolute', right: 60}}>
                        <img style={{background: '#1287ff', width: 100, height: 42}}
                            src={vci_logo} alt="Venture Capital Insight"/>
                    </a> */}
                    <IconButton
                        onClick={() => props.closeCapTableDialog()}
                        className={props.classes.IconButton}>
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <DialogContent>
                <div style={{ paddingBottom: 40 }}>
                    <Typography variant='h6' align='center'>Total Funding, Cap Table And Value Of Shareholding</Typography>
                    <TableContainer component={Paper} style={{ position: 'relative', marginTop: 15, overflowX: "initial" }}>
                        <Table stickyHeader size="small" aria-label="simple table">
                            <TableHead>
                                <StyledTableRow>
                                    <TighterHeaderTableCell className="investor/shareholder">INVESTOR/SHAREHOLDER</TighterHeaderTableCell>
                                    <TighterHeaderTableCell className="amount">AMOUNT INVESTED</TighterHeaderTableCell>
                                    <TighterHeaderTableCell className="allocated">SHARE ALLOCATED</TighterHeaderTableCell>
                                    <TighterHeaderTableCell className="sold">ANY SHARES SOLD</TighterHeaderTableCell>
                                    <TighterHeaderTableCell className="purchased">ANY SECONDARIES PURCHASED</TighterHeaderTableCell>
                                    <TighterHeaderTableCell className="current">CURRENT SHAREHOLDING</TighterHeaderTableCell>
                                    <TighterHeaderTableCell className="valuation">VALUE OF SHAREHOLDING AT LAST ROUND VALUATION</TighterHeaderTableCell>
                                </StyledTableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    resultList.map((row, index) => {
                                        return (
                                            <React.Fragment key={index}>
                                                <StyledTableRow>
                                                    <TighterTableCell className="investor/shareholder">
                                                        {row.investor ? row.investor : '-'}
                                                    </TighterTableCell>
                                                    <TighterTableCell className="amount">
                                                        {row.amount_invested ? displayVal(row.amount_invested, 2, 2) : '-'}
                                                    </TighterTableCell>
                                                    <TighterTableCell className="allocated">
                                                        {row.shares_allocated ? numberWithCommas(row.shares_allocated, 0) : '-'}
                                                    </TighterTableCell>
                                                    <TighterTableCell className="sold">
                                                        {row.any_shares_sold ? numberWithCommas(row.any_shares_sold,0) : '-'}
                                                    </TighterTableCell>
                                                    <TighterTableCell className="purchased">
                                                        {row.any_secondary_purchased ? numberWithCommas(row.any_secondary_purchased, 0) : '-'}
                                                    </TighterTableCell>
                                                    <TighterTableCell className="current">
                                                        {row.current_share_holding_percent ? `${row.current_share_holding_percent}%` : '-'}
                                                    </TighterTableCell>
                                                    <TighterTableCell className="valuation">
                                                        {row.holding_last_round_valation ? displayVal(row.holding_last_round_valation, 2, 2) : '-'}
                                                    </TighterTableCell>
                                                </StyledTableRow>
                                            </React.Fragment>
                                        )
                                    })
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <div style={{ position: 'absolute', bottom: 10, right: 20 }}>
                        <div className="powered-by-vci">
                            <p>Powered by <a href="https://www.venturecapinsights.com"  target="_blank" >
                                    <img style={{background: '#1287ff', width: 60 }}
                                        src={vci_logo} alt="Venture Capital Insight"/>
                                </a>
                            </p>
                        </div>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    )
})