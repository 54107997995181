import React, { useState } from 'react';

import {
    AppBar,
    Tabs,
    Tab,
    Typography,
    Box,
    Paper,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableBody,
} from '@material-ui/core';

import {
    makeStyles,
} from '@material-ui/core/styles';

import {
    StyledTableRow,
    StyledHoverTableRow,
    TighterHeaderTableCell,
    TighterClickableTableCell,
} from '../../common/Table';

import {
    rearrange_cost_base,
    roundNumber,
} from '../../../../utils/util';

function TabPanel( props ){
    const { children, value, index } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}>
                {value === index && (
                    <Box p={3}>
                        {children}
                    </Box>
                )}
            </div>
    )
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        // backgroundColor: theme.palette.primary.dark,
    },
    appBar: {
        color: theme.palette.primary.dark,
    }
}));

export default (props) => {
    // console.log( props );
    const classes = useStyles();
    const [tabValue, setTabValue] = useState(0);

    const handleChange = (evt, newValue) => {
        setTabValue(newValue);
    }

    const new_cost_base = rearrange_cost_base(props.cost_base, props.company_details, props.price_index);
    
    return (
        <div className={classes.root}>
            <AppBar className={classes.appBar} position="static">
                <Tabs value={tabValue} onChange={handleChange} aria-label="simple tabs example">
                    <Tab label="Portfolio Summary" />
                </Tabs>
            </AppBar>
            <TabPanel value={tabValue} index={0}>
                <TableContainer component={Paper}>
                    <Table stickyHeader size="small" aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TighterHeaderTableCell>Company</TighterHeaderTableCell>
                                <TighterHeaderTableCell>Entry Price (PPS)</TighterHeaderTableCell>
                                <TighterHeaderTableCell>No. of Shares</TighterHeaderTableCell>
                                <TighterHeaderTableCell>Share Class</TighterHeaderTableCell>
                                <TighterHeaderTableCell>Entry Date</TighterHeaderTableCell>
                                <TighterHeaderTableCell>Potential Gain / Loss</TighterHeaderTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                new_cost_base.map((row, index) => {
                                    return (
                                        <React.Fragment key={index}>
                                            <StyledHoverTableRow>
                                                <TighterClickableTableCell
                                                    onClick={(evt) => props.handleUpdateMarkToMarket(evt, row.id)}
                                                    >{row.company ? row.company.name : ''}</TighterClickableTableCell>
                                                <TighterClickableTableCell
                                                    onClick={(evt) => props.handleUpdateMarkToMarket(evt, row.id)}
                                                    >{row.price}</TighterClickableTableCell>
                                                <TighterClickableTableCell
                                                    onClick={(evt) => props.handleUpdateMarkToMarket(evt, row.id)}
                                                    >{row.noOfShares}</TighterClickableTableCell>
                                                <TighterClickableTableCell
                                                    onClick={(evt) => props.handleUpdateMarkToMarket(evt, row.id)}
                                                    >{row.share_class ? row.share_class : '-'}</TighterClickableTableCell>
                                                <TighterClickableTableCell
                                                    onClick={(evt) => props.handleUpdateMarkToMarket(evt, row.id)}
                                                    >{row.date}</TighterClickableTableCell>
                                                <TighterClickableTableCell
                                                    onClick={(evt) => props.handleUpdateMarkToMarket(evt, row.id)}>
                                                    {row.price && row.latest_index && row.latest_index.average_bid ? 
                                                        roundNumber((row.latest_index.average_bid - row.price) / row.price * 100) + '%' : '-'}
                                                    </TighterClickableTableCell>    
                                            </StyledHoverTableRow>
                                        </React.Fragment>
                                    )
                                })
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </TabPanel>
        </div>
    )
}