import { 
    CreateKeyAction as _createKeyAction,
    CreateApiAction as _createApiAction
} from '../../utils';

const key = 'ROOT'
const CreateKeyAction = _createKeyAction(key);

export const ShowLoader = CreateKeyAction('SHOW_LOADER');
export const HideLoader = CreateKeyAction('HIDE_LOADER');
export const CollapseSidebar = CreateKeyAction('COLLAPSE_SIDEBAR');

const CreateApiAction = _createApiAction(key);

export const checkMaintenance = CreateApiAction('CHECK_MAINTENANCE');