import {
    global150BodyFont,
    globalBodyFont,
    globalFontFamily,
    global150LabelFont,
    globalLabelFont,
} from '../../styles';

import {
    ReScaleForDeviceScale,
} from '../../../utils/util';

export const FormStyle = theme => ({
    Box: {
        // boxShadow: '0px 0px 5px #666',
        // borderRadius: '8px',
        backgroundColor: '#FFF',
        transition: 'all .3s',
    },
    BoxDarker: {
        backgroundColor: theme.palette.action.hover,
        transition: 'all .3s',
    },
    FormControlLabel: {
        fontSize: ReScaleForDeviceScale(1.5, global150BodyFont, globalBodyFont),
        fontFamily: globalFontFamily,
        marginBottom: 0,
        '& $label': {
            fontSize: ReScaleForDeviceScale(1.5, global150BodyFont, globalBodyFont),
        }
    },
    TextField: {
        fontSize: ReScaleForDeviceScale(1.5, global150BodyFont, globalBodyFont),
        fontFamily: globalFontFamily,
        '& $input': {
            fontSize: ReScaleForDeviceScale(1.5, global150BodyFont, globalBodyFont),
            fontFamily: globalFontFamily,
            '&::placeholder': {
                fontSize: ReScaleForDeviceScale(1.5, global150BodyFont+'px !important', globalBodyFont+'px !important'),
                fontFamily: globalFontFamily,
            }
        },
        '&::placeholder': {
            fontSize: ReScaleForDeviceScale(1.5, global150BodyFont, globalBodyFont),
            fontFamily: globalFontFamily,
        },
        '& textarea': {
            fontSize: ReScaleForDeviceScale(1.5, global150BodyFont, globalBodyFont),
            fontFamily: globalFontFamily, 
            '&::placeholder': {
                fontSize: ReScaleForDeviceScale(1.5, global150BodyFont+'px !important', globalBodyFont+'px !important'),
                fontFamily: globalFontFamily,
            }
        },
        '& $label': {
            fontSize: ReScaleForDeviceScale(1.5, global150LabelFont, globalLabelFont),
            fontFamily: globalFontFamily,
        },
    },
    ToggleButtonGroup: {
        width: '100%'
    },
    ToggleButton: {
        fontSize: ReScaleForDeviceScale(1.5, global150BodyFont, globalBodyFont),
        fontFamily: globalFontFamily,
        width: '100%',
    },
    TighterToggleButton: {
        fontSize: ReScaleForDeviceScale(1.5, global150BodyFont, globalBodyFont),
        fontFamily: globalFontFamily,
        padding: '0.2rem 0.35rem !important',
        width: '100%',
    },
    FormLabel: {
        fontSize: ReScaleForDeviceScale(1.5, global150BodyFont, globalBodyFont),
        fontFamily: globalFontFamily,
        margin: '0 0 5px 0 !important',
    }
});
