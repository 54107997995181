import React from 'react';
import {
    default as Base
} from './BaseTooltip';

export default (props) => {
    return <Base 
            items={
                [
                    'ROFR waived:  Share transfer is not subject to Right of First Refusal by the Company or its Shareholder.'
                ]
            }
            /> 
}