import { connect } from "react-redux";

import { 
	createWatchlist,
	createWatchlistGroup,
	getWatchlistByCompanyId,
	createWatchlistV2,
	resetCompanyWatchlistData,
} from '../Action';
import { default as WatchListDialog } from "./WatchListDialog";

export default connect(
	(state) => ({
		createWatchListGroupError: state.watchlist.reducer.createWatchListGroupError,
		createWatchListGroupMessage: state.watchlist.reducer.createWatchListGroupMessage,
		createWatchListError: state.watchlist.reducer.createWatchListError,
		createWatchListMessage: state.watchlist.reducer.createWatchListMessage,
		createWatchListResponse: state.watchlist.reducer.createWatchListResponse,
		companyWatchlist: state.watchlist.reducer.companyWatchlist,
		watchlistGroupList: state.watchlist.reducer.watchlistGroupList,
		appConfig: state.root.reducer.appConfig,
		loginUserData: state.login.reducer.loginUserData,
	}),
	(dispatch) => ({
		createWatchlist: (payload) => dispatch(createWatchlist.request(payload)),
		createWatchlistGroup: (payload) => dispatch(createWatchlistGroup.request(payload)),
		getWatchlistByCompanyId: (payload) => dispatch(getWatchlistByCompanyId.request(payload)),
		createWatchlistV2: (payload) => dispatch(createWatchlistV2.request(payload)),
		resetCompanyWatchlistData: () => dispatch(resetCompanyWatchlistData()),
	})
)(WatchListDialog);
