export default (data) => {
	if (data.is_superuser)
		return 'admin'
	else if (data.is_staff)
		return 'staff'
	else if (data.accessRight && data.accessRight.access_right && data.accessRight.access_right.permission == "CLUB_CLIENT")
		return 'club_investor'
	else if (data.is_investor && !data.is_superuser && !data.is_staff)
		return 'investor'
	else if (!data.is_investor && !data.is_superuser && !data.is_staff)
		return 'seller'
}