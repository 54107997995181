import {
    put,
    call,
    takeLatest,
    all,
    fork,
} from 'redux-saga/effects';

import { Unauthorized } from '../../login/Action';

import {
    getPriceIndex,
    getCompanyDetail,
    getFundingRound,
    getMyPortfolio,
} from './Action';

import {
    portfolio_holding,
} from '../../../api';

import {
    NewRelicError
} from '../../../utils';
  
import {
    logEventInternallyAndNewRelic
} from '../../../utils/ga';

import {
    saga as portfolioControlSaga,
} from './components/portfolio_control';

function* getPriceIndexResponder({ payload }){
    try{
        let query_string = "?";
        let counter = 0;
        if( payload.qs ){
            for( var key in payload.qs ){
                if( counter ) query_string += "&";
                query_string += key+"="+payload['qs'][key];
                counter++;
            }
        }
        const { data } = yield call(portfolio_holding.getPriceIndex, query_string, payload);
        yield put(getPriceIndex.success(data));
    } catch(e){
        if( e.message && e.response ){
            const err_payload = {
                type: 'api_failure_portfolioHoldinggetPriceIndexResponder',
                data: {
                    config: e.response.config,
                    data: e.response.data,
                    status: e.response.status
                },
                err: new Error("API Failure portfolioHoldinggetPriceIndexResponder: " + e.message)
            }
            logEventInternallyAndNewRelic(err_payload);
        } else if (e.message) {
            NewRelicError( new Error("API Failure portfolioHoldinggetPriceIndexResponder: " + e.message) );
        } else {
            NewRelicError( new Error("API Failure portfolioHoldinggetPriceIndexResponder with no message") );
        }
        if (e.response.status == 401) {
          yield put(Unauthorized(e));
        }
        yield put(getPriceIndex.failure(e));
    } finally{

    }
}

function* getCompanyDetailResponder({ payload }){
    try {
        let query_string = "?";
        let counter = 0;
        if( payload.qs ){
            for( var key in payload.qs ){
                if( counter ) query_string += "&";
                query_string += key+"="+payload['qs'][key];
                counter++;
            }
        }
        const { data } = yield call(portfolio_holding.getCompanyDetail, query_string, payload);
        yield put(getCompanyDetail.success(data));
    } catch(e){
        if( e.message && e.response ){
            const err_payload = {
                type: 'api_failure_portfolioHoldinggetCompanyDetailResponder',
                data: {
                    config: e.response.config,
                    data: e.response.data,
                    status: e.response.status
                },
                err: new Error("API Failure portfolioHoldinggetCompanyDetailResponder: " + e.message)
            }
            logEventInternallyAndNewRelic(err_payload);
        } else if (e.message) {
            NewRelicError( new Error("API Failure portfolioHoldinggetCompanyDetailResponder: " + e.message) );
        } else {
            NewRelicError( new Error("API Failure portfolioHoldinggetCompanyDetailResponder with no message") );
        }
        if (e.response.status == 401) {
          yield put(Unauthorized(e));
        }
        yield put(getCompanyDetail.failure(e));
    } finally{

    }
}

function* getFundingRoundResponder({ payload }){
    try {
        let query_string = "?";
        let counter = 0;
        if( payload.qs ){
            for( var key in payload.qs ){
                if( counter ) query_string += "&";
                query_string += key+"="+payload['qs'][key];
                counter++;
            }
        }
        const { data } = yield call(portfolio_holding.getFundingRound, query_string, payload);
        yield put(getFundingRound.success(data));
    } catch(e){
        if( e.message && e.response ){
            const err_payload = {
                type: 'api_failure_portfolioHoldinggetFundingRoundResponder',
                data: {
                    config: e.response.config,
                    data: e.response.data,
                    status: e.response.status
                },
                err: new Error("API Failure portfolioHoldinggetFundingRoundResponder: " + e.message)
            }
            logEventInternallyAndNewRelic(err_payload);
        } else if (e.message) {
            NewRelicError( new Error("API Failure portfolioHoldinggetFundingRoundResponder: " + e.message) );
        } else {
            NewRelicError( new Error("API Failure portfolioHoldinggetFundingRoundResponder with no message") );
        }
        if (e.response.status == 401) {
          yield put(Unauthorized(e));
        }
        yield put(getFundingRound.failure(e));
    } finally{

    }
}

function* getMyPortfolioResponder({ payload }){
    try{
        yield put(getMyPortfolio.success({}));
        yield put(getPriceIndex.request(payload));
        yield put(getCompanyDetail.request(payload));
        yield put(getFundingRound.request(payload));
    } catch(e){
        if( e.message && e.response ){
            const err_payload = {
                type: 'api_failure_portfolioHoldinggetMyPortfolioResponder',
                data: {
                    config: e.response.config,
                    data: e.response.data,
                    status: e.response.status
                },
                err: new Error("API Failure portfolioHoldinggetMyPortfolioResponder: " + e.message)
            }
            logEventInternallyAndNewRelic(err_payload);
        } else if (e.message) {
            NewRelicError( new Error("API Failure portfolioHoldinggetMyPortfolioResponder: " + e.message) );
        } else {
            NewRelicError( new Error("API Failure portfolioHoldinggetMyPortfolioResponder with no message") );
        }
        if (e.response.status == 401) {
          yield put(Unauthorized(e));
        }
        yield put(getMyPortfolio.failure(e));
    } finally{

    }
}


function* saga(){
    yield all([
        fork(portfolioControlSaga),
        takeLatest(getPriceIndex.request, getPriceIndexResponder),
        takeLatest(getCompanyDetail.request, getCompanyDetailResponder),
        takeLatest(getFundingRound.request, getFundingRoundResponder),
        takeLatest(getMyPortfolio.request, getMyPortfolioResponder),
    ])
}

export default saga;