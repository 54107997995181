import { connect } from 'react-redux';
import { default as CompanySearch } from './CompanySearch';

import {
    getSearchCompany,
    clearNoMatch,
} from './Action';

export default connect(
    state => ({
        searchCompanyResponse: state.orderbookCompanySearch.reducer.searchCompanyResponse,
        search_company_loading: state.orderbookCompanySearch.reducer.search_company_loading,
        loginUserData: state.login.reducer.loginUserData,
        no_match: state.orderbookCompanySearch.reducer.no_match,
    }),
    dispatch => ({
        getSearchCompany: (payload) => dispatch(getSearchCompany.request(payload)),
        clearNoMatch: () => dispatch(clearNoMatch()),
    })
)(CompanySearch);