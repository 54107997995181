import {
    put,
    takeLatest,
    all,
    fork,
    call
} from 'redux-saga/effects';

import {
    checkMaintenance,
    ShowLoader,
    HideLoader
} from './Action';

import {
    health
} from '../../api';

function* checkMaintenanceResponder({ payload }){
    yield put(ShowLoader(true));
    try{
        // let query_string = "?";
        // let counter = 0;
        // if( payload.qs ){
        //     for( var key in payload.qs ){
        //         if( counter ) query_string += "&";
        //         query_string += key+"="+payload['qs'][key];
        //         counter++;
        //     }
        // }
        const { data } = yield call(health.checkMaintenance);
        yield put(checkMaintenance.success(data));
    } catch(e){
        yield put(checkMaintenance.failure(e));
    } finally{
        yield put(HideLoader(true));
    }
}

function* saga(){
    yield all([
        takeLatest(checkMaintenance.request, checkMaintenanceResponder)
    ])
}

export default saga;