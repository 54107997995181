import React, { Fragment, useState } from 'react';

import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    makeStyles,
    Typography,
    Card,
    Grid,
    IconButton,
    Tooltip,
} from '@material-ui/core';
import CropFreeIcon from '@material-ui/icons/CropFree';
import vci_logo from '../../../../assets/images/vci_logo.png';

import { HeaderTableCell, StyledTableRow, recentCellClasses } from '../../common/Table';

import {
    numberWithCommas,
    getCompanyValuation,
    displayVal,
    showBlurred,
} from '../../../../utils/util';
import {
    feature_flags,
} from '../../../../config';
import {
    default as PrimaryPermissionedDeals
} from './PrimaryPermissionedDeals';
import { default as VciFundingRoundDialog } from './VciFundingRoundDialog';
import { sendGAEvent } from '../../../../utils/ga';

const useStyles = makeStyles((theme) => ({
    container: {
        // minHeight: 50,
        // maxHeight: 50,
    },
    SubTitle: {
        fontSize: 12,
        // textAlign: 'center',
        display: 'block',
    },
    Card: {
        padding: 16,
        marginTop: 16,
        marginBottom: 16,
    }
}));

export default (props) => {
    const classes = useStyles();
    const rcc = recentCellClasses();

    let valid_funding_rounds = props.results.filter(item => item.price || item.post_money_valuation);
    let company_valuation = getCompanyValuation(props);
    const { primary_activity_deals, company_detail, vci_funding_rounds, appConfig, loginUserData: { email }, } = props;
    const vciEnable = feature_flags.feature_is_available(
        appConfig,
        feature_flags.flags.VciIntegration.flag_name,
        feature_flags.flags.VciIntegration.attributes.vci_integration,
        email
    ) && company_detail.vci
    if(vciEnable){
        valid_funding_rounds = vci_funding_rounds.results.filter(item => item.price || item.post_money_valuation);
        company_valuation = getCompanyValuation(vci_funding_rounds);
    }
    const [showVciFundingRound, setShowVciFundingRound] = useState(false);
    const closeVciFundingRoundDialog = () => {
        setShowVciFundingRound(false)
    }
    return (
        <Fragment>
            <Grid container spacing={2}>
                <Grid item xs={vciEnable ? 6 : 12}>
                    <Typography variant="h6">Primary Activity</Typography>
                </Grid>
                {
                    vciEnable &&
                    <Grid item xs={6} style={{zIndex: 1001, textAlign: 'right'}}>
                        {/* <a href="https://www.venturecapinsights.com"  target="_blank" >
                            <img style={{background: '#1287ff', width: 100 }}
                                src={vci_logo} alt="Venture Capital Insight"/>
                        </a> */}
                        <Tooltip title="Show Valuation Dialog">
                            <IconButton aria-label="fullscreen" style={{padding: '0 12px'}} onClick={() => {
                                setShowVciFundingRound(true)
                                sendGAEvent({
                                    type:"view_vci_valuation_data_full_screen",
                                    data:{
                                        company_id: props.id,
                                    }
                                })
                            }}
                                disabled={!(vci_funding_rounds !== undefined && vci_funding_rounds.count !== 0)}>
                                <CropFreeIcon />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                }
                <Grid item xs={12}>
                {
                    vciEnable ? 
                    <p className="elaborate-text">Data displayed represents a snapshot of the financing history of a Company. Most data is aggregated and derived from <a href='https://www.venturecapinsights.com' target="_blank">https://www.venturecapinsights.com</a>.</p>
                    :
                    <p className="elaborate-text">Data displayed represents a snapshot of the financing history of a Company. Most data is aggregated and derived from third-party sources.</p>
                }
                </Grid>
                
            </Grid>
            
            
            {
                primary_activity_deals && primary_activity_deals.length ?
                    <PrimaryPermissionedDeals
                        handleShowOrderDetailDialog={props.handleShowOrderDetailDialog}
                        {...{ primary_activity_deals }}
                        /> : ''
            }

            {
                company_valuation.valuation ? 
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <Card className={classes.Card}>
                                <span className={classes.SubTitle}>Company Valuation</span>
                                <Typography variant="h6">
                                {
                                    showBlurred(company_valuation.valuation) ? 
                                    <span className="blurred">{company_valuation.valuation}</span> : 
                                    displayVal(company_valuation.valuation, 2)
                                }    
                                </Typography>
                            </Card>
                        </Grid>
                        <Grid item xs={6}>
                            <Card className={classes.Card}>
                                <span className={classes.SubTitle}>Valuation Date</span>
                                <Typography variant="h6">
                                    {
                                        showBlurred(company_valuation.date) ?
                                        <span className="blurred">{company_valuation.date}</span> :
                                        company_valuation.date
                                    }
                                </Typography>
                            </Card>
                        </Grid>
                    </Grid> 
                    : ''
            }
            
            <TableContainer component={Paper} className={classes.container}>
                <Table stickyHeader size="small" aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <HeaderTableCell>Series</HeaderTableCell>
                            <HeaderTableCell>Deal Size (US$M)</HeaderTableCell>
                            <HeaderTableCell>Price</HeaderTableCell>
                            <HeaderTableCell>Valuation (US$M)</HeaderTableCell>
                            <HeaderTableCell>Date</HeaderTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            valid_funding_rounds.map((row, index) => {
                                return (
                                    <StyledTableRow key={index}>
                                        <TableCell>
                                            {
                                            row.deal_type_2 ? 
                                                showBlurred(row.deal_type_2) ? <span className="blurred">{row.deal_type_2}</span> : row.deal_type_2
                                                : ''
                                            }
                                        </TableCell>
                                        <TableCell>
                                            {
                                                row.deal_amount ? 
                                                showBlurred(row.deal_amount) ? <span className="blurred">{row.deal_amount}</span> : numberWithCommas(row.deal_amount, 0) 
                                                : ''
                                            }
                                        </TableCell>
                                        <TableCell>
                                            {
                                                row.price ? 
                                                showBlurred(row.price) ? <span className="blurred">{row.price}</span> : numberWithCommas(row.price)
                                                : ''
                                            }    
                                        </TableCell>
                                        <TableCell>
                                            {
                                                row.post_money_valuation ? 
                                                showBlurred(row.post_money_valuation) ? <span className="blurred">{row.post_money_valuation}</span> : numberWithCommas(row.post_money_valuation, 0) 
                                                : ''
                                            }    
                                        </TableCell>
                                        <TableCell>
                                            {
                                                row.deal_date ?
                                                showBlurred(row.deal_date) ? <span className="blurred">{row.deal_date}</span> : row.deal_date
                                                : ''
                                            }    
                                        </TableCell>
                                    </StyledTableRow>
                                )
                            })
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            <p className='elaborate-text' style={{marginTop: '10px'}}>The content is provided for informational purposes only. Gateway does not guarantee nor make any representation or warranty as to the accuracy, validity, timeliness, completement or continued availability of the content.</p>
            
            

            {
                vciEnable &&
                <div style={{ position: 'absolute', bottom: 10, right: 10 }}>
                    <div className="powered-by-vci">
                        <p>Powered by <a href="https://www.venturecapinsights.com"  target="_blank" >
                                <img style={{background: '#1287ff', width: 60 }}
                                    src={vci_logo} alt="Venture Capital Insight"/>
                            </a>
                        </p>
                    </div>
                </div>
                    
            }
            
            <VciFundingRoundDialog 
                open = {showVciFundingRound}
                closeVciFundingRoundDialog={closeVciFundingRoundDialog}
                {...vci_funding_rounds}
                />
        </Fragment>
    )
}