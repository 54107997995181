import {
    put,
    takeLatest,
    all,
    call,
} from 'redux-saga/effects';

import {
    orderbook,
} from '../../../api';

import {
    // compileQueryString,
    handleCatchSagaError,
} from '../../../utils/util';

import {
    requestUnlock,
} from './Action';

function* requestUnlockResponder({ payload }){
    try{
        const { data } = yield call(orderbook.requestInformation, payload);
        yield put(requestUnlock.success(data));
    } catch(e){
        yield handleCatchSagaError(e, 'requestUnlockResponder');
        yield put(requestUnlock.failure(e));
    } finally{

    }
}

function* saga(){
    yield all([
        takeLatest(requestUnlock.request, requestUnlockResponder),
    ])
}

export default saga;