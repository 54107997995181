import Cookies from 'universal-cookie';

export default ( ) => {
    const cookies = new Cookies();
    let payload = {}
    if(
        cookies.get('preservedUrl_pathname') &&
        cookies.get('preservedUrl_search') &&
        cookies.get('preservedUrl_set_time')
    ) {
        payload = {
            pathname: cookies.get('preservedUrl_pathname'),
            search: cookies.get('preservedUrl_search'),
            set_time: cookies.get('preservedUrl_set_time'),
        }
    }

    return payload;
}

// localStorage.getItem('preservedUrl') ? JSON.parse( localStorage.getItem('preservedUrl') ) : {}