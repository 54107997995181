
import { combineReducers } from "redux";

import { default as InitialState } from './InitialState';

import {
    getCompanyDetail,
    getFundingRound,
    getHistoricalSecondaryPricing,
    getReferencePricing,
    getLiveOffers,
    getLiveDemand,
    getCompanyVerticals,
    getSimilarCompany,
    toggleWatchList,
    clearWatchlistMessage,
    getPermissionedDeal,
    getLiveOffersWatchlist,
    getLiveDemandWatchlist,
    getRiskDisclosure,
    confirmRiskDisclosure,
    shareDeal,
    getSharedUser,
    addCount,
    getVciHistoricalRevenue,
    getVciCapTable,
    getVciFundingRound,
} from './Action';

import {
    createWatchlistV2,
} from '../../common/watchlist/Action';

import {
    clearOrderbookCompany,
} from '../pipeline/Action';

import {
    reducer as request_info_reducer,
} from './components/RequestInformation';

export default combineReducers({
    request_info_reducer,
    reducer(state=InitialState, action){
        switch( action.type ){
            case getCompanyDetail.request.toString():
            case getCompanyDetail.failure.toString():
            case clearOrderbookCompany.toString():
                return Object.assign({}, state, {
                    company_detail: {},
                });
            case getCompanyDetail.success.toString():
                return Object.assign({}, state, {
                    company_detail: action.payload,
                });
            case getFundingRound.request.toString():
            case getFundingRound.failure.toString():
                return Object.assign({}, state, {
                    funding_rounds: { count: 0, results: [] }
                });
            case getFundingRound.success.toString():
                return Object.assign({}, state, {
                    funding_rounds: action.payload
                });
            case getHistoricalSecondaryPricing.request.toString():
            case getHistoricalSecondaryPricing.failure.toString():
                return Object.assign({}, state, {
                    historical_pricing: {
                        allow: false,
                        data: false,
                        historical_pricing: []
                    }
                });
            case getHistoricalSecondaryPricing.success.toString():
                return Object.assign({}, state, {
                    historical_pricing: action.payload
                });
            case getReferencePricing.request.toString():
            case getReferencePricing.failure.toString():
                return Object.assign({}, state, {
                    reference_pricing: {
                        allow: false,
                        data: false,
                        reference_names: [],
                        reference_pricing: []
                    }
                });
            case getReferencePricing.success.toString():
                return Object.assign({}, state, {
                    reference_pricing: action.payload
                })
            case getLiveOffers.request.toString():
            case getLiveOffers.failure.toString():
                return Object.assign({}, state, {
                    live_offers: { resultCount: 0, resultList: [] }
                });
            case getLiveOffers.success.toString():
                return Object.assign({}, state, {
                    live_offers: action.payload
                });
            case getLiveDemand.request.toString():
            case getLiveDemand.failure.toString():
                return Object.assign({}, state, {
                    live_demand: { resultCount: 0, resultList: [] }
                });
            case getLiveDemand.success.toString():
                return Object.assign({}, state, {
                    live_demand: action.payload
                });
            case getCompanyVerticals.request.toString():
            case getCompanyVerticals.failure.toString():
                return Object.assign({}, state, {
                    company_verticals: []
                });
            case getCompanyVerticals.success.toString():
                return Object.assign({}, state, {
                    company_verticals: action.payload
                });
            case getSimilarCompany.request.toString():
            case getSimilarCompany.failure.toString():
                return Object.assign({}, state, {
                    similar_companies: {
                        resultCount: 0,
                        resultList: []
                    }
                })
            case getSimilarCompany.success.toString():
                return Object.assign({}, state, {
                    similar_companies: action.payload
                });
            case toggleWatchList.request.toString():
            case clearWatchlistMessage.toString():
                return Object.assign({}, state, {
                    toggle_watchlist: {
                        error: false,
                        message: ''
                    }
                });
            case toggleWatchList.failure.toString():
                return Object.assign({}, state, {
                    toggle_watchlist: {
                        error: true,
                        message: "There is an error, please try again later."
                    }
                });
            case toggleWatchList.success.toString():
            case createWatchlistV2.success.toString():
                const updateWatchlist = state.company_detail.id === action.payload.company_id
                return Object.assign({}, state, {
                    toggle_watchlist: {
                        error: false,
                        message: action.payload.message
                    },
                    company_detail: {...state.company_detail, 
                        ...{ watchlist: updateWatchlist ? 
                            !state.company_detail.watchlist:
                            state.company_detail.watchlist} },
                });
            case getPermissionedDeal.request.toString():
            case getPermissionedDeal.failure.toString():
                return Object.assign({}, state, {
                    permission_deal: {}
                });
            case getPermissionedDeal.success.toString():
                return Object.assign({}, state, {
                    permission_deal: action.payload
                })
            case getLiveOffersWatchlist.request.toString():
            case getLiveOffersWatchlist.failure.toString():
                return Object.assign({}, state, {
                    live_offers_watchlist: 0
                });
            case getLiveOffersWatchlist.success.toString():
                const o_num = action.payload && 
                            action.payload.resultList && 
                            action.payload.resultList.length ? action.payload.resultList[0].deal_size : 0;
                return Object.assign({}, state, {
                    live_offers_watchlist: o_num
                });
            case getLiveDemandWatchlist.request.toString():
            case getLiveDemandWatchlist.failure.toString():
                return Object.assign({}, state, {
                    live_demand_watchlist: 0
                });
            case getLiveDemandWatchlist.success.toString():
                const d_num = action.payload && 
                            action.payload.resultList && 
                            action.payload.resultList.length ? action.payload.resultList[0].deal_size : 0;
                return Object.assign({}, state, {
                    live_demand_watchlist: d_num
                });
            case getRiskDisclosure.request.toString():
			case getRiskDisclosure.failure.toString():
				return Object.assign({}, state, {
					riskDisclosureConfirmed: false,
					riskDisclosureResponse: {
						content: []
					}
				});
			case getRiskDisclosure.success.toString():
				return Object.assign({}, state, {
					riskDisclosureResponse: action.payload
				});
            case confirmRiskDisclosure.toString():
				return Object.assign({}, state, {
					riskDisclosureConfirmed: true
				});
            case shareDeal.request.toString():
			case shareDeal.failure.toString():
				return Object.assign({}, state, {
					shareDealResponse: {
						token: ''
					}
				});
			case shareDeal.success.toString():
				return Object.assign({}, state, {
					shareDealResponse: action.payload
				});
            case getSharedUser.request.toString():
			case getSharedUser.failure.toString():
				return Object.assign({}, state, {
					shared_user: {}
				});
			case getSharedUser.success.toString():
				return Object.assign({}, state, {
					shared_user: action.payload
				});
            case getVciHistoricalRevenue.request.toString():
            case getVciHistoricalRevenue.failure.toString():
                return Object.assign({}, state, {
                    vci_historical_revenue: {
                        resultCount: 0,
                        resultList: []
                    },
                });
            case getVciHistoricalRevenue.success.toString():
                return Object.assign({}, state, {
                    vci_historical_revenue: action.payload,
                });
            case getVciCapTable.request.toString():
            case getVciCapTable.failure.toString():
                return Object.assign({}, state, {
                    vci_cap_table: {
                        resultCount: 0,
                        resultList: []
                    },
                });
            case getVciCapTable.success.toString():
                return Object.assign({}, state, {
                    vci_cap_table: action.payload,
                });
            case getVciFundingRound.request.toString():
            case getVciFundingRound.failure.toString():
                return Object.assign({}, state, {
                    vci_funding_rounds: {
                        count: 0,
                        results: []
                    },
                });
            case getVciFundingRound.success.toString():
                return Object.assign({}, state, {
                    vci_funding_rounds: action.payload,
                });
			case addCount.request.toString():
			case addCount.failure.toString():
			case addCount.success.toString():
				return Object.assign({}, state, {});
            default:
                return state;
        }
    }
})