import React from 'react'
import {
    withStyles
} from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';

const useStyles = (theme => ({
    root: {
        // border: '1px solid rgba(77, 77, 77, 0.25)',
        overflow: 'hidden',
        borderRadius: 7,
        width: '100% ',
        minHeight: 50,
        // backgroundColor: '#fff',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        '&:hover': {
            backgroundColor: '#fff',
        },
        '&$focused': {
            // backgroundColor: '#fff',
            // boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
            // borderColor: theme.palette.primary.main,
        },
    }
}));

const CustomInput = (props) => (
    <TextField 
        InputProps={
            {
                classes: props.classes,
                min: "0", 
                max: "10", 
                step: "1" , 
                style: { backgroundColor: props.backgroundColor ? props.backgroundColor: '#fff' },
                disableUnderline: true 
            }
        } {...props} 
        />
)

export default withStyles(useStyles)(CustomInput)
