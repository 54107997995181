import React from 'react';
import {
    default as Base
} from './BaseTooltip';

export default (props) => {
    return <Base
                {...props}
                items={
                    [
                        'Only visible to Gateway: When checked, this order will only be visible to the Gateway team for order matching.'
                    ]
                }
                />
}