import React, { Component, Fragment } from 'react';
import * as funcs from '../common/Funcs';
import { Link } from "react-router-dom";
import { isMobile } from 'react-device-detect';
import { Button } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';

class DealDetail extends Component {
    state = {
        scale: [],
        meta_data: {}
    }

    constructor(props) {
        super(props);
        this.handleCloseDealDetailPopUp = this.handleCloseDealDetailPopUp.bind(this);
    }

    componentDidMount() {
        const { scale } = this.props;
        if (scale) {
            const new_scale = scale.map(item => false);
            this.setState({ scale: new_scale });
        }
        // document.addEventListener('mousedown', this.handleClickOutside.bind(this));
    }

    // componentWillUnmount() {
    //     document.removeEventListener('mousedown', this.handleClickOutside.bind(this));
    // }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { scale } = this.props;

        if (scale && scale.length !== prevProps.scale.length) {
            const new_scale = [];
            for (var ii = 0; ii < scale.length; ii++) {
                new_scale.push(false);
            }
            this.setState({ scale: new_scale });
        }
    }

    // handleClickOutside(evt) {
    //     const { scale } = this.state;
    //     const new_scale = [];
    //     for (var ii = 0; ii < scale.length; ii++) {
    //         new_scale.push(false);
    //     }
    //     this.setState({ scale: new_scale });
    // }

    handleClickScaleItem(state_key, index, evt) {

		// console.log(index);

		const { selected_price, selected_post_money_valuation } = this.props;
		const scales = this.props[state_key];

		if( scales[index] ){
			const scale = scales[index];
			const meta_data = funcs.GeneratePipelineItemMetaInfo(scale, selected_post_money_valuation, selected_price);
            this.setState({meta_data: meta_data});
		}

        // // console.log(index);
        // const { scale } = this.state;
        // const new_scale = [];
        // for (var ii = 0; ii < scale.length; ii++) {
        //     let new_state = scale[ii];
        //     if (ii == index) new_state = !scale[ii];
        //     new_scale.push(new_state);
        // }
        // this.setState({ scale: new_scale });
    }

    expressInterest(value, evt){
        this.props.expressInterest({
            company : value,
            interest_type: 'INVEST'
        })
    }

    handleCloseDealDetailPopUp(evt){
       this.setState({ meta_data: {} });
	  }

    render() {
        const { props } = this;
        const { meta_data } = this.state;
        const state_scale = this.state.scale;
        // console.log(state_scale);
        const overall_has_live_offers = props.offer_scale ? props.offer_scale.filter(item => item.has_live_offer) : [];
        // const overall_has_live_bids = props.bid_scale ? props.bid_scale.filter(item => item.has_live_bid) : [];
        const deal_detail_popup_class = meta_data.price_range ? "deal-detail-popup active" : "deal-detail-popup";
        return (
            <Fragment>
                <div className="col-12 mt-3 deal-detail">

                    <div className="row">
                        <div className="col-12 col-lg-12">
                            <div className="box p-4">

                                <div>
                                    <h4 className="subheading-color">Offer Range <span>(as seen in the last 90 days)</span></h4>
                                    <div className="row space-evenly offer-details">
                                        <div className="col">
                                            <div className="meta">
                                                <div className="lower-end">
                                                    {props['historical_lowest_offer'] ? <span className="bigText">${props['historical_lowest_offer'].toFixed(2)}</span> : ''}
                                                </div>

                                                <div className="higher-end">
                                                    {props['historical_highest_offer'] ? <span className="bigText">${props['historical_highest_offer'].toFixed(2)}</span> : ''}
                                                </div>

                                                <div className="clear"></div>
                                            </div>

                                            <div className="track">
                                                {
                                                    props['offer_scale'] &&
                                                    props['offer_scale'].map((scale, ii) => {

                                                        let track_item_class = scale.has_live_offer ? "track-item highlighted" : "track-item";
                                                        if( isMobile ) track_item_class += " mobile";
                                                        if( state_scale[ii] ) track_item_class += " active";

                                                        // const price_range = "$" + scale.scale_start.toFixed(2) + ' - $' + scale.scale_end.toFixed(2);
                                                        // const medium_price_point = ( scale.scale_end + scale.scale_start ) / 2;
                                                        // const disc_prem = medium_price_point / props['selected_price'] - 1;
                                                        // const disc_prem_range = props['selected_price'] ? funcs.ConvertDiscPrem(disc_prem) : "-";

                                                        // let implied_valuation_display = " - ";
                                                        // if( props['selected_price'] && props['selected_post_money_valuation'] ){
                                                        //     let implied_valuation = ( disc_prem + 1 ) * props['selected_post_money_valuation'];

                                                        //     if( implied_valuation > 1000 ){
                                                        //         implied_valuation_display = "$" + ( implied_valuation / 1000 ).toFixed(2) + "B^";
                                                        //     } else {
                                                        //         implied_valuation_display = "$" + implied_valuation.toFixed(0) + "M^";
                                                        //     }
                                                        // }

                                                        const meta_data = funcs.GeneratePipelineItemMetaInfo(scale, props['selected_post_money_valuation'], props['selected_price']);

                                                        return (
                                                            <div className={track_item_class} key={ii} onClick={this.handleClickScaleItem.bind(this, 'offer_scale', ii)}>
                                                                { !isMobile ?
                                                                    <div className="track-meta">
                                                                        <div className="track-meta-container">
                                                                            <div className="meta-item">
                                                                                <span className="label">Price Range</span>
                                                                                <span>{meta_data.price_range}</span>
                                                                            </div>

                                                                            <div className="meta-item no-border">
                                                                                <span className="label">Median</span>
                                                                                <span>{meta_data.disc_prem_range}</span>
                                                                            </div>

                                                                            <div className="meta-item no-border">
                                                                                <span className="label">Implied Valuation: {meta_data.implied_valuation_display} </span>
                                                                            </div>

                                                                            <div className="triangle"></div>
                                                                        </div>
                                                                    </div>
                                                                : ''}
                                                            </div>
                                                        )

                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    {props['bid_scale'] && props['bid_scale'].length ? <h4 className="subheading-color clear-space">Bid Range <span>(as seen in the last 90 days)</span></h4> : ''}
                                    <div className="row space-evenly bid-details">
                                        <div className="col">

                                            <div className="meta">
                                                <div className="lower-end">
                                                    {props['historical_lowest_bid'] ? <span className="bigText">${props['historical_lowest_bid'].toFixed(2)}</span> : ''}
                                                </div>

                                                <div className="higher-end">
                                                    {props['historical_highest_bid'] ? <span className="bigText">${props['historical_highest_bid'].toFixed(2)}</span> : ''}
                                                </div>

                                                <div className="clear"></div>
                                            </div>

                                            <div className="track">
                                                {
                                                    props['bid_scale'] &&
                                                    props['bid_scale'].map((scale, ii) => {

                                                        let track_item_class = scale.has_live_bid ? "track-item highlighted" : "track-item";
                                                        if( isMobile ) track_item_class += " mobile";
                                                        if( state_scale[ii] ) track_item_class += " active";

                                                        // const price_range = "$" + scale.scale_start.toFixed(2) + ' - $' + scale.scale_end.toFixed(2);
                                                        // const medium_price_point = ( scale.scale_end + scale.scale_start ) / 2;
                                                        // const disc_prem = medium_price_point / props['selected_price'] - 1;
                                                        // const disc_prem_range = props['selected_price'] ? funcs.ConvertDiscPrem(disc_prem) : "-";

                                                        // let implied_valuation_display = " - ";
                                                        // if( props['selected_price'] && props['selected_post_money_valuation'] ){
                                                        //     let implied_valuation = ( disc_prem + 1 ) * props['selected_post_money_valuation'];

                                                        //     if( implied_valuation > 1000 ){
                                                        //         implied_valuation_display = "$" + ( implied_valuation / 1000 ).toFixed(2) + "B^";
                                                        //     } else {
                                                        //         implied_valuation_display = "$" + implied_valuation.toFixed(0) + "M^";
                                                        //     }
                                                        // }

                                                        const meta_data = funcs.GeneratePipelineItemMetaInfo(scale, props['selected_post_money_valuation'], props['selected_price']);

                                                        return (
                                                            <div className={track_item_class} key={ii} onClick={this.handleClickScaleItem.bind(this, 'bid_scale', ii)}>
                                                                { !isMobile ?
                                                                    <div className="track-meta">
                                                                        <div className="track-meta-container">
                                                                            <div className="meta-item">
                                                                                <span className="label">Price Range</span>
                                                                                <span>{meta_data.price_range}</span>
                                                                            </div>

                                                                            <div className="meta-item no-border">
                                                                                <span className="label">Median</span>
                                                                                <span>{meta_data.disc_prem_range}</span>
                                                                            </div>

                                                                            <div className="meta-item no-border">
                                                                                <span className="label">Implied Valuation: {meta_data.implied_valuation_display}</span>
                                                                            </div>

                                                                            <div className="triangle"></div>
                                                                        </div>
                                                                    </div>
                                                                : ''}
                                                            </div>
                                                        )

                                                    })
                                                }
                                            </div>

                                        </div>
                                    </div>

                                    <div className="disclaimer-container mt-4">
                                        {
                                            overall_has_live_offers.length ? <div className="disclaimer-item offer">
                                                <div className="label"></div>
                                                <p>Live Offers</p>
                                                <div className="clear"></div>
                                            </div> : ''
                                        }

                                        {
                                            props['bid_scale'] && props['bid_scale'].length ? <div className="disclaimer-item bid">
                                                <div className="label"></div>
                                                <p>Live Bids</p>
                                                <div className="clear"></div>
                                            </div> : ''
                                        }

                                        <p className="margin-remove">^ Estimated based on median price point</p>
                                        {/* <a className="btn-seller">Sell { props.name }</a> */}
                                        <a className="position-button-2 express-interest btn-mid" onClick={this.expressInterest.bind(this, props['company_id'])}>Express Interest &nbsp;&nbsp;&gt;</a>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>

                </div>
                <div className={deal_detail_popup_class}>
					<div className="container">
						<div className="row">
							<div className="col col-12">
								<Button className="btn-close" onClick={this.handleCloseDealDetailPopUp}>
									<CloseIcon />
								</Button>
								<div className="clear"></div>
							</div>
						</div>
						<div className="row">
							<div className="col col-4">
								<span className="label">Price Range</span>
							</div>
							<div className="col col-4">
								<span className="label">Disc / Prem^</span>
							</div>
							<div className="col col-4">
								<span className="label">Implied Valuation^</span>
							</div>
						</div>
						<div className="row">
							<div className="col col-4">
								<span>{meta_data.price_range}</span>
							</div>
							<div className="col col-4">
								<span>{meta_data.disc_prem_range}</span>
							</div>
							<div className="col col-4">
								<span>{meta_data.implied_valuation_display}</span>
							</div>
						</div>
					</div>
				</div>
            </Fragment>
        )
    }
}

export default DealDetail;
