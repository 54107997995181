import {
	getAllCompanies,
	getCompanyInvestors,
	getVerticals, 
	getFundingRound,
	getLatestFundingRound
} from './Action'

import { default as InitialState } from './InitialState';
import { combineReducers } from 'redux';

export default combineReducers({
	reducer(state=InitialState, action){
		switch( action.type ){
			case getAllCompanies.success.toString():
				return Object.assign({}, state, {
					AllCompaniesResponse: action.payload
				});
			case getAllCompanies.failure.toString():
				return Object.assign({}, state, {
					AllCompaniesResponse: action.payload
				});
			case getVerticals.success.toString():
				return Object.assign({}, state, {
					verticals: action.payload
				});
			case getVerticals.failure.toString():
				return state;
			case getCompanyInvestors.success.toString():
				return Object.assign({}, state, {
					investors: action.payload
				});
			case getCompanyInvestors.failure.toString():
				return state;
			case getFundingRound.success.toString():
				return Object.assign({}, state, {
					fundingRounds: action.payload
				});
			case getFundingRound.failure.toString():
				return state;
			case getLatestFundingRound.success.toString():
				return Object.assign({}, state, {
					latestFundingRound: action.payload
				});
			case getLatestFundingRound.failure.toString():
				return state;
			default:
				return state;
		}
	}
	
});