import React, { useState } from 'react';

import {
    Button,
    Box,
    Grid,
    Paper,
    Tooltip,
    IconButton,
} from '@material-ui/core';

import { content_placeholders } from '../../../config';
import arrowIcon from "../../../assets/images/arrow-icon.png";
import InfoIcon from '@material-ui/icons/Info';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CropFreeIcon from '@material-ui/icons/CropFree';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import ShareIcon from '@material-ui/icons/Share';

import {
    PriceChart,
    CompanyInfo,
    FundingRounds,
    DealTimeline,
    DealHighlight,
    LiveOrderbook,
    GPDetail,
    OrderDetailDialog,
    // FullScreenPriceChartDialog,
    // historicalPrice,
    // company_id,
} from './components';

import {
    CompanySearch,
} from '../common';

import {
    feature_flags,
} from '../../../config';

import DisclaimerData from '../../common/DisclaimerData';

export default (props) => {
    const { 
        dealType, 
        sharedPage, 
        gp_detail, 
        dealHighlight, 
        dealTimelines,
        live_offers,
        live_demand,
        appConfig,
        loginUserData,
        clubDetail,
        companyDetailResponse,
        company_id,
        historicalPrice,
        classes,
        fundingRounds,
        verticals,
        loginUserData: { email },
        public_mode,
        isMobile,
        gpDetailIsBlurred,

        companyDetailIsBlurred,
        
        AllCompaniesResponse,

        handleShowExpressInterest,
        handlePromptExpress,
        live_demand_watchlist,
        live_offers_watchlist,
        match: { params : { id, token } },
        location: { pathname },
    } = props;

    const [order_detail, set_order_detail] = useState({
        open: false,
        data: {}
    });

    const handleScrollTo = (evt, id) => {
        const elem = document.getElementById(id);
        elem.scrollIntoView({behavior: 'smooth'});
    }


    const handleSetOddOpen = (evt, type, data) => {
        // console.log( 'calling handle set odd open')
        const new_order_detail = {
            open: !order_detail['open'],
            data: { ...data, buy_type: type }
        }
        set_order_detail(new_order_detail);
    }

    const class_str = dealType && dealType.deal_class_str ? dealType.deal_class_str.split('|') : []
    const watermark_class = sharedPage && email ? `watermark-container` : '';
    const watermark_wording = sharedPage && email ? `Gateway Private Markets - Private & Confidential, Prepared for ${email}`  : ''

    const non_keyevents = dealTimelines && dealTimelines.length ? dealTimelines.filter(item => !item.key_event) : [];
    return (
        <Box flexGrow={1} m={2}>
            <Grid container spacing={2}>
                <Grid item xs={isMobile ? 12 : 8}>
                    {
                        email && !public_mode ? 
                            <Button onClick={props.pageGoBack}>
                                <ArrowBackIcon />
                            </Button> : ''
                    }
                    
                    {
                        props.handleShowDealShareDialog &&
                        feature_flags.feature_is_available(
                            appConfig,
                            feature_flags.wealth_flags.DealShare.flag_name,
                            feature_flags.wealth_flags.DealShare.attributes.share_button,
                            email
                        )
                        ? 
                            <Tooltip
                                title={'Share this deal opportunity'}
                                >
                                    <Button
                                        onClick={(evt) => props.handleShowDealShareDialog(evt)}
                                        >
                                        <ShareIcon />
                                        <span className="smallText orderbook" style={{marginLeft: '5px'}}>
                                            Share this company
                                        </span>
                                    </Button>
                                    
                            </Tooltip> : ''
                    }

                    {
                        email && !public_mode ?
                            <div className="deal-type-tag-wrap in-detail-page">
                                { class_str.indexOf(content_placeholders.deal_class_str.gpled) > -1 && <span className={classes.GPLedDeal}>GP Co-investment</span>}
                                { 
                                    class_str.indexOf(content_placeholders.deal_class_str.primary) > -1 &&
                                    class_str.indexOf(content_placeholders.deal_class_str.gpled) == -1 && 
                                        <span className={classes.PrimaryDeal}>Company Primary</span>
                                }
                                { class_str.indexOf(content_placeholders.deal_class_str.companyled) > -1 &&
                                    class_str.indexOf(content_placeholders.deal_class_str.primary) == -1 &&
                                    class_str.indexOf(content_placeholders.deal_class_str.gpled) == -1 && 
                                        <span className={classes.CompLedDeal}>Company Arranged</span>}
                                {
                                    !class_str.length && <span className={classes.DirectSecondaryDeal}>Secondary</span>
                                }
                            </div> : ''
                    }
                    
                </Grid>
                
                <Grid item xs={isMobile ? 12 : 4}>
                    {
                        !sharedPage ? 
                            <CompanySearch 
                                handleShowExpressInterest={props.handleShowExpressInterest}
                                handlePromptExpress={props.handlePromptExpress}
                                handleShowWatchlistFromSearchCompany={props.handleShowWatchlistFromSearchCompany}
                                /> : ''
                    }
                </Grid>

                <Grid item xs={12} className={watermark_class}>
                    <CompanyInfo
                        handleToggleWatchList={() => props.doToggleWatchList1()}
                        handleRequestInformationDialogOpen={
                            props.handleRequestInformationDialogOpen}
                        {...{
                            ...companyDetailResponse,
                            verticals,
                            appConfig,
                            loginUserData,
                            companyDetailIsBlurred,
                            sharedPage,
                            public_mode,
                        }
                    }
                    />
                    {
                        sharedPage && email ?
                            <div className="watermark-label">{watermark_wording}</div>
                            : ''
                    }
                </Grid>

                {
                    gp_detail && gp_detail.length ?
                        <Grid item xs={12} className={`gp-highlight ${watermark_class}`}>
                            <Paper style={{ padding: 16 }}>
                                <Box>
                                    <Grid item xs={12}>
                                        <GPDetail
                                            {...{gp_detail}}
                                            {...{gpDetailIsBlurred: gpDetailIsBlurred,
                                                logo: companyDetailResponse.logo}}
                                            handleRequestInformationDialogOpen={
                                                props.handleRequestInformationDialogOpen}
                                            />
                                    </Grid>
                                </Box>
                            </Paper>
                            {
                                sharedPage && email ?
                                    <div className="watermark-label">{watermark_wording}</div>
                                    : ''
                            }
                        </Grid>
                        : ''
                }

                {
                    dealHighlight && Object.keys(dealHighlight).length ?
                        <Grid item xs={12} className={`deal-offer-section ${watermark_class}`}>
                        
                            <Paper style={{ padding: 16 }}>
                                <Box>
                                    <h4 className="subheading-color company-item-title">
                                        <span className="company-item-rectangle"></span>
                                        Featured Deal
                                        <Button 
                                            onClick={(evt) => handleScrollTo(evt, "additional-market-activity")} 
                                            style={{fontSize: 12}}>Show more offers</Button>
                                        {/* <Tooltip title="Data represented in Market Intelligence are inclusive of all market data that Gateway has knowledge of, including but not limited to offers / demand available on the Gateway platform.">
                                            <InfoIcon style={{width: '15px'}} />
                                        </Tooltip> */}
                                    </h4>
                                    <DealHighlight
                                        {...{ 
                                            dealHighlight, 
                                            gp_detail, 
                                            dealType, 
                                            companyDetailResponse, 
                                            fundingRounds }}
                                        />
                                </Box>
                            </Paper>
                            {
                                sharedPage && email ?
                                    <div className="watermark-label">{watermark_wording}</div>
                                    : ''
                            }
                        </Grid> : ''
                }
                
                {
                    dealTimelines && dealTimelines.length && non_keyevents.length ?
                        <Grid item xs={12} className={`timeline-section ${watermark_class}`}>
                            <Paper style={{ padding: 16 }}>
                                <Box>
                                    <h4 className="subheading-color company-item-title">
                                        <span className="company-item-rectangle"></span>
                                        Deal Timeline
                                        {/* <Tooltip title="Data represented in Market Intelligence are inclusive of all market data that Gateway has knowledge of, including but not limited to offers / demand available on the Gateway platform.">
                                            <InfoIcon style={{width: '15px'}} />
                                        </Tooltip> */}
                                    </h4>
                                    <DealTimeline
                                        {...{ dealTimelines }} 
                                        />
                                </Box>
                            </Paper>
                            {
                                sharedPage && email ?
                                    <div className="watermark-label">{watermark_wording}</div>
                                    : ''
                            }
                        </Grid> : ''
                }
                
                <Grid item xs={12} md={6} className={`historical-price-section ${watermark_class}`}>
                    <Paper style={{ padding: 16,  width: '100%', height: '100%' }}>
                        <Box>
                            <h4 className="subheading-color company-item-title">
                                <span className="company-item-rectangle"></span>
                                Primary Activity
                                <Tooltip enterTouchDelay={0} title="Data displayed represents a snapshot of the financing history of a Company. Most data is aggregated and derived from third-party sources.">
                                    <InfoIcon style={{width: '15px'}} />
                                </Tooltip>
                            </h4>
                            <FundingRounds
                                {...fundingRounds}
                                />
                        </Box>
                    </Paper>
                    {
                        sharedPage && email ?
                            <div className="watermark-label">{watermark_wording}</div>
                            : ''
                    }
                </Grid>

                <Grid item xs={12} md={6} className="historical-price-section">
                    <Paper style={{ padding: 16,  width: '100%', height: '100%' }}>
                        <Box>
                            <Grid container spacing={2}>
                                <Grid item xs={10}>
                                    <h4 className="subheading-color company-item-title">
                                        <span className="company-item-rectangle"></span>
                                            Secondary Activity 
                                        <Tooltip enterTouchDelay={0} title="Data displayed represents live order (offer or bid) prices at that point in time and not executed prices. All data is sourced from Gateway network.">
                                            <InfoIcon style={{width: '15px'}} />
                                        </Tooltip>
                                    </h4>
                                </Grid>
                                <Grid item xs={2}>
                                    <Tooltip title="Fullscreen view">
                                        <IconButton aria-label="fullscreen" onClick={(evt) => props.handlePriceChartFullScreen(evt)}>
                                            <CropFreeIcon />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                            </Grid>
                            
                            <PriceChart
                                {...{
                                    ...{ company_id: id, token: token },
                                    historical_pricing: historicalPrice,
                                    funding_rounds: fundingRounds,
                                    isMobile: isMobile,
                                    public_mode: public_mode,
                                }}
                            />
                        </Box>
                    </Paper>
                </Grid>

                <Grid item xs={12} className={watermark_class}>
                    <Paper style={{padding: 16, width: '100%', height: '100%'}}>
                        <Box>
                            <h4 id="additional-market-activity" className="subheading-color company-item-title">
                                <span className="company-item-rectangle"></span>
                                Additional Live Market Activity
                                </h4>
                            <LiveOrderbook
                                handlePrefillPlaceOrder={props.handleFakeClick}
                                handleShowExpressInterest={props.handleFakeClick}
                                handleShowOrderDetailDialog={handleSetOddOpen}
                                handlePromptExpress={props.handleFakeClick}
                                {...{
                                    live_offers,
                                    live_demand,
                                    historical_pricing: historicalPrice.historical_pricing,
                                    funding_rounds: fundingRounds,
                                    longest_digit:3,
                                    appConfig,
                                    loginUserData,
                                    live_demand_watchlist,
                                    live_offers_watchlist,
                                }}
                            />
                        </Box>
                    </Paper>
                    {
                        sharedPage && email ?
                            <div className="watermark-label">{watermark_wording}</div>
                            : ''
                    }
                </Grid>
                {
                    !sharedPage ?
                        <Grid item xs={12} className="club-deal-control">
                            <Paper style={{ padding: 16 }}>
                                <Box>
                                    <Grid container spacing={2}>
                                        <Grid item xs={isMobile ? 12: 6}>
                                            <Button
                                                // onClick={(e) =>
                                                //     props.handleExpressBuySellDialogOpen(
                                                //         {
                                                //             price: clubDetail.price,
                                                //             min_size: clubDetail.min_size,
                                                //             company: companyDetailResponse.name,
                                                //             logo: companyDetailResponse.logo,
                                                //             dialogTitle: content_placeholders.buttons.express_interest,
                                                //             interest_type: "INVEST",
                                                //         },
                                                //         e
                                                //     )
                                                // }
                                                onMouseEnter={(evt) => handlePromptExpress(evt, true)}
                                                onMouseLeave={(evt) => handlePromptExpress(evt, false)}
                                                onClick={(evt) => handleShowExpressInterest(evt, true)}
                                                
                                                className="btn-mid express-interest">
                                                <BorderColorIcon style={{marginRight: 10}} />
                                                <span className="btn-mid-content">
                                                {
                                                    loginUserData.activeAccessRight &&
                                                    loginUserData.activeAccessRight.access_right &&
                                                    loginUserData.activeAccessRight.access_right.permission == content_placeholders.user_type.trial_user ?
                                                        content_placeholders.buttons.request_full_access 
                                                    : clubDetail.price
                                                        ? content_placeholders.buttons.invest_now
                                                        : content_placeholders.buttons.express_interest
                                                }
                                                </span>
                                                <img src={arrowIcon} className="arrow-icon" />
                                            </Button>
                                        </Grid>
                                        <Grid item xs={isMobile ? 12: 6}>
                                            <Button
                                                onClick={(e) =>
                                                    props.handleRequestInformationDialogOpen(
                                                        {
                                                            logo: companyDetailResponse.logo,
                                                            dialogTitle: content_placeholders.buttons.request_for_information,
                                                        },
                                                        e
                                                    )
                                                }
                                                //onClick={this.openSellPopup}
                                                className="btn-mid express-interest request-information">
                                                {/* <img
                                                    src={expressInterestIcon}
                                                    className="express-icon"
                                                /> */}
                                                <HelpOutlineIcon style={{marginRight: 10}} />
                                                <span className="btn-mid-content">
                                                    Request Info
                                                </span>
                                                <img src={arrowIcon} className="arrow-icon" />
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Box>
                                
                            </Paper>
                        </Grid> : ''
                }
                

                <Grid item xs={12}>
                    <DisclaimerData
                        {...{ token, pathname }}
                        />
                </Grid>

            </Grid>

            <OrderDetailDialog
                handleShowOrderDetailDialog={handleSetOddOpen}
                company_detail={AllCompaniesResponse}
                funding_rounds={fundingRounds}
                {...order_detail}
                
                />
        </Box>
    )
}