import React, { Fragment } from "react";
import { PipelineSidebar } from '../../common';
// import { Styles } from '../../styles';
import {
    content_placeholders,
    feature_flags,
} from "../../../config";
import { Helmet } from "react-helmet";

import {
    ConfirmOverwrite,
    FullScreenPriceChartDialog,
    OrderDetailDialog,
    RequestInformationPositionWrapper,
} from './components';

import {
    PlaceOrderPositionWrapper,
} from '../common';

import {
    WatchlistSnackbar,
} from '../common/components';

import { sendGAEvent } from '../../../utils/ga';
import {
    getLongestDigit
} from '../../../utils/util';

import {
    GetToken,
} from '../../../utils';
import { WatchListDialog } from "../../common/watchlist/dialog";
import { default as CompanyContent } from './CompanyContent';
import { default as DealShareDialog } from './DealShareDialog';

class Company extends React.Component {

    state = {
        placeorder: {},
        alert: false,
        show_alert: false,
        dialog_open: false,
        company_id: 0,
        express_interest_modal_open: false,
        // request_information_modal_open: false,
        // request_information_prefill: [],
        request_information: {
            open: false,
            prefill_key: [],
            order_detail: {}
        },
        order_detail: {
            open: false,
            data: {}
        },
        prompt_express: false,
        preserved: {
            type: '',
            data: {}
        },
        longest_digit: 0,
        permission_deal: {
            company_id: 0
        },
        dealSharing: {
            open: false,
        },
        watchListDialog: {
            open: false,
            companyId: 0,
            active: false,
        },
        primary_activity_deals: [],
        secondary_activity_deals: []
    }

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        const {
            getCompanyDetail,
            getFundingRound,
            getLiveOffers,
            getLiveDemand,
            getHistoricalSecondaryPricing,
            getReferencePricing,
            getCompanyVerticals,
            getSimilarCompany,
            getPermissionedDeal,
            getLiveOffersWatchlist,
            getLiveDemandWatchlist,
            match: { params: { id } },
            history: { location },
            clearWatchlistMessage,
            getVciHistoricalRevenue,
            getVciCapTable,
            getVciFundingRound,
            appConfig,
            loginUserData: { email }
        } = this.props;

        //console.log( appConfig );

        clearWatchlistMessage();
        getCompanyDetail({ 
            public_url: false,
            // root_path: 'v2',
            // sub_path: 'orderbook',
            company_identifier: id,
            // qs: { company_id: id } 
        });
        getFundingRound({ 
            root_path: 'v2',
            sub_path: 'orderbook',
            company_identifier: '',
            qs: { company_id: id, show_all: 1 }
        });

        getLiveOffers({
            root_path: 'v2',
            sub_path: 'orderbook/offers',
            company_identifier: '',
            qs: { company_id: id, live: true, asc_or_desc: 'asc' }
        });
        getLiveDemand({
            root_path: 'v2',
            sub_path: 'orderbook/demands',
            company_identifier: '',
            qs: { company_id: id, live: true }
        });
        getLiveOffersWatchlist({
            root_path: 'v2',
            sub_path: 'orderbook/offers/watchlist-total-notional',
            company_identifier: '',
            qs: { company_id: id, live: true, asc_or_desc: 'asc' }
        });
        getLiveDemandWatchlist({
            root_path: 'v2',
            sub_path: 'orderbook/demands/watchlist-total-notional',
            company_identifier: '',
            qs: { company_id: id, live: true }
        });

        // console.log( feature_flags.feature_is_available(appConfig, feature_flags.flags.SecondaryActivity.flag_name, feature_flags.flags.SecondaryActivity.attributes.extended_price_chart, email) )
        // getHistoricalSecondaryPricing({
        //     root_path: 'v2/orderbook',
        //     sub_path: 'weighted-prices',
        //     company_identifier: '',
        //     qs: feature_flags.feature_is_available(appConfig, feature_flags.flags.SecondaryActivity.flag_name, feature_flags.flags.SecondaryActivity.attributes.extended_price_chart, email) ? { company_id: id, last_N_day: 1095 } : { company_id: id }
        // });
        // getReferencePricing({
        //     root_path: 'v2/orderbook',
        //     sub_path: 'reference-prices',
        //     company_identifier: '',
        //     qs: feature_flags.feature_is_available(appConfig, feature_flags.flags.SecondaryActivity.flag_name, feature_flags.flags.SecondaryActivity.attributes.extended_price_chart, email) ? { company_id: id, last_N_day: 1095 } : { company_id: id }
        // });
        this.fetchGraphData()
        getCompanyVerticals({ qs: { company_id: id } });
        getSimilarCompany({ qs: { company_id: id } });
        getPermissionedDeal({ company_id: id });


        getVciHistoricalRevenue({ company_id: id });
        getVciCapTable({ company_id: id });
        getVciFundingRound({ company_id: id });
        

        document.addEventListener('visibilitychange', this.handleWindowVisibilitychange);
    }

    componentDidUpdate(prevProps, prevState){
        if(prevProps.appConfig !== this.props.appConfig){
            this.fetchGraphData()
        }
    }
    componentWillUnmount() {
        document.removeEventListener('visibilitychange', this.handleWindowVisibilitychange);
    }

    handleWindowVisibilitychange = (evt) => {
        const { ValidateUserInBackground } = this.props;
        if (document.visibilityState === 'visible') {
            const accessToken = GetToken();
            if (accessToken && accessToken.token && accessToken.email && accessToken.set_time) {
                accessToken['currentUserType'] = content_placeholders.user_type.orderbook_user
                ValidateUserInBackground(accessToken);
            }
        }
    }

    fetchGraphData = () =>{
        const {
            getHistoricalSecondaryPricing,
            getReferencePricing,
            match: { params: { id } },
            appConfig,
            loginUserData: { email }
        } = this.props;
        if(appConfig === undefined || appConfig.length == 0){
            return
        }
        //console.log( feature_flags.feature_is_available(appConfig, feature_flags.flags.SecondaryActivity.flag_name, feature_flags.flags.SecondaryActivity.attributes.extended_price_chart, email) )
        getHistoricalSecondaryPricing({
            root_path: 'v2/orderbook',
            sub_path: 'weighted-prices',
            company_identifier: '',
            qs: feature_flags.feature_is_available(this.props.appConfig, feature_flags.flags.SecondaryActivity.flag_name, feature_flags.flags.SecondaryActivity.attributes.extended_price_chart, email) ? { company_id: id, last_N_day: 1095 } : { company_id: id }
        });
        getReferencePricing({
            root_path: 'v2/orderbook',
            sub_path: 'reference-prices',
            company_identifier: '',
            qs: feature_flags.feature_is_available(this.props.appConfig, feature_flags.flags.SecondaryActivity.flag_name, feature_flags.flags.SecondaryActivity.attributes.extended_price_chart, email) ? { company_id: id, last_N_day: 1095 } : { company_id: id }
        });
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const { live_offers, live_demand, permission_deal, company_detail } = nextProps;
        // console.log( permission_deal )
        if (
            company_detail.id != prevState.company_id
        ) {
            const bids = live_demand.resultList.map(item => item.bid_price);
            const asks = live_offers.resultList.map(item => item.bid_price);
            const orders = bids.concat(asks);
            const payload = {
                longest_digit: getLongestDigit(orders),
                primary_activity_deals: [],
                secondary_activity_deals: [],
                company_id: company_detail.id,
            }

            if (permission_deal.offers && permission_deal.offers.length) {
                const { offers } = permission_deal;
                const primary_activity_deals = offers.filter(item => item.is_primary);
                const secondary_activity_deals = offers.filter(item => !item.is_primary);

                payload['primary_activity_deals'] = primary_activity_deals;
                payload['secondary_activity_deals'] = secondary_activity_deals;
            }

            return payload;
        } else if (
            permission_deal.company_id &&
            permission_deal.company_id != prevState.permission_deal.company_id) {
            const payload = {
                primary_activity_deals: [],
                secondary_activity_deals: []
            }
            if (permission_deal.offers && permission_deal.offers.length) {
                const { offers } = permission_deal;
                const primary_activity_deals = offers.filter(item => item.is_primary);
                const secondary_activity_deals = offers.filter(item => !item.is_primary);

                payload['primary_activity_deals'] = primary_activity_deals;
                payload['secondary_activity_deals'] = secondary_activity_deals;
            }

            return payload;
        } else {
            return null;
        }
    }

    handleGoBack = (evt) => {
        const { history, match: { params: { id } } } = this.props;
        sendGAEvent({
            type: 'orderbook_go_to_pipeline',
            data: {
                company_id: id
            }
        });

        history.push('/orderbook/pipeline');
        // this.props.history.goBack();
    }

    handleShowDealShareDialog = (evt) => {
        const { match: { params: { id } } } = this.props;
        const { dealSharing } = this.state;
        dealSharing['open'] = !dealSharing['open'];

        if (dealSharing['open']) {
            const { getRiskDisclosure } = this.props;
            getRiskDisclosure({ public_url: false, root_path: 'v2/orderbook', sub_path: 'search', company_identifier: id });
        }

        this.setState({ dealSharing: dealSharing });
    }

    handleAcknowledgeRiskDisclosure = (evt) => {
        const { confirmRiskDisclosure, shareDeal } = this.props;
        confirmRiskDisclosure();
        shareDeal({ company_id: this.props.match.params.id });
    }

    handleConfirmOverwrite = (evt) => {
        this.setState({ show_alert: false, alert: false }, () => {
            const { preserved } = this.state;
            this.handlePrefillPlaceOrder(evt, preserved.type, preserved.data);
        })
    }

    handleCancelOverwrite = (evt) => {
        this.setState({ show_alert: false });
    }

    handleAlertNeeded = (evt) => {
        this.setState({ alert: true });
    }

    handlePriceChartFullScreen = (evt) => {
        const { dialog_open } = this.state;
        this.setState({ dialog_open: !dialog_open });
    }

    handleShowExpressInterest = (evt) => {
        const { match: { params: { id } } } = this.props;
        const { express_interest_modal_open } = this.state;
        sendGAEvent({
            type: 'orderbook_click_show_express_interest',
            data: {
                company_id: id
            }
        });
        this.setState({ express_interest_modal_open: !express_interest_modal_open });
    }

    handleShowRequestInformation = (evt, property, order_detail) => {
        const { request_information } = this.state;
        request_information['open'] = !request_information['open'];
        request_information['prefill_key'] = [property];
        request_information['order_detail'] = order_detail;

        if (order_detail) {
            sendGAEvent({
                type: 'orderbook_click_show_request_info',
                data: {
                    ...order_detail,
                    order_type: order_detail.buy_type == 'buy' ? 'offer' : 'bid'
                }
            });
        }

        this.setState({
            request_information: request_information
        });
    }

    handleShowOrderDetailDialog = (evt, type, data) => {
        const { order_detail } = this.state;
        order_detail['open'] = !order_detail['open'];
        order_detail['data'] = { ...data, buy_type: type };

        sendGAEvent({
            type: 'orderbook_click_show_order_detail',
            data: {
                ...data,
                order_type: type == 'buy' ? 'offer' : 'bid'
            }
        });

        this.setState({ order_detail: order_detail });
    }

    handlePromptExpress = (evt, show) => {
        const { prompt_express } = this.state;
        this.setState({ prompt_express: show });
    }

    handleToggleWatchList = (evt, params) => {
        let { company_id, watchlist } = params;
        const { match: { params: { id } } } = this.props;
        if (evt && evt.stopPropagation) evt.stopPropagation();
        if (evt && evt.preventDefault) evt.preventDefault();
        const watchListDialog = {
            open: true,
            companyId: id,
            active: watchlist,
        }
        this.setState({
            watchListDialog: watchListDialog,
        })
    }

    handleCloseWatchListDialog = (event) => {
        const { watchListDialog } = this.state;
        watchListDialog["open"] = false;
        watchListDialog["companyId"] = 0;
        this.setState({ watchListDialog: watchListDialog });
    }

    handleWatchListStar = (companyId, active) => {
        // let { company_detail } = this.props;
        // company_detail.watchlist = active;
        // this.setState({
        //     company_detail: company_detail,
        // });
    }

    handleSnackbarClose = (evt, reason) => {
        if (reason === "clickaway") {
            return;
        }

        const { clearWatchlistMessage } = this.props;
        clearWatchlistMessage();
    }

    handlePrefillPlaceOrder = (evt, type, data) => {
        const { alert, express_interest_modal_open } = this.state;
        const { match: { params: { id } } } = this.props;
        sendGAEvent({
            type: 'orderbook_click_live_orders',
            data: {
                ...data,
                company_id: id
            }
        });

        if (alert) {
            const preserved = { type: type, data: data }
            this.setState({ show_alert: true, preserved: preserved });
        } else {
            const placeorder = {
                buy_type: type,
                provide_type: 'price',
                order_payload: {
                    price: data.bid_price,
                    size: undefined,
                    valuation: undefined,
                    order_type: 'Indication',
                    is_available_financial_info: data.is_available_financial_info ? true : false,
                    is_rofr_waived: data.is_rofr_waived ? true : false,
                    is_corporate_approval_obtain: data.is_corporate_approval_obtain ? true : false,
                    is_company_sponsored: data.is_company_sponsored ? true : false,
                    held_in_spv: data.is_spv ? true : false,
                    share_class: '',
                    invisible: false,
                    primary: data.is_primary ? true : false,
                    fee_management: undefined,
                    fee_upfront: undefined,
                    fee_carry_interest: undefined,
                    comment: '',
                }
            }
            const self = this;
            this.setState({ placeorder: placeorder }, () => {
                setTimeout(function () {
                    self.setState({ placeorder: {} });
                }, 1000);
            });
        }

        if (!express_interest_modal_open) {
            this.setState({ express_interest_modal_open: true });
        }
    }

    handleShowWatchlistFromSearchCompany = (evt, search_name) => {
        const company_name = search_name.trim() ? search_name.trim() : ''
        if(company_name){
            const { watchListDialog } = this.state;
		    watchListDialog["open"] = true;
		    watchListDialog["companyId"] = null;
            watchListDialog['active'] = false;
            watchListDialog['newCompanyName'] = company_name;
		    this.setState({ watchListDialog: watchListDialog });
        }
    }

    render() {
        const {
            company_detail,
            funding_rounds,
            live_offers,
            live_demand,
            historical_pricing,
            reference_pricing,
            match: { params: { id } },
            collapseSidebar,
            toggle_watchlist,
            company_verticals,
            appConfig,
            loginUserData,
        } = this.props;

        const {
            placeorder,
            show_alert,
            dialog_open,
            express_interest_modal_open,
            request_information,
            prompt_express,
            longest_digit,
            order_detail,
            primary_activity_deals,
            secondary_activity_deals,
            watchListDialog,
            dealSharing,
        } = this.state;

        const home_right_class = collapseSidebar ? 'home-right narrow' : 'home-right';

        const blockchain_vertical = company_verticals.filter(ver => ver && ver.vertical && (ver.vertical.name && ver.vertical.name.indexOf('Blockchain') > -1 || ver.vertical.name && ver.vertical.name.indexOf('Crypto') > -1 || ver.vertical.name && ver.vertical.name.indexOf('Gaming') > -1));

        return (
            <div className="home orderbook">
                <div className="top-padding"></div>
                <Helmet>
                    <title>Orderbook | {content_placeholders.title_prefix}</title>
                </Helmet>
                <PipelineSidebar
                    tabFocusIndex="3"
                    collapse={true}
                    {...this.props}
                />

                <div className={home_right_class} id="home-right">
                    <CompanyContent
                        handleGoBack={this.handleGoBack}
                        handleShowDealShareDialog={this.handleShowDealShareDialog}
                        handleToggleWatchList={this.handleToggleWatchList}
                        handleShowOrderDetailDialog={this.handleShowOrderDetailDialog}
                        handlePriceChartFullScreen={this.handlePriceChartFullScreen}
                        handlePrefillPlaceOrder={this.handlePrefillPlaceOrder}
                        handleShowExpressInterest={this.handleShowExpressInterest}
                        handlePromptExpress={this.handlePromptExpress}
                        handleShowWatchlistFromSearchCompany={this.handleShowWatchlistFromSearchCompany}
                        {...this.props}
                        {...{
                            primary_activity_deals,
                            secondary_activity_deals,
                            longest_digit,
                        }}
                    />
                </div>

                <PlaceOrderPositionWrapper
                    handleShowExpressInterest={this.handleShowExpressInterest}
                    open={express_interest_modal_open}
                    placeorder={placeorder}
                    lock_company={true}
                    popOut={prompt_express}
                    handleAlertNeeded={this.handleAlertNeeded}
                    {...this.props}
                />

                <RequestInformationPositionWrapper
                    handleShowRequestInformation={this.handleShowRequestInformation}
                    open={request_information.open}
                    {...{ ...this.props, request_information }}
                />

                <FullScreenPriceChartDialog
                    open={dialog_open}
                    price_chart_payload={{
                        ...{
                            ...{ company_id: id, blockchain: blockchain_vertical.length },
                            historical_pricing,
                            reference_pricing,
                            funding_rounds,
                            live_offers,
                            live_demand,
                            appConfig,
                            loginUserData,
                        }
                    }}
                    handlePriceChartFullScreen={this.handlePriceChartFullScreen}
                />

                <ConfirmOverwrite
                    handleConfirmOverwrite={this.handleConfirmOverwrite}
                    handleCancelOverwrite={this.handleCancelOverwrite}
                    open={show_alert}
                />

                <OrderDetailDialog
                    handleShowOrderDetailDialog={this.handleShowOrderDetailDialog}
                    handlePrefillPlaceOrder={this.handlePrefillPlaceOrder}
                    handlePromptExpress={this.handlePromptExpress}
                    handleShowRequestInformation={this.handleShowRequestInformation}
                    {...order_detail}
                    {...{
                        company_detail,
                        funding_rounds,
                    }}
                />

                <WatchlistSnackbar
                    handleSnackbarClose={this.handleSnackbarClose}
                    open={toggle_watchlist.message.trim() ? true : false}
                    error={toggle_watchlist.error}
                    message={toggle_watchlist.message}
                />

                {watchListDialog.open ?
                    <WatchListDialog
                        {...watchListDialog}
                        closeDialog={this.handleCloseWatchListDialog}
                        updateStarStatus={this.handleWatchListStar}
                        company_name={company_detail.name}
                        handleShowExpressInterest={this.handleShowExpressInterest}
                    /> : null}

                <DealShareDialog
                    open={dealSharing.open}
                    handleShowDealShareDialog={this.handleShowDealShareDialog}
                    handleAcknowledgeRiskDisclosure={this.handleAcknowledgeRiskDisclosure}
                    handleGoBack={this.handleGoBack}
                    handleToggleWatchList={this.handleToggleWatchList}
                    handleShowOrderDetailDialog={this.handleShowOrderDetailDialog}
                    handlePriceChartFullScreen={this.handlePriceChartFullScreen}
                    handlePrefillPlaceOrder={this.handlePrefillPlaceOrder}
                    handleShowExpressInterest={this.handleShowExpressInterest}
                    handlePromptExpress={this.handlePromptExpress}
                    {...this.props}
                    {...{
                        primary_activity_deals,
                        secondary_activity_deals,
                        longest_digit,
                    }}
                />
            </div>
        )
    }
}

export default Company;
