import { combineReducers } from 'redux';


import {
    getProspectInvestorList,
    postSubmitInvestorApproval,
    clearRefreshData,
} from './Action';


import {
    default as InitialState
} from './InitialState';

export default combineReducers({
    reducer(state=InitialState, action){
        switch( action.type ){
            case getProspectInvestorList.request.toString():
                return Object.assign({}, state, {
                    prospect_loading: true,
                    prospect_investors: {
                        resultCount: 0,
                        resultList: []
                    }
                })
            case getProspectInvestorList.failure.toString():
                return Object.assign({}, state, {
                    prospect_loading: false,
                    prospect_investors: {
                        resultCount: 0,
                        resultList: []
                    }
                });
            case getProspectInvestorList.success.toString():
                const { payload: { qs : offset }, data} = action.payload;
                return Object.assign({}, state, {
                    prospect_loading: false,
                    prospect_investors: {
                        resultCount: data.resultCount,
                        resultList: 
                            offset ? 
                            state.prospect_investors.resultList.concat(data.resultList) : 
                            data.resultList
                    }
                        
                });
            case postSubmitInvestorApproval.success.toString():
                return Object.assign({}, state, {
                    refresh_data: true
                });
            case clearRefreshData.toString():
                return Object.assign({}, state, {
                    refresh_data: false
                })
            default:
                return state;
        }
    }
})