import { connect } from 'react-redux';
import { default as Trends } from './Trends';

import {
    getTrends,
} from './Action';

import {
    CollapseSidebar,
} from '../../root/Action';

export default connect(
    state => ({
        loginUserData: state.login.reducer.loginUserData,
        collapseSidebar: state.root.reducer.collapseSidebar,
        trends: state.wealthTrends.reducer.trends,
    }),
    dispatch => ({
        getTrends: ( payload ) => dispatch(getTrends.request(payload)),
        CollapseSidebar: ( payload ) => dispatch(CollapseSidebar(payload)),
    })
)(Trends);