import React, { Component } from 'react';
import Loading from 'react-loading-bar';
import 'react-loading-bar/dist/index.css'

import { withTheme, ThemeProvider, createTheme, } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import { ApplicationTheme } from '../styles';
import { Header, TempLoader } from '../common';
import { Maintenance } from '../maintenance';
import { 
    PreserveUrl, 
    RetrievePreservedUrl, 
    RemovePreservedUrl, 
    ReadQueryString, 
    RemoveToken,
    PreserveDebug,
    GetToken,
    PreserveEmailCampaign,
} from '../../utils';
import { 
    extractAllowedCompany,
    initializeLiveChat,
    redirectPortalByUserType,
} from '../../utils/util';
import { sendLogEvent } from '../../utils/ga';
// import { flag_to_check } from '../../config/Content';

import {
    content_placeholders,
    feature_flags,
} from '../../config';

import { SessionEnd } from '../sessionEnd';
// import { update } from 'plotly.js';

// import { BiWindows } from 'react-icons/bi';
// import { logs } from '../../api';

class Root extends Component{

    constructor(props){
        super(props);
    }

    componentDidMount(){
        // console.log('Root component did mount');
        const { checkMaintenance, ValidateUser, ClearTempLoader } = this.props;
        const accessToken = GetToken();
        const { location: { pathname, search } } = this.props;
        // console.log( accessToken );
        const qs = ReadQueryString(search);
        
        // const flag_to_check = 'Watchlist,PortfolioPage,OrderList';
        if( accessToken.token && accessToken.set_time ){
            // console.log("Run validate user API.");
            checkMaintenance({});
            if( accessToken && accessToken.email && accessToken.token ){
                if( qs.ua ){
                    const { UpdateMyAccess } = this.props;
                    UpdateMyAccess({ ...accessToken, permission: qs.ua, validate: 1 });
                } else {
                    ValidateUser(accessToken);
                }
            } else {
                ClearTempLoader();
            }
        } else {
            if( pathname && search && search.indexOf('preserve-url') > -1 ){
                // console.log(" Running preserve url. ");
                PreserveUrl({ pathname, search, ...{ set_time: Date.now() } });
            }
            
            if( accessToken && accessToken.token ) RemoveToken("authData");
            setTimeout( function(){
                checkMaintenance({});
                ClearTempLoader();
            }, 1000);
            
        }

        PreserveDebug(pathname, search);
        PreserveEmailCampaign(pathname, search);
        sendLogEvent(false, 'initial_root_componentDidMount', {}, this.props);
    }

    shouldComponentUpdate(nextProps, nextState){
        const {
            location: { pathname, search },
            loginUserData,
            pendingUserState,
            agreementAndPasswordSet,
            appConfig,
        } = nextProps;

        const {
            agreement_needed,
            update_password_needed,
            email,
            accessRight,
            activeAccessRight : { active, access_right : { permission } },
        } = loginUserData;


        const pathname_lowercase = pathname.toLowerCase();

        const user_type = permission;

        const accessRightActive = active ? true : false;

        if( feature_flags.feature_is_available(
            appConfig,
            feature_flags.flags.LiveChat.flag_name,
            feature_flags.flags.LiveChat.attributes.live_chat,
            email
        ) && email ) {
            initializeLiveChat(loginUserData);
        }

        const allowed_company_id = extractAllowedCompany(user_type);

        const beforeLoginExemptionPath = /forgot-password|reset-password|new-password|terms-of-use|privacy-policy|ob-platform-guide|ob-agreement|confidentiality-agreement|risk-disclosure|wp-platform-guide|wp-agreement|campaign/gi;
        const confirmAgreementExemptionPath = /ob-w-platform-guide|terms-of-use|privacy-policy|ob-platform-guide|ob-agreement|wp-platform-guide|wp-agreement/gi;
        
        const singleCompanyExemptionPath = allowed_company_id ? 
            new RegExp(`\/club\/company-detail\/${allowed_company_id}|disclaimer|forgot-password|reset-password|new-password|terms-of-use|privacy-policy`, 'gi')
            : new RegExp(`\/club\/company-detail|disclaimer|forgot-password|reset-password|new-password|terms-of-use|privacy-policy`, 'gi');
        
        const extractCompanyId = /company-detail\/([0-9]*)/gi;
        
        const disclaimerOrLoginPath = /^\/$|disclaimer|campaign/gi;

        const orderbookP1RedirectPath = /\/app\/|\/club\/|\/orderbook\/company-detail\/|\/boost\//gi;
        const orderbookRedirectPath = /^\/$|\/app\/|\/club\/|\/wealth\/|\/boost\//gi;
        const clubRedirectPath = /^\/$|\/app\/|\/orderbook\/|\/wealth\/|\/boost\//gi;
        const wealthRedirectPath = /^\/$|\/app\/|\/orderbook\/|\/club\/|\/boost\//gi;
        const boostRedirectPath = /^\/$|\/app\/|\/orderbook\/|\/wealth\/|\/club\//gi;

        const publicPagePath = /^\/(wealth|orderbook)\/public\//gi;

        const set_time_buffer = 600000;

        if ( (pathname_lowercase != '/'&&
            publicPagePath.exec(pathname_lowercase) == null &&
            beforeLoginExemptionPath.exec(pathname_lowercase) == null
            ) && !email && !pendingUserState ){
                // Redirect to login page
                //  console.log("To login page.");
                if( pathname && search && search.indexOf('preserve-url') > -1 ){
                    PreserveUrl({ pathname, search, ...{ set_time: Date.now() } });
                }
                this.props.history.push('/');
        } else if ( pathname_lowercase != "/disclaimer" &&
            (agreement_needed || update_password_needed) &&
            email &&
            !pendingUserState &&
            !agreementAndPasswordSet &&
            confirmAgreementExemptionPath.exec(pathname_lowercase) == null
            ){
                // console.log("To disclaimer page.")
                // Redirect user to accept agreement and update password
                this.props.history.push('/Disclaimer');
        } else if (
            ( 
                disclaimerOrLoginPath.exec(pathname_lowercase) != null
            ) &&
            !( agreement_needed || update_password_needed ) &&
            email &&
            !pendingUserState &&
            !agreementAndPasswordSet &&
            accessRight.length > 1
        ) {
            if(loginUserData.user_type && 
                loginUserData.user_type == content_placeholders.user_candidate_type.lifetime_candidate &&
                user_type == content_placeholders.user_type.orderbook_user){
                    this.props.history.push('/orderbook/pipeline');
                    return true
                }
            const preservedPayload = RetrievePreservedUrl();
			if( preservedPayload.search ){
                const query_string = ReadQueryString(preservedPayload.search);
                if( query_string['preserve-url'] &&
                    preservedPayload.pathname &&
                    preservedPayload.set_time &&
                    ( Date.now() - preservedPayload.set_time < set_time_buffer ) &&
                    (
                        preservedPayload.pathname.indexOf('/company-detail') > -1
                    )
                ){
                    RemovePreservedUrl();
                    this.props.history.push(redirectPortalByUserType(user_type, preservedPayload.pathname, preservedPayload.search));
                } else if ( pathname_lowercase.indexOf('/company-detail/') > -1 ) {
                    this.props.history.push(redirectPortalByUserType(user_type, pathname, search));
                } else {
                    RemovePreservedUrl();
                    this.props.history.push('/dashboard');
                }
            } else {
                this.props.history.push('/dashboard');
            }
        } else if ( 
            singleCompanyExemptionPath.exec(pathname_lowercase) == null &&
            !(agreement_needed || update_password_needed) &&
            email &&
            !pendingUserState &&
            user_type.indexOf(content_placeholders.user_type.single_company_user) > -1 &&
            accessRightActive 
            ) {
                // console.log("To allowed company page.")
                // Redirect user to view the allowed company profile
                if( allowed_company_id ){
                    this.props.history.push('/club/company-detail/' + allowed_company_id);
                } else {
                    this.props.history.push('/app/Pipeline');
                }

        } else if (  
            ( 
                disclaimerOrLoginPath.exec(pathname_lowercase) != null || 
                orderbookP1RedirectPath.exec(pathname_lowercase) != null 
            ) &&
            !(agreement_needed || update_password_needed) &&
            email &&
            !pendingUserState &&
            user_type == content_placeholders.user_type.orderbook_p1_user &&
            accessRightActive
            ) {
            // console.log("To orderbook page.");
            // Redirect user to orderbook main page
            const preservedPayload = RetrievePreservedUrl();

            if( preservedPayload.search ){
                const query_string = ReadQueryString(preservedPayload.search);
                if( query_string['preserve-url'] &&
                    preservedPayload.pathname &&
                    preservedPayload.set_time &&
                    ( Date.now() - preservedPayload.set_time < set_time_buffer ) &&
                    (
                        preservedPayload.pathname.indexOf('/pipeline') > -1 ||
                        preservedPayload.pathname.indexOf('/company-detail') > -1
                    )
                    ){
                        const extract_result = extractCompanyId.exec(preservedPayload.pathname);

                        if( extract_result.length > 1 ){
                            this.props.history.push(`/orderbook/pipeline?company_id=${extract_result[1]}`);
                        } else {
                            this.props.history.push('/orderbook/pipeline');
                        }
                } 
                // else if ( pathname_lowercase.indexOf('/company-detail/') > -1 ) {
                //     this.props.history.push(redirectPortalByUserType(user_type, pathname, search));
                // } 
                else {
                    this.props.history.push('/orderbook/pipeline');
                }
            } else {
                this.props.history.push('/orderbook/pipeline');
            }
            
        } else if (  
            ( 
                disclaimerOrLoginPath.exec(pathname_lowercase) != null || 
                orderbookRedirectPath.exec(pathname_lowercase) != null 
            ) &&
            !(agreement_needed || update_password_needed) &&
            email &&
            !pendingUserState &&
            user_type == content_placeholders.user_type.orderbook_user &&
            accessRightActive
            ) {
            // console.log("To orderbook page.");
            // Redirect user to orderbook
            const preservedPayload = RetrievePreservedUrl();

            if( preservedPayload.search ){
                const query_string = ReadQueryString(preservedPayload.search);
                if( query_string['preserve-url'] &&
                    preservedPayload.pathname &&
                    preservedPayload.set_time &&
                    ( Date.now() - preservedPayload.set_time < set_time_buffer ) &&
                    (
                        preservedPayload.pathname.indexOf('/orderbook/company-detail') > -1
                    )
                    ){
                        RemovePreservedUrl();
                        this.props.history.push(redirectPortalByUserType(user_type, preservedPayload.pathname, preservedPayload.search));
                } else {
                    this.props.history.push('/orderbook/pipeline');
                }
            } else if ( pathname_lowercase.indexOf('/company-detail') > -1 ) {
                this.props.history.push(redirectPortalByUserType(user_type, pathname, search));
            } else {
                this.props.history.push('/orderbook/pipeline');
            }
            
        } else if ( 
            (
                disclaimerOrLoginPath.exec(pathname_lowercase) != null || 
                clubRedirectPath.exec(pathname_lowercase) != null 
            ) &&
            !(agreement_needed || update_password_needed) &&
            email &&
            !pendingUserState &&
            user_type == content_placeholders.user_type.new_version_user &&
            accessRightActive 
            ) {
                // console.log("To club page.")
                // Redirect user to club portal
                const preservedPayload = RetrievePreservedUrl();
				if( preservedPayload.search ){
					const query_string = ReadQueryString(preservedPayload.search);
					if( query_string['preserve-url'] &&
						preservedPayload.pathname &&
						preservedPayload.set_time &&
						( Date.now() - preservedPayload.set_time < set_time_buffer ) &&
						(
							preservedPayload.pathname.indexOf('/club/company-detail') > -1
						)
					){
						RemovePreservedUrl();
                        this.props.history.push(redirectPortalByUserType(user_type, preservedPayload.pathname, preservedPayload.search));
					} else {
						RemovePreservedUrl();
						this.props.history.push('/club/pipeline');
					}
				} else if ( pathname_lowercase.indexOf('/company-detail') > -1 ) {
                    this.props.history.push(redirectPortalByUserType(user_type, pathname, search));
                } else {
                    this.props.history.push('/club/pipeline');
                }

        } else if ( 
            (
                disclaimerOrLoginPath.exec(pathname_lowercase) != null || 
                wealthRedirectPath.exec(pathname_lowercase) != null 
            ) &&
            !(agreement_needed || update_password_needed) &&
            email &&
            !pendingUserState &&
            user_type == content_placeholders.user_type.wealth_user &&
            accessRightActive 
            ) {
                // console.log("To wealth page.")
                // Redirect user to wealth portal
                const preservedPayload = RetrievePreservedUrl();
				if( preservedPayload.search ){
					const query_string = ReadQueryString(preservedPayload.search);
					if( query_string['preserve-url'] &&
						preservedPayload.pathname &&
						preservedPayload.set_time &&
						( Date.now() - preservedPayload.set_time < set_time_buffer ) &&
						(
							preservedPayload.pathname.indexOf('/wealth/company-detail') > -1
						)
					){
						RemovePreservedUrl();
						window.location.href=redirectPortalByUserType(user_type, preservedPayload.pathname, preservedPayload.search);
					} else if ( pathname_lowercase.indexOf('/company-detail/') > -1 ) {
                        this.props.history.push(redirectPortalByUserType(user_type, pathname, search));
                    } else {
						RemovePreservedUrl();
						this.props.history.push('/wealth/pipeline');
					}
				} else {
                    this.props.history.push('/wealth/pipeline');
                }
        } else if (
            (
                boostRedirectPath.exec(pathname_lowercase) != null
            ) &&
            !(agreement_needed || update_password_needed) &&
            email &&
            !pendingUserState &&
            user_type == content_placeholders.user_type.boost_user &&
            accessRightActive
        ){
            // console.log("To boost page.")
            // Redirect user to boost portal
            const preservedPayload = RetrievePreservedUrl();
            if( preservedPayload.search ){
                const query_string = ReadQueryString(preservedPayload.search);
                if( query_string['preserve-url'] &&
                    preservedPayload.pathname &&
                    preservedPayload.set_time &&
                    ( Date.now() - preservedPayload.set_time < set_time_buffer ) &&
                    (
                        preservedPayload.pathname.indexOf('/boost/pipline') > -1 ||
                        preservedPayload.pathname.indexOf('/boost/dashboard') > -1 ||
                        preservedPayload.pathname.indexOf('/boost/vetting') > -1
                    )
                ){
                    RemovePreservedUrl();
                    window.location.href=preservedPayload.pathname+preservedPayload.search;
                } else {
                    RemovePreservedUrl();
                    this.props.history.push('/boost/pipeline');
                }
            } else {
                this.props.history.push('/boost/pipeline');
            }
        } else if ( ( pathname_lowercase == '/' || pathname_lowercase.indexOf('/app/') > -1 ) &&
            !(agreement_needed || update_password_needed) &&
            email &&
            !pendingUserState &&
            user_type == content_placeholders.user_type.trial_user &&
            accessRightActive 
            ) {
                // Redirect trial user to club portal - trial version
                const preservedPayload = RetrievePreservedUrl();
				if( preservedPayload.search ){
					const query_string = ReadQueryString(preservedPayload.search);

					if( query_string['preserve-url'] &&
						preservedPayload.pathname &&
						preservedPayload.set_time &&
						( Date.now() - preservedPayload.set_time < set_time_buffer ) &&
						(
							preservedPayload.pathname.indexOf('/club/pipline') > -1 ||
							preservedPayload.pathname.indexOf('/club/company-detail') > -1
						)
					){
						RemovePreservedUrl();
						window.location.href=preservedPayload.pathname+preservedPayload.search;
					} else {
						RemovePreservedUrl();
						this.props.history.push('/club/pipeline');
					}
				} else {
                    this.props.history.push('/club/pipeline');
                }

        } else if (
            ( pathname_lowercase == '/' || pathname_lowercase.indexOf('/club/') > -1 ) &&
            !(agreement_needed || update_password_needed) &&
            email &&
            !pendingUserState &&
            user_type == content_placeholders.user_type.old_version_user ) {
                // console.log("To old pipeline page.")
                this.props.history.push('/app/Pipeline');
        }

        return true;
    }

    render(){
        const {
            location: { pathname }, loaderState, maintenance, pendingUserState,
            loginUserData: { activeAccessRight: { active, access_right: { permission } }, 
            agreement_needed, update_password_needed, }
        } = this.props;
        const pathname_lowercase = pathname.toLowerCase();
        let user_type = active && permission ? permission : 'INSTITUTIONAL_CLIENT';
        if( user_type.indexOf('SINGLE_COMPANY') > -1 ) user_type = "CLUB_CLIENT";
        
        const noHeaderPath = /ob-w-platform-guide|forgot-password|reset-password|new-password|disclaimer|terms-of-use|privacy-policy|confidentiality-agreement|risk-disclosure|ob-platform-guide|ob-agreement|wp-platform-guide|wp-agreement|campaign/gi;

        return (
            <ThemeProvider theme={createTheme(ApplicationTheme('blueCyanTheme', 'light'))}>
                {
                    parseInt(maintenance) ? <Maintenance /> :

                        pendingUserState ? <TempLoader />:
                        <div className={user_type}>
                            <Loading
                                show={loaderState}
                                size='50'
                                color='#F00'
                                showSpinner={false}
                                />
                            {
                                (() => {
                                    if(
                                        
                                        pathname_lowercase !== "/" &&
                                        noHeaderPath.exec( pathname_lowercase ) == null 
                                    ) return <Header {...this.props} />
                                })()
                            }
                            {this.props.children}
                        </div>

                }
                <SessionEnd />
            </ThemeProvider>
        )
    }
}

export default withRouter(Root);
