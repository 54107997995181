
import {
    numberWithCommas,
    ReScaleForDeviceScale,
    getImpliedVal,
    roundNumber,
    getLatestFundingRound,
    displayVal,
} from '../../../../utils/util';

import { sendGAEvent } from '../../../../utils/ga';

import {
    content_placeholders,
} from '../../../../config';

export const getOption = (state, props) => {
    const {
        average_bid,
        average_offer,
        highest_bid,
        lowest_offer,
        count_bid,
        count_offer,
        bitcoin_price,
        ether_price,
        max_percent,
        bid_size,
        offer_size,
        date,
        average_bid_visual_map,
        average_offer_visual_map,
        highest_bid_visual_map,
        lowest_offer_visual_map,
        show_latest_funding_rounds,
        latest_funding_rounds,
        latest_funding_rounds_val,
        lfr_visual_map,
        price_chart_type,
        price_line_type,
        bar_type,
        show_ref_price,

        average_bid_change,
        average_offer_change,
        highest_bid_change,
        lowest_offer_change,

        average_bid_change_visual_map,
        average_offer_change_visual_map,
        highest_bid_change_visual_map,
        lowest_offer_change_visual_map,

        reference_prices,
        
    } = state;

    // console.log( JSON.stringify(state) );
    // console.log( reference_prices );

    const {
        hidefullscreen,
        company_id, 
        token,
        blockchain,
    } = props;

    const option = {
        legend: {
            show: true,
            left: 'left',
            width: '100%',
            align: 'left',
            left: hidefullscreen ? '10%' : 55,
            data: []
            // orient: 'vertical'
        },
        dataZoom: [
            {
                type: 'slider',
                show: true,
                xAxisIndex: [0,1],
            }
        ],
        xAxis: [
            {
                type: 'category',
                boundaryGap: false,
                data: date,
                nameTextStyle: {
                    fontFamily: 'Roboto',
                    fontSize: 12
                },
                axisLabel: {
                    fontFamily: 'Roboto',
                    fontSize: 12,
                    rich: {
                        fontFamily: 'Roboto',
                        fontSize: 12
                    }
                }
            },
            {
                type: 'category',
                boundaryGap: false,
                data: date,
                gridIndex: 1,
                nameTextStyle: {
                    fontFamily: 'Roboto',
                    fontSize: 12
                },
                axisLabel: {
                    fontFamily: 'Roboto',
                    fontSize: 12,
                    rich: {
                        fontFamily: 'Roboto',
                        fontSize: 12
                    }
                }
            }
        ],
        grid: [
            {
                bottom: '60%'
            },
            {
                top: '60%'
            }
        ],
        yAxis: [
            {
                type: 'value',
                name: price_chart_type=='actual' ? 'Price per share' : '% Change',
                nameTextStyle: {
                    fontFamily: 'Roboto',
                    fontSize: 12
                },
                axisLabel: {
                    formatter: price_chart_type=='actual' ? '${value} ' : '{value}%',
                    fontFamily: 'Roboto',
                    fontSize: 12,
                    rich: {
                        fontFamily: 'Roboto',
                        fontSize: 12
                    }
                },
                axisPointer: {
                    snap: true
                }
            },
            {
                type: 'value',
                name: bar_type=='notional' ? 'Notional ($M)' : 'Order Count',
                interval: 1,
                gridIndex: 1,
                splitNumber: 500,
                nameTextStyle: {
                    fontFamily: 'Roboto',
                    fontSize: 12
                },
                axisLabel: {
                    formatter: '{value} ',
                    fontFamily: 'Roboto',
                    fontSize: 12,
                    rich: {
                        fontFamily: 'Roboto',
                        fontSize: 12
                    },
                    hideOverlap: true
                },
                axisPointer: {
                    snap: true
                },
            },
        ],
        tooltip: {
            trigger: 'axis',
            
            axisPointer: {
              type: 'cross'
            },
            formatter: function(params){
                // console.log( params );

                const line_items = params.filter(item => item.componentSubType == 'line');
                const bar_items = params.filter(item => item.componentSubType == 'bar');
                // console.log( line_items );
                if( line_items.length || bar_items.length ){

                    const lfr_val = latest_funding_rounds_val.length ? latest_funding_rounds_val[latest_funding_rounds_val.length-1] : null;

                    const axisValue = line_items.length ? line_items[0]['axisValue'] : bar_items[0]['axisValue'];
                    sendGAEvent({
                        type: line_items.length ? 'orderbook_view_price_chart' : 'orderbook_view_volume_chart',
                        data: {
                            company_id: company_id,
                            token: token,
                            view_time: axisValue,
                        }
                    });

                    let label = '<div class="chart-tooltip">';
                    label += line_items.length ? `<strong>${axisValue}</strong><br />` : bar_items.length ? `<strong>${axisValue}</strong><br />` : '';
                    label += '<table cellspacing="0" cellpadding="0" border="0">';
                    if( line_items.length ){
                        label += '<tr>';
                        label += `<th></th><th>${price_chart_type=='actual'?'Price per share':'% Change'}</th>`
                        if(price_chart_type=='actual') label += `<th>Implied valuation</th>`;
                        else label += `<th></th>`;
                        label += '</tr>';

                    }
                    
                    for( let ii=0; ii<line_items.length; ii++ ){
                        label += '<tr>';
                        label += '<td>';
                        label += `<span class="name-label" style="display: inline-block; min-width: 120px">`;
                        label += `${line_items[ii]['marker']} ${line_items[ii]['seriesName']} `
                        label += `${line_items[ii]['color'].indexOf(',0.2') > -1 ? '(Not live)' : ''}`;
                        label += `</span>`;
                        label += '</td><td>';
                        label += line_items[ii]['componentSubType'] != 'bar' ? `<span class="price-label">${price_chart_type=='actual' ? '$' : ''}${numberWithCommas(line_items[ii]['value'])}${price_chart_type=='actual' ? '' : '%'}</span>` : `<span class="price-label">${line_items[ii]['value']}</span>`
                        label += '</td><td>';

                        if(
                            latest_funding_rounds.length && 
                            latest_funding_rounds_val.length && 
                            latest_funding_rounds[latest_funding_rounds.length-1] && 
                            latest_funding_rounds_val[latest_funding_rounds_val.length-1] && 
                            line_items[ii]['seriesName'].indexOf('LFR') == -1 &&
                            line_items[ii]['componentSubType'] != 'bar' &&
                            price_chart_type == 'actual'
                        ){
                            label += `<span class="price-label">${getImpliedVal(line_items[ii]['value'], latest_funding_rounds[latest_funding_rounds.length-1], latest_funding_rounds_val[latest_funding_rounds_val.length-1], 1)}</span>`
                        } else if(line_items[ii]['seriesName'].indexOf('LFR') > -1){
                            label += `<span class="price-label">${displayVal(lfr_val,1,1)}</span>`
                        }
                        label += '</td>';
                        label += '</tr>';
                    }

                    for( let iii=0; iii<bar_items.length; iii++ ){
                        label += `<span class="count-label" style="display: inline-block; margin-right: 10px;">${bar_items[iii]['marker']} ${bar_items[iii]['seriesName']}: ${bar_items[iii]['value']}</span>`
                    }
                    label += '</div>';
                    return label;
                } else {
                    return '';
                }
                
            }
        },
        graphic: {
            type: 'group',
            width: '100%',
            height: '100%',
            children: []
        }
    }

    if( price_chart_type=='percent' ){
        option['yAxis'][0]['min'] = roundNumber(max_percent*-1.1,1);
        option['yAxis'][0]['max'] = roundNumber(max_percent*1.1,1);
    }

    const graphics = [
        {
            type: 'circle',
            id: 'bidcount',
            left: hidefullscreen ? '10%' : 55,
            top: hidefullscreen ? '50%' : 265,
            shape: {
                r: 7,
            },
            style: {
                fill: 'rgba(69,97,168,0.8)'
            }
        },
        {
            type: 'text',
            id: 'bidcounttext',
            left: hidefullscreen ? '12%' : 75,
            top: hidefullscreen ? '50.5%' : 267,
            style: {
                text: bar_type=='notional' ? 'Bid Size' : 'Bid Count',
                fill: 'rgba(69,97,168,0.8)'
            }
        },
        {
            type: 'circle',
            id: 'offercount',
            left: hidefullscreen ? '17%' : 140,
            top: hidefullscreen ? '50%' : 265,
            shape: {
                r: 7,
            },
            style: {
                fill: 'rgba(252,163,16,0.8)'
            }
        },
        {
            type: 'text',
            id: 'offercounttext',
            left: hidefullscreen ? '19%' : 160,
            top: hidefullscreen ? '50.5%' : 267,
            style: {
                text: bar_type=='notional' ? 'Offer Size' : 'Offer Count',
                fill: 'rgba(252,163,16,0.8)'
            }
        },
    ];

    if( !hidefullscreen ){
        option['tooltip']['position'] = function (point, params, dom, rect, size) {
            // console.log( point );
            if( Array.isArray(point) && point.length > 1 && point[1] > 280 ) {
                return ['60%', '50%']
            } else {
                return ['10%', '-40%'];
            }

        } 
    } else {
        // option['tooltip']['position'] = ['65%', '-30%'];
        option['tooltip']['position'] = function (point, params, dom, rect, size) {
            // console.log( point );
            if( Array.isArray(point) && point.length > 1 && point[1] > 350 ) {
                return ['65%', '50%']
            } else {
                return ['65%', '-30%'];
            }
        }

        graphics.push(
            {
                type: 'image',
                left: '85%',
                top: 0,
                style: {
                    image: content_placeholders.logos.gateway,
                    width: 52,
                    height: 55,
                }
            }
        );

    }

    const visual_map = [];
        const series = [];
        const legend_data = [];

        if( price_line_type == "average_price" ){
            visual_map.push({
                show: false,
                dimension: 0,
                seriesIndex: 0,
                pieces: price_chart_type=='actual' ? average_bid_visual_map : average_bid_change_visual_map
            });

            series.push({
                name: 'Ave. Bid', 
                type: 'line', 
                data: price_chart_type=='actual' ? average_bid : average_bid_change
            });

            visual_map.push({
                show: false,
                dimension: 0,
                seriesIndex: 1,
                pieces: price_chart_type=='actual' ? average_offer_visual_map : average_offer_change_visual_map
            });

            series.push({
                name: 'Ave. Offer',
                type: 'line',
                data: price_chart_type=='actual' ? average_offer : average_offer_change
            });

            legend_data.push({
                name: 'Ave. Bid',
                icon: 'circle',
                itemStyle: {
                    color: 'rgba(69,97,168,0.8)'
                },
                textStyle: {
                    fontFamily: "'Roboto', sans-serif",
                    color: 'rgba(69,97,168,0.8)',
                }
            });

            legend_data.push({
                name: 'Ave. Offer',
                icon: 'circle',
                itemStyle: {
                    color: 'rgba(252,163,16,0.8)'
                },
                textStyle: {
                    fontFamily: "'Roboto', sans-serif",
                    color: 'rgba(252,163,16,0.8)',
                }
            });


        } else {
            visual_map.push({
                show: false,
                dimension: 0,
                seriesIndex: 0,
                pieces: price_chart_type=='actual' ? highest_bid_visual_map : highest_bid_change_visual_map
            });

            series.push({
                name: 'Highest Bid',
                type: 'line',
                data: price_chart_type=='actual' ? highest_bid : highest_bid_change
            });

            visual_map.push({
                show: false,
                dimension: 0,
                seriesIndex: 1,
                pieces: price_chart_type=='actual' ? lowest_offer_visual_map : lowest_offer_change_visual_map
            });

            series.push({
                name: 'Lowest Offer',
                type: 'line',
                data: price_chart_type=='actual' ? lowest_offer : lowest_offer_change
            });

            legend_data.push({
                name: 'Highest Bid',
                icon: 'circle',
                itemStyle: {
                    color: 'rgba(69,97,168,0.8)'
                },
                textStyle: {
                    fontFamily: "'Roboto', sans-serif",
                    color: 'rgba(69,97,168,0.8)'
                }
            });

            legend_data.push({
                name: 'Lowest Offer',
                icon: 'circle',
                itemStyle: {
                    color: 'rgba(252,163,16,0.8)'
                },
                textStyle: {
                    fontFamily: "'Roboto', sans-serif",
                    color: 'rgba(252,163,16,0.8)'
                }
            });


        }

        if( show_latest_funding_rounds ){
            visual_map.push({
                show: false,
                dimension: 0,
                seriesIndex: 2,
                pieces: lfr_visual_map
            });

            series.push({
                name: 'LFR price',
                type: 'line',
                lineStyle: {
                    type: 'dashed'
                },
                label: {
                    formatter: '{a} <sup>(4)</sup>'
                },
                data: latest_funding_rounds,
            });

            legend_data.push({
                name: 'LFR price',
                icon: 'circle',
                itemStyle: {
                    color: 'rgba(34,139,34,1)'
                },
                textStyle: {
                    color: 'rgba(34,139,34,1)'
                }
            });

        }

        if( show_ref_price && price_chart_type=='percent' ){

            if( blockchain ){
                series.push({
                    name: 'Bitcoin Price',
                    type: 'line',
                    // yAxisIndex: 2,
                    lineStyle: {
                        type: 'dashed'
                    },
                    data: bitcoin_price
                });
    
                series.push({
                    name: 'Ether Price',
                    type: 'line',
                    // yAxisIndex: 2,
                    lineStyle: {
                        type: 'dashed'
                    },
                    data: ether_price
                });
            }
            
            const colors = ['rgba(27,59,89,1)', 'rgba(127,38,72,1)', 'rgba(89,27,50,1)', 'rgba(127,119,38,1)'];
            // console.log( colors );
            let counter = 0;
            for( var key in reference_prices ){
                series.push({
                    name: key,
                    type: 'line',
                    data: reference_prices[key],
                    itemStyle: {
                        color: colors[counter],
                        borderColor: colors[counter],
                    },
                    lineStyle: {
                        type: 'dashed',
                        color: colors[counter]
                    },
                });

                legend_data.push({
                    name: key,
                    icon: 'circle',
                    itemStyle: {
                        color: colors[counter],
                        borderColor: colors[counter],
                    },
                    lineStyle: {
                        color: colors[counter]
                    },
                    textStyle: {
                        fontFamily: "'Roboto', sans-serif",
                        color: colors[counter]
                    }
                });

                counter++;
            }

        }

        if( bar_type=='notional' ){
            
            series.push({
                name: 'Bid Size',
                type: 'bar',
                yAxisIndex: 1,
                xAxisIndex: 1,
                data: bid_size,
                itemStyle: {
                    color: 'rgba(69,97,168,0.8)'
                }
            });

            series.push({
                name: 'Offer Size',
                type: 'bar',
                yAxisIndex: 1,
                xAxisIndex: 1,
                data: offer_size,
                itemStyle: {
                    color: 'rgba(252,163,16,0.8)'
                }
            })

        } else {
            series.push({
                name: 'Bid Count',
                type: 'bar',
                yAxisIndex: 1,
                xAxisIndex: 1,
                data: count_bid,
                itemStyle: {
                    color: 'rgba(69,97,168,0.8)'
                },
            });
    
            series.push({
                name: 'Offer Count',
                type: 'bar',
                yAxisIndex: 1,
                xAxisIndex: 1,
                data: count_offer,
                itemStyle: {
                    color: 'rgba(252,163,16,0.8)'
                },
            });
        }

        

        // legend_data.push({
        //     name: 'Bid Count',
        //     icon: 'circle',
        //     itemStyle: {
        //         color: 'rgba(7, 160, 230, 1)'
        //     },
        //     textStyle: {
        //         fontFamily: "'Roboto', sans-serif",
        //         color: 'rgba(7, 160, 230, 1)'
        //     },
        //     yAxisIndex: 1,
        //     xAxisIndex: 1,
        // });

        // legend_data.push({
        //     name: 'Offer Count',
        //     icon: 'circle',
        //     itemStyle: {
        //         color: 'rgba(46, 65, 112, 1)'
        //     },
        //     textStyle: {
        //         fontFamily: "'Roboto', sans-serif",
        //         color: 'rgba(46, 65, 112, 1)'
        //     },
        //     yAxisIndex: 1,
        //     xAxisIndex: 1,
        // })
        

        option['visualMap'] = visual_map;
        option['series'] = series;
        option['legend']['data'] = legend_data;
        option['graphic']['children'] = graphics;

        return option;
}