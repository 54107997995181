import { connect } from 'react-redux';
import { default as SubmitPortfolio } from './SubmitPortfolio';

import {
    postSubmitPortfolio,
    getSearchCompany,
} from './Action';

export default connect(
    state => ({
        search_company_loading: state.submitPortfolio.reducer.search_company_loading,
        searchCompanyResponse: state.submitPortfolio.reducer.searchCompanyResponse,
        submitPortfolioResponse: state.submitPortfolio.reducer.submitPortfolioResponse,
    }),
    dispatch => ({
        postSubmitPortfolio: (payload) => dispatch(postSubmitPortfolio.request(payload)),
        getSearchCompany: (payload) => dispatch(getSearchCompany.request(payload)),
    })
)(SubmitPortfolio);