import {
    put,
    call,
    takeLatest,
    all,
    delay,
} from 'redux-saga/effects';

import { Unauthorized, ValidateUserInBackground } from '../../login/Action';

import {
    getVerticalsList,
    getGeographyList,
    getOrderbookView,
    getMoreOrderbook,
    getOpportunityHighlight,
    getCompanyNameById,
    getLiveOfferCount,
    getLiveBidCount,
    getPermissionedDeals,
    toggleWatchList,
    updateUserType,
    getDealMetrics,
} from './Action';

import { ShowLoader, HideLoader } from "../../root/Action";

import {
    companies,
    club_pipeline,
    orderbook,
    watch_list,
    auth,
} from '../../../api';

import {
    GetToken,
    NewRelicError
} from '../../../utils';
  
import {
    logEventInternallyAndNewRelic
} from '../../../utils/ga';

import {
    compileQueryString,
    handleCatchSagaError,
} from '../../../utils/util';
import { 
    content_placeholders,
} from "../../../config";

function* getVerticalsListResponder({ payload }){
    try{
        const { data } = yield call(orderbook.getVerticalCat, payload);
        yield put(getVerticalsList.success(data));
    } catch(e){
        if( e.message && e.response ){
            const err_payload = {
                type: 'api_failure_orderbookgetVerticalsListResponder',
                data: {
                    config: e.response.config,
                    data: e.response.data,
                    status: e.response.status
                },
                err: new Error("API Failure orderbookgetVerticalsListResponder: " + e.message)
            }
            logEventInternallyAndNewRelic(err_payload);
        } else if (e.message) {
            NewRelicError( new Error("API Failure orderbookgetVerticalsListResponder: " + e.message) );
        } else {
            NewRelicError( new Error("API Failure orderbookgetVerticalsListResponder with no message") );
        }
        if (e.response.status == 401) {
          yield put(Unauthorized(e));
        }
        yield put(getVerticalsList.failure(e));
    } finally{

    }
}

function* getGeographyListResponder({ payload }){
    try{
        const { data } = yield call(club_pipeline.getRegion, payload);
        yield put(getGeographyList.success(data));
    } catch(e){
        if( e.message && e.response ){
            const err_payload = {
                type: 'api_failure_orderbookgetGeographyListResponder',
                data: {
                    config: e.response.config,
                    data: e.response.data,
                    status: e.response.status
                },
                err: new Error("API Failure orderbookgetGeographyListResponder: " + e.message)
            }
            logEventInternallyAndNewRelic(err_payload);
        } else if (e.message) {
            NewRelicError( new Error("API Failure orderbookgetGeographyListResponder: " + e.message) );
        } else {
            NewRelicError( new Error("API Failure orderbookgetGeographyListResponder with no message") );
        }
        if (e.response.status == 401) {
          yield put(Unauthorized(e));
        }
        yield put(getVerticalsList.failure(e));
    } finally{

    }
}

function* getOrderbookViewResponder({ payload }){
    try{
        yield put(ShowLoader(true));
        let query_string = "?";
        let counter = 0;
        if( payload.qs ){
            for( var key in payload.qs ){
                if( counter ) query_string += "&";
                query_string += key+"="+payload['qs'][key];
                counter++;
            }
        }
        const { data } = yield call(companies.getOrderbookView, query_string, payload);
        yield put(getOrderbookView.success(data));
    } catch(e){
        if( e.message && e.response ){
            const err_payload = {
                type: 'api_failure_orderbookgetOrderbookViewResponder',
                data: {
                    config: e.response.config,
                    data: e.response.data,
                    status: e.response.status
                },
                err: new Error("API Failure orderbookgetOrderbookViewResponder: " + e.message)
            }
            logEventInternallyAndNewRelic(err_payload);
        } else if (e.message) {
            NewRelicError( new Error("API Failure orderbookgetOrderbookViewResponder: " + e.message) );
        } else {
            NewRelicError( new Error("API Failure orderbookgetOrderbookViewResponder with no message") );
        }
        if (e.response.status == 401) {
          yield put(Unauthorized(e));
        }
        yield put(getOrderbookView.failure(e));
    } finally{
        yield put(HideLoader(true));
    }
}


function* getOpportunityHighlightResponder({ payload }){
    try{
        let query_string = "?";
        let counter = 0;
        if( payload.qs ){
            for( var key in payload.qs ){
                if( counter ) query_string += "&";
                query_string += key+"="+payload['qs'][key];
                counter++;
            }
        }
        const { data } = yield call(companies.getOrderbookView, query_string, payload);
        yield put(getOpportunityHighlight.success(data));
    } catch(e){
        if( e.message && e.response ){
            const err_payload = {
                type: 'api_failure_orderbookgetOpportunityHighlightResponder',
                data: {
                    config: e.response.config,
                    data: e.response.data,
                    status: e.response.status
                },
                err: new Error("API Failure orderbookgetOpportunityHighlightResponder: " + e.message)
            }
            logEventInternallyAndNewRelic(err_payload);
        } else if (e.message) {
            NewRelicError( new Error("API Failure orderbookgetOpportunityHighlightResponder: " + e.message) );
        } else {
            NewRelicError( new Error("API Failure orderbookgetOpportunityHighlightResponder with no message") );
        }
        if (e.response.status == 401) {
          yield put(Unauthorized(e));
        }
        yield put(getOpportunityHighlight.failure(e));
    } finally{

    }
}

function* getMoreOrderbookResponder({ payload }){
    try{
        let query_string = "?";
        let counter = 0;
        if( payload.qs ){
            for( var key in payload.qs ){
                if( counter ) query_string += "&";
                query_string += key+"="+payload['qs'][key];
                counter++;
            }
        }
        const { data } = yield call(companies.getOrderbookView, query_string, payload);
        yield put(getMoreOrderbook.success(data));
    } catch(e){
        if( e.message && e.response ){
            const err_payload = {
                type: 'api_failure_orderbookgetMoreOrderbookResponder',
                data: {
                    config: e.response.config,
                    data: e.response.data,
                    status: e.response.status
                },
                err: new Error("API Failure orderbookgetMoreOrderbookResponder: " + e.message)
            }
            logEventInternallyAndNewRelic(err_payload);
        } else if (e.message) {
            NewRelicError( new Error("API Failure orderbookgetMoreOrderbookResponder: " + e.message) );
        } else {
            NewRelicError( new Error("API Failure orderbookgetMoreOrderbookResponder with no message") );
        }
        if (e.response.status == 401) {
          yield put(Unauthorized(e));
        }
        yield put(getMoreOrderbook.failure(e));
    } finally{

    }
}

function* getCompanyNameByIdResponder({ payload }){
    try{
        const { data } = yield call(companies.getAllCompanies, payload.company_id, payload);
        yield put(getCompanyNameById.success(data));
    } catch(e){
        if( e.message && e.response ){
            const err_payload = {
                type: 'api_failure_orderbookgetCompanyNameByIdResponder',
                data: {
                    config: e.response.config,
                    data: e.response.data,
                    status: e.response.status
                },
                err: new Error("API Failure orderbookgetCompanyNameByIdResponder: " + e.message)
            }
            logEventInternallyAndNewRelic(err_payload);
        } else if (e.message) {
            NewRelicError( new Error("API Failure orderbookgetCompanyNameByIdResponder: " + e.message) );
        } else {
            NewRelicError( new Error("API Failure orderbookgetCompanyNameByIdResponder with no message") );
        }
        if (e.response.status == 401) {
            yield put(Unauthorized(e));
        }
        yield put(getCompanyNameById.failure(e));
    } finally{

    }
}

function* getLiveOfferCountResponder({ }){
    try{
        const { data } = yield call(orderbook.getLiveOfferCount);
        yield put(getLiveOfferCount.success(data));
    } catch(e){
        if( e.message && e.response ){
            const err_payload = {
                type: 'api_failure_orderbookgetLiveOfferCountResponder',
                data: {
                    config: e.response.config,
                    data: e.response.data,
                    status: e.response.status
                },
                err: new Error("API Failure orderbookgetLiveOfferCountResponder: " + e.message)
            }
            logEventInternallyAndNewRelic(err_payload);
        } else if (e.message) {
            NewRelicError( new Error("API Failure orderbookgetLiveOfferCountResponder: " + e.message) );
        } else {
            NewRelicError( new Error("API Failure orderbookgetLiveOfferCountResponder with no message") );
        }
        if (e.response.status == 401) {
            yield put(Unauthorized(e));
        }
        yield put(getLiveOfferCount.failure(e));
    } finally{

    }
}

function* getLiveBidCountResponder({ }){
    try{
        const { data } = yield call(orderbook.getLiveBidCount);
        yield put(getLiveBidCount.success(data));
    } catch(e){
        if( e.message && e.response ){
            const err_payload = {
                type: 'api_failure_orderbookgetLiveBidCountResponder',
                data: {
                    config: e.response.config,
                    data: e.response.data,
                    status: e.response.status
                },
                err: new Error("API Failure orderbookgetLiveBidCountResponder: " + e.message)
            }
            logEventInternallyAndNewRelic(err_payload);
        } else if (e.message) {
            NewRelicError( new Error("API Failure orderbookgetLiveBidCountResponder: " + e.message) );
        } else {
            NewRelicError( new Error("API Failure orderbookgetLiveBidCountResponder with no message") );
        }
        if (e.response.status == 401) {
            yield put(Unauthorized(e));
        }
        yield put(getLiveBidCount.failure(e));
    } finally{

    }
}

function* toggleWatchListResponder({ payload }){
    try{        
        const { data } = yield call(watch_list.toggleWatchList, payload);        
        yield put(toggleWatchList.success(data));
    } catch(e) {
        if( e.message && e.response ){
            const err_payload = {
                type: 'api_failure_orderbookToggleWatchlistResponder',
                data: {
                    config: e.response.config,
                    data: e.response.data,
                    status: e.response.status
                },
                err: new Error(`api_failure_orderbookToggleWatchlistResponder: ${e.message}`)
            }
            logEventInternallyAndNewRelic(err_payload);
        } else if (e.message){
            NewRelicError( new Error("API Failure orderbookToggleWatchlistResponder: " + e.message));
        } else {
            NewRelicError( new Error("API Failure orderbookToggleWatchlistResponder with no message"));
        }
        if (e.response.status == 401){
            yield put(Unauthorized(e));
        }
        yield put(toggleWatchList.failure(e));
    } finally {

    }
}

function* getPermissionedDealsResponder({ payload }){
    try{
        const { data } = yield call(orderbook.getPermissionedDeals, payload);
        yield put( getPermissionedDeals.success(data) );
    } catch(e){
        yield handleCatchSagaError(e, 'orderbookgetPermissionedDealsResponder');
        yield put(getPermissionedDeals.failure(e));
    } finally{

    }
}

function* updateUserTypeResponse({payload}){
    try{
        const { data } = yield call(auth.UpdateUserType);
        if(data.update){
            const accessToken = GetToken();
            if( accessToken && accessToken.token && accessToken.email && accessToken.set_time ){
                accessToken['currentUserType'] = content_placeholders.user_type.orderbook_user
                yield put(ValidateUserInBackground.request(accessToken));
            }
        }
        yield delay(3000)
        yield put( updateUserType.success(data) );
    }catch(e){
        console.log(e)
        yield handleCatchSagaError(e, 'updateUserTypeResponse');
        yield put(updateUserType.failure(e));
    }finally{

    }
}

function* getDealMetricsResponse({ payload }){
    try{
        const { data } = yield call(orderbook.getDealMetrics, payload);
        yield put(getDealMetrics.success(data));
    } catch(e){
        if( e.message && e.response ){
            const err_payload = {
                type: 'api_failure_orderbookgetDealMetricsResponse',
                data: {
                    config: e.response.config,
                    data: e.response.data,
                    status: e.response.status
                },
                err: new Error("API Failure orderbookgetDealMetricsResponse: " + e.message)
            }
            logEventInternallyAndNewRelic(err_payload);
        } else if (e.message) {
            NewRelicError( new Error("API Failure orderbookgetDealMetricsResponse: " + e.message) );
        } else {
            NewRelicError( new Error("API Failure orderbookgetDealMetricsResponse with no message") );
        }
        if (e.response.status == 401) {
          yield put(Unauthorized(e));
        }
        yield put(getDealMetrics.failure(e));
    } finally{

    }
}

function* saga(){
    yield all([
        takeLatest(getVerticalsList.request, getVerticalsListResponder),
        takeLatest(getGeographyList.request, getGeographyListResponder),
        takeLatest(getOrderbookView.request, getOrderbookViewResponder),
        takeLatest(getMoreOrderbook.request, getMoreOrderbookResponder),
        takeLatest(getOpportunityHighlight.request, getOpportunityHighlightResponder),
        takeLatest(getCompanyNameById.request, getCompanyNameByIdResponder),
        takeLatest(getLiveOfferCount.request, getLiveOfferCountResponder),
        takeLatest(getLiveBidCount.request, getLiveBidCountResponder),
        takeLatest(toggleWatchList.request, toggleWatchListResponder),
        takeLatest(getPermissionedDeals.request, getPermissionedDealsResponder),
        takeLatest(updateUserType.request, updateUserTypeResponse),
        takeLatest(getDealMetrics.request, getDealMetricsResponse),
    ])
}

export default saga;