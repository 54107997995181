import {
    put,
    takeLatest,
    all,
    fork,
    call
} from 'redux-saga/effects';

import {
    confirmPasswordChange
} from './Action';

import {
    auth
} from '../../api';

function* confirmPasswordChangeResponder({ payload }){
    try{
        const { data } = yield call(auth.confirmPasswordChange, payload);
        yield put(confirmPasswordChange.success(data));
    } catch(e){
        yield put(confirmPasswordChange.failure(e));
    } finally {

    }
}

function* saga(){
    yield all([
        takeLatest(confirmPasswordChange.request, confirmPasswordChangeResponder),
    ])
}

export default saga;