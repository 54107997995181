export default {
    sort_type: '',
    sort_order: '',
    geographyResponse: [],
    verticalsListResponse: [],
    valuation: {
        min: 100,
        max: 30000,
    },
    orderbook: {
        resultCount: 0,
        resultList: []
    },
    opportunity_highlight: {
        resultCount: 0,
        resultList: []
    },
    orderbook_loading: false,
    company: {},
    live_offer_count: {
        cat_list: []
    },
    live_bid_count: {
        cat_list: []
    },
    toggle_watchlist: {
        error: false,
        message: '',
        item_count: 0,
        upTime: 3000 
    },
    permissioned_deals: [],
    showCampaignDialog: false,
    dealMetricsResponse: {},
}