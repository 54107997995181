import { CreateApiAction as _createApiAction } from '../../utils'

const key = 'COMPANIES';
const CreateApiAction = _createApiAction(key);

export const getAllCompanies = CreateApiAction('GET_ALL_COMPANIES');

export const getCompanyInvestors = CreateApiAction('GET_COMPANY_INVESTORS');
export const getVerticals = CreateApiAction('GET_VERTICALS');
export const getFundingRound = CreateApiAction('GET_FUNDING_ROUND')
export const getLatestFundingRound = CreateApiAction('GET_LATEST_FUNDING_ROUND');
