import { CreateApiAction as _createApiAction } from '../../../utils'

const key = 'CLUB_COMPANIES';
const CreateApiAction = _createApiAction(key);

export const getAllCompanies = CreateApiAction('GET_ALL_COMPANIES');

export const getCompanyInvestors = CreateApiAction('GET_COMPANY_INVESTORS');
export const getVerticals = CreateApiAction('GET_VERTICALS');
export const getFundingRound = CreateApiAction('GET_FUNDING_ROUND')
export const getLatestFundingRound = CreateApiAction('GET_LATEST_FUNDING_ROUND');

export const getClubDetail = CreateApiAction('GET_CLUB_DETAIL');
export const getDealLinks = CreateApiAction('GET_DEAL_LINKS');
export const getDealTimeline = CreateApiAction('GET_DEAL_TIMELINE');
export const getEmployee = CreateApiAction('GET_EMPLOYEE');
export const getPublicNewsArticle = CreateApiAction('GET_PUBLIC_NEWS_ARTICLE');
export const getHistoricalPrice = CreateApiAction('GET_HISTORICAL_PRICE');
export const toggleWatchList = CreateApiAction('TOGGLE_WATCH_LIST');

export const RequestOnboarding = CreateApiAction('REQUEST_ONBOARDING');

export const getSimilarCompanies = CreateApiAction('GET_SIMILAR_COMPANIES');
// export const postReferral = CreateApiAction('POST_REFERRAL');