// import * as React from 'react';
import { connect } from "react-redux";

import {
	logoutWithEmail,
	createUserMessage,
	getNotifications,
	markNotificationAllRead,
	markNotificationAllUnRead,
	updateNotificationPreferences,
} from "./Action";

import {
	getWatchlistGroup,
} from '../watchlist/Action';
import { confirmPasswordChange, resetSnackBar } from "../../account/Action";

import { GetPiAgreement, GetConfidentialityAgreement } from "./../../login/Action";

import { showHideCampaignPopUp } from '../../orderbook/pipeline/Action';

import { default as Header } from "./Header";

export default connect(
	(state) => ({
		loginUserData: state.login.reducer.loginUserData,
		showSnakbar: state.account.reducer.showSnakbar,
		passwordError: state.account.reducer.passwordError,
		responseMsg: state.account.reducer.responseMsg,
		pi_content: state.login.reducer.pi_content,
		notificationsResponse: state.account.reducer.notificationsResponse,
		showWatchList: state.clubPipeline.reducer.showWatchList,
		ca_content: state.login.reducer.ca_content,
		pendingUserState: state.login.reducer.pendingUserState,
		updateNotificationPreferencesResponse: state.header.reducer.updateNotificationPreferencesResponse,
		// referralError: state.clubPipeline.reducer.referralError,
		// referralResponseMessage: state.clubPipeline.reducer.referralResponseMessage,
	}),
	(dispatch) => ({
		logoutWithEmail: (payload) => dispatch(logoutWithEmail.request(payload)),
		confirmPasswordChange: (payload) =>
			dispatch(confirmPasswordChange.request(payload)),
		GetPiAgreement: (payload) => dispatch(GetPiAgreement.request(payload)),
		createUserMessage: (payload) =>
			dispatch(createUserMessage.request(payload)),
		resetSnackBar: () => dispatch(resetSnackBar()),
		getNotifications: (payload) => dispatch(getNotifications.request(payload)),
		markNotificationAllRead: (payload) =>
			dispatch(markNotificationAllRead.request(payload)),
		markNotificationAllUnRead: (payload) =>
			dispatch(markNotificationAllUnRead.request(payload)),
		GetConfidentialityAgreement: (payload) =>
			dispatch(GetConfidentialityAgreement.request(payload)),
		updateNotificationPreferences: (payload) =>
			dispatch(updateNotificationPreferences.request(payload)),
		getWatchlistGroup: (payload) => dispatch(getWatchlistGroup.request(payload)),
		showHideCampaignPopUp: (payload) => dispatch(showHideCampaignPopUp(payload)),
	})
)(Header);
