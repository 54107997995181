import { 
    put,
    takeLatest, 
    call,
    all,
 } from 'redux-saga/effects';

import {
    getFundraisingsByUser,
} from './Action';

import {
    ShowLoader,
    HideLoader,
} from '../../root/Action';

import {
    boost,
} from '../../../api';

import {
    handleCatchSagaError,
} from '../../../utils/util';

function* getFundraisingsByUserResponder({ payload }){
    try{
        yield put(ShowLoader(true));
        const { data } = yield call(boost.getFundraisingsByUser, payload);
        yield put(getFundraisingsByUser.success(data));
    } catch(e){
        yield handleCatchSagaError(e, 'getFundraisingsByUserResponder');
        yield put(getFundraisingsByUser.failure(e));
    } finally{
        yield put(HideLoader(true));
    }
}

function* saga(){
    yield all([
        takeLatest(getFundraisingsByUser.request, getFundraisingsByUserResponder)
    ])
}

export default saga;