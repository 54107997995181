import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import TagManager from 'react-gtm-module';

import App from './App';
import * as serviceWorker from './serviceWorker';
import { createStore } from './config/Store';
import * as prototype from './prototype';


const tagManagerArgs = {
	gtmId: window.tag_manager_key
}

TagManager.initialize(tagManagerArgs);

const store = createStore(true);

ReactDOM.render(
	<React.StrictMode>
		<Provider store={store}>
			<App />
		</Provider>
	</React.StrictMode>,
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
