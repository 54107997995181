import {
    put,
    takeLatest,
    all,
    fork,
    call
} from 'redux-saga/effects';

import {
    ConfirmPasswordReset
} from './Action';

import {
    auth
} from '../../../api';

function* ConfirmPasswordResetResponder({ payload }){
    try{

        const { data } = yield call(auth.ConfirmPasswordReset, payload);
        yield put(ConfirmPasswordReset.success(data));
    } catch(e){
        yield put(ConfirmPasswordReset.failure(e));
    } finally {

    }
}

function* saga(){
    yield all([
        takeLatest(ConfirmPasswordReset.request, ConfirmPasswordResetResponder)
    ])
}

export default saga;