import { combineReducers } from 'redux';

import { 
    SignInWithEmailAndPassword, 
    ValidateUser, 
    Unauthorized, 
    GetPiAgreement, 
    ClearTempLoader,
    SetAgreementAndPassword,
    GetTermsOfUse,
    GetPrivacyPolicy,
    GetConfidentialityAgreement,
    SetAgreementFalse,
    GetPlatformGuide,
    GetOrderbookAgreement,
    GetWealthPlatformGuide,
    GetWealthAgreement,
    GetOBWealthPlatformGuide,
    GetOBWealthAgreement,
    ValidateUserInBackground,
} from './Action';

import { logoutWithEmail } from '../common/header/Action';
// import { createReducer } from 'redux-act';
import { default as InitialState } from './InitialState';
// import { content_placeholders } from '../../config';
import { 
    getActiveAccessRight,
} from '../../utils/util';

export default combineReducers({

    reducer(state=InitialState, action){
        
        switch( action.type ){
            case SignInWithEmailAndPassword.success.toString():
                return Object.assign({}, state, {
                    // getPasscodeResponse: true,
                    loginUserData: getActiveAccessRight(action.payload.user),
                    loginError: false,
                    disclaimer: true,
                    updatePassword: true
                });
            case ValidateUser.request.toString():
                return Object.assign({}, state, {
                    pendingUserState: true
                });
            case ValidateUser.success.toString():
                return Object.assign({}, state, {
                    // getPasscodeResponse: true,
                    pendingUserState: false,
                    loginUserData: getActiveAccessRight(action.payload),
                });
            case SignInWithEmailAndPassword.failure.toString():
                let error_response = '';
                for( var key in action.payload.response.data ){
                    if( key == 'password' ){
                        error_response += " Password: " + action.payload.response.data[key];
                    } else if ( key == "non_field_errors" ){
                        error_response += " " + action.payload.response.data[key];
                    }
                }
                return Object.assign({}, state, {
                    showSnackbarInLogin: !( state.showSnackbarInLogin ),
                    loginError:true,
                    loginResponse: error_response,
                    disclaimer: true,
                    updatePassword:true
                });
            case Unauthorized.toString():
                return Object.assign({}, state, {
                    pendingUserState: false,
                    showSnackbarInLogin: !(state.showSnackbarInLogin),
                    loginUserData: {
                        activeAccessRight: {
                            access_right: {
                                active: true,
                                permission: ''
                            }
                        },
                        accessRight: [
                            {
                                access_right: { 
                                    active: true,
                                    permission: '' 
                                }
                            }
                        ]
                    },
                    loginError:true,
                    loginResponse: 'Your previous login session has expired. Kindly login again to continue.'
                });
            case ValidateUser.failure.toString():
                // console.log( action.payload );
                return Object.assign({}, state, {
                    pendingUserState: false,
                    showSnackbarInLogin: !(state.showSnackbarInLogin),
                    loginUserData: {
                        activeAccessRight: {
                            access_right: {
                                active: true,
                                permission: ''
                            }
                        },
                        accessRight: [
                            {
                                access_right: { 
                                    active: true,
                                    permission: '' 
                                }
                            }
                        ]
                    },
                    loginError:true,
                    loginResponse: action.payload.response.data && action.payload.response.data.detail ? 
                        action.payload.response.data.detail : 
                        action.payload.response.data
                });
            case ValidateUserInBackground.request.toString():
            case ValidateUserInBackground.failure.toString():
                return state;
            case ValidateUserInBackground.success.toString():
                return Object.assign({}, state, {
                    loginUserData: getActiveAccessRight(action.payload),
                });
            case ClearTempLoader.toString():
                return Object.assign({}, state, {
                    pendingUserState: false
                });
            case logoutWithEmail.success.toString():
            case logoutWithEmail.failure.toString():
                return Object.assign({}, state, {
                    loginUserData: {
                        activeAccessRight: {
                            access_right: {
                                active: true,
                                permission: ''
                            }
                        },
                        accessRight: [
                            {
                                access_right: { 
                                    active: true,
                                    permission: '' 
                                }
                            }
                        ]
                    }
                })
            case GetPiAgreement.request.toString():
            case GetPiAgreement.failure.toString():
                return Object.assign({}, state, {
                    pi_content: {
                        content: [],
                        subject: ''
                    }
                });
            case GetPiAgreement.success.toString():
                return Object.assign({}, state, {
                    pi_content: action.payload
                });
            case GetTermsOfUse.request.toString():
            case GetTermsOfUse.failure.toString():
                return Object.assign({}, state, {
                    tou_content: {
                        content: [],
                        subject: ''
                    }
                });
            case GetTermsOfUse.success.toString():
                return Object.assign({}, state, {
                    tou_content: action.payload
                });
            case GetPrivacyPolicy.request.toString():
            case GetPrivacyPolicy.failure.toString():
                return Object.assign({}, state, {
                    pp_content: {
                        content: [],
                        subject: ''
                    }
                });
            case GetPrivacyPolicy.success.toString():
                return Object.assign({}, state, {
                    pp_content: action.payload
                });
            case GetConfidentialityAgreement.request.toString():
            case GetConfidentialityAgreement.failure.toString():
                return Object.assign({}, state, {
                    ca_content: {
                        subject: '',
                        content: []
                    }
                });
            case GetConfidentialityAgreement.success.toString():
                return Object.assign({}, state, {
                    ca_content: action.payload
                });
            case GetPlatformGuide.request.toString():
            case GetPlatformGuide.failure.toString():
                return Object.assign({}, state, {
                    pg_content: {
                        subject: '',
                        content: []
                    }
                });
            case GetPlatformGuide.success.toString():
                return Object.assign({}, state, {
                    pg_content: action.payload
                });
            case GetOrderbookAgreement.request.toString():
            case GetOrderbookAgreement.failure.toString():
                return Object.assign({}, state, {
                    oba_content: {
                        subject: '',
                        content: []
                    }
                });
            case GetOrderbookAgreement.success.toString():
                return Object.assign({}, state, {
                    oba_content: action.payload
                });
            case SetAgreementAndPassword.request.toString():
                return Object.assign({}, state, {
                    agreementAndPasswordSet: false
                });
            case SetAgreementAndPassword.success.toString():
                return Object.assign({}, state, {
                    agreementAndPasswordSet: true
                });
            case SetAgreementAndPassword.failure.toString():
                return Object.assign({}, state, {
                    agreementAndPasswordSet: false
                });
            case SetAgreementFalse.success.toString():
                return Object.assign({}, state, {
                    agreementAndPasswordSet: true
                });
            case GetWealthPlatformGuide.request.toString():
            case GetWealthPlatformGuide.failure.toString():
                return Object.assign({}, state, {
                    wpg_content: {
                        content: [],
                        subject: ''
                    }
                });
            case GetWealthPlatformGuide.success.toString():
                return Object.assign({}, state, {
                    wpg_content: action.payload
                })
            case GetWealthAgreement.request.toString():
            case GetWealthAgreement.failure.toString():
                return Object.assign({}, state, {
                    wa_content: {
                        content: [],
                        subject: ''
                    }
                });
            case GetWealthAgreement.success.toString():
                return Object.assign({}, state, {
                    wa_content: action.payload
                });
            case GetOBWealthPlatformGuide.request.toString():
            case GetOBWealthPlatformGuide.failure.toString():
                return Object.assign({}, state, {
                    obwpg_content: {
                        content: [],
                        subject: ''
                    }
                })
            case GetOBWealthPlatformGuide.success.toString():
                return Object.assign({}, state, {
                    obwpg_content: action.payload
                })
            case GetOBWealthAgreement.request.toString():
            case GetOBWealthAgreement.failure.toString():
                return Object.assign({}, state, {
                    obwa_content: {
                        content: [],
                        subject: ''
                    }
                })
            case GetOBWealthAgreement.success.toString():
                return Object.assign({}, state, {
                    obwa_content: action.payload
                })
            default:
                return state;
        }

    }
    
});