import React from 'react';
import {
    default as Base
} from './BaseTooltip';

export default (props) => {
    return <Base 
                {...props}
                items={
                    [
                        'Firm Order: Order for which the buyer / seller has signed a mandate.',
                        // 'Within 30 days: Gateway receives the order within the past 30 days.',
                        // '30 - 60 days: Gateway receives the order between the past 30 - 60 days.',
                    ]
                }
                /> 
}