import { combineReducers } from "redux";

import {
    postPlaceOrder,
    getSearchCompany,
    clearPlaceOrderMsg,
    lockCompany,
    getFundingRound,
} from './Action';

import { default as InitialState } from './InitialState';

export default combineReducers({
    reducer(state=InitialState, action){
        switch( action.type ){
            case postPlaceOrder.request.toString():
                return Object.assign({}, state, {
                    placeorderResponse: {
                        loading: true,
                        error: false,
                        message: ''
                    }
                });
            case clearPlaceOrderMsg.toString():
                return Object.assign({}, state, {
                    placeorderResponse: {
                        loading: false,
                        error: false,
                        message: ''
                    }
                });
            case postPlaceOrder.failure.toString():
                return Object.assign({}, state, {
                    placeorderResponse: {
                        loading: false,
                        error: true,
                        message: 'There are some issue in submitting your order. Please try again later. If the problem persist, kindly contact us.'
                    }
                });
            case postPlaceOrder.success.toString():
                return Object.assign({}, state, {
                    placeorderResponse: {
                        loading: false,
                        error: false,
                        message: 'Order successfully submitted. Your Gateway representative will reach out to you.'
                    }
                });
            case getSearchCompany.request.toString():
                return Object.assign({}, state, {
                    // searchCompanyResponse: {
                    //     resultCount: 0,
                    //     resultList: [],
                    // },
                    searchCompanyResponse: [],
                    search_company_loading: true
                });
            case getSearchCompany.failure.toString():
                return Object.assign({}, state, {
                    // searchCompanyResponse: {
                    //     resultCount: 0,
                    //     resultList: [],
                    // },
                    searchCompanyResponse: [],
                    search_company_loading: false
                });
            case getSearchCompany.success.toString():
                return Object.assign({}, state, {
                    searchCompanyResponse: action.payload,
                    search_company_loading: false
                });
            case lockCompany.toString():
                return Object.assign({}, state, {
                    lock_company: action.payload
                });
            case getFundingRound.request.toString():
            case getFundingRound.failure.toString():
                return Object.assign({}, state, {
                    frs: { count: 0, results: [] }
                });
            case getFundingRound.success.toString():
                return Object.assign({}, state, {
                    frs: action.payload
                });
            default:
                return state;
        }
    }
})