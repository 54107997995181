import React from 'react';

import {
    MenuItem,
    FormControl,
    ListItemText,
    makeStyles,
    Grid,
    OutlinedInput,
    Checkbox,
    Button,
} from '@material-ui/core';

import {
    StyledInputLabel,
    StyledSelect,
} from '../../../orderbook/common/Label';

import {
    SelectPlaceholder
} from '../../../orderbook/common';

import {
    ReScaleForDeviceScale
} from '../../../../utils/util';

import {
    global150BodyFont,
    globalBodyFont,
    globalFontFamily,
} from '../../../styles';

const FormStyle = makeStyles({
    FormControl: {
        '& .MuiListItemText-root': {
            marginTop: 0,
            marginBottom: 0,
            '& .MuiTypography-body1': {
                lineHeight: 1
            }
        }
    },
    ListItemText: {
        '& span': {
            fontSize: ReScaleForDeviceScale(1.5, global150BodyFont, globalBodyFont),
            fontFamily: globalFontFamily,
        }
    },
    Button: {
        fontSize: 8,
        fontFamily: globalFontFamily,
        position: 'absolute',
        top: -20,
        right: -5,
        zIndex: 100,
    },
    OutlinedInput: {
        '& .MuiOutlinedInput-inputMarginDense': {
            paddingTop: '8.5px !important',
            paddingBottom: '8.5px !important',
        }
    }
});



export default (props) => {
    const classes = FormStyle();
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <FormControl variant="outlined" className={classes.FormControl} size="small">
                    {props.selected_options.length ? <Button onClick={() => props.handleResetFilter(props.name)} className={classes.Button}>Clear filter</Button> : ''}
                    <StyledSelect
                        labelId={props.id+"-label"}
                        id={props.id+"-select"}
                        name={props.name}
                        multiple
                        value={props.selected_options}
                        onChange={props.handleSelectChange}
                        displayEmpty
                        renderValue={(selected) => selected.length ? selected.map(item => item.name).join(', ') : <SelectPlaceholder>{props.placeholder}</SelectPlaceholder>}
                        input={<OutlinedInput className={classes.OutlinedInput} notched placeholder={props.placeholder} />}
                    >
                        <MenuItem value="none" disabled>
                            <ListItemText className={classes.ListItemText} primary={props.placeholder} />
                        </MenuItem>
                        {
                            props.options.map((item, index) => {
                                return (
                                    <MenuItem
                                        value={item}
                                        key={index}>
                                        <Checkbox checked={props.selected_options.indexOf(item) > -1} />
                                        <ListItemText className={classes.ListItemText} primary={item.name} />
                                    </MenuItem>
                                )
                            })
                        }
                    </StyledSelect>
                </FormControl>
            </Grid>
        </Grid>
    )
}