import React, { Fragment } from "react";
import {
    Dialog,
    DialogContent,
} from "@material-ui/core";

import { PdfDocument } from '../../../common';

export default (props) => {
    const target_url = props.kyc_documents[props.target.chosen_investor_index];
    const onboarding_panel_class = props.show_onboarding ? "onboarding-panel show" : "onboarding-panel";
    // console.log(target_url)

    return (
        <Dialog
            open={props.open}
            onClose={props.closePopup}
            maxWidth="lg"
            className="onboarding-dialog"
            >
            <DialogContent>
                <div className="title">Request Full Portal Access</div>
                <div className="content">
                    <p>Please confirm your request by selecting your investor type.</p>

                    <select
                        onChange={(e) => props.handleChangeInvestorStatus(e)}
                        className="investor-status-select">
                        {
                            props.investor_statuses.map((item, ii) => {
                                return <option value={item} key={ii}>{item}</option>
                            })
                        }
                    </select>

                    <p>
                        Please note that onboarding with Gateway is a mandatory requirement for access to the Gateway portal.
                        You may refer to our onboarding requirements <span className="btn-show-onboarding" onClick={props.handleShowOnboardingToggle}>here</span>.
                    </p>

                    {
                        props.show_onboarding ?
                            <div className={onboarding_panel_class}>
                                <p>
                                    1. Refer to below note detailing the required KYC documents for individual investors or corporate investors <br />
                                        Proof of funds can be an aggregate of several statements, as long as their dates are within 1 month to one-another (your name and currencies must be clearly stated)
                                </p>

                                <PdfDocument pdf={target_url} />

                                <p>2. Sign NDA and standard Client Agreement via <span className="underline">DocuSign</span></p>
                            </div> : ''
                    }



                    <div className="onboarding-btns">
                        <span onClick={props.closePopup} className="dialog-btn">Cancel</span>
                        <span onClick={props.handleSubmitOnboardingRequest} className="dialog-btn btn-confirm">
                            Confirm
                        </span>
                    </div>

                </div>
            </DialogContent>
        </Dialog>
    )
}
