import React from 'react';

import {
    Dialog,
    DialogContent,
    Slide,
    AppBar,
    Toolbar,
    IconButton,
    withStyles,
} from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';

import {
    default as PriceChart
} from './PriceChart';

const FSPCDialogStyle = theme => ({
    AppBar: {
        background: theme.palette.common.white,
        boxShadow: 'none',
    },
    IconButton: {
        position: 'absolute',
        right: 0,
    }
})

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default withStyles(FSPCDialogStyle)((props) => {
    return (
        <Dialog
            open={props.open}
            fullScreen
            TransitionComponent={Transition}
            >   
                <AppBar className={props.classes.AppBar}>
                    <Toolbar>
                        <IconButton 
                            onClick={(evt) => props.handlePriceChartFullScreen(evt)}
                            className={props.classes.IconButton}>
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <DialogContent>
                    <PriceChart 
                        {...props.price_chart_payload}
                        hidefullscreen={true}
                        />
                </DialogContent>
        </Dialog>
    )
})