import Cookies from 'universal-cookie';
import { default as ReadQueryString } from './ReadQueryString';

export default (pathname, search) => {
    const cookieMaxAge = 600;
    const debug_cookie_key = "gpm_debug";
    const cookies = new Cookies();
    const query_string = ReadQueryString(search);
    // console.log( query_string );

    if( pathname=="/" && query_string && query_string.debug ){
        cookies.set(debug_cookie_key, query_string.debug, { maxAge: cookieMaxAge });
    } else if ( pathname == "/" ){
        cookies.remove(debug_cookie_key, { path: '/' });
    }
}