import { connect } from "react-redux";

import { 
	getWatchlistGroupItem,
	deleteWatchlistGroupItem,
	updateWatchlistGroupItem,
	createWatchlistGroupOnly,
	createWatchlist,
	createWatchlistV2,
	resetCompanyWatchlistData,
	getWatchlistHistoricalPrices,
	getFundingRound,
} from '../Action';
import { default as WatchlistGroup } from "./WatchlistGroup";

export default connect(
	(state) => ({
		watchlistGroupList: state.watchlist.reducer.watchlistGroupList,
		watchlistGroupItem: state.watchlist.reducer.watchlistGroupItem,
		createWatchListError: state.watchlist.reducer.createWatchListError,
		createWatchListMessage: state.watchlist.reducer.createWatchListMessage,
		createWatchListResponse: state.watchlist.reducer.createWatchListResponse,
		appConfig: state.root.reducer.appConfig,
		loginUserData: state.login.reducer.loginUserData,
		historical_pricing: state.watchlist.reducer.historical_pricing,
		funding_rounds: state.watchlist.reducer.funding_rounds,
	}),
	(dispatch) => ({
		getWatchlistGroupItem: (payload) => dispatch(getWatchlistGroupItem.request(payload)),
		deleteWatchlistGroupItem: (payload) => dispatch(deleteWatchlistGroupItem.request(payload)),
		updateWatchlistGroupItem: (payload) => dispatch(updateWatchlistGroupItem.request(payload)),
		createWatchlistGroupOnly: (payload) => dispatch(createWatchlistGroupOnly.request(payload)),
		createWatchlist: (payload) => dispatch(createWatchlist.request(payload)),
		createWatchlistV2: (payload) => dispatch(createWatchlistV2.request(payload)),
		getWatchlistHistoricalPrices: (payload) => dispatch(getWatchlistHistoricalPrices.request(payload)),
		getFundingRound: (payload) => dispatch(getFundingRound.request(payload)),
		resetCompanyWatchlistData: () => dispatch(resetCompanyWatchlistData()),
	})
)(WatchlistGroup);
