import { default as CheckUserType } from './CheckUserType';
import { setGAUserId, sendGAEvent } from './ga';
export default (authData) => {
	// console.log( " Setting cookie. ");
	// console.log( authData );
	

	localStorage.setItem('authData', JSON.stringify({
		token: authData.key,
		id: authData.user.id,
		userProfileId: authData.user.investor_profile ? authData.user.investor_profile : authData.user.seller_profile,
		email: authData.user.email,
		userType: CheckUserType(authData.user),
		firstName: authData.user.first_name,
		lastName: authData.user.last_name,
		set_time: authData.set_time
    }))


    if(authData && authData.user && authData.user.id){
		setGAUserId({
			'user_id': authData.user.id
		})
		sendGAEvent({
			type:"user_login",
			data:{
				auth_user_id: authData.user.id,
                email: authData.user.email,
				firstName: authData.user.first_name,
				lastName: authData.user.last_name,
			}
		})
	}
}