import React from 'react';

import {
    Grid,
    Card,
    Typography,
    makeStyles,
} from '@material-ui/core';

import {
    rearrange_cost_base,
    roundNumber,
    get_average,
} from '../../../../utils/util';

const useStyles = makeStyles((theme) => ({
    container: {},
    SubTitle: {
        fontSize: 12,
        display: 'block'
    },
    Card: {
        padding: 16,
        // marginTop: 16,
        // marginBottom: 16,
    }
}));

export default (props) => {
    const classes = useStyles();

    const new_cost_base = rearrange_cost_base(props.cost_base, props.company_details, props.price_index);
    const sizes = new_cost_base.map(item => item.noOfShares * item.price);
    let mark_up = new_cost_base.map(
                        item => item.price && item.latest_index && item.latest_index.average_bid ? 
                            ( item.latest_index.average_bid - item.price ) / item.price * 100 : 0
                        )
    mark_up = mark_up.filter(item => item);

    let projected_holding = new_cost_base.map(
                        item => item.latest_index && item.latest_index.average_bid ? 
                            item.noOfShares * item.latest_index.average_bid : 
                            item.noOfShares * item.price
                        );
    
    return (
        <React.Fragment>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Card className={classes.Card}>
                        <span className={classes.SubTitle}>No. of companies</span>    
                        <Typography variant="h6">{(Object.keys(props.cost_base)).length}</Typography>
                    </Card>                    
                </Grid>
                <Grid item xs={6}>
                    <Card className={classes.Card}>
                        <span className={classes.SubTitle}>Total Vol. in $M (Entry price)</span>
                        <Typography variant="h6">
                            {
                                roundNumber( sizes.reduce((partialSum, a) => partialSum+a, 0) / 1000000 )
                            }
                        </Typography>
                    </Card>
                </Grid>
                <Grid item xs={6}>
                    <Card className={classes.Card}>
                        <span className={classes.SubTitle}>Overall Potential Gain/Loss</span>
                        <Typography variant="h6">
                            {
                                roundNumber( get_average(mark_up) )
                            }%
                        </Typography>
                    </Card>
                </Grid>
                <Grid item xs={6}>
                    <Card className={classes.Card}>
                        <span className={classes.SubTitle}>Projected Total Vol. in $M</span>
                        <Typography variant="h6">
                            {
                                roundNumber( projected_holding.reduce((partialSum, a) => partialSum+a, 0) / 1000000 )
                            }
                        </Typography>
                    </Card>
                </Grid>
            </Grid>
        </React.Fragment>
    )
}