import { Update } from '@material-ui/icons';
import { combineReducers } from 'redux';

import {
    UpdateMyAccess,
    clearAccessResponsePayload,
} from './Action';

import {
    default as InitialState
} from './InitialState';

export default combineReducers({
    reducer(state=InitialState, action){
        switch( action.type ){
            case UpdateMyAccess.request.toString():
            case UpdateMyAccess.failure.toString():
                return Object.assign({}, state, {
                    accessRightResponse: {}
                });
            case UpdateMyAccess.success.toString():
                return Object.assign({}, state, {
                    accessRightResponse: action.payload
                });
            case clearAccessResponsePayload.toString():
                return Object.assign({}, state, {
                    accessRightResponse: {}
                })
            default:
                return state;
        }
    }
})