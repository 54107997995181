import Cookies from 'universal-cookie';
import { email_campaigns } from '../config';

export default () => {
    const cookies = new Cookies();
    const all_cookies = cookies.getAll();
    
    const campaign_payload = {}

    const utm_keys = email_campaigns.extract_key(all_cookies);

    if( utm_keys.length ){
        utm_keys.map(key => {
            if( cookies.get(key) ){
                campaign_payload[key] = cookies.get(key);
            }
        });
    }

    return campaign_payload;
}