import React from 'react';

import {
    Box,
    Button,
    Dialog,
    DialogContent,
    DialogActions,
    withStyles,
} from '@material-ui/core';

import {
    FormStyle,
} from '../../orderbook/common/FormStyle';

import {
    Snackbar,
} from '../../common';

import blurChartImage from '../../../assets/images/blur_big.png';

const LockedGraphStyle = theme => ({

})

class LockedGraph extends React.Component{
    state = {
        dialog: {
            open: false,
        },
        request_info_payload: {
            company_id: 0,
            interest_type: 'ORDERBOOK_REQUEST',
            comment: {
                min_investment_size_m: false,
                dataroom_access: false,
                financial_info: false,
                share_class: false,
                fee_structure: false,
                timeline: false,
                company_presentation: false,
                management_call: false,
                others: null,
                price_chart: true,
                // company_info: null,
                // gp_info: null,
                offer: null,
                demand: null
            }
        },
        snackBar: {
            open: false,
            error: false,
            message: ''
        }
    }

    constructor(props){
        super(props);
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const { 
            company_name, 
            company_id,
            request_info_response,
        } = nextProps;

        if( company_id && company_id != prevState.request_info_payload.company_id ){
            const request_info_payload = {
                company_id: company_id,
                interest_type: 'ORDERBOOK_REQUEST',
                comment: {
                    min_investment_size_m: false,
                    dataroom_access: false,
                    financial_info: false,
                    share_class: false,
                    fee_structure: false,
                    timeline: false,
                    company_presentation: false,
                    management_call: false,
                    others: null,
                    price_chart: true,
                    // company_info: null,
                    // gp_info: null,
                    offer: null,
                    demand: null
                }
            }

            return { request_info_payload }

        } else if ( 
            !request_info_response.loading &&
            request_info_response.message.trim() &&
            request_info_response.message != prevState.snackBar.message
        ){

            const new_state = {
                snackBar:{
                    open: true,
                    message: request_info_response.message,
                    error: request_info_response.error
                }
            }
            return new_state;

        } else {
            return null;
        }
    }

    handleShowConfirm = (evt, show) => {
        const { dialog } = this.state;
        dialog['open'] = show;
        this.setState({ dialog });
    }

    handleConfirmUnlock = (evt) => {
        const { request_info_payload } = this.state;
        const { requestUnlock } = this.props;

        requestUnlock(request_info_payload);
    }

    handleSnackbarClose = (evt, reason) => {
        if( reason === "clickaway" ){
            return;
        }

        const { clearRequestUnlockMsg } = this.props;
        
        clearRequestUnlockMsg();
        this.setState({
            snackBar: {
                error: false,
                open: false,
                message: ''
            }
        });

        this.handleShowConfirm(evt, false);
    }

    render(){
        const { dialog, snackBar } = this.state;
        const { public_mode, company_detail, company_name } = this.props;

        return (
            <React.Fragment>
                <div style={{ position: 'relative' }}>
                    <img src={blurChartImage} alt="Locked graph" style={{ width: '100%', position: 'relative' }} />
                    <div style={{ 
                        position: 'absolute', 
                        width: 300, 
                        height: 100, 
                        background: '#FFFFFF',
                        top: '50%',
                        left: '50%',
                        margin: '-50px 0 0 -150px',
                        padding: 20,
                        boxShadow: '0 0 5px #CCC',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center'
                        }}>
                        <p style={{ textAlign: 'center' }}>Unlock access to this graph</p>
                        {
                            public_mode ? 
                                <a 
                                    style={{ 
                                        background: 'rgba(252,163,16,0.8)',
                                        maxWidth: 150,
                                        display: 'block'
                                    }}
                                href="https://gatewaypmx.formstack.com/forms/gateway_orderbook_request_form" target="_blank">Get in touch</a> :
                                <Button 
                                    style={{ 
                                        background: 'rgba(252,163,16,0.8)',
                                        maxWidth: 150
                                    }} 
                                    onClick={(evt) => this.handleShowConfirm(evt, true)}>Get in touch</Button>
                        }
                        
                    </div>
                </div>
                <Dialog
                    open={dialog.open}
                    onClose={(evt) => this.handleShowConfirm(evt, false)}
                    >
                        <DialogContent>
                            <p>Unlock my access to {company_name}'s Secondary Activity.</p>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={(evt) => this.handleConfirmUnlock(evt)}>Submit my request</Button>
                        </DialogActions>
                </Dialog>

                <Snackbar
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center'
                    }}
                    openStyle={snackBar.open}
                    handleSnackbarClose={this.handleSnackbarClose}
                    type={snackBar.error ? "error" : 'success'}
                    message={snackBar.message}
                    upTime={6000}
                    />
            </React.Fragment>
            
        )
    }
}

export default withStyles(LockedGraphStyle)(LockedGraph);