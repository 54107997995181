import { connect } from 'react-redux';
import { default as PlaceInterest } from './PlaceInterest';

import {
    postPlaceInterest,
    getSearchCompany,
    clearPlaceInterestMsg,
    clearNoMatch,
} from './Action';

export default connect(
    state => ({
        postPlaceInterestResponse: state.wealthPlaceInterest.reducer.postPlaceInterestResponse,
        searchCompanyResponse: state.wealthPlaceInterest.reducer.searchCompanyResponse,
        search_company_loading: state.wealthPlaceInterest.reducer.search_company_loading,
        no_match: state.wealthPlaceInterest.reducer.no_match,
    }),
    dispatch => ({
        postPlaceInterest: (payload) => dispatch(postPlaceInterest.request(payload)),
        getSearchCompany: (payload) => dispatch(getSearchCompany.request(payload)),
        clearPlaceInterestMsg: () => dispatch(clearPlaceInterestMsg()),
        clearNoMatch: () => dispatch(clearNoMatch()),
    })
)(PlaceInterest);