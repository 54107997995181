import { combineReducers } from 'redux';
import { getPipeline, expressInterest, headquaterCountry, verticals, resetExpressInterest, getAdBanner1 } from './Action';
import { default as InitialState } from './InitialState';

export default combineReducers({
    reducer(state=InitialState, action){
        switch( action.type ){
            case getPipeline.success.toString():
                return Object.assign({}, state, {
                    pipelineResponse: action.payload
                });
            case getPipeline.failure.toString():
                return state;
            case resetExpressInterest.toString():
            case expressInterest.request.toString():
                return Object.assign({}, state, {
                    expressInterestError: false,
                    expressInterestResponseMessage: ''
                })
            case expressInterest.success.toString():
                return Object.assign({}, state, {
                    expressInterestError: false,
                    expressInterestResponseMessage: "Thank you for your interest. Our team will reach out to discuss further."
                })
            case expressInterest.failure.toString():
                return Object.assign({}, state, {
                    expressInterestError: true,
                    expressInterestResponseMessage: "There is an error, please refresh this page and try again."
                })
            case headquaterCountry.success.toString():
                return Object.assign({}, state, {
                    headquarterCountryResponse: action.payload
                });
            case headquaterCountry.failure.toString():
                return state;
            case verticals.success.toString():
                return Object.assign({}, state, {
                    verticalsResponse: action.payload
                });
            case verticals.failure.toString():
                return state;
            case getAdBanner1.success.toString():
                return Object.assign({}, state, {
                    adBanner1Response: action.payload
                });
            case getAdBanner1.failure.toString():
                return Object.assign({}, state, {
                    adBanner1Response: {}
                });
            default:
                return state;
        }
    }
})
