import React, { Fragment } from "react";
// import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { Styles } from '../styles';
import HorTable from "../common/HorTable";
import InputTags from "../common/InputTags";
import loaderImg from '../../assets/images/loader.gif';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { Link } from "react-router-dom";
import { Button, Typography } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';

import { KeyboardArrowDown, ExitToApp } from '@material-ui/icons';
import DisclaimerData from "../common/DisclaimerData";
import { PipelineItem, GatewayBanner, GatewayBannerWithExternalLink } from './components';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import { Snackbar } from '../common';

const discountOptions = [
	{
		"id": 0,
		"name": "40%+ Discount",
		'query_string': 'lt-40',
		'color': 'red'
	},
	{
		"id": 1,
		"name": "20% to 40% Discount",
		'query_string': 'lt-20',
		'color': 'red'
	},
	{
		"id": 2,
		"name": "0 to 20% Discount",
		'query_string': 'lt0',
		'color': 'red'
	},
	{
		"id": 3,
		"name": "0 to 20% Premium",
		'query_string': 'lt20',
		'color': 'green'
	},
	{
		"id": 4,
		"name": "20% to 40% Premium",
		'query_string': 'lt40',
		'color': 'green'
	},
	{
		"id": 5,
		"name": "40%+ Premium",
		'query_string': 'gt40',
		'color': 'green'
	}
]

const totalOptions = [
	{
		"id": 0,
		"name": "<$500mn",
		"query_string": "lt500"
	},
	{
		"id": 1,
		"name": "$500mn - $1Bn",
		"query_string": "gt500"
	}, {
		"id": 2,
		"name": "$1Bn - $10Bn",
		"query_string": "gt1000"
	}, {
		"id": 3,
		"name": ">$10Bn",
		"query_string": "gt10000"
	}
]

class Pipeline extends React.Component {
	state = {
		page: 0,
		rowsPerPage: 100,
		selectedFilterOption: "Company",
		open: false,
		element: {},
		tags: [],
		selectedDP: [],
		selectedTotal: [],
		selectedVertical: [],
		selectedHQCountry: [],
		sortDisc: 0,
		sortComp: 0,
		sortPost: 0,
		filter1: false,
		filter2: false,
		filter3: false,
		filter4: false,
		pipeline_filter_options: ['Company', 'Investor'],//, 'Vertical', 'Headquarter Country'
		showScroll: false,
		selectAllDP: true,
		selectAllHC: true,
		selectAllVT: true,
		selectAllVF: true,

		// State used for express interest resposne
		openStyle: false,
		expressInterestError: false,
		expressInterestResponseMessage: '',

		meta_data: {},
		ad_banners: []
	};

	constructor(props) {
		super(props);
		this.handleCollectParameters = this.handleCollectParameters.bind(this);
		this.handleSearchOptionChange = this.handleSearchOptionChange.bind(this);
		this.handlePipelineFilterChange = this.handlePipelineFilterChange.bind(this);
		this.handleSnackbarClose = this.handleSnackbarClose.bind(this);
		this.handleChangeMeta = this.handleChangeMeta.bind(this);
		this.handleCloseDealDetailPopUp = this.handleCloseDealDetailPopUp.bind(this);
		this.handleBannerExpressInterest = this.handleBannerExpressInterest.bind(this);

		const items = discountOptions.map(item => {
			item.selected = true;
			return item;
		});

		this.state["selectedDP"] = items;

		const t_items = totalOptions.map(item => {
			item.selected = true;
			return item;
		});

		this.state['selectedTotal'] = t_items;
	}

	componentDidMount() {
		const { page, rowsPerPage, selectedTotal, selectedDP } = this.state;
		const { getPipeline, headquaterCountry, verticals, getAdBanner1 } = this.props;

		setTimeout(function(){
			getPipeline({
				limit: rowsPerPage,
				offset: page * rowsPerPage,
				selected: selectedDP,
				selectedTotal: selectedTotal
			});
			headquaterCountry();
			verticals();
			getAdBanner1();
		}, 300);

	}

	handleSearchOptionChange(e) {
		if (e.target.value && e.target.value != this.state.selectedFilterOption) {
			this.setState({ selectedFilterOption: e.target.value.label });
		}
	}

	checkScrollTop = () => {
		const { showScroll } = this.state
		if (!showScroll && window.pageYOffset > 400) {
			this.setState({
				showScroll: true
			})
		} else if (showScroll && window.pageYOffset <= 400) {
			this.setState({
				showScroll: false
			})
		}
	};

	handleToggle(key, value, evt) {

		const newly_selected = this.state[key].map(item => {
			if (item.id == value) item.selected = !item.selected;
			return item;
		});

		this.setState({ [key]: newly_selected });

		const { rowsPerPage, page, tags, selectedDP, selectedTotal, selectedVertical, selectedHQCountry } = this.state;

		this.props.getPipeline({
			limit: rowsPerPage,
			offset: page * rowsPerPage,
			tags: tags,
			selected: selectedDP,
			selectedTotal,
			selectedVertical,
			selectedHQCountry
		});

	}

	pageChange = page => {
		this.setState({ page }, () => this.handlePagination());
	};

	changeRowsPerPage = rowsPerPage => {
		this.setState({ rowsPerPage }, () => this.handlePagination());
	};

	handlePagination = () => {
		const { rowsPerPage, page, tags, selectedDP, selectedTotal, selectedVertical, selectedHQCountry } = this.state;
		this.props.getPipeline({
			limit: rowsPerPage,
			offset: page * rowsPerPage,
			tags: tags,
			selected: selectedDP,
			selectedTotal,
			selectedVertical,
			selectedHQCountry
		});
	};

	handleCollectParameters = (tags) => {
		const { rowsPerPage, page, selectedDP, selectedTotal, selectedVertical, selectedHQCountry } = this.state;
        //   console.log(tags,"tags")
		this.setState(tags);
		this.props.getPipeline({
			limit: rowsPerPage,
			offset: page * rowsPerPage,
			tags: tags.tags,
			selected: selectedDP,
			selectedTotal,
			selectedVertical,
			selectedHQCountry
		});
	}

	changeOpen = (ele) => {
		this.setState({
			open: !this.state.open,
			element: ele
		})
	}

	changeFilter = (value) => {
		if(value==='filter1'){
			this.setState({
				filter1: !this.state.filter1,
			})
		}
		else if(value==='filter2'){
			this.setState({
				filter2: !this.state.filter2,
			})
		}
		else if(value==='filter3'){
			this.setState({
				filter3: !this.state.filter3,
			})
		}
		else if(value==='filter4'){
			this.setState({
				filter4: !this.state.filter4,
			})
		}
	}

	handlePipelineFilterChange(evt, new_value) {
		if (new_value) {
			this.setState({ selectedFilterOption: new_value });
		}
	}

	scrollTop = () => {
		window.scrollTo({ top: 0, behavior: 'smooth' });
	};

	toggleAllCheckbox(allSelectedKey, listKey, new_selected_state, evt){

		const new_list = this.state[listKey].map(item => {
			item.selected = new_selected_state;
			return item
		});

		this.setState({
			[allSelectedKey]: new_selected_state,
			[listKey]: new_list
		});

		const { 
			rowsPerPage, 
			page, 
			tags, 
			selectedDP, 
			selectedTotal, 
			selectedVertical, 
			selectedHQCountry 
		} = this.state;
      
		this.props.getPipeline({
			limit: rowsPerPage,
			offset: page * rowsPerPage,
			tags: tags,
			selected: selectedDP,
			selectedTotal,
			selectedVertical,
			selectedHQCountry
		});

	}

	handleSnackbarClose(evt, reason){
		if (reason === 'clickaway') {
			return;
		}
		this.setState({ openStyle: false });
	}

	handleChangeMeta = (meta_data) => {
		this.setState({ meta_data: meta_data });
	}

	handleCloseDealDetailPopUp(evt){
		this.setState({ meta_data: {} });
	}

	handleBannerExpressInterest(banner_location, company_id, evt){

		if( banner_location.trim() && company_id ){
			const { expressInterest } = this.props;
			expressInterest({
				company: company_id,
				interest_type: 'INVEST',
				comment: "Club Deal banner location: " + banner_location
			});
		}

	}

	static getDerivedStateFromProps(nextProps, prevState){

		const {
			expressInterestError,
			expressInterestResponseMessage,
			headquarterCountryResponse,
			verticalsResponse,
			adBanner1Response
		} = nextProps;

		if(expressInterestResponseMessage != prevState.expressInterestResponseMessage && expressInterestResponseMessage !== undefined){
			const new_open_style = expressInterestResponseMessage.trim() ? true : false;

			return {
				openStyle: new_open_style,
				expressInterestResponseMessage: expressInterestResponseMessage,
				expressInterestError: expressInterestError
			}
		} else if (verticalsResponse && verticalsResponse.length != prevState.selectedVertical.length){
			const vert = verticalsResponse.map((item, ii) => {
				return { id: item.id, name: item.name, selected: true };
			});
			return { selectedVertical: vert };
		} else if (headquarterCountryResponse && headquarterCountryResponse.length != prevState.selectedHQCountry.length) {
			const hq = headquarterCountryResponse.map((item, ii) => {
				return { id: ii, name: item, selected: true };
			});
			return { selectedHQCountry: hq };
		} else if ( adBanner1Response ){
			return { ad_banners: adBanner1Response };
		}
		else {
			return null;
		}

	}

	render() {

		const {
			page,
			rowsPerPage,
			selectedFilterOption,
			selectedDP,
			selectedTotal,
			selectedVertical,
			selectedHQCountry,
			filter1,
			filter2,
			filter3,
			filter4,
			pipeline_filter_options,
			showScroll,
			selectAllDP,
			selectAllHC,
			selectAllVT,
			selectAllVF,
			openStyle,

			expressInterestError,
			expressInterestResponseMessage,

			meta_data,
			ad_banners
		} = this.state;

		const deSelectedDP = selectedDP.filter(item => !item.selected);
		const deSelectedTotal = selectedTotal.filter(item => !item.selected);
		const deSelectedVertical = selectedVertical.filter(item => !item.selected);
		const deSelectedHQCountry = selectedHQCountry.filter(item => !item.selected);

		const {
			pipelineResponse,
			loaderState
		} = this.props;

		const pipeline_response = pipelineResponse && pipelineResponse.results ? pipelineResponse.results : [];

		const live_pipeline_response = pipeline_response.filter(item => item['status'].indexOf('LIVE') > -1);
		const preview_pipeline_response = pipeline_response.filter(item => item['status'].indexOf('LIVE') == -1);

		var today = new Date()
		var dd = String(today.getDate()).padStart(2, '0');
		var mm = String(today.getMonth() + 1).padStart(2, '0');
		var yyyy = today.getFullYear();
		// var subtract = new Date(yyyy, mm, dd)
		// const oneDay = 24 * 60 * 60 * 1000;
		const filter1_class = filter1 ? "height-1" : "height-2";
		const filter2_class = filter2 ? "height-1" : "height-2";
		const filter3_class = filter3 ? "height-1" : "height-2";
		const filter4_class = filter4 ? "height-1" : "height-2";
		window.addEventListener('scroll', this.checkScrollTop );

		const deal_detail_popup_class = meta_data.price_range ? "deal-detail-popup active" : "deal-detail-popup";
		const container_class = meta_data.price_range ? "container header-padding has-popup" : "container header-padding";

		const first_banner = ad_banners.length ? ad_banners[0] : {};
		const second_banner = ad_banners.length > 1 ? ad_banners[ad_banners.length - 1] : {};
		return (
			<Fragment>
				<div className="gateway-notification">
					<div className="container">
						<div className="row">
							<div className="col col-12">
								<h3>New Gateway Portal is now live, please reach out to us to request access <a href="https://www.gatewayprivatemarkets.com/portal/" target="_blank">here</a>.</h3>
							</div>
						</div>
					</div>
				</div>
				{/* <section className={container_class}>
					
					<div className="border-left-blue">
						<Typography color="primary" variant="h4"> The Gateway</Typography>

						{loaderState ? <img src={loaderImg} /> : ""}
						<div className="clear"></div>
					</div>
					{
						first_banner.image_url?
							first_banner.link ?
								<GatewayBannerWithExternalLink { ...first_banner } banner_location={'first-banner'} handleBannerExpressInterest={this.handleBannerExpressInterest} />
							: <GatewayBanner { ...first_banner } banner_location={'first-banner'} handleBannerExpressInterest={this.handleBannerExpressInterest} />
						: ''
					}
					<div className="row mt-3 justified">
						<div className="search-bar margin-top">
							<InputTags keyDownCallback={this.handleCollectParameters} prefix={selectedFilterOption ? selectedFilterOption + ":" : ""} />
						</div>
						<div className=" search-option-container">
							<span>Search Options:</span>
							<div className="no-padding">
								<ToggleButtonGroup
									value={selectedFilterOption}
									exclusive
									onChange={this.handlePipelineFilterChange}
									aria-label="Pipeline filter options"
									className="search-filter-options padding-min"
									>
									{
										pipeline_filter_options.map((item, index) => {
											return (
												<ToggleButton key={index} value={item} className="padding-min">{item}</ToggleButton>
											)
										})
									}
								</ToggleButtonGroup>
							</div>
							<div className="clear"></div>
						</div>
					</div>
					<div className="row clear-space mt-3">
						<div className="col-lg-3 col-md-12 margin-top">
							{deSelectedDP.length ? <Button className="btn-clear-filter" onClick={this.toggleAllCheckbox.bind(this, 'selectAllDP', 'selectedDP', true)}>Clear Filter</Button> : ''}
							<div className="outer-absolute">
								<div className={filter1_class}>
									<div className="align-to-center">
										<Button onClick={()=>{this.changeFilter('filter1')}}><span className="samllTextBlod">&emsp;Disc. / Prem. Filter</span>
											<KeyboardArrowDown />
										</Button>
									</div>
									<div>
										<div className="selection-item">
											&emsp;
											<Checkbox
												onChange={this.toggleAllCheckbox.bind(this, 'selectAllDP', 'selectedDP', !selectAllDP)}
												type="checkbox"
												checked={selectAllDP}>
											</Checkbox>
											{ selectAllDP ? <span>Deselect All</span> : <span>Select All</span> }
											<div className="clear"></div>
										</div>

										{selectedDP.map((value, index) => (
											<div className="selection-item" key={index}>
												&emsp;
												<Checkbox
													onChange={this.handleToggle.bind(this, "selectedDP", value.id)}
													type="checkbox"
													checked={value.selected}>
												</Checkbox>
												<span><font color={value.color}>{value.name}</font></span>
												<div className="clear"></div>
											</div>
										))}
									</div>
								</div>
							</div>
						</div>
						<div className="col-lg-3 col-md-12 margin-top aligning-left selection-filter aligining">
							{deSelectedTotal.length ? <Button className="btn-clear-filter" onClick={this.toggleAllCheckbox.bind(this, 'selectAllVF', 'selectedTotal', true)}>Clear Filter</Button> : ''}
							<div className="outer-absolute">
								<div className={filter2_class}>
									<div className="align-to-center">
										<Button onClick={()=>{this.changeFilter('filter2')}}><span className="samllTextBlod">&emsp;Valuation Filter</span>
											<KeyboardArrowDown />
										</Button>
									</div>

									<div>
										<div className="selection-item">
											&emsp;
											<Checkbox
												onChange={this.toggleAllCheckbox.bind(this, 'selectAllVF', 'selectedTotal', !selectAllVF)}
												type="checkbox"
												checked={selectAllVF}>
											</Checkbox>
											{selectAllVF ? <span>Deselect All</span> : <span>Select All</span>}
											<div className="clear"></div>
										</div>

										{selectedTotal.map((value, index) => (
											<div className="selection-item" key={index}>
												&emsp;
												<Checkbox
													onChange={this.handleToggle.bind(this, "selectedTotal", value.id)}
													type="checkbox"
													checked={value.selected}>
												</Checkbox>
												<span>{value.name}</span>
												<div className="clear"></div>
											</div>
										))}
									</div>

								</div>
							</div>
						</div>
						<div className="col-lg-3 col-md-12 margin-top aligining">
							{deSelectedHQCountry.length ? <Button className="btn-clear-filter" onClick={this.toggleAllCheckbox.bind(this, 'selectAllHC', 'selectedHQCountry', true)}>Clear Filter</Button> : ''}
							<div className="outer-absolute">
								<div className={filter3_class}>
									<div className="align-to-center">
										<Button onClick={()=>{this.changeFilter('filter3')}}><span className="samllTextBlod">&emsp;Headquarter Country</span>
											<KeyboardArrowDown />
										</Button>
									</div>
									<div className="height-3">
										<div>
											<div className="selection-item">
												&emsp;
												<Checkbox
													onChange={this.toggleAllCheckbox.bind(this, 'selectAllHC', 'selectedHQCountry', !selectAllHC)}
													type="checkbox"
													checked={selectAllHC}
													>
												</Checkbox>
												{selectAllHC ? <span>Deselect All</span> : <span>Select All</span>}
												<div className="clear"></div>
											</div>

											{selectedHQCountry.map((value, index) => (
												<div className="selection-item" key={index}>
													&emsp;
													<Checkbox
														onChange={this.handleToggle.bind(this, "selectedHQCountry", value.id)}
														type="checkbox"
														checked={value.selected}
														>
													</Checkbox>
													<span>{value.name}</span>
													<div className="clear"></div>
												</div>
											))}
										</div>

									</div>

								</div>
							</div>
						</div>
						<div className="col-lg-3 col-md-12 margin-top aligining">
							{deSelectedVertical.length ? <Button className="btn-clear-filter" onClick={this.toggleAllCheckbox.bind(this, 'selectAllVT', 'selectedVertical', true)}>Clear Filter</Button> : ''}
							<div className="outer-absolute">
								<div className={filter4_class}>
									<div className="align-to-center">
										<Button onClick={()=>{this.changeFilter('filter4')}}><span className="samllTextBlod">&emsp;Verticals</span>
											<KeyboardArrowDown />
										</Button>
									</div>
									<div className="height-3">
										<div>
											<div className="selection-item">
												&emsp;
												<Checkbox
													onChange={this.toggleAllCheckbox.bind(this, 'selectAllVT', 'selectedVertical', !selectAllVT)}
													type="checkbox"
													checked={selectAllVT}
												>
												</Checkbox>
												{selectAllVT ? <span>Deselect All</span> : <span>Select All</span>}
												<div className="clear"></div>
											</div>

											{selectedVertical.map((value, index) => (
												<div className="selection-item" key={index}>
													&emsp;
													<Checkbox
														onChange={this.handleToggle.bind(this, "selectedVertical", value.id)}
														type="checkbox"
														checked={value.selected}
													>
													</Checkbox>
													<span>{value.name}</span>
													<div className="clear"></div>
												</div>
											))}
										</div>
									</div>

								</div>
							</div>
						</div>
					</div>
					<div className="disclaimer">
						<p className="margin-remove" style={{ marginTop: '20px' }}>All prices are in USD($) and are reflective of transaction price before fees</p>
						<p className="margin-remove">Discount / Premium as compared to verifiable latest funding round</p>
						<p className="margin-remove">^ Estimated based on median price point</p>
						<p className="margin-remove" style={{ marginBottom: '-35px' }}><sup>E</sup> Estimated</p>
					</div>
					<br />
					<HorTable
						count={
							pipelineResponse
								? pipelineResponse && pipelineResponse.count
								: 0
						}
						defaultPerPage={rowsPerPage}
						page={page}
						rowsPerPage={rowsPerPage}
						pageChange={this.pageChange}
						changeRowsPerPage={this.changeRowsPerPage}
						data={[]}
						order={''}
						orderBy={''}
						selected={[]}
						filterText={''}
						columnData={[]} />
					{(pipeline_response.length === 0) ?
						<Typography style={{ margin: '20px' }}
							variant="h6"><div className="centering">No Records Available</div></Typography> :
						<div>
							<div className="row">

								{
									live_pipeline_response.map((ele, ind) => {
										const date_arr = ele['selected_deal_date'] ? ele['selected_deal_date'].split("-") : [];
										let lfr_date;
										if (date_arr.length) {
											lfr_date = new Date(date_arr[0], date_arr[1] - 1, date_arr[2])
										}
										return <PipelineItem
													{...ele}
													key={ind}
													lfr_date={lfr_date}
													expressInterest={this.props.expressInterest}
													handleChangeMeta={this.handleChangeMeta}
													/>;
									})
								}

								{
									preview_pipeline_response.map((ele, ind) => {
										const date_arr = ele['selected_deal_date'] ? ele['selected_deal_date'].split("-") : [];
										let lfr_date;
										if (date_arr.length) {
											lfr_date = new Date(date_arr[0], date_arr[1] - 1, date_arr[2])
										}
										return <PipelineItem
													{...ele}
													key={ind}
													lfr_date={lfr_date}
													expressInterest={this.props.expressInterest}
													handleChangeMeta={this.handleChangeMeta}
													/>;
									})
								}

							</div>
							<ArrowUpwardIcon
								className="scrollTop btn-back-to-top"
								onClick={this.scrollTop}
								style={{ height: 40, display: showScroll ? 'flex' : 'none' }}
								/>
						</div>
					}
					{
						second_banner.image_url ?
							second_banner.link ?
								<GatewayBannerWithExternalLink { ...second_banner } banner_location={'second-banner'} handleBannerExpressInterest={this.handleBannerExpressInterest} />
							: <GatewayBanner { ...second_banner } banner_location={'second-banner'} handleBannerExpressInterest={this.handleBannerExpressInterest} />
						: ''
					}
					<br />
					<DisclaimerData />
					<Snackbar
						anchorOrigin={{
							vertical: 'top',
							horizontal: 'center',
							}}
						openStyle={openStyle}
						handleSnackbarClose={this.handleSnackbarClose}
						type={expressInterestError ? 'error' : 'success'}
						message={expressInterestResponseMessage}
						/>
					<div className={deal_detail_popup_class}>
						<div className="container">
							<div className="row">
								<div className="col col-12">
									<Button className="btn-close" onClick={this.handleCloseDealDetailPopUp}>
										<CloseIcon />
									</Button>
									<div className="clear"></div>
								</div>
							</div>
							<div className="row">
								<div className="col col-4">
									<span className="label">Price Range</span>
								</div>
								<div className="col col-4">
									<span className="label">Disc / Prem^</span>
								</div>
								<div className="col col-4">
									<span className="label">Implied Valuation^</span>
								</div>
							</div>
							<div className="row">
								<div className="col col-4">
									<span>{meta_data.price_range}</span>
								</div>
								<div className="col col-4">
									<span>{meta_data.disc_prem_range}</span>
								</div>
								<div className="col col-4">
									<span>{meta_data.implied_valuation_display}</span>
								</div>
							</div>
						</div>
					</div>
				</section> */}
			</Fragment>
		);
	}
}


export default withStyles(Styles)(Pipeline);
