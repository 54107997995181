import React, { Component } from 'react';

import {
    FormControl,
    Checkbox,
    Button,
    withStyles,
} from '@material-ui/core';

import {
    FormStyle,
} from '../../../../orderbook/common/FormStyle';

const CustomeCheckbox = withStyles({
    root: {
        "&$checked": {
            color: "#fff",
            "& .MuiIconButton-label": {
                position: "relative",
                zIndex: 0
            },
            "& .MuiIconButton-label:after": {
                content: '""',
                left: '-2px',
                top: '6px',
                height: '1rem',
                width: '1rem',
                position: "absolute",
                backgroundColor: "black",
                zIndex: -1
            }
        }
    },
    checked: {}
})((props) => <Checkbox color="default" {...props} />);

class RejectReason extends Component {
    state = {
        check1: false,
        check2: false,
        check3: false,
        otherReason: '',
    }
    constructor(props) {
        super(props);
    }

    handleCheckboxChange1 = (evt) => {
        const { check1 } = this.state;
        this.setState({check1: !check1})
    }
    handleCheckboxChange2 = (evt) => {
        const { check2 } = this.state;
        this.setState({check2: !check2})
    }
    handleCheckboxChange3 = (evt) => {
        const { check3 } = this.state;
        this.setState({check3: !check3})
    }
    handlNewInputChange = (event) => {
		this.setState({
			otherReason: event.target.value
		})
	}
    handleConfirmReasons = () => {
        const reasons = []
        if(this.state.check1){
            reasons.push('Existing relationship with investor / already in discussions with them')
        }
        if(this.state.check2){
            reasons.push('Investor has already declined to participate in this round')
        }
        if(this.state.check3 && this.state.otherReason){
            reasons.push(this.state.otherReason)
        }
        this.props.handleConfirmRejectReason(this.props.index, reasons)
    }
    render() {
        const { check1, check2, check3, otherReason } = this.state;
        return (
            <div>
                <p className="company-dashboard-subtitle">
                    Can you share your reason of why Gateway should not approach this / these investor(s)?
                </p>

                <div style={{ display: 'flex' }}>
                    <FormControl className='company-vetting-checkbox-container' sx={{ m: 1 }} style={{ backgroundColor: '#D9D9D9', width: '28px', height: '28px' }}>
                        <CustomeCheckbox type="checkbox" checked={check1}
                            onChange={this.handleCheckboxChange1}
                        />
                    </FormControl>
                    <p className="company-dashboard-subtitle" style={{ marginLeft: '15px', flex: '1' }}>
                        Existing relationship with investor / already in discussions with them
                    </p>
                </div>

                <div style={{ display: 'flex'}} >
                    <FormControl className='company-vetting-checkbox-container' sx={{ m: 1 }} style={{ backgroundColor: '#D9D9D9', width: '28px', height: '28px' }}>
                        <CustomeCheckbox type="checkbox" checked={check2}
                            onChange={this.handleCheckboxChange2}
                        />
                    </FormControl>
                    <p className="company-dashboard-subtitle" style={{ marginLeft: '15px', flex: '1' }}>
                        Investor has already declined to participate in this round
                    </p>
                </div>

                <div style={{ display: 'flex' , marginBottom: '30px' }}>
                    <FormControl className='company-vetting-checkbox-container' sx={{ m: 1 }} style={{ backgroundColor: '#D9D9D9', width: '28px', height: '28px' }}>
                        <CustomeCheckbox type="checkbox" checked={check3}
                            onChange={this.handleCheckboxChange3}
                        />
                    </FormControl>
                    <div style={{ marginLeft: '15px', flex: '1' }}>
                        <span className="company-dashboard-subtitle" style={{ marginRight: '3px' }}>
                            Others:
                        </span>
                        <input
                            id="others_input"
                            name="othersInput"
                            className="buy-sell-input"
                            disabled={!check3}
                            value={otherReason}
                            onChange={(e) => this.handlNewInputChange(e)}
                        />
                    </div>
                </div>

                <Button
                    className="btn-submit blue-btn"
                    onClick={this.handleConfirmReasons}
                >
                    Confirm
                </Button>
            </div>
        )
    }
}

export default withStyles(FormStyle)(RejectReason);