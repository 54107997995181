import React from 'react';
import { Helmet } from 'react-helmet';
import {
	Grid,
	Typography,
} from '@material-ui/core';


import { content_placeholders } from '../../config';
import FrontPageHeader from './FrontPageHeader';
import { default as ConnectedInvestorLogin } from './ConnectedInvestorLogin';
import { withRouter } from 'react-router-dom';
import PortalBanner from '../../assets/images/PortalBanner.jpg';
import OctagonBg from '../../assets/images/octagon_bg.svg';
import {
	Footer,
} from '../common';

class LoginCampaign extends React.Component {

	constructor(props) {
		super(props);
	}

	componentDidMount() {
		// if(GetToken()){
		// 	this.props.history.push('/app/Pipeline');
		// }
	}

	render() {
		const title = 'Login | ' + content_placeholders.title_prefix;
		return (
			<div>
				<Helmet>
					<title>{title}</title>
					<meta name="description" content={"Gateway Private Markets"} />
				</Helmet>
				<div className="main-page login login-campaign">
					<div className="bg-color2">
						<FrontPageHeader />

						<Grid container spacing={2}>
							<Grid item xs={12} md={8}>
								<Grid container spacing={2}>
									<Grid item xs={0} md={1} style={{ height: 1 }}></Grid>
									<Grid item xs={12} md={11}>
										<Grid container spacing={2}>
											<Grid item xs={12} md={12}>
												<div style={{ paddingTop: 30 }} className="tagline">
													<Typography variant="h4" style={{ color: "black", textTransform: 'none' }}>
														Thank You For Your Continued Support!
													</Typography>
													<p style={{ color: "black", fontWeight: 'bold', marginTop: '10px', marginBottom: '20px',  textTransform: 'none' }}>To Qualify For Your Complimentary Premium Subscription</p>
												</div>
												<Grid item xs={12} md={12} style={{ color: "black", fontWeight: 'bold', marginBottom: '10px' }}>
													<span style={{position: 'relative', textAlign: 'center'}}>
														<img src={OctagonBg} style={{maxWidth: '40px'}}/>
														<p style={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}}>1</p>
													</span>
													<span style={{marginLeft: '20px'}}>Login to the Gateway Orderbook</span>
												</Grid>
												<Grid item xs={12} md={8} style={{marginLeft: '60px', marginBottom: '10px' }}>
													<ConnectedInvestorLogin {...this.props} />
												</Grid>
												<Grid item xs={12} md={12} style={{ color: "black", fontWeight: 'bold', marginBottom: '10px' }}>
													<span style={{position: 'relative', textAlign: 'center'}}>
														<img src={OctagonBg} style={{maxWidth: '40px'}}/>
														<p style={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}}>2</p>
													</span>
													<span style={{marginLeft: '20px'}}>Add 3 Companies to your Watchlist</span>
												</Grid>
												<Grid item xs={12} md={12} style={{ color: "black", fontWeight: 'bold', marginBottom: '10px' }}>
													<span style={{position: 'relative', textAlign: 'center'}}>
														<img src={OctagonBg} style={{maxWidth: '40px'}}/>
														<p style={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}}>3</p>
													</span>
													<span style={{marginLeft: '20px'}}>Start your Price Discovery</span>
												</Grid>
											</Grid>
										</Grid>

										<div>
											<Typography variant="h5" style={{ color: "black", margin: '30px 0' }}>
												Why You Need The Gateway Orderbook
											</Typography>
											<Grid container spacing={2}>
												<Grid container spacing={2} style={{ marginBottom: '10px' }}>
													<Grid item xs={12} md={3} >
														<img src='https://media.gatewayprivatemarkets.com/portal/orderbook/CampaignPicture1.png' style={{ width: '100%' }} />
													</Grid>
													<Grid item xs={12} md={9}>
														<Typography variant="subtitle2" style={{ color: "black", marginBottom: '15px' }}>
															Market Activity Data & Price Discovery
														</Typography>
														<Typography variant="body2" style={{ color: "black" }}>
															Live & historical secondary market data that help you to identify trends and opportunistic entry points, value your holdings, and ensure a fair price for portfolio management.
														</Typography>
													</Grid>
												</Grid>
												<Grid container spacing={2} style={{ marginBottom: '10px' }}>
													<Grid item xs={12} md={3} >
														<img src='https://media.gatewayprivatemarkets.com/portal/orderbook/CampaignPicture2.png' style={{ width: '100%' }} />
													</Grid>
													<Grid item xs={12} md={9}>
														<Typography variant="subtitle2" style={{ color: "black", marginBottom: '15px' }}>
															Marketplace for Deal Access & Liquidity
														</Typography>
														<Typography variant="body2" style={{ color: "black" }}>
															Over $2.5 billion aggregated deal flow in over 100+ global growth to late-stage venture companies, filtered across primary and secondary mandates, transaction formats and terms.
														</Typography>
													</Grid>
												</Grid>
												<Grid container spacing={2} style={{ marginBottom: '10px' }}>
													<Grid item xs={12} md={3} >
														<img src='https://media.gatewayprivatemarkets.com/portal/orderbook/CampaignPicture3.png' style={{ width: '100%' }} />
													</Grid>
													<Grid item xs={12} md={9}>
														<Typography variant="subtitle2" style={{ color: "black", marginBottom: '15px' }}>
															Efficient Market Execution
														</Typography>
														<Typography variant="body2" style={{ color: "black" }}>
															Facilitation of new flows by bridging mismatches in size, price and timing through deep expertise and standardized processes to streamline counterparty qualification, deal structuring and settlement.
														</Typography>
													</Grid>
												</Grid>
											</Grid>
										</div>
									</Grid>
								</Grid>
							</Grid>
							<Grid item xs={12} md={4}>
								<div className="style-container style-container-campaign">
									<div className="inner-style-container">
										<img src={PortalBanner} />
									</div>

									<div className="style-bar-container">
										<div className="third style-bar"></div>
										<div className="primary style-bar"></div>
										<div className="secondary style-bar"></div>
									</div>

								</div>

							</Grid>
						</Grid>

						<Footer />
					</div>
				</div>

			</div>
		);
	}
}

export default withRouter(LoginCampaign);