import React, { Component } from "react";
import {
	Dialog,
	DialogContent,
	Button,
	Grid,
	Box,
	FormLabel,
	TextField,
	Typography,
	Divider,
	Checkbox,
	FormControlLabel,
	FormGroup,
	FormControl,
	withStyles,
} from "@material-ui/core";
import {
	ToggleButtonGroup,
	ToggleButton,
	Autocomplete,
} from '@material-ui/lab';
import {
	sendGAEvent
} from '../../../../utils/ga';
import {
	global150BodyFont,
	globalBodyFont,
	globalFontFamily,
	global150LabelFont,
	globalLabelFont,
} from '../../../styles';

import {
	ReScaleForDeviceScale,
} from '../../../../utils/util';
import {
	StarBorder,
	Star,
} from "@material-ui/icons";
import Tooltip from "@material-ui/core/Tooltip";
import { Snackbar } from "../../index";
import {
	feature_flags,
} from '../../../../config';

import {
	FormStyle
} from '../../../orderbook/common/FormStyle';

class WatchListDialog extends Component {
	state = {
		newWatchlistInput: "",
		companyWatchlist: [],
		companyWatchlistGroupId: [],
		createWatchListGroupMessage: "",
		createWatchListMessage: "",
		showSnackBar: false,
		snackBarMessage: "",
		createWatchListResponse: {},

		isOrderBookPortal: window.location.pathname.includes('/orderbook/'), // temporary
		buy_type: null,
		provide_type: 'price',
		order_payload: {
			company_id: undefined,
			price: undefined,
			size: undefined,
			valuation: undefined,
		},
		new_company_name:this.props.newCompanyName,
		new_company_website: '',
		show_benchmark: false,
	}

	constructor(props) {
		super(props);

	}

	handlNewInputChange = (event) => {
		this.setState({
			newWatchlistInput: event.target.value
		})
	}
	doAddNewWatchListGroup = () => {
		const {
			createWatchlistGroup,
		} = this.props;

		let {
			newWatchlistInput,
			isOrderBookPortal,
		} = this.state;
		let {
			companyId
		} = this.props;
		if (newWatchlistInput.trim().length != 0) {
			createWatchlistGroup({
				watchlistName: newWatchlistInput.trim(),
				companyId: companyId,
				path: isOrderBookPortal ? '/v2/orderbook' : '/v2/wealth',
			});
			this.setState({
				newWatchlistInput: '',
			})
		}
	}

	doUpdateWatchlistV2 = (willOpenInterest = false) => {
		const {
			companyId,
			watchlistGroupList,
			createWatchlistV2,
			createWatchlist,
			active,
			handleShowExpressInterest,
		} = this.props;
		const {
			buy_type,
			order_payload,
			new_company_name,
			isOrderBookPortal,
			new_company_website,
		} = this.state;
		let payload = {}
		payload['company_id'] = companyId;
		payload['active'] = !active;
		payload['watchlist_grp_id'] = watchlistGroupList[0].id;
		payload['watchlist_type'] = buy_type ? buy_type.toUpperCase() : null;
		payload['price'] = order_payload['price'] ? Number(order_payload['price']) : null;
		payload['valuation'] = order_payload['valuation'] ? Number(order_payload['valuation']) : null;
		payload['deal_size'] = order_payload['size'] ? Number(order_payload['size']) : null;
		payload['company_name'] = new_company_name ? new_company_name : null;
		payload['website'] = new_company_website ? new_company_website : null;
		if(isOrderBookPortal){
			createWatchlistV2(payload);
			if(willOpenInterest && handleShowExpressInterest){
				handleShowExpressInterest();
			}
		}else{
			createWatchlist(payload);
		}
	}

	doUpdateWatchlist = (params, event) => {
		const { watchlist_grp_id, active } = params;
		const {
			companyId,
			createWatchlist,
		} = this.props;
		// createWatchlist({
		// 	company: companyId,
		// 	watchlist_grp_id: watchlist_grp_id,
		// 	active: active,
		// });
		createWatchlist({
			company_id: companyId,
			watchlist_grp_id: watchlist_grp_id,
			active: active,
			watchlist_type: null,
			price: null,
			valuation: null,
			deal_size: null,
		});
	}

	componentDidMount() {
		const {
			companyId,
			getWatchlistByCompanyId,
		} = this.props;
		const {
			isOrderBookPortal
		} = this.state;
		if (this.props.companyId != 0) {
			// getWatchlistByCompanyId({
			// 	companyId: companyId,
			// 	path: isOrderBookPortal ? '/v2/orderbook' : '/v2/wealth',
			// })
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		const { companyWatchlist } = this.state;
		let {
			updateStarStatus,
			companyId,
		} = this.props;
		if(companyWatchlist.length !== prevState.companyWatchlist.length){
			if (companyWatchlist.length > 0) {
				updateStarStatus(companyId, true)
			} else {
				updateStarStatus(companyId, false)
			}
		}
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		const {
			companyWatchlist,
			createWatchListGroupError,
			createWatchListGroupMessage,
			createWatchListError,
			createWatchListMessage,
			createWatchListResponse,
			closeDialog,
			resetCompanyWatchlistData,
		} = nextProps;

		// console.log( createWatchListResponse );
		// console.log( prevState.createWatchListResponse );

		if (
			companyWatchlist 
			// &&
			// companyWatchlist.length != prevState.companyWatchlist.length
		) {
			if(createWatchListResponse &&
				JSON.stringify(createWatchListResponse) !== JSON.stringify(prevState.createWatchListResponse)){
					//  console.log( "Running reset data and close dialog")
					resetCompanyWatchlistData()
					closeDialog()
			}
			const companyWatchlistGroupId = companyWatchlist.map(item => item.id)
			return {
				companyWatchlist: companyWatchlist,
				companyWatchlistGroupId: companyWatchlistGroupId
			};
		} else if (
			createWatchListGroupMessage !=
			prevState.createWatchListGroupMessage &&
			createWatchListGroupMessage !== undefined
		) {
			if (createWatchListGroupError) {
				return {
					showSnackBar: true,
					snackBarMessage: createWatchListGroupMessage.trim()
				};
			} else {
				return null
			}
		} else if (
			createWatchListMessage !=
			prevState.createWatchListMessage &&
			createWatchListMessage !== undefined
		) {
			if (createWatchListError) {
				return {
					showSnackBar: true,
					snackBarMessage: createWatchListMessage.trim()
				};
			} else {
				return null
			}
		} if (
			createWatchListResponse &&
			createWatchListResponse != prevState.createWatchListResponse
		) {
			return {
				createWatchListResponse: createWatchListResponse,
			};
		} else {
			return null
		}
	}

	share_title_style = {
		fontFamily: globalFontFamily,
		fontStyle: 'normal',
		fontWeight: '400',
		fontSize: '12px',
		lineHeight: '14px',
		letterSpacing: '0.1em',
		color: 'black',
		marginTop: '10px',
		marginBottom: '-4px',
	}

	form_lable_style = {
		fontSize: ReScaleForDeviceScale(1.5, global150BodyFont, globalBodyFont),
		fontFamily: globalFontFamily,
		marginBottom: '0 0 5px 0 !important',
	}

	toggle_button_group_style = {
		width: '100%',
	}

	toggle_button_style = {
		fontSize: ReScaleForDeviceScale(1.5, global150BodyFont, globalBodyFont),
		fontFamily: globalFontFamily,
		width: '100%',
	}

	tighter_toggle_button_style = {
		fontSize: ReScaleForDeviceScale(1.5, global150BodyFont, globalBodyFont),
		fontFamily: globalFontFamily,
		padding: '0.2rem 0.35rem !important',
		width: '100%',
	}

	text_field_style = {
		fontSize: ReScaleForDeviceScale(1.5, global150BodyFont, globalBodyFont),
		fontFamily: globalFontFamily,
		'& $input': {
			fontSize: ReScaleForDeviceScale(1.5, global150BodyFont, globalBodyFont),
			fontFamily: globalFontFamily,
			'&::placeholder': {
				fontSize: ReScaleForDeviceScale(1.5, global150BodyFont + 'px !important', globalBodyFont + 'px !important'),
				fontFamily: globalFontFamily,
			}
		},
		'&::placeholder': {
			fontSize: ReScaleForDeviceScale(1.5, global150BodyFont, globalBodyFont),
			fontFamily: globalFontFamily,
		},
		'& textarea': {
			fontSize: ReScaleForDeviceScale(1.5, global150BodyFont, globalBodyFont),
			fontFamily: globalFontFamily,
			'&::placeholder': {
				fontSize: ReScaleForDeviceScale(1.5, global150BodyFont + 'px !important', globalBodyFont + 'px !important'),
				fontFamily: globalFontFamily,
			}
		},
		'& $label': {
			fontSize: ReScaleForDeviceScale(1.5, global150LabelFont, globalLabelFont),
			fontFamily: globalFontFamily,
		},
	}

	handleInputChange(key, evt, newValue) {
		const value_to_apply = newValue ? newValue : evt.target && evt.target.value ? evt.target.value : '';
		sendGAEvent({
			type: 'watchlist_dialog_input_change',
			data: {
				key: value_to_apply
			}
		});
		if ([
			'provide_type',
			'buy_type'
		].indexOf(key) > -1) {
			const { order_payload } = this.state;
			if (key == 'provide_type') {
				order_payload['price'] = undefined;
				order_payload['valuation'] = undefined;
			}
			this.setState({ [key]: value_to_apply });
		} else if (key == 'show_benchmark'){
			this.setState({ show_benchmark: value_to_apply });
		} else {
			const { order_payload } = this.state;

			if (key == "price") {
				const { provide_type } = this.state;
				order_payload[provide_type] = value_to_apply;
			} else {
				order_payload[key] = value_to_apply;
			}

			this.setState({ order_payload: order_payload });
		}

		const { handleAlertNeeded } = this.props;

		if (handleAlertNeeded) {
			handleAlertNeeded();
		}
	}

	handleNameInputChange(evt, newValue){
		const value_to_apply = newValue ? newValue : evt.target && evt.target.value ? evt.target.value : '';
		this.setState({
			new_company_name: value_to_apply
		})
	}
	handleWebsiteInputChange(evt, newValue){
		const value_to_apply = newValue ? newValue : evt.target && evt.target.value ? evt.target.value : '';
		this.setState({
			new_company_website: value_to_apply
		})
	}
	closeDialogAndClearData = () =>{
		const {
			closeDialog,
			resetCompanyWatchlistData,
		} = this.props;
		resetCompanyWatchlistData();
		closeDialog()
	}

	render() {
		let {
			newWatchlistInput,
			companyWatchlistGroupId,
			showSnackBar,
			snackBarMessage,
			isOrderBookPortal,
			buy_type,
			provide_type,
			order_payload,
			new_company_name,
			new_company_website,
			show_benchmark,
		} = this.state;

		let {
			watchlistGroupList,
			active,
			appConfig,
			loginUserData,
			orderbook,
			companyId,
			newCompanyName,
			classes,
			company_name,
		} = this.props;

		const { email } = loginUserData;

		const price_label = provide_type == 'valuation' ? "Valuation (US$M)" : "Price (US$)";

		// console.log( company_name );

		const company_name_label = newCompanyName && newCompanyName.trim() ? newCompanyName : company_name && company_name.trim() ? company_name : '';

		return (
			<Dialog
				open
				onClose={this.closeDialogAndClearData}
				fullWidth
				maxWidth="sm"
				className="club-express-interest-dialog"
			>
				<DialogContent className="watchlist-dialog">
					<div className="title">
						{
							this.props.newCompanyName ?
								'We can source this company for you':
								'WatchList'
						}
					</div>
					{
						feature_flags.feature_is_available(
							appConfig,
							feature_flags.watchlist_flags.Watchlist.flag_name,
							feature_flags.watchlist_flags.Watchlist.attributes.multiple_watchlist_group,
							email
						) &&
						<div className="current-watchlist-container">
							<div className="subtitle">
								Existing watchlist
							</div>
							<div className="current-watchlist">
								{
									watchlistGroupList && watchlistGroupList.length > 0 &&
									watchlistGroupList.map((item, index) => {
										return <div key={index} className="watchlist-item">
											<span>{item.name}</span>
											{
												(companyWatchlistGroupId &&
													companyWatchlistGroupId.length > 0 &&
													companyWatchlistGroupId.includes(item.id))
													?
													<span
														className="watchist_star_icon"
														style={{ color: "#FFC50D" }}
														onClick={(event) =>
															this.doUpdateWatchlist(
																{
																	watchlist_grp_id: item.id,
																	active: false,
																},
																event
															)
														}
													>
														<Tooltip title="Remove from watchlist">
															<Star style={{ fontSize: 17 }} />
														</Tooltip>
													</span>
													:
													<span
														className="watchist_star_icon"
														style={{ color: "#AFABAB" }}
														onClick={(event) =>
															this.doUpdateWatchlist(
																{
																	watchlist_grp_id: item.id,
																	active: true,
																},
																event
															)
														}
													>
														<Tooltip title="Add to watchlist">
															<StarBorder style={{ fontSize: 17 }} />
														</Tooltip>
													</span>
											}
										</div>
									})
								}
							</div>
						</div>
					}
					{
						!active &&
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<Box>
									{/* <p style={this.share_title_style}>Tell us more about your interest to receive more market updates. Don’t worry this is optional</p> */}
									<p style={this.share_title_style}>Adding {company_name_label} to your watchlist</p>
									{/* <FormControl component="fieldset">
										<FormGroup>
											<FormControlLabel
												className={classes.FormControlLabel}
												control={
													<Checkbox
														checked={show_benchmark}
														name="show_benchmark"
														onChange={this.handleInputChange.bind(this, 'show_benchmark')}
														/>
												}
												label={<Typography className={classes.FormControlLabel}>{"I have a target to benchmark"}</Typography>}
												/>
										</FormGroup>
									</FormControl> */}
								</Box>
							</Grid>
							{
								this.props.newCompanyName &&
								<>
									<Grid item xs={12}>
										<FormLabel
											component="legend"
											style={this.form_lable_style}>Company Name:</FormLabel>
										<TextField
											id="name-input"
											placeholder="Please Input The Company You Want"
											name="company_name"
											variant="outlined"
											style={this.text_field_style}
											InputLabelProps={{
												shrink: true
											}}
											size="small"
											value={new_company_name}
											onChange={this.handleNameInputChange.bind(this)}
										/>
									</Grid>
									<Grid item xs={12}>
										<FormLabel
											component="legend"
											style={this.form_lable_style}>Website:</FormLabel>
										<TextField
											id="name-input"
											placeholder="Please Input The Company's Website(Optional)"
											name="company_website"
											variant="outlined"
											style={this.text_field_style}
											InputLabelProps={{
												shrink: true
											}}
											size="small"
											value={new_company_website}
											onChange={this.handleWebsiteInputChange.bind(this)}
										/>
										<Divider style={{ marginTop: 16 }} />
									</Grid>
								</>
							}
							{
								show_benchmark ?	
									<React.Fragment>
										<Grid item xs={12}>
											<FormLabel
												component="legend"
												style={this.form_lable_style}>Type</FormLabel>
											<ToggleButtonGroup
												style={this.toggle_button_group_style}
												exclusive
												value={buy_type}
												onChange={this.handleInputChange.bind(this, 'buy_type')}
											>
												<ToggleButton style={this.toggle_button_style} value="buy" aria-label="left aligned">
													Buy
												</ToggleButton>
												<ToggleButton style={this.toggle_button_style} value="sell" aria-label="right aligned">
													Sell
												</ToggleButton>
											</ToggleButtonGroup>
										</Grid>

										<Grid item xs={12}>
											<FormLabel
												component="legend"
												style={this.form_lable_style}>Price</FormLabel>
											<ToggleButtonGroup
												style={this.toggle_button_group_style}
												exclusive
												value={provide_type}
												onChange={this.handleInputChange.bind(this, "provide_type")}
											>
												<ToggleButton style={this.tighter_toggle_button_style} value="valuation" aria-label="left aligned">
													Valuation Amount
												</ToggleButton>
												<ToggleButton style={this.tighter_toggle_button_style} value="price" aria-label="right aligned">
													Price Per Share
												</ToggleButton>
											</ToggleButtonGroup>
										</Grid>

										<Grid item xs={12}>
											<TextField
												id="price-input"
												// label={price_label}
												placeholder={price_label}
												name="price"
												variant="outlined"
												style={this.text_field_style}
												InputLabelProps={{
													shrink: true
												}}
												size="small"
												type="number"
												value={provide_type == "valuation" ? order_payload.valuation : order_payload.price}
												onChange={this.handleInputChange.bind(this, 'price')}
											/>
										</Grid>

										<Grid item xs={12}>
											<FormLabel
												component="legend"
												style={this.form_lable_style}>Size</FormLabel>
											<TextField
												id="size-input"
												name="size"
												placeholder="Size (US$M)"
												variant="outlined"
												style={this.text_field_style}
												value={order_payload.size}
												size="small"
												type="number"
												InputLabelProps={{
													shrink: true,
												}}
												onChange={this.handleInputChange.bind(this, 'size')}
											/>
										</Grid>
									</React.Fragment> : ''
							}
							
						</Grid>
					}
					
					{
						feature_flags.feature_is_available(
							appConfig,
							feature_flags.watchlist_flags.Watchlist.flag_name,
							feature_flags.watchlist_flags.Watchlist.attributes.multiple_watchlist_group,
							email
						)  &&
						<div className="new-watchlist-container">
							<div className="subtitle">
								Add new watchlist
							</div>
							<div className="input_container">
								<input
									className="new_watchlist_input"
									placeholder="WatchList name"
									value={newWatchlistInput}
									onChange={(e) => this.handlNewInputChange(e)}
								/>
								<Button
									className="watchlist_add_btn"
									onClick={this.doAddNewWatchListGroup}>
									Submit
								</Button>
							</div>
						</div>
					}

					{
						active &&
						<div style={{marginTop: '10px', marginBottom: '15px', borderTop:'1px solid #F6F6F6'}}>
								<p style={this.share_title_style}>Confirm your intention to remove from watchlist?</p>
								<Button
									className="watchlist_add_btn"
									onClick={(evt) => this.doUpdateWatchlistV2()}>
									Remove from my watchlist
								</Button>
							</div>
					}

					{
						!active &&
						<div style={{marginTop: '10px', marginBottom: '15px', borderTop:'1px solid #F6F6F6'}}>
								<Button
									className="watchlist_add_btn"
									onClick={(evt) => this.doUpdateWatchlistV2()}>
									Confirm add to my watchlist
								</Button>
								{
									!this.props.newCompanyName &&
									<Button
										className="watchlist_add_btn"
										onClick={(evt) => this.doUpdateWatchlistV2(true)}>
										Confirm add to my watchlist and express interest
									</Button>
								}
							</div>
					}
				</DialogContent>

				<Snackbar
					anchorOrigin={{
						vertical: "top",
						horizontal: "center",
					}}
					openStyle={showSnackBar}
					handleSnackbarClose={this.closeDialogAndClearData}
					type={"error"}
					message={snackBarMessage}
					autoHideDuration={4000}
				/>
			</Dialog>
		);
	}
}

export default withStyles(FormStyle)(WatchListDialog);