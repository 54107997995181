export default {
    placeorderResponse: {
        loading: false,
        error: false,
        message: ''
    },
    // searchCompanyResponse: {
    //     resultCount: 0,
    //     resultList: [],
    // },
    searchCompanyResponse: [],
    search_company_loading: false,
    lock_company: false,
    frs: {
        count: 0,
        results: []
    }
}