import { combineReducers } from 'redux';

import {
    postRequestInfo,
    clearRequestInfoMsg,
} from './Action';

import { default as InitialState } from './InitialState';

import { key_map } from './Config';

function handle_error_message( payload ){
    const payload_keys = Object.keys(payload);
    let messages = []
    payload_keys.forEach(key => {
        if( key == 'comment' ){
            messages = messages.concat( handle_error_message(payload[key]) );
        } else {
            messages.push(`${key_map[key]} is ${payload[key][0]}`);
        }
    });

    return messages;
}

export default combineReducers({
    reducer( state=InitialState, action ){
        switch( action.type ){
            case postRequestInfo.request.toString():
                return Object.assign({}, state, {
                    request_info_response: {
                        loading: true,
                        error: false,
                        message: ''
                    }
                })
            case postRequestInfo.success.toString():
                return Object.assign({}, state, {
                    request_info_response: {
                        loading: false,
                        error: false,
                        message: 'Your request for information has been sent. Your Gateway representative will revert back to you shortly.'
                    }
                });
            case postRequestInfo.failure.toString():
                return Object.assign({}, state, {
                    request_info_response: {
                        messages: handle_error_message(action.payload)
                    }
                });
            case clearRequestInfoMsg.toString():
                return Object.assign({}, state, {
                    request_info_response: {
                        loading: false,
                        error: false,
                        message: ''
                    }
                });
            default:
                return state;
        }
    }
})