import React, { Component } from 'react';
import logo from '../../assets/images/logo.png';
import { Link } from 'react-router-dom';


class FrontPageHeader extends Component {
    state = {
        show: false
    }
    
    toggle = () => {
        this.setState({ show: !this.state.show })
    }

    render() {
        const { show } = this.state
        return (
            <header className="container-fluid" >
                <div className="row">
                    <div className="col-12">
                        <div className="navigation">
                            <nav className="navbar navbar-light bg-faded  navbar-expand-lg">
                                <Link to="/" className="navbar-brand"><img className="h-100" src={logo} alt="" /></Link>
                                <button onClick={() => this.toggle()} className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                                    <span className="icon-menu"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span></span>
                                </button>
                                <div className={` ${show ? '' : 'collapse'} navbar-collapse`} id="navbarNavAltMarkup">
                                    {
                                        //     <ul className="navbar-nav mr-auto ">
                                        //     <li className="nav-item dropdown">
                                        //         <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Solutions</a>
                                        //         <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                        //             <a className="dropdown-item" href="">Link 1 </a>
                                        //             <a className="dropdown-item" href="">Link 2 </a>
                                        //             <a className="dropdown-item" href="">Link 3 </a>
                                        //             <a className="dropdown-item" href="">Link 4 </a>
                                        //         </div>
                                        //     </li>
                                        //     <li className="nav-item "><a className="nav-link " href="">About</a></li>
                                        //     <li className="nav-item "><a className="nav-link " href="">Contact</a></li>
                                        //     <li className="nav-item "><a className="nav-link" href="">News Room </a></li>
                                        //     <li className="nav-item "><a className="nav-link active" href="">Portal</a></li>
                                        // </ul>
                                    }
                                </div>
                            </nav>
                        </div>
                    </div>
                </div>
            </header>

        )
    }
}

export default FrontPageHeader;
