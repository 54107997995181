import React, { Fragment, useState, useEffect } from "react";
import {
	Dialog,
	DialogContent,
	FormControlLabel,
	FormLabel,
	FormGroup,
	Checkbox,
} from "@material-ui/core";
import {
    showBlurred,
} from '../../../../utils/util';
export default (props) => {
	const { dealType, dealHighlight: { company_presentation, financial_info, management_call } } = props;

	const [companyPresentChecked, setCompanyPresentChecked] = useState(false);
	const [managementCallChecked, setManagementCallChecked] = useState(false);
	const [financialInfoChecked, setFinancialInfoChecked] = useState(false);
	const [othersChecked, setOthersChecked] = useState(false);
	const [othersText, setOthersText] = useState('');
	const [interestText, setInterestText] = useState('');
	const [gpInfoChecked, setGpInfoChecked] = useState(false);
	const [companyInfoChecked, setCompanyInfoChecked] = useState(false);

	const [tryAllChecked, setTryAllChecked] = useState(false);
	useEffect(() =>{
		if(props.open && !tryAllChecked){
			setCompanyPresentChecked(true)
			props.handleChange('company_presentation',true)
			setManagementCallChecked(true)
			props.handleChange('management_call',true)
			setFinancialInfoChecked(true)
			props.handleChange('financial_info',true)
			if(props.companyInfoIncluded){
				setCompanyInfoChecked(true)
				props.handleChange('company_info',true)
			}
			if(props.gpInfoIncluded){
				setGpInfoChecked(true)
				props.handleChange('gp_info',true)
			}
			setTryAllChecked(true)
		}
	})

	const handleCheckBoxChange = (event, checked) => {
		switch(event.target.value){
			case 'company_presentation':
				props.handleChange('company_presentation',checked)
				setCompanyPresentChecked(checked)
				break;
			case 'management_call':
				props.handleChange('management_call',checked)
				setManagementCallChecked(checked)
				break;
			case 'financial_info':
				props.handleChange('financial_info',checked)
				setFinancialInfoChecked(checked)
				break;
			case 'gp_info':
				props.handleChange('gp_info',checked)
				setGpInfoChecked(checked)
				break;
			case 'company_info':
				props.handleChange('company_info',checked)
				setCompanyInfoChecked(checked)
				break;
			case 'others':
				setOthersChecked(checked)
				if(!checked){
					setOthersText('')
				}
				break;
		}
	}

	const handleOtherInputChange = (event) => {
		props.handleChange('others',event.target.value)
		setOthersText(event.target.value);
	}
	const handleInterestInputChange = (event) => {
		const payload = { 
			'id': 0,
			'req_size_m': false,
			'req_implied_val_m': false,
			'req_price': false,
			'prelim_size_m': parseFloat(event.target.value),
			'prelim_price_m': null
		}
		props.handleChange('offer', payload);
		setInterestText(event.target.value);
	}

	const resetState = () =>{
		setCompanyPresentChecked(false)
		setManagementCallChecked(false)
		setFinancialInfoChecked(false)
		setGpInfoChecked(false)
		setCompanyInfoChecked(false)
		setOthersChecked(false)
		setOthersText('')
		setInterestText('')
	}
	const clickCancel = ()=>{
		resetState()
		props.handleCancelRequest()
		setTryAllChecked(false)
	}
	const clickSubmit = ()=>{
		resetState()
		props.handleSubmitRequest()
		setTryAllChecked(false)
	}
	const onCloseDialog = () => {
		props.closePopup()
		resetState()
		setTryAllChecked(false)
	}
	return (
		<Dialog
			open={props.open}
			onClose={onCloseDialog}
			fullWidth
			maxWidth="sm"
			className="club-express-interest-dialog"
		>
			<DialogContent className="buy-sell-dialog">
				<div className="title">{props.dialogTitle}
					{(props.logo_url && !showBlurred(props.logo_url)) &&
						<img className="logo-img" src={props.logo_url} />
					}
				</div>
				<div className="flex-row-between">
					<div className="mt-2 request-info-checkboxes">
						<FormGroup>
							{/* <FormLabel className="buy-sell-label">I would like to know:</FormLabel> */}
							{
								company_presentation && 
								company_presentation.value && 
								typeof company_presentation.value == 'string' &&
								company_presentation.value.toLowerCase() === "true" &&
									<FormControlLabel 
										control={<Checkbox onChange={handleCheckBoxChange} checked={companyPresentChecked}/>} 
										value="company_presentation" 
										label={
											dealType.deal_class_str &&
											typeof dealType.deal_class_str == "string" &&
											dealType.deal_class_str.indexOf('gpled') > -1 ? 
											"Investor Deck" : "Company Presentation"} />
							}
							
							{
								financial_info && 
								financial_info.value && 
								typeof financial_info.value == 'string' &&
								financial_info.value.toLowerCase() === "true" &&
									<FormControlLabel 
										control={<Checkbox onChange={handleCheckBoxChange} checked={financialInfoChecked}/>} 
										value="financial_info" 
										label="Financial Information" />
							}

							{
								management_call && 
								management_call.value && 
								typeof management_call.value == 'string' &&
								management_call.value.toLowerCase() === "true" &&
									<FormControlLabel 
										control={<Checkbox onChange={handleCheckBoxChange} checked={managementCallChecked}/>} 
										value="management_call" 
										label={
											dealType.deal_class_str &&
											typeof dealType.deal_class_str == "string" &&
											dealType.deal_class_str.indexOf('gpled') > -1 ? 
											"Access to GP" : "Management Call"} />
							}
														
							{
								props.gpInfoIncluded && 
								<FormControlLabel control={<Checkbox onChange={handleCheckBoxChange} checked={gpInfoChecked}/>} value="gp_info" label="GP Information" />	
							}
							{
								props.companyInfoIncluded && 
								<FormControlLabel control={<Checkbox onChange={handleCheckBoxChange} checked={companyInfoChecked}/>} value="company_info" label="Company Information" />
							}
							<div>
								<FormControlLabel control={<Checkbox onChange={handleCheckBoxChange} checked={othersChecked}/>} value="others" label="Others:" />
								<input
									id="others_input"
									name="othersInput"
									disabled  = {!othersChecked}
									value={othersText}
									onChange={(e) => handleOtherInputChange(e)}
									className="buy-sell-input"
								/>
							</div>
						</FormGroup>
					</div>
				</div>
				<FormLabel>I have preliminary interest of US$</FormLabel>
				<input
									id="interest_input"
									name="interestInput"
									type="number"
									placeholder="E.g. 5"
									style={{textAlign: 'center'}}
									value={interestText}
									onChange={(e) => handleInterestInputChange(e)}
									className="buy-sell-input"
								/><FormLabel>M in this opportunity.</FormLabel>
				<div className="flex-row-center mt-4 buy-sell-btns">
					<span onClick={clickCancel} className="buy-sell-btn">
						Cancel
					</span>
					<span
						onClick={clickSubmit}
						className="buy-sell-btn buy-sell-btn-focus-request-info"
					>
						Confirm
					</span>
				</div>
			</DialogContent>
		</Dialog>
	);
};
