import React, { useState, useEffect } from 'react';

import {
    Dialog,
    DialogContent,
    Button,
    Grid,
    TextField,
} from '@material-ui/core';

import {
    globalFontFamily,
    globalBodyFont
} from '../../../../styles';

export default (props) => {
    const [name, setName] = useState('')
    const [description, setDescription] = useState('')

    useEffect(() => {
        setName(props.group_name);
        setDescription(props.group_description);
    }, [props])
    return (
        <Dialog
            open={props.open}
            onClose={(evt) => props.handleCloseGroupEditDialog(evt, false, name, description)}
            fullWidth
            maxWidth="sm"
        >
            <DialogContent className="watchlist-dialog">
                <div className="title">
                    WatchList
                </div>
                <div style={{ marginTop: '10px', marginBottom: '15px', borderTop: '1px solid #F6F6F6' }}>
                    <p style={{
                        fontFamily: globalFontFamily, fontStyle: 'normal', fontWeight: '400', fontSize: '12px',
                        lineHeight: '14px', letterSpacing: '0.1em', color: 'black', marginTop: '10px', marginBottom: '5px',
                    }}>Confirm your intention to update watchlist '{props.group_name}'?</p>

                    <Grid container>
                        <Grid item xs={4} style={{alignSelf: 'end'}}>
                            <strong style={{
                                fontSize: globalBodyFont, padding: '0 8px',
                                lineHeight: 1.75, display: 'inline-block'
                            }}>Name:</strong>
                        </Grid>
                        <Grid item xs={8}>
                            <TextField
                                margin="dense"
                                id="name"
                                fullWidth
                                variant="standard"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                        </Grid>
                    </Grid>

                    <Grid container>
                        <Grid item xs={4} style={{alignSelf: 'end'}}>
                            <strong style={{
                                fontSize: globalBodyFont, padding: '0 8px',
                                lineHeight: 1.75, display: 'inline-block'
                            }}>Description:</strong>
                        </Grid>
                        <Grid item xs={8}>
                            <TextField
                                margin="dense"
                                id="name"
                                fullWidth
                                variant="standard"
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                            />
                        </Grid>
                    </Grid>

                    <Button
                        className="watchlist_add_btn"
                        onClick={(evt) => props.handleCloseGroupEditDialog(evt, true, name, description)}>
                        Update this watchlist
                    </Button>
                </div>
            </DialogContent>
        </Dialog>
    )
}