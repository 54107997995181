export default {
    createWatchListGroup:{},
    createWatchListGroupError:false,
    createWatchListGroupMessage:"",
    createWatchListResponse:{},
    createWatchListError:false,
    createWatchListMessage:"",

    watchlistGroupList: [],
    companyWatchlist:[],
    watchlistGroupItem:{},
    watchlistGroupItem_loading: false, 
    deleteGroupResponseError:false,
    deleteGroupResponseMessage:'',
    updateGroupResponseError:false,
    updateGroupResponseMessage:'',

    price_index: {},
    watchlist_companies: {
        count: 0,
        results: []
    },
    funding_rounds: {
        results: []
    },
    verticals: {},
    pipelines: {
        resultCount: 0,
        resultList: []
    },
    historical_pricing: {
        allow: false,
        data: false,
        historical_pricing: []
    },
    funding_rounds: {
        count: 0,
        results: []
    }
}