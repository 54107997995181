import { connect } from 'react-redux';
import { default as ChangePassword } from './ChangePassword';

import {
    ConfirmPasswordReset
} from './Action';


export default connect(
    state => ({
        showSnackbarInPassword:state.resetPassword.updatePassword.reducer.showSnackbarInPassword,
        passwordError: state.resetPassword.updatePassword.reducer.passwordError,
        passwordResponse: state.resetPassword.updatePassword.reducer.passwordResponse,
    }),
    dispatch => ({
        ConfirmPasswordReset: payload => dispatch(ConfirmPasswordReset.request(payload))
    })
)(ChangePassword);
