import { CreateApiAction as _createApiAction, CreateKeyAction as _createKeyAction } from '../../../utils'

const key = 'WATCHLIST';
const CreateApiAction = _createApiAction(key);
const CreateKeyAction = _createKeyAction(key);

export const createWatchlist = CreateApiAction('CREAT_WATCHLIST');
export const createWatchlistGroup = CreateApiAction('CREAT_WATCHLIST_GROUP');//used in WatchlistDialog, will call createWatchlist afterwards

export const getWatchlistGroup = CreateApiAction('GET_WATCHLIST_GROUP');//watchlist group list
export const getWatchlistByCompanyId = CreateApiAction('GET_WATCHLIST_BY_COMPANY_ID');

export const getWatchlistGroupItem = CreateApiAction('GET_WATCHLIST_GROUP_ITEM');//watchlist group item detail. like name, description...
export const deleteWatchlistGroupItem = CreateApiAction('DELETE_WATCHLIST_GROUP_ITEM');
export const updateWatchlistGroupItem = CreateApiAction('UPDATE_WATCHLIST_GROUP_ITEM');
export const createWatchlistGroupOnly = CreateApiAction('CREAT_WATCHLIST_GROUP_ONLY');//used in WatchlistGroup, only create a new group

export const getWatchlistHistoricalPrices = CreateApiAction('GET_WATCHLIST_HISTORICAL_PRICES');

export const createWatchlistV2 = CreateApiAction('CREAT_WATCHLIST_V2');//temp for orderbook only
export const resetCompanyWatchlistData = CreateKeyAction('RESET_COMPANY_WATCHLIST');

export const getWatchlistCompanies = CreateApiAction('GET_WATCHLIST_COMPAINES');
export const getWatchlistPipeline = CreateApiAction('GET_WATCHLIST_PIPELINE');
export const getPriceIndex = CreateApiAction('GET_PRICE_INDEX');
export const getFundingRound = CreateApiAction('GET_FUNDING_ROUND');
export const getVerticals = CreateApiAction('GET_VERTICALS');