import React, { Fragment } from "react";
import moment from "moment";
// import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { Styles } from "../../styles";
import { Link, Switch } from "react-router-dom";
import { CompanyStatusCalculate } from "../../../utils/util";
import { sendGAEvent } from "../../../utils/ga";
import { ReadQueryString } from "../../../utils";
import WhatshotIcon from '@material-ui/icons/Whatshot';
import {
	StarBorder,
	Star,
	ExpandMore,
	ExpandLess,
} from "@material-ui/icons";
import { GetToken } from "../../../utils";
import { GenerateGreeting } from "../../../utils";
// import { BottomForm, RangeDoubleSide } from "./components";
import {
	Button,
	TextField,
} from "@material-ui/core";
import {
	ToggleButton,
	ToggleButtonGroup,
} from "@material-ui/lab";
import { BiDetail, BiFilterAlt } from "react-icons/bi";
import { BsSearch } from "react-icons/bs";
import { Snackbar, PipelineSidebar } from "../../common";
import Tooltip from "@material-ui/core/Tooltip";
import { FilterSlider } from "./components/Sliders";
import DisclaimerData from '../../common/DisclaimerData';
import { content_placeholders } from '../../../config';
import { sendLogEvent } from '../../../utils/ga';
import { CardList } from '../common';

let liveDealsTop, marketIntelligenceTop, watchlistTop;

const discountOptions = [
	{
		id: 0,
		name: "40%+ Discount",
		query_string: "lt-40",
		color: "red",
	},
	{
		id: 1,
		name: "20% to 40% Discount",
		query_string: "lt-20",
		color: "red",
	},
	{
		id: 2,
		name: "0 to 20% Discount",
		query_string: "lt0",
		color: "red",
	},
	{
		id: 3,
		name: "0 to 20% Premium",
		query_string: "lt20",
		color: "green",
	},
	{
		id: 4,
		name: "20% to 40% Premium",
		query_string: "lt40",
		color: "green",
	},
	{
		id: 5,
		name: "40%+ Premium",
		query_string: "gt40",
		color: "green",
	},
];

const totalOptions = [
	{
		id: 0,
		name: "<$500mn",
		query_string: "lt500",
	},
	{
		id: 1,
		name: "$500mn - $1Bn",
		query_string: "gt500",
	},
	{
		id: 2,
		name: "$1Bn - $10Bn",
		query_string: "gt1000",
	},
	{
		id: 3,
		name: ">$10Bn",
		query_string: "gt10000",
	},
];

class Pipeline extends React.Component {
	state = {
		tabFocusIndex: 0,
		showFilter: false,
		page: 0,
		rowsPerPage: 1000,
		selectedFilterOption: "Company",
		tags: [],
		selectedDP: [],
		selectedTotal: [],
		selectedVertical: [],
		selectedHQCountry: [],
		valuationSort: "disabled",
		stageSort: "disabled",
		discountPremium: "all", // '' discount premium all
		showWatchList: false,
		showFilterMore: false,
		referral: {
			first_name: "",
			last_name: "",
			email: "",
		},
		expressInterest: {
			company: "",
			comment: "",
		},
		expandMore: false,
		valuation: {
			min: 100,
			max: 30000,
		},
		showClearFilter: false,
		newMarketIntelligenceList: [],
		popular_companies: [],
		showDetailId: -1,
		selectAllVT: true,
		selectAllHC: true,
		keyWord: "",
		openStyle: false,
		expressInterestError: false,
		expressInterestResponseMessage: "",
		valuationDefaultValues: [0, 9900, 0],
		snackBar: {
			open: false,
			error: false,
			message: "",
		},
		toggleWatchError: false,
		toggleWatchListMessage: "",
	};

	constructor(props) {
		super(props);
		const items = discountOptions.map((item) => {
			item.selected = true;
			return item;
		});

		this.state["selectedDP"] = items;
		// console.log("selectedDP", this.state.selectedDP);

		const t_items = totalOptions.map((item) => {
			item.selected = true;
			return item;
		});
		this.state["selectedTotal"] = t_items;
		// console.log("selectedTotal", this.state.selectedTotal);
		// this.handleSnackbarClose = this.handleSnackbarClose.bind(this);
	}

	componentDidMount() {
		const { location : { search } } = this.props;
		const { tabFocusIndex } = this.state;
		const query_string_obj = ReadQueryString(search);
		
		if( query_string_obj.tab && parseInt(query_string_obj.tab) != tabFocusIndex ){
			this.onTabClick(parseInt(query_string_obj.tab));
		}
		this.onPageScroll();
		this.getData();
		this.getListHeight();
		sendLogEvent(false, 'pipeline_componentDidMount', {}, this.props);
	}

	componentDidUpdate(prevProps, prevState) {
		const { location : { search } } = this.props;
		const { tabFocusIndex } = this.state;
		const query_string_obj = ReadQueryString(search);
		// console.log('Component did update.');
		// console.log( query_string_obj );
		// console.log( prevProps.location.search );
		// console.log( search );
		if(search != prevProps.location.search && query_string_obj.tab && parseInt(query_string_obj.tab) != tabFocusIndex ){
			this.onTabClick(parseInt(query_string_obj.tab));
		}
		this.getListHeight();
	}

	getListHeight = () => {
		let { showWatchList } = this.state;
		if (!!showWatchList) {
			return;
		}
		let liveDeals = document.getElementById("liveDeals");
		if(liveDeals) liveDealsTop = liveDeals.offsetTop - 90;

		// let watchlist = document.getElementById("watchlist");
		let marketIntelligence = document.getElementById("marketIntelligence");

		// watchlistTop = watchlist.offsetTop - 90;
		marketIntelligenceTop = marketIntelligence.offsetTop - 90;
	};

	onPageScroll = () => {
		let _this = this;
		window.onscroll = () => {
			let scrollTop =
				document.documentElement.scrollTop || document.body.scrollTop;
			let tabFocusIndex = 0;
			if (scrollTop > liveDealsTop && scrollTop < marketIntelligenceTop) {
				tabFocusIndex = 0;
			}
			if (scrollTop > marketIntelligenceTop) {
				tabFocusIndex = 1;
			}
			if (!!this.state.showWatchList) {
				tabFocusIndex = 2;
			}
			_this.setState({
				tabFocusIndex: tabFocusIndex,
			});
			// if (
			//   this.getScrollTop() + this.getClientHeight() ==
			//     this.getScrollHeight() &&
			//   !this.state.showWatchList
			// ) {
			//   this.setState({
			//     page: this.state.page + 1,
			//   });
			//   this.getNewMarketIntelligence();
			// }
		};
	};

	getData = () => {
		const { page, rowsPerPage, selectedTotal, selectedDP } = this.state;
		const {
			getPipeline,
			getHeadquaterCountryList,
			getVerticalsList,
			getViewSummary,
		} = this.props;
		let _this = this;

		setTimeout(function () {
			getPipeline({
				limit: rowsPerPage,
				offset: page * rowsPerPage,
				selected: selectedDP,
				selectedTotal: selectedTotal,
			});
			_this.getNewMarketIntelligence();
			getHeadquaterCountryList();
			getVerticalsList();
			getViewSummary();
		}, 300);

		setTimeout(function(){
			_this.getWatchList();
		}, 1000)
	};

	getWatchList = () => {
		this.props.getWatchList({
			offset: 0,
			watchlist: 1,
		});
	};

	static getDerivedStateFromProps(nextProps, prevState) {
		const {
			headquaterCountryListResponse,
			verticalsListResponse,
			marketIntelligenceResponse,
			showSnakbar,
			passwordError,
			responseMsg,
			referralError,
			referralResponseMessage,
			toggleWatchError,
			toggleWatchListMessage,
			popular_companies,
		} = nextProps;
		let { newMarketIntelligenceList, page, rowsPerPage } = prevState;

		if (
			verticalsListResponse &&
			verticalsListResponse.length != prevState.selectedVertical.length
		) {
			const vert = verticalsListResponse.map((item, ii) => {
				return { id: item.id, name: item.name, selected: true };
			});
			return { selectedVertical: vert };
		} else if (
			headquaterCountryListResponse &&
			headquaterCountryListResponse.length != prevState.selectedHQCountry.length
		) {
			const hq = headquaterCountryListResponse.map((item, ii) => {
				return { id: ii, name: item, selected: true };
			});
			return { selectedHQCountry: hq };
		} else if (
			toggleWatchListMessage != prevState.toggleWatchListMessage &&
			toggleWatchListMessage !== undefined
		) {
			const new_open_style = toggleWatchListMessage.trim() ? true : false;
			return {
				snackBar: {
					open: new_open_style,
					error: toggleWatchError,
					message: toggleWatchListMessage,
				},
			};
		} else if (
			marketIntelligenceResponse &&
			marketIntelligenceResponse["results"]
			// &&
			// marketIntelligenceResponse["results"].length !=
			// 	prevState.newMarketIntelligenceList.length
		) {
			return {
				newMarketIntelligenceList: [...marketIntelligenceResponse["results"]],
			};
		} else if (
			popular_companies
		) {
			return { popular_companies: popular_companies }
		} else if (
			showSnakbar &&
			responseMsg != prevState.expressInterestResponseMessage &&
			responseMsg !== undefined
		) {
			const new_open_style = referralResponseMessage.trim() ? true : false;
			return {
				snackBar: {
					open: new_open_style,
					error: passwordError,
					message: responseMsg,
				},
			};
		} else if (
			referralResponseMessage != prevState.expressInterestResponseMessage &&
			referralResponseMessage !== undefined
		) {
			const new_open_style = referralResponseMessage.trim() ? true : false;
			return {
				snackBar: {
					open: new_open_style,
					error: referralError,
					message: referralResponseMessage,
				},
			};
		}  else {
			return null;
		}
	}

	keyWordInput = (event) => {
		// this.setState({ keyWord: event.target.value }, this.doSearch);
		this.setState({ keyWord: event.target.value });
	};

	keyWordKeyUp = (event) => {
		if (event.keyCode == "13") {
			this.doSearch();
		}
	};

	doSearch = () => {
		if (!!this.state.keyWord) {
			this.setState(
				{
					tags: [`Company:${this.state.keyWord}`],
				},
				() => {
					this.clearDataAndGetNewMarketIntelligence();
				}
			);
		} else {
			this.setState(
				{
					tags: [],
				},
				() => {
					this.clearDataAndGetNewMarketIntelligence();
				}
			);
		}
	};

	clearDataAndGetNewMarketIntelligence = () => {
		this.setState(
			{
				newMarketIntelligenceList: [],
				page: 0,
			},
			() => {
				this.getNewMarketIntelligence();
			}
		);
	};

	renderStatus(parms) {
		let result = CompanyStatusCalculate(parms);
		return (
			<div
				className="flex-row-center showcase-status-container"
				style={{ color: result.color, fontSize: "1.4rem" }}
			>
				{result.icon}
				<span className="home-status" style={{ marginLeft: "0.3rem" }}>
					{result.statusTip}
				</span>
			</div>
		);
	}

	doToggleWatchList = (parms, event) => {
		let { companyId, watchlist } = parms;
		if(event && event.stopPropagation) event.stopPropagation();
		if(event && event.preventDefault) event.preventDefault();
		const { toggleWatchList } = this.props;
		toggleWatchList({
			company: companyId,
			active: !watchlist,
		});
		this.updateCompanyStar(parms);
	};

	updateCompanyStar = (parms) => {
		let { watchListResponse, pipelineResponse, popular_companies } = this.props;
		let { newMarketIntelligenceList } = this.state;
		// console.log( popular_companies );
		if (
			popular_companies &&
			popular_companies.length > 0
		) {
			for( let index in popular_companies ){
				if(
					popular_companies[index]['company_id'] == parms.companyId
				) {
					popular_companies[index]['watchlist'] = !parms.watchlist;
				}
			}
		}

		if (
			pipelineResponse &&
			pipelineResponse.results &&
			pipelineResponse.results.length > 0
		) {
			for (let index in pipelineResponse["results"]) {
				if (
					pipelineResponse["results"][index]["company"]["id"] == parms.companyId
				) {
					pipelineResponse["results"][index]["watchlist"] = !parms.watchlist;
				}
			}
		}

		if (newMarketIntelligenceList && newMarketIntelligenceList.length > 0) {
			for (let index in newMarketIntelligenceList) {
				if (newMarketIntelligenceList[index]["company_id"] == parms.companyId) {
					newMarketIntelligenceList[index]["watchlist"] = !parms.watchlist;
				}
			}
		}
		
		if (
			watchListResponse &&
			watchListResponse.results &&
			watchListResponse.results.length > 0
		) {
			for (let index in watchListResponse["results"]) {
				if (
					watchListResponse["results"][index]["company_id"] == parms.companyId
				) {
					watchListResponse["results"][index]["watchlist"] = !parms.watchlist;
					if (!!parms.watchlist) {
						watchListResponse["results"].splice(index, 1);
					}
				}
			}
		}

		this.setState({
			pipelineResponse: pipelineResponse,
			newMarketIntelligenceList: newMarketIntelligenceList,
			watchListResponse: watchListResponse,
		});
	};

	onTabClick = (index) => {
		// let homeRight = document.getElementById("home-right");
		let homeRight = window;
		let scrollToHeight = liveDealsTop;
		let showWatchList = false;
		let eventGAName = ""
		switch (index) {
			case 0:
				scrollToHeight = liveDealsTop;
				eventGAName = "click_deal_showcase"
				break;
			case 1:
				scrollToHeight = marketIntelligenceTop;
				eventGAName = "click_market_intelligence"
				break;
			case 2:
				scrollToHeight = liveDealsTop - 10;
				showWatchList = true;
				eventGAName = "click_watchlist"
				break;
			default:
				break;
		}

		this.setState({
			tabFocusIndex: index,
			showWatchList: showWatchList,
		});
		this.props.updateProps({
			showWatchList: showWatchList,
		});

		if (!showWatchList) {
			// console.log("Try scrolling.")
			try{
				homeRight.scrollTo({
					top: scrollToHeight + 10,
					behavior: "smooth",
				});
			} catch(err){
				console.log(err);
			}

		} else {
			// console.log("Try watchlist.")
			if (index == 2) {

				this.getWatchList();
				try{
					homeRight.scrollTo({
						top: scrollToHeight + 10,
						behavior: "smooth",
					});
				} catch(err){
					console.log(err);
				}

			}
		}
		sessionStorage.setItem("tabIndex", index);
		sendGAEvent({
			type:eventGAName,
			data:{
			}
		})
	};

	renderCaseList = (listArray, type) => {
		const { loginUserData } = this.props;

		return (
			<div>
				{listArray.map((item, index) => {
					let company = item.company;
					return (
						<Link
							to={{
								pathname: `/club/company-detail/${company.id}`,
								state: { type: type },
							}}
							key={index}
							className="flex-row home-card"
						>
							<img
								className="case-img"
								src={company.logo_url}
								alt={company.name}
							/>
							<div className="flex-row-between case-center">
								<div className="flex-column">
									<div className="home-company-name-case">{company.name}</div>
									<div className="home-company-info">
										{company.primary_industry_code}
									</div>
								</div>
								{this.renderStatus({
									live: item.live,
									committed_by: item.committed_by,
									trial_user: (
										loginUserData.activeAccessRight &&
										loginUserData.activeAccessRight.access_right &&
										loginUserData.activeAccessRight.access_right.permission == content_placeholders.user_type.trial_user ) ? true : false
								})}
							</div>
							<div className="pr-1 commitment-display-container">
								<div className="home-company-info">
									{
										loginUserData.activeAccessRight &&
										loginUserData.activeAccessRight.access_right &&
										loginUserData.activeAccessRight.access_right.permission == content_placeholders.user_type.trial_user ?
											<span className="blurred">Deadline for firm commitment</span> : <span>Deadline for firm commitment</span>
									}
								</div>
								<div className="home-deadline">
									{
										loginUserData.activeAccessRight &&
										loginUserData.activeAccessRight.access_right &&
										loginUserData.activeAccessRight.access_right.permission == content_placeholders.user_type.trial_user ?
											<span className="blurred">N/A</span> : <span>{item.committed_by
											? moment(item.committed_by).format("D MMM YYYY")
											: "N/A"}</span>
									}
									{}
								</div>
							</div>
							{/* {item.watchlist ? (
								<span
									onClick={(event) =>
										this.doToggleWatchList(
											{
												companyId: company.id,
												watchlist: item.watchlist,
											},
											event
										)
									}
									style={{ color: "#FFC50D" }}
								>
									<Tooltip title="Remove from watchlist">
										<Star />
									</Tooltip>
								</span>
							) : (
								<span
									onClick={(event) =>
										this.doToggleWatchList(
											{
												companyId: company.id,
												watchlist: item.watchlist,
											},
											event
										)
									}
									style={{ color: "#AFABAB" }}
								>
									<Tooltip title="Add to watchlist">
										<StarBorder />
									</Tooltip>
								</span>
							)} */}
						</Link>
					);
				})}
			</div>
		);
	};

	renderShowcase(liveDeals) {
		return liveDeals.length ? (
			<div  id="liveDeals" className="case-wrap">
				<div className="home-list-name">
					<h3>DEAL SHOWCASE</h3>
				</div>
				{this.renderCaseList(liveDeals, "showcase")}
			</div>
		) : (<React.Fragment></React.Fragment>);
	}

	getScrollTop = () => {
		var scrollTop = 0;
		if (document.documentElement && document.documentElement.scrollTop) {
			scrollTop = document.documentElement.scrollTop;
		} else if (document.body) {
			scrollTop = document.body.scrollTop;
		}
		return scrollTop;
	};

	getClientHeight = () => {
		let clientHeight = 0;
		if (document.body.clientHeight && document.documentElement.clientHeight) {
			clientHeight = Math.min(
				document.body.clientHeight,
				document.documentElement.clientHeight
			);
		} else {
			clientHeight = Math.max(
				document.body.clientHeight,
				document.documentElement.clientHeight
			);
		}
		return clientHeight;
	};

	getScrollHeight = () => {
		return Math.max(
			document.body.scrollHeight,
			document.documentElement.scrollHeight
		);
	};

	renderCompanyLiveTag = () => {
		return (
			<div
				className="flex-row-center home-card-status"
				style={{ background: "#FCA311" }}
			>
				<span className="home-status">Live</span>
			</div>
		);
	};

	onDetailMouseEnter = (event, id) => {
		// console.log("in enter ", event.target, event.relatedTarget);
		if(event && event.stopPropagation) event.stopPropagation();
		this.setState(
			{
				showDetailId: id,
			},
			() => {
				// console.log(this.state.showDetailId, "this.state.showDetailId");
			}
		);
		sendGAEvent({
			type:"hover_company_description",
			data:{
				company_id: id,
			}
		})
	};

	onDetailMouseLeave = (event) => {
		if(event && event.stopPropagation) event.stopPropagation();
		this.setState({ showDetailId: -1, });
	};

	renderCardList = (listArray, type) => {
		const target_date = new Date();
		target_date.setDate(target_date.getDate() - 14);

		return (
			<div className="flex-row market-intelligence-list">
				{listArray &&
					listArray.length > 0 &&
					listArray.map((item, index) => {
						const date_obj = new Date(item.date_added+"T00:00:00");
						return (
							<Link
								to={{
									pathname: `/club/company-detail/${item.company_id}`,
									state: { type: type },
								}}
								key={index}
								className="home-card market-item"
							>
								{
									date_obj > target_date && item.status == "DEAL LIVE CONFIRMED" ?
										<div className="new-label">
											Recently<br />Added
										</div>
										: ''
								}
								{this.state.showDetailId == item.company_id && (
									<div className="flex-row market-about">
										<div className="line-ten">{item.description}</div>
									</div>
								)}
								<div className="flex-row-between">
									<div>
										<div className="home-company-name-market line-two">
											{item.name}
										</div>
										<div className="home-company-info line-two company-info-height">
											{item.primary_industry_code}
										</div>
									</div>
									<div className="flex-row company-icon-wrap">
										{
											item.focus_deal ? <WhatshotIcon style={{ color: '#FF0000' }} /> : ""
										}
										<div
											onMouseOver={(evt) =>
												this.onDetailMouseEnter(evt, item.company_id)
											}
											onMouseOut={(evt) => this.onDetailMouseLeave(evt)}
											className="flex-row detail-icon">
											<BiDetail className="pointer-none" />
										</div>
										{/* {item.watchlist ? (
											<span
												onClick={(event) =>
													this.doToggleWatchList(
														{
															companyId: item.company_id,
															watchlist: item.watchlist,
														},
														event
													)
												}
												style={{ color: "#FFC50D" }}
											>
												<Tooltip title="Remove from watchlist">
													<Star />
												</Tooltip>
											</span>
										) : (
											<span
												onClick={(event) =>
													this.doToggleWatchList(
														{
															companyId: item.company_id,
															watchlist: item.watchlist,
														},
														event
													)
												}
												style={{ color: "#AFABAB" }}
											>
												<Tooltip title="Add to watchlist">
													<StarBorder />
												</Tooltip>
											</span>
										)} */}
									</div>
								</div>
								<img className="case-img mt-2" src={item.logo} alt="" />
								<div className="pr-1 market-info">
									<div className="valuation-wrap">
										<span className="market-label">Valuation: </span>
										<span className="home-company-info">
											{item.selected_post_money_valuation
												? item.selected_post_money_valuation > 1000
													? "US$" +
														(item.selected_post_money_valuation / 1000).toFixed(
															1
														) +
														"B"
													: "US$" +
														item.selected_post_money_valuation.toFixed(0) +
														"M"
												: "-"}
										</span>
									</div>
									<div className="location-wrap">
										<span className="market-label">Location: </span>
										<span className="home-company-info">
											{item.headquarter_country}
										</span>
									</div>
									<div className="stage-wrap">
										<span className="market-label">Stage: </span>
										<span className="home-company-info">
											{item.selected_deal_type_1 && item.selected_deal_type_1.indexOf('Stage VC') > -1 ? item.selected_deal_type_1 : "-"}
											{item.selected_deal_type_1 &&
											item.selected_deal_type_1.indexOf('Stage VC') > -1 &&
											item.selected_deal_type_2 &&
											item.selected_deal_type_2.trim() ? " (Series " + item.selected_deal_type_2 + ")" : ""}
										</span>
									</div>
									{item.status == "DEAL LIVE CONFIRMED" &&
										this.renderCompanyLiveTag()}
								</div>
							</Link>
						);
					})}
			</div>
		);
	};

	doDiscountPremiumSelect = (value, event) => {
		if(event && event.preventDefault) event.preventDefault();
		if(event && event.stopPropagation) event.stopPropagation();
		// discount premium all
		let { discountPremium, selectedDP } = this.state;
		let newDiscountPremium = "";
		if (!discountPremium) {
			newDiscountPremium = value;
		} else {
			if (value === discountPremium) {
				newDiscountPremium = "";
			} else {
				if (discountPremium === "all") {
					newDiscountPremium = value == "discount" ? "premium" : "discount";
				} else {
					newDiscountPremium = "all";
				}
			}
		}
		switch (newDiscountPremium) {
			case "all":
				selectedDP = selectedDP.map((item) => {
					item.selected = true;
					return item;
				});
				break;
			case "discount":
				selectedDP = selectedDP.map((item, index) => {
					if (index <= 2) {
						item.selected = true;
					} else {
						item.selected = false;
					}
					return item;
				});
				break;
			case "premium":
				selectedDP = selectedDP.map((item, index) => {
					if (index > 2) {
						item.selected = true;
					} else {
						item.selected = false;
					}
					return item;
				});
				break;
			default:
				selectedDP = selectedDP.map((item) => {
					item.selected = false;
					return item;
				});
				break;
		}
		this.setState({
			selectedDP: selectedDP,
			discountPremium: newDiscountPremium,
		});
	};

	handleToggle = (key, value, event) => {
		if(event && event.preventDefault) event.preventDefault();
		if(event && event.stopPropagation) event.stopPropagation();
		const newly_selected = this.state[key].map((item) => {
			if (item.id == value) {
				item.selected = !item.selected;
				if (key === "selectedVertical" && !item.selected) {
					this.setState({
						selectAllVT: false,
					});
				}
				if (key === "selectedHQCountry" && !item.selected) {
					this.setState({
						selectAllHC: false,
					});
				}
			}
			return item;
		});
		this.setState({ [key]: newly_selected });
	};

	getNewMarketIntelligence = () => {
		const {
			rowsPerPage,
			page,
			tags,
			selectedDP,
			selectedTotal,
			selectedVertical,
			selectedHQCountry,
			valuation,
			showClearFilter,
			valuationSort,
			stageSort,
		} = this.state;

		let order_by = "";
		if( valuationSort.toLowerCase() != "disabled" ){
			order_by = "valuation_" + valuationSort.toLowerCase();
		} else if ( stageSort.toLowerCase() != "disabled" ){
			order_by = "stage_" + stageSort.toLowerCase();
		}

		this.props.getMarketIntelligence({
			limit: rowsPerPage,
			offset: page * rowsPerPage,
			tags: tags,
			selected: selectedDP,
			selectedTotal,
			selectedVertical,
			selectedHQCountry,
			order_by,
			valuation: valuation,
		});

		if(
			(selectedDP.filter(item => !item.selected)).length ||
			(selectedTotal.filter(item => !item.selected)).length ||
			(selectedVertical.filter(item => !item.selected)).length ||
			(selectedHQCountry.filter(item => !item.selected)).length ||
			tags.length ||
			valuation.min != 100 ||
			valuation.max != 30000
			){
				this.setState({ showClearFilter: true });
			} else {
				this.setState({ showClearFilter: false });
			}

	};

	comfirmFilter = (event) => {
		this.setState({
			newMarketIntelligenceList: [],
		});
		if(event && event.stopPropagation) event.stopPropagation();
		this.toggleFilter(false, event);
		this.setState({
			valuationDefaultValues: [
				this.state.valuation.min,
				this.state.valuation.max - this.state.valuation.min,
				9900 - this.state.valuation.max,
			],
		});
		this.clearDataAndGetNewMarketIntelligence();
	};

	sortSelectedVertical = (arr) => {
		if (arr.length <= 0) {
			return;
		}
		let newArr = arr.sort(function (a, b) {
			return a["name"]["length"] - b["name"]["length"];
		});
		return newArr;
	};

	toggleFilterShowMore = (event) => {
		if(event && event.stopPropagation) event.stopPropagation();
		this.setState({
			showFilterMore: !this.state.showFilterMore,
		});
	};

	onValuationChange = (event, newValue) => {
		// console.log("slide event ", event, newValue);
		let min = newValue[0];
		let max = newValue[1];
		let valuation = {
			min: min,
			max: max,
		};
		this.setState({
			valuation: valuation,
		});
	};

	toggleAllCheckbox(allSelectedKey, listKey, new_selected_state, evt) {
		const new_list = this.state[listKey].map((item) => {
			item.selected = new_selected_state;
			return item;
		});

		this.setState({
			[allSelectedKey]: new_selected_state,
			[listKey]: new_list,
		});
	}

	stopFilterPropagation = (event) => {
		if(event && event.stopPropagation) event.stopPropagation();
	};

	handleToggleChange(sortType, evt, newValue){
		const reset_sort_type = sortType=="valuationSort" ? "stageSort" : "valuationSort";
		this.setState({ [sortType]: newValue, [reset_sort_type]: "disabled" });
	}

	renderFilterWindow() {
		let {
			discountPremium,
			selectedVertical,
			selectedHQCountry,
			showFilterMore,
			valuation,
			valuationSort,
			stageSort,
		} = this.state;
		let newSelectedVertical = selectedVertical;
		let maxVal = valuation.max;
		let minVal = valuation.min;
		if (maxVal >= 1000){
			maxVal = (maxVal / 1000).toFixed(2);
			maxVal = `US$${maxVal}B`
		} else {
			maxVal = `US$${maxVal}M`
		}
		if (minVal >= 1000){
			minVal = (minVal / 1000).toFixed(2);
			minVal = `US$${minVal}B`
		} else {
			minVal = `US$${minVal}M`
		}
		return (
			<div
				onClick={(event) => this.stopFilterPropagation(event)}
				className="filter-window"
			>
				<h4>Filter</h4>
				<div className="flex-row window-item-list">
					<div className="filter-window-item">
						<div className="market-tag-title">DISCOUNT/PREMIUM</div>
						<div className="flex-row market-tag-wrap">
							<span
								className={`market-tag ${
									discountPremium === "discount" || discountPremium === "all"
										? "market-tag-focus"
										: ""
								} `}
								onClick={(event) =>
									this.doDiscountPremiumSelect("discount", event)
								}
							>
								Discount
							</span>
							<span
								className={`market-tag ${
									discountPremium === "premium" || discountPremium === "all"
										? "market-tag-focus"
										: ""
								}`}
								onClick={(event) =>
									this.doDiscountPremiumSelect("premium", event)
								}
							>
								Premium
							</span>
						</div>
						<div className="market-tag-title">VERTICALS</div>
						<div
							className={`flex-row market-tag-wrap ${
								showFilterMore ? "" : "tag-wrap-max-height"
							}`}
						>
							<span
								onClick={this.toggleAllCheckbox.bind(
									this,
									"selectAllVT",
									"selectedVertical",
									!this.state.selectAllVT
								)}
								className={`line-one market-tag ${
									this.state.selectAllVT ? "market-tag-focus" : ""
								}`}
							>
								All
							</span>
							{newSelectedVertical &&
								newSelectedVertical.length > 0 &&
								newSelectedVertical.map((item, index) => (
									<span
										onClick={(event) =>
											this.handleToggle("selectedVertical", item.id, event)
										}
										key={index}
										className={`line-one market-tag ${
											item.selected ? "market-tag-focus" : ""
										}
										${item.name.length > 13 ? "big-width-tag" : ""}
										`}
									>
										{item.name}
									</span>
								))}
						</div>
					</div>
					<div className="filter-window-line"></div>
					<div className="filter-window-item">
						<div className="market-tag-title">VALUATION</div>
						<div className="range_value">
							<span>The selected range:&nbsp;</span>
							<span style={{ color: "#ECAA32" }}>
								{minVal} - {maxVal}
							</span>
						</div>
						<div
							className="range_wrap"
							onClick={(event) => { if(event && event.stopPropagation) event.stopPropagation()}}
						>
							{/* <RangeDoubleSide
								trackSize="3px"
								handleSize="5px"
								onChange={(value) => this.onValuationChange(value)}
								defaultValues={this.state.valuationDefaultValues}
							/> */}
							<FilterSlider
								// onChangeCommitted={(event, newValue) =>
								//   this.onValuationChange(event, newValue)
								// }
								onChange={(event, newValue) =>
									this.onValuationChange(event, newValue)
								}
								value={[valuation.min, valuation.max]}
								step={100}
								min={100}
								max={30000}
							/>
						</div>
						<div className="flex-row-between range-tip">
							<span>US$100M</span>
							<span>US$30B</span>
						</div>
						<div className="market-tag-title">LOCATION</div>
						<div
							className={`flex-row market-tag-wrap ${
								showFilterMore ? "" : "tag-wrap-max-height shorter"
							}`}
						>
							<span
								onClick={this.toggleAllCheckbox.bind(
									this,
									"selectAllHC",
									"selectedHQCountry",
									!this.state.selectAllHC
								)}
								className={`line-one market-tag ${
									this.state.selectAllHC ? "market-tag-focus" : ""
								}`}
							>
								All
							</span>
							{selectedHQCountry &&
								selectedHQCountry.length > 0 &&
								selectedHQCountry.map((item, index) => (
									<span
										onClick={(event) =>
											this.handleToggle("selectedHQCountry", item.id, event)
										}
										key={index}
										className={`line-one market-tag ${
											item.selected ? "market-tag-focus" : ""
										}
										${item.name.length > 13 ? "big-width-tag" : ""}
										`}
									>
										{item.name}
									</span>
								))}
						</div>
					</div>
				</div>
				<div className="sort-panel">
					<h2>Sort</h2>
					<div className="row">
						<div className="col col-md-4">
							<div className="market-tag-title">VALUATION</div>
							<ToggleButtonGroup
								value={valuationSort}
								exclusive
								onChange={this.handleToggleChange.bind(this, 'valuationSort')}
								>
									<ToggleButton value="disabled" aria-label="left aligned">
										<span>Disable</span>
									</ToggleButton>
									<ToggleButton value="asc" aria-label="centered">
										<span>A-Z</span>
									</ToggleButton>
									<ToggleButton value="desc" aria-label="right aligned">
										<span>Z-A</span>
									</ToggleButton>
							</ToggleButtonGroup>
						</div>
						<div className="col col-md-4">
							<div className="market-tag-title">STAGE</div>
							<ToggleButtonGroup
								value={stageSort}
								exclusive
								onChange={this.handleToggleChange.bind(this, 'stageSort')}
								>
									<ToggleButton value="disabled" aria-label="left aligned">
										<span>Disable</span>
									</ToggleButton>
									<ToggleButton value="asc" aria-label="centered">
										<span>A-Z</span>
									</ToggleButton>
									<ToggleButton value="desc" aria-label="right aligned">
										<span>Z-A</span>
									</ToggleButton>
							</ToggleButtonGroup>
						</div>
						<div className="col col-md-4"></div>
					</div>
				</div>
				<div
					className="filter-show-more"
					onClick={(event) => this.toggleFilterShowMore(event)}
				>
					{showFilterMore ? "Collapse" : "Show More"}
					{showFilterMore ? <ExpandLess /> : <ExpandMore />}
				</div>
				<div className="flex-row btn-list">
					<span
						onClick={(event) => this.toggleFilter(false, event, "Cancel")}
						className="market-btn"
					>
						Cancel
					</span>
					<span
						onClick={(event) => this.comfirmFilter(event)}
						className="market-btn market-btn-focus"
					>
						Show Result
					</span>
				</div>
			</div>
		);
	}

	toggleFilter = (value, event, type) => {
		if(event && event.stopPropagation) event.stopPropagation();
		if(event && event.preventDefault) event.preventDefault();
		this.setState({
			showFilter: value,
		});
		if (type && type == "Cancel") {
			this.setState({
				valuationDefaultValues: [0, 9900, 0],
				valuation: {
					min: 100,
					max: 30000,
				},
			});
		}
	};

	renderMarketIntelligence() {
		const { loaderState } = this.props;
		let { newMarketIntelligenceList } = this.state;
		let { showFilter, showClearFilter } = this.state;
		// console.log( newMarketIntelligenceList );
		return (
			<div id="marketIntelligence" className="market-intelligence">
				<div className="flex-row-between">
					<div className="home-list-name">
						<h3>MARKET INTELLIGENCE</h3>
					</div>
					<div className="flex-row-center mb-2">
						{/* Display clear filter */}
						{ showClearFilter ?
							<Button className="btn-clear-filter1" onClick={this.handleClearFilter.bind(this)}>
								Clear filter
							</Button>
							: '' }
						<div className="flex-row-center market-border">
							<input
								className="market-input"
								type="text"
								placeholder="Search by company name. Press enter to search"
								value={this.state.keyWord}
								onKeyUp={(e) => this.keyWordKeyUp(e)}
								onChange={(e) => this.keyWordInput(e)}
							/>
							<span onClick={this.doSearch} className="search-icon-wrap">
								<BsSearch className="search-icon" />
							</span>
						</div>
						<div
							onClick={(event) =>
								this.toggleFilter(!this.state.showFilter, event)
							}
							className="market-filter"
						>
							<BiFilterAlt className="market-filter-icon" />
							<span className="market-filter-text">Filter / Sort</span>
							{showFilter && this.renderFilterWindow()}
						</div>
					</div>
				</div>
				{this.renderCardList(newMarketIntelligenceList, "marketintelligence")}
			</div>
		);
	}

	renderMostViewList() {
		const { popular_companies } = this.props;
		return (
			<div id="most-view" className="market-intelligence">
				<div className="home-list-name">
					<h3>MOST VIEWED LIVE OPPORTUNITIES</h3>
				</div>
				{this.renderCardList(popular_companies, "marketintelligence")}
			</div>
		)
	}

	renderWatchList() {
		const { watchListResponse } = this.props;
		let watch_list_response =
			watchListResponse && watchListResponse.results
				? watchListResponse.results
				: [];
		return (
			<div  id="watchlist" className="market-intelligence">
				<div className="home-list-name">
					<h3>WATCHLIST</h3>
				</div>
				{this.renderCardList(watch_list_response, "watchList")}
			</div>
		);
	}

	handleClearFilter = (evt) => {
		const {
			selectedDP,
			selectedTotal,
			selectedVertical,
			selectedHQCountry,
		} = this.state;

		let newSelectedDP = selectedDP.map(item => {
			item.selected = true;
			return item;
		})

		let newselectedTotal = selectedTotal.map(item => {
			item.selected = true;
			return item;
		})

		let newSelectedVertical = selectedVertical.map(item => {
			item.selected = true;
			return item;
		})

		let newSelectedHQCountry = selectedHQCountry.map(item => {
			item.selected = true;
			return item;
		})

		this.setState({
			valuation: {
				min: 100,
				max: 30000,
			},
			selectedDP: newSelectedDP,
			selectedTotal: newselectedTotal,
			selectedVertical: newSelectedVertical,
			selectedHQCountry: newSelectedHQCountry,
			keyWord: '',
		}, this.doSearch)
	}

	handleChangeExpressInterestValue = (evt) => {
		const { name, value } = evt.target;
		const { expressInterest } = this.state;
		expressInterest[name] = value;
		this.setState({ expressInterest: expressInterest });
	};

	handleSubmitExpressInterest = (evt) => {
		const { expressInterest } = this.state;
		if (expressInterest.company.trim()) {
			const { createUserMessage } = this.props;
			let message = "Investor interest (Other company) received: \n";
			message += "Company: " + expressInterest.company.trim() + " \n";
			message += "Comment: " + expressInterest.comment.trim() + " \n";

			const payload = { message: message };
			createUserMessage(payload);
		} else {
			const { snackBar } = this.state;
			snackBar['open'] = true;
			snackBar['error'] = true;
			snackBar['message'] = "Please fill in the company you are interested in.";
			this.setState({
				snackBar: snackBar
			});
		}
	};

	handleChangeReferralValue = (evt) => {
		const { name, value } = evt.target;
		const { referral } = this.state;
		referral[name] = value;
		this.setState({ referral: referral });
	};

	handleSubmitReferral = () => {
		const { referral } = this.state;

		if (
			referral.first_name.trim() &&
			referral.last_name.trim() &&
			referral.email.trim()
		) {
			const { postReferral } = this.props;

			const payload = {
				first_name: referral.first_name,
				last_name: referral.last_name,
				email: referral.email,
			};

			postReferral(payload);
		} else {
			const { snackBar } = this.state;
			snackBar['open'] = true;
			snackBar['error'] = true;
			snackBar['message'] = "Please fill in the full name and email.";
			this.setState({
				snackBar: snackBar
			});
		}
	};

	toggleBottomForm = () => {
		this.setState({
			expandMore: !this.state.expandMore,
		});
		sendGAEvent({
			type:"click_tell_us",
			data:{
				type: this.state.expandMore ? 'collapse' : 'expand',
			}
		})
	};

	handleSnackbarClose = (evt, reason) => {
		if (reason === "clickaway") {
			return;
		}

		const { resetSnackBar } = this.props;
		resetSnackBar();

		this.setState({
			snackBar: {
				open: false,
				error: false,
				message: "",
			},
			toggleWatchListMessage: "",
			referral: {
				first_name: "",
				last_name: "",
				email: "",
			},
			expressInterest: {
				company: "",
				comment: "",
			},
		});
	};

	render() {
		let {
			tabFocusIndex,
			showWatchList,
			referral,
			expressInterest,
			expandMore,
			// openStyle,
			// expressInterestError,
			// expressInterestResponseMessage,
			snackBar,
		} = this.state;

		const accessToken = GetToken();
		const greeting = GenerateGreeting();
		const { pipelineResponse } = this.props;

		let tabs = ["DEAL SHOWCASE", "MARKET INTELLIGENCE", "WATCHLIST"];

		const {
			collapseSidebar
		} = this.props;

		const home_right_class = collapseSidebar ? 'home-right narrow' : 'home-right';
		
		let control_panel_class = 'control-panel';
		if( expandMore ) control_panel_class += " show-panel-more";
		if( collapseSidebar ) control_panel_class += ' narrow';
		

		const pipeline_response =
			pipelineResponse && pipelineResponse.results
				? pipelineResponse.results
				: [];
		return (

				<div className="home">
					<div className="top-padding"></div>
					<PipelineSidebar
						tabFocusIndex={tabFocusIndex}
						{...this.props}
						/>
					<div className={home_right_class} id="home-right">
						{!showWatchList ? (
							<div className="pipeline_bg">
								<div className="home-content">
									{ this.renderMostViewList() }
								</div>
								<div className="home-content">
									{ this.renderShowcase(pipeline_response) }
								</div>
								<div className="home-content">
									{ this.renderMarketIntelligence() }
								</div>
								<div className="row">
									<div className="col col-12 mt-3">
										<DisclaimerData />
									</div>
								</div>
							</div>
						) : (
							<div className="home-content">{this.renderWatchList()}</div>
						)}
						
						{!this.state.showWatchList && (
							<div className={control_panel_class}>
								<div
									onClick={this.toggleBottomForm}
									className="flex-row arrow-wrap"
								>
									{!expandMore ? <ExpandLess /> : <ExpandMore />}
								</div>
								<div className="container">
									<div className="row">
										<div className="col col-12 col-md-6">
											<div className="row">
												<div className="col col-12">
													<span className="label">
														What's next? Tell us your preference!
													</span>
												</div>
											</div>
											<div className="row">
												<div className="col col-12">
													<TextField
														margin="normal"
														id="company"
														name="company"
														value={expressInterest.company_name}
														onChange={(e) =>
															this.handleChangeExpressInterestValue(e)
														}
														label="Company"
														variant="outlined"
														InputLabelProps={{ shrink: true }}
														fullWidth
													/>
												</div>
											</div>
											<div className="row">
												<div className="col col-12">
													<TextField
														margin="normal"
														id="comment"
														name="comment"
														value={expressInterest.comment}
														onChange={(e) =>
															this.handleChangeExpressInterestValue(e)
														}
														label="Comment"
														fullWidth
														multiline={true}
														rows={4}
														rowsMax={4}
														placeholder="E.g. Target Price $15, Ready to sign mandate"
														variant="outlined"
														InputLabelProps={{ shrink: true }}
													/>
												</div>
											</div>
											<div className="row">
												<div className="col col-12">
													<Button
														className="btn-submit-interest btn-submit"
														onClick={this.handleSubmitExpressInterest}
													>
														Submit
													</Button>
												</div>
											</div>
										</div>
										<div className="col col-12 col-md-6 border-left">
											<div className="row">
												<div className="col col-12">
													<span className="label">Refer a friend</span>
												</div>
											</div>
											<div className="row">
												<div className="col col-12">
													<TextField
														margin="normal"
														id="first_name"
														name="first_name"
														value={referral.first_name}
														onChange={(e) => this.handleChangeReferralValue(e)}
														label="First Name"
														variant="outlined"
														InputLabelProps={{ shrink: true }}
														fullWidth
													/>
												</div>
											</div>
											<div className="row">
												<div className="col col-12">
													<TextField
														margin="normal"
														id="last_name"
														name="last_name"
														value={referral.last_name}
														onChange={(e) => this.handleChangeReferralValue(e)}
														label="Last Name"
														variant="outlined"
														InputLabelProps={{ shrink: true }}
														fullWidth
													/>
												</div>
											</div>
											<div className="row">
												<div className="col col-12">
													<TextField
														margin="normal"
														id="email"
														name="email"
														value={referral.email}
														onChange={(e) => this.handleChangeReferralValue(e)}
														label="Email"
														variant="outlined"
														InputLabelProps={{ shrink: true }}
														fullWidth
													/>
												</div>
											</div>
											<div className="row">
												<div className="col col-12">
													<Button
														className="btn-refer-a-friend btn-submit"
														onClick={this.handleSubmitReferral}
													>
														Submit
													</Button>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						)}
					</div>
					<Snackbar
						anchorOrigin={{
							vertical: "top",
							horizontal: "center",
						}}
						openStyle={snackBar.open}
						handleSnackbarClose={this.handleSnackbarClose}
						type={snackBar.error ? "error" : "success"}
						message={snackBar.message}
						autoHideDuration={2000}
						/>
				</div>

		);
	}
}

export default withStyles(Styles)(Pipeline);
