import {
    CreateApiAction as _createApiAction
} from '../../../utils';

const key = 'UPDATE_RESET_PASSWORD';

const CreateApiAction = _createApiAction(key);

const ConfirmPasswordReset = CreateApiAction('CONFIRM_PASSWORD_RESET');

export {
    ConfirmPasswordReset
};