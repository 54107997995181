import React from 'react';
import { makeStyles } from "@material-ui/core/styles";

const usePlaceholderStyles = makeStyles(theme => ({
    placeholder: {
      color: "#aaa"
    }
}));
  
export const SelectPlaceholder = ({ children }) => {
    const classes = usePlaceholderStyles();
    return <div className={classes.placeholder}>{children}</div>;
};