import { default as RemoveToken } from './RemoveToken';
import { TOKEN_MAX_AGE } from './util';

export default () => {
    // localStorage.getItem('authData') ? JSON.parse(localStorage.getItem('authData')) : {}
    const authDataName = 'authData';
    const authData = localStorage.getItem(authDataName) ? JSON.parse(localStorage.getItem(authDataName)) : {} 
    // console.log( Date.now() - authData.set_time );
    // console.log( Date.now() - authData.set_time > TOKEN_MAX_AGE );
    if( authData.set_time && Date.now() - authData.set_time > TOKEN_MAX_AGE ){
        RemoveToken(authDataName);
        return {};
    } else {
        return authData
    }
    
}