import {
    put,
    call,
    takeLatest,
    all,
} from 'redux-saga/effects';

import { Unauthorized } from '../../../login/Action';

import {
    getSearchCompany
} from './Action';

import {
    orderbook
} from '../../../../api';

import {
    NewRelicError
} from '../../../../utils';
  
import {
    logEventInternallyAndNewRelic
} from '../../../../utils/ga';

function* getSearchCompanyResponder({ payload }){
    try{
        let query_string = "?";
        let counter = 0;
        if( payload.qs ){
            for( var key in payload.qs ){
                if( counter ) query_string += "&";
                query_string += key+"="+payload['qs'][key];
                counter++;
            }
        }
        const { data } = yield call(orderbook.getSearchCompany, query_string, payload);
        yield put(getSearchCompany.success(data));
    } catch(e){
        if( e.message && e.response ){
            const err_payload = {
                type: 'api_failure_orderbookSearchgetSearchCompanyResponder',
                data: {
                    config: e.response.config,
                    data: e.response.data,
                    status: e.response.status
                },
                err: new Error("API Failure orderbookSearchgetSearchCompanyResponder: " + e.message)
            }
            logEventInternallyAndNewRelic(err_payload);
        } else if (e.message) {
            NewRelicError( new Error("API Failure orderbookSearchgetSearchCompanyResponder: " + e.message) );
        } else {
            NewRelicError( new Error("API Failure orderbookSearchgetSearchCompanyResponder with no message") );
        }
        if (e.response && e.response.status == 401) {
          yield put(Unauthorized(e));
        }
        yield put(getSearchCompany.failure(e));
    } finally{

    }
}

function* saga(){
    yield all([
        takeLatest(getSearchCompany.request, getSearchCompanyResponder)
    ])
}

export default saga;