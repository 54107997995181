import {
    CreateApiAction as _createApiAction,
    CreateKeyAction as _createKeyAction,
} from '../../../../utils';

const key = 'ORDERBOOK_COMP_SEARCH';

const CreateApiAction = _createApiAction(key);

const getSearchCompany = CreateApiAction('SEARCH_COMPANY');

const CreateKeyAction = _createKeyAction(key);
const clearNoMatch = CreateKeyAction('CLEAR_NO_MATCH');

export {
    getSearchCompany,
    clearNoMatch,
}