export function ConvertDiscPrem(disc_prem) {
    if( disc_prem < -0.4 ){
        return "40%+ Discount^";
    } else if( disc_prem < -0.2 ){
        return "20% to 40% Discount^";
    } else if( disc_prem < -0.1 ){
        return "10% to 20% Discount^";
    } else if( disc_prem < 0 ){
        return "0% to 10% Discount^";
    } else if( disc_prem < 0.1 ){
        return "0% to 10% Premium^";
    } else if( disc_prem < 0.2 ){
        return "10% to 20% Premium^";
    } else if( disc_prem < 0.4 ){
        return "20% to 40% Premium^";
    } else {
        return "40%+ Premium^";
    }
}

export const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export function GeneratePipelineItemMetaInfo(scale, selected_post_money_valuation, selected_price){
    const price_range = "$" + scale.scale_start.toFixed(2) + ' - $' + scale.scale_end.toFixed(2);
    const medium_price_point = ( scale.scale_end + scale.scale_start ) / 2;
    const disc_prem = medium_price_point / selected_price - 1;
    const disc_prem_range = selected_price ? ConvertDiscPrem(disc_prem) : "-";

    let implied_valuation_display = " - ";
    if( selected_price && selected_post_money_valuation ){
        let implied_valuation = ( disc_prem + 1 ) * selected_post_money_valuation;

        if( implied_valuation > 1000 ){
            implied_valuation_display = "$" + ( implied_valuation / 1000 ).toFixed(2) + "B^";
        } else {
            implied_valuation_display = "$" + implied_valuation.toFixed(0) + "M^";
        }
    }

    return {
        price_range,
        medium_price_point,
        disc_prem,
        disc_prem_range,
        implied_valuation_display
    }    
}

export const passwordRegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^\&\*\(\)_\-=+\{\}|\[\]\\:;\?,\.\/\~])(?=.{12,})/;