import React from 'react';

import {
    Grid,
    Box,
    Typography,
    withStyles,
    TextField,
    CircularProgress,
    FormLabel,
    Divider,
    Button,
} from '@material-ui/core';

import {
    Autocomplete, ToggleButton, ToggleButtonGroup,
} from '@material-ui/lab';

import {
    sendGAEvent,
} from '../../../../utils/ga';

import {
    ReScaleForDeviceScale,
} from '../../../../utils/util';

import {
    global150BodyFont,
    globalBodyFont,
} from '../../../styles';

import {
    FormStyle,
} from '../../../orderbook/common/FormStyle';


class SubmitPortfolio extends React.Component{
    state = {
        open: false,
        options: [],
        loading: false,
        share_class_options: [
            'Common',
            'Preferred',
            'Others',
        ],
        selected_share_class: '',
        share_class_text: '',
        selected_company: {
            company_id: undefined,
            company_name: ''
        },
        portfolio_payload: {
            price: undefined,
            no_of_shares: undefined,
            holding_date: undefined,
            is_primary: false,
            investment_type: undefined,
            management_fee: undefined,
            carry_interest: undefined,
            share_class: '',
        },
        company_not_selectable: false,
    }

    constructor(props){
        super(props);
    }

    static getDerivedStateFromProps(nextProps, prevState){
        const {
            search_company_loading,
            searchCompanyResponse,
        } = nextProps;

        if( search_company_loading && !prevState.loading ){
            return { loading: true }
        } else if(!search_company_loading && prevState.loading ){
            return { options: searchCompanyResponse, loading: false }
        } else {
            return null;
        }

    }

    handleSearchCompany = (evt) => {
        if( evt ){
            const { target: { name, value } } = evt;
            const { getSearchCompany } = this.props;
            if( value && value.trim() ){
                sendGAEvent({
                    type: 'submit_portfolio_search_company',
                    data: {
                        keyword: value
                    }
                });
                getSearchCompany({ qs: { search_string: value } });
            }
        }
    }

    handleSelectCompany = (evt, value) => {
        const { portfolio_payload } = this.state;
        const { lock_company } = this.props;
        if( value && value.company_id && value.company_name ){
            portfolio_payload['company_id'] = value.company_id;
            sendGAEvent({
                type: 'submit_portfolio_select_company',
                data: {
                    company_id: value.company_id
                }
            });
            this.setState({ selected_company: value, portfolio_payload: portfolio_payload }, () => {
                // if(!lock_company){
                //     const { getFundingRound } = this.props;
                //     getFundingRound({ 
                //         root_path: 'v2',
                //         sub_path: 'orderbook',
                //         company_identifier: '',
                //         qs: { company_id: value.company_id, show_all: 1 }
                //     });
                // }
            });
        } else if ( !value ){
            this.setState({ selected_company: { company_id: 0, company_name: '' } });
        }
    }

    handleOpen( open, evt ) {
        this.setState({ open: open });
    }

    handleInputChange(key, evt, newValue) {
        const value_to_apply = newValue ? newValue : evt.target && evt.target.value ? evt.target.value : '';
        console.log( key );
        console.log( newValue );
        const { portfolio_payload } = this.state;
        portfolio_payload[key] = value_to_apply;
        this.setState({ portfolio_payload });
    }

    handleSubmitOrder = (evt) => {
        const { portfolio_payload } = this.state;

    }

    render(){
        const { 
            open,
            options, 
            company_not_selectable,
            portfolio_payload,
            selected_company,
            share_class_options,
            selected_share_class,
            share_class_text,
        } = this.state;
        const { 
            search_company_loading,
            lock_company,
            classes,
        } = this.props;

        return (
            <section>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Box>
                            <Typography variant="h6">Submit Portfolio</Typography>
                            <p className="elaborate-text"></p>
                        </Box>
                    </Grid>
                    {
                        !company_not_selectable && !lock_company ?
                            <Grid item xs={12}>
                                <Autocomplete
                                    id="company-name-input"
                                    open={open}
                                    onOpen={this.handleOpen.bind(this, true)}
                                    onClose={this.handleOpen.bind(this, false)}
                                    onInputChange={this.handleSearchCompany}
                                    onChange={this.handleSelectCompany}
                                    getOptionSelected={(option, value) => option.company_name === value.company_name}
                                    getOptionLabel={(option) => option.company_name}
                                    options={options}
                                    loading={search_company_loading}
                                    value={selected_company}
                                    placeholder="Company Name"
                                    size="small"
                                    renderInput={(params) => {
                                        return (
                                            <TextField
                                                {...params}
                                                placeholder="Company Name"
                                                className={classes.TextField}
                                                InputLabelProps={{ shrink: true }}
                                                value={selected_company.company_name}
                                                variant="outlined"
                                                InputProps={{
                                                    ...params.InputProps,
                                                    endAdornment: (
                                                        <React.Fragment>
                                                            {search_company_loading ? <CircularProgress color="inherit" size={20} /> : null}
                                                            {params.InputProps.endAdornment}
                                                        </React.Fragment>
                                                    )
                                                }}
                                                />
                                        )
                                    }}
                                    />
                            </Grid> 
                            : ''
                    }

                    <Grid item xs={12}>
                        <FormLabel
                            component="legend"
                            className={classes.FormLabel}
                            >Acquire Date</FormLabel>
                        <TextField
                            id="acquire-date"
                            placeholder="Acquire Date"
                            variant="outlined"
                            className={classes.TextField}
                            value={portfolio_payload.holding_date}
                            size="small"
                            type="date"
                            InputLabelProps={{ shrink: true }}
                            onChange={this.handleInputChange.bind(this, 'holding_date')}
                            />
                    </Grid>

                    <Grid item xs={12}>
                        <FormLabel
                            component="legend"
                            className={classes.FormLabel}
                            >No. of shares</FormLabel>
                        <TextField
                            id="no-of-shares"
                            name="no_of_shares"
                            placeholder='No. of shares'
                            variant="outlined"
                            className={classes.TextField}
                            value={portfolio_payload.no_of_shares}
                            size="small"
                            type="number"
                            InputLabelProps={{
                                shrink: true
                            }}
                            onChange={this.handleInputChange.bind(this, 'no_of_shares')}
                            />
                    </Grid>

                    <Grid item xs={12}>
                        <FormLabel
                            component="legend"
                            className={classes.FormLabel}
                            >Entry Price</FormLabel>
                        <TextField
                            id="price"
                            name="price"
                            placeholder='Price per share'
                            variant="outlined"
                            className={classes.TextField}
                            value={portfolio_payload.price}
                            size="small"
                            type="number"
                            InputLabelProps={{
                                shrink: true
                            }}
                            onChange={this.handleInputChange.bind(this, 'price')}
                            />
                    </Grid>

                    <Grid item xs={12}>
                        <FormLabel
                            component="legend"
                            className={classes.FormLabel}>Type</FormLabel>
                        <ToggleButtonGroup
                            className={classes.ToggleButtonGroup}
                            exclusive
                            value={portfolio_payload.is_primary}
                            onChange={this.handleInputChange.bind(this, 'is_primary')}
                            >
                                <ToggleButton className={classes.TighterToggleButton} value={true} aria-label="left aligned">
                                    Primary
                                </ToggleButton>
                                <ToggleButton className={classes.TighterToggleButton} value={false} aria-label="right aligned">
                                    Secondary
                                </ToggleButton>
                        </ToggleButtonGroup>
                    </Grid>

                    <Grid item xs={12}>
                        <Grid container spacing={0}>
                            <Grid item xs={12}>
                                <FormLabel 
                                    component="legend" 
                                    className={classes.FormLabel}>Share Class</FormLabel>
                            </Grid>
                            <Grid item xs={12}>
                                <ToggleButtonGroup 
                                    className={classes.ToggleButtonGroup}
                                    exclusive 
                                    value={selected_share_class} 
                                    onChange={this.handleInputChange.bind(this, "select_share_class")}>
                                    {
                                        share_class_options.map((item, index) => {
                                            return (
                                                <ToggleButton className={classes.ToggleButton} value={item} key={index}>{item}</ToggleButton>
                                            )
                                        })
                                    }
                                </ToggleButtonGroup>
                                <Divider style={{ marginTop: 16 }} />
                            </Grid>
                            
                            {
                                ['preferred', 'others'].indexOf( selected_share_class.toLowerCase() ) > -1 ?
                                <Grid item xs={12} style={{marginTop: 8}}>
                                    <TextField
                                        id="share-class-input"
                                        placeholder={selected_share_class.toLowerCase() == "preferred" ? "Series (E.g. A, B, C)" : "Share class"}
                                        variant="outlined"
                                        size="small"
                                        className={classes.TextField}
                                        value={share_class_text}
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        onChange={this.handleInputChange.bind(this, 'share_class_text')}
                                        />
                                    </Grid> : ''
                            }
                            
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        <Button
                            className="btn-submit blue-btn"
                            style={{ fontSize: ReScaleForDeviceScale(1.5, global150BodyFont, globalBodyFont) }}
                            onClick={this.handleSubmitOrder}
                            >
                                Submit
                        </Button>
                    </Grid>
                    
                </Grid>
            </section>
        )
    }
}

export default withStyles(FormStyle)(SubmitPortfolio);