import React from 'react';
import ReactECharts from 'echarts-for-react';

import {
    Grid,
    Card,
} from '@material-ui/core';

import {
    rearrange_cost_base,
    get_array_total,
    roundNumber,
} from '../../../../utils/util';

function group_holding_by_company(new_cost_base, company_ids){
    const holding_by_company = [];
    company_ids.forEach(company_id => {
        const matched_ncb = new_cost_base.filter(item => item.company_id == company_id);
        if( matched_ncb.length ){
            const sizes = matched_ncb.map(item => item.price * item.noOfShares);
            holding_by_company.push({
                name: matched_ncb[0]['company'] && matched_ncb[0]['company']['name'] ? matched_ncb[0]['company']['name'] : '',
                value: roundNumber( get_array_total(sizes) / 1000000 ),
            });
        }
    });

    return holding_by_company;
}

class PortfolioDistribution extends React.Component{
    state = {
        company_ids: [],
        sizes: [],
        portfolios: [],
    }

    constructor(props){
        super(props);
    }

    static getDerivedStateFromProps(nextProps, prevState){
        const {
            cost_base,
            company_details,
            price_index,
        } = nextProps;

        const new_cost_base = rearrange_cost_base(cost_base, company_details, price_index);
        const new_portfolio = group_holding_by_company(new_cost_base, Object.keys(cost_base));

        const sizes = new_portfolio.map(item => item.value);

        if( 
            ( Object.keys( cost_base ) ).length &&
            ( Object.keys( company_details ) ).length &&
            (
                !( Object.keys( cost_base ) ).equals(prevState.company_ids) ||
                !( Object.keys( company_details )).equals(prevState.company_ids) ||
                !( sizes.equals(prevState.sizes) )
                )
            ){
                return {
                    company_ids: Object.keys( cost_base ),
                    sizes: sizes,
                    portfolios: new_portfolio,
                }
        } else {
            return null;
        }
        
    }

    getOption = () => {
        const {
            portfolios,
        } = this.state;
        
        const option = {
            title: {
                text: 'Portfolio Distribution',
                left: 'center',
            },
            legend: {
                show: true,
                orient: 'vertical',
                left: 'left',
            },
            tooltip: {
                show: true,
                trigger: 'item'
            },
        }

        if( portfolios.length ){
            option['series'] = [
                {
                    name: 'Holding Vol. ($M)',
                    type: 'pie',
                    radius: '60%',
                    data: portfolios,
                    emphasis: {
                        itemStyle: {
                            shadowBlur: 10,
                            shadowOffsetX: 0,
                            shadowColor: 'rgba(0,0,0,0.5)'
                        }
                    }
                }
            ]
        }

        return option['series'] ? option : {};
    }

    render(){
        return (
            <React.Fragment>
                <section className="price-chart-section orderbook" id="price-chart-section1">
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Card>
                                <div className="chart-wrapper">
                                    <ReactECharts
                                        option={this.getOption()}
                                        style={{height: 400}}
                                        notMerge={true}
                                        />
                                </div>
                            </Card>
                            
                        </Grid>
                    </Grid>
                </section>
            </React.Fragment>
        )
    }
}

export default PortfolioDistribution;