import { fromJS, List, Map } from 'immutable';

export default {
    AllCompaniesResponse: '',
    investors: [],
    verticals: [],
    fundingRounds:[],
    latestFundingRound: {}
}

// const initialImmutableState = fromJS(initialState)
// export default initialImmutableState;