import { fromJS, List, Map } from 'immutable';
export default {
    AllCompaniesResponse: {},
    investors: [],
    verticals: [],
    fundingRounds: {
        count: 0,
        results: []
    },
    finishedCollectFundingRounds: false,
    latestFundingRound: {},
    clubDetail: {},
    employees: [],
    publicNewsArticles: {},
    clubDealLinks: [],
    clubDealTimelines: [],
    toggleWatchError: false,
    toggleWatchListMessage: "",
    historicalPrice: {},
    trial_onboarding: {
        error: false,
        message: ''
    },
    similar_companies: {}
}

// const initialImmutableState = fromJS(initialState)
// export default initialImmutableState;