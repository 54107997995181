import {
    put,
    call,
    takeLatest,
    all,
} from 'redux-saga/effects';

// import { Unauthorized } from '../../../login/Action';

import {
    getSearchCompany
} from './Action';

import {
    wealth_pipeline
} from '../../../../api';

// import {
//     NewRelicError
// } from '../../../../utils';
  
// import {
//     logEventInternallyAndNewRelic
// } from '../../../../utils/ga';

import {
    compileQueryString,
    handleCatchSagaError,
} from '../../../../utils/util';

function* getSearchCompanyResponder({ payload }){
    try{
        const query_string = compileQueryString(payload.qs);
        const { data } = yield call(wealth_pipeline.getSearchDealCompany, query_string, payload);
        yield put(getSearchCompany.success(data));
    } catch(e){
        yield handleCatchSagaError(e, 'api_failure_wealthPortalSearchgetSearchCompanyResponder');
        yield put(getSearchCompany.failure(e));
    } finally{

    }
}

function* saga(){
    yield all([
        takeLatest(getSearchCompany.request, getSearchCompanyResponder)
    ])
}

export default saga;