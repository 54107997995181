import { CreateApiAction as _createApiAction, CreateKeyAction as _createKeyAction  } from '../../../utils'

const key = 'WEALTH_COMPANIES';
const CreateApiAction = _createApiAction(key);
const CreateKeyAction = _createKeyAction(key);

export const getAllCompanies = CreateApiAction('GET_ALL_COMPANIES');

// export const getVerticals = CreateApiAction('GET_VERTICALS');
export const getFundingRound = CreateApiAction('GET_FUNDING_ROUND')
export const getLatestFundingRound = CreateApiAction('GET_LATEST_FUNDING_ROUND');

export const getDealLinks = CreateApiAction('GET_DEAL_LINKS');
export const getDealTimeline = CreateApiAction('GET_DEAL_TIMELINE');
export const getHistoricalPrice = CreateApiAction('GET_HISTORICAL_PRICE');
export const getLiveOffers = CreateApiAction('GET_LIVE_OFFERS');
export const getLiveDemand = CreateApiAction('GET_LIVE_DEMAND');
export const toggleWatchList = CreateApiAction('TOGGLE_WATCH_LIST');

// export const getDealOfferDetail = CreateApiAction('GET_DEAL_OFFER_DETAIL');
export const requestInformation = CreateApiAction('POST_REQUEST_INFORMATION');
export const resetRequestInformation = CreateKeyAction('RESET_REQUEST_INFORMATION');
export const getDealHighlight = CreateApiAction('GET_DEAL_HIGHLIGHT');
export const getDealType = CreateApiAction('GET_DEAL_TYPE');

export const getGPDetail = CreateApiAction('GET_GP_DETAIL');
export const shareDeal = CreateApiAction('SHARE_DEAL');
export const getRiskDisclosure = CreateApiAction('GET_RISK_DISCLOSURE');
export const getSharedUser = CreateApiAction('GET_SHARED_USER');
export const addCount = CreateApiAction('ADD_COUNT');

export const confirmRiskDisclosure = CreateKeyAction('CONFIRM_RISK_DISCLOSURE');

export const getLiveOffersWatchlist = CreateApiAction('GET_LIVE_OFFERS_WATCHLIST');
export const getLiveDemandWatchlist = CreateApiAction('GET_LIVE_DEMAND_WATCHLIST');
export const clearWatchlistMessage = CreateKeyAction('CLEAR_WATCHLIST_MESSAGE');