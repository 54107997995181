import { connect } from 'react-redux';


import {
    getAllCompanies,
    getFundingRound,
    getLatestFundingRound,
    // getVerticals,

    getDealLinks,
    getDealTimeline,
    getHistoricalPrice,
    getLiveOffers,
    getLiveDemand,
    getLiveOffersWatchlist,
    getLiveDemandWatchlist,
    // getDealOfferDetail,
    getDealHighlight,
    getDealType,
    getGPDetail,
    getRiskDisclosure,
    confirmRiskDisclosure,
    getSharedUser,
    addCount,
} from './Action';

import { default as PublicCompanyDetail } from './PublicCompanyDetail';

export default connect(
    state => ({
        AllCompaniesResponse: state.wealthCompany.reducer.AllCompaniesResponse,
        verticals: state.wealthCompany.reducer.verticals,
        fundingRounds: state.wealthCompany.reducer.fundingRounds,
        finishedCollectFundingRounds: state.wealthCompany.reducer.finishedCollectFundingRounds,
        latestFundingRound: state.wealthCompany.reducer.latestFundingRound,
        investors: state.wealthCompany.reducer.investors,
        clubDetail: state.wealthCompany.reducer.clubDetail,
        clubDealLinks: state.wealthCompany.reducer.clubDealLinks,
        dealTimelines: state.wealthCompany.reducer.dealTimelines,
        historicalPrice: state.wealthCompany.reducer.historicalPrice,
        live_offers: state.wealthCompany.reducer.live_offers,
        live_demand: state.wealthCompany.reducer.live_demand,
        live_offers_watchlist: state.wealthCompany.reducer.live_offers_watchlist,
        live_demand_watchlist: state.wealthCompany.reducer.live_demand_watchlist,
        loaderState: state.root.reducer.loaderState,
        showSnakbar: state.account.reducer.showSnakbar,
        loginUserData: state.login.reducer.loginUserData,
        // dealOffer: state.wealthCompany.reducer.dealOffer,
        appConfig: state.root.reducer.appConfig,
        dealHighlight: state.wealthCompany.reducer.dealHighlight,
        dealType: state.wealthCompany.reducer.dealType,
        gp_detail: state.wealthCompany.reducer.gp_detail,
        shared_user: state.wealthCompany.reducer.shared_user,
        riskDisclosureConfirmed: state.wealthCompany.reducer.riskDisclosureConfirmed,
        riskDisclosureResponse: state.wealthCompany.reducer.riskDisclosureResponse,
    }),
    dispatch => ({
        getAllCompanies: payload => dispatch(getAllCompanies.request(payload)),
        getFundingRound: payload => dispatch(getFundingRound.request(payload)),
        getLatestFundingRound: payload => dispatch(getLatestFundingRound.request(payload)),
        // getVerticals: payload => dispatch(getVerticals.request(payload)),
        // getPipeline: payload => dispatch(getPipeline.request(payload)),
        getDealLinks: payload => dispatch(getDealLinks.request(payload)),
        getDealTimeline: payload => dispatch(getDealTimeline.request(payload)),
        getHistoricalPrice: payload => dispatch(getHistoricalPrice.request(payload)),
        getLiveOffers: payload => dispatch(getLiveOffers.request(payload)),
        getLiveDemand: payload => dispatch(getLiveDemand.request(payload)),
        getLiveOffersWatchlist: payload => dispatch(getLiveOffersWatchlist.request(payload)),
        getLiveDemandWatchlist: payload => dispatch(getLiveDemandWatchlist.request(payload)),
        // getDealOfferDetail: payload => dispatch(getDealOfferDetail.request(payload)),
        getDealHighlight: payload => dispatch(getDealHighlight.request(payload)),
        getDealType: payload => dispatch(getDealType.request(payload)),
        getGPDetail: (payload) => dispatch(getGPDetail.request(payload)),
        getRiskDisclosure: (payload) => dispatch(getRiskDisclosure.request(payload)),
        getSharedUser: (payload) => dispatch(getSharedUser.request(payload)),
        confirmRiskDisclosure: () => dispatch(confirmRiskDisclosure()),
        addCount: (payload) => dispatch(addCount.request(payload)),
    })
)(PublicCompanyDetail);