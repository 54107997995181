import React from 'react';
import { Helmet } from 'react-helmet';
import {
    Box,
    Grid,
} from '@material-ui/core';

import { PipelineSidebar } from '../../common';
import {
    content_placeholders,
    feature_flags,
} from '../../../config';

import { WatchlistGroup } from "../../common/watchlist/group"

class Watchlist extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        const {
            collapseSidebar,
        } = this.props;

        const home_right_class = collapseSidebar ? 'home-right narrow' : 'home-right';

        return (
            <div className='home orderbook'>
                <div className='top-padding'></div>
                <Helmet>
                    <title>Orderbook Watchlist | {content_placeholders.title_prefix}</title>
                </Helmet>

                <PipelineSidebar
                    tabFocusIndex="5"
                    collapse={true}
                    {...this.props}
                />

                <div className={home_right_class} id="home-right">
                    <Box m={2}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <WatchlistGroup
                                    companyDetailType="orderbook"
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </div>
            </div>
        )
    }
}

export default Watchlist;