import React, { Component } from 'react';

import './assets/css/vendor.css';
import './assets/styles/main.scss';

import {
	BrowserRouter as Router,
	Route
} from 'react-router-dom';

import { GA4Init } from './utils/ga';

import {
	Login,
	LoginCampaign,
	Root,
	ForgotPassword,
	Pipeline,
	UpdateResetPassword,
	Disclaimer,
	ClubPipeline,
	ClubCompanyDetail,
	TermsOfUse,
	PrivacyPolicy,
	ConfidentialityAgreement,
	RiskDisclosure,
	OrderbookPipeline,
	OrderbookCompany,
	PublicOrderbookCompany,
	PlatformGuide,
	OrderbookAgreement,
	PortfolioHolding,
	OrderbookWatchlist,
	WealthPipeline,
	WealthCompanyDetail,
	WealthTrends,
	PublicWealthCompanyDetail,
	Dashboard,
	WealthPlatformGuide,
	BoostDashboard,
	BoostVetting,
	BoostPipeline,
	OBWPlatformGuide,
} from './modules';

class App extends Component{ 
	
	componentDidMount(){
		//replace with your own G-ID
		// Dev G-ID: G-CJ58BV1584
		// Production G-ID: G-G4FNFGNZZN
		GA4Init(window.ga_code);
	}

	render(){
		return (
			
			<Router>
				<Root>
					<Route exact path="/" component={Login} />
					<Route exact path="/campaign" component={LoginCampaign} />
					<Route exact path="/forgot-password" component={ForgotPassword} />
					<Route exact path="/app/Pipeline" component={Pipeline} />
					<Route exact path="/club/pipeline" component={ClubPipeline} />
					<Route exact path="/club/company-detail/:id" component={ClubCompanyDetail} />
					<Route exact path="/new-password/:uid/:token" component={UpdateResetPassword} />
					<Route exact path="/Disclaimer" component={Disclaimer} />
					<Route exact path="/terms-of-use/:token" component={TermsOfUse} />
					<Route exact path="/terms-of-use" component={TermsOfUse} />
					<Route exact path="/privacy-policy/:token" component={PrivacyPolicy} />
					<Route exact path="/privacy-policy" component={PrivacyPolicy} />
					<Route exact path="/confidentiality-agreement/:token" component={ConfidentialityAgreement} />
					<Route exact path="/confidentiality-agreement" component={ConfidentialityAgreement} />
					<Route exact path="/risk-disclosure/:token" component={RiskDisclosure} />
					<Route exact path="/risk-disclosure" component={RiskDisclosure} />
					<Route exact path="/orderbook/pipeline" component={OrderbookPipeline} />
					<Route exact path="/orderbook/company-detail/:id" component={OrderbookCompany} />
					<Route exact path="/orderbook/public/company-detail/:token" component={PublicOrderbookCompany} />
					<Route exact path="/orderbook/watchlist" component={OrderbookWatchlist} />
					<Route exact path="/ob-platform-guide" component={PlatformGuide} />
					<Route exact path="/wp-platform-guide" component={WealthPlatformGuide} />
					<Route exact path="/ob-w-platform-guide" component={OBWPlatformGuide} />

					<Route exact path="/ob-agreement" component={OrderbookAgreement} />
					<Route exact path="/portfolio-holding" component={PortfolioHolding} />
					<Route exact path="/wealth/pipeline" component={WealthPipeline} />
					<Route exact path="/wealth/company-detail/:id" component={WealthCompanyDetail} />
					<Route exact path="/wealth/trends" component={WealthTrends} />
					<Route exact path="/wealth/public/company-detail/:token" component={PublicWealthCompanyDetail} />
					<Route exact path="/dashboard" component={Dashboard} />
					<Route exact path="/boost/dashboard/:id" component={BoostDashboard}/>
					<Route exact path="/boost/vetting/:id" component={BoostVetting} />
					<Route exact path="/boost/pipeline" component={BoostPipeline} />
				</Root>
			</Router>
	
		)
	}
}

export default App;
