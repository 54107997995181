import {
    CreateApiAction as _createApiAction,
} from '../../../utils';

const key = 'WEALTH_TRENDS';

const CreateApiAction = _createApiAction(key);

const getTrends = CreateApiAction('GET_TRENDS');

export {
    getTrends,
};