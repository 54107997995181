import React, { Component, Fragment } from "react";
import * as Yup from "yup";
import { withStyles } from "@material-ui/core/styles";
import { Styles } from "../../styles";
import { Link } from "react-router-dom";
import deal from "./../../../assets/images/deal.jpg";
import expressInterestIcon from "../../../assets/images/express-interest-icon.png";
import arrowIcon from "../../../assets/images/arrow-icon.png";
import docIcon from "../../../assets/images/doc-icon.png";
import referIcon from "../../../assets/images/refer-icon.png";
import InfoIcon from '@material-ui/icons/Info';
import calendarIcon from "../../../assets/images/calendar-icon.png";
// import CompanyInfo from './CompanyInfo';
import { 
	Button, 
	TextField, 
	InputAdornment, 
	Container,
	Grid, 
	Box,
	Card,
} from "@material-ui/core";
import DisclaimerData from "../../common/DisclaimerData";
import Tooltip from "@material-ui/core/Tooltip";
import { Snackbar } from "../../common";
import { content_placeholders } from '../../../config';
import {
	TeaserDialog,
	VideoDialog,
	ExpressBuySellDialog,
	Sidebar,
	PriceChart,
	OnboardingDialog,
} from "./components";
import OpenInNew from "@material-ui/icons/OpenInNew";
import {
	StarBorder,
	Star,
	ExpandMore,
	ExpandLess
} from '@material-ui/icons';
import { BiDetail, BiFilterAlt } from "react-icons/bi";
import {
	CompanyStatusCalculate,
	getTodayHistoricalPrice,
} from "../../../utils/util";
import { SellSharesDialog } from "../../common/header/dialogs";
import { sendGAEvent } from "../../../utils/ga";
import { GetToken } from "../../../utils";
import WhatshotIcon from '@material-ui/icons/Whatshot';
import { sendLogEvent } from "../../../utils/ga";

const validations = {
	email: Yup.string("app.error-invalid-string")
		.email("*Please enter a valid email")
		.required("*Email is required")
		.trim(),
};

// let termBlockTop, priceBlockTop, timeLineBlockTop,homeRight;

class CompanyDetail extends Component {
	state = {
		deleteId: "",
		deleteModal: false,
		formModal: false,
		formModalEdit: false,
		pdf: {
			open: false,
			url: "",
			link_name: "",
			numPages: 0,
		},
		video: {
			open: false,
			url: "",
			link_name: ""
		},
		expressInterest: {
			open: false,
			price_slider: true,
			deal: {
				price: 0,
				min_size: 0,
				company: "",
				logo: "",
			},
			target: {
				price: 0,
				notional: 0,
				comment: "",
				firm_interest: 1,
				implied_valuation: 0,
				note: '',
				company_name: '',
			},
		},
		onboarding: {
			open: false,
			show_onboarding: false,
			kyc_documents: [
				'https://media.gatewayprivatemarkets.com/portal/documents/onboarding/InvestorOnboardingKYCIndividual.pdf',
				'https://media.gatewayprivatemarkets.com/portal/documents/onboarding/InvestorOnboardingKYCIndividual.pdf',
				'https://media.gatewayprivatemarkets.com/portal/documents/onboarding/InvestorOnboardingKYCCorporate.pdf',
				'https://media.gatewayprivatemarkets.com/portal/documents/onboarding/InvestorOnboardingKYCCorporate.pdf'
			],
			investor_statuses: [
				"I am an individual investor - making direct investments",
				"I am an individual investor - using investment vehicle",
				"I represent a licensed financial intermediary or listed entity",
				"I represent other corporation"
			],
			target: {
				investor_status: "I am an individual investor - making direct investments",
				chosen_investor_index: 0
			}
		},
		referral: {
			first_name: "",
			last_name: "",
			email: "",
		},
		tabIndex: 0,
		element: {},
		snackBar: {
			open: false,
			error: false,
			message: "",
		},
		openSell: false,
		company: "",
		notional: "",
		price: "",
		comment: "",
		formType: "",
		toggleWatchError: false,
		toggleWatchListMessage: "",
		referralResponseMessage: "",
		referralError: false,
		expressInterestResponseMessage: "",
		investorPanelExpanded: false,
		showExpandButton: false,
		similar_companies: [],
	};

	constructor(props) {
		super(props);
		// this.handleSnackbarClose = this.handleSnackbarClose.bind(this);
		this.handleTeaserDialogClose = this.handleTeaserDialogClose.bind(this);
		this.handleVideoDialogOpen = this.handleVideoDialogOpen.bind(this);
		this.handleVideoDialogClose = this.handleVideoDialogClose.bind(this);
		// this.handleExpressInterestDialogClose = this.handleExpressInterestDialogClose.bind(this);
		// this.handleExpressInterestDialogOpen = this.handleExpressInterestDialogOpen.bind(this);
		this.handleTeaserDialogOpen = this.handleTeaserDialogOpen.bind(this);
		this.handleSubmitCommitment = this.handleSubmitCommitment.bind(this);
		this.handleSubmitOnboardingRequest = this.handleSubmitOnboardingRequest.bind(this);

		this.handleScrollToTermsContainer = this.handleScrollToTermsContainer.bind(
			this
		);
		this.handleScrollToIndicativePrice = this.handleScrollToIndicativePrice.bind(
			this
		);
		this.handleScrollToIndicativeTimeline = this.handleScrollToIndicativeTimeline.bind(
			this
		);
		this.handleInvestorExpanded = this.handleInvestorExpanded.bind(
			this
		);
		this.handleChangePriceInputType = this.handleChangePriceInputType.bind( this );
		
		this.dummyRef = React.createRef();
		this.TermsContainer = React.createRef();
		this.IndicativePrice = React.createRef();
		this.IndicativeTimeline = React.createRef();
	}

	componentDidMount() {
		const {
			match: {
				params: { id, type },
			},
			getLatestFundingRound,
			getFundingRound,
			getCompanyInvestors,
			getVerticals,
			getClubDetail,
			// getEmployee,
			getPublicNewsArticle,
			getDealLinks,
			getDealTimeline,
			getAllCompanies,
			getHistoricalPrice,
		} = this.props;
		// console.log(this.props.location.state);
		if (
			this.props.location &&
			this.props.location.state &&
			this.props.location.state.type
		) {
			switch (this.props.location.state.type) {
				case "showcase":
					this.setState({
						formType: "showcase",
					});
					break;
				case "watchList":
					this.setState({
						formType: "watchList",
					});
					break;
				default:
					break;
			}
		}
		getAllCompanies({ company_id: id });
		getLatestFundingRound({ company_id: id });
		// getCompanyInvestors({ company_id: id });
		getVerticals({ company_id: id });
		getClubDetail({ company_id: id });
		// getEmployee({ company_id: id });
		
		getDealLinks({ company_id: id });
		getDealTimeline({ company_id: id });

		setTimeout(function(){
			// getHistoricalPrice({ company_id: id });
			// getFundingRound({ company_id: id });
			getPublicNewsArticle({ company_id: id });
		}, 1500);
		sendLogEvent(false, 'companydetail_componentDidMount', {}, this.props);
	}

	componentDidUpdate(prevProps, prevState, snapshot){
		const InvestRow = document.getElementById('InvestRow');
		const InvestorsItemWrapper = document.getElementById('InvestorsItemWrapper');
		if( InvestRow && InvestorsItemWrapper ){
			const { showExpandButton, investorPanelExpanded } = this.state;
			if( InvestRow.clientHeight < InvestorsItemWrapper.clientHeight && !showExpandButton ){
				this.setState({ showExpandButton : true });
			} else if ( InvestRow.clientHeight > InvestorsItemWrapper.clientHeight && !investorPanelExpanded && showExpandButton ) {
				this.setState({ showExpandButton : false });
			}
		}
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		const {
			expressInterestError,
			expressInterestResponseMessage,
			referralError,
			referralResponseMessage,
			toggleWatchError,
			toggleWatchListMessage,
			similar_companies,
			match: {
				params: { id },
			},
		} = nextProps;

		if (
			expressInterestResponseMessage !=
			prevState.expressInterestResponseMessage &&
			expressInterestResponseMessage !== undefined
		) {
			const new_open_style = expressInterestResponseMessage.trim()
				? true
				: false;
			return {
				snackBar: {
					open: new_open_style,
					error: expressInterestError,
					message: expressInterestResponseMessage,
				},
			};
		} else if (
			referralResponseMessage != prevState.referralResponseMessage &&
			referralResponseMessage !== undefined
		) {
			const new_open_style = referralResponseMessage.trim() ? true : false;

			return {
				snackBar: {
					open: new_open_style,
					error: referralError,
					message: referralResponseMessage,
				},
			};
		} else if (
			toggleWatchListMessage != prevState.toggleWatchListMessage &&
			toggleWatchListMessage !== undefined
		) {
			const new_open_style = toggleWatchListMessage.trim() ? true : false;
			return {
				snackBar: {
					open: new_open_style,
					error: toggleWatchError,
					message: toggleWatchListMessage,
				},
			};
		} else if (
			similar_companies &&
			similar_companies.results
		) { 
			const companies = [];
			for( var ii=0; ii<similar_companies.results.length; ii++ ){
				if( companies.length < 4 && similar_companies.results[ii].company_id != id ){
					companies.push(similar_companies.results[ii]);
				}
			}
			
			return { similar_companies: companies }
		} else {
			return null;
		}
	}

	pageGoBack = () => {
		this.props.history.goBack();
	};

	doToggleWatchList1 = () => {
		let { AllCompaniesResponse, toggleWatchList } = this.props;
		toggleWatchList({
			company: AllCompaniesResponse.id,
			active: !AllCompaniesResponse.watchlist,
		});
		AllCompaniesResponse.watchlist = !AllCompaniesResponse.watchlist;
		this.setState({
			AllCompaniesResponse: AllCompaniesResponse,
		});
	};

	thousands_separators = (num) => {
		return num.toStbuyring().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	};

	handleInvestorExpanded(evt){
		const { investorPanelExpanded } = this.state;
		this.setState({ investorPanelExpanded: !investorPanelExpanded });
		sendGAEvent({
			type: "show_more_notable_investors",
			data:{
				type: this.state.investorPanelExpanded ? 'collapse' : 'expand',
				company_id: this.props.match.params.id, 
			}
		})
	}

	handleChangeValue = (evt) => {
		const { name, value } = evt.target;
		const { expressInterest } = this.state;
		expressInterest["target"][name] = value;
		this.setState({ expressInterest: expressInterest });
	};

	onValuationChange = (event, newValue) => {
		let target_price = newValue;

		if(typeof target_price == 'string'){
			target_price = target_price.replace(/[^\d.]/g, '');
			target_price = parseFloat(target_price);
		}
		
		// console.log(typeof target_price);

		// let re = /^([0-9]+)(?:\.)(?:[0-9]+)$/;
		// console.log( re.exec(target_price) );
	
		const { latestFundingRound } = this.props;
		const { expressInterest } = this.state;
		expressInterest["target"]["price"] = target_price;

		let implied_val = ""
		if( latestFundingRound.selected_price && latestFundingRound.selected_post_money_valuation ) {
			implied_val = (target_price / latestFundingRound.selected_price) * latestFundingRound.selected_post_money_valuation;
			implied_val = implied_val >= 1000 ? ( implied_val / 1000 ).toFixed(1) + "B" : implied_val.toFixed(0) + "M";
		} 
		
		expressInterest["target"]["implied_valuation"] = implied_val;
		this.setState({ expressInterest: expressInterest });
	};

	setValuation = (min_sell) => {
		const { expressInterest } = this.state;
		expressInterest["target"]["price"] = min_sell;
		this.setState({ expressInterest: expressInterest });
	};

	handleChangeReferralValue = (evt) => {
		const { name, value } = evt.target;
		const { referral } = this.state;
		referral[name] = value;
		this.setState({ referral: referral });
	};

	handleSnackbarClose = (evt, reason) => {
		if (reason === "clickaway") {
			return;
		}
		const { resetSnackBar } = this.props;
		resetSnackBar();

		this.setState({
			snackBar: {
				open: false,
				error: false,
				message: "",
			},
			toggleWatchListMessage: "",
			expressInterest: {
				open: false,
				deal: {
					price: 0,
					min_size: 0,
					company: "",
					logo: "",
				},
				target: {
					price: 0,
					notional: 0,
					comment: "",
					note: "",
					firm_interest: 1,
					implied_valuation: 0,
					company_name: '',
				},
			},
			referral: {
				first_name: "",
				last_name: "",
				email: "",
			},
		});
	};

	handleTeaserDialogOpen(url, link_name, evt) {
		if(evt && evt.preventDefault) evt.preventDefault();
		const { pdf } = this.state;
		pdf["open"] = true;
		pdf["url"] = url;
		pdf["link_name"] = link_name;
		this.setState({ pdf: pdf });
	}

	handleTeaserDialogClose() {
		const { pdf } = this.state;
		pdf["open"] = false;
		this.setState({ pdf: pdf });
	}

	handleVideoDialogOpen(url, link_name, evt){
		if(evt && evt.preventDefault) evt.preventDefault();
		const { video } = this.state;
		video["open"] = true;
		video["url"] = url;
		video["link_name"] = link_name;
		this.setState({ video: video });
	}

	handleVideoDialogClose() {
		const { video } = this.state;
		video["open"] = false;
		this.setState({ video: video });
	}

	handleExpressBuySellDialogOpen = (deal, evt) => {
		let { 
			historicalPrice, 
			latestFundingRound, 
			loginUserData : { 
				activeAccessRight: {
					access_right: {
						permission
					}
				} } } = this.props;
		const event_payload = {
			company_id: this.props.match.params.id,
		}
		if( 
			permission == content_placeholders.user_type.trial_user
		){
			this.handleOnboarDialogToggle();
			event_payload['onboarding'] = 1;
		} else {
			let todayHistoricalPrice = getTodayHistoricalPrice(
				historicalPrice["hist_data"]
			);
			let { min, max } = todayHistoricalPrice;
			let min_value = min;
			let default_price = deal.price ? deal.price : min_value;
			evt.preventDefault();
			const { expressInterest } = this.state;
			expressInterest["open"] = true;
			expressInterest["deal"] = deal;
			expressInterest["target"]["price"] = default_price;

			
			let implied_val = ""
			if( latestFundingRound.selected_price && latestFundingRound.selected_post_money_valuation ){
				implied_val = (default_price / latestFundingRound.selected_price) * latestFundingRound.selected_post_money_valuation;
				implied_val = implied_val >= 1000 ? ( implied_val / 1000 ).toFixed(1) + "B" : implied_val.toFixed(0) + "M";
			} 
			expressInterest["target"]['implied_valuation'] = implied_val;
			
			expressInterest["dialogTitle"] = deal.dialogTitle;
			// expressInterest['company'] = companyDetailResponse.id;
			this.setState({ expressInterest: expressInterest });
			event_payload['expressInterest'] = expressInterest;
		}
		
		sendGAEvent({
			type:"click_live_offer",
			data: event_payload,
		});
	};

	handleExpressBuySellDialogClose = () => {
		const { expressInterest } = this.state;
		expressInterest["open"] = false;
		this.setState({ expressInterest: expressInterest });
	};

	openSellPopup = () => {
		this.setState({ openSell: true });
	};

	closeSellPopup = () => {
		this.setState({ openSell: false });
	};

	handleSubmitSellInterest = (evt) => {
		const { company, notional, price, comment } = this.state;

		if (company.trim()) {
			let message = "Sell interest received: \n";
			message += "Company: " + company.trim() + " \n";
			message += "Target Notional: " + notional + " \n";
			message += "Target Price: " + price + " \n";
			message += "Comment: " + comment + " \n";

			const payload = { message: message };

			const { createUserMessage } = this.props;
			createUserMessage(payload);
		} else {
			this.setState({
				openStyle: true,
				passwordError: true,
				responseMsg: "Please fill in the company you are interested to sell.",
			});
		}
	};

	handleChange = (e) => {
		const { name, value } = e.target;
		this.setState({ [name]: value });
	};

	handleSubmitReferral() {
		const { referral } = this.state;
		// referral.first_name.trim() &&
		// referral.last_name.trim() &&
		if (referral.email.trim()) {
			const {
				postReferral,
				clubDetail: { company },
			} = this.props;

			const payload = {
				first_name: referral.first_name,
				last_name: referral.last_name,
				email: referral.email,
				company: company.id,
			};

			postReferral(payload);
		} else {
			this.setState({
				snackBar: {
					open: true,
					error: true,
					message: "Please fill in the full name and email.",
				},
			});
		}
	}

	handleCancelCommitment = () => {
		let expressInterest = {
			open: false,
			deal: {
				price: 0,
				min_size: 0,
				company: "",
				logo: "",
			},
			target: {
				price: 0,
				notional: 0,
				comment: "",
				other_comment: "",
				firmInterest: 1,
			},
		};
		this.setState({ expressInterest: expressInterest });
	};

	handleSubmitCommitment = (evt) => {
		const {
			expressInterest: { deal, target },
		} = this.state;

		if (target.price || deal.price) {
			const payload = {
				price: target.price ? target.price : deal.price,
				notional: Number(target.notional),
				interest_type: deal.interest_type,
				firm_interest: target.firm_interest ? true : false,
				note: target.note,
				company_name: '',
				comment: target.comment
				// interest_type: deal.price ? "CLUBDEAL_INVEST" : "CLUBDEAL_INTEREST",
			};
			const {
				expressInterest,
				clubDetail: { company },
				AllCompaniesResponse,
			} = this.props;

			payload["company"] = AllCompaniesResponse.id;
			expressInterest(payload);
			this.handleCancelCommitment();
		} else {
			this.setState({
				snackBar: {
					open: true,
					error: true,
					message: deal.price
						? "Please provide us your target notional"
						: "Please provide us your target price and notional",
				},
			});
		}
	};

	handleOnboarDialogToggle = () => {
		const { onboarding } = this.state;
		onboarding['open'] = !onboarding['open'];
		onboarding['target']['investor_status'] = "I manage my investments under my own name";
		onboarding['target']['chosen_investor_index'] = 0;
		this.setState({ onboarding: onboarding });
	}

	handleSubmitOnboardingRequest = (evt) => {
		const { onboarding } = this.state;

		if( onboarding['target']['investor_status'] ){
			const { createUserMessage } = this.props;
			let message = "Trial user has requested full portal access: \n";
			message += "Investment type: " + onboarding['target']['investor_status'];
			createUserMessage({ message: message });
		} else {

		}
		
	}

	viewArticle = (id)=>{
		sendGAEvent({
			type:"click_news_article_item",
			data: { 
				news_article_id: id, 
			}
		})
	}
	
	handleShowOnboardingToggle = () => {
		const { onboarding } = this.state;
		onboarding['show_onboarding'] = !onboarding['show_onboarding'];
		this.setState({ onboarding: onboarding });
	}

	handleChangeInvestorStatus = (evt) => {
		const { name, value } = evt.target;
		const { onboarding } = this.state;

		const investor_status_index = onboarding['investor_statuses'].indexOf(value);		
		onboarding['target']['investor_status'] = value;
		onboarding['target']['chosen_investor_index'] = investor_status_index;
		this.setState({ onboarding: onboarding });
	}

	handleScrollToTermsContainer() {
		 this.TermsContainer.current.scrollIntoView({
			behavior: "smooth",
			block: "center",
		});
	}

	handleScrollToIndicativePrice() {
		this.IndicativePrice.current.scrollIntoView({
			behavior: "smooth",
			block: "center",
		});
	}

	handleScrollToIndicativeTimeline() {
		this.IndicativeTimeline.current.scrollIntoView({
			behavior: "smooth",
			block: "center",
		});
	}

	handleChangePriceInputType(evt){
		const { expressInterest } = this.state;
		expressInterest['price_slider'] = !expressInterest.price_slider;
		this.setState({ expressInterest: expressInterest });
	}

	onPdfDocumentSuccess(document){
		// const { pdf } = this.state;
		// pdf['numPages'] = document.numPages;
		// this.setState({ pdf: pdf });
		// console.log( document );
	}

	onDetailMouseEnter = (event, id) => {
		// console.log("in enter ", event.target, event.relatedTarget);
		if(event && event.stopPropagation) event.stopPropagation();
		this.setState(
			{
				showDetailId: id,
			},
			() => {
				// console.log(this.state.showDetailId, "this.state.showDetailId");
			}
		);
		sendGAEvent({
			type:"hover_company_description",
			data:{
				company_id: id, 
			}		 
		})
	};

	onDetailMouseLeave = (event) => {
		if(event && event.stopPropagation) event.stopPropagation();
		this.setState({ showDetailId: -1, });
	};

	doToggleWatchList = (parms, event) => {
		let { companyId, watchlist } = parms;
		if(event && event.stopPropagation) event.stopPropagation();
		if(event && event.preventDefault) event.preventDefault();
		const { toggleWatchList } = this.props;
		toggleWatchList({
			company: companyId,
			active: !watchlist,
		});
		this.updateCompanyStar(parms);
	};

	renderCompanyLiveTag = () => {
		return (
			<div
				className="flex-row-center home-card-status"
				style={{ background: "#FCA311" }}
			>
				<span className="home-status">Live</span>
			</div>
		);
	};

	updateCompanyStar = (parms) => {
		let { similar_companies } = this.state;
		
		if (similar_companies && similar_companies.length > 0) {
			for (let index in similar_companies) {
				if (similar_companies[index]["company_id"] == parms.companyId) {
					similar_companies[index]["watchlist"] = !parms.watchlist;
				}
			}
		}

		this.setState({
			similar_companies: similar_companies
		});
	};

	renderCardList = (listArray, type) => {
		const target_date = new Date();
		target_date.setDate(target_date.getDate() - 14);

		return (
			<div className="flex-row market-intelligence-list">
				{listArray &&
					listArray.length > 0 &&
					listArray.map((item, index) => {
						const date_obj = new Date(item.date_added+"T00:00:00");
						return (
							<Link
								to={{
									pathname: `/club/company-detail/${item.company_id}`,
									state: { type: type },
								}}
								onClick={() => window.location.href=`/club/company-detail/${item.company_id}`}
								key={index}
								className="home-card market-item"
							>
								{
									date_obj > target_date && item.status == "DEAL LIVE CONFIRMED" ? 
										<div className="new-label">
											Recently<br />Added
										</div> 
										: ''
								}
								{this.state.showDetailId == item.company_id && (
									<div className="flex-row market-about">
										<div className="line-ten">{item.description}</div>
									</div>
								)}
								<div className="flex-row-between">
									<div>
										<div className="home-company-name-market line-two">
											{item.name}
										</div>
										<div className="home-company-info line-two company-info-height">
											{item.primary_industry_code}
										</div>
									</div>
									<div className="flex-row company-icon-wrap">
										{
											item.focus_deal ? <WhatshotIcon style={{ color: '#FF0000' }} /> : ""
										}
										<div
											onMouseOver={(evt) =>
												this.onDetailMouseEnter(evt, item.company_id)
											}
											onMouseOut={(evt) => this.onDetailMouseLeave(evt)}
											className="flex-row detail-icon">
											<BiDetail className="pointer-none" />
										</div>
										{/* {item.watchlist ? (
											<span
												onClick={(event) =>
													this.doToggleWatchList(
														{
															companyId: item.company_id,
															watchlist: item.watchlist,
														},
														event
													)
												}
												style={{ color: "#FFC50D" }}
											>
												<Tooltip title="Remove from watchlist">
													<Star />
												</Tooltip>
											</span>
										) : (
											<span
												onClick={(event) =>
													this.doToggleWatchList(
														{
															companyId: item.company_id,
															watchlist: item.watchlist,
														},
														event
													)
												}
												style={{ color: "#AFABAB" }}
											>
												<Tooltip title="Add to watchlist">
													<StarBorder />
												</Tooltip>
											</span>
										)} */}
									</div>
								</div>
								<img className="case-img mt-2" src={item.logo} alt="" />
								<div className="pr-1 market-info">
									<div className="valuation-wrap">
										<span className="market-label">Valuation: </span>
										<span className="home-company-info">
											{item.selected_post_money_valuation
												? item.selected_post_money_valuation > 1000
													? "US$" +
														(item.selected_post_money_valuation / 1000).toFixed(
															1
														) +
														"B"
													: "US$" +
														item.selected_post_money_valuation.toFixed(0) +
														"M"
												: "-"}
										</span>
									</div>
									<div className="location-wrap">
										<span className="market-label">Location: </span>
										<span className="home-company-info">
											{item.headquarter_country}
										</span>
									</div>
									<div className="stage-wrap">
										<span className="market-label">Stage: </span>
										<span className="home-company-info">
											{item.selected_deal_type_1 && item.selected_deal_type_1.indexOf('Stage VC') > -1 ? item.selected_deal_type_1 : "-"}
											{item.selected_deal_type_1 &&
											item.selected_deal_type_1.indexOf('Stage VC') > -1 && 
											item.selected_deal_type_2 && 
											item.selected_deal_type_2.trim() ? " (Series " + item.selected_deal_type_2 + ")" : ""}
										</span>
									</div>
									{item.status == "DEAL LIVE CONFIRMED" &&
										this.renderCompanyLiveTag()}
								</div>
							</Link>
						);
					})}
			</div>
		);
	};

	render() {
		const {
			snackBar,
			pdf,
			video,
			expressInterest,
			referral,
			openSell,
			company,
			notional,
			price,
			comment,
			investorPanelExpanded,
			showExpandButton,
			onboarding,
			similar_companies,
		} = this.state;
		
		const {
			AllCompaniesResponse,
			fundingRounds,
			finishedCollectFundingRounds,
			latestFundingRound,
			investors,
			verticals,
			// pipelineResponse,
			clubDetail,
			// employees,
			publicNewsArticles,
			clubDealLinks,
			clubDealTimelines,
			historicalPrice,
			loginUserData,
		} = this.props;

		const date_arr = latestFundingRound.selected_deal_date
			? latestFundingRound.selected_deal_date.split("-")
			: [];

		let lf_date;
		if (date_arr.length) {
			lf_date = new Date(date_arr[0], date_arr[1] - 1, date_arr[2]);
		}

		let companyDetailResponse = AllCompaniesResponse;
		let liveStatus =
			clubDetail && clubDetail.live ? CompanyStatusCalculate(clubDetail) : null;

		let id = this.props.match.params.id;
		var website_name =
			companyDetailResponse &&
			companyDetailResponse.website &&
			companyDetailResponse.website.trim()
				? companyDetailResponse.website.trim().substring("http:") == 0
					? companyDetailResponse.website.trim()
					: "http://" + companyDetailResponse.website.trim()
				: "";

		const months = [
			"Jan",
			"Feb",
			"Mar",
			"Apr",
			"May",
			"Jun",
			"Jul",
			"Aug",
			"Sep",
			"Oct",
			"Nov",
			"Dec",
		];

		const date_diff = new Date(clubDetail["committed_by"]) - new Date();

		const brokerage_fee_notes = clubDetail.brokerage_fee_note ? clubDetail.brokerage_fee_note.split("\\n") : [clubDetail.brokerage_fee_note];
		const transaction_considerations = clubDetail.transaction_consideration ? clubDetail.transaction_consideration.split("\r\n") : [clubDetail.transaction_consideration];
		const other_deal_terms = clubDetail.other_deal_terms ? clubDetail.other_deal_terms.split("\r\n") : [clubDetail.other_deal_terms];

		let user_type = ""
		if( loginUserData ){
			const { activeAccessRight: { access_right: { permission}}} = loginUserData;
			user_type = permission;
		}

		return (
			<div className="home">
				<div className="top-padding"></div>
				<div className="home_main">
					<div className="sidebar-container company-page">
						<Sidebar
							pageGoBack={this.pageGoBack}
							formType={this.state.formType}
							companyDetailResponse={companyDetailResponse}
							clubDetail={clubDetail}
							latestFundingRound={latestFundingRound}
							clubDealTimelines={clubDealTimelines}
							clubDealLinks={clubDealLinks}
							handleExpressBuySellDialogOpen={
								this.handleExpressBuySellDialogOpen
							}
							handleTeaserDialogOpen={this.handleTeaserDialogOpen}
							handleVideoDialogOpen={this.handleVideoDialogOpen}
							handleScrollToTermsContainer={this.handleScrollToTermsContainer}
							handleScrollToIndicativePrice={this.handleScrollToIndicativePrice}
							handleScrollToIndicativeTimeline={
								this.handleScrollToIndicativeTimeline
							}
							loginUserData={loginUserData}
							date_diff={date_diff}
						/>
					</div>
					<div className="home-right company-detail-right" id="home-right">
						<Box  flexGrow={1} m={2}>
							<Grid container spacing={2}>
								<Grid item xs={12}>
									<div className="image-design">
										<div className="logo-hoder-2">
											<img
												className="w-100 c_d_name"
												src={
													companyDetailResponse.logo_url
														? companyDetailResponse.logo_url
														: deal
												}
												alt={companyDetailResponse.name}
											/>
										</div>
										<div className="company-title">
											{companyDetailResponse.name}
										</div>
										{/* <div className="add-to-watchlist-box">
													{companyDetailResponse.watchlist ? (
													<div></div>
												) : (
													<Container className="watchlist-cta" style={{
														}} fixed>
														<p>
														Add this company to your Watchlist to receive the
														latest updates on investment opportunities and movement in price
														</p>
													</Container>
											)}
										</div>
										<div className="company-status">
											{liveStatus && (
												<div style={{ color: liveStatus.color }}>
													{liveStatus.statusTip}
												</div>
											)}
											<div style={{ cursor: "pointer"}} onClick={this.doToggleWatchList1}>
												{companyDetailResponse.watchlist ? (
													<Tooltip title="Remove from watchlist">
														<Star style={{ color: "#FFC50D" }} />
													</Tooltip>
												) : (
													<Tooltip title="Add to watchlist">
														<StarBorder style={{ color: "#AFABAB" }} />
													</Tooltip>
												)}
											</div>
										</div> */}
									</div>
								</Grid>
							</Grid>
							<Grid container spacing={2}>
								<Grid item xs={12} md={6}>
									<Card style={{padding: 16, height: '100%'}}>
										<Grid container spacing={2}>
											<Grid item xs={12}>
												<h4 className="subheading-color company-item-title flex-row-center">
													<span className="company-item-rectangle"></span>
													Company Information
												</h4>
											</Grid>
											<Grid item xs={12}>
												<h6 className="company-detail-label">Verticals</h6>
											</Grid>
											<Grid item xs={12}>
												<div className="company-detail-value">
													<p>
														<span>
															{companyDetailResponse.primary_industry_code}
															{verticals.length ? ", " : ""}
														</span>
														{verticals.map((ver, ii) => {
															if (ii)
																return ver.vertical && ver.vertical.name ? (
																	<span key={ii}>, {ver.vertical.name}</span>
																) : (
																	<span key={ii}></span>
																);
															else
																return ver.vertical && ver.vertical.name ? (
																	<span key={ii}>{ver.vertical.name}</span>
																) : (
																	<span key={ii}></span>
																);
														})}
													</p>
												</div>
											</Grid>
											<Grid item xs={12} md={8}>
												<h6 className="company-detail-label">
													Last Funding Round Valuation
												</h6>
												<span className="value company-detail-value">
													{
														latestFundingRound.custom_external_note && latestFundingRound.custom_external_note.toLowerCase().indexOf("sensitive") > -1 ? 
															latestFundingRound.external_post_money_valuation_external_note : (
																<Fragment>
																	{
																		latestFundingRound.selected_post_money_valuation
																			? latestFundingRound.selected_post_money_valuation >= 1000
																				? "US$" +
																					(
																						latestFundingRound.selected_post_money_valuation / 1000
																					).toFixed(1) +
																					"B "
																				: "US$" +
																					latestFundingRound.selected_post_money_valuation.toFixed(
																						0
																					) +
																					"M "
																			: "-"
																	}
																	{latestFundingRound["selected_price_disclaimer"] == "manually selected" &&
																	latestFundingRound["external_post_money_valuation_external_note"] ? (
																		<sup>1</sup>
																	) : ("")}
																	{latestFundingRound["selected_price_disclaimer"] == "other price" &&
																	latestFundingRound["other_post_money_valuation_external_note"] ? (
																		<sup>1</sup>
																	) : ("")}
																	{latestFundingRound["selected_price_disclaimer"] == "pitchbook latest price" &&
																	latestFundingRound["pb_post_money_valuation_external_note"] ? (
																		<sup>1</sup>
																	) : ("")}
																	{latestFundingRound["selected_price_disclaimer"] == "pitchbook with price" &&
																	latestFundingRound["pb_wp_post_money_valuation_external_note"] ? (
																		<sup>1</sup>
																	) : ("")}
																</Fragment>
																
															)
													}
													
													(
													{latestFundingRound.selected_deal_date
														? months[lf_date.getMonth()] +
															"-" +
															lf_date.getFullYear()
														: "-"}
													)
												</span>
											</Grid>
											<Grid item xs={12} md={4}>
												<h6 className="company-detail-label">Share Price</h6>
												<span className="value company-detail-value">
													{
														latestFundingRound.custom_external_note && 
															latestFundingRound.custom_external_note.toLowerCase().indexOf("sensitive") > -1 ? 
																latestFundingRound.external_price_external_note : (
																	latestFundingRound.selected_price
																		? "$" + latestFundingRound.selected_price.toFixed(1) + "p.s."
																		: "-"
																)
													}
													
													{latestFundingRound["selected_price_disclaimer"] == "pitchbook pre money valuation price" ? (
														<sup>E</sup>
													) : (
														""
													)}
													{latestFundingRound["selected_price_disclaimer"] == "pitchbook post money valuation price" ? (
														<sup>E</sup>
													) : (
														""
													)}
													{latestFundingRound["selected_price_disclaimer"] == "manually selected" &&
													latestFundingRound["external_price_estimated"] ? (
														<sup>E</sup>
													) : (
														""
													)}
													{latestFundingRound["selected_price_disclaimer"] == "other price" &&
													latestFundingRound["other_price_estimated"] ? (
														<sup>E</sup>
													) : (
														""
													)}
													{latestFundingRound["selected_price_disclaimer"] == "pitchbook latest price" &&
													latestFundingRound["pb_price_estimated"] ? (
														<sup>E</sup>
													) : (
														""
													)}
													{latestFundingRound["selected_price_disclaimer"] == "pitchbook with price" &&
													latestFundingRound["pb_wp_price_estimated"] ? (
														<sup>E</sup>
													) : (
														""
													)}
												</span>
											</Grid>
											<Grid item xs={12} md={8}>
												<h6 className="company-detail-label">HQ</h6>
												<span className="value">
													{companyDetailResponse.headquarter_country}
												</span>
											</Grid>
											<Grid item xs={12} md={4}>
												<span className="value pt-3 company-detail-link">
													{website_name.trim() && (
														<a href={website_name} target="_blank">
															Website
															<span>
																<OpenInNew fontSize="small" />
															</span>
														</a>
													)}
												</span>
											</Grid>
											<Grid item xs={12}>
												<div className="margin-remove margin-top-area disclaimer">
													{
														(!latestFundingRound['custom_external_note'] || 
															latestFundingRound['custom_external_note'] && latestFundingRound['custom_external_note'].indexOf('sensitive') == -1) &&
															latestFundingRound["selected_price_disclaimer"] == "manually selected" &&
															latestFundingRound["external_post_money_valuation_external_note"]
														? <p className="mb-0"><sup>1</sup> {latestFundingRound["external_post_money_valuation_external_note"]}</p> : ''
													}
													{
														(!latestFundingRound['custom_external_note'] || 
															latestFundingRound['custom_external_note'] && latestFundingRound['custom_external_note'].indexOf('sensitive') == -1) &&
															latestFundingRound["selected_price_disclaimer"] == "other price" &&
															latestFundingRound["other_post_money_valuation_external_note"]
														? <p className="mb-0"><sup>1</sup> {latestFundingRound["other_post_money_valuation_external_note"]}</p> : ""
													}
													{	
														(!latestFundingRound['custom_external_note'] || 
															latestFundingRound['custom_external_note'] && latestFundingRound['custom_external_note'].indexOf('sensitive') == -1) &&
														latestFundingRound["selected_price_disclaimer"] == "pitchbook latest price" &&
															latestFundingRound["pb_post_money_valuation_external_note"]
														? <p className="mb-0"><sup>1</sup> {latestFundingRound["pb_post_money_valuation_external_note"]}</p>: ""
													}
													{
														(!latestFundingRound['custom_external_note'] || 
															latestFundingRound['custom_external_note'] && latestFundingRound['custom_external_note'].indexOf('sensitive') == -1) &&
														latestFundingRound["selected_price_disclaimer"] == "pitchbook with price" &&
															latestFundingRound["pb_wp_post_money_valuation_external_note"]
														? <p className="mb-0"><sup>1</sup> {latestFundingRound["pb_wp_post_money_valuation_external_note"]}</p> : ""
													}
													{latestFundingRound &&
													(
														latestFundingRound["selected_price_disclaimer"] == "pitchbook pre money valuation price" ||
														latestFundingRound["selected_price_disclaimer"] == "pitchbook post money valuation price" ||
														(
															latestFundingRound["selected_price_disclaimer"] == "manually selected" &&
															latestFundingRound["external_price_estimated"]
															) ||
														(
															latestFundingRound["selected_price_disclaimer"] == "other price" &&
															latestFundingRound["other_price_estimated"]
															) ||
														(
															latestFundingRound["selected_price_disclaimer"] == "pitchbook latest price" &&
															latestFundingRound["pb_price_estimated"]
															) ||
														(
															latestFundingRound["selected_price_disclaimer"] =="pitchbook with price" &&
															latestFundingRound["pb_wp_price_estimated"]
															) 
													) ? (
														<div>
															<br />
															<p className="margin-remove">
																<sup>E</sup> Estimated
															</p>{" "}
														</div>
													) : (
														""
													)}
													<p className="mb-0">All figures in US$</p>{" "}
												</div>
											</Grid>
											<Grid item xs={12}>
												{latestFundingRound["selected_price_disclaimer"] ==
														"manually selected" &&
													latestFundingRound[
														"selected_external_post_money_valuation_external_note"
													] ? (
														<p>
															<sup>1</sup>
															{
																latestFundingRound[
																	"selected_external_post_money_valuation_external_note"
																]
															}
														</p>
													) : (
														""
													)}
													{latestFundingRound["selected_price_disclaimer"] ==
														"other price" &&
													latestFundingRound[
														"selected_other_post_money_valuation_external_note"
													] ? (
														<p>
															<sup>2</sup>
															{
																latestFundingRound[
																	"selected_other_post_money_valuation_external_note"
																]
															}
														</p>
													) : (
														""
													)}
													{latestFundingRound["selected_price_disclaimer"] ==
														"pitchbook latest price" &&
													latestFundingRound[
														"selected_pb_post_money_valuation_external_note"
													] ? (
														<p>
															<sup>3</sup>
															{
																latestFundingRound[
																	"selected_pb_post_money_valuation_external_note"
																]
															}
														</p>
													) : (
														""
													)}
													{latestFundingRound["selected_price_disclaimer"] ==
														"pitchbook with price" &&
													latestFundingRound[
														"selected_pb_wp_post_money_valuation_external_note"
													] ? (
														<p>
															<sup>4</sup>
															{
																latestFundingRound[
																	"selected_exteselected_pb_wp_post_money_valuation_external_noternal_post_money_valuation_external_note"
																]
															}
														</p>
													) : (
														""
													)}
											</Grid>
										</Grid>
										
									</Card>
								</Grid>
								<Grid item xs={12} md={6}>
									<Card style={{ padding: 16, height: '100%' }}>
										<Grid container spacing={2}>
											<Grid item xs={12}>
												<h4 className="subheading-color company-item-title flex-row-center">
													<span className="company-item-rectangle" />
													About
												</h4>
											</Grid>
											<Grid item xs={12}>
												<p>{companyDetailResponse.description}</p>
											</Grid>
										</Grid>
									</Card>
									
								</Grid>
							</Grid>
						</Box>
						
						{/* {investors && investors.length > 0 && (
							<div className="row" ref={this.dummyRef}>
								<div className="col-12 mt-3">
									<div className="box">
										<div className="row">
											<div className="col col-12">
												<h4 className="subheading-color company-item-title">
													<span className="company-item-rectangle"></span>
													Notable Investors
												</h4>
											</div>
											<div id="InvestRow" className={investorPanelExpanded ? "invest-row expanded" : "invest-row"}>
												<div id="InvestorsItemWrapper" className="investors-item-wrapper">
													{investors.map((item, index) => {
														return (
															<div className="investor-item" key={index}>
																<Tooltip title={item.source_name}>
																	<span className="investor-title truncate">
																		{item.source_name}
																	</span>
																</Tooltip>
															</div>
														);
													})}
												</div>
											</div>
											{
												showExpandButton ? 
													<Button
														onClick={this.handleInvestorExpanded}
														className="btn-investor-section"
													>
														{ investorPanelExpanded ? "Collapse" : "Show More" }
														{ investorPanelExpanded ? <ExpandLess /> : <ExpandMore /> }
													</Button> : ''
											}
											
										</div>
									</div>
								</div>
							</div>
						)} */}

						{clubDetail && Object.keys(clubDetail).length &&
							( clubDetail.transaction_format || clubDetail.min_size ||
								clubDetail.brokerage_fee || clubDetail.brokerage_fee_note ||
								clubDetail.vehicle_fee || clubDetail.vehicle_fee_note ||
								clubDetail.allocation_note || clubDetail.allocation ) && (clubDetail.committed_by) &&
						(
							<Fragment>
								<Box flexGrow={1} m={2}>
									<Grid container spacing={2}>
										<Grid item xs={12}>
											<div
												className="box terms-container"
												id="term-block"
												ref={this.TermsContainer}
											>
												<div className="row">
													<div className="col-12">
														<h4 className="subheading-color company-item-title">
															<span className="company-item-rectangle"></span>
															Terms
														</h4>
													</div>
													<div className="col-12">
														{clubDetail.transaction_format ? (
															<div className="row">
																<div className="col col-12">
																	<div className="content-wrapper">
																		<div className="row">
																			<div className="col-12 col-md-3">
																				<span className="label">Transaction Format</span>
																			</div>
																			<div className="col-12 col-md-9">
																				{clubDetail.transaction_format.toLowerCase().indexOf(content_placeholders.error_message.blurred_text) > -1 ? <div className="blurred">{clubDetail.transaction_format}</div> :  clubDetail.transaction_format}
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														) : (
															""
														)}

														{clubDetail.allocation_note ? (
															<div className="row">
																<div className="col col-12">
																	<div className="content-wrapper">
																		<div className="row">
																			<div className="col-12 col-md-3">
																				<span className="label">Allocation</span>
																			</div>
																			<div className="col-12 col-md-9">
																				{clubDetail.allocation_note.toLowerCase().indexOf(content_placeholders.error_message.blurred_text) > -1 ? 
																					<div className="blurred">
																						{clubDetail.allocation_note}</div> : clubDetail.allocation_note.replace(
																								"{{allocation}}",
																								clubDetail.allocation
																							)}
																				
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														) : (
															""
														)}

														{clubDetail.min_size ? (
															<div className="row">
																<div className="col col-12">
																	<div className="content-wrapper">
																		<div className="row">
																			<div className="col-12 col-md-3">
																				<span className="label">Min. Investment Size</span>
																			</div>
																			<div className="col-12 col-md-9">
																				{clubDetail.min_size.toLowerCase().indexOf(content_placeholders.error_message.blurred_text) > -1 ? 
																					<div className="blurred">{clubDetail.min_size}</div> : clubDetail.min_size}
																			
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														) : (
															""
														)}

														{clubDetail.brokerage_fee || clubDetail.brokerage_fee_note ? (
															<div className="row">
																<div className="col col-12">
																	<div className="content-wrapper">
																		<div className="row">
																			<div className="col-12 col-md-3">
																				<span className="label">Brokerage Fee</span>
																			</div>
																			<div className="col-12 col-md-9">
																				{clubDetail.brokerage_fee_note.toLowerCase().indexOf(content_placeholders.error_message.blurred_text) > -1 ? 
																					<div className="blurred">{clubDetail.brokerage_fee_note}</div> : 
																						brokerage_fee_notes.map((item, index) => {
																							return <p className="mb-0" key={index}>{item}</p>
																						})
																					}
																				
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														) : (
															""
														)}

														{clubDetail.vehicle_fee ||
														clubDetail.vehicle_fee_note ? (
															<div className="row">
																<div className="col col-12">
																	<div className="content-wrapper">
																		<div className="row">
																			<div className="col-12 col-md-3">
																				<span className="label">Vehicle Fee</span>
																			</div>
																			<div className="col-12 col-md-9">
																				{
																					clubDetail.vehicle_fee_note.toLowerCase().indexOf(content_placeholders.error_message.blurred_text) > -1 ? 
																						<div className="blurred">{clubDetail.vehicle_fee_note}</div> :
																							clubDetail.vehicle_fee_note.replace(
																								"{{vehicle_fee}}",
																								clubDetail.vehicle_fee
																							)
																				}
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														) : (
															""
														)}

														{clubDetail.transaction_consideration ? (
															<div className="row">
																<div className="col col-12">
																	<div className="content-wrapper no-bottom-border">
																		<div className="row">
																			<div className="col-12 col-md-3">
																				<span className="label">
																					Transaction Closing Consideration
																				</span>
																			</div>
																			<div className="col-12 col-md-9">
																				{
																					transaction_considerations.map((item, index) => {
																						return <p className="mb-0" key={index}>{item}</p>
																					})
																				}
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														) : (
															""
														)}

														{clubDetail.other_deal_terms ? (
															<div className="row">
																<div className="col col-12">
																	<div className="content-wrapper no-bottom-border">
																		<div className="row">
																			<div className="col-12 col-md-3">
																				<span className="label">
																					Detail Terms
																				</span>
																			</div>
																			<div className="col-12 col-md-9">
																				{

																					clubDetail.other_deal_terms.toLowerCase().indexOf(content_placeholders.error_message.blurred_text) > -1 ?
																						<div className="blurred">{clubDetail.other_deal_terms}</div> :
																							other_deal_terms.map((item, index) => {
																								return <p className="mb-0" key={index}>{item}</p>
																							})
																					
																				}
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														) : (
															""
														)}
													</div>
												</div>
											</div>
										</Grid>
									</Grid>
								</Box>
							</Fragment>
						)}

							{
								clubDetail && Object.keys(clubDetail).length &&
									clubDetail.price &&
										<Box flexGrow={1} m={2}>
											<Grid container spacing={2}>
												<Grid item xs={12}>
													<div
														className="box indicative-offer-container"
														id="price-block"
														ref={this.IndicativePrice}
													>
														<div className="row">
															<div className="col col-12">
																<h4 className="subheading-color company-item-title">
																	<span className="company-item-rectangle"></span>
																	Indicative Offer
																</h4>
															</div>
															<div className="col-12">
																<Fragment>
																	<div className="row">
																		<div className="col col-12">
																			<div className="content-wrapper">
																				<div className="row">
																					<div className="col col-12 col-md-3">
																						<span className="label">Price</span>
																					</div>
																					<div className="col col-12 col-md-9">
																						{
																							loginUserData.activeAccessRight && 
																							loginUserData.activeAccessRight.access_right && 
																							loginUserData.activeAccessRight.access_right.permission == content_placeholders.user_type.trial_user ? 
																								<span className="blurred">XXXXX</span> : <span>${clubDetail.price}</span>
																						}
																					</div>
																				</div>
																			</div>
																		</div>
																	</div>
																	{clubDetail.allocation && !clubDetail.allocation_note ? (
																		<div className="row">
																			<div className="col col-12">
																				<div className="content-wrapper">
																					<div className="row">
																						<div className="col col-12 col-md-3">
																							<span className="label">Deal Size</span>
																						</div>
																						<div className="col col-12 col-md-9">
																							<span className="value">
																								{
																									loginUserData.activeAccessRight &&
																									loginUserData.activeAccessRight.access_right &&
																									loginUserData.activeAccessRight.access_right.permission == content_placeholders.user_type.trial_user ? 
																										<span className="blurred">XXXXX</span> : <span>${(clubDetail.allocation/1000000).toFixed(1)+"M"}</span>
																								}
																							</span>
																						</div>
																					</div>
																				</div>
																			</div>
																		</div>
																	) : ''}
																	{
																		clubDetail.price && latestFundingRound.selected_price && (
																			<div className="row">
																				<div className="col col-12">
																					<div className="content-wrapper">
																						<div className="row">
																							<div className="col col-12 col-md-3">
																								<span className="label">Disc / Prem</span>
																							</div>
																							<div className="col col-12 col-md-9">
																								{(
																									Math.abs((clubDetail.price /
																										latestFundingRound.selected_price -
																										1) *
																									100)
																								).toFixed(1)}
																								%
																								{clubDetail.price >
																									latestFundingRound.selected_price
																									? " Premium"
																									: " Discount"}
																							</div>
																						</div>
																					</div>
																				</div>
																			</div>
																		)
																	}
																	<div className="row">
																		<div className="col col-12">
																			<div className="content-wrapper">
																				<div className="row">
																					<div className="col col-12 col-md-3">
																						<span className="label">Implied Valuation</span>
																					</div>
																					<div className="col col-12 col-md-9">
																						{
																							clubDetail.implied_valuation ?
																							clubDetail.implied_valuation>1000 ?  
																							(clubDetail.implied_valuation/1000).toFixed(1) + "B" :
																							clubDetail.implied_valuation.toFixed(0) + "M" :

																								(clubDetail.price /
																									latestFundingRound.selected_price) *
																									latestFundingRound.selected_post_money_valuation >=
																									1000
																									? "$" +
																										(
																											((clubDetail.price /
																												latestFundingRound.selected_price) *
																												latestFundingRound.selected_post_money_valuation) /
																											1000
																										).toFixed(1) +
																										"B"
																									: "$" +
																										(
																											(clubDetail.price /
																												latestFundingRound.selected_price) *
																											latestFundingRound.selected_post_money_valuation
																										).toFixed(0) +
																										"M"
																						}
																							<sup>E</sup>
																					</div>
																				</div>
																			</div>
																		</div>
																	</div>
																	<div className="row">
																		<div className="col col-12">
																			<div className="content-wrapper no-bottom-border">
																				<div className="row">
																					<div className="col col-12 col-md-3">
																						<span className="label">
																							Indication of Interest By
																						</span>
																					</div>
																					<div className="col col-12 col-md-9">

																						{loginUserData.activeAccessRight && 
																							loginUserData.activeAccessRight.access_right && 
																							loginUserData.activeAccessRight.access_right.permission == content_placeholders.user_type.trial_user ? 
																							<span className="blurred">{clubDetail.committed_by}</span>
																							: <span>{clubDetail.committed_by}</span>}
																					</div>
																				</div>
																			</div>
																		</div>
																	</div>
																	<p className="mb-0 disclaimer">
																		<sup>E</sup> Estimated
																	</p>
																	
																	<p className="mb-0 disclaimer">
																		All figures in US$
																	</p>
																</Fragment>
																
															</div>
														</div>
													</div>
												</Grid>
											</Grid>
										</Box>
							}

						{clubDealTimelines.length && clubDetail.committed_by ? (
							<div className="row">
								<div className="col col-12 mt-3">
									<div className="box"
										id="timeline-block"
										ref={this.IndicativeTimeline}>
										<div className="row">
											<div className="col col-12">
												<h4 className="subheading-color company-item-title">
													<span className="company-item-rectangle"></span>
													Indicative Timeline
												</h4>
											</div>
										</div>
										<div className="row">
											<div className="col col-3">
												<span className="label">Date</span>
											</div>
											<div className="col col-3">
												<span className="label">Deals Timeline</span>
											</div>
											<div className="col col-6">
												<span className="label">Info / Documentation</span>
											</div>
										</div>
										{clubDealTimelines.map((item, index) => {
											return (
												<div className="row" key={index}>
													<div className="col col-12">
														<div className="content-wrapper mt-2">
															<div className="row">
																<div className="col col-3">
																	<span className="value">
																		{item.timeline_date}
																	</span>
																</div>
																<div className="col col-12 col-md-3">
																	<span className="value">
																		{item.timeline_tag}
																	</span>
																</div>
																<div className="col col-12 col-md-6">
																	<span className="value">
																		{
																			loginUserData.activeAccessRight &&
																			loginUserData.activeAccessRight.access_right &&
																			loginUserData.activeAccessRight.access_right.permission == content_placeholders.user_type.trial_user &&
																			item.sensitive ?
																				<span className="blurred">{item.timeline_value}</span>:
																				<span>{item.timeline_value}</span>
																		}
																		
																	</span>
																</div>
															</div>
														</div>
													</div>
												</div>
											);
										})}

										<p className="margin-remove margin-top-area disclaimer">
											Indicative timeline is for illustrative purposes and maybe
											subject to delay or change
										</p>
									</div>
								</div>
							</div>
						) : (
							""
						)}

						{historicalPrice && historicalPrice.hist_data && (
							<div className="row">
								<div className="col-12 mt-3 historical-price-section">
									<div className="box">
										<div className="row">
											<div className="col col-12">
												<h4 className="subheading-color company-item-title">
													<span className="company-item-rectangle"></span>
													Market Intelligence: Historical Price Discovery 
													<Tooltip title="Data represented in Market Intelligence are inclusive of all market data that Gateway has knowledge of, including but not limited to offers / demand available on the Gateway platform.">
														<InfoIcon style={{width: '15px'}} />
													</Tooltip>
												</h4>
											</div>
										</div>
										<PriceChart
											{...{
												...historicalPrice,
												...{ company_id: id },
												clubDetail,
												companyDetailResponse,
												fundingRounds,
												finishedCollectFundingRounds,
												loginUserData,
											}}
											handleExpressBuySellDialogOpen={
												this.handleExpressBuySellDialogOpen
											}
										/>
									</div>
								</div>
							</div>
						)}

						{publicNewsArticles.results &&
							publicNewsArticles.results.length > 0 && (
								<div className="row">
									<div className="col-12 mt-3">
										<div className="row" style={{padding: '15px'}}>
											<div className="col-12">
												<h4 className="subheading-color company-item-title">
													<span className="company-item-rectangle"></span>
													News Highlights
												</h4>
											</div>
											<div className="col-12">
												{publicNewsArticles.results.map((item, index) => {
													const date_arr = item.date_published ? item.date_published.split(/\D/) : [];
													
													const news_date = date_arr.length >= 6 ? 
														new Date( date_arr[0], date_arr[1]-1, date_arr[2], date_arr[3], date_arr[4], date_arr[5] ) : false;
													
													return index < 8 ? (
														<article onClick={() => this.viewArticle(item.id)} className="company-news-item" key={index}>
															<a href={item.url} target="_blank">
																<div className="img-container">
																	{item.lead_image_url ? (
																		<img
																			src={item.lead_image_url}
																			alt={item.title}
																		/>
																	) : (
																		""
																	)}
																	<span className="publish-date">
																		{news_date ? news_date.getDate() +
																			" " +
																			months[news_date.getMonth()] +
																			" " +
																			news_date.getFullYear() : ""}
																	</span>
																</div>

																<div className="text-container">
																	<div className="title-container">
																		<strong>{item.title}</strong>
																	</div>

																	<p>{item.excerpt}</p>
																	<span className="domain">{item.domain}</span>
																</div>
															</a>
														</article>
													) : '';
												})}
												<div className="clear"></div>
											</div>
										</div>
									</div>
								</div>
							)}

							<Box flexGrow={1} m={2} className="club-deal-control">
								<Grid container spacing={2}>
									<Grid item xs={12} md={6}>
										<Button
											onClick={(e) =>
												this.handleExpressBuySellDialogOpen(
													{
														price: clubDetail.price,
														min_size: clubDetail.min_size,
														company: companyDetailResponse.name,
														logo: companyDetailResponse.logo,
														dialogTitle: content_placeholders.buttons.express_buy_interest,
														interest_type: "INVEST",
													},
													e
												)
											}
											//onClick={this.openSellPopup}
											className="btn-mid express-interest">
											<img
												src={expressInterestIcon}
												className="express-icon"
											/>
											{
												loginUserData.activeAccessRight &&
												loginUserData.activeAccessRight.access_right &&
												loginUserData.activeAccessRight.access_right.permission == content_placeholders.user_type.trial_user ?
													content_placeholders.buttons.request_full_access 
												: clubDetail.price
													? content_placeholders.buttons.invest_now
													: content_placeholders.buttons.express_buy_interest
											}
											<img src={arrowIcon} className="arrow-icon" />
										</Button>
										{
										clubDetail && clubDetail.committed_by && clubDetail.committed_by.toLowerCase().indexOf('gateway blurry') == -1 
										&& Object.keys(clubDetail).length &&
										( clubDetail.transaction_format || clubDetail.min_size ||
											clubDetail.brokerage_fee || clubDetail.brokerage_fee_note ||
											clubDetail.vehicle_fee || clubDetail.vehicle_fee_note ||
											clubDetail.price || clubDetail.committed_by ||
											clubDetail.allocation_note )
										? 
											<span className="committed-by">
												<img
													src={calendarIcon}
													style={{ marginRight: "10px" }}
												/>
												Indicative commitment by{" "}
												<strong>{clubDetail.committed_by}</strong>
											</span>
											: ''}
									</Grid>
									<Grid item xs={12} md={6}>
										{clubDetail && clubDetail.price && clubDetail.committed_by && clubDealLinks.map((item, index) => {
											switch (item.file_type) {
												case "application/pdf":
													return (
														<a
															href={item.url}
															key={index}
															onClick={(e) =>
																this.handleTeaserDialogOpen(
																	item.url,
																	item.link_name,
																	e
																)
															}
															className="btn-mid btn-doc">
															<img src={docIcon} className="doc-icon" />
															View {item.link_name}
														</a>
													);
											}
										})}
										<div className="clear"></div>
									</Grid>
								</Grid>
							</Box>
								
						{
							similar_companies.length && user_type.indexOf("SINGLE_COMPANY") == -1 ?  
							<div className="row">
								<div className="col col-12">
									<div className="row">	
										<div className="col col-12 mt-3 similar-companies">
											<div className="box">
												<h4 className="subheading-color company-item-title">
													<span className="company-item-rectangle"></span>
													Similar Companies
												</h4>
												{ this.renderCardList(similar_companies, "marketintelligence")}
											</div>
										</div>
									</div>
								</div>
							</div>
							: ''
						}
						

						<div className="row">
							<div className="col col-12 mt-3 refer-a-friend">
								<div className="box">
									<div className="row">
										<div className="col col-12">
											<h4 className="subheading-color">
												<img src={referIcon} style={{ marginRight: "10px" }} />
												Refer a friend
											</h4>
											<p>Enter name/email here.</p>
										</div>
									</div>
									<div className="row">
										<div className="col col-12 col-md-6">
											<TextField
												margin="normal"
												id="first_name"
												name="first_name"
												value={referral.first_name}
												onChange={(e) => this.handleChangeReferralValue(e)}
												label="First Name"
												variant="outlined"
												InputLabelProps={{
													shrink: true,
												}}
												fullWidth
											/>
										</div>
										<div className="col col-12 col-md-6">
											<TextField
												margin="normal"
												id="last_name"
												name="last_name"
												value={referral.last_name}
												onChange={(e) => this.handleChangeReferralValue(e)}
												label="Last Name"
												variant="outlined"
												InputLabelProps={{
													shrink: true,
												}}
												fullWidth
											/>
										</div>
									</div>
									<div className="flex-row-center">
										<div className="col col-12 col-md-10 email-wrap">
											<TextField
												margin="normal"
												id="email"
												name="email"
												value={referral.email}
												onChange={(e) => this.handleChangeReferralValue(e)}
												label="Email"
												variant="outlined"
												InputLabelProps={{
													shrink: true,
												}}
												fullWidth
											/>
										</div>
										<div className="col col-12 col-md-2">
											<Button
												className="btn-referral-submit btn-submit blue-btn"
												onClick={this.handleSubmitReferral.bind(this)}>
												Submit
											</Button>
										</div>
									</div>
								</div>
							</div>
						</div>
						
						<Box flexGrow={1} m={2}>
							<Grid container spacing={2}>
								<Grid item xs={12}>
									<DisclaimerData />
								</Grid>
							</Grid>
						</Box>
						
					</div>
				</div>

				<TeaserDialog
					open={pdf.open}
					url={pdf.url}
					link_name={pdf.link_name}
					closePopup={this.handleTeaserDialogClose}
					/>

				<VideoDialog
					open={video.open}
					url={video.url}
					link_name={video.link_name}
					closePopup={this.handleVideoDialogClose}
					/>


				<ExpressBuySellDialog
					open={expressInterest.open}
					deal={expressInterest.deal}
					historicalPrice={historicalPrice["hist_data"]}
					logo_url={companyDetailResponse.logo_url}
					target={expressInterest.target}
					price_slider={this.state.expressInterest.price_slider}
					handleChange={this.handleChangeValue}
					closePopup={this.handleExpressBuySellDialogClose}
					onValuationChange={this.onValuationChange}
					setValuation={this.setValuation}
					handleSubmitInterest={this.handleSubmitCommitment}
					handleCancelInterest={this.handleCancelCommitment}
					handleChangePriceInputType={this.handleChangePriceInputType}
					/>

				<OnboardingDialog
					// open={onboarding.open}
					{...onboarding}
					closePopup={this.handleOnboarDialogToggle}
					handleSubmitOnboardingRequest={this.handleSubmitOnboardingRequest}
					handleShowOnboardingToggle={this.handleShowOnboardingToggle}
					handleChangeInvestorStatus={this.handleChangeInvestorStatus}
					onPdfDocumentSuccess={this.onPdfDocumentSuccess}
					/>

				<Snackbar
					anchorOrigin={{
						vertical: "top",
						horizontal: "center",
					}}
					openStyle={snackBar.open}
					handleSnackbarClose={this.handleSnackbarClose}
					type={snackBar.error ? "error" : "success"}
					message={snackBar.message}
					autoHideDuration={4000}
					/>

				<SellSharesDialog
					closeSellPopup={this.closeSellPopup}
					handleSubmitSellInterest={this.handleSubmitSellInterest}
					openSell={openSell}
					handleChange={this.handleChange}
					{...{ company, notional, price, comment }}
					/>
			</div>
		);
	}
}

export default withStyles(Styles)(CompanyDetail);
