import { combineReducers } from 'redux';

import {
    reducer as portfolioControlReducer,
} from './components/portfolio_control';

import {
    getPriceIndex,
    getCompanyDetail,
    getFundingRound,
} from './Action';

import {
    postSubmitPortfolio,
} from './components/portfolio_control/Action';

import { default as InitialState } from './InitialState';

import {
    rearrange_price_index,
} from '../../../utils/util';

// function rearrange_results(price_index){
//     const new_payload = {}
//     for( let key in price_index ){
//         const company_item = price_index[key];
//         new_payload[key] = [];
//         const temp_payload = {};
//         const payload_keys = [];
//         for( let ii=0; ii<company_item.length; ii++ ){
//             // console.log( company_item[ii] )
//             const item_payload = {
//                 date: company_item[ii]['date'],
//             }

//             if( company_item[ii]['targettable'].toLowerCase() == 'buy' ){
//                 item_payload['average_bid'] = company_item[ii]['average_price'];
//                 item_payload['lag_bid'] = company_item[ii]['lag_price'];
//                 item_payload['average_bid_growth'] = company_item[ii]['average_growth'];
//             } else {
//                 item_payload['average_offer'] = company_item[ii]['average_price'];
//                 item_payload['lag_offer'] = company_item[ii]['lag_price'];
//                 item_payload['average_offer_growth'] = company_item[ii]['average_growth'];
//             }

//             if( temp_payload[company_item[ii]['date']] ){
//                 temp_payload[company_item[ii]['date']] = {
//                     ...temp_payload[company_item[ii]['date']],
//                     ...item_payload
//                 }
//             } else {
//                 temp_payload[company_item[ii]['date']] = item_payload;
//             }

//             if( payload_keys.indexOf(company_item[ii]['date']) == -1 ){
//                 payload_keys.push(company_item[ii]['date']);
//             }
//         }

//         // console.log( payload_keys );
//         // console.log( temp_payload );

//         for( let iii=0; iii<payload_keys.length; iii++ ){
//             new_payload[key].push( temp_payload[payload_keys[iii]] );
//         }
//     }
//     return new_payload;
// }

function get_last_id(cost_base){
    let largest_id = 0;
    ( Object.keys(cost_base) ).forEach(company_id => {
        cost_base[company_id].forEach(item => {
            if( item.id > largest_id ) largest_id=item.id;
        })
    })
    return largest_id;
}


function add_to_cost_base(cost_base, payload){
    payload.forEach(item => {
        if( ( Object.keys(cost_base) ).indexOf(item.company_id) == -1 ){
            cost_base[item.company_id] = [];
        }

        const item_id = get_last_id(cost_base) + 1;
        item.id = item_id;
        cost_base[item.company_id].push(item);
    });
    return cost_base;
}

export default combineReducers({
    portfolioControlReducer,
    reducer(state=InitialState, action){
        switch( action.type ){
            case getPriceIndex.request.toString():
            case getPriceIndex.failure.toString():
                return Object.assign({}, state, {
                    price_index: {}
                });
            case getPriceIndex.success.toString():
                return Object.assign({}, state, {
                    price_index: rearrange_price_index(action.payload)
                });
            case postSubmitPortfolio.success.toString():
                return Object.assign({}, state, {
                    cost_base: add_to_cost_base({...state.cost_base}, action.payload)
                });
            case getCompanyDetail.request.toString():
            case getCompanyDetail.failure.toString():
                return Object.assign({}, state, {
                    company_details: {}
                });
            case getCompanyDetail.success.toString():
                return Object.assign({}, state, {
                    company_details: action.payload
                });
            case getFundingRound.request.toString():
            case getFundingRound.failure.toString():
                return Object.assign({}, state, { 
                    funding_rounds: {}
                });
            case getFundingRound.success.toString():
                return Object.assign({}, state, {
                    funding_rounds: action.payload
                });
            default:
                return state;
        }
    }
})