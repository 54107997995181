import React from 'react';

import {
    Typography,
    Checkbox,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    Paper,
} from '@material-ui/core';

import {
    TableLabelStyle,
    StyledTableRow,
    TighterHeaderTableCell,
    StyledHoverTableRow,
    TighterTableCell,
    TighterClickableTableCell,
} from '../../../../orderbook/common/Table';

import {
    getLatestFundingRound,
    displayVal,
    numberWithCommas,
    getDigitPadding,
    getLongestDigit,
} from '../../../../../utils/util';

export default (props) => {
    const classes = TableLabelStyle();
    const bids = props.pipelines.resultList.map(item => item['highest_bid']);
    const asks = props.pipelines.resultList.map(item => item['lowest_ask']);
    const orders = bids.concat(asks);
            
    const longest_digit = getLongestDigit(orders);

    return (
        <React.Fragment>
            <Typography variant="h6">Watchlist Companies</Typography>
            <TableContainer component={Paper} className={classes.container}>
                <Table stickyHeader size="small" aria-label="simple table">
                    <TableHead>
                        <StyledTableRow>
                            <TighterHeaderTableCell className="watchlist"></TighterHeaderTableCell>
                            <TighterHeaderTableCell className="company-name">Name</TighterHeaderTableCell>
                            <TighterHeaderTableCell className="price-column">Offer (PPS)</TighterHeaderTableCell>
                            <TighterHeaderTableCell className="price-column">Bid (PPS)</TighterHeaderTableCell>
                            <TighterHeaderTableCell className="price-column">LFR</TighterHeaderTableCell>
                        </StyledTableRow>
                    </TableHead>
                    <TableBody>
                        {
                            props.pipelines.resultList.map((row, index) => {
                                const lfr = props.funding_rounds[row.company_id] ? 
                                    getLatestFundingRound({ results: props.funding_rounds[row.company_id] }) : 
                                    { price: 0, valuation: 0 };
                                
                                const bid_padding = getDigitPadding(row.highest_bid, longest_digit);
                                const offer_padding = getDigitPadding(row.lowest_ask, longest_digit);
                                
                                return (
                                    <React.Fragment key={index}>
                                        <StyledHoverTableRow>
                                            <TighterTableCell
                                                className="watchlist">
                                                    <Checkbox
                                                        className={classes.Checkbox}
                                                        checked={props.selected_company_ids.indexOf(row.company_id) > -1}
                                                        onChange={(evt) => props.toggleCompanySelect(evt, row.company_id)}
                                                        />
                                            </TighterTableCell>
                                            <TighterClickableTableCell
                                                onClick={(evt) => (evt)}
                                                className="company-name"
                                                >
                                                    {
                                                        row.company_name
                                                    }
                                            </TighterClickableTableCell>
                                            <TighterClickableTableCell
                                                onClick={(evt) => (evt)}
                                                className="price-column"
                                                >
                                                    { offer_padding }
                                                    { row.lowest_ask ? numberWithCommas(row.lowest_ask) : ' - ' }
                                            </TighterClickableTableCell>
                                            <TighterClickableTableCell
                                                onClick={(evt) => (evt)}
                                                className="price-column"
                                                >
                                                    { bid_padding }
                                                    { row.highest_bid ? numberWithCommas(row.highest_bid) : ' - ' }
                                            </TighterClickableTableCell>
                                            <TighterClickableTableCell
                                                onClick={(evt) => (evt)}>
                                                    ${ lfr.price } @ {displayVal(lfr.valuation)}
                                                </TighterClickableTableCell>
                                        </StyledHoverTableRow>
                                    </React.Fragment>
                                )
                            })
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </React.Fragment>
    )
}