import {
	put,
	takeLatest,
	all,
	fork,
	call
} from 'redux-saga/effects';

import {
	getFundingRound,
	getAllCompanies,
	getCompanyInvestors,
	getVerticals,
	getLatestFundingRound
} from './Action';

import {
	companies
} from '../../api';

function* getFundingRoundResponder({payload}){
	try{
		const { data } = yield call(companies.getFundingRound, payload.company_id, "", payload);
		
		yield put(getFundingRound.success(data));
	} catch (e){
		yield put(getFundingRound.failure(e));
	} finally {

	}
}

function* getLatestFundingRoundResponder({ payload }){
	try{
		const { data } = yield call(companies.getLatestFundingRound, payload.company_id, payload);
		yield put(getLatestFundingRound.success(data));
	} catch(e){
		yield put(getLatestFundingRound.failure(e));
	} finally{

	}
}

function* getVerticalsResponder({payload}){
	try{
		const { data } = yield call(companies.getVerticals, payload.company_id,payload);
		
		yield put(getVerticals.success(data));
	} catch (e){
		yield put(getVerticals.failure(e));
	} finally {

	}
}

function* getCompanyInvestorsResponder({payload}){
	try{
		const { data } = yield call(companies.getCompanyInvestors,payload.company_id, payload);
		
		yield put(getCompanyInvestors.success(data));
	} catch (e){
		yield put(getCompanyInvestors.failure(e));
	} finally {

	}
}

function* getAllCompaniesResponder({payload}){
	var query_string=''
	if(payload.paginationStr){
		query_string+=payload.paginationStr
	}
	try{
		const { data } = yield call(companies.getAllCompanies, payload.company_id,payload);
		
		yield put(getAllCompanies.success(data));
	} catch (e){
		yield put(getAllCompanies.failure(e));
	} finally {

	}
}

function* saga(){
	yield all([
		takeLatest(getFundingRound.request, getFundingRoundResponder),
		takeLatest(getLatestFundingRound.request, getLatestFundingRoundResponder),
		takeLatest(getAllCompanies.request, getAllCompaniesResponder),
		takeLatest(getCompanyInvestors.request, getCompanyInvestorsResponder),
		takeLatest(getVerticals.request, getVerticalsResponder)
	])
}

export default saga;