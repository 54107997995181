import {
    createStore as createReduxStore,
    applyMiddleware,
    compose,
    combineReducers
} from 'redux';
import { fromJS } from 'immutable';
import * as reducers from './Reducers';
import createSagaMiddleware from 'redux-saga';
import { default as root } from './Sagas';

const createStore = (dev = false) => {
    const sagaMiddleware = createSagaMiddleware();

    const middlewares = compose(
        applyMiddleware(sagaMiddleware),
        (dev && window.__REDUX_DEVTOOLS_EXTENSION__) ? window.__REDUX_DEVTOOLS_EXTENSION__() : (f) => f
    );

    const store = createReduxStore(
        combineReducers({
            ...reducers
        }),
        middlewares
    );
    sagaMiddleware.run(root);
    return store;
}

export { createStore };
