import {
    InputLabel,
    Select,
    withStyles,
} from '@material-ui/core';

import {
    ReScaleForDeviceScale
} from '../../../utils/util';
import { 
    globalBodyFont, 
    global150BodyFont,
    globalLabelFont,
    global150LabelFont,
    globalFontFamily,
} from "../../styles";

export const StyledInputLabel = withStyles((theme) => ({
    root: {
        fontFamily: globalFontFamily,
        fontSize: ReScaleForDeviceScale(1.5, global150LabelFont, globalLabelFont),
    }
}))(InputLabel);

export const StyledSelect = withStyles((theme) => ({
    root: {
        fontFamily: globalFontFamily,
        fontSize: ReScaleForDeviceScale(1.5, global150BodyFont, globalBodyFont)
    }
}))(Select);