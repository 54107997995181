import Cookies from 'universal-cookie';

export default () => {
    const cookies = new Cookies();
    cookies.remove('preservedUrl_pathname', { path: '/' } );
    cookies.remove('preservedUrl_search', { path: '/' } );
    cookies.remove('preservedUrl_set_time', { path: '/' } );
}

// localStorage.removeItem('preservedUrl');
