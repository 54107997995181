import React from "react";
import moment from "moment";

import {
	put,
} from 'redux-saga/effects';

import { Unauthorized } from '../modules/login/Action';

import {
	BiInfoSquare,
	BiCheckboxSquare,
	BiChevronUpSquare,
} from "react-icons/bi";

import { content_placeholders } from "../config";

import { logEventInternallyAndNewRelic } from "./ga";
import { default as NewRelicError } from './NewRelicError';

export const TOKEN_MAX_AGE=14400000;
export const MONTH_ARR = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export function GetCurrentUrl(){
	return window && window.location ? window.location.pathname : "/";
}

export function CompanyStatusCalculate(parms) {
	let { live, committed_by } = parms;
	// if live  not true upcoming
	// if live true
	// commixed_by : within 3 days closing soon,  else live
	let icon = <BiInfoSquare />;
	let color = "#808080";
	let statusTip = "UPCOMING";
	if (!live) {
		icon = <BiInfoSquare />;
		color = "#808080";
		statusTip = "UPCOMING";
	} else {
		if (!!committed_by) {
			let today = moment().format("YYYY-MM-DD");
			let threeDaysLater = moment(today).add(3, "days").format("YYYY-MM-DD");
			if (moment(committed_by).isBetween(today, threeDaysLater, "day", "[]")) {
				icon = <BiChevronUpSquare />;
				color = "#258AC6";
				statusTip = "CLOSING SOON";
			} else {
				icon = <BiCheckboxSquare />;
				color = "#ECAA32";
				statusTip = "LIVE";
			}
		} else if (parms.trial_user){
			icon = <BiCheckboxSquare />;
			color = "#ECAA32";
			statusTip = "LIVE";
		}
	}
	return {
		icon: icon,
		color: color,
		statusTip: statusTip,
	};
}

export function replaceEnterCharacter(string) {
	return string.replace("\r\n", "<br/>");
}

export function getTodayHistoricalPrice(historicalPrice) {
	let result = { min: 0, max: 0 };
	if(historicalPrice){
		for( let ii=0; ii<historicalPrice.length; ii++ ){
			const todays_price = historicalPrice[ii];
			if( todays_price['max_buy'] > todays_price['max_sell'] && 
					todays_price['max_buy'] > result['max'] ) {
					result['max'] = todays_price['max_buy'];
			} else if ( todays_price['max_sell'] > result['max'] ){ 
					result['max'] = todays_price['max_sell'];
			}
	
	
			// if( !payload['min_y_axis'] ){
			//     if( todays_price['min_buy'] < todays_price['min_sell'] && 
			//         ( todays_price['min_buy'] && todays_price['min_sell'] ) ){
			//         payload['min_y_axis'] = todays_price['min_buy'];
			//     } else if ( todays_price['min_buy'] ) {
			//         payload['min_y_axis'] = todays_price['min_buy']
			//     } else if ( todays_price['min_sell'] ){
			//         payload['min_y_axis'] = todays_price['min_sell']
			//     }
			// } else if( todays_price['min_buy'] < todays_price['min_sell'] && 
			//             todays_price['min_buy'] < payload['min_y_axis'] && todays_price['min_buy'] ){ 
			//     payload['min_y_axis'] = todays_price['min_buy'];
			// } else if ( todays_price['min_sell'] < payload['min_y_axis'] && todays_price['min_sell'] ){ 
			//     payload['min_y_axis'] = todays_price['min_sell'];
			// }
		}
	}
	
	return result;
}
 
export function formatDate(date, format) {   
    if (!date) return;   
    if (!format) format = "yyyy-MM-dd";   
    switch(typeof date) {   
        case "string":   
            date = new Date(date.replace(/-/, "/"));   
            break;   
        case "number":   
            date = new Date(date);   
            break;   
    }    
    if (!date instanceof Date) return;   
    var dict = {   
        "yyyy": date.getFullYear(),   
        "M": date.getMonth() + 1,   
        "d": date.getDate(),   
        "H": date.getHours(),   
        "m": date.getMinutes(),   
        "s": date.getSeconds(),   
        "MM": ("" + (date.getMonth() + 101)).substr(1),   
        "dd": ("" + (date.getDate() + 100)).substr(1),   
        "HH": ("" + (date.getHours() + 100)).substr(1),   
        "mm": ("" + (date.getMinutes() + 100)).substr(1),   
        "ss": ("" + (date.getSeconds() + 100)).substr(1)   
    };       
    return format.replace(/(yyyy|MM?|dd?|HH?|ss?|mm?)/g, function() {   
        return dict[arguments[0]];   
    });                   
}   

export function numberWithCommas(x, decimal=2) {
	return x ? x.toFixed(decimal).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : x;
}

export function ReScaleForDeviceScale(scale=1.5, if_return, else_return){
	return window && window.devicePixelRatio && window.devicePixelRatio>=1.5 ? if_return : else_return;
}

export function extractAllowedCompany(user_type){
	let company_id = 0;
	if( user_type.indexOf(content_placeholders.user_type.single_company_user) > -1 ){
		company_id = user_type.split('_');
		company_id = company_id[company_id.length - 1];
	}
	return company_id;
}

export function compileQueryString(qs){
	let query_string = "?";
	let counter = 0;
	if( qs ){
		for( var key in qs ){
			if( counter ) query_string += "&";
			query_string += key+"="+qs[key];
			counter++;
		}
	}
	return query_string;
}

export function getImpliedVal(price, lfr_price, lfr_val, decimal, return_digit){
	const dec = !decimal ? 0 : decimal;
    return return_digit ? 
		price / lfr_price * lfr_val >= 1000 ? roundNumber(price / lfr_price * lfr_val / 1000, dec) : roundNumber(price / lfr_price * lfr_val, dec) 
		: price / lfr_price * lfr_val >= 1000 ? 
				'$'+numberWithCommas(price / lfr_price * lfr_val / 1000, dec)+'B' : 
				'$'+numberWithCommas(price / lfr_price * lfr_val, dec)+'M';
}

export function displayVal(value, b_decimal, m_decimal){
	return value >= 1000 ? 
		'$'+numberWithCommas(value/1000, b_decimal ? b_decimal : 0)+'B' :
		'$'+numberWithCommas(value, m_decimal ? m_decimal : 0)+'M';
}

export function roundNumber(num, decimal=100){
    return Math.round((num + Number.EPSILON) * decimal) / decimal;
}

export function getLatestFundingRound(funding_rounds, custom_condition){
	const response = { price: 0, valuation: 0 };
	const valid_funding_rounds = 
		custom_condition ? 
		funding_rounds.results.filter(
			fr => 
				fr.price != -1 && fr.post_money_valuation != -1 && 
				fr.price && fr.post_money_valuation && custom_condition(fr)) : 
		funding_rounds.results.filter(
			fr => 
				fr.price != -1 && fr.post_money_valuation != -1 && 
				fr.price && fr.post_money_valuation);
	
	if( valid_funding_rounds.length ){
		response['price'] = roundNumber(valid_funding_rounds[0].price);
		response['valuation'] = roundNumber(valid_funding_rounds[0].post_money_valuation, 1);
	}

	return response;
}

export function getCompanyValuation(funding_rounds){
	const response = { valuation: 0, date: '' }
	const valid_funding_rounds = funding_rounds.results.filter(
			fr => fr.post_money_valuation);
	
	if( valid_funding_rounds.length ){
		response['valuation'] = valid_funding_rounds[0]['post_money_valuation'];
		response['date'] = valid_funding_rounds[0]['deal_date'];
	}
	return response;
}

export function convertSizeLabel(size){
	return size.replaceAll(' mn', 'M');
}

export function getLongestDigit(orders){
	let digit = 0;
	for( let ii=0; ii<orders.length; ii++ ){
		if( orders[ii] && Math.floor(orders[ii]).toString().length > digit ) 
			digit = orders[ii].toString().length;
	}
	return digit;
}

export function getDigitPadding(price, longest_digit){
	let price_padding = <span style={{ opacity: 0 }}></span>;
	let standardized_price = price ? price : 0;
	if( Math.floor(standardized_price).toString().length < longest_digit ){
		const items = [];
		for( let ii=Math.floor(standardized_price).toString().length; ii<longest_digit; ii++ ){
			items.push(0);
			if( ii && ii % 3 == 0 ) items.push(',')
		}
		price_padding = <span style={{ opacity: 0 }}>{items.join('')}</span>;
	}
	return price_padding;
}

export function rearrange_cost_base(cost_base, company_details, price_index){
    const cost_base_keys = Object.keys(cost_base);
    const new_cost_base = [];

    cost_base_keys.forEach(key_item => {
        cost_base[key_item].forEach(item => {
            item['company_id'] = key_item;
            if( company_details[key_item] ){
                item['company'] = company_details[key_item];
            }

			if( price_index && price_index[key_item] && price_index[key_item].length ){
				const all_ave_bids_items = price_index[key_item].filter(item => item.average_bid)
				item['latest_index'] = all_ave_bids_items[all_ave_bids_items.length-1];
			}
            new_cost_base.push(item);
        })
    })

    return new_cost_base;
}

export function get_array_total(arr){
	return arr.reduce((partialSum, a) => partialSum+a, 0);
}

export function get_average(arr){
    let total = 0;
    let count = 0;
    arr.forEach((item, index) => {
        total += item;
        count++;
    });
    return total / count;
}

export function rearrange_price_index(price_index){
	const new_payload = {}
    for( let key in price_index ){
        const company_item = price_index[key];
        new_payload[key] = [];
        const temp_payload = {};
        const payload_keys = [];
        for( let ii=0; ii<company_item.length; ii++ ){
            // console.log( company_item[ii] )
            const item_payload = {
                date: company_item[ii]['date'],
            }

            if( company_item[ii]['targettable'].toLowerCase() == 'buy' ){
                item_payload['average_bid'] = company_item[ii]['average_price'];
                item_payload['lag_bid'] = company_item[ii]['lag_price'];
                item_payload['average_bid_growth'] = company_item[ii]['average_growth'];
            } else {
                item_payload['average_offer'] = company_item[ii]['average_price'];
                item_payload['lag_offer'] = company_item[ii]['lag_price'];
                item_payload['average_offer_growth'] = company_item[ii]['average_growth'];
            }

            if( temp_payload[company_item[ii]['date']] ){
                temp_payload[company_item[ii]['date']] = {
                    ...temp_payload[company_item[ii]['date']],
                    ...item_payload
                }
            } else {
                temp_payload[company_item[ii]['date']] = item_payload;
            }

            if( payload_keys.indexOf(company_item[ii]['date']) == -1 ){
                payload_keys.push(company_item[ii]['date']);
            }
        }

        // console.log( payload_keys );
        // console.log( temp_payload );

        for( let iii=0; iii<payload_keys.length; iii++ ){
            new_payload[key].push( temp_payload[payload_keys[iii]] );
        }
    }
    return new_payload;
}

export function* handleCatchSagaError(e, error_key){
	if( e.message && e.response ){
		const err_payload = {
			type: `api_failure_${error_key}`,
			data: {
				config: e.response.config,
				data: e.response.data,
				status: e.response.status
			},
			err: new Error(`API Failure ${error_key}: ${e.message}`)
		}
		logEventInternallyAndNewRelic(err_payload);
	} else if (e.message){
		NewRelicError( new Error(`API Failure ${error_key}: ${e.message}`) )
	} else {
		NewRelicError( new Error(`API Failure ${error_key} with no message`) )
	}

	if (e.response && e.response.status == 401) {
		yield put(Unauthorized(e));
	}
}

export function initializeLiveChat(data){
	if( data.hubspot_token && window.hsConversationsSettings ){
		const email = data['email'] ? data['email'] : data['user']['email'];
		window.hsConversationsSettings = {
			identificationEmail: email,
			identificationToken: data['hubspot_token']
		}

		const widget_status = window.HubSpotConversations.widget.status();
		// console.log( widget_status );
		if( widget_status.loaded ){
			window.HubSpotConversations.widget.refresh();
		} else {
			window.HubSpotConversations.widget.load();
		}
		
	}
}

export function destroyLiveChat(){
	if( window.hsConversationsSettings ){
		window.HubSpotConversations.widget.remove();
	}
}

export function generateWebContentPayload(payload){
	// console.log( payload );
	const { loginUserData, token, identifier, public_url, qs } = payload;
	const { activeAccessRight: { access_right : { permission } } } = loginUserData;
	
	let sub_path = permission == content_placeholders.user_type.wealth_user ? `wealth` : 
						permission == content_placeholders.user_type.orderbook_user ? 'orderbook' :
						permission == content_placeholders.user_type.boost_user ? 'boost' :
						'club';
	
	if( qs && qs.portal_type ){
		sub_path = qs.portal_type;
	}

	if( public_url ) sub_path += `/public`
	let path = `/v2/${sub_path}/web-contents/search/`

	payload['path'] = path;
	const qs_payload = {
		identifier: identifier,
		single: 1,
	}

	if( token ) qs_payload['token'] = token;
	payload['qs'] = qs_payload;
	return payload;
}

export function isMobile(){
	return window.innerWidth <= 768;
}

export function showBlurred(value){
	const blur_value = typeof value == 'string' ? 'xxxx' : -1;
	return value == blur_value;
}

export function longBlurText(){
	const blur_text = [];
    for(let ii=0;ii<100;ii++){
        blur_text.push(ii%2 ? 'xxxxxxx' : ' ');
    }
	return blur_text;
}


export const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
];

export const timeFormatConvert = (time) =>{
    try{
        const date_arr = time ? time.split(/\D/) : [];
        time = date_arr[2] + " " + months[date_arr[1]-1] + " " + date_arr[0]
        return time
    }catch (e){
        // console.log( e );
        return false;
    }
}

export const showValueByFormat = (item) => {
    // console.log( item );
    try {
        switch(item.format){
            case 'Text - CSV':
                return item.value;
            case 'Date':
                return timeFormatConvert(item.value);
            case 'True/False':
                return item.value ? 'Yes' : 'No';
            default:
                return item.value;
        }
    } catch(e){
        return '-';
    }
}

export const remainingAllocation = (deal_size, demand_size) => {
	if( demand_size > deal_size ){
		return <strong>{' (We have received full commitment on this opportunity. However, please continue to submit your interest as Gateway is actively sourcing for additional allocation.)'}</strong>
	} else {
		return <strong>{` (Remaining allocation: ${numberWithCommas(deal_size - demand_size)})`}</strong> 
	}
}

export const getActiveAccessRight = (loginUserData) => {
    const { accessRight } = loginUserData;
    const activeAccessRight = accessRight.filter(item => item.active);
    loginUserData['activeAccessRight'] = activeAccessRight.length ? activeAccessRight[0] : { access_right: { active: true, permission: '' } }
    return loginUserData;
}

export const extractPortalType = (pathname) => {
	let portal_type_sq = '';
	const regex_result = pathname.match(/\/([A-Za-z]+)\/public\//);
	if( regex_result && regex_result.length > 1 && regex_result[1] ){
		portal_type_sq = regex_result[1];
	}
	return portal_type_sq;
}

export const redirectPortalByUserType = (user_type, pathname, search) => {
	try{
		let type = ''
		switch(user_type){
			case content_placeholders.user_type.wealth_user:
				type = 'wealth';
				break;
			case content_placeholders.user_type.orderbook_user:
				type = 'orderbook';
				break;
			case content_placeholders.user_type.new_version_user:
				type = 'club';
				break;
			default:
				break;
		}
		if(type){
			const paths = pathname.split('/');
			return pathname.replace(paths[1],type)
		}else{
			return pathname
		}
	}catch{
		return pathname
	}
	
}