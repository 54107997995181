import React from 'react';

import {
    makeStyles,
    Card,
    Grid,
    Typography,
    Button,
    useMediaQuery,
} from '@material-ui/core';
// import { Link } from 'react-router-dom';

import {
    feature_flags,
} from '../../../../config';

import { 
    globalMD,
    globalLG,
    globalXL, 
} from '../../../styles';

const useStyles = makeStyles((theme) => ({
    container: {

    },
    Card: {
        padding: 16,
        marginTop: 16,
        marginBottom: 16
    },
    CardMobile: {
        padding: 16
    },
    CardMobileLast: {
        padding: 16,
        marginBottom: 16
    }
}));

const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
];

const timeFormatConvert = (time) =>{
    try{
        const date_arr = time ? time.split(/\D/) : [];
        time = date_arr[2] + " " + months[date_arr[1]-1] + " " + date_arr[0]
    }catch (e){

    }
    
    return time
}

export default (props) => {
    
    const { appConfig, loginUserData: { email } } = props;
    const classes = useStyles();

    const flag_payload = feature_flags.extract_payload(
                        appConfig,
                        feature_flags.wealth_flags.SectorTrend.flag_name,
                        feature_flags.wealth_flags.SectorTrend.attributes.external_doc,
                        email
                    )
    const isMobile = useMediaQuery(`(max-width:${globalMD}px)`);
    const isLG = useMediaQuery(`(max-width:${globalLG}px)`);
    const isXL = useMediaQuery(`(max-width:${globalXL}px)`);
    let itemXs = 3;
    if(isMobile){
        itemXs = 12;
    }
    if(!isMobile && isLG){
        itemXs = 6;
    }
    if(!isMobile && !isLG && isXL){
        itemXs = 4;
    }
    return (
        <React.Fragment>
            <Grid container spacing={2}>
                {props.eventData.map((event, index) => {
                    let payload = {}
                    try{
                        payload = JSON.parse(event.timeline_value);
                    } catch(e){

                    }
                    
                    return <Grid item xs={itemXs} key={index}>
                        <Card className={isMobile ? classes.CardMobile : classes.Card}>
                            <span style={{ fontSize: 12, display: 'block', lineHeight: '14px', height: '20px',overflow:'hidden' }}>
                                {
                                    timeFormatConvert(event.timeline_date)+" - "
                                }
                                {
                                    payload.content && Array.isArray(payload.content) && payload.content.length ?
                                        payload.content[0] : ''
                                }
                            </span>
                            <Typography variant="h6" style={{ lineHeight: '1rem', height: '2rem',overflow:'hidden', marginTop: '4px' }}>{payload.title}</Typography>
                            <div
                                style={{ height: '10rem', marginTop: '4px'}}>
                                    <img style={{ width: 'auto', height: '100%', margin: '0 auto', display: 'block', objectFit: 'contain' }} src={event.banner_url} />
                            </div>
                            {
                                event.event_url &&
                                <Button className="btn-submit blue-btn" onClick={()=> window.open(event.event_url, "_blank")}>
                                    {
                                        new Date() > new Date(event.timeline_date) ? `Access the event` : `Join this event`
                                    }
                                </Button>
                            }
                        </Card>
                    </Grid>
                })}
                {
                    feature_flags.feature_is_available(
                        appConfig,
                        feature_flags.wealth_flags.SectorTrend.flag_name,
                        feature_flags.wealth_flags.SectorTrend.attributes.external_doc,
                        email
                    ) ? 
                            <Grid item xs={itemXs}>
                                <Card className={isMobile ? classes.CardMobileLast : classes.Card} >
                                    <span style={{ fontSize: 12, display: 'block', lineHeight: '14px', height: '20px',overflow:'hidden'}}>
                                        Gateway Private Market Intelligence
                                    </span>
                                    <Typography variant="h6" style={{ lineHeight: '1rem', height: '2rem',overflow:'hidden', marginTop: '4px' }}>Sector Performance Trends</Typography>
                                    <div
                                        style={{ height: '10rem', marginTop: '4px'  }}>
                                            <img style={{ width: 'auto', height: '100%', margin: '0 auto', display: 'block', objectFit: 'contain' }} src={'https://media.gatewayprivatemarkets.com/portal/WealthPortal/GatewaySectorPerformance.png'} />
                                    </div>
                                    {
                                        flag_payload.external_doc.url &&
                                        <Button className="btn-submit blue-btn" onClick={()=> window.open(flag_payload.external_doc.url, "_blank")}>
                                            Detail
                                        </Button>
                                    }
                                </Card>
                            </Grid>
                        : <Grid item xs={itemXs}>
                            
                        </Grid>
                }
            </Grid>
        </React.Fragment>
    )
}