import Cookies from 'universal-cookie';
import { default as ReadQueryString } from './ReadQueryString';
import { email_campaigns } from '../config';

export default (pathname, search) => {
    const cookieMaxAge = 18000;
    // const cookie_key = email_campaigns.query_string.keys;
    const cookies = new Cookies();
    const query_string = ReadQueryString(search);

    const utm_keys = email_campaigns.extract_key(query_string);

    if( utm_keys.length ){
        // const cookie_items = {}
        // utm_keys.forEach(key => {
        //     cookie_items[key] = query_string[key];
        // });

        utm_keys.map(key => {
            if( ( Object.keys(query_string) ).indexOf(key) > -1  ){
                cookies.set(key, query_string[key], { maxAge: cookieMaxAge });
            }
        })
    }
}