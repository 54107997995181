import { CreateApiAction as _createApiAction } from '../../utils';

const key = "FORGOT_PASSWORD";

const CreateApiAction = _createApiAction(key);

const resetPasswordWithEmail = CreateApiAction('RESET_PASSWORD_WITH_EMAIL')

export {
    resetPasswordWithEmail
};
