// import * as React from 'react';
import { connect } from "react-redux";

import {
	getPipeline,
	getDeal,
	getMoreDeal,
	getVerticalsList,
	getRegionList,
	getViewSummary,
	getDealTimeline,
	getGpCompany,
	getPermissionedDeal,
	clearWatchlistMessage,
	// updateProps
} from "./Action";
import {
    ValidateUserInBackground
} from '../../login/Action';
import { createUserMessage } from "../../common/header/Action";

import { resetSnackBar } from "../../account/Action";

import { CollapseSidebar } from "../../root/Action";

import { default as Pipeline } from "./Pipeline";
export default connect(
	(state) => ({
		pipelineResponse: state.wealthPipeline.reducer.pipelineResponse,
		page: state.wealthPipeline.reducer.page,
		dealResponse: state.wealthPipeline.reducer.dealResponse,
		deal_loading: state.wealthPipeline.reducer.deal_loading,
		no_more_deal_data: state.wealthPipeline.reducer.no_more_deal_data,
		verticalsListResponse: state.wealthPipeline.reducer.verticalsListResponse,
		regionListResponse: state.wealthPipeline.reducer.regionListResponse,
		showSnakbar: state.account.reducer.showSnakbar,
		passwordError: state.account.reducer.passwordError,
		responseMsg: state.account.reducer.responseMsg,
		loginUserData: state.login.reducer.loginUserData,
		loaderState: state.root.reducer.loaderState,
		collapseSidebar: state.root.reducer.collapseSidebar,
		gpCompanyResponse: state.wealthPipeline.reducer.gpCompanyResponse,
		dealTimeline: state.wealthPipeline.reducer.dealTimeline,
		permissioned_deals: state.wealthPipeline.reducer.permissioned_deals,
		toggle_watchlist: state.wealthPipeline.reducer.toggle_watchlist,
		appConfig: state.root.reducer.appConfig,
	}),
	(dispatch) => ({
		getPipeline: (payload) => dispatch(getPipeline.request(payload)),
		getDeal: (payload) => dispatch(getDeal.request(payload)),
		getMoreDeal: (payload) => dispatch(getMoreDeal.request(payload)),
		getRegionList: (payload) => dispatch(getRegionList.request(payload)),
		getVerticalsList: (payload) => dispatch(getVerticalsList.request(payload)),
		createUserMessage: (payload) => dispatch(createUserMessage.request(payload)),
		updateProps: (payload) => dispatch({ type: "UPDATE_PROPS", payload: payload }),
		getViewSummary: () => dispatch(getViewSummary.request()),
		resetSnackBar: () => dispatch(resetSnackBar()),
		CollapseSidebar: (payload) => dispatch(CollapseSidebar(payload)),
		getDealTimeline: (payload) => dispatch(getDealTimeline.request(payload)),
		getGpCompany: (payload) => dispatch(getGpCompany.request(payload)),
		getPermissionedDeal: (payload) => dispatch(getPermissionedDeal.request(payload)),
		clearWatchlistMessage: () => dispatch(clearWatchlistMessage()),
		ValidateUserInBackground: (payload) => dispatch(ValidateUserInBackground.request(payload)),
	})
)(Pipeline);
