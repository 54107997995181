import React from 'react';

import {
    Typography,
    FormControl,
    FormGroup,
    FormControlLabel,
    Checkbox,
    Grid,
    TextField,
    withStyles,
    Box,
    Button,
} from '@material-ui/core';

import {
    FormStyle,
} from '../../../common/FormStyle';

import {
    Snackbar,
} from '../../../../common';

class RequestInformation extends React.Component{
    state = {
        has_others: false,
        target_order: {
            id: 0,
            type: ''
        },
        request_info_payload: {
            company_id: 0,
            interest_type: 'ORDERBOOK_REQUEST',
            comment: {
                min_investment_size_m: false,
                dataroom_access: false,
                financial_info: false,
                share_class: false,
                fee_structure: false,
                timeline: false,
                company_presentation: false,
                management_call: false,
                others: null,
                price_chart: false,
                // company_info: null,
                // gp_info: null,
                offer: {
                    id: 0,
                    req_size_m: false,
                    req_implied_val_m: false,
                    req_price: false,
                    prelim_size_m: null,
                    prelim_price_m: null
                },
                demand: {
                    id: 0,
                    req_size_m: false,
                    req_implied_val_m: false,
                    req_price: false,
                    prelim_size_m: null,
                    prelim_price_m: null
                }
            }
        },
        snackBar: {
            open: false,
            error: false,
            message: ''
        }
    }

    constructor(props){
        super(props);
    }

    static getDerivedStateFromProps(nextProps, prevState){
        // console.log( nextProps );
        const { company_detail, request_information, request_info_response } = nextProps;
        const { request_info_payload: { company }, target_order } = prevState;
        const { order_detail } = request_information;

        // console.log( request_info_response );

        // console.log( order_detail );
        
        if( order_detail &&
            order_detail.id &&
            order_detail.buy_type &&
            (
                order_detail.buy_type != target_order.type ||
                order_detail.id != target_order.id
            ) ){
            // const { request_info_payload } = prevState;
            // request_info_payload['company'] = company_detail.id;

            const request_info_payload = {
                company_id: company_detail.id,
                interest_type: 'ORDERBOOK_REQUEST',
                comment: {
                    min_investment_size_m: false,
                    dataroom_access: false,
                    financial_info: false,
                    share_class: false,
                    fee_structure: false,
                    timeline: false,
                    company_presentation: false,
                    management_call: false,
                    others: null,
                    price_chart: false,
                    // company_info: null,
                    // gp_info: null,
                    offer: {
                        id: 0,
                        req_size_m: false,
                        req_implied_val_m: false,
                        req_price: false,
                        prelim_size_m: null,
                        prelim_price_m: null
                    },
                    demand: {
                        id: 0,
                        req_size_m: false,
                        req_implied_val_m: false,
                        req_price: false,
                        prelim_size_m: null,
                        prelim_price_m: null
                    }
                }
            }

            if( order_detail.buy_type == "buy" ){
                request_info_payload['comment']['demand'] = null;
                request_info_payload['comment']['offer']['id'] = order_detail.id;
                request_info_payload['comment']['share_class'] = true;
                request_info_payload['comment']['offer']['req_size_m'] = true;
                if( !order_detail.bid_price ){
                    request_info_payload['comment']['offer']['req_price'] = true;
                }

            } else {
                request_info_payload['comment']['offer'] = null;
                request_info_payload['comment']['demand']['id'] = order_detail.id;
                request_info_payload['comment']['demand']['req_size_m'] = true;

                if( !order_detail.bid_price ){
                    request_info_payload['comment']['demand']['req_price'] = true;
                }
            }

            // request_information['prefill_key'].forEach( pk => {
            //     if( ['size', 'price', 'implied_valuation'].indexOf(pk) > -1  ){

            //     } else {

            //     }
            // });

            if( order_detail.is_available_financial_info ){
                request_info_payload['comment']['dataroom_access'] = true;
            }

            if( order_detail.is_spv ){
                request_info_payload['comment']['fee_structure'] = true;
            }

            if( order_detail.is_primary ){
                request_info_payload['comment']['timeline'] = true;
            }

            


            const target_order = {
                id: order_detail.id,
                type: order_detail.buy_type
            }

            return { target_order, request_info_payload };
        } else if (
            !request_info_response.loading && 
            request_info_response.message.trim() &&
            request_info_response.message != prevState.snackBar.message
        ) {
            const new_state = {
                snackBar: {
                    open: true,
                    message: request_info_response.message,
                    error: request_info_response.error
                }
            };

            return new_state;
        } else {
            return null;
        }

    }
    
    handleInputChange = (key, evt, newValue) => {
        // console.log( key );
        // console.log( evt );
        // console.log( newValue );
        const value_to_apply = newValue ? newValue : evt.target && evt.target.value ? evt.target.value : '';
        const { request_info_payload } = this.state;

        if(key == 'has_others'){
            this.setState({ has_others: value_to_apply });
        } else if (
            ['req_size_m', 'req_implied_val_m', 'req_price', 'prelim_size_m', 'prelim_price_m'].indexOf(key) > -1
        ){
            const { target_order } = this.state;
            if( target_order.type == 'buy' ){
                request_info_payload['comment']['offer'][key] = parseFloat(value_to_apply);
            } else {
                request_info_payload['comment']['demand'][key] = parseFloat(value_to_apply);
            }

            this.setState({ request_info_payload: request_info_payload });
        } else {
            request_info_payload['comment'][key] = value_to_apply;
            this.setState({ request_info_payload: request_info_payload });
        }
    }

    handleConfirmRequest = (evt) => {
        const { request_info_payload } = this.state;

        let error_message = '';

        const comment_keys = Object.keys(request_info_payload.comment);
        let has_value = false;
        comment_keys.forEach(com_key => {
            if( request_info_payload.comment[com_key] ){
                has_value=true;
            }
        });

        if( !has_value ){
            error_message += 'Please select at least one item. <br />';
        }

        if( !error_message.trim() ){
            const { postRequestInfo } = this.props;
            postRequestInfo(request_info_payload);
        } else { 
            this.setState({
                snackBar: {
                    open: true,
                    error: true,
                    message: error_message,
                }
            });
        }
    }

    handleSnackbarClose = (evt, reason) => {
        if ( reason === "clickaway") {
            return;
        }

        const { clearRequestInfoMsg } = this.props;
        clearRequestInfoMsg();
        this.setState({
            snackBar: {
                error: false,
                open: false,
                message: ''
            }
        });
    }

    render(){
        const {
            classes,
            request_information: { order_detail },
        } = this.props;


        const { 
            request_info_payload,
            has_others, 
            snackBar,
        } = this.state;

        // console.log( request_info_payload );

        const { comment } = request_info_payload;
        
        return (
            <section className={classes.Box}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Box>
                            <Typography variant="h6">Information Request</Typography>
                        </Box>
                    </Grid>

                    <Grid item xs={12}>
                        <Box>
                            <p style={{fontSize: 12}}>I would like to have receive the following information to help my { order_detail && order_detail.buy_type == 'buy' ? 'investment' : 'liquidity'} decision:</p>
                        </Box>
                    </Grid>

                    {
                        order_detail && !order_detail.bid_price ?
                            <Grid item xs={12}>
                                <FormControl required component="fieldset">
                                    <FormGroup>
                                        <FormControlLabel
                                            className={classes.FormControlLabel}
                                            control={<Checkbox 
                                                        name="req_price" 
                                                        onChange={this.handleInputChange.bind(this, "req_price")}
                                                        checked={comment.offer ? comment.offer.req_price : comment.demand ? comment.demand.req_price : false}
                                                        />}
                                            label={<Typography className={classes.FormControlLabel}>{'Price per share'}</Typography>}
                                            />
                                    </FormGroup>
                                </FormControl>
                            </Grid> : ''
                    }

                    <Grid item xs={12}>
                        <FormControl required component="fieldset">
                            <FormGroup>
                                <FormControlLabel
                                    className={classes.FormControlLabel}
                                    control={<Checkbox 
                                                name="req_size_m" 
                                                onChange={this.handleInputChange.bind(this, "req_size_m")}
                                                checked={comment.offer ? comment.offer.req_size_m : comment.demand ? comment.demand.req_size_m : false}
                                                />}
                                    label={<Typography className={classes.FormControlLabel}>{order_detail && order_detail.buy_type=='buy'?'Offer notional':'Size of the bid'}</Typography>}
                                    />
                            </FormGroup>
                        </FormControl>
                    </Grid> 

                    {
                        order_detail && order_detail.buy_type=='buy' ?
                            <Grid item xs={12}>
                                <FormControl required component="fieldset">
                                    <FormGroup>
                                        <FormControlLabel
                                            className={classes.FormControlLabel}
                                            control={<Checkbox 
                                                        name="share_class" 
                                                        onChange={this.handleInputChange.bind(this, "share_class")}
                                                        checked={comment.share_class}
                                                        />}
                                            label={<Typography className={classes.FormControlLabel}>{'Class of shares'}</Typography>}
                                            />
                                    </FormGroup>
                                </FormControl>
                            </Grid> : ''
                    }
                    
                    {
                        order_detail && order_detail.is_available_financial_info ?
                            <Grid item xs={12}>
                                <FormControl required component="fieldset">
                                    <FormGroup>
                                        <FormControlLabel
                                            className={classes.FormControlLabel}
                                            control={<Checkbox 
                                                        name="dataroom_access" 
                                                        onChange={this.handleInputChange.bind(this, "dataroom_access")}
                                                        checked={comment.dataroom_access}
                                                        />}
                                            label={<Typography className={classes.FormControlLabel}>{'Dataroom / Financial Info'}</Typography>}
                                            />
                                    </FormGroup>
                                </FormControl>
                            </Grid> : ''
                    }

                    
                    
                    {
                        order_detail && order_detail.is_spv ? 
                            <Grid item xs={12}>
                                <FormControl required component="fieldset">
                                    <FormGroup>
                                        <FormControlLabel
                                            className={classes.FormControlLabel}
                                            control={<Checkbox 
                                                        name="fee_structure" 
                                                        onChange={this.handleInputChange.bind(this, "fee_structure")}
                                                        checked={comment.fee_structure}
                                                        />}
                                            label={<Typography className={classes.FormControlLabel}>{'Fee Structure'}</Typography>}
                                            />
                                    </FormGroup>
                                </FormControl>
                            </Grid> : ''
                    }
                    
                    {
                        order_detail && order_detail.is_primary ?
                            <Grid item xs={12}>
                                <FormControl required component="fieldset">
                                    <FormGroup>
                                        <FormControlLabel
                                            className={classes.FormControlLabel}
                                            control={<Checkbox 
                                                        name="timeline" 
                                                        onChange={this.handleInputChange.bind(this, "timeline")}
                                                        checked={comment.timeline}
                                                        />}
                                            label={<Typography className={classes.FormControlLabel}>{'Timeline to close'}</Typography>}
                                            />
                                    </FormGroup>
                                </FormControl>
                            </Grid> : ''
                    }
                    

                    <Grid item xs={12}>
                        <Grid item xs={12}>
                            <FormControl required component="fieldset">
                                <FormGroup>
                                    <FormControlLabel
                                        className={classes.FormControlLabel}
                                        control={<Checkbox 
                                                    name="has_others" 
                                                    onChange={this.handleInputChange.bind(this, "has_others")}
                                                    checked={comment.has_others}
                                                    />}
                                        label={<Typography className={classes.FormControlLabel}>{'Others'}</Typography>}
                                        />
                                </FormGroup>
                            </FormControl>
                        </Grid>
                        {
                            has_others ?
                                <Grid item xs={12}>
                                    <TextField
                                        id="others"
                                        name="others"
                                        size="small"
                                        placeholder="Others"
                                        variant="outlined"
                                        className={classes.TextField}
                                        onChange={this.handleInputChange.bind(this, 'others')}
                                        value={comment.others ? comment.others : ''}
                                        />
                                </Grid> : ''
                        }
                        
                    </Grid>

                    <Grid item xs={12}>
                        <Box>
                            <p style={{fontSize: 12}}>I am currently considering a potential 
                                {
                                    
                                    order_detail && 
                                    order_detail.buy_type == 'buy' ? ' investment' : ' liquidity'
                                } of <TextField
                                    id="prelim_size_m"
                                    name="prelim_size_m"
                                    className={`request-info-textfield ${classes.TextField}`}
                                    placeholder="notional (US$M)"
                                    onChange={this.handleInputChange.bind(this, 'prelim_size_m')}
                                    style={{ display: 'inline-block', width: 'auto', maxWidth: 100 }}
                                    /> 
                                    <span 
                                        style={{
                                            display: 'inline-block', 
                                            marginTop: 15,
                                            marginLeft: 5, 
                                            marginRight: 5 }}>at</span>
                                <TextField
                                    id="prelim_price_m"
                                    name="prelim_price_m"
                                    className={`request-info-textfield ${classes.TextField}`}
                                    placeholder="target price"
                                    onChange={this.handleInputChange.bind(this, 'prelim_price_m')}
                                    style={{ display: 'inline-block', width: 'auto', maxWidth: 100 }}
                                    /> <span> per share.</span> 
                            </p>
                        </Box>
                    </Grid>

                    <Grid item xs={12}>
                        <Button
                            className="btn-submit blue-btn"
                            onClick={(evt) => this.handleConfirmRequest(evt)}
                            >Confirm</Button>
                    </Grid>
                </Grid>

                <Snackbar
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center'
                    }}
                    openStyle={snackBar.open}
                    handleSnackbarClose={this.handleSnackbarClose}
                    type={snackBar.error ? "error" : "success"}
                    message={snackBar.message}
                    upTime={10000}
                    />
            </section>
        )
    }
}

export default withStyles(FormStyle)(RequestInformation);