import React, { Component, Fragment } from 'react';
import { Link } from "react-router-dom";
import deal from "../../../assets/images/deal.jpg";
import * as funcs from '../../common/Funcs';
import { isMobile } from 'react-device-detect';
import { Button } from "@material-ui/core";

class PipelineItem extends Component{
	state = {
		offer_scale: [],
		bid_scale: [],
		scale: []
	}

	constructor(props){
		super(props);
	}

	componentDidMount(){
		const { offer_scale, bid_scale } = this.props;
		const new_offer_scale = offer_scale.map(item => false);
		const new_bid_scale = bid_scale.map(item => false);
		this.setState({ offer_scale: new_offer_scale, bid_scale: new_bid_scale });

	}

	static getDerivedStateFromProps(nextProps, prevState){
		const { offer_scale, bid_scale } = nextProps;

		if (offer_scale.length !== prevState.offer_scale.length){
			const new_offer_scale=[];
			for(var ii=0;ii< offer_scale.length; ii++){
				new_offer_scale.push(false);
			}
			return { offer_scale: new_offer_scale }
		} else if (bid_scale.length !== prevState.bid_scale.length){
			const new_bid_scale=[];
			for(var ii=0; ii< bid_scale.length; ii++){
				new_bid_scale.push(false);
			}
			return { bid_scale: new_bid_scale }
		} else {
			return null;
		}
	}

	handleClickScaleItem(state_key, index, evt){

		const { selected_price, selected_post_money_valuation } = this.props;
		const scales = this.props[state_key];

		if( scales[index] ){
			const scale = scales[index];

			const meta_data = funcs.GeneratePipelineItemMetaInfo(scale, selected_post_money_valuation, selected_price);
			this.props.handleChangeMeta(meta_data);
		}
	
	}

	expressInterest = (value)=>{
		this.props.expressInterest({ company: value, interest_type: 'INVEST' });
	}

	render(){
		const { props } = this;
		const { offer_scale, bid_scale } = this.state;

		const overall_has_live_offers = props.offer_scale ? props.offer_scale.filter(item => item.has_live_offer) : [];
		const overall_has_live_bids = props.bid_scale ? props.bid_scale.filter(item => item.has_live_bid) : [];

		const disc_prem_to_use = props['deal_disc_prem'] ? props['deal_disc_prem'] : props['disc_prem'];
		const vertical_name = props['vertical_name'] ? ', ' + props['vertical_name'] : '';

		return (
			<div className="col-12 mt-3">
				<div className="box">
					<div className="row pl-4 pr-4">
						<div className="col-12 col-lg-12 col-xl-6">
							<div className="row company-detail-container">
								<div className="image-design no-pad">
									<div className="logo-hoder-2">
										<img
											className="w-100"
											src={props.logo ? props.logo : deal}
											alt={props.name}
											/>
									</div>
								</div>
								<div className="lfr-container">

									<div className="company-meta border-bottom">
										<span className="samllText">
											{
												props['status'].indexOf('LIVE') > -1 ?
													<span className="dot-color-blue"></span>
												:
													<span className="dot-color-grey"></span>
											}

											<span>
												&ensp;{ props['status'].indexOf('LIVE') > -1 ? "Live" : "Watchlist" }
											</span>
										</span>

										<div className="margin-1">
											<h2 className="heading">
												{props['name']}
											</h2>
											<span className="samllText">{props['primary_industry_code'] + vertical_name}</span>
										</div>
									</div>
									<div >
										<h6 className="subheading-color mt-3 mb-2 ">Latest Funding Round</h6>
										<div className="row ">
											<div className="col-4 col-lg-4 col-xl-4 col-xs-5 padding-remove">
												<div><span className="samllText">Post Money Valuation</span></div>
											</div>
											<div className="col-3 col-lg-3 col-xl-3 no-pad col-xs-3">
												<div><span className="samllText">Share Price</span></div>
											</div>
											<div className="col-5 col-lg-5 col-xl-5 no-pad col-xs-4">
												<div><span className="samllText">Deal Date</span></div>
											</div>
										</div>
										<div className="row ">
											<div className="col-4 col-lg-4 col-xl-4 col-xs-5 padding-remove">
												{props['selected_post_money_valuation'] ? (
														<Fragment>
															{
																props['selected_post_money_valuation'] > 1000 ?
																	(
																		<div>
																			<span className="bigText">
																				${(props['selected_post_money_valuation'] / 1000).toFixed(2)}B
																				{props['selected_price_disclaimer'] == "manually selected" && props['selected_external_post_money_valuation_external_note'] ? <sup>1</sup> : ""}
																				{props['selected_price_disclaimer'] == "other price" && props['selected_other_post_money_valuation_external_note'] ? <sup>2</sup> : ""}
																				{props['selected_price_disclaimer'] == "pitchbook latest price" && props['selected_pb_post_money_valuation_external_note'] ? <sup>3</sup> : ""}
																				{props['selected_price_disclaimer'] == "pitchbook with price" && props['selected_pb_wp_post_money_valuation_external_note'] ? <sup>4</sup> : ""}
																			</span>
																		</div>
																	)
																	:
																	(
																		<div>
																			<span className="bigText">
																				${props['selected_post_money_valuation'].toFixed(2)}M
																				{props['selected_post_money_valuation_external_note'] ? <sup>^</sup> : ""}
																			</span>
																		</div>
																	)
															}
														</Fragment>
													) : <span className="bigText">-</span>
												}
											</div>
											<div className="col-3 col-lg-3 col-xl-3 no-pad col-xs-3">
												{props['selected_price'] ? <div>
													<span className="bigText">${props['selected_price'].toFixed(2)}</span>
														{ props['selected_price_disclaimer'] == "pitchbook pre money valuation price" ? <sup>E</sup> : "" }
														{ props['selected_price_disclaimer'] == "pitchbook post money valuation price" ? <sup>E</sup> : "" }
														{ props['selected_price_disclaimer'] == "manually selected" && props['selected_external_price_estimated'] ? <sup>E</sup> : "" }
														{ props['selected_price_disclaimer'] == "other price" && props['selected_other_price_estimated'] ? <sup>E</sup> : "" }
														{ props['selected_price_disclaimer'] == "pitchbook latest price" && props['selected_pb_price_estimated'] ? <sup>E</sup> : "" }
														{ props['selected_price_disclaimer'] == "pitchbook with price" && props['selected_pb_wp_price_estimated'] ? <sup>E</sup> : "" }
													</div> : <span className="bigText">-</span>}
											</div>
											<div className="col-5 col-lg-5 col-xl-5 no-pad col-xs-4">
												{props.lfr_date ? <div><span className="bigText">{props.lfr_date.getDate() + '-' + funcs.months[props.lfr_date.getMonth()] + '-' + props.lfr_date.getFullYear()}</span></div> : <span className="bigText">-</span>}
											</div>
										</div>
										<div className="row">
											<div className="col col-12">
												{props['selected_price_disclaimer'] == "manually selected" && props['selected_external_post_money_valuation_external_note'] ? <p><sup>1</sup>{props['selected_external_post_money_valuation_external_note']}</p> : ""}
												{props['selected_price_disclaimer'] == "other price" && props['selected_other_post_money_valuation_external_note'] ? <p><sup>2</sup>{props['selected_other_post_money_valuation_external_note']}</p> : ""}
												{props['selected_price_disclaimer'] == "pitchbook latest price" && props['selected_pb_post_money_valuation_external_note'] ? <p><sup>3</sup>{props['selected_pb_post_money_valuation_external_note']}</p> : ""}
												{props['selected_price_disclaimer'] == "pitchbook with price" && props['selected_pb_wp_post_money_valuation_external_note'] ? <p><sup>4</sup>{props['selected_exteselected_pb_wp_post_money_valuation_external_noternal_post_money_valuation_external_note']}</p> : ""}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="col-12 col-lg-12 col-xl-6 pipeline-deal-detail">

							<div className="row first-row">
								<div className="col-12">
									<div>
										<h6 className="subheading-color padding-420">Offer Range <span>(as seen in the last 90 days)</span></h6>
										{disc_prem_to_use?<span className="flex-design samllText">{disc_prem_to_use>0?<font className="green-tag">Premium</font>:<font className="red-tag">Discount</font>}</span>:''}
										<div className="row space-evenly offer-details">
											<div className="col">

												<div className="meta">
													<div className="lower-end">
														{props['historical_lowest_offer'] ? <span className="bigText">${props['historical_lowest_offer'].toFixed(2)}</span> : ''}
													</div>

													<div className="higher-end">
														{props['historical_highest_offer'] ? <span className="bigText">${props['historical_highest_offer'].toFixed(2)}</span> : ''}
													</div>

													<div className="clear"></div>
												</div>

												<div className="track">
													{
														props['offer_scale'].map((scale, ii) => {

															let track_item_class = scale.has_live_offer ? "track-item highlighted" : "track-item";
															if( isMobile ) track_item_class += " mobile";

															if( offer_scale[ii] ) track_item_class += " active";

															// const price_range = "$" + scale.scale_start.toFixed(2) + ' - $' + scale.scale_end.toFixed(2);
															// const medium_price_point = ( scale.scale_end + scale.scale_start ) / 2;
															// const disc_prem = medium_price_point / props['selected_price'] - 1;
															// const disc_prem_range = props['selected_price'] ? funcs.ConvertDiscPrem(disc_prem) : "-";
															//
															// let implied_valuation_display = " - ";
															// if( props['selected_price'] && props['selected_post_money_valuation'] ){
															// 	let implied_valuation = ( disc_prem + 1 ) * props['selected_post_money_valuation'];
															//
															// 	if( implied_valuation > 1000 ){
															// 		implied_valuation_display = "$" + ( implied_valuation / 1000 ).toFixed(2) + "B^";
															// 	} else {
															// 		implied_valuation_display = "$" + implied_valuation.toFixed(0) + "M^";
															// 	}
															// }

															const meta_data = funcs.GeneratePipelineItemMetaInfo(scale, props['selected_post_money_valuation'], props['selected_price']);

															return (
																<div className={track_item_class} key={ii} onClick={this.handleClickScaleItem.bind(this, 'offer_scale', ii)}>
																	{ !isMobile ? 
																		<div className="track-meta">
																			<div className="track-meta-container">
																				<div className="meta-item">
																					<span className="label">Price Range</span>
																					<span>{meta_data.price_range}</span>
																				</div>

																				<div className="meta-item no-border">
																					<span className="label">Median</span>
																					<span>{meta_data.disc_prem_range}</span>
																				</div>

																				<div className="meta-item no-border">
																					<span className="label">Implied Valuation : {meta_data.implied_valuation_display}</span>
																				</div>

																				<div className="triangle"></div>
																			</div>
																		</div>
																	: '' }
																</div>
															)

														})
													}
												</div>

											</div>
										</div>
										{props['bid_scale'].length ? <h6 className="subheading-color">Bid Range <span>(as seen in the last 90 days)</span></h6> : ''}
										<div className="row space-evenly bid-details">
											<div className="col">

												<div className="meta">
													<div className="lower-end">
														{props['historical_lowest_bid'] ? <span className="bigText">${props['historical_lowest_bid'].toFixed(2)}</span> : ''}
													</div>

													<div className="higher-end">
														{props['historical_highest_bid'] ? <span className="bigText">${props['historical_highest_bid'].toFixed(2)}</span> : ''}
													</div>

													<div className="clear"></div>
												</div>

												<div className="track">
													{
														props['bid_scale'].map((scale, ii) => {

															let track_item_class = scale.has_live_bid ? "track-item highlighted" : "track-item";
															if( isMobile ) track_item_class += " mobile";

															if( bid_scale[ii] ) track_item_class += " active";

															// const price_range = "$" + scale.scale_start.toFixed(2) + ' - $' + scale.scale_end.toFixed(2);
															// const medium_price_point = ( scale.scale_end + scale.scale_start ) / 2;
															// const disc_prem = medium_price_point / props['selected_price'] - 1;
															// const disc_prem_range = props['selected_price'] ? funcs.ConvertDiscPrem(disc_prem) : "-";
															//
															// let implied_valuation_display = " - ";
															// if( props['selected_price'] && props['selected_post_money_valuation'] ){
															// 	let implied_valuation = ( disc_prem + 1 ) * props['selected_post_money_valuation'];
															//
															// 	if( implied_valuation > 1000 ){
															// 		implied_valuation_display = "$" + ( implied_valuation / 1000 ).toFixed(2) + "B^";
															// 	} else {
															// 		implied_valuation_display = "$" + implied_valuation.toFixed(0) + "M^";
															// 	}
															// }

															const meta_data = funcs.GeneratePipelineItemMetaInfo(scale, props['selected_post_money_valuation'], props['selected_price']);

															return (
																<div className={track_item_class} key={ii} onClick={this.handleClickScaleItem.bind(this, 'bid_scale', ii)}>
																	{ !isMobile ? 
																		<div className="track-meta">
																			<div className="track-meta-container">
																				<div className="meta-item">
																					<span className="label">Price Range</span>
																					<span>{meta_data.price_range}</span>
																				</div>

																				<div className="meta-item no-border">
																					<span className="label">Median</span>
																					<span>{meta_data.disc_prem_range}</span>
																				</div>

																				<div className="meta-item no-border">
																					<span className="label">Implied Valuation:{meta_data.implied_valuation_display}</span>
																				</div>

																				<div className="triangle"></div>
																			</div>
																		</div>
																	: '' }
																	
																</div>
															)

														})
													}
												</div>

											</div>
										</div>

									</div>
								</div>
							</div>
							<div className="row second-row mt-3">
								<div className="col-12 col-md-8">
									{
										overall_has_live_offers.length ? <div className="disclaimer-item offer">
											<div className="label"></div>
											<p>Live Offers</p>
											<div className="clear"></div>
										</div> : ''
									}

									{
										( props['bid_scale'].length && overall_has_live_offers.length ) || overall_has_live_bids.length ? <div className="disclaimer-item bid">
											<div className="label"></div>
											<p>Live Bids</p>
											<div className="clear"></div>
										</div> : ''
									}
								</div>
								<div className="col-12 col-md-4 align-self">
									{/* <a className="btn-seller">Sell { props.name }</a> */}
									<a className="express-interest position-button btn-mid" onClick={()=>{this.expressInterest(props['company_id'])}}>Express Interest &nbsp;&nbsp;&gt;</a>
									{/*   */}
									<Link to={`/app/CompanyDetail/${props['company_id']}`} target="_blank" className="btn-mid btn-mid-position">Know More &nbsp;&nbsp;&gt;</Link>
								</div>
							</div>

						</div>

					</div>
					<div className="row px-4 detail"></div>
				</div>
			</div>
		)
	}
}

export default PipelineItem;
