// import * as React from 'react';
import { connect } from 'react-redux';

import {
    getAllCompanies,
    getFundingRound,
    getLatestFundingRound,
    // getVerticals,

    getDealLinks,
    getDealTimeline,
    getHistoricalPrice,
    getLiveOffers,
    getLiveDemand,
    getLiveOffersWatchlist,
    getLiveDemandWatchlist,
    toggleWatchList,
    // getDealOfferDetail,
    requestInformation,
    getDealHighlight,
    getDealType,
    getGPDetail,
    shareDeal,
    getRiskDisclosure,
    confirmRiskDisclosure,
} from './Action';

import {
    // getPipeline,
    expressInterest,
} from '../../pipeline/Action';

import {
    resetSnackBar
} from '../../account/Action';

import {
    createUserMessage
} from '../../common/header/Action';
import {
    ValidateUserInBackground
} from '../../login/Action';
import { default as CompanyDetail } from './CompanyDetail';
export default connect(
    state => ({
        
        AllCompaniesResponse: state.wealthCompany.reducer.AllCompaniesResponse,
        expressInterestError: state.pipeline.reducer.expressInterestError,
        expressInterestResponseMessage: state.pipeline.reducer.expressInterestResponseMessage,

        verticals: state.wealthCompany.reducer.verticals,
        fundingRounds: state.wealthCompany.reducer.fundingRounds,
        finishedCollectFundingRounds: state.wealthCompany.reducer.finishedCollectFundingRounds,
        latestFundingRound: state.wealthCompany.reducer.latestFundingRound,
        investors: state.wealthCompany.reducer.investors,
        clubDetail: state.wealthCompany.reducer.clubDetail,
        clubDealLinks: state.wealthCompany.reducer.clubDealLinks,
        dealTimelines: state.wealthCompany.reducer.dealTimelines,
        historicalPrice: state.wealthCompany.reducer.historicalPrice,
        live_offers: state.wealthCompany.reducer.live_offers,
        live_demand: state.wealthCompany.reducer.live_demand,
        live_offers_watchlist: state.wealthCompany.reducer.live_offers_watchlist,
        live_demand_watchlist: state.wealthCompany.reducer.live_demand_watchlist,
        loaderState: state.root.reducer.loaderState,
        toggleWatchError: state.wealthCompany.reducer.toggleWatchError,
        toggleWatchListMessage: state.wealthCompany.reducer.toggleWatchListMessage,
        toggle_watchlist: state.wealthCompany.reducer.toggle_watchlist,
        showSnakbar: state.account.reducer.showSnakbar,
        loginUserData: state.login.reducer.loginUserData,
        // dealOffer: state.wealthCompany.reducer.dealOffer,
        appConfig: state.root.reducer.appConfig,
        requestInformationtError: state.wealthCompany.reducer.requestInformationtError,
        requestInformationtResponseMessage: state.wealthCompany.reducer.requestInformationtResponseMessage,
        dealHighlight: state.wealthCompany.reducer.dealHighlight,
        dealType: state.wealthCompany.reducer.dealType,
        gp_detail: state.wealthCompany.reducer.gp_detail,
        shareDealResponse: state.wealthCompany.reducer.shareDealResponse,
        riskDisclosureConfirmed: state.wealthCompany.reducer.riskDisclosureConfirmed,
        riskDisclosureResponse: state.wealthCompany.reducer.riskDisclosureResponse,
    }),
    dispatch => ({
        getAllCompanies: payload => dispatch(getAllCompanies.request(payload)),
        getFundingRound: payload => dispatch(getFundingRound.request(payload)),
        getLatestFundingRound: payload => dispatch(getLatestFundingRound.request(payload)),
        // getVerticals: payload => dispatch(getVerticals.request(payload)),
        // getPipeline: payload => dispatch(getPipeline.request(payload)),
        expressInterest: payload => dispatch(expressInterest.request(payload)),
        getDealLinks: payload => dispatch(getDealLinks.request(payload)),
        getDealTimeline: payload => dispatch(getDealTimeline.request(payload)),
        resetSnackBar: () => dispatch(resetSnackBar()),
        getHistoricalPrice: payload => dispatch(getHistoricalPrice.request(payload)),
        getLiveOffers: payload => dispatch(getLiveOffers.request(payload)),
        getLiveDemand: payload => dispatch(getLiveDemand.request(payload)),
        getLiveOffersWatchlist: payload => dispatch(getLiveOffersWatchlist.request(payload)),
        getLiveDemandWatchlist: payload => dispatch(getLiveDemandWatchlist.request(payload)),
        toggleWatchList: (payload) => dispatch(toggleWatchList.request(payload)),
        createUserMessage: (payload) => dispatch(createUserMessage.request(payload)),
        // getDealOfferDetail: payload => dispatch(getDealOfferDetail.request(payload)),
        requestInformation: payload => dispatch(requestInformation.request(payload)),
        getDealHighlight: payload => dispatch(getDealHighlight.request(payload)),
        getDealType: payload => dispatch(getDealType.request(payload)),
        getGPDetail: (payload) => dispatch(getGPDetail.request(payload)),
        shareDeal: (payload) => dispatch(shareDeal.request(payload)),
        getRiskDisclosure: (payload) => dispatch(getRiskDisclosure.request(payload)),
        confirmRiskDisclosure: () => dispatch(confirmRiskDisclosure()),
        ValidateUserInBackground: (payload) => dispatch(ValidateUserInBackground.request(payload)),
    })
)(CompanyDetail);