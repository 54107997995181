import {
    CreateApiAction as _createApiAction,
    CreateKeyAction as _createKeyAction,
} from '../../utils';

const key = "DASHBOARD";

const CreateApiAction = _createApiAction(key);

const UpdateMyAccess = CreateApiAction('UPDATE_MY_ACCESS');

const CreateKeyAction = _createKeyAction(key);

const clearAccessResponsePayload = CreateKeyAction('CLEAR_ACCESS_RESPONSE_PAYLOAD');

export {
    UpdateMyAccess,
    clearAccessResponsePayload,
}