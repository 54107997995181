import { put, takeLatest, all, call, delay } from "redux-saga/effects";

import {
  getDeal,
  getMoreDeal,
  getRegionList,
  getVerticalsList,
  getViewSummary,
  getDealTimeline,
  getGpCompany,
  getPermissionedDeal,
} from "./Action";

import { Unauthorized } from "../../login/Action";

import { ShowLoader, HideLoader } from "../../root/Action";

import { logs, wealth_pipeline } from "../../../api";

import {
  NewRelicError
} from '../../../utils';

import {
  logEventInternallyAndNewRelic
} from '../../../utils/ga';

import {
  handleCatchSagaError,
} from '../../../utils/util';

function* getDealResponder({ payload }) {
  yield put(ShowLoader(true));
  try {
    let query_string = "?";
        let counter = 0;
        if( payload ){
            for( var key in payload ){
                if( counter ) query_string += "&";
                query_string += key+"="+payload[key];
                counter++;
            }
        }
    const { data } = yield call(
      wealth_pipeline.getDeal,
      query_string,
      payload
    );
    yield put(getDeal.success(data));
  } catch (e) {
    yield handleCatchSagaError(e, 'wealthgetDealResponder');
    yield put(getDeal.failure(e));
  } finally {
    yield put(HideLoader(true));
  }
}

function* getMoreDealResponder({payload}){
  yield put(ShowLoader(true));
  try {
    let query_string = "?";
        let counter = 0;
        if( payload ){
            for( var key in payload ){
                if( counter ) query_string += "&";
                query_string += key+"="+payload[key];
                counter++;
            }
        }
    const { data } = yield call(
      wealth_pipeline.getDeal,
      query_string,
      payload
    );
    yield put(getMoreDeal.success(data));
  }catch (e) {
    if( e.message && e.response ){
        const err_payload = {
            type: 'api_failure_getMoreDealResponder',
            data: {
                config: e.response.config,
                data: e.response.data,
                status: e.response.status
            },
            err: new Error("API Failure getMoreDealResponder: " + e.message)
        }
        logEventInternallyAndNewRelic(err_payload);
    } else if (e.message) {
        NewRelicError( new Error("API Failure getMoreDealResponder: " + e.message) );
    } else {
        NewRelicError( new Error("API Failure getMoreDealResponder with no message") );
    }

    if (e.response && e.response.status == 401) {
      yield put(Unauthorized(e));
    }

    yield put(getMoreDeal.failure(e));
  } finally {
    yield put(HideLoader(true));
  }
}

function* getVerticalsListResponder({ payload }) {
  try {
    yield delay(3000);
    const { data } = yield call(wealth_pipeline.getVerticalsList, payload);
    yield put(getVerticalsList.success(data));
  } catch (e) {
    if( e.message && e.response ){
        const err_payload = {
            type: 'api_failure_getVerticalsListResponder',
            data: {
                config: e.response.config,
                data: e.response.data,
                status: e.response.status
            },
            err: new Error("API Failure getVerticalsListResponder: " + e.message)
        }
        logEventInternallyAndNewRelic(err_payload);
    } else if (e.message) {
        NewRelicError( new Error("API Failure getVerticalsListResponder: " + e.message) );
    } else {
        NewRelicError( new Error("API Failure getVerticalsListResponder with no message") );
    }
    if (e.response.status == 401) {
      yield put(Unauthorized(e));
    }
    yield put(getVerticalsList.failure(e));
  } finally {
  }
}

function* getRegionListResponder({ payload }) {
  try {
    yield delay(2000);
    const { data } = yield call(
      wealth_pipeline.getRegion,
      payload
    );
    yield put(getRegionList.success(data));
  } catch (e) {
    if( e.message && e.response ){
        const err_payload = {
            type: 'api_failure_getRegionListResponder',
            data: {
                config: e.response.config,
                data: e.response.data,
                status: e.response.status
            },
            err: new Error("API Failure getRegionListResponder: " + e.message)
        }
        logEventInternallyAndNewRelic(err_payload);
    } else if (e.message) {
        NewRelicError( new Error("API Failure getRegionListResponder: " + e.message) );
    } else {
        NewRelicError( new Error("API Failure getRegionListResponder with no message") );
    }

    if (e.response.status == 401) {
      yield put(Unauthorized(e));
    }
    yield put(getRegionList.failure(e));
  } finally {
  }
}

function* getViewSummaryResponder({ }){
  try{
    const { data } = yield call(logs.getViewSummary);
    yield put(getViewSummary.success(data));
  } catch (e){
    if( e.message && e.response ){
        const err_payload = {
            type: 'api_failure_getViewSummaryResponder',
            data: {
                config: e.response.config,
                data: e.response.data,
                status: e.response.status
            },
            err: new Error("API Failure getViewSummaryResponder: " + e.message)
        }
        logEventInternallyAndNewRelic(err_payload);
    } else if (e.message) {
        NewRelicError( new Error("API Failure getViewSummaryResponder: " + e.message) );
    } else {
        NewRelicError( new Error("API Failure getViewSummaryResponder with no message") );
    }

    if (e.response.status == 401) {
      yield put(Unauthorized(e));
    }
    yield put(getViewSummary.failure(e));
  } finally {

  }
}

function* getDealTimelineResponder({ payload }){
  try{
    const { data } = yield call(wealth_pipeline.getDealTimeline, payload);
    yield put( getDealTimeline.success(data) );
  } catch(e){
    yield handleCatchSagaError(e, 'wealthPortalgetDealTimelineResponder');
    yield put( getDealTimeline.failure(e) );
  } finally{
  }
}

function* getGpCompanyResponder({ payload }) {
  try {
    yield delay(2000);
    const { data } = yield call(wealth_pipeline.getGpCompany, payload);
    yield put(getGpCompany.success(data));
  } catch (e) {
    yield handleCatchSagaError(e, 'getGpCompanyResponder');
    yield put(getGpCompany.failure(e));
  } finally {
  }
}

function* getPermissionedDealResponder({ payload }){
  try{
    const { data } = yield call(wealth_pipeline.getPermissionedDeal, payload);
    yield put(getPermissionedDeal.success(data));
  } catch(e){
    yield handleCatchSagaError(e, 'getPermissionedDealResponder');
    yield put(getPermissionedDeal.failure(e))
  } finally{

  }
}

function* saga() {
  yield all([
    takeLatest(getDeal.request, getDealResponder),
    takeLatest(getMoreDeal.request, getMoreDealResponder),
    takeLatest(getVerticalsList.request, getVerticalsListResponder),
    takeLatest(
      getRegionList.request,
      getRegionListResponder
    ),
    takeLatest(getViewSummary.request, getViewSummaryResponder),
    takeLatest(getDealTimeline.request, getDealTimelineResponder),
    takeLatest(getGpCompany.request, getGpCompanyResponder),
    takeLatest(getPermissionedDeal.request, getPermissionedDealResponder),
  ]);
}

export default saga;
