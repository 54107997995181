import React, { useState, useEffect, Fragment } from 'react';
import { default as SubmitPortfolio } from './ConnectedSubmitPortfolio';

import {
    Drawer,
    Paper,
    Button,
    makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    DrawerPaper: {
        maxWidth: 350,
        padding: 16,
        minHeight: '100%',
        height: 'auto',
        boxShadow: 'none',
    },
    Drawer: {
        maxWidth: 350
    },
    Button: {
        position: 'fixed',
        bottom: '20%',
        marginBottom: 160,
        right: -70,
        zIndex: 1000,
        background: 'rgba(231,230,230,1)',
        color: theme.palette.common.black,
        transition: 'all .5s',
        transform: 'rotate(-90deg)',
        paddingBottom: 20,
        // '&:hover': {
        //     color: theme.palette.common.black,
        //     backgroundColor: 'rgba(213,226,253,1)',
        //     right: -50,
        // },
        '&.hover': {
            color: theme.palette.common.black,
            backgroundColor: 'rgba(213,226,253,1)',
            right: -50,
        }
    }
}));

export default (props) => {
    const classes = useStyles();
    const [open, setOpen] = useState(props.open ? props.open : false);
    const [popOut, setPopOut] = useState(props.popOut ? props.popOut : false);

    const handleOpen = (evt, should_open) => {
        setOpen(should_open);
    }

    const handlePopOut = (evt, pop) => {
        setPopOut(pop);
    }

    useEffect(() => {
        if(props.open != open) setOpen(props.open);
        if(props.popOut != popOut) setPopOut(props.popOut);
    }, [props.open, props.popOut]);

    return (
        <Fragment>
            <Button
                className={popOut ? `${classes.Button} hover` : classes.Button}
                onClick={(evt) => handleOpen(evt, true)}
                onMouseEnter={(evt) => handlePopOut(evt, true)}
                onMouseLeave={(evt) => handlePopOut(evt, false)}
                >
                Submit Portfolio
            </Button>
            <Drawer
                anchor="right"
                className={classes.Drawer}
                open={open}
                onClose={(evt) => handleOpen(evt, false)}
                >
                    <Paper
                        className={classes.DrawerPaper}
                        >
                            <SubmitPortfolio
                                lock_company={props.lock_company}
                                handleAlertNeeded={props.handleAlertNeeded}
                                {...props}
                                />
                    </Paper>
            </Drawer>
        </Fragment>
    )
}