import { combineReducers } from "redux";
import {
    createWatchlistGroup,
    createWatchlist,
    getWatchlistGroup,
    getWatchlistByCompanyId,
    getWatchlistGroupItem,
    deleteWatchlistGroupItem,
    updateWatchlistGroupItem,
    createWatchlistV2,
    resetCompanyWatchlistData,
    getWatchlistCompanies,
    getPriceIndex,
    getFundingRound,
    getVerticals,
    getWatchlistPipeline,
    getWatchlistHistoricalPrices,
} from './Action';
import { default as InitialState } from './InitialState';
import {
    rearrange_price_index,
} from '../../../utils/PriceIndex';

function rearrange_verticals(verticals){
    const vertical_result = {};
    const vertical_keys = Object.keys(verticals);

    vertical_keys.forEach(key => {

        const company_vertical = verticals[key];

        company_vertical.forEach(vertical_item => {
            const cat = vertical_item['category'];
            if( !vertical_result[cat] ){
                vertical_result[cat] = [];
            }

            vertical_result[cat].push( vertical_item['company_id'] )
        });

    });

    const result_key = Object.keys(vertical_result);
    result_key.forEach(key => {
        vertical_result[key] = vertical_result[key].filter((val, index, self) => self.indexOf(val) === index)
    });

    return vertical_result;
}

export default combineReducers({
    reducer(state = InitialState, action){
        switch( action.type ){
            case createWatchlistGroup.request.toString():
                return Object.assign({}, state, {
                    createWatchListGroup: {},
                    createWatchListGroupError:false,
                    createWatchListGroupMessage:"",
                })
            case createWatchlistGroup.failure.toString():
                return Object.assign({}, state, {
                    createWatchListGroup: {},
                    createWatchListGroupError:true,
                    createWatchListGroupMessage: "There is an error, please refresh this page and try again.",
                })
            case createWatchlistGroup.success.toString():
                return Object.assign({}, state, {
                    createWatchListGroup: action.payload,
                    createWatchListGroupError:false,
                    createWatchListGroupMessage:"",
                })
            case createWatchlist.request.toString():
                return Object.assign({}, state, {
                    createWatchListResponse:{},
                    createWatchListError:false,
                    createWatchListMessage:"",
                })
            case createWatchlist.failure.toString():
                return Object.assign({}, state, {
                    createWatchListResponse:{},
                    createWatchListError:true,
                    createWatchListMessage:"There is an error, please refresh this page and try again.",
                })
            case createWatchlist.success.toString():
                let currentCompanyWatchList = state.companyWatchlist
                if(action.payload.active){
                    currentCompanyWatchList = currentCompanyWatchList.concat({
                        id:action.payload.watchlist_grp_id
                    })
                }else{
                    currentCompanyWatchList = currentCompanyWatchList.filter(item => item.id !== action.payload.watchlist_grp_id)
                }
                return Object.assign({}, state, {
                    createWatchListResponse:action.payload,
                    createWatchListError:false,
                    createWatchListMessage: action.payload.message,
                    companyWatchlist: currentCompanyWatchList,
                })
            case getWatchlistGroup.request.toString():
                return Object.assign({}, state, {
                    
                })
            case getWatchlistGroup.failure.toString():
                return Object.assign({}, state, {
                    watchlistGroupList:[]
                })
            case getWatchlistGroup.success.toString():
                return Object.assign({}, state, {
                    watchlistGroupList: action.payload,
                })
            case getWatchlistByCompanyId.request.toString():
                return Object.assign({}, state, {
                    companyWatchlist:[],

                    createWatchListGroup: {},
                    createWatchListGroupError:false,
                    createWatchListGroupMessage:"",

                    createWatchListResponse:{},
                    createWatchListError:false,
                    createWatchListMessage:"",
                })
            case getWatchlistByCompanyId.failure.toString():
                return Object.assign({}, state, {
                    companyWatchlist:[]
                })
            case getWatchlistByCompanyId.success.toString():
                return Object.assign({}, state, {
                    companyWatchlist:action.payload,
                })
            case getWatchlistGroupItem.request.toString():
                return Object.assign({}, state, {
                    //watchlistGroupItem:{},
                    watchlistGroupItem_loading: true,
                })
            case getWatchlistGroupItem.failure.toString():
                return Object.assign({}, state, {
                    watchlistGroupItem:{},
                    watchlistGroupItem_loading: false,
                })
            case getWatchlistGroupItem.success.toString():
                return Object.assign({}, state, {
                    watchlistGroupItem:action.payload,
                    watchlistGroupItem_loading: false,
                })
            case updateWatchlistGroupItem.success.toString():
                return Object.assign({}, state, {

                })
            case createWatchlistV2.request.toString():
                return Object.assign({}, state, {
                    createWatchListResponse:{},
                    createWatchListError:false,
                    createWatchListMessage:"",
                })
            case createWatchlistV2.failure.toString():
                return Object.assign({}, state, {
                    createWatchListResponse:{},
                    createWatchListError:true,
                    createWatchListMessage:"There is an error, please refresh this page and try again.",
                })
            case createWatchlistV2.success.toString():
                let currentCompanyWatchListV2 = state.companyWatchlist
                if(action.payload.active){
                    currentCompanyWatchListV2 = currentCompanyWatchListV2.concat({
                        id:action.payload.watchlist_grp_id
                    })
                }else{
                    currentCompanyWatchListV2 = currentCompanyWatchListV2.filter(item => item.id !== action.payload.watchlist_grp_id)
                }
                return Object.assign({}, state, {
                    createWatchListResponse: action.payload,
                    createWatchListError: false,
                    createWatchListMessage: action.payload.message,
                    companyWatchlist: currentCompanyWatchListV2,
                })
            case resetCompanyWatchlistData.toString():
                return Object.assign({}, state, {
                    companyWatchlist:[],

                    createWatchListGroup: {},
                    createWatchListGroupError:false,
                    createWatchListGroupMessage:"",

                    createWatchListResponse:{},
                    createWatchListError:false,
                    createWatchListMessage:"",
                  });
            case getPriceIndex.request.toString():
            case getPriceIndex.failure.toString():
                return Object.assign({}, state, {
                    price_index: {}
                });
            case getPriceIndex.success.toString():
                return Object.assign({}, state, {
                    price_index: rearrange_price_index(action.payload)
                });
            case getWatchlistCompanies.request.toString():
            case getWatchlistCompanies.failure.toString():
                return Object.assign({}, state, {
                    watchlist_companies: {
                        count: 0,
                        results: []
                    }
                });
            case getWatchlistCompanies.success.toString():
                return Object.assign({}, state, {
                    watchlist_companies: action.payload
                });
            case getFundingRound.request.toString():
            case getFundingRound.failure.toString():
                return Object.assign({}, state, {
                    funding_rounds: {
                        results: []
                    }
                });
            case getFundingRound.success.toString():
                return Object.assign({}, state, {
                    funding_rounds: action.payload
                });
            case getVerticals.request.toString():
            case getVerticals.failure.toString():
                return Object.assign({}, state, {
                    verticals: {}
                });
            case getVerticals.success.toString():
                return Object.assign({}, state, {
                    verticals: {
                        ...action.payload, 
                        ...{ by_cat: rearrange_verticals(action.payload) }
                    }
                });
            case getWatchlistPipeline.request.toString():
            case getWatchlistPipeline.failure.toString():
                return Object.assign({}, state, {
                    pipelines: {
                        resultCount: 0,
                        resultList: []
                    }
                });
            case getWatchlistPipeline.success.toString():
                return Object.assign({}, state, {
                    pipelines: action.payload
                });
            case getWatchlistHistoricalPrices.request.toString():
            case getWatchlistHistoricalPrices.failure.toString():
                return Object.assign({}, state, {
                    historical_pricing: { 
                        allow: false,
                        data: false,
                        historical_pricing: [] 
                    }
                });
            case getWatchlistHistoricalPrices.success.toString():
                return Object.assign({}, state, { historical_pricing: action.payload });
            default:
                return state;
        }
    }
})