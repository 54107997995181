const flags = {
    // Watchlist: {
    //     flag_name: 'Watchlist',
    //     attributes: {
    //         add_remove_watchlist: 'add_remove_watchlist',
    //         watchlist_filter: 'watchlist_filter',
    //         watchlist_view: 'watchlist_view',
    //         multiple_watchlist_group: 'multiple_watchlist_group',
    //         watchlist_graph: 'watchlist_graph'
    //     }
    // },
    PortfolioPage: {
        flag_name: 'PortfolioPage',
        attributes: {
            mtm_view: 'mtm_view',
            submission: 'submission'
        }
    },
    OrderList: {
        flag_name: 'OrderList',
        attributes: {
            transaction_detail: 'transaction_detail',
            new_orderbook_view: 'new_orderbook_view'
        }
    },
    LiveChat: {
        flag_name: 'LiveChat',
        attributes: {
            live_chat: 'live_chat'
        }
    },
    SectorTrend: {
        flag_name: 'SectorTrend',
        attributes: {
            external_doc: 'external_doc'
        }
    },
    SecondaryActivity: {
        flag_name: 'SecondaryActivity',
        attributes: {
            real_time_permission: 'real_time_permission',
            bitcoin_ref: 'bitcoin_ref',
            ether_ref: 'ether_ref',
            btc_eth_permission: 'btc_eth_permission',
            extended_price_chart: 'extended_price_chart'
        }
    },
    VciIntegration: {
        flag_name: 'VciIntegration',
        attributes: {
            vci_integration: 'vci_integration'
        }
    }
}

const wealth_flags = {
    SectorTrend: {
        flag_name: 'SectorTrend',
        attributes: {
            external_doc: 'external_doc'
        }
    },
    LiveChat: {
        flag_name: 'LiveChat',
        attributes: {
            live_chat: 'live_chat'
        }
    },
    DealShare: {
        flag_name: 'DealShare',
        attributes: {
            share_button: 'share_button'
        }
    },
    // Watchlist: {
    //     flag_name: 'Watchlist',
    //     attributes: {
    //         edit_watchlist: 'edit_watchlist',
    //         display_watchlist: 'display_watchlist',
    //         multiple_watchlist_group: 'multiple_watchlist_group',
    //         watchlist_graph: 'watchlist_graph'
    //     }
    // }
}

const watchlist_flags = {
    Watchlist: {
        flag_name: 'Watchlist',
        attributes: {
            add_remove_watchlist: 'add_remove_watchlist',
            watchlist_filter: 'watchlist_filter',
            watchlist_view: 'watchlist_view',
            multiple_watchlist_group: 'multiple_watchlist_group',
            watchlist_graph: 'watchlist_graph',
            watchlist_benchmark: 'watchlist_benchmark',
        }
    }
}

function extract_payload(appConfig, feature_flag, attribute, email){
    try{
        const matched_feature_flag = appConfig.filter(item => item.profile == feature_flag);
        
        if(
            matched_feature_flag.length &&
            matched_feature_flag[0].cached_config_data[attribute]['enabled'] 
        ){
            return matched_feature_flag[0]['cached_config_data'];
        } else {
            return {}
        }
        
    } catch(e){
        // console.log( e );
        return {};
    }
}

function feature_is_available(appConfig, feature_flag, attribute, email){
    try{
        const matched_feature_flag = appConfig.filter(item => item.profile == feature_flag);
        
        return matched_feature_flag.length &&
                matched_feature_flag[0].cached_config_data[attribute]['enabled'];
    } catch(e){
        // console.log( e );
        return false;
    }
}

module.exports = {
    flags,
    wealth_flags,
    watchlist_flags,
    feature_is_available,
    extract_payload,
}