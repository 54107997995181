import { connect } from 'react-redux';
import { default as WatchlistGraph } from './WatchlistGraph';

import {
    getWatchlistCompanies,
    getWatchlistPipeline,
} from '../Action';

export default connect(
    state => ({
        loginUserData: state.login.reducer.loginUserData,
        appConfig: state.root.reducer.appConfig,
        watchlist_companies: state.watchlist.reducer.watchlist_companies,
        price_index: state.watchlist.reducer.price_index,
        funding_rounds: state.watchlist.reducer.funding_rounds,
        verticals: state.watchlist.reducer.verticals,
        pipelines: state.watchlist.reducer.pipelines,
        watchlistGroupList: state.watchlist.reducer.watchlistGroupList,
		watchlistGroupItem: state.watchlist.reducer.watchlistGroupItem,
    }),
    dispatch => ({
        getWatchlistCompanies: (payload) => dispatch(getWatchlistCompanies.request(payload)),
        getWatchlistPipeline: (payload) => dispatch(getWatchlistPipeline.request(payload)),
    })
)(WatchlistGraph);