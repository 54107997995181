import React, { Fragment } from 'react';

import {
    Table,
    TableBody,
    TableContainer,
    TableHead,
    Paper,
    Grid,
    Box,
    Typography,
    Button,
    useMediaQuery,
} from '@material-ui/core';

import StarIcon from '@material-ui/icons/Star';
import StarBorderIcon from '@material-ui/icons/StarBorder';

import {
    AgeOfOrders
} from '../../common/place_order/components';

import {
    WatchlistTooptip,
    WatchlistButton,
} from '../../common/components';

import {
    HeaderTableCell,
    StyledTableRow,
    recentCellClasses,
    TableLabelStyle,
    ControlledFixedTable,
    TighterTableCell,
    StyledHoverTableRow,
    WhiteStyledHoverTableRow,
    TighterClickableTableCell,
    TighterHeaderTableCell,
    BidTighterHeaderTableCell,
    OfferTighterHeaderTableCell,
    BidTighterClickableTableCell,
    OfferTighterClickableTableCell,
    discPremClasses,
    EmptyTighterHeaderTableCell,
} from '../../common/TempTable';

import {
    numberWithCommas,
    convertSizeLabel,
    getDigitPadding,
    getImpliedVal,
    roundNumber,
    displayVal,
} from '../../../../utils/util';

import {
    content_placeholders,
    feature_flags,
} from '../../../../config';

import { default as OrderbookTooltip } from './OrderbookTooltip';

export default (props) => {
    const classes = TableLabelStyle();
    const rcc = recentCellClasses();
    const dpc = discPremClasses();
    const isNOTSmallScreen = useMediaQuery(`(min-width:576px)`);

    const header_class = props.fixHeader ? props.collapseSidebar ? 'header-fix narrow' : 'header-fix' : 'header-top-fix';

    const { appConfig, loginUserData: { email, activeAccessRight: { access_right: { permission } } } } = props;
    // const isTemp2 = window.location.search.includes('temp=2') // temporary
    const isTemp3 = false //window.location.search.includes('temp=3') // temporary
    const isTemp1 = window.location.search.includes('temp=1') // temporary
    const isTemp2 = false //window.location.search.includes('temp=2') // temporary

    return (
        <Fragment>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="body1" className={classes.FirmOrder}><span>Firm Order</span></Typography>
                    {/* <Typography variant="body1" className={classes.ThirtyDay}><span>Within 30 days</span></Typography>
                    <Typography variant="body1" className={classes.SixtyDay}><span>30 - 60 days</span></Typography> */}
                    <AgeOfOrders />
                </Grid>
            </Grid>
            <TableContainer component={Paper} className={classes.container} >
                <Table stickyHeader size="small" aria-label="simple table">
                    {
                        feature_flags.feature_is_available(
                            appConfig,
                            feature_flags.watchlist_flags.Watchlist.flag_name,
                            feature_flags.watchlist_flags.Watchlist.attributes.add_remove_watchlist,
                            email
                        ) ?
                        isNOTSmallScreen ?
                            <colgroup>
                                <col style={{ width: '25%' }} />
                                <col style={{ width: '12%' }} />
                                <col style={{ width: '12%' }} />
                                <col style={{ width: '11%' }} />
                                <col style={{ width: '12%' }} />
                                <col style={{ width: '12%' }} />
                                <col style={{ width: '11%' }} />
                                <col style={{ width: '5%' }} />
                            </colgroup> :
                            <colgroup>
                                <col style={{}} />
                                <col style={{}} />
                                <col style={{}} />
                                <col style={{}} />
                                <col style={{}} />
                                <col style={{}} />
                            </colgroup>
                        :
                        isNOTSmallScreen ?
                            <colgroup>
                                <col style={{ width: '26%' }} />
                                <col style={{ width: '13%' }} />
                                <col style={{ width: '12%' }} />
                                <col style={{ width: '12%' }} />
                                <col style={{ width: '13%' }} />
                                <col style={{ width: '12%' }} />
                                <col style={{ width: '12%' }} />
                            </colgroup> :
                            <colgroup>
                                <col style={{}} />
                                <col style={{}} />
                                <col style={{}} />
                                <col style={{}} />
                                <col style={{}} />
                            </colgroup>
                    }

                    <TableHead>
                        <StyledTableRow>
                            {
                                isTemp3? 
                                <EmptyTighterHeaderTableCell></EmptyTighterHeaderTableCell>
                                : 
                                <TighterHeaderTableCell></TighterHeaderTableCell>
                            }
                            {
                                isTemp3 ? 
                                <BidTighterHeaderTableCell colSpan={isNOTSmallScreen ? 3 : 2} style={{ textAlign: 'center' }}>Bid</BidTighterHeaderTableCell>
                                :
                                <TighterHeaderTableCell colSpan={isNOTSmallScreen ? 3 : 2} style={{ textAlign: 'center' }}>Bid</TighterHeaderTableCell>
                            }
                            {
                                isTemp3 ? 
                                <OfferTighterHeaderTableCell colSpan={isNOTSmallScreen ? 3 : 2} style={{ textAlign: 'center' }}>Offer</OfferTighterHeaderTableCell>
                                :
                                <TighterHeaderTableCell colSpan={isNOTSmallScreen ? 3 : 2} style={{ textAlign: 'center' }}>Offer</TighterHeaderTableCell>
                            }
                            {
                                feature_flags.feature_is_available(
                                    appConfig,
                                    feature_flags.watchlist_flags.Watchlist.flag_name,
                                    feature_flags.watchlist_flags.Watchlist.attributes.add_remove_watchlist,
                                    email
                                ) ? 
                                (isTemp3 ? <EmptyTighterHeaderTableCell></EmptyTighterHeaderTableCell>: <TighterHeaderTableCell></TighterHeaderTableCell>) 
                                : ''
                            }
                        </StyledTableRow>
                        <StyledTableRow>
                            {
                                isTemp3 ? 
                                <EmptyTighterHeaderTableCell className='company-name'>Name</EmptyTighterHeaderTableCell>
                                :
                                <TighterHeaderTableCell className='company-name'>Name</TighterHeaderTableCell> 
                            }
                            {
                                isNOTSmallScreen &&
                                (isTemp3 ? 
                                <BidTighterHeaderTableCell style={{ textAlign: 'center' }} className="size-column">Bid (PPS)</BidTighterHeaderTableCell>
                                : 
                                <TighterHeaderTableCell style={{ textAlign: 'center' }} className="size-column">Bid (PPS)</TighterHeaderTableCell>)
                            }
                            {
                                isTemp3 ? 
                                <BidTighterHeaderTableCell>Implied Val.</BidTighterHeaderTableCell>
                                :
                                <TighterHeaderTableCell style={{ textAlign: 'center' }}>Implied Val.</TighterHeaderTableCell>
                            }
                            {
                                isTemp3 ? 
                                <BidTighterHeaderTableCell style={{ textAlign: 'center' }}>Disc / Prem</BidTighterHeaderTableCell>
                                :
                                <TighterHeaderTableCell style={{ textAlign: 'center' }}>Disc / Prem</TighterHeaderTableCell>
                            }
                            {
                                isNOTSmallScreen &&
                                (isTemp3 ? 
                                <OfferTighterHeaderTableCell style={{ textAlign: 'center' }} className="size-column">Offer (PPS)</OfferTighterHeaderTableCell>
                                :
                                <TighterHeaderTableCell style={{ textAlign: 'center' }} className="size-column">Offer (PPS)</TighterHeaderTableCell>)
                            }
                            {
                                isTemp3 ? 
                                <OfferTighterHeaderTableCell style={{ textAlign: 'center' }} className='price-column'>Implied Val.</OfferTighterHeaderTableCell>
                                :
                                <TighterHeaderTableCell style={{ textAlign: 'center' }} className='price-column'>Implied Val.</TighterHeaderTableCell>
                            }
                            {
                                isTemp3 ? 
                                <OfferTighterHeaderTableCell style={{ textAlign: 'center' }}>Disc / Prem</OfferTighterHeaderTableCell>
                                :
                                <TighterHeaderTableCell style={{ textAlign: 'center' }}>Disc / Prem</TighterHeaderTableCell> 
                            }
                            {
                                feature_flags.feature_is_available(
                                    appConfig,
                                    feature_flags.watchlist_flags.Watchlist.flag_name,
                                    feature_flags.watchlist_flags.Watchlist.attributes.add_remove_watchlist,
                                    email
                                ) ? 
                                (isTemp3 ? <EmptyTighterHeaderTableCell></EmptyTighterHeaderTableCell>: <TighterHeaderTableCell></TighterHeaderTableCell>) 
                                : ''
                            }
                        </StyledTableRow>
                    </TableHead>
                    <TableBody>
                        {
                            props.orderbook.resultList.map((row, index) => {
                                // console.log( row );
                                const bid_padding = getDigitPadding(row.highest_bid, props.longest_digit);
                                const offer_padding = getDigitPadding(row.lowest_ask, props.longest_digit);
                                const bid_imp_padding = getDigitPadding(row.bid_implied_val, props.longest_implied_digit);
                                const offer_imp_padding = getDigitPadding(row.ask_implied_val, props.longest_implied_digit);

                                return (
                                    <React.Fragment key={index}>
                                        <OrderbookTooltip
                                            placement="right"
                                            data={row}
                                        >
                                            {
                                                permission == content_placeholders.user_type.orderbook_user ?
                                                    isTemp2 ?
                                                        <WhiteStyledHoverTableRow key={index}>
                                                            <TighterClickableTableCell style={{ wordBreak: isNOTSmallScreen ? 'normal' : 'break-word', minWidth: '90px', fontWeight: 'bold' }}
                                                                onClick={(evt) => props.handleGoToCompanyDetail(evt, row.company_id)}
                                                                className="company-name">{row.company_name}</TighterClickableTableCell>
                                                            {
                                                                isNOTSmallScreen &&
                                                                <TighterClickableTableCell
                                                                    style={{ textAlign: 'center' }}
                                                                    onClick={(evt) => props.handleGoToCompanyDetail(evt, row.company_id)}
                                                                    className={`price-column ${rcc.dateClassName(row.highest_bid_date, row.firm_bid)}`}>
                                                                    {bid_padding}
                                                                    {row.highest_bid ? '$' + numberWithCommas(row.highest_bid) : '-'}
                                                                </TighterClickableTableCell>
                                                            }
                                                            <TighterClickableTableCell
                                                                style={{ textAlign: 'center' }}
                                                                onClick={(evt) => props.handleGoToCompanyDetail(evt, row.company_id)}
                                                                className={`price-column ${rcc.dateClassName(row.highest_bid_date, row.firm_bid)}`}>
                                                                {isNOTSmallScreen && bid_imp_padding}
                                                                {
                                                                    row.highest_bid && row.selected_price && row.selected_post_money_valuation ?
                                                                        getImpliedVal(row.highest_bid, row.selected_price, row.selected_post_money_valuation, isNOTSmallScreen ? 2 : 0) :
                                                                        row.bid_valuation ?
                                                                            displayVal(row.bid_valuation) : '-'
                                                                }
                                                            </TighterClickableTableCell>
                                                            <TighterClickableTableCell style={{ textAlign: 'center' }}
                                                                onClick={(evt) => props.handleGoToCompanyDetail(evt, row.company_id)}
                                                                className={`${dpc.discPremClassName(row.bid_disc_prem)}`}>
                                                                {row.bid_disc_prem ? `${roundNumber(row.bid_disc_prem, isNOTSmallScreen ? 100 : 1)}%` : '-'}
                                                            </TighterClickableTableCell>
                                                            {
                                                                isNOTSmallScreen &&
                                                                <TighterClickableTableCell
                                                                    style={{ textAlign: 'center' }}
                                                                    onClick={(evt) => props.handleGoToCompanyDetail(evt, row.company_id)}
                                                                    className={`price-column ${rcc.dateClassName(row.lowest_ask_date, row.firm_ask)}`}>
                                                                    {offer_padding}
                                                                    {row.lowest_ask ? '$' + numberWithCommas(row.lowest_ask) : '-'}
                                                                </TighterClickableTableCell>
                                                            }
                                                            <TighterClickableTableCell
                                                                style={{ textAlign: 'center' }}
                                                                onClick={(evt) => props.handleGoToCompanyDetail(evt, row.company_id)}
                                                                className={`price-column ${rcc.dateClassName(row.lowest_ask_date, row.firm_ask)}`}>
                                                                {offer_imp_padding}
                                                                {
                                                                    row.lowest_ask && row.selected_price && row.selected_post_money_valuation ?
                                                                        getImpliedVal(row.lowest_ask, row.selected_price, row.selected_post_money_valuation, isNOTSmallScreen ? 2 : 0) :
                                                                        row.ask_valuation ?
                                                                            displayVal(row.ask_valuation) : '-'
                                                                }
                                                            </TighterClickableTableCell>
                                                            <TighterClickableTableCell style={{ textAlign: 'center' }}
                                                                onClick={(evt) => props.handleGoToCompanyDetail(evt, row.company_id)}
                                                                className={`${dpc.discPremClassName(row.ask_disc_prem)}`}>
                                                                {row.ask_disc_prem ? `${roundNumber(row.ask_disc_prem, isNOTSmallScreen ? 100 : 1)}%` : '-'}
                                                            </TighterClickableTableCell>
                                                            {
                                                                feature_flags.feature_is_available(
                                                                    appConfig,
                                                                    feature_flags.watchlist_flags.Watchlist.flag_name,
                                                                    feature_flags.watchlist_flags.Watchlist.attributes.add_remove_watchlist,
                                                                    email
                                                                ) ?
                                                                    <TighterClickableTableCell
                                                                        onClick={(evt) => props.handleToggleWatchList(evt, { company_id: row.company_id, watchlist: row.active_watchlist })}
                                                                        style={{ textAlign: 'center' }}
                                                                    >
                                                                        <WatchlistButton
                                                                            active_watchlist={row.active_watchlist}
                                                                        />
                                                                    </TighterClickableTableCell> : ''
                                                            }
                                                        </WhiteStyledHoverTableRow>
                                                        : isTemp3 ?
                                                        <StyledHoverTableRow key={index}>
                                                            <TighterClickableTableCell style={{ wordBreak: isNOTSmallScreen ? 'normal' : 'break-word', minWidth: '90px', fontWeight: 'bold' }}
                                                                onClick={(evt) => props.handleGoToCompanyDetail(evt, row.company_id)}
                                                                className="company-name">{row.company_name}</TighterClickableTableCell>
                                                            {
                                                                isNOTSmallScreen &&
                                                                <TighterClickableTableCell
                                                                    onClick={(evt) => props.handleGoToCompanyDetail(evt, row.company_id)}
                                                                    className={`price-column ${rcc.dateClassName(row.highest_bid_date, row.firm_bid)}`}>
                                                                    {bid_padding}
                                                                    {row.highest_bid ? '$' + numberWithCommas(row.highest_bid) : '-'}
                                                                </TighterClickableTableCell>
                                                            }
                                                            <TighterClickableTableCell
                                                                onClick={(evt) => props.handleGoToCompanyDetail(evt, row.company_id)}
                                                                className={`price-column ${rcc.dateClassName(row.highest_bid_date, row.firm_bid)}`}>
                                                                {isNOTSmallScreen && bid_imp_padding}
                                                                {
                                                                    row.highest_bid && row.selected_price && row.selected_post_money_valuation ?
                                                                        getImpliedVal(row.highest_bid, row.selected_price, row.selected_post_money_valuation, isNOTSmallScreen ? 2 : 0) :
                                                                        row.bid_valuation ?
                                                                            displayVal(row.bid_valuation) : '-'
                                                                }
                                                            </TighterClickableTableCell>
                                                            <TighterClickableTableCell style={{ textAlign: 'center' }}
                                                                onClick={(evt) => props.handleGoToCompanyDetail(evt, row.company_id)}
                                                                className={`price-column ${dpc.discPremClassName(row.bid_disc_prem)}`}>
                                                                {row.bid_disc_prem ? `${roundNumber(row.bid_disc_prem, isNOTSmallScreen ? 100 : 1)}%` : '-'}
                                                            </TighterClickableTableCell>
                                                            {
                                                                isNOTSmallScreen &&
                                                                <TighterClickableTableCell
                                                                    onClick={(evt) => props.handleGoToCompanyDetail(evt, row.company_id)}
                                                                    className={`price-column ${rcc.dateClassName(row.lowest_ask_date, row.firm_ask)}`}>
                                                                    {offer_padding}
                                                                    {row.lowest_ask ? '$' + numberWithCommas(row.lowest_ask) : '-'}
                                                                </TighterClickableTableCell>
                                                            }
                                                            <TighterClickableTableCell
                                                                onClick={(evt) => props.handleGoToCompanyDetail(evt, row.company_id)}
                                                                className={`price-column ${rcc.dateClassName(row.lowest_ask_date, row.firm_ask)}`}>
                                                                {offer_imp_padding}
                                                                {
                                                                    row.lowest_ask && row.selected_price && row.selected_post_money_valuation ?
                                                                        getImpliedVal(row.lowest_ask, row.selected_price, row.selected_post_money_valuation, isNOTSmallScreen ? 2 : 0) :
                                                                        row.ask_valuation ?
                                                                            displayVal(row.ask_valuation) : '-'
                                                                }
                                                            </TighterClickableTableCell>
                                                            <TighterClickableTableCell style={{ textAlign: 'center' }}
                                                                onClick={(evt) => props.handleGoToCompanyDetail(evt, row.company_id)}
                                                                className={`price-column ${dpc.discPremClassName(row.ask_disc_prem)}`}>
                                                                {row.ask_disc_prem ? `${roundNumber(row.ask_disc_prem, isNOTSmallScreen ? 100 : 1)}%` : '-'}
                                                            </TighterClickableTableCell>
                                                            {
                                                                feature_flags.feature_is_available(
                                                                    appConfig,
                                                                    feature_flags.watchlist_flags.Watchlist.flag_name,
                                                                    feature_flags.watchlist_flags.Watchlist.attributes.add_remove_watchlist,
                                                                    email
                                                                ) ?
                                                                    <TighterClickableTableCell
                                                                        onClick={(evt) => props.handleToggleWatchList(evt, { company_id: row.company_id, watchlist: row.active_watchlist })}
                                                                        style={{ textAlign: 'center' }}
                                                                    >
                                                                        <WatchlistButton
                                                                            active_watchlist={row.active_watchlist}
                                                                        />
                                                                    </TighterClickableTableCell> : ''
                                                            }
                                                        </StyledHoverTableRow>
                                                        :
                                                        <StyledHoverTableRow key={index}>
                                                            <TighterClickableTableCell style={{ wordBreak: isNOTSmallScreen ? 'normal' : 'break-word', minWidth: '90px', fontWeight: 'bold' }}
                                                                onClick={(evt) => props.handleGoToCompanyDetail(evt, row.company_id)}
                                                                className="company-name">{row.company_name}</TighterClickableTableCell>
                                                            {
                                                                isNOTSmallScreen &&
                                                                <BidTighterClickableTableCell
                                                                    style={{ textAlign: 'center' }}
                                                                    onClick={(evt) => props.handleGoToCompanyDetail(evt, row.company_id)}
                                                                    className={`price-column ${rcc.dateClassName(row.highest_bid_date, row.firm_bid)}`}>
                                                                    {bid_padding}
                                                                    {row.highest_bid ? '$' + numberWithCommas(row.highest_bid) : '-'}
                                                                </BidTighterClickableTableCell>
                                                            }
                                                            <BidTighterClickableTableCell
                                                                style={{ textAlign: 'center' }}
                                                                onClick={(evt) => props.handleGoToCompanyDetail(evt, row.company_id)}
                                                                className={`price-column ${rcc.dateClassName(row.highest_bid_date, row.firm_bid)}`}>
                                                                {isNOTSmallScreen && bid_imp_padding}
                                                                {
                                                                    row.highest_bid && row.selected_price && row.selected_post_money_valuation ?
                                                                        getImpliedVal(row.highest_bid, row.selected_price, row.selected_post_money_valuation, isNOTSmallScreen ? 1 : 0) :
                                                                        row.bid_valuation ?
                                                                            displayVal(row.bid_valuation,1,1) : '-'
                                                                }
                                                            </BidTighterClickableTableCell>
                                                            <BidTighterClickableTableCell style={{ textAlign: 'center' }}
                                                                onClick={(evt) => props.handleGoToCompanyDetail(evt, row.company_id)}
                                                                className={`price-column ${dpc.discPremClassName(row.bid_disc_prem)}`}>
                                                                {row.bid_disc_prem ? `${roundNumber(row.bid_disc_prem, isNOTSmallScreen ? 100 : 1)}%` : '-'}
                                                            </BidTighterClickableTableCell>
                                                            {
                                                                isNOTSmallScreen &&
                                                                <OfferTighterClickableTableCell
                                                                    style={{ textAlign: 'center' }}
                                                                    onClick={(evt) => props.handleGoToCompanyDetail(evt, row.company_id)}
                                                                    className={`price-column ${rcc.dateClassName(row.lowest_ask_date, row.firm_ask)}`}>
                                                                    {offer_padding}
                                                                    {row.lowest_ask ? '$' + numberWithCommas(row.lowest_ask) : '-'}
                                                                </OfferTighterClickableTableCell>
                                                            }
                                                            <OfferTighterClickableTableCell
                                                                style={{ textAlign: 'center' }}
                                                                onClick={(evt) => props.handleGoToCompanyDetail(evt, row.company_id)}
                                                                className={`price-column ${rcc.dateClassName(row.lowest_ask_date, row.firm_ask)}`}>
                                                                {offer_imp_padding}
                                                                {
                                                                    row.lowest_ask && row.selected_price && row.selected_post_money_valuation ?
                                                                        getImpliedVal(row.lowest_ask, row.selected_price, row.selected_post_money_valuation, isNOTSmallScreen ? 1 : 0) :
                                                                        row.ask_valuation ?
                                                                            displayVal(row.ask_valuation,1,1) : '-'
                                                                }
                                                            </OfferTighterClickableTableCell>
                                                            <OfferTighterClickableTableCell style={{ textAlign: 'center' }}
                                                                onClick={(evt) => props.handleGoToCompanyDetail(evt, row.company_id)}
                                                                className={`price-column ${dpc.discPremClassName(row.ask_disc_prem)}`}>
                                                                {row.ask_disc_prem ? `${roundNumber(row.ask_disc_prem, isNOTSmallScreen ? 100 : 1)}%` : '-'}
                                                            </OfferTighterClickableTableCell>
                                                            {
                                                                feature_flags.feature_is_available(
                                                                    appConfig,
                                                                    feature_flags.watchlist_flags.Watchlist.flag_name,
                                                                    feature_flags.watchlist_flags.Watchlist.attributes.add_remove_watchlist,
                                                                    email
                                                                ) ?
                                                                    <TighterClickableTableCell
                                                                        onClick={(evt) => props.handleToggleWatchList(evt, { company_id: row.company_id, watchlist: row.active_watchlist })}
                                                                        style={{ textAlign: 'center' }}
                                                                    >
                                                                        <WatchlistButton
                                                                            active_watchlist={row.active_watchlist}
                                                                        />
                                                                    </TighterClickableTableCell> : ''
                                                            }
                                                        </StyledHoverTableRow>
                                                    :
                                                    <StyledTableRow>
                                                        <TighterTableCell
                                                            className='size-column'>
                                                            {bid_padding}
                                                            {row.highest_bid ? numberWithCommas(row.highest_bid) : '-'}
                                                        </TighterTableCell>
                                                        <TighterTableCell
                                                            className={`price-column ${rcc.dateClassName(row.highest_bid_date, row.firm_bid)}`}>
                                                            {bid_padding}
                                                            {row.highest_bid ? numberWithCommas(row.highest_bid) : '-'}
                                                        </TighterTableCell>
                                                        <TighterTableCell
                                                            className="company-name">{row.company_name}
                                                        </TighterTableCell>
                                                        <TighterTableCell
                                                            className={`price-column ${rcc.dateClassName(row.lowest_ask_date, row.firm_ask)}`}>
                                                            {offer_padding}
                                                            {row.lowest_ask ? numberWithCommas(row.lowest_ask) : '-'}
                                                        </TighterTableCell>
                                                        <TighterTableCell
                                                            className='size-column'>
                                                            {offer_padding}
                                                            {row.lowest_ask ? numberWithCommas(row.lowest_ask) : '-'}
                                                        </TighterTableCell>
                                                        {
                                                            feature_flags.feature_is_available(
                                                                appConfig,
                                                                feature_flags.flags.Watchlist.flag_name,
                                                                feature_flags.flags.Watchlist.attributes.add_remove_watchlist,
                                                                email
                                                            ) ?
                                                                <TighterClickableTableCell
                                                                    onClick={(evt) => props.handleToggleWatchList(evt, { company_id: row.company_id, watchlist: row.active_watchlist })}
                                                                >
                                                                    <WatchlistButton
                                                                        active_watchlist={row.active_watchlist}
                                                                    />
                                                                </TighterClickableTableCell> : ''
                                                        }

                                                    </StyledTableRow>
                                            }
                                        </OrderbookTooltip>
                                    </React.Fragment>
                                )
                            })
                        }
                    </TableBody>
                </Table>
                <ControlledFixedTable
                    className={header_class}
                    stickyHeader
                    size="small"
                    aria-label="simple table">
                    {
                        feature_flags.feature_is_available(
                            appConfig,
                            feature_flags.watchlist_flags.Watchlist.flag_name,
                            feature_flags.watchlist_flags.Watchlist.attributes.add_remove_watchlist,
                            email
                        ) ?
                        isNOTSmallScreen ?
                            <colgroup>
                                <col style={{ width: '25%' }} />
                                <col style={{ width: '12%' }} />
                                <col style={{ width: '12%' }} />
                                <col style={{ width: '11%' }} />
                                <col style={{ width: '12%' }} />
                                <col style={{ width: '12%' }} />
                                <col style={{ width: '11%' }} />
                                <col style={{ width: '5%' }} />
                            </colgroup> :
                            <colgroup>
                                <col style={{}} />
                                <col style={{}} />
                                <col style={{}} />
                                <col style={{}} />
                                <col style={{}} />
                                <col style={{}} />
                            </colgroup>
                            :
                        isNOTSmallScreen ?
                            <colgroup>
                                <col style={{ width: '26%' }} />
                                <col style={{ width: '13%' }} />
                                <col style={{ width: '12%' }} />
                                <col style={{ width: '12%' }} />
                                <col style={{ width: '13%' }} />
                                <col style={{ width: '12%' }} />
                                <col style={{ width: '12%' }} />
                            </colgroup> :
                            <colgroup>
                                <col style={{}} />
                                <col style={{}} />
                                <col style={{}} />
                                <col style={{}} />
                                <col style={{}} />
                            </colgroup>
                    }
                    <TableHead>
                        <StyledTableRow>
                            {
                                isTemp3 ?
                                <EmptyTighterHeaderTableCell className='company-name'>Name</EmptyTighterHeaderTableCell>
                                :
                                <TighterHeaderTableCell className='company-name'>Name</TighterHeaderTableCell>
                            }
                            {
                                isNOTSmallScreen &&
                                (isTemp3 ? 
                                <BidTighterHeaderTableCell className="size-column">Bid (PPS)</BidTighterHeaderTableCell>
                                :
                                <TighterHeaderTableCell className="size-column" style={{ textAlign: 'center' }}>Bid (PPS)</TighterHeaderTableCell>)
                            }
                            {
                                isTemp3 ? 
                                <BidTighterHeaderTableCell>Implied Val.</BidTighterHeaderTableCell>
                                :
                                <TighterHeaderTableCell style={{ textAlign: 'center' }}>Implied Val.</TighterHeaderTableCell>
                            }
                            {
                                isTemp3 ?
                                <BidTighterHeaderTableCell style={{ textAlign: 'center' }}>Disc / Prem</BidTighterHeaderTableCell>
                                :
                                <TighterHeaderTableCell style={{ textAlign: 'center' }}>Disc / Prem</TighterHeaderTableCell>
                            }
                            {
                                isNOTSmallScreen &&
                                (isTemp3 ? 
                                <OfferTighterHeaderTableCell className="size-column">Offer (PPS)</OfferTighterHeaderTableCell>
                                :
                                <TighterHeaderTableCell className="size-column" style={{ textAlign: 'center' }}>Offer (PPS)</TighterHeaderTableCell>)
                            }
                            {
                                isTemp3 ? 
                                <OfferTighterHeaderTableCell className='price-column'>Implied Val.</OfferTighterHeaderTableCell>
                                :
                                <TighterHeaderTableCell className='price-column' style={{ textAlign: 'center' }}>Implied Val.</TighterHeaderTableCell>
                            }
                            {
                                isTemp3 ? 
                                <OfferTighterHeaderTableCell style={{ textAlign: 'center' }}>Disc / Prem</OfferTighterHeaderTableCell>
                                :
                                <TighterHeaderTableCell style={{ textAlign: 'center' }}>Disc / Prem</TighterHeaderTableCell>
                            }
                            {
                                feature_flags.feature_is_available(
                                    appConfig,
                                    feature_flags.watchlist_flags.Watchlist.flag_name,
                                    feature_flags.watchlist_flags.Watchlist.attributes.add_remove_watchlist,
                                    email
                                ) ? 
                                ( isTemp3 ? <EmptyTighterHeaderTableCell></EmptyTighterHeaderTableCell> : <TighterHeaderTableCell></TighterHeaderTableCell>) 
                                : ''
                            }
                        </StyledTableRow>
                    </TableHead>
                </ControlledFixedTable>

            </TableContainer>
        </Fragment>
    )
}