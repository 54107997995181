import { combineReducers } from "redux";

import {
    getVerticalsList,
    getGeographyList,
    getOrderbookView,
    getMoreOrderbook,
    getOpportunityHighlight,
    internalSort,
    getCompanyNameById,
    clearOrderbookCompany,
    getLiveOfferCount,
    getLiveBidCount,
    toggleWatchList,
    clearWatchlistMessage,
    getPermissionedDeals,
    updateUserType,
    showHideCampaignPopUp,
    getDealMetrics,
} from './Action';

import {
    createWatchlistV2,
} from '../../common/watchlist/Action';

import {
    getImpliedVal,
} from '../../../utils/util';

import { default as InitialState } from './InitialState';

function updateWatchListItem(orderbook_list, company_id){
    const new_orderbook_list = orderbook_list.map((item, index) => {
        item.active_watchlist = item.company_id == company_id ? !item.active_watchlist : item.active_watchlist;
        return item;
    });
    return new_orderbook_list;
}

export default combineReducers({
    reducer(state=InitialState, action){
        switch( action.type ){
            case getVerticalsList.request.toString():
            case getVerticalsList.failure.toString():
                return Object.assign({}, state, {
                    verticalsListResponse: []
                });
            case getVerticalsList.success.toString():
                return Object.assign({}, state, {
                    verticalsListResponse: action.payload
                });
            case getGeographyList.request.toString():
            case getGeographyList.failure.toString():
                    return Object.assign({}, state, {
                        geographyResponse: []
                    });
            case getGeographyList.success.toString():
                return Object.assign({}, state, {
                    geographyResponse: action.payload
                });
            case getOrderbookView.request.toString():
            case getOrderbookView.failure.toString():
                return Object.assign({}, state, {
                    orderbook: {
                        resultCount: 0,
                        resultList: []
                    },
                    orderbook_loading: true,
                });
            case getOrderbookView.success.toString():
                const orderbook_list = action.payload.resultList.map((item, index) => {
                    item.ask_implied_val = undefined;
                    item.bid_implied_val = undefined;
                    item.ask_disc_prem = undefined;
                    item.bid_disc_prem = undefined;

                    if( item.lowest_ask && item.selected_price && item.selected_post_money_valuation ) 
                        item.ask_implied_val = getImpliedVal(item.lowest_ask, item.selected_price, item.selected_post_money_valuation, 100, true);
                    else if ( item.ask_valuation )
                        item.ask_implied_val = item.ask_valuation

                    if( item.lowest_ask && item.selected_price )
                        item.ask_disc_prem = ( item.lowest_ask - item.selected_price ) / item.selected_price * 100;
                    else if ( item.ask_valuation && item.selected_post_money_valuation )
                        item.ask_disc_prem = ( item.ask_valuation - item.selected_post_money_valuation ) / item.selected_post_money_valuation * 100;
                        
                    if( item.highest_bid && item.selected_price && item.selected_post_money_valuation )
                        item.bid_implied_val = getImpliedVal(item.highest_bid, item.selected_price, item.selected_post_money_valuation, 100, true);
                    else if ( item.bid_valuation )
                        item.bid_implied_val = item.bid_valuation

                    if( item.highest_bid && item.selected_price )
                        item.bid_disc_prem = ( item.highest_bid - item.selected_price ) / item.selected_price * 100;
                    else if ( item.bid_valuation && item.selected_post_money_valuation )
                        item.bid_disc_prem = ( item.bid_valuation - item.selected_post_money_valuation ) / item.selected_post_money_valuation * 100;

                    return item;
                });

                return Object.assign({}, state, {
                    orderbook: {
                        resultCount: action.payload.resultCount,
                        resultList: orderbook_list,
                    },
                    orderbook_loading: false,
                });
            case getOpportunityHighlight.request.toString():
            case getOpportunityHighlight.failure.toString():
                return Object.assign({}, state, {
                    opportunity_highlight: {
                        resultCount: 0,
                        resultList: []
                    },
                });
            case getOpportunityHighlight.success.toString():
                return Object.assign({}, state, {
                    opportunity_highlight: action.payload,
                });
            case getMoreOrderbook.request.toString():
            case getMoreOrderbook.failure.toString():
                return Object.assign({}, state, {
                    orderbook_loading: true
                });
            case getMoreOrderbook.success.toString():

                const more_orderbook_list = action.payload.resultList.map((item, index) => {
                    item.ask_implied_val = undefined;
                    item.bid_implied_val = undefined;
                    item.ask_disc_prem = undefined;
                    item.bid_disc_prem = undefined;

                    if( item.lowest_ask && item.selected_price && item.selected_post_money_valuation ) 
                        item.ask_implied_val = getImpliedVal(item.lowest_ask, item.selected_price, item.selected_post_money_valuation, 100, true);
                    else if ( item.ask_valuation )
                        item.ask_implied_val = item.ask_valuation

                    if( item.lowest_ask && item.selected_price )
                        item.ask_disc_prem = ( item.lowest_ask - item.selected_price ) / item.selected_price * 100;
                    else if ( item.ask_valuation && item.selected_post_money_valuation )
                        item.ask_disc_prem = ( item.ask_valuation - item.selected_post_money_valuation ) / item.selected_post_money_valuation * 100;
                        
                    if( item.highest_bid && item.selected_price && item.selected_post_money_valuation )
                        item.bid_implied_val = getImpliedVal(item.highest_bid, item.selected_price, item.selected_post_money_valuation, 100, true);
                    else if ( item.bid_valuation )
                        item.bid_implied_val = item.bid_valuation

                    if( item.highest_bid && item.selected_price )
                        item.bid_disc_prem = ( item.highest_bid - item.selected_price ) / item.selected_price * 100;
                    else if ( item.bid_valuation && item.selected_post_money_valuation )
                        item.bid_disc_prem = ( item.bid_valuation - item.selected_post_money_valuation ) / item.selected_post_money_valuation * 100;

                    return item;
                });

                return Object.assign({}, state, {
                    orderbook: {
                        resultCount: state.orderbook.resultCount + action.payload.resultCount,
                        resultList: state.orderbook.resultList.concat(more_orderbook_list),
                    },
                    orderbook_loading: false,
                });
            case getCompanyNameById.request.toString():
            case getCompanyNameById.failure.toString():
            case clearOrderbookCompany.toString():
                return Object.assign({}, state, {
                    company: {}
                });
            case getCompanyNameById.success.toString():
                return Object.assign({}, state, {
                    company: action.payload
                });
            case internalSort.toString():
                return state;
            case getLiveOfferCount.request.toString():
            case getLiveOfferCount.failure.toString():
                return Object.assign({}, state, {
                    live_offer_count: {
                        cat_list: []
                    }
                });
            case getLiveOfferCount.success.toString():
                return Object.assign({}, state, {
                    live_offer_count: action.payload
                });
            case getLiveBidCount.request.toString():
            case getLiveBidCount.failure.toString():
                return Object.assign({}, state, {
                    live_bid_count: {
                        cat_list: []
                    }
                });
            case getLiveBidCount.success.toString():
                return Object.assign({}, state, {
                    live_bid_count: action.payload
                });
            case toggleWatchList.request.toString():
            case clearWatchlistMessage.toString():
                return Object.assign({}, state, {
                    toggle_watchlist: {
                        error: false,
                        message: '',
                        item_count: 0,
                    }
                });
            case toggleWatchList.failure.toString():
                return Object.assign({}, state, {
                    toggle_watchlist: {
                        error: true,
                        message: "There is an error, please try again later.",
                        item_count: 0,
                    }
                });
            case toggleWatchList.success.toString():
            case createWatchlistV2.success.toString():
                return Object.assign({}, state, {
                    toggle_watchlist: {
                        error: false,
                        message: action.payload.message,
                        item_count: action.payload.item_count,
                    },
                    orderbook: {
                        resultCount: state.orderbook.resultCount,
                        resultList: updateWatchListItem(state.orderbook.resultList, action.payload.company_id),
                    },
                });
            case updateUserType.success.toString():
                return Object.assign({}, state, {
                    toggle_watchlist: {
                        error: false,
                        message: "Thank you for confirming your interest in premium user subscription and added 3 companies to your watchlist. Final confirmation of your premium user subscription will be communicated via email.",
                        item_count: 0,
                        upTime: 8000,
                    }
                });
            case getPermissionedDeals.request.toString():
            case getPermissionedDeals.failure.toString():
                return Object.assign({}, state, {
                    permissioned_deals: []
                });
            case getPermissionedDeals.success.toString():
                return Object.assign({}, state, {
                    permissioned_deals: action.payload
                });
            case showHideCampaignPopUp.toString():
                const { show } = action.payload;
                return Object.assign({}, state, {
                    showCampaignDialog: show
                });
            case getDealMetrics.request.toString():
            case getDealMetrics.failure.toString():
                return Object.assign({}, state, {
                    dealMetricsResponse: {}
                });
            case getDealMetrics.success.toString():
                return Object.assign({}, state, {
                    dealMetricsResponse: action.payload
                });
            default:
                return state;
        }
    }
})