import React, { Fragment } from 'react';
import * as Yup from 'yup';
import { Formik, Field } from 'formik';

import { withStyles } from '@material-ui/core/styles';
// import { reduxForm } from 'redux-form/immutable';
import { 
	Button, 
	FormHelperText, 
	FormControl,
	Grid, 
} from '@material-ui/core';
import { CustomInput, Snackbar } from '../common';
import { Styles } from '../styles';
import { Link } from 'react-router-dom';
import { 
	RetrievePreservedUrl, 
	RemovePreservedUrl, 
	ReadQueryString, 
	GetToken,
	RemoveToken 
} from '../../utils';
import { sendLogEvent } from '../../utils/ga';

// validation functions
const validations = {

	email: Yup.string('app.error-invalid-string')
		.email('*Please enter a valid email')
		.required('*Email is required')
		.trim(),

	password: Yup.string('app.error-invalid-string')
		.min(3, 'app.error-min-character')
		.required('app.error-required')
		.trim(),

	// passcode: Yup.string('app.error-invalid-string')
	// 	.min(3, 'app.error-min-character')
	// 	.required('app.error-required')
	// 	.trim(),

}


class InvestorLogin extends React.Component {

	state = {
		showPassword: false,
		email: '',
		password: '',
		passcode: '',
		openStyle: false,
		loginResponsecustom: ''
	}

	constructor() {
		super();
		this.onKeyUp = this.onKeyUp.bind(this);
	}

	onKeyUp(event) {
		if (event.charCode === 13) {
			this.onSubmitLogin(event);
		}
	}

	handleTextChange = (e) => {
		const { name, value } = e.target;
		this.setState({ [name]: value });

		sendLogEvent(true, 'InvestorLogin_handleTextChange_'+name, { [name]: value }, this.props);
	}

	handleChangePasscode = (e) => {
		const { name, value } = e.target;
		this.setState({ [name]: value });
	}

	onSubmitLogin = (e) => {
		e.preventDefault();

		const accessToken = GetToken();
		if( accessToken && accessToken.token ){
			RemoveToken('authData');
		}

		const { email, password } = this.state;
		const { SignInWithEmailAndPassword } = this.props;
		const this_props= this.props;
		setTimeout( function(){
			SignInWithEmailAndPassword({ email, password });
			sendLogEvent(true, 'InvestorLogin_onSubmitLogin', { email: email, password: password }, this_props);
		}, 500);
	}

	handleSnackbarClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
		this.setState({ openStyle: false });
	};

	componentWillUpdate(prevProps) {
		const { showSnackbarInLogin } = this.props;
		if (showSnackbarInLogin !== prevProps.showSnackbarInLogin) {
			this.setState({ openStyle: true })
		}
	}

	// static getDerivedStateFromProps(nextProps, prevState){
	// 	const { showSnackbarInLogin } = nextProps;
	// 	const payload = {}

	// 	// console.log(showSnackbarInLogin);
	// 	// console.log(this.props);

	// 	return payload;
	// }

	shouldComponentUpdate(nextProps, nextState) {

		const { loginUserData } = nextProps;
		const { activeAccessRight: { access_right: { permission } } } = loginUserData;
		// console.log( loginUserData );
		if (loginUserData.email) {
			if (loginUserData.agreement_needed || loginUserData.update_password_needed) {
				this.props.history.push('/Disclaimer')
			} else if ( 
					permission == "CLUB_CLIENT" || 
					permission == "TRIAL_USER"
				) {
					const preservedPayload = RetrievePreservedUrl();
					if( preservedPayload.search ){
						const query_string = ReadQueryString(preservedPayload.search);
						if( query_string['preserve-url'] && 
							preservedPayload.pathname && 
							preservedPayload.set_time &&
							( Date.now() - preservedPayload.set_time < 900000 ) &&
							( 
								preservedPayload.pathname.indexOf('/club/pipline') > -1 || 
								preservedPayload.pathname.indexOf('/club/company-detail') > -1 
							) 
						){
							RemovePreservedUrl();
							window.location.href=preservedPayload.pathname+preservedPayload.search;
						} else {
							this.props.history.push('/club/pipeline');	
						}
					} else {
						this.props.history.push('/club/pipeline');
					}
			} else {
				this.props.history.push('/app/Pipeline');
			}
		}
		return true;

	}

	render() {
		const {
			classes,
			loginError,
			loginResponse
		} = this.props;
		const { email, password } = this.state;
		return (
			
				<Grid item xs={12} className="LoginCustomSection">
					<Formik
						initialValues={{
							email: '',
							password: ''
						}}
						validationSchema={Yup.object().shape(validations)}
					>
						{
							({ errors, touched, handleChange, handleBlur }) => 
								{
									// console.log( errors );
									// console.log( touched );
									return (
										<Grid container spacing={2}>
											<Grid item xs={12}>
												<FormControl size="small" margin="none">
													<CustomInput
														type="email"
														name="email"
														placeholder="Email"
														autoComplete="email"
														margin="none"
														value={email}
														onBlur={handleBlur}
														size="small"
														onChange={(e) => { this.handleTextChange(e); handleChange(e); handleBlur(e) }}
													/>
													{/* {
														errors.email && touched.email && <div className="LoginEmailRequired">
															{errors.email ?
																<FormHelperText id="email" className={classes.errorRedColor}>*Email is required</FormHelperText>
																: ''}
														</div>
													} */}
												</FormControl>
											</Grid>
											<Grid item xs={12}>
												<FormControl size="small" margin="none" onKeyPress={this.onKeyUp}>
													<CustomInput
														placeholder="Password"
														type="password"
														name="password"
														autoComplete="current-password"
														value={password}
														// onBlur={handleBlur}
														onChange={(e) => { this.handleTextChange(e); handleChange(e); handleBlur(e); }}
														margin="none"
														size="small"
														required
													/>
													{/* {
														errors.password && touched.password && <div className="loginPasswordRequired">
															{errors.password ?
																<FormHelperText id="password" className={classes.errorRedColor}>*Password is required</FormHelperText>
																: ''}
														</div>
													} */}
												</FormControl>
											</Grid>
											<Grid item xs={12}>
												<Button
													className="btn btn-1 code customStyle"
													onClick={(e) => { this.onSubmitLogin(e) }}
													type="submit">
													Login
												</Button>
											</Grid>
											<Grid item xs={12}>
												<Link size="small" className="ForgotPassword btn-main" to="/forgot-password" >
													Forgot Password?
												</Link>
												<a 
													href="https://gatewaypmx.formstack.com/forms/gateway_orderbook_request_form" 
													className="btn-main login-page-btns" 
													target="_blank">
													Sign Up for Access
												</a>
											</Grid>
										</Grid>
										
									)
								}
						}
					</Formik>
					<Snackbar
						openStyle={this.state.openStyle}
						handleSnackbarClose={this.handleSnackbarClose}
						type={loginError ? 'error' : 'success'}
						message={loginResponse}
					/>
				</Grid>

		);
	}

}


// const ReduxInvestorLoginForm = reduxForm({
// 	form: 'immutableExample',
// 	enableReinitialize: true,
// })(InvestorLogin);

// export default withStyles(Styles)(ReduxInvestorLoginForm);
export default withStyles(Styles)(InvestorLogin);
