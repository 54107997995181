export default {
    price_index: {},
    cost_base: {
        100: [
            {
                id: 1,
                price: 850,
                noOfShares: 100000,
                date: '2015-10-10',
                share_class: 'Preferred C'
            },
            {
                id: 2,
                price: 1350,
                noOfShares: 2500,
                date: '2019-08-10',
                share_class: 'Preferred D'
            }
        ],
        146: [
            {
                id: 3,
                price: 25,
                noOfShares: 850000,
                date: '2016-05-10'
            }
        ],
        718: [
            {
                id: 4,
                price: 2.3,
                noOfShares: 65000,
                date: '2012-10-11',
            }
        ],
        161: [
            {
                id: 5,
                price: 17.5,
                noOfShares: 95000,
                date: '2020-10-23'
            }
        ],
        828: [
            {
                id: 6,
                price: 76.05,
                noOfShares: 80000,
                date: '2021-07-20'
            }
        ]
    },
    company_details: {},
    funding_rounds: {}
}