import React, { Fragment } from 'react';
import TableChartIcon from '@material-ui/icons/TableChart';
import {
    Button,
    Tooltip,
} from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';

import { 
    GenerateGreeting, 
    GetToken 
} from '../../utils';

import { sendGAEvent } from '../../utils/ga';

import { 
    content_placeholders,
    feature_flags,
} from '../../config';

export class PipelineSidebar extends React.Component{
    state = {
        tabs: [
            // {
            //     name: "DEAL SHOWCASE",
            //     url: "/club/pipeline?tab=0",
            //     accessRight: 'new_version_user',
            // }, 
            {
                name: "MARKET INTELLIGENCE",
                url: "/club/pipeline?tab=1",
                accessRight: 'new_version_user',
            }, 
            // {
            //     name: "WATCHLIST",
            //     url: "/club/pipeline?tab=2",
            //     accessRight: 'new_version_user',
            // },
            // {
            //     name: "DEAL SHOWCASE",
            //     url: "/wealth/pipeline?tab=0",
            //     accessRight: 'wealth_user',
            // }, 
            {
                name: "DEALS",
                url: "/wealth/pipeline?tab=1",
                accessRight: 'wealth_user',
                icon: <TableChartIcon />,
            }, 
            // Temporary hide trends and watchlist from wealth portal
            // {
            //     name: "TRENDS",
            //     url: "/wealth/trends",
            //     accessRight: 'wealth_user',
            //     icon: <TrendingUpIcon />,
            // },
            {
                name: "WATCHLIST",
                url: "/wealth/pipeline?tab=2",
                accessRight: 'wealth_user',
                icon: <StarBorderIcon />,
                feature_flag: feature_flags.watchlist_flags.Watchlist.flag_name,
                attribute: feature_flags.watchlist_flags.Watchlist.attributes.watchlist_view,
            },
            {
                name: "ORDERBOOK",
                url: "/orderbook/pipeline",
                accessRight: 'orderbook_user',
                icon: <TableChartIcon />,
            },
            {
                name: "PORTFOLIO HOLDING",
                url: "/portfolio-holding",
                accessRight: 'orderbook_user',
                icon: <AccountBalanceIcon />,
                feature_flag: feature_flags.flags.PortfolioPage.flag_name,
                attribute: feature_flags.flags.PortfolioPage.attributes.mtm_view,
            },
            {
                name: "WATCHLIST",
                url: "/orderbook/watchlist",
                accessRight: 'orderbook_user',
                icon: <StarBorderIcon />,
                feature_flag: feature_flags.watchlist_flags.Watchlist.flag_name,
                attribute: feature_flags.watchlist_flags.Watchlist.attributes.watchlist_view,
            }
        ],
    }

    constructor(props){
        super(props);
    }

    componentDidMount(){
        const { location: { pathname }, CollapseSidebar, collapse } = this.props;
        if( collapse ){
            CollapseSidebar(true);
        }
    }

    handlePageChange = (url) => {
        const { history } = this.props;
        history.push(url);
    }

    handleSidebarCollapse = () => {
        const { CollapseSidebar, collapseSidebar } = this.props;
        CollapseSidebar(!collapseSidebar);
    }

    render(){
        const { 
            tabs,
        } = this.state;

        const { 
            appConfig,
            collapseSidebar, 
            tabFocusIndex, 
            loginUserData : { email, activeAccessRight } 
        } = this.props;
        const { access_right : { permission } } = activeAccessRight;
        
        const accessToken = GetToken();
        const greeting = GenerateGreeting();
        const sidebar_class = collapseSidebar ? "sidebar-container common narrow" : "sidebar-container common";
        const button_icon = collapseSidebar ? <ArrowForwardIosIcon /> : <ArrowBackIosIcon />;
        
        return (
            <div className={sidebar_class}>
                <div className='cover'>
                    <Button onClick={this.handleSidebarCollapse}>{button_icon}</Button>
                </div>

                <div className="welcome mb-4">
                    <div>{greeting},</div>
                    <div>{accessToken.firstName}</div>
                </div>

                {tabs.map((item, index) => {
                    return (
                        <Fragment key={index}>
                            {   
                                content_placeholders.user_type[item.accessRight] == permission  &&  
                                (
                                    (
                                        item.feature_flag &&
                                        feature_flags.feature_is_available(appConfig, item.feature_flag, item.attribute, email)
                                    ) || !item.feature_flag
                                ) ?
                                    <Tooltip
                                        title={item.name}>
                                        <div
                                            onClick={() => this.handlePageChange(item.url)}
                                            className={`tab-border ${
                                                index == tabFocusIndex ? "tab-focus" : ""
                                            }`}>
                                                
                                                {
                                                    item.icon !== undefined ? 
                                                        <span className='gpm-icon'>
                                                            { item.icon }
                                                        </span>
                                                        : ''
                                                }
                                                <span className='text'>{item.name}</span>
                                    
                                        </div>
                                    </Tooltip>
                                 : ''
                            }
                        </Fragment>
                    )
                })}
            </div>
        )
    }
}
