import { 
    CreateApiAction as _createApiAction, 
    CreateKeyAction as _createKeyAction 
} from '../../../utils';

const key = "CLUB_PIPELINE";

const CreateApiAction = _createApiAction(key);
const CreateKeyAction = _createKeyAction(key);

const getPipeline = CreateApiAction('GET_PIPELINE');
const getMarketIntelligence = CreateApiAction('GET_MARKET_INTELLIGENCE');
const clearPopUp = CreateKeyAction('CLEAR_POPUP');
const expressInterest = CreateApiAction('EXPRESS_INTEREST');
const resetExpressInterest = CreateKeyAction('RESET_EXPRESS_INTEREST');
const getHeadquaterCountryList = CreateApiAction('HEADQUATER_COUNTRY_LIST');
const getVerticalsList = CreateApiAction('VERTICALS_LIST');
const postReferral = CreateApiAction('POST_REFERRAL');
const getWatchList = CreateApiAction('GET_WATCH_LIST');
const toggleWatchList = CreateApiAction('TOGGLE_WATCH_LIST');
const getAdBanner1 = CreateApiAction('GET_AD_BANNER1');
const updateProps = CreateApiAction('UPDATE_PROPS');

const getViewSummary = CreateApiAction('GET_VIEW_SUMMARY');

export {
    getPipeline,
    getMarketIntelligence,
    clearPopUp,
    expressInterest,
    getHeadquaterCountryList,
    getVerticalsList,
    resetExpressInterest,
    getAdBanner1,
    postReferral,
    getWatchList,
    toggleWatchList,
    updateProps,

    getViewSummary,
};