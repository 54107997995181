import React from 'react';

import {
    makeStyles,
    Paper,
    Grid,
    Typography,
    Button,
} from '@material-ui/core';

import {
    globalFontFamily,
} from '../../../styles';

import {
    numberWithCommas,
    showBlurred,
    longBlurText,
} from '../../../../utils/util';
import { content_placeholders } from '../../../../config';

const useStyles = makeStyles((theme) => ({
    HeaderOne: {
        fontSize: '1rem',
        fontFamily: globalFontFamily,
        fontWeight: 'bold',
    },
    Blurb: {
        fontSize: 12
    },
    InvestmentFocus: {
        fontSize: 12
    },
    Img: {
        maxWidth: 150
    }
}));

export default (props) => {
    const { gp_detail, gpDetailIsBlurred, logo } = props;
    // console.log( gp_detail );
    const classes = useStyles();

    const blur_text = longBlurText();

    return (
        <Grid container>
            <Grid item xs={12}>
                <h4 className="subheading-color company-item-title" style={{display: 'inline-block'}}>
                    <span className="company-item-rectangle"></span>
                    GP Highlight
                </h4>
                { gpDetailIsBlurred && 
                    <Button style={{fontSize: '0.6rem', float: 'right'}}
                        onClick={(e) =>
                            props.handleRequestInformationDialogOpen(
                                {
                                    logo: logo,
                                    dialogTitle: content_placeholders.buttons.request_for_information,
                                    dialogTitle: 'Information Request',
                                },
                                e
                                )
                            }
                    >
                        { 'Request Info' }
                    </Button>
                }
            </Grid>
            {
                gp_detail.map((gpItem, gpIndex) => {
                    return <Grid container key={gpIndex} spacing={1} style={{marginTop: '5px'}}>
                        <Grid item xs={12}>
                            <Typography 
                                className={classes.HeaderOne}
                                variant="span">
                                    {showBlurred(gpItem.name) ? <span className="blurred">{gpItem.name}</span> : gpItem.name}
                            </Typography>
                            {
                                gpItem.logo && !showBlurred(gpItem.logo)
                                    ?
                                    <div style={{ float: 'right' }}>
                                        <img 
                                            style={{ width: 'auto', maxHeight: '1rem', maxWidth: 'none' }} 
                                            src={gpItem.logo} 
                                            className={classes.Img} />
                                    </div>
                                    :
                                    <div></div>
                            }
                        </Grid>
                        <Grid item xs={12}>
                            <p className={classes.Blurb}>
                                {
                                    showBlurred(gpItem.blurb) ? 
                                        <span className='blurred'>{blur_text}</span> : 
                                        gpItem.blurb
                                }
                            </p>
                        </Grid>
                        <Grid item xs={6} sm={2}>
                            <span className="company-info-label">Headquarter</span>
                            <span className="smallText orderbook">
                                {
                                    gpItem.headquarter_country ? 
                                        showBlurred(gpItem.headquarter_country) ?
                                            <span className="blurred">{gpItem.headquarter_country}</span> :
                                            gpItem.headquarter_country : 
                                        '-'
                                }
                            </span>
                        </Grid>
                        <Grid item xs={6} sm={2}>
                            <span className="company-info-label">Investment Focus</span>
                            <span className="smallText orderbook">
                                {
                                    gpItem.investment_focus ? 
                                        showBlurred(gpItem.investment_focus) ?
                                            <span className="blurred">{gpItem.investment_focus}</span> :
                                            gpItem.investment_focus : 
                                        '-'
                                }</span>
                        </Grid>
                        <Grid item xs={6} sm={2}>
                            <span className="company-info-label">Asset under management</span>
                            <span className="smallText orderbook">
                                {
                                    gpItem.aum ? 
                                        showBlurred(gpItem.aum) ?
                                            <span className="blurred">{`${numberWithCommas(gpItem.aum, 0)}M`}</span> :
                                        `${numberWithCommas(gpItem.aum, 0)}M` : 
                                    '-'
                                } 
                            </span>
                        </Grid>
                        <Grid item xs={6} sm={2}>
                            <span className="company-info-label">Website</span>
                            <span className="smallText orderbook">
                                {
                                    gpItem.website ? 
                                        showBlurred(gpItem.website) ?
                                            <span className='blurred'>{gpItem.website}</span> :
                                        <a href={gpItem.website.indexOf('http') == 0 ? gpItem.website : `http://${gpItem.website}`} target="_blank">{gpItem.website}</a> : 
                                    '-'
                                }
                            </span>
                        </Grid>
                    </Grid>
                })
            }
        </Grid>
    )
}