import React, { Fragment } from "react";
import {
	Dialog,
	DialogContent,
	Button,
	TextField,
} from "@material-ui/core";
// import { RangeDoubleSide } from "../../pipeline/components";
import { getTodayHistoricalPrice } from "../../../../utils/util";

import { InterestSlider } from "../../pipeline/components/Sliders"

export default (props) => {
	let todayHistoricalPrice = getTodayHistoricalPrice(props.historicalPrice);
	let { min, max } = todayHistoricalPrice;
	// let min_value = props.deal.interest_type == "SELL" ? min_buy : min_sell;
	let min_value = min;
	let max_value = max;
	max_value = max_value * 1.5;
	let step = (max_value - min_value) / 100;
	step = step.toFixed(2) * 1;
	return (
		<Dialog
			open={props.open}
			onClose={props.closePopup}
			fullWidth
			maxWidth="sm"
			className="club-express-interest-dialog"
		>
			<DialogContent className="buy-sell-dialog">
				<div className="title">{props.deal.dialogTitle}<img className="logo-img" src={props.logo_url} /></div>
				
				<div className="flex-row-between">
					<div>
						<div className="mt-2">
							<span className="buy-sell-label">Company Name:&nbsp;</span>
							<span className="buy-sell-value">{props.deal.company}</span>
						</div>
						<div className="mt-2">
							<span className="buy-sell-label">Target Notional US$: </span>
							<input
								id="notional"
								name="notional"
								type="number"
								value={props.target.notional}
								onChange={(e) => props.handleChange(e)}
								className="buy-sell-input"
							/>
							{/* <span className="buy-sell-value">shares</span> */}
						</div>
					</div>
					
				</div>
				<div className="flex-row mt-2">
					<span className="buy-sell-label">
						Target Price(US$ per share): <br />
						{max_value ? 
							<Button 
								className="btn-price-input-type"
								onClick={(e) => 
									props.handleChangePriceInputType(e)
									}
								>{props.price_slider ? "Input" : "Slider"}</Button>
						: ''}
						
					</span>
					<div className="price-right">
						
						{ max_value && props.price_slider ? 
							<Fragment>
								<div className="range_value">
									<span style={{ color: "#ECAA32" }}>US${props.target.price} per share</span>
								</div>
								<div className="range_wrap">
									
										<InterestSlider
											value={props.target.price}
											step={step}
											min={min_value}
											max={max_value}
											// onChangeCommitted={(event, newValue) => props.onValuationChange(event, newValue)}
											onChange={(event, newValue) => props.onValuationChange(event, newValue)}
										/>
								</div>
								{/* <input className="price-range" type="range"  name="" id="" /> */}
								<div className="flex-row-between price_value_wrap">
									<span className="price-value">US${ min_value.toFixed(2) }</span>
									<span className="price-value">US${ max_value.toFixed(2) }</span>
								</div>
								{
									props.target.implied_valuation ? <div className="flex-row-center">
										<div className="price-tip-bg">
											<span className="price-tip-label">Implied Valuation (estimated)</span>
											<span className="price-tip-bold">US${props.target.implied_valuation}</span>
										</div>
									</div> : ""
								}
								
							</Fragment>
						: 
							<TextField 
								margin="normal"
								id="price"
								name="price"
								value={props.target.price}
								onChange={e => props.onValuationChange(e, e.target.value)}
								/>
						}
					</div>
				</div>
				<div className="flex-row mt-4">
					<span className="buy-sell-label">Express Interest:</span>
					<select
						className="buy-sell-value buy-sell-select"
						id="firmInterest"
						name="firmInterest"
						onChange={(e) => props.handleChange(e)}
					>
						<option value="0">Interested, want to find out more</option>
						<option value="1">Yes, I am ready to sign binding mandate</option>
					</select>
				</div>
				<div className="mt-4">
					{
						props.target.firmInterest == 0 ? 
							<Fragment>
								<span className="buy-sell-label full">
									Are there any questions you need answers for to confirm your interest?</span>
								<TextField
									margin="normal"
									id="comment"
									name="comment"
									value={props.target.comment}
									fullWidth
									multiline={true}
									rows={4}
									rowsMax={4}
									variant="outlined"
									onChange={(e) => props.handleChange(e)}
									/>
							</Fragment>
						: ''
					}
				</div>
				<div className="mt-4">
					<Fragment>
						<span className="buy-sell-label full">
							Note to Gateway:
						</span>
						<TextField 
							margin="normal"
							id="other_comment"
							name="other_comment"
							placeholder="E.g. Open to SPV structure, Pref. only...etc"
							value={props.target.other_comment}
							fullWidth
							multiline={true}
							rows={4}
							rowsMax={4}
							variant="outlined"
							onChange={(e) => props.handleChange(e)}
							/>
					</Fragment>
				</div>
				<div className="flex-row-center mt-4 buy-sell-btns">
					<span onClick={props.handleCancelInterest} className="buy-sell-btn">
						Cancel
					</span>
					<span
							onClick={props.handleSubmitInterest}
							className="buy-sell-btn buy-sell-btn-focus"
						>
							Confirm
					</span> 
				</div>
			</DialogContent>
		</Dialog>
	);
};
