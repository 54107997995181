const error_message = {
    auth: {
        login: "Internal server error."
    },
    general: "There is an error processing your request. Please try again. If the problem persist, kindly contact us at info@gatewaypmx.com",
    blurred_text: "gateway blurry"
}

const user_type = {
    old_version_user: "INSTITUTIONAL_CLIENT",
    new_version_user: "CLUB_CLIENT",
    wealth_user: "WEALTH_CLIENT",
    trial_user: "TRIAL_USER",
    orderbook_user: 'ORDERBOOK_CLIENT',
    orderbook_p1_user: 'ORDERBOOK_MAIN_PAGE_CLIENT',
    single_company_user: 'SINGLE_COMPANY',
    boost_user: 'BOOST_CLIENT',
}

const user_candidate_type = {
    pro_user: "PRO_USER",
    lifetime_candidate: "PREMIUM_CANDIDATE",
    lifetime_user: "PREMIUM_USER",
}

const response_message = {
    onboarding: 'Thank you for your interest. The Gateway team will following up with you on your onboarding request.',
    general_interest: 'Thank you for expressing your interest. The Gateway team will reach out to discuss further.'
}

const buttons = {
    request_full_access: "Request Full Access",
    invest_now: "Invest Now",
    express_buy_interest: "Express Buy Interest",
    express_sell_interest: "Express Sell Interest",
    request_for_information: "Information Request",
    express_interest: "Express Interest",
}

const deal_class_str = {
    gpled: 'gpled',
    primary: 'primary',
    companyled: 'companyled'
}

const flag_to_check = 'Watchlist,PortfolioPage,OrderList';
const gateway_logo_url = "https://media.gatewayprivatemarkets.com/company-logos/full/GatewayLOGO.png";

const logos = {
    gateway: "https://media.gatewayprivatemarkets.com/company-logos/full/GatewayLOGO.png",
    wealth: "https://media.gatewayprivatemarkets.com/general-image/GatewayWealth.png?1",
    boost: "https://media.gatewayprivatemarkets.com/general-image/GatewayBoost.png?1",
}

module.exports = {
    title_prefix: "Gateway Private Markets Portal",
    error_message,
    user_type,
    user_candidate_type,
    response_message,
    buttons,
    flag_to_check,
    gateway_logo_url,
    deal_class_str,
    logos,
}
