import { combineReducers } from "redux";

import {
    requestUnlock,
    clearRequestUnlockMsg,
} from './Action';

import { default as InitialState } from './InitialState';

export default combineReducers({
    reducer( state=InitialState, action ){
        switch( action.type ){
            case requestUnlock.request.toString():
                return Object.assign({}, state, {
                    request_info_response: {
                        loading: true,
                        error: false,
                        message: ''
                    }
                });
            case requestUnlock.failure.toString():
                console.log( action.payload );
                return Object.assign({}, state, {
                    request_info_response: {
                        loading: false,
                        error: true,
                        message: ''
                    }
                });
            case requestUnlock.success.toString():
                return Object.assign({}, state, {
                    request_info_response: {
                        loading: false,
                        error: false,
                        message: 'We have received your request. Your Gateway representative will revert back to you shortly.'
                    }
                });
            case clearRequestUnlockMsg.toString():
                return Object.assign({}, state, {
                    request_info_response: {
                        loading: false,
                        error: false,
                        message: ''
                    }
                })
            default:
                return state;
        }
    }
})