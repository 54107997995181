import { connect } from 'react-redux';
import { default as PortfolioControl } from './PortfolioControl';

import {
    getSearchCompany,
    postSubmitPortfolio,
} from './Action';

import {
    getMyPortfolio,
} from '../../Action';

export default connect(
    state => ({
        searchCompanyResponse: state.portfolioHolding.portfolioControlReducer.reducer.searchCompanyResponse,
        search_company_loading: state.portfolioHolding.portfolioControlReducer.reducer.search_company_loading,
        cost_base: state.portfolioHolding.reducer.cost_base,
    }),
    dispatch => ({
        getSearchCompany: (payload) => dispatch(getSearchCompany.request(payload)),
        postSubmitPortfolio: (payload) => dispatch(postSubmitPortfolio.request(payload)),
        getMyPortfolio: (payload) => dispatch(getMyPortfolio.request(payload)),
    })
)(PortfolioControl);