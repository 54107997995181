import {
    roundNumber,
    numberWithCommas,
    getImpliedVal,
} from '../../../../../utils/util';


export const getOption = (state) => {
    const {
        average_bid,
        average_offer,
        highest_bid,
        lowest_offer,
        date,
        average_bid_visual_map,
        average_offer_visual_map,
        highest_bid_visual_map,
        lowest_offer_visual_map,
        show_latest_funding_rounds,
        latest_funding_rounds,
        latest_funding_rounds_val,
        lfr_visual_map,
        price_line_type,
        company_id,
        my_target_price,
        my_target_visual_map,
    } = state;

    const option = {
        legend: {
            show: true,
        },
        // dataZoom: [
        //     {
        //         type: 'slider',
        //         show: true,
        //         xAxisIndex: [0,1],
        //     }
        // ],
        xAxis: {
            type: 'category',
            boundaryGap: false,
            data: date,
            nameTextStyle: {
                fontFamily: 'Roboto',
                fontSize: 12
            },
            axisLabel: {
                fontFamily: 'Roboto',
                fontSize: 12,
                rich: {
                    fontFamily: 'Roboto',
                    fontSize: 12
                }
            }
        },
        yAxis: {
            type: 'value',
            name: 'Price per share',
            nameTextStyle: {
                fontFamily: 'Roboto',
                fontSize: 12
            },
            axisLabel: {
                formatter: '${value} ',
                fontFamily: 'Roboto',
                fontSize: 12,
                rich: {
                    fontFamily: 'Roboto',
                    fontSize: 12
                }
            },
            axisPointer: {
                snap: true
            }
        },
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'cross'
            },
            showContent: false
            // position: function (point, params, dom, rect, size) {
            //     // console.log( point );
            //     if( Array.isArray(point) && point.length > 1 && point[1] > 280 ) {
            //         return ['60%', '50%']
            //     } else {
            //         return ['10%', '-40%'];
            //     }

            // },
            // formatter: function(params, ticket, callback) {
            //     return '';
            // }
        }
        
    }

    const visual_map = [];
    const series = [];
    const legend_data = [];

    if( price_line_type == "average_price" ){
        visual_map.push({
            show: false,
            dimension: 0,
            seriesIndex: 0,
            pieces: average_bid_visual_map
        });

        series.push({
            name: 'Ave. Bid',
            type: 'line',
            data: average_bid
        });

        visual_map.push({
            show: false,
            dimension: 0,
            seriesIndex: 1,
            pieces: average_offer_visual_map
        });

        series.push({
            name: 'Ave. Offer',
            type: 'line',
            data: average_offer
        });

        legend_data.push({
            name: 'Ave. Bid',
            icon: 'circle',
            itemStyle: {
                color: 'rgba(69,97,168,0.8)'
            },
            textStyle: {
                fontFamily: "'Roboto', sans-serif",
                color: 'rgba(69,97,168,0.8)',
            }
        });

        legend_data.push({
            name: 'Ave. Offer',
            icon: 'circle',
            itemStyle: {
                color: 'rgba(252,163,16,0.8)'
            },
            textStyle: {
                fontFamily: "'Roboto', sans-serif",
                color: 'rgba(252,163,16,0.8)',
            }
        });
    } else {
        visual_map.push({
            show: false,
            dimension: 0,
            seriesIndex: 0,
            pieces: highest_bid_visual_map
        });

        series.push({
            name: 'Highest Bid',
            type: 'line',
            data: highest_bid
        });

        visual_map.push({
            show: false,
            dimension: 0,
            seriesIndex: 1,
            pieces: lowest_offer_visual_map
        });

        series.push({
            name: 'Lowest Offer',
            type: 'line',
            data: lowest_offer
        });

        legend_data.push({
            name: 'Highest Bid',
            icon: 'circle',
            itemStyle: {
                color: 'rgba(69,97,168,0.8)'
            },
            textStyle: {
                fontFamily: "'Roboto', sans-serif",
                color: 'rgba(69,97,168,0.8)'
            }
        });

        legend_data.push({
            name: 'Lowest Offer',
            icon: 'circle',
            itemStyle: {
                color: 'rgba(252,163,16,0.8)'
            },
            textStyle: {
                fontFamily: "'Roboto', sans-serif",
                color: 'rgba(252,163,16,0.8)'
            }
        });
    }

    if( show_latest_funding_rounds ){
        
        visual_map.push({
            show: false,
            dimension: 0,
            seriesIndex: 2,
            pieces: lfr_visual_map
        });

        series.push({
            name: 'LFR price',
            type: 'line',
            lineStyle: {
                type: 'dashed'
            },
            label: {
                formatter: '{a} <sup>(4)</sup>'
            },
            data: latest_funding_rounds,
        });

    }

    if( my_target_price.length ){
        series.push({
            name: 'Target Price',
            type: 'line',
            lineStyle: {
                type: 'dashed',
            },
            data: my_target_price
        });

        visual_map.push({
            show: false,
            dimension: 0,
            seriesIndex: show_latest_funding_rounds ? 3 : 2,
            pieces: my_target_visual_map
        });

        legend_data.push({
            name: 'Target Price',
            icon: 'circle',
            itemStyle: {
                color: 'rgba(7, 160, 230, 1)'
            },
            textStyle: {
                fontFamily: "'Roboto', sans-serif",
                color: 'rgba(7, 160, 230, 1)'
            }
        });
    }

    option['visualMap'] = visual_map;
    option['series'] = series;
    option['legend']['data'] = legend_data;
    

    return option;
}