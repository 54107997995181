import React from 'react';
import ReactECharts from 'echarts-for-react';

import {
    Dialog,
    DialogContent,
    Slide,
    AppBar,
    Toolbar,
    IconButton,
    Table,
    TableCell,
    TableBody,
    TableContainer,
    TableHead,
    Typography,
    Paper,
    withStyles,
    useMediaQuery,
} from '@material-ui/core';
import {
    StyledTableRow,
    TighterTableCell,
    TighterHeaderTableCell,
} from '../../../orderbook/common/Table';
import {
    globalFontFamily
} from '../../../styles';
import {
    numberWithCommas,
    roundNumber,
    displayVal,
} from '../../../../utils/util';

import CloseIcon from '@material-ui/icons/Close';
import vci_logo from '../../../../assets/images/vci_logo.png';

const FSPCDialogStyle = theme => ({
    AppBar: {
        background: theme.palette.common.white,
        boxShadow: 'none',
        position: 'relative',
    },
    IconButton: {
        position: 'absolute',
        right: 0,
    }
})

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const getOption = (results) => {
    let xAxisData = [];
    let seriesData = [];
    let fundingData = [];
    let postMoneyValuationData = [];
    let priceData = [];
    let employeeData = [];
    const sortedResult = [...results].sort(function (a, b) {
        return new Date(a.deal_date) - new Date(b.deal_date)
    })
    sortedResult.forEach(item => {
        xAxisData.push(item.deal_date)
        seriesData.push(item.deal_type_2)
        fundingData.push(item.deal_amount)
        postMoneyValuationData.push(item.post_money_valuation)
        priceData.push(item.price)
        employeeData.push(item.no_of_employee)
    });
    const option = {
        grid: {
            bottom: 181,
            // containLabel: true
        },
        legend: {
            data: ['Funding', 'Post Money Valuation'],
            y: 20,
        },
        title: [
            {
                text: 'Valuation Data',
                textStyle: {
                    color: 'gray',
                }
            },
            {
                text: '\nSERIES',
                bottom: 120,
                textStyle: {
                    color: '#258ac6',
                    lineHeight: 0,
                    fontWeight: 'normal',
                    fontSize: 12,
                    fontFamily: globalFontFamily
                }
            },
            {
                text: '\nFUNDING',
                bottom: 90,
                textStyle: {
                    color: 'black',
                    lineHeight: 0,
                    fontWeight: 'normal',
                    fontSize: 12,
                    fontFamily: globalFontFamily,
                }
            },
            {
                text: '\nPOST MONEY\n VALUATION',
                bottom: 60,
                textStyle: {
                    color: 'black',
                    // lineHeight: 0,
                    fontWeight: 'normal',
                    fontSize: 12,
                    fontFamily: globalFontFamily
                }
            },
            {
                text: '\nSHARE PRICE',
                bottom: 30,
                textStyle: {
                    color: 'black',
                    lineHeight: 0,
                    fontWeight: 'normal',
                    fontSize: 12,
                    fontFamily: globalFontFamily,
                }
            },
            {
                text: '\nEMPLOYEES',
                bottom: 0,
                textStyle: {
                    color: 'black',
                    lineHeight: 0,
                    fontWeight: 'normal',
                    fontSize: 12,
                    fontFamily: globalFontFamily
                }
            },
        ],
        tooltip: {
            trigger: 'item',
            formatter: function (params) {
                return `
                 ${params.name}</br>
                 <b>${params.seriesName}</b></br>
                 ${displayVal(params.value, 2, 2)}
                `
            }
        },
        xAxis: [
            {
                data: xAxisData,
                axisTick: {
                    show: false,
                },
                axisLine: {
                    onZero: true,
                    lineStyle: {
                        color: 'lightgray',
                    },
                },
                axisLabel: {
                    color: 'gary',
                    fontSize: 12,
                    fontFamily: globalFontFamily,
                }
            },
            {
                data: seriesData.map(item => 'SERIES ' + item),
                position: 'bottom',
                offset: 30,
                axisTick: {
                    show: false,
                },
                axisLine: {
                    show: false,
                },
                axisLabel: {
                    color: '#258ac6',
                    fontSize: 12,
                    fontFamily: globalFontFamily,
                }
            },
            {
                data: fundingData.map(item => displayVal(item, 2, 2)),
                position: 'bottom',
                offset: 60,
                axisTick: {
                    show: false,
                },
                axisLine: {
                    show: false,
                },
                axisLabel: {
                    color: 'black',
                    fontSize: 12,
                    fontFamily: globalFontFamily
                }
            },
            {
                data: postMoneyValuationData.map(item => displayVal(item, 2, 2)),
                position: 'bottom',
                offset: 90,
                axisTick: {
                    show: false,
                },
                axisLine: {
                    show: false,
                },
                axisLabel: {
                    color: 'black',
                    fontSize: 12,
                    fontFamily: globalFontFamily
                }
            },
            {
                data: priceData.map(item => roundNumber(item, 100)),
                position: 'bottom',
                offset: 120,
                axisTick: {
                    show: false,
                },
                axisLine: {
                    show: false,
                },
                axisLabel: {
                    color: 'black',
                    fontSize: 12,
                    fontFamily: globalFontFamily
                }
            },
            {
                data: employeeData.map(item => item ? item : '-'),
                position: 'bottom',
                offset: 150,
                axisTick: {
                    show: false,
                },
                axisLine: {
                    show: false,
                },
                axisLabel: {
                    color: 'black',
                    fontSize: 12,
                    fontFamily: globalFontFamily,
                },

            },
        ],
        yAxis: {
            name: 'US$M',
            nameLocation: 'middle',
            nameGap: 100,
        },
        series: [
            {
                name: 'Funding',
                type: 'bar',
                stack: 'one',
                data: fundingData,
                itemStyle: { color: '#258ac6' },
            },
            {
                name: 'Post Money Valuation',
                type: 'bar',
                stack: 'two',
                data: postMoneyValuationData,
                itemStyle: { color: 'lightgray' },
            },
        ],
    }
    return option
}

export default withStyles(FSPCDialogStyle)((props) => {
    const { results } = props;
    const isDesktopScreen = useMediaQuery(`(min-width:576px)`);
    return (
        <Dialog
            open={props.open}
            fullScreen
            TransitionComponent={Transition}
            sx={{ overflowY: 'scroll' }}
        >
            <AppBar className={props.classes.AppBar}>
                <Toolbar>
                    
                    <IconButton
                        onClick={() => props.closeVciFundingRoundDialog()}
                        className={props.classes.IconButton}>
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <DialogContent>
                {
                    isDesktopScreen
                        ?
                        <div className="chart-wrapper">
                            <ReactECharts
                                option={getOption(results)}
                                style={{ height: 400 }}
                                notMerge={true}
                            />
                        </div>
                        :
                        <div>
                            <Typography variant='h6' align='center'>Valuation Data</Typography>
                            <TableContainer component={Paper} style={{ position: 'relative', marginTop: 15, overflowX: "initial" }}>
                                <Table stickyHeader size="small" aria-label="simple table">
                                    <TableHead>
                                        <StyledTableRow>
                                            <TighterHeaderTableCell className="vci-valuation-date">Date</TighterHeaderTableCell>
                                            <TighterHeaderTableCell className="vci-valuation-series">Series</TighterHeaderTableCell>
                                            <TighterHeaderTableCell className="vci-valuation-valuation">Post Money Valuation</TighterHeaderTableCell>
                                            <TighterHeaderTableCell className="vci-valuation-price">Share Price</TighterHeaderTableCell>
                                            <TighterHeaderTableCell className="vci-valuation-employee">Employees</TighterHeaderTableCell>
                                        </StyledTableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            results.map((row, index) => {
                                                return (
                                                    <React.Fragment key={index}>
                                                        <StyledTableRow>
                                                            <TighterTableCell className="vci-valuation-date">
                                                                {row.deal_date ? row.deal_date : '-'}
                                                            </TighterTableCell>
                                                            <TighterTableCell className="vci-valuation-series">
                                                                {row.deal_type_2 ? 'SERIES ' + row.deal_type_2 : '-'}
                                                            </TighterTableCell>
                                                            <TighterTableCell className="vci-valuation-valuation">
                                                                {row.deal_amount ? displayVal(row.deal_amount, 2, 2) : '-'}
                                                            </TighterTableCell>
                                                            <TighterTableCell className="vci-valuation-price">
                                                                {row.price ? numberWithCommas(row.price, 2) : '-'}
                                                            </TighterTableCell>
                                                            <TighterTableCell className="vci-valuation-employee">
                                                                {row.no_of_employee ? row.no_of_employee : '-'}
                                                            </TighterTableCell>
                                                        </StyledTableRow>
                                                    </React.Fragment>
                                                )
                                            })
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                }
                
                <div style={{ position: 'absolute', bottom: 10, right: 20 }}>
                    <div className="powered-by-vci">
                        <p>Powered by <a href="https://www.venturecapinsights.com"  target="_blank" >
                                <img style={{background: '#1287ff', width: 60 }}
                                    src={vci_logo} alt="Venture Capital Insight"/>
                            </a>
                        </p>
                    </div>
                </div>
                {/* <a href="https://www.venturecapinsights.com"  target="_blank" style={{position: 'absolute', right: 10, bottom: 10}}>
                    <p></p>
                    <img style={{background: '#1287ff', width: 100, height: 42}}
                        src={vci_logo} alt="Venture Capital Insight"/>
                </a> */}
            </DialogContent>
        </Dialog>
    )
})