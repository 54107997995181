import {
    put,
    call,
    takeLatest,
    all,
} from 'redux-saga/effects';

import {
    compileQueryString,
    handleCatchSagaError,
} from '../../../../utils/util';

import {
    postPlaceInterest,
    getSearchCompany,
} from './Action';

import {
    wealth_pipeline,
} from '../../../../api';

function* postPlaceInterestResponder({ payload }){
    try{
        if( payload.order_type_url ){
            const order_type_url = payload.order_type_url;
            delete payload.order_type_url;
            const { data } = yield call(wealth_pipeline.expressInterest, order_type_url, payload);
            yield put(postPlaceInterest.success(data));
        } else {
            yield put(postPlaceInterest.failure(new Error('Missing required parameter.')));
        }
        
    } catch(e){
        yield handleCatchSagaError(e, 'postPlaceInterestResponder');
        yield put(postPlaceInterest.failure(e));
    }
}

function* getSearchCompanyResponder({ payload }){
    try{
        const query_string = compileQueryString(payload.qs);
        const { data } = yield call(wealth_pipeline.getSearchDealCompany, query_string, payload);
        yield put(getSearchCompany.success(data));
    } catch(e){
        yield handleCatchSagaError(e, 'getSearchCompanyResponder');
        yield put(getSearchCompany.failure(e));
    }
}

function* saga(){
    yield all([
        takeLatest(getSearchCompany.request, getSearchCompanyResponder),
        takeLatest(postPlaceInterest.request, postPlaceInterestResponder),
    ])
}

export default saga;