import React, { useState } from 'react';

import {
    Button,
    Box,
    Grid,
    Paper,
    makeStyles,
    Checkbox,
    Divider,
} from '@material-ui/core';
import { act } from '@testing-library/react';

const useStyles = makeStyles((theme) => ({
    p: {
        fontSize: 12,
        marginBottom: 10
    },
    checkbox: {
        fontSize: 12,
        marginBottom: 10
    }
}));

export default (props) => {
    // console.log( props );
    const { 
        riskDisclosureResponse: { content }, 
        public_mode,
    } = props;

    
    let empty_array = content.map(item => { return false })
    
    const [checkedBoxes, setCheckedBoxes] = useState(empty_array);

    const handleCheckBox = (evt, index) => {
        const { target } = evt;
        checkedBoxes[index] = target.checked;
        setCheckedBoxes([...checkedBoxes]);
    }

    const classes = useStyles();

    const required_check = content.filter(item => item.checkbox);
    const actual_checked = checkedBoxes.filter(item => item == true); 


    return (
        <Box flexGrow={1} m={2}>
            <Grid container spacing={2}>
                <Grid item xs={12} style={{height: 60}}></Grid>
                <Grid item xs={12}>
                    {
                        !public_mode ? 
                            <React.Fragment>
                                <p className={classes.p}>Please note that all investment opportunities on the Gateway platform are only available to Professional Investor (PI) as defined below. </p> 
                                <Divider style={{marginBottom: 10}} />
                            </React.Fragment>
                            : ''
                    }
                    {
                        content.map((value, index) => {
                            if( value.h2 ){
                                return (
                                    <h2 key={index}>{value.h2}</h2>
                                )
                            } else if( value.p ){
                                return <div 
                                    className={classes.p}
                                    key={index} 
                                    dangerouslySetInnerHTML={{__html: value.p}} />;
                            } else if ( value.checkbox && public_mode ) {
                                const checked_val = checkedBoxes[index];
                                return (
                                    <Grid container spacing={2} key={index}>
                                        <Grid style={{ display: 'flex' }} item xs={12}>
                                            <Checkbox type="checkbox"
                                                onChange={(evt) => handleCheckBox(evt, index)}
                                                checked={checked_val}
                                            ></Checkbox>
                                            <div className={classes.checkbox} style={{padding: '9px 3px'}} dangerouslySetInnerHTML={{__html: value.checkbox}}  />
                                        </Grid>
                                    </Grid>
                                )
                            } else if ( value.ul ){
                                return <ul key={index}>{
                                    value.ul.map((ul_val, ul_ind) => {
                                        if( ul_val.li ){
                                            return <li key={ul_ind}>{ul_val.li}</li>
                                        } else if( ul_val.ul ){
                                            return <ul key={ul_ind}>{
                                                ul_val.ul.map((uul_val, uul_ind) => {
                                                    return <li key={uul_ind}>{uul_val}</li>;
                                                })
                                                }</ul>
                                        }
                                    })
                                    }</ul>
                            }
                        })
                    }
                </Grid>
                <Grid item xs={12}>
                    {
                        content.length && (required_check.length == actual_checked.length || !public_mode) ?
                            <Button onClick={(evt) => props.handleAcknowledgeRiskDisclosure(evt)}>I acknowledge and confirm</Button> :
                            ''
                    }
                </Grid>
            </Grid>
        </Box>
    )
}