import { connect } from 'react-redux';


import {
    getCompanyDetail,
    getFundingRound,
    getHistoricalSecondaryPricing,
    getLiveOffers,
    getLiveDemand,
    getCompanyVerticals,
    getSimilarCompany,
    getPermissionedDeal,
    getLiveOffersWatchlist,
    getLiveDemandWatchlist,
    getRiskDisclosure,
    confirmRiskDisclosure,
    getSharedUser,
    addCount,
    getVciHistoricalRevenue,
    getVciCapTable,
    getVciFundingRound,
} from './Action';

import { default as PublicCompany } from './PublicCompany';

export default connect(
    state => ({
        loaderState: state.root.reducer.loaderState,
        showSnakbar: state.account.reducer.showSnakbar,
        loginUserData: state.login.reducer.loginUserData,
        appConfig: state.root.reducer.appConfig,
        company_detail: state.orderbookCompany.reducer.company_detail,
        funding_rounds: state.orderbookCompany.reducer.funding_rounds,
        historical_pricing: state.orderbookCompany.reducer.historical_pricing,
        reference_pricing: state.orderbookCompany.reducer.reference_pricing,
        live_offers: state.orderbookCompany.reducer.live_offers,
        live_demand: state.orderbookCompany.reducer.live_demand,
        company_verticals: state.orderbookCompany.reducer.company_verticals,
        similar_companies: state.orderbookCompany.reducer.similar_companies,
        permission_deal: state.orderbookCompany.reducer.permission_deal,
        live_offers_watchlist: state.orderbookCompany.reducer.live_offers_watchlist,
        live_demand_watchlist: state.orderbookCompany.reducer.live_demand_watchlist,
        shareDealResponse: state.orderbookCompany.reducer.shareDealResponse,
        riskDisclosureConfirmed: state.orderbookCompany.reducer.riskDisclosureConfirmed,
        riskDisclosureResponse: state.orderbookCompany.reducer.riskDisclosureResponse,
        shared_user: state.orderbookCompany.reducer.shared_user,
        vci_historical_revenue: state.orderbookCompany.reducer.vci_historical_revenue,
        vci_cap_table: state.orderbookCompany.reducer.vci_cap_table,
        vci_funding_rounds: state.orderbookCompany.reducer.vci_funding_rounds,
    }),
    dispatch => ({
        getCompanyDetail: payload => dispatch(getCompanyDetail.request(payload)),
        getFundingRound: payload => dispatch(getFundingRound.request(payload)),
        getHistoricalSecondaryPricing: payload => dispatch(getHistoricalSecondaryPricing.request(payload)),
        getLiveOffers: payload => dispatch(getLiveOffers.request(payload)),
        getLiveDemand: payload => dispatch(getLiveDemand.request(payload)),
        getCompanyVerticals: (payload) => dispatch(getCompanyVerticals.request(payload)),
        getSimilarCompany: (payload) => dispatch(getSimilarCompany.request(payload)),
        getPermissionedDeal: (payload) => dispatch(getPermissionedDeal.request(payload)),
        getLiveOffersWatchlist: payload => dispatch(getLiveOffersWatchlist.request(payload)),
        getLiveDemandWatchlist: payload => dispatch(getLiveDemandWatchlist.request(payload)),
        getRiskDisclosure: payload => dispatch(getRiskDisclosure.request(payload)),
        confirmRiskDisclosure: () => dispatch(confirmRiskDisclosure()),
        getSharedUser: (payload) => dispatch(getSharedUser.request(payload)),
        addCount: (payload) => dispatch(addCount.request(payload)),
        getVciHistoricalRevenue: payload => dispatch(getVciHistoricalRevenue.request(payload)),
        getVciCapTable: payload => dispatch(getVciCapTable.request(payload)),
        getVciFundingRound: payload => dispatch(getVciFundingRound.request(payload)),
    })
)(PublicCompany);