import { fromJS, List, Map } from 'immutable';

export default {
	pendingUserState: true,
	getPasscodeResponse: false,
	loginError: false,
	loginResponse: '',
	loginUserData: {
		activeAccessRight: {
			access_right: {
				active: true,
				permission: ''
			}
		},
		accessRight: [
			{
				access_right: { 
					active: true,
					permission: '' 
				}
			}
		]
	},
	showSnackbarInLogin: false,
	pi_content: {
		content: [],
		subject: ''
	},
	tou_content: {
		content: [],
		subject: ''
	},
	pp_content: {
		content: [],
		subject: ''
	},
	ca_content: {
		content: [],
		subject: ''
	},
	oba_content: {
		content: [],
		subject: ''
	},
	pg_content: {
		content: [],
		subject: ''
	},
	wpg_content: {
		content: [],
		subject: ''
	},
	wa_content: {
		content: [],
		subject: ''
	},
	obwpg_content: {
		content: [],
		subject: ''
	},
	obwa_content: {
		content: [],
		subject: ''
	},
	agreementAndPasswordSet: false
}
