export default {
    fundraising_params: {},
    prospect_summary: {},
    vetted_investors_response: {},
    goal_summary: {},
    company_verticals: [],
    similar_companies: {
        resultCount: 0,
        resultList: []
    },
    company_detail: {},
}