import { combineReducers } from 'redux';
import { ConfirmPasswordReset } from './Action';
import { createReducer } from 'redux-act';
import { default as InitialState } from './InitialState';

export default combineReducers({
    reducer(state=InitialState, action){
        switch( action.type ){
            case ConfirmPasswordReset.success.toString():
                return Object.assign({}, state, {
                    showSnackbarInPassword:!(state.showSnackbarInPassword),
                    passwordError:false,
                    passwordResponse: 'Password has been successfully changed.'
                });
            case ConfirmPasswordReset.failure.toString():
                let errorInString = ''
                if (action.payload && action.payload.response && action.payload.response.data) {
                    errorInString = ''
                    for (let key in action.payload.response.data) {
                        if ( key == 'password' ) {
                            errorInString = 'The entered passwords do not match'
                        }
                        else if(key=='token'){
                            errorInString = "The link has expired. Please try to reset the password again"
                        }
                        else {
                            errorInString += action.payload.response.data[key]
                        }
                    }
                }
                return Object.assign({}, state, {
                    showSnackbarInPassword:!(state.showSnackbarInPassword),
                    passwordError:true,
                    passwordResponse: errorInString
                });
            default:
                return state;
        }
    }
})
