import React, { Fragment } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { TableStyles } from '../styles';



class InputTags extends React.Component {
	constructor() {
		super();
		this.state = {
			tags: []
		};
	}

	inputKeyDown = (e) => {
		let val = e.target.value;
		if (e.key === 'Enter' && val) {
			const { keyDownCallback } = this.props;
			val = this.props.prefix ? this.props.prefix + val : val;

			if (this.state.tags.find(tag => tag.toLowerCase() === val.toLowerCase())) {
				return;
			}
			this.setState({ tags: [...this.state.tags, val] });
			this.tagInput.value = null;

			keyDownCallback({ tags: [...this.state.tags, val] });
		}
		else if (e.key === 'Backspace' && !val) {
			const { keyDownCallback } = this.props;
			this.removeTag(this.state.tags.length - 1);
		}

	}

	removeTag = (i) => {
		const { keyDownCallback } = this.props;
		const newTags = [...this.state.tags];
		newTags.splice(i, 1);
		this.setState({ tags: newTags });
		keyDownCallback({ tags: newTags })

	}

	render() {
		const { tags } = this.state;
		return (
			<div className="input-tag">
				<ul className="input-tag__tags">
					{tags.map((tag, i) => (
						<li key={tag}>
							{tag}
							<button type="button" onClick={() => { this.removeTag(i); }}>+</button>
						</li>
					))}
					<li className="input-tag__tags__input">
						<input type="text" placeholder="Search" onKeyDown={this.inputKeyDown} ref={c => { this.tagInput = c; }} />
					</li>
				</ul>
			</div>
		);
	}
}

export default withStyles(TableStyles)(InputTags)


