import {
    put,
    call,
    takeLatest,
    all,
} from 'redux-saga/effects';

import {
    postRequestInfo,
} from './Action';

import {
    handleCatchSagaError,
} from '../../../../../utils/util';

import {
    orderbook,
} from '../../../../../api';

function* postRequestInfoResponder({ payload }){
    try{
        const { data } = yield call(orderbook.requestInformation, payload);
        yield put(postRequestInfo.success(data));
    } catch(e){
        yield handleCatchSagaError(e, 'postRequestInfoResponder');
        yield put(postRequestInfo.failure(e));
    } finally{

    }
}

function* saga(){
    yield all([
        takeLatest(postRequestInfo.request, postRequestInfoResponder),
    ])
}

export default saga;