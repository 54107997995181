import React from 'react';

import { 
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Button,
    makeStyles,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    Dialog: {
        zIndex: '2000 !important',
    }
}))

export default (props) => {
    const classes = useStyles();
    return (
        <div>
            <Dialog
                className={classes.Dialog}
                open={props.open}
                onClose={props.handleCancelOverwrite}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                >
                <DialogTitle id="alert-dialog-title">{"Overwrite your changes"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        This will overwrite any changes you have made to the place order form.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.handleCancelOverwrite} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={props.handleConfirmOverwrite} color="primary" autoFocus>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}