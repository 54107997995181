import React, { Fragment } from 'react';
import ReactECharts from 'echarts-for-react';

import {
    Grid,
} from '@material-ui/core';

import {
    roundNumber,
    get_average,
    rearrange_cost_base,
} from '../../../../utils/util';


function get_weighted_ave_cost_base(cost_base){
    const valid_cost_base = cost_base.filter(item => item.price && item.noOfShares);
    const total_shares = (valid_cost_base.map(item => item.noOfShares)).reduce((partialSum, a) => partialSum+a, 0);
    const total_size = (valid_cost_base.map(item => item.noOfShares*item.price)).reduce((partialSum, a) => partialSum+a, 0);
    return total_size / total_shares;
}

function get_date_range(buffer){
    let now = new Date();
    let a_year_ago = new Date();
    a_year_ago.setDate(a_year_ago.getDate()-buffer);
    return { now, a_year_ago }
}

function get_date_array(){
    let { now, a_year_ago } = get_date_range(365);
    const dates = [];
    for( let ii=a_year_ago; ii<=now; ii.setDate(ii.getDate() + 1) ){
        dates.push( get_date_string(ii) );
    }
    return dates;
}

function get_date_string(ii){
    return ii.getFullYear() + '-' + ( ii.getMonth()+1 < 10 ? '0' + (ii.getMonth()+1) : ii.getMonth()+1 ) + '-' + ( ii.getDate() < 10 ? '0' + ii.getDate() : ii.getDate() );
}

function dissect_price_index(price_index_by_company, cost_base){
    const weighted_ave_cost_base = get_weighted_ave_cost_base(cost_base);
    // console.log( weighted_ave_cost_base );
    const response = {
        average_offer: [],
        average_bid: [],
        average_offer_growth: [],
        average_bid_growth: [],
        est_earning: []
    }

    const response_keys = Object.keys(response);

    let { now, a_year_ago } = get_date_range(365);
    
    for( let ii=a_year_ago; ii<=now; ii.setDate(ii.getDate() + 1) ){
        const forloop_date = get_date_string(ii);
        const matched_price_item = price_index_by_company.filter(item => item['date'] == forloop_date);
        
        let matched_item = {
            date: forloop_date,
            average_offer: undefined,
            average_bid: undefined,
            average_offer_growth: 0,
            average_bid_growth: 0,
        }
        if( matched_price_item.length ){
            matched_item = matched_price_item[0];
        }

        for( let iii=0; iii<response_keys.length; iii++ ){
            const key = response_keys[iii];
            if( key == 'est_earning' ){
                response[key].push( 
                    matched_item['average_bid'] && weighted_ave_cost_base ? 
                        roundNumber((matched_item['average_bid'] - weighted_ave_cost_base) / weighted_ave_cost_base * 100) :
                        0 );
            } else {
                response[key].push( matched_item[key] );
            }
        }
    }

    return response;
}


function get_overall_estimated_earning(dates, est_earning){
    const company_keys = Object.keys( est_earning );

    let { now, a_year_ago } = get_date_range(365);

    const overall_est_earning = [];
            
    for( let ii=a_year_ago; ii<=now; ii.setDate(ii.getDate() + 1) ){
        const date_index = dates.indexOf( get_date_string(ii) );

        if( date_index > -1 ){
            const earning_per_day = []
            for( let iii=0; iii<company_keys.length; iii++ ){
                if( est_earning[company_keys[iii]][date_index] ){
                    earning_per_day.push(est_earning[company_keys[iii]][date_index])
                }
            }
            overall_est_earning.push( roundNumber(get_average(earning_per_day)) );
        } else {
            overall_est_earning.push(0);
        }

    }

    est_earning['overall'] = overall_est_earning;
    return est_earning;
}

class PriceChart extends React.Component {
    state = {
        date: [],
        price_index: {},
        average_offer: {},
        average_bid: {},
        average_offer_growth: {},
        average_bid_growth: {},
        cost_base: {},
        est_earning: {},
        chart_type: {
            type: 'overall',
            id: undefined,
            company_id: undefined,
        },
        
    }

    constructor(props){
        super(props);
    }

    static getDerivedStateFromProps(nextProps, prevState){
        const {
            price_index,
            cost_base,
            company_details,
            chart_data_id,
        } = nextProps;

        const state_price_index = Object.keys(prevState['price_index']);
        const props_price_index = Object.keys(price_index);

        if( !props_price_index.equals(state_price_index) ){
            const state = {
                date: [],
                price_index: price_index,
                cost_base: cost_base,
                average_offer: {},
                average_bid: {},
                average_offer_growth: {},
                average_bid_growth: {},        
                est_earning: {},
            }
            
            state['date'] = get_date_array();
            
            for( let ii=0; ii<props_price_index.length; ii++ ){
                const dissect_response = dissect_price_index( price_index[props_price_index[ii]], cost_base[props_price_index[ii]] );
                for( let iii=0; iii<(Object.keys(dissect_response)).length; iii++ ){
                    const key = Object.keys(dissect_response)[iii];
                    state[key][props_price_index[ii]] = dissect_response[key];
                }
           }

           state['est_earning'] = get_overall_estimated_earning(state['date'], state['est_earning']);
           return state;
        } else if ( chart_data_id && chart_data_id != prevState.chart_type.id ){
            const re_cost_base = rearrange_cost_base(cost_base, company_details);
            const matched_cb = re_cost_base.filter(item => item.id == chart_data_id);

            const state = {}

            if( matched_cb.length ){
                state['chart_type'] = {
                    type: 'holding',
                    id: matched_cb[0]['id'],
                    company_id: matched_cb[0]['company_id'],
                } 

                const dissect_response = dissect_price_index( price_index[matched_cb[0]['company_id']], matched_cb );
                const { est_earning } = prevState;
                est_earning[matched_cb[0]['company_id']] = dissect_response['est_earning'];
                state['est_earning'] = est_earning;
            }
            
            return state;
        } else {
            return null;
        }
    }

    getOption = () => {
        const {
            chart_type,
            date,
            average_bid,
            average_offer,
            // average_bid_growth,
            // average_offer_growth,
            est_earning,
        } = this.state;

        const option = {
            legend: {
                show: true
            },
            dataZoom: [
                {
                    type: 'slider'
                }
            ],
            xAxis: {
                type: 'category',
                boundaryGap: false,
                data: date,
                nameTextStyle: {
                    fontFamily: 'Roboto',
                    fontSize: 12
                },
                axisLabel: {
                    fontFamily: 'Roboto',
                    fontSize: 12,
                    rich: {
                        fontFamily: 'Roboto',
                        fontSize: 12
                    }
                }
            },
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'cross'
                }
            }
        }

        const series = [];
        const legend_data = [];

        if( chart_type.type == "overall" ){
            series.push({
                name: 'Portfolio Mark to Market',
                type: 'bar',
                stack: 'Total',
                data: est_earning['overall'],
            });

            option['yAxis'] = {
                type: 'value',
                nameTextStyle: {
                    fontFamily: 'Roboto',
                    fontSize: 12
                },
                axisLabel: {
                    formatter: '{value} %',
                    fontFamily: 'Roboto',
                    fontSize: 12,
                    rich: {
                        fontFamily: 'Roboto',
                        fontSize: 12
                    }
                },
                axisPointer: {
                    snap: true
                }
            }
        } else if ( 
            average_bid[chart_type.company_id] &&
            average_offer[chart_type.company_id] &&
            est_earning[chart_type.company_id]
            ) {

                option['yAxis'] = [
                    {
                        type: 'value',
                        name: 'Ave. Bid',
                        position: 'left',
                        alignTicks: true,
                        nameTextStyle: {
                            fontFamily: 'Roboto',
                            fontSize: 12
                        },
                        axisLine: {
                            show: true,
                            // lineStyle: {
                            //     color: colors[0]
                            // }
                        },
                        axisLabel: {
                            formatter: '${value}',
                            fontFamily: 'Roboto',
                            fontSize: 12,
                            rich: {
                                fontFamily: 'Roboto',
                                fontSize: 12
                            }
                        },
                        // axisPointer: {
                        //     snap: true
                        // }
                    },
                    // {
                    //     type: 'value',
                    //     name: 'Ave. Offer',
                    //     position: 'left',
                    //     alignTicks: true,
                    //     nameTextStyle: {
                    //         fontFamily: 'Roboto',
                    //         fontSize: 12
                    //     },
                    //     axisLine: {
                    //         show: true,
                    //         // lineStyle: {
                    //         //     color: colors[0]
                    //         // }
                    //     },
                    //     axisLabel: {
                    //         formatter: '${value}',
                    //         fontFamily: 'Roboto',
                    //         fontSize: 12,
                    //         rich: {
                    //             fontFamily: 'Roboto',
                    //             fontSize: 12
                    //         }
                    //     },
                    //     // axisPointer: {
                    //     //     snap: true
                    //     // }
                    // },
                    {
                        type: 'value',
                        name: 'Mark to Market',
                        position: 'right',
                        alignTicks: true,
                        nameTextStyle: {
                            fontFamily: 'Roboto',
                            fontSize: 12
                        },
                        axisLine: {
                            show: true,
                            // lineStyle: {
                            //     color: colors[2]
                            // }
                        },
                        axisLabel: {
                            formatter: '{value} %',
                            fontFamily: 'Roboto',
                            fontSize: 12,
                            rich: {
                                fontFamily: 'Roboto',
                                fontSize: 12
                            }
                        }
                    }
                ]

                series.push({
                    name: 'Ave. Bid',
                    type: 'line',
                    yAxisIndex: 0,
                    data: average_bid[chart_type.company_id],
                    itemStyle: {
                        color: 'rgba(69,97,168,0.8)'
                    },
                    lineStyle: {
                        color: 'rgba(69,97,168,0.8)'
                    }
                });

                series.push({
                    name: 'Ave. Offer',
                    type: 'line',
                    yAxisIndex: 0,
                    data: average_offer[chart_type.company_id],
                    itemStyle: {
                        color: 'rgba(252,163,16,0.8)'
                    },
                    lineStyle: {
                        color: 'rgba(252,163,16,0.8)'
                    }
                });

                series.push({
                    name: 'Mark to Market',
                    type: 'bar',
                    stack: 'Total',
                    yAxisIndex: 1, 
                    data: est_earning[chart_type.company_id],
                });

                legend_data.push({
                    name: 'Ave. Bid',
                    icon: 'circle',
                    itemStyle: {
                        color: 'rgba(69,97,168,0.8)'
                    },
                    textStyle: {
                        fontFamily: "'Roboto', sans-serif",
                        color: 'rgba(69,97,168,0.8)'
                    }
                });

                legend_data.push({
                    name: 'Ave. Offer',
                    icon: 'circle',
                    itemStyle: {
                        color: 'rgba(252,163,16,0.8)'
                    },
                    textStyle: {
                        fontFamily: "'Roboto', sans-serif",
                        color: 'rgba(252,163,16,0.8)'
                    }
                })
        }

        option['series'] = series;
        option['legend']['data'] = legend_data;

        return option['xAxis'] && option['yAxis'] && option['series'] ? option : {};
    }

    render(){
        // console.log( this.state );
        return (
            <section className="price-chart-section orderbook" id="price-chart-section">
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <div className="chart-wrapper">
                            <ReactECharts
                                option={this.getOption()}
                                style={{height: 400}}
                                notMerge={true}
                                />
                        </div>
                    </Grid>
                </Grid>
            </section>
        )
    }
}

export default PriceChart;