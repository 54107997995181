import { combineReducers } from "redux";

import {
    getSearchCompany,
    clearNoMatch,
} from './Action';
import {
    createWatchlistV2,
} from '../../../common/watchlist/Action';
import { default as InitialState } from './InitialState';

export default combineReducers({
    reducer(state=InitialState, action){
        switch( action.type ){
            case getSearchCompany.request.toString():
                return Object.assign({}, state, {
                    // searchCompanyResponse: {
                    //     resultCount: 0,
                    //     resultList: [],
                    // },
                    searchCompanyResponse: [],
                    search_company_loading: true,
                    no_match: false,
                });
            case getSearchCompany.failure.toString():
                return Object.assign({}, state, {
                    // searchCompanyResponse: {
                    //     resultCount: 0,
                    //     resultList: [],
                    // },
                    searchCompanyResponse: [],
                    search_company_loading: false,
                    no_match: false,
                });
            case getSearchCompany.success.toString():
                return Object.assign({}, state, {
                    searchCompanyResponse: action.payload,
                    search_company_loading: false,
                    no_match: action.payload.length ? false: true,
                });
            case createWatchlistV2.success.toString():  
            case clearNoMatch.toString():
                return Object.assign({}, state, {
                    no_match: false
                });
            default:
                return state;
        }
    }
})