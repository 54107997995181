import { connect } from 'react-redux';

import { default as SessionEnd } from './SessionEnd';

export default connect(
    state => ({
        UserTypeError: state.sessionEnd.reducer.UserTypeError,
    }),
    dispatch => ({

    })
)(SessionEnd);