import { 
    CreateApiAction as _createApiAction, 
    CreateKeyAction as _createKeyAction,
} from '../../../utils';

const key = "ORDERBOOK_PIPELINE";

const CreateApiAction = _createApiAction(key);

const getVerticalsList = CreateApiAction('VERTICALS_LIST');
const getGeographyList = CreateApiAction('GEOGRAPHY_LIST');
const getOrderbookView = CreateApiAction('ORDERBOOKVIEW');
const getMoreOrderbook = CreateApiAction('MORE_ORDERBOOK');
const getOpportunityHighlight = CreateApiAction('OPPORTUNITY_HIGHLIGHT');
const getCompanyNameById = CreateApiAction('GET_COMPANY_NAME_BY_ID');
const getLiveOfferCount = CreateApiAction('GET_LIVE_OFFER_COUNT');
const getLiveBidCount = CreateApiAction('GET_LIVE_BID_COUNT');
const getPermissionedDeals = CreateApiAction('GET_PERMISSIONED_DEALS');
const toggleWatchList = CreateApiAction('TOGGLE_WATCHLIST');

const CreateKeyAction = _createKeyAction(key);
const clearOrderbookCompany = CreateKeyAction('CLEAR_ORDERBOOK_COMPANY');
const internalSort = CreateKeyAction('INTERNAL_SORT');
const clearPrefillCompanyName = CreateKeyAction('CLEAR_PREFILL_COMPANY_NAME');
const clearWatchlistMessage = CreateKeyAction('CLEAR_WATCHLIST_MESSAGE');
const showHideCampaignPopUp = CreateKeyAction('SHOW_HIDE_CAMPAIGN_POPUP');

const updateUserType = CreateApiAction('UPDATE_USER_TYPE');
const getDealMetrics = CreateApiAction('GET_DEAL_METRICS');

export {
    getVerticalsList,
    getGeographyList,
    getOrderbookView,
    getMoreOrderbook,
    getOpportunityHighlight,
    getCompanyNameById,
    clearOrderbookCompany,
    internalSort,
    clearPrefillCompanyName,
    getLiveOfferCount,
    getLiveBidCount,
    toggleWatchList,
    clearWatchlistMessage,
    getPermissionedDeals,
    updateUserType,
    showHideCampaignPopUp,
    getDealMetrics,
}