import {
    CreateApiAction as _createApiAction,
    CreateKeyAction as _createKeyAction,
} from '../../../utils';

const key = "BOOST_DASHBOARD";

const CreateApiAction = _createApiAction(key);
const getFundraisingParameters = CreateApiAction('GET_FUNDRAISING_PARAMETERS');
const getGoalSummary = CreateApiAction('GET_GOAL_SUMMARY');
const getProspectSummary = CreateApiAction('GET_PROSPECT_SUMMARY');
const getInvestorSummary = CreateApiAction('GET_INVESTOR_SUMMARY');
const getVettedInvestorList = CreateApiAction('GET_VETTED_INVESTOR_LIST');
const getSimilarCompany = CreateApiAction('GET_SIMILAR_COMPANY');
const getCompanyVerticals = CreateApiAction('GET_COMPANY_VERTICALS');
const getCompanyDetail = CreateApiAction('GET_COMPANY_DETAIL');

export {
    getFundraisingParameters,
    getGoalSummary,
    getProspectSummary,
    getInvestorSummary,
    getVettedInvestorList,
    getSimilarCompany,
    getCompanyVerticals,
    getCompanyDetail,
}