import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { BiDetail } from 'react-icons/bi';

import {
    Button,
    Tooltip,
} from '@material-ui/core';

import {
    StarBorder,
    Star,
    ExpandMore,
    ExpandLess,
} from '@material-ui/icons';

import WhatshotIcon from '@material-ui/icons/Whatshot';

import {
    showBlurred,
    longBlurText,
    isMobile,
} from '../../../../utils/util';

import {
    content_placeholders,
} from '../../../../config';


const renderCompanyLiveTag = () => {
    return (
        <div
            className="flex-row-center home-card-status"
            style={{ background: "#FCA311" }}
        >
            <span className="home-status">Live</span>
        </div>
    );
};

export default (props) => {
    const { 
        listArray, 
        type, 
        classes,
        doToggleWatchList,
    } = props;

    const target_date = new Date();
    target_date.setDate(target_date.getDate() - 14);

    const [showDetailId, setShowDetailId] = useState(0);
    const blur_text = longBlurText();

    const handleDetailMouseEnter = (evt, company_id) => {
        setShowDetailId(company_id);
    }

    const handleDetailMouseLeave = (evt) => {
        setShowDetailId(0);
    }

    return (
        <div className="flex-row market-intelligence-list">
            {listArray &&
                listArray.length > 0 &&
                listArray.map((item, index) => {
                    const class_str = item && item.deal_class_str ? item.deal_class_str.split('|') : [];
                    const date_obj = new Date(item.date_added+"T00:00:00");
                    return (
                        <div
                            key={index}
                            className="home-card market-item"
                        >
                            {
                                date_obj > target_date && item.status == "WEALTH DEAL LIVE CONFIRMED" ?
                                    <div className="new-label">
                                        Recently<br />Added
                                    </div>
                                    : ''
                            }
                            {showDetailId == item.company_id && (
                                <div className="flex-row market-about">
                                    <div className="line-ten">{
                                        showBlurred(item.description) ?
                                        <span className="blurred">{blur_text}</span> :
                                        item.description
                                    }</div>
                                </div>
                            )}
                            <div className="src-logo-container">
                            {item.src_info_logo_list && item.src_info_logo_list.length > 0 &&
                                
                                    item.src_info_logo_list.slice(0, 2).map((infoLogn, logoIndex) => {
                                        return <img className="case-img-logo" key={logoIndex} src={infoLogn} alt="" />
                                    })
                                
                            }
                            </div>
                            <div className="flex-row-between">
                                <div style={{width: '100%'}}>
                                    <div className="home-company-name-market">
                                        {item.name}
                                    </div>
                                    <div className="home-company-info line-two company-info-height">
                                        {item.primary_industry_code}
                                        <div className="flex-row company-icon-wrap">
                                            {
                                                item.focus_deal ? <WhatshotIcon style={{ color: '#FF0000' }} /> : ""
                                            }
                                            {
                                                isMobile() ? 
                                                    <div
                                                        onTouchStart={(evt) => 
                                                            handleDetailMouseEnter(evt, item.company_id)}
                                                        onTouchEnd={(evt) => handleDetailMouseLeave(evt)}
                                                        onMouseOver={(evt) => 
                                                            handleDetailMouseEnter(evt, item.company_id)}
                                                        onMouseOut={(evt) => handleDetailMouseLeave(evt)}
                                                        className="flex-row detail-icon">
                                                            <BiDetail className="pointer-none" />
                                                    </div>
                                                :
                                                    <div
                                                        onMouseOver={(evt) =>
                                                            handleDetailMouseEnter(evt, item.company_id)}
                                                        onMouseOut={(evt) => handleDetailMouseLeave(evt)}
                                                        className="flex-row detail-icon">
                                                            <BiDetail className="pointer-none" />
                                                    </div>
                                            }
                                            
                                    
                                    {/* {item.watchlist ? (
                                        <span
                                            onClick={(event) =>
                                                doToggleWatchList(
                                                    {
                                                        companyId: item.company_id,
                                                        watchlist: item.watchlist,
                                                    },
                                                    event
                                                )
                                            }
                                            style={{ color: "#FFC50D" }}
                                        >
                                            <Tooltip title="Remove from watchlist">
                                                <Star />
                                            </Tooltip>
                                        </span>
                                    ) : (
                                        <span
                                            onClick={(event) =>
                                                doToggleWatchList(
                                                    {
                                                        companyId: item.company_id,
                                                        watchlist: item.watchlist,
                                                    },
                                                    event
                                                )
                                            }
                                            style={{ color: "#AFABAB" }}
                                        >
                                            <Tooltip title="Add to watchlist">
                                                <StarBorder />
                                            </Tooltip>
                                        </span>
                                    )} */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {item.vertical_category_list && item.vertical_category_list.length > 0 &&
                                <div className="vertical-category-container">
                                    {
                                        item.vertical_category_list.map((categoryitem, categoryIndex) => {
                                            return <span className="card-vertical-category-item" key={categoryIndex}>#{categoryitem} </span>
                                        })
                                    }
                                </div>
                            }
                            {
                                showBlurred(item.logo) ?
                                    <div className="case-img mt-2"></div>
                                    : <img className="case-img mt-2" src={item.logo} alt="" />
                            }
                            
                            <div className="pr-1 market-info">
                                {/* <div className="valuation-wrap">
                                    <span className="market-label">Valuation: </span>
                                    <span className="home-company-info">
                                        {item.selected_post_money_valuation
                                            ? item.selected_post_money_valuation > 1000
                                                ? "US$" +
                                                    (item.selected_post_money_valuation / 1000).toFixed(
                                                        1
                                                    ) +
                                                    "B"
                                                : "US$" +
                                                    item.selected_post_money_valuation.toFixed(0) +
                                                    "M"
                                            : "-"}
                                    </span>
                                </div> */}
                                <div className="location-wrap">
                                    <span className="market-label">Location: </span>
                                    <span className="home-company-info">
                                        { showBlurred(item.headquarter_country) ? <span className="blurred">{item.headquarter_country}</span> : item.headquarter_country}
                                    </span>
                                </div>
                                <div className="stage-wrap">
                                    <span className="market-label">Stage: </span>
                                    <span className="home-company-info">
                                        {item.selected_deal_type_1 && item.selected_deal_type_1.indexOf('Stage VC') > -1 ? item.selected_deal_type_1 : "-"}
                                        {item.selected_deal_type_1 &&
                                        item.selected_deal_type_1.indexOf('Stage VC') > -1 &&
                                        item.selected_deal_type_2 &&
                                        item.selected_deal_type_2.trim() ? " (Series " + item.selected_deal_type_2 + ")" : ""}
                                    </span>
                                </div>
                                <div className="deal-type-tag-wrap in-pipeline-page">
                                    { class_str.indexOf(content_placeholders.deal_class_str.gpled) > -1 && <span className={classes.GPLedDeal}>GP Co-investment</span>}
                                    { 
                                        class_str.indexOf(content_placeholders.deal_class_str.primary) > -1 &&
                                        class_str.indexOf(content_placeholders.deal_class_str.gpled) == -1 && 
                                            <span className={classes.PrimaryDeal}>Company Primary</span>
                                    }
                                    { class_str.indexOf(content_placeholders.deal_class_str.companyled) > -1 &&
                                        class_str.indexOf(content_placeholders.deal_class_str.primary) == -1 &&
                                        class_str.indexOf(content_placeholders.deal_class_str.gpled) == -1 && 
                                            <span className={classes.CompLedDeal}>Company Arranged</span>}
                                    {
                                        !class_str.length && <span className={classes.DirectSecondaryDeal}>Secondary</span>
                                    }
                                </div>
                                {item.status == "DEAL LIVE CONFIRMED" &&
                                    renderCompanyLiveTag()}
                            </div>
                            <Link
                                to={{
                                    pathname: `/wealth/company-detail/${item.company_id}`,
                                    state: { type: type },
                                }}>
                                <Button variant="outlined" className="view-detail-btn">View Details</Button>
                            </Link>
                        </div>
                    );
                })}
        </div>
    )
}