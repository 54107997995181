import { fromJS, List, Map } from 'immutable';

const initialState = {
    validEmail:false,
    emailToSend:{},
    showSnackbarInReset:false,
    emailError: false,
    emailResponse: '',

}

const initialImmutableState = fromJS(initialState)
export default initialImmutableState;