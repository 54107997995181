import moment from "moment";
function GenerateGreeting() {

    var currentHour = moment().format("HH");
  
    if (currentHour >= 3 && currentHour < 12){
        return "Good Morning";
    } else if (currentHour >= 12 && currentHour < 19){
        return "Good Afternoon";
    }   else if (currentHour >= 19 && currentHour < 22){
        return "Good Evening";
    } else if (currentHour >= 22 && currentHour <= 23){
        return "Good Night";
    } else {
        return "Hello"
    }
  
}
export default GenerateGreeting;