export default {
    company_detail: {},
    funding_rounds: {
        count: 0,
        results: []
    },
    historical_pricing: {
        allow: false,
        data: false,
        historical_pricing: [
            
        ]
    },
    reference_pricing: {
        allow: false,
        data: false,
        reference_names: [],
        reference_pricing: []
    },
    live_demand: {
        resultCount: 0,
        resultList: [],
    },
    live_offers: {
        resultCount: 0,
        resultList: [],
    },
    company_verticals: [],
    similar_companies: {
        resultCount: 0,
        resultList: []
    },
    toggle_watchlist: {
        error: false,
        message: ''
    },
    permission_deal: {},
    live_demand_watchlist: 0,
    live_offers_watchlist: 0,
    shareDealResponse: {
        token: ''
    },
    riskDisclosureConfirmed: false,
    riskDisclosureResponse: {
        content: []
    },
    shared_user: {
        
    },
    vci_historical_revenue: {
        resultCount: 0,
        resultList: []
    },
    vci_cap_table: {
        resultCount: 0,
        resultList: []
    },
    vci_funding_rounds: {
        count: 0,
        results: []
    },
}