import {
    put,
    takeLatest,
    all,
    call,
} from 'redux-saga/effects';

import {
    portfolio_holding,
    orderbook,
} from '../../../../api';

import {
    compileQueryString,
    handleCatchSagaError,
} from '../../../../utils/util';

import {
    postSubmitPortfolio,
    getSearchCompany,
} from './Action';


function* postSubmitPortfolioResponder({ payload }){
    try {
        const { data } = yield call(portfolio_holding.postSubmitPortfolio, payload);
        yield put(postSubmitPortfolio.success(data));
    } catch(e){
        yield handleCatchSagaError(e, `postSubmitPortfolioResponder`);
        yield put(postSubmitPortfolio.failure(e));
    } finally{

    }
}

function* getSearchCompanyResponder({ payload }){
    try{
        const query_string = compileQueryString(payload.qs);
        const { data } = yield call(orderbook.getSearchCompany, query_string, payload);
        yield put(getSearchCompany.success(data));
    } catch(e){
        yield handleCatchSagaError(e, `getSearchCompanyResponder`);
        yield put(getSearchCompany.failure(e));
    } finally{

    }
}

function* saga(){
    yield all([
        takeLatest(postSubmitPortfolio.request, postSubmitPortfolioResponder),
        takeLatest(getSearchCompany.request, getSearchCompanyResponder),
    ])
}

export default saga;