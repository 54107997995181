import React from 'react';

import { content_placeholders } from '../../config';
import {
    makeStyles,
    Card,
    Grid,
    Typography,
    useMediaQuery,
} from '@material-ui/core';
import {
    globalMD,
    globalLG,
    globalXL,
} from '../styles';

const useStyles = makeStyles((theme) => ({
    container: {

    },
    Card: {
        padding: '1rem',
    },
}));

export default (props) => {
    const { loginUserData: { accessRight } } = props;
    
    const permissions = accessRight.map((item, index) => {
        const { access_right : { permission } } = item
        return permission;
    })
    const classes = useStyles();
    
    const doOpenPortal= (type) => {
        const { UpdateMyAccess } = props;
        UpdateMyAccess({ permission: type });
        const { clearAccessResponsePayload } = props;
        const path = type == content_placeholders.user_type.wealth_user ? 
                                'wealth' :  
                                type == content_placeholders.user_type.boost_user ?
                                'boost' :
                                'orderbook';
        window.open(`${path}/pipeline?ua=${type}`, '_blank');
        clearAccessResponsePayload({});
    }

    const isMobile = useMediaQuery(`(max-width:${globalMD}px)`);
    const isLG = useMediaQuery(`(max-width:${globalLG}px)`);
    const isXL = useMediaQuery(`(max-width:${globalXL}px)`);
    let itemXs = 3;
    if (isMobile) {
        itemXs = 12;
    }
    if (!isMobile && isLG) {
        itemXs = 6;
    }
    if (!isMobile && !isLG && isXL) {
        itemXs = 4;
    }
    
    return (
        <div className="home" style={{padding: '1rem'}}>
            <div className="top-padding" />
            <React.Fragment>
                <Grid container spacing={2}>
                    
                    {
                        permissions.indexOf(content_placeholders.user_type.orderbook_user) > -1 ||
                        permissions.indexOf(content_placeholders.user_type.orderbook_p1_user) > -1 ?
                            <Grid item xs={itemXs}>
                                <Card 
                                    className={classes.Card} 
                                    style={{ cursor: 'pointer' }} 
                                    onClick={() => doOpenPortal(content_placeholders.user_type.orderbook_user)}>
                                    <div
                                        style={{ height: '6rem' }}>
                                        <img 
                                            style={{ 
                                                width: 'auto', 
                                                height: '100%', 
                                                margin: '0 auto', 
                                                display: 'block', 
                                                objectFit: 'contain' }} 
                                            src={content_placeholders.logos.gateway} />
                                    </div>
                                    <Typography 
                                        variant="h6" 
                                        style={{ 
                                            lineHeight: '1rem', 
                                            height: '2rem', 
                                            overflow: 'hidden', 
                                            marginTop: '10px', 
                                            display: 'flex', 
                                            justifyContent: 'center' }}>Orderbook</Typography>
                                </Card>
                            </Grid> : ''
                    }
                    
                    {
                        permissions.indexOf(content_placeholders.user_type.wealth_user) > -1 ?
                            <Grid item xs={itemXs}>
                                <Card 
                                    className={classes.Card} 
                                    style={{ cursor: 'pointer' }} 
                                    onClick={() => doOpenPortal(content_placeholders.user_type.wealth_user)}>
                                    <div
                                        style={{ height: '6rem' }}>
                                        <img 
                                            style={{ 
                                                width: 'auto', 
                                                height: '100%', 
                                                margin: '0 auto', 
                                                display: 'block', 
                                                objectFit: 'contain' }} 
                                            src={content_placeholders.logos.wealth} />
                                    </div>
                                    <Typography 
                                        variant="h6" 
                                        style={{ 
                                            lineHeight: '1rem', 
                                            height: '2rem', 
                                            overflow: 'hidden', 
                                            marginTop: '10px', 
                                            display: 'flex', 
                                            justifyContent: 'center'}}>Wealth</Typography>
                                </Card>
                            </Grid> : ''
                    }

                    {
                        permissions.indexOf(content_placeholders.user_type.boost_user) > -1 ?
                            <Grid item xs={itemXs}>
                                <Card 
                                    className={classes.Card} 
                                    style={{ cursor: 'pointer' }} 
                                    onClick={() => doOpenPortal(content_placeholders.user_type.boost_user)}>
                                    <div
                                        style={{ 
                                            height: '6rem', 
                                            paddingTop: '10px',
                                            boxSizing: 'border-box' }}>
                                        <img 
                                            style={{ 
                                                width: 'auto', 
                                                height: '100%', 
                                                margin: '0 auto', 
                                                display: 'block', 
                                                objectFit: 'contain' }} 
                                            src={content_placeholders.logos.boost} />
                                    </div>
                                    <Typography 
                                        variant="h6" 
                                        style={{ 
                                            lineHeight: '1rem', 
                                            height: '2rem', 
                                            overflow: 'hidden', 
                                            marginTop: '10px', 
                                            display: 'flex', 
                                            justifyContent: 'center'}}>Boost</Typography>
                                </Card>
                            </Grid> : ''
                    }

                </Grid>
            </React.Fragment>
        </div>
    )
}