import {
    put,
    call,
    takeLatest,
    all,
} from 'redux-saga/effects';

import { Unauthorized } from '../../../login/Action';

import {
    postPlaceOrder,
    getSearchCompany,
    getFundingRound,
} from './Action';
import{
    getHistoricalSecondaryPricing,
}from '../../company/Action';
import {
    orderbook,
} from '../../../../api';

import {
    compileQueryString,
    handleCatchSagaError,
} from '../../../../utils/util';


function* postPlaceOrderResponder({ payload }){
    try{
        console.log( payload );
        const { order_payload } = payload;
        if( order_payload.order_type_url ){
            const query_string = compileQueryString(payload.qs);
            const order_type_url = order_payload.order_type_url;
            delete order_payload.order_type_url;
            const { data } = yield call(orderbook.postPlaceOrder, order_type_url, query_string, order_payload);
            yield put(postPlaceOrder.success(data));
            if( payload.qs && payload.qs.flag ){
                const query_string_historical_data = compileQueryString({company_id: order_payload.company_id});
                const result = yield call(orderbook.getHistoricalSecondaryPricing, 'v2/orderbook', 'weighted-prices',null, query_string_historical_data, null);
                yield put(getHistoricalSecondaryPricing.success(result.data));    
            }
        } else {
            yield put(postPlaceOrder.failure(new Error('Missing required parameter.')));
        }
    } catch(e){
        yield handleCatchSagaError(e, `orderbookpostPlaceOrderResponder`);
        yield put(postPlaceOrder.failure(e));
    } finally{

    }
}

function* getSearchCompanyResponder({ payload }){
    try{
        const query_string = compileQueryString(payload.qs);
        const { data } = yield call(orderbook.getSearchCompany, query_string, payload);
        yield put(getSearchCompany.success(data));
    } catch(e){
        yield handleCatchSagaError(e, `orderbookgetSearchCompanyResponder`);
        yield put(getSearchCompany.failure(e));
    } finally{

    }
}

function* getFundingRoundResponder({ payload }) {
    try {
        const query_string = compileQueryString(payload.qs);
        const { data } = yield call(orderbook.getFundingRound, payload.root_path, payload.sub_path, payload.company_identifier, query_string, payload);

        yield put(getFundingRound.success(data));
    } catch (e) {
        yield handleCatchSagaError(e, `orderbookPlaceOrderGetFundingRoundResponder`);
        yield put(getFundingRound.failure(e));
    } finally {

    }
}

function* saga(){
    yield all([
        takeLatest(postPlaceOrder.request, postPlaceOrderResponder),
        takeLatest(getSearchCompany.request, getSearchCompanyResponder),
        takeLatest(getFundingRound.request, getFundingRoundResponder),
    ])
}

export default saga;