import React, { Fragment } from 'react';

import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    withStyles,
    makeStyles,
} from '@material-ui/core';

import { default as OrderTooltip } from './OrderTooltip';

import { 
    HeaderTableCell, 
    StyledTableRow,
    recentCellClasses,
    ClickableTableCell,
    TighterHeaderTableCell,
    StyledHoverTableRow,
} from '../../common/Table';

import {
    numberWithCommas,
    getImpliedVal,
    convertSizeLabel,
    getDigitPadding,
    displayVal,
} from '../../../../utils/util';

import {
    feature_flags,
} from '../../../../config';

import { default as Base } from '../../common/place_order/components/BaseTooltip';
const useStyles = makeStyles((theme) => ({
    container: {
        height: 250,
    }
}));

export default (props) => {
    const classes = useStyles();
    const rcc = recentCellClasses();
    const buy_type = "sell";

    const { appConfig, loginUserData: { email } } = props;

    const clickEventHandler = feature_flags.feature_is_available(
        appConfig,
        feature_flags.flags.OrderList.flag_name,
        feature_flags.flags.OrderList.attributes.transaction_detail,
        email
    ) ? props.handleShowOrderDetailDialog : props.handlePrefillPlaceOrder;

    return (
        <TableContainer component={Paper} className={classes.container}>
            <Table stickyHeader size="small" aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <HeaderTableCell>Terms<sup>(2)</sup></HeaderTableCell>
                        <HeaderTableCell>Size</HeaderTableCell>
                        <HeaderTableCell>Implied Valuation</HeaderTableCell>
                        <HeaderTableCell>Price (US$)</HeaderTableCell>
                    </TableRow>
                </TableHead>
                <TableBody
                    // onMouseEnter={(evt) => props.handlePromptExpress(evt, true)}
                    // onMouseLeave={(evt) => props.handlePromptExpress(evt, false)}
                    >
                    {
                        props.resultList.length ?
                            props.resultList.map((row, index) => {
                                const transaction_terms = [];
                                // if(row.is_rofr_waived) transaction_terms.push('R')
                                // if(row.is_company_sponsored) transaction_terms.push('C')
                                // if(row.is_available_financial_info) transaction_terms.push('I')
                                // if(row.is_primary) transaction_terms.push('P')
                                if(row.is_info_req) transaction_terms.push('IR')
                                if(row.is_structured_equity) transaction_terms.push("SE")

                                const bid_padding = getDigitPadding(row.bid_price, props.longest_digit);
                                
                                return (
                                    <Fragment key={index}>
                                        <OrderTooltip
                                            placement="right"
                                            latest_funding_round={props.latest_funding_round}
                                            data={row}
                                            latest_average={props.latest_ave_bid}
                                            children={
                                                <StyledHoverTableRow>
                                                    <ClickableTableCell 
                                                        onClick={(evt) => clickEventHandler(evt, buy_type, row)} 
                                                        style={{width: '20%'}}>
                                                        {
                                                            row.is_invisible ?
                                                                <span className='blurred'>XXX</span> :
                                                                <span>{transaction_terms.join(',')}</span>
                                                        }
                                                    </ClickableTableCell>
                                                    <ClickableTableCell 
                                                        onClick={(evt) => clickEventHandler(evt, buy_type, row)}>
                                                            {
                                                                row.is_invisible ?
                                                                    <span className="blurred">$10M - $25M</span> :
                                                                    <span className={row.size && row.size.indexOf('<') == -1 ? 'left-gap' : ''}>
                                                                        {
                                                                            row.size ? convertSizeLabel(row.size) : row.size_range ? convertSizeLabel(row.size_range) : '-'
                                                                        }
                                                                    </span>
                                                            }
                                                    </ClickableTableCell>
                                                    <ClickableTableCell
                                                        onClick={(evt) => clickEventHandler(evt, buy_type, row)}>
                                                        {
                                                            row.is_invisible ?
                                                                <span className="blurred">$0.0B</span> :
                                                                <span>
                                                                    { 
                                                                        row.bid_price && props.latest_funding_round.price && props.latest_funding_round.valuation ?
                                                                            getImpliedVal(row.bid_price, props.latest_funding_round.price, props.latest_funding_round.valuation,1)
                                                                            : 
                                                                            row.valuation ? displayVal(row.valuation,1,1) :
                                                                            ' - '
                                                                    }
                                                                </span>
                                                        }
                                                    </ClickableTableCell>
                                                    <ClickableTableCell 
                                                        onClick={(evt) => clickEventHandler(evt, buy_type, row)}
                                                        className={rcc.dateClassName(row.updated_at, row.is_firm_order)}>
                                                            {bid_padding}
                                                            {
                                                                row.is_invisible ?
                                                                    <span className="blurred">00.00</span> :
                                                                <span>{row.bid_price ? numberWithCommas(row.bid_price) : '-'}</span>
                                                            }
                                                    </ClickableTableCell>
                                                </StyledHoverTableRow>
                                            }
                                            />
                                    </Fragment>
                                )
                            }) : (
                                <StyledHoverTableRow>
                                    <ClickableTableCell colSpan={4}
                                        onClick={(evt) => props.handlePrefillPlaceOrder(evt, 'buy', {bid_price: 0, is_primary: false})}>
                                        <p>Be the first to place a bid.</p>
                                    </ClickableTableCell>
                                </StyledHoverTableRow>
                            )
                    }
                        {
                            props.watchlist ? 
                                <StyledTableRow>
                                    <TableCell colSpan={4} style={{backgroundColor: 'rgb(231,230,230)'}}>
                                        <span style={{verticalAlign: 'text-top'}}>Watchlist Buy Interest: {displayVal(props.watchlist)}.</span>
                                        <Base 
                                            items={
                                                [
                                                    'On top of actionable bids, Gateway has received ' + displayVal(props.watchlist) +' of watchlist buy interest in this name.',
                                                ]
                                            }
                                        /> 
                                    </TableCell>
                                </StyledTableRow> : ''
                        }
                </TableBody>
            </Table>
        </TableContainer>
    )
}