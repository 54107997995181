import { put, takeLatest, all, call } from 'redux-saga/effects';

import {
    getTrends,
} from './Action';

import {
    trends,
} from '../../../api';

import {
    compileQueryString,
    handleCatchSagaError,
} from '../../../utils/util';

function* getTrendsResponder({ payload }){
    try{
        const query_string = compileQueryString(payload.qs);
        const { data } = yield call(trends.getTrends, query_string, payload);
        yield put(getTrends.success(data));
    } catch(e){
        yield handleCatchSagaError(e, 'getTrendsResponder');
        yield put(getTrends.failure(e));
    } finally{

    }
}


function* saga(){
    yield all([
        takeLatest(getTrends.request, getTrendsResponder),
    ]);
}

export default saga;