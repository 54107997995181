import React from 'react';
import {
    Box,
    Grid,
    Paper,
    Typography,
} from '@material-ui/core';

import {
    WatchlistList,
    PriceChart,
} from './components';

class WatchlistGraph extends React.Component {
    state = {
        selected_company_ids: [],
        watchlist: {
            id: 0,
            name: '',
            description: '',
            notification_required: false,
            company: []
        }
    }

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        const {
            getWatchlistCompanies,
            // getWatchlistPipeline,
            // watchlistGroupList,
            // selectedGroupAtIndex,
            // graph_payload,
            watchlistGroupItem,
            isOrderBookPortal,
        } = this.props;

        getWatchlistCompanies({ group_id: watchlistGroupItem.id,
        path: isOrderBookPortal? '/v2/orderbook' : '/v2/wealth' });

    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const { 
            watchlistGroupItem,
        } = nextProps;
        if( watchlistGroupItem.id != prevState.watchlist.id ){
            return { watchlist: watchlistGroupItem };
        } else {
            return null;
        }
    }

    handleSelectCompany = (evt, company_ids) => {
        this.setState({ selected_company_ids: company_ids });
    }

    toggleCompanySelect = (evt, company_id) => {
        const { selected_company_ids } = this.state;
        let new_selected_company_ids = selected_company_ids.filter(item => item != company_id);
        if (new_selected_company_ids.equals(selected_company_ids)) {
            new_selected_company_ids.push(company_id);
        }

        this.setState({ selected_company_ids: new_selected_company_ids });
    }

    render() {
        const {
            price_index,
            verticals,
            funding_rounds,
            pipelines,
            watchlist_companies: { results },
        } = this.props;

        const {
            selected_company_ids,
            watchlist : { company }
        } = this.state;

        return (
            <>
                <Grid item xs={12} md={7}>
                    <Paper style={{ padding: 16, width: '100%' }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant="h6">Watchlist mark to market</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <PriceChart
                                    handleSelectCompany={this.handleSelectCompany}
                                    {...{
                                        price_index,
                                        results,
                                        verticals,
                                        selected_company_ids,
                                        company,
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={5}>
                    <Paper style={{ padding: 16, width: '100%' }}>
                        <WatchlistList
                            toggleCompanySelect={this.toggleCompanySelect}
                            {...{
                                results,
                                funding_rounds,
                                pipelines,
                                selected_company_ids,
                            }}
                        />
                    </Paper>
                </Grid></>
        )
    }
}

export default WatchlistGraph;