import React, { Fragment } from "react";
import { Styles } from "../../styles";
import { PipelineSidebar } from "../../common";
import {
    Box,
    Grid,
    Button,
    Typography,
    withStyles,
    Paper,
    Card,
} from "@material-ui/core";

import {
    ProspectiveInvestors,
    RoundDetails,
    TrackVettedInvestors,
    CommittedCapitalTable,
    CompanyInfo,
} from './components';

import { content_placeholders } from '../../../config';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

class Dashboard extends React.Component {
    state = {
        tabFocusIndex: 0,
        vettedInvestors: [],
    }
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        const {
            match: { params: { id } },
            getFundraisingParameters,
            getGoalSummary,
            getProspectSummary,
            getInvestorSummary,
            getVettedInvestorList,
            getCompanyDetail,
        } = this.props;

        // getFundraisingParameters({ qs: { company_id: id } });
        // getGoalSummary({ qs: { company_id: id } });
        // getProspectSummary({ qs: { company_id: id } });
        // getInvestorSummary({ qs: { company_id: id }});
        // getVettedInvestorList({ qs: { company_id: id } });

        getFundraisingParameters({ fundraising_id: id, qs: {} } );
        getGoalSummary({ fundraising_id: id, qs: {} } );
        getProspectSummary({ fundraising_id: id, qs: {} } );
        getInvestorSummary({ fundraising_id: id, qs: {} });
        getVettedInvestorList({ fundraising_id: id , qs: {}} );
        getCompanyDetail({ 
            root_path: 'v2',
            sub_path: 'boost',
            company_identifier: id });
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const {
            vetted_investors_response,
        } = nextProps;

        if (
            vetted_investors_response &&
            vetted_investors_response.resultList
        ) {
            return {
                vettedInvestors: vetted_investors_response["resultList"],
            };
        } else {
            return null;
        }
    }

    pageGoBack = () => {
        const { history } = this.props;
        history.push(`/boost/pipeline?ua=${content_placeholders.user_type.boost_user}`);
    };

    handleSearchInvestors = (evt) => {
        console.log(evt);
        const { target: { value } } = evt;
        const { match: { params: { id } } } = this.props;
        if (value && value.trim()) {
            const { getVettedInvestorList } = this.props;
            getVettedInvestorList({ qs: { company_id: id, search_string: value } });
        } else {
            const { getVettedInvestorList } = this.props;
            getVettedInvestorList({ qs: { company_id: id } });
        }
    }

    render() {
        let {
            tabFocusIndex,
            vettedInvestors,
        } = this.state;

        const {
            prospect_summary,
            fundraising_params,
            goal_summary,
            appConfig,
            loginUserData,
            similar_companies,
            company_verticals,
            company_detail,
        } = this.props;

        const { collapseSidebar } = this.props;

        const home_right_class = collapseSidebar ? 'home-right narrow' : 'home-right';
        const paper_style = { padding: 16, width: '100%' }
        const paper_style_full_height = { padding: 16, width: '100%', height: '100%' }
        const paper_style_full_height_flex = { padding: 16, width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }
        return (
            <div className="home">
                <div className="top-padding"></div>
                <PipelineSidebar
                    tabFocusIndex={tabFocusIndex}
                    collapse={true}
                    {...this.props}
                />
                <div className={home_right_class} id="home-right">
                    <Box m={2}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Button onClick={this.pageGoBack}>
                                    <ArrowBackIcon />
                                </Button>
                            </Grid>
                            <Grid item xs={12}>
                                <CompanyInfo
                                    {...{
                                         ...company_detail,
                                        company_verticals,
                                        similar_companies,
                                        appConfig,
                                        loginUserData,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={5}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Grid container spacing={2} alignItems="stretch">
                                            <Grid item xs={12} md={4}>
                                                <Paper style={paper_style_full_height_flex}>
                                                    <p className="company-dashboard-subtitle">
                                                        Target Amount
                                                    </p>
                                                    <p className="company-dashboard-title" style={{ flex: '1', display: 'flex', alignItems: 'center' }}>
                                                        {goal_summary.goal_total
                                                            ? goal_summary.goal_total > 1000
                                                                ? "US$" +
                                                                (goal_summary.goal_total / 1000).toFixed(1) +
                                                                "B"
                                                                : "US$" +
                                                                goal_summary.goal_total.toFixed(1) +
                                                                "M"
                                                            : goal_summary.goal_total == 0 ? `US$${goal_summary.goal_total}` : ""}
                                                    </p>
                                                </Paper>
                                            </Grid>
                                            <Grid item xs={12} md={8}>
                                                <Paper style={paper_style_full_height}>
                                                    <p className="company-dashboard-subtitle">
                                                        Committed Capital
                                                    </p>
                                                    <p className="company-dashboard-title">
                                                        {
                                                            goal_summary.direct_total && goal_summary.spv_total
                                                                ? (goal_summary.direct_total + goal_summary.spv_total) > 1000
                                                                    ? "US$" +
                                                                    (parseFloat(goal_summary.direct_total.toFixed(1)) + parseFloat(goal_summary.spv_total.toFixed(1))) + "B"
                                                                    : "US$" +
                                                                    (parseFloat(goal_summary.direct_total.toFixed(1)) + parseFloat(goal_summary.spv_total.toFixed(1))) + "M"
                                                                : goal_summary.direct_total == 0 && goal_summary.spv_total == 0 ? `US$0` : ""}
                                                    </p>
                                                    <CommittedCapitalTable
                                                        {...{ goal_summary }} />
                                                </Paper>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <Paper style={paper_style}>
                                                    <ProspectiveInvestors
                                                        prospectSummary={prospect_summary} />
                                                </Paper>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Paper style={paper_style}>
                                                    <RoundDetails
                                                        fundraisingParams={fundraising_params} />
                                                </Paper>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>


                            </Grid>
                            <Grid item xs={12} md={7}>
                                <Paper style={paper_style_full_height}>
                                    <TrackVettedInvestors
                                        handleSearchInvestors={this.handleSearchInvestors}
                                        company_id={this.props.match.params.id}
                                        {...{ vettedInvestors }} />
                                </Paper>
                            </Grid>
                        </Grid>
                    </Box>
                </div>
            </div>
        )
    }
}

export default withStyles(Styles)(Dashboard);
