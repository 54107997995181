import {
    put,
    call,
    takeLatest,
    all,
} from 'redux-saga/effects';

import { Unauthorized } from '../../../../login/Action';

import {
    getSearchCompany,
    postSubmitPortfolio,
} from './Action';

import {
    getMyPortfolio,
} from '../../Action';

import {
    orderbook,
} from '../../../../../api';

import {
    NewRelicError,
} from '../../../../../utils';

import {
    logEventInternallyAndNewRelic,
} from '../../../../../utils/ga';


function* getSearchCompanyResponder({ payload }){
    try{
        let query_string = "?";
        let counter = 0;
        if( payload.qs ){
            for( var key in payload.qs ){
                if( counter ) query_string += "&";
                query_string += key+"="+payload['qs'][key];
                counter++;
            }
        }
        const { data } = yield call(orderbook.getSearchCompany, query_string, payload);
        yield put(getSearchCompany.success(data));
    } catch(e){
        if( e.message && e.response ){
            const err_payload = {
                type: 'api_failure_portfoliogetSearchCompanyResponder',
                data: {
                    config: e.response.config,
                    data: e.response.data,
                    status: e.response.status
                },
                err: new Error("API Failure portfoliogetSearchCompanyResponder: " + e.message)
            }
            logEventInternallyAndNewRelic(err_payload);
        } else if (e.message) {
            NewRelicError( new Error("API Failure portfoliogetSearchCompanyResponder: " + e.message) );
        } else {
            NewRelicError( new Error("API Failure portfoliogetSearchCompanyResponder with no message") );
        }
        if (e.response && e.response.status == 401) {
          yield put(Unauthorized(e));
        }
        yield put(getSearchCompany.failure(e));
    } finally{

    }
}

function* postSubmitPortfolioResponder({ payload }){
    try{
        yield put(postSubmitPortfolio.success(payload));
        // yield put(getMyPortfolio.request({
        //     qs: {
        //         company_ids: ( Object.keys(cost_base) ).join(",")
        //     }
        // }));
        
    } catch(e){
        if( e.message && e.response ){
            const err_payload = {
                type: 'api_failure_portfoliopostSubmitPortfolioResponder',
                data: {
                    config: e.response.config,
                    data: e.response.data,
                    status: e.response.status
                },
                err: new Error("API Failure portfoliopostSubmitPortfolioResponder: " + e.message)
            }
            logEventInternallyAndNewRelic(err_payload);
        } else if (e.message) {
            NewRelicError( new Error("API Failure portfoliopostSubmitPortfolioResponder: " + e.message) );
        } else {
            NewRelicError( new Error("API Failure portfoliopostSubmitPortfolioResponder with no message") );
        }
        if (e.response && e.response.status == 401) {
          yield put(Unauthorized(e));
        }
        yield put(postSubmitPortfolio.failure(e));
    } finally{

    }
}

function* saga(){
    yield all([
        takeLatest(getSearchCompany.request, getSearchCompanyResponder),
        takeLatest(postSubmitPortfolio.request, postSubmitPortfolioResponder),
    ])
}

export default saga;