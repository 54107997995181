import { 
    CreateApiAction as _createApiAction,
    CreateKeyAction as _createKeyAction,
} from '../../../utils';

const key = "ORDERBOOK_COMPANY";

const CreateApiAction = _createApiAction(key);

export const getCompanyDetail = CreateApiAction('GET_COMPANY_DETAIL');
export const getFundingRound = CreateApiAction('GET_FUNDING_ROUND');
export const getHistoricalSecondaryPricing = CreateApiAction('GET_HISTORICAL_SECONDARY_PRICING');
export const getReferencePricing = CreateApiAction('GET_REFERENCE_PRICING');
export const getLiveOrderbook = CreateApiAction('GET_LIVE_ORDERBOOK');
export const getLiveOffers = CreateApiAction('GET_LIVE_OFFERS');
export const getLiveDemand = CreateApiAction('GET_LIVE_DEMAND');
export const getCompanyVerticals = CreateApiAction('GET_COMPANY_VERTICALS');
export const getSimilarCompany = CreateApiAction('GET_SIMILAR_COMPANY');
export const getPermissionedDeal = CreateApiAction('GET_PERMISSIONED_DEAL');
export const toggleWatchList = CreateApiAction('TOGGLE_WATCHLIST');

const CreateKeyAction = _createKeyAction(key);
export const clearWatchlistMessage = CreateKeyAction('CLEAR_WATCHLIST_MESSAGE');

export const getLiveOffersWatchlist = CreateApiAction('GET_LIVE_OFFERS_WATCHLIST');
export const getLiveDemandWatchlist = CreateApiAction('GET_LIVE_DEMAND_WATCHLIST');

export const shareDeal = CreateApiAction('SHARE_DEAL');
export const getRiskDisclosure = CreateApiAction('GET_RISK_DISCLOSURE');
export const getSharedUser = CreateApiAction('GET_SHARED_USER');
export const addCount = CreateApiAction('ADD_COUNT');
export const confirmRiskDisclosure = CreateKeyAction('CONFIRM_RISK_DISCLOSURE');
export const getVciHistoricalRevenue = CreateApiAction('GET_VCI_HISTORICAL_REVENUE');
export const getVciCapTable = CreateApiAction('GET_VCI_CAP_TABLE');
export const getVciFundingRound = CreateApiAction('GET_VCI_FUNDING_ROUND');