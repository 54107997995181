import {
    CreateApiAction as _createApiAction,
    CreateKeyAction as _createKeyAction,
} from '../../../utils';

const key='BOOST_VETTING';

const CreateApiAction = _createApiAction(key);
const getProspectInvestorList = CreateApiAction('GET_PROSPECT_INVESTOR_LIST');
const postSubmitInvestorApproval = CreateApiAction('POST_SUBMIT_INVESTOR_APPROVAL');

const CreateKeyAction = _createKeyAction(key);
const clearRefreshData = CreateKeyAction('CLEAR_REFRESH_DATA');

export {
    getProspectInvestorList,
    postSubmitInvestorApproval,
    clearRefreshData,
}