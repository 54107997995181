import React from 'react';

import {
    Table,
    TableBody,
    TableContainer,
    TableHead,
    Grid,
    Typography,
    Card,
    makeStyles,
    Paper,
} from '@material-ui/core';

import {
    HeaderTableCell,
    StyledTableRow,
    StyledHoverTableRow,
    TableLabelStyle,
    TighterHeaderTableCell,
    TighterClickableTableCell,
} from '../../common/Table';

import {
    numberWithCommas,
    convertSizeLabel,
    displayVal,
    showBlurred,
    getImpliedVal,
} from '../../../../utils/util';

const useStyles = makeStyles((theme) => ({
    HeaderTitle: {
        fontSize: 12,
        fontWeight: 'bolder',
        textAlign: 'center',
    },
    SubTitle: {
        fontSize: 12,
        // textAlign: 'center',
        display: 'inline-block',
    },
    Card: {
        padding: 16
    },
    TightCard: {
        padding: '8px 16px'
    },
    Grid: {
        display: 'flex',
        alignItems: 'center',
    }
}));

export default (props) => {
    // console.log( props );
    const classes = TableLabelStyle();
    const custom_classes = useStyles();

    const { permissioned_deals } = props;

    const filtered_permissioned_deals = permissioned_deals.filter(item => item.offers && item.offers.length);

    return (
        <React.Fragment>
            <Typography variant="h6" className="orderbook-paper-title">Permissioned Deals</Typography>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Grid container spacing={2} style={{marginBottom: '-20px'}}>
                        <Grid item xs={2}>
                            
                        </Grid>
                        <Grid item xs={3}>
                            <span className={custom_classes.HeaderTitle} style={{marginLeft: 10}}>Company</span>
                        </Grid>
                        <Grid item xs={3}>
                            <span className={custom_classes.HeaderTitle}>
                                Deal Type
                            </span>
                        </Grid>
                        <Grid item xs={4}>
                            <span className={custom_classes.HeaderTitle} style={{marginLeft: -5}}>Implied Val.</span>
                        </Grid>
                    </Grid>
                </Grid>
                
                {
                    filtered_permissioned_deals.length ? 
                        filtered_permissioned_deals.map((item, index) => {
                            return (
                                <Grid item xs={12} key={index}>
                                    
                                    <Card
                                        className={custom_classes.Card}
                                        style={{cursor: 'pointer'}}
                                        onClick={(evt) => props.handleGoToCompanyDetail(evt, item.company_id)}
                                        >
                                            <Grid container spacing={2}>
                                                <Grid item xs={2} className={custom_classes.Grid}>
                                                    {
                                                        !showBlurred(item.company_logo_url) ?
                                                            <img src={item.company_logo_url} style={{ maxWidth: 50 }} />: ''
                                                    }
                                                    
                                                </Grid>
                                                <Grid item xs={3} className={custom_classes.Grid}>
                                                    <span className={custom_classes.SubTitle}>{item.company_name}</span>
                                                </Grid>
                                                <Grid item xs={3} className={custom_classes.Grid}>
                                                    <span className={custom_classes.SubTitle}>
                                                        {
                                                            item.offers[0].is_primary ? 'Primary' : 'Secondary'
                                                        }
                                                    </span>
                                                </Grid>
                                                <Grid item xs={4} className={custom_classes.Grid}>
                                                    <span className={custom_classes.SubTitle}>
                                                        {
                                                            item.offers.length && item.offers[0].valuation ?
                                                                `US${displayVal(item.offers[0].valuation, 0)}`
                                                            :
                                                                item.offers.length && 
                                                                item.offers[0].bid_price &&
                                                                item.offers[0].lfr_price &&
                                                                item.offers[0].post_money_val ?
                                                                    `US${getImpliedVal(item.offers[0].bid_price, item.offers[0].lfr_price, item.offers[0].post_money_val)}` :
                                                                    item.offers.length && 
                                                                    item.offers[0].bid_price &&
                                                                    item.offers[0].lfr_price &&
                                                                    item.offers[0].pre_money_val ?
                                                                    `US${getImpliedVal(item.offers[0].bid_price, item.offers[0].lfr_price, item.offers[0].pre_money_val)}` :
                                                                    '-'
                                                        }
                                                    </span>
                                                </Grid>
                                            </Grid>
                                        
                                        
                                        

                                        {/* {
                                            item.offers && item.offers.length ? 
                                                <TableContainer component={Paper} className={classes.container}>
                                                    <Table stickyHeader size="small" aria-label="simple table">
                                                        <TableHead>
                                                            <StyledTableRow>
                                                                <TighterHeaderTableCell className="price-column">
                                                                    Offer (PPS)
                                                                </TighterHeaderTableCell>
                                                                <TighterHeaderTableCell className="size-column">
                                                                    Size
                                                                </TighterHeaderTableCell>
                                                                <TighterHeaderTableCell>Terms</TighterHeaderTableCell>
                                                            </StyledTableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            { 
                                                                item.offers.map((o_item, o_index) => {
                                                                    const transaction_terms = [];
                                                                    if(o_item.is_rofr_waived) transaction_terms.push("R")
                                                                    if(o_item.is_company_sponsored) transaction_terms.push("C")
                                                                    if(o_item.is_available_financial_info) transaction_terms.push("I")
                                                                    if(o_item.is_primary) transaction_terms.push("P")
                                                                    if(o_item.is_spv && !o_item.is_gateway_spv) transaction_terms.push("S")
                                                                    if(o_item.is_gateway_spv) transaction_terms.push("G")

                                                                    return (
                                                                        <StyledHoverTableRow key={o_index}>
                                                                            <TighterClickableTableCell
                                                                                onClick={(evt) => props.handleGoToCompanyDetail(evt, item.company_id)}
                                                                                className="price-column">{o_item.price ? numberWithCommas(o_item.price) : '-'}</TighterClickableTableCell>
                                                                            <TighterClickableTableCell
                                                                                onClick={(evt) => props.handleGoToCompanyDetail(evt, item.company_id)}
                                                                                className="size-column">{o_item.deal_size_range ? convertSizeLabel(o_item.deal_size_range) : '-'}</TighterClickableTableCell>
                                                                            <TighterClickableTableCell
                                                                                onClick={(evt) => props.handleGoToCompanyDetail(evt, item.company_id)}
                                                                                >
                                                                                    <span>{transaction_terms.join(',')}</span>
                                                                                </TighterClickableTableCell>
                                                                        </StyledHoverTableRow>
                                                                    )
                                                                })
                                                            }
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer> : ''
                                        }


                                        {
                                            item.demands && item.demands.length ? 
                                                <TableContainer style={{marginTop: 16}} component={Paper} className={classes.container}>
                                                    <Table stickyHeader size="small" aria-label="simple table">
                                                        <TableHead>
                                                            <StyledTableRow>
                                                                <TighterHeaderTableCell className="price-column">
                                                                    Bid (PPS)
                                                                </TighterHeaderTableCell>
                                                                <TighterHeaderTableCell className="size-column">
                                                                    Size
                                                                </TighterHeaderTableCell>
                                                                <TighterHeaderTableCell>Terms</TighterHeaderTableCell>
                                                            </StyledTableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            { 
                                                                item.demands.map((o_item, o_index) => {
                                                                    const transaction_terms = [];
                                                                    if(o_item.is_rofr_waived) transaction_terms.push("R")
                                                                    if(o_item.is_company_sponsored) transaction_terms.push("C")
                                                                    if(o_item.is_available_financial_info) transaction_terms.push("I")
                                                                    if(o_item.is_primary) transaction_terms.push("P")
                                                                    if(o_item.is_spv && !o_item.is_gateway_spv) transaction_terms.push("S")
                                                                    if(o_item.is_gateway_spv) transaction_terms.push("G")

                                                                    return (
                                                                        <StyledHoverTableRow key={o_index}>
                                                                            <TighterClickableTableCell
                                                                                onClick={(evt) => props.handleGoToCompanyDetail(evt, item.company_id)}
                                                                                className="price-column">{o_item.price ? numberWithCommas(o_item.price) : '-'}</TighterClickableTableCell>
                                                                            <TighterClickableTableCell
                                                                                onClick={(evt) => props.handleGoToCompanyDetail(evt, item.company_id)}
                                                                                className="size-column">{o_item.deal_size_range ? convertSizeLabel(o_item.deal_size_range) : '-'}</TighterClickableTableCell>
                                                                            <TighterClickableTableCell
                                                                                onClick={(evt) => props.handleGoToCompanyDetail(evt, item.company_id)}
                                                                                >
                                                                                    <span>{transaction_terms.join(',')}</span>
                                                                                </TighterClickableTableCell>
                                                                        </StyledHoverTableRow>
                                                                    )
                                                                })
                                                            }
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer> : ''
                                        } */}
                                        
                                    </Card>
                                    
                                </Grid>
                            )
                        }) : 
                        <Grid item xs={12}>
                            <Card
                                className={custom_classes.Card}
                                style={{cursor: 'pointer'}}>
                                    <span style={{fontSize: 12}}>No record</span>
                            </Card>
                        </Grid>
                }
            </Grid>
        </React.Fragment>
    ) 
}