import React, { Component } from 'react';

import {
    Typography,
    FormControl,
    FormGroup,
    FormControlLabel,
    FormLabel,
    Checkbox,
    TextField,
    Box,
    Paper,
    Grid,
    Button,
    CircularProgress,
    Divider,
    withStyles,
} from '@material-ui/core';

import {
    ToggleButtonGroup,
    ToggleButton,
    Autocomplete,
} from '@material-ui/lab';

import {
    OrderTypeTooltip,
    ROFRTooltip,
    OnlyGPMVisibleTooltip,
} from './components';

import { Snackbar } from '../../../common';

import {
    ReScaleForDeviceScale,
    getLatestFundingRound,
    getImpliedVal,
} from '../../../../utils/util';

import {
    global150BodyFont,
    globalBodyFont,
} from '../../../styles';

import {
    sendGAEvent
} from '../../../../utils/ga';

import {
    FormStyle
} from '../FormStyle';

import {
    feature_flags,
} from '../../../../config';

class PlaceOrder extends Component{
    state = {
        open: false,
        options: [],
        loading: false,
        provide_type: 'price',
        buy_type: 'buy',
        share_class_options: [
            'Common',
            'Preferred',
            'Others',
        ],
        selected_share_class: '',
        share_class_text: '',
        selected_company: {
            company_id: undefined,
            company_name: ''
        },
        order_payload: {
            company_id: undefined,
            price: undefined,
            size: undefined,
            valuation: undefined,
            order_type: 'Indication',
            is_available_financial_info: false,
            is_rofr_waived: false,
            is_corporate_approval_obtain: false,
            is_company_sponsored: false,
            held_in_spv: false,
            share_class: '',
            invisible: false,
            primary: false,
            fee_management: undefined,
            fee_upfront: undefined,
            fee_carry_interest: undefined,
            comment: '',
        },
        snackBar: {
            open: false,
            error: false,
            message: ''
        },
        latest_funding_round: {
            price: 0,
            valuation: 0,
        },
        company_not_selectable: false,
        show_prefill_message: false,
    }

    constructor(props){
        super(props);
    }

    // componentDidMount(){
    //     const { location: { pathname }, lockCompany } = this.props;
    //     if( pathname.indexOf('/pipeline') > -1 ){
    //         lockCompany(false);
    //     } else {
    //         lockCompany(true);
    //     }
    // }

    static getDerivedStateFromProps(nextProps, prevState){
        // console.log( nextProps );
        const {
            searchCompanyResponse,
            search_company_loading,
            placeorderResponse,
            company_detail,
            lock_company,
            placeorder,
            funding_rounds,
            frs,
            company_id_from_watchlist,
        } = nextProps;

        if( search_company_loading && !prevState.loading ){
            return { loading: true }
        } else if ( !search_company_loading && prevState.loading ){
            return {
                // options: searchCompanyResponse.resultList ? 
                //             searchCompanyResponse.resultList :
                //             [],
                options: searchCompanyResponse,
                loading: false
            }
        } else if ( 
            !placeorderResponse.loading && 
            placeorderResponse.message.trim() && 
            placeorderResponse.message != prevState.snackBar.message) {
            const new_state = {
                snackBar: {
                    open: true,
                    message: placeorderResponse.message,
                    error: placeorderResponse.error,
                },
                order_payload: {
                    company_id: prevState.company_not_selectable ? prevState.selected_company.company_id : 0,
                    price: '',
                    size: '',
                    valuation: '',
                    order_type: 'Indication',
                    is_available_financial_info: false,
                    is_rofr_waived: false,
                    is_corporate_approval_obtain: false,
                    is_company_sponsored: false,
                    held_in_spv: false,
                    share_class: '',
                    invisible: false,
                    primary: false,
                    fee_management: undefined,
                    fee_upfront: undefined,
                    fee_carry_interest: undefined,
                    comment: '',
                },
                share_class_text: '',
                selected_share_class: '',
                provide_type: 'price',
                buy_type: 'buy',
            }

            if( !lock_company ){
                new_state['selected_company'] = {
                    company_id: undefined,
                    company_name: ''
                }

                new_state['options'] = [];
            }

            return new_state;
        } else if ( placeorder && placeorder.buy_type && lock_company && company_detail.id && company_detail.name ) {
            const { buy_type, provide_type, order_payload } = placeorder;
            order_payload['company_id'] = company_detail.id;
            const payload = {
                provide_type: provide_type,
                buy_type: buy_type,
                order_payload: order_payload,
                show_prefill_message: true,
            }
            if ( funding_rounds.results && funding_rounds.results.length ) {
                payload['latest_funding_round'] = getLatestFundingRound(funding_rounds);
            }
            return payload;
        } else if ( lock_company && company_detail.id && company_detail.name ){
            const { order_payload } = prevState;
            order_payload['company_id'] = company_detail.id;

            const payload = {
                selected_company: {
                    company_id: company_detail.id,
                    company_name: company_detail.name
                },
                order_payload: order_payload,
                company_not_selectable: true
            }
            
            if ( funding_rounds.results && funding_rounds.results.length ) {
                payload['latest_funding_round'] = getLatestFundingRound(funding_rounds);
            }
            
            return payload;
        } else if(lock_company && company_id_from_watchlist){
            const { order_payload } = prevState;
            order_payload['company_id'] = company_id_from_watchlist;
            const payload = {
                selected_company: {
                    company_id: company_id_from_watchlist,
                    // company_name: 
                },
                order_payload: order_payload,
                company_not_selectable: true
            }
            return payload;
        } else if ( !lock_company && prevState.company_not_selectable ){
            const { order_payload } = prevState;
            order_payload['company_id'] = undefined;
            return {
                selected_company: {
                    company_id: undefined,
                    company_name: ''
                },
                order_payload: order_payload,
                company_not_selectable: false
            }
        } else if ( !lock_company && frs.results && frs.results.length ) {
            const payload = {}
            payload['latest_funding_round'] = getLatestFundingRound(frs);
            return payload;
        } else {
            return null;
        }
    }

    handleSearchCompany = (evt) => {
        if( evt ){
            const { target: { name, value } } = evt;
            const { getSearchCompany } = this.props;
            if( value && value.trim() ){
                sendGAEvent({
                    type: 'orderbook_placeorder_search_company',
                    data: {
                        keyword: value
                    }
                });
                getSearchCompany({ qs: { search_string: value } });
            }
        }
    }

    handleSelectCompany = (evt, value) => {
        const { order_payload } = this.state;
        const { lock_company } = this.props;
        if( value && value.company_id && value.company_name ){
            order_payload['company_id'] = value.company_id;
            sendGAEvent({
                type: 'orderbook_placeorder_select_company',
                data: {
                    company_id: value.company_id
                }
            });
            this.setState({ selected_company: value, order_payload: order_payload }, () => {
                if(!lock_company){
                    const { getFundingRound } = this.props;
                    getFundingRound({ 
                        root_path: 'v2',
                        sub_path: 'orderbook',
                        company_identifier: '',
                        qs: { company_id: value.company_id, show_all: 1 }
                    });
                }
            });
        } else if ( !value ){
            this.setState({ selected_company: { company_id: 0, company_name: '' } });
        }
    }

    handleInputChange(key, evt, newValue) {
        const value_to_apply = newValue ? newValue : evt.target && evt.target.value ? evt.target.value : '';
        sendGAEvent({
            type: 'orderbook_placeorder_input_change',
            data: {
                key: value_to_apply
            }
        });
        if([
            'provide_type',
            'buy_type'
        ].indexOf(key) > -1){
            const { order_payload } = this.state;
            if( key == 'provide_type' ){
                order_payload['price'] = undefined;
                order_payload['valuation'] = undefined;
            }
            this.setState({ [key]: value_to_apply });
        } else if ( key == 'select_share_class' ) {
            const { order_payload } = this.state;
            order_payload['share_class'] = value_to_apply //['common', 'preferred'].indexOf(value_to_apply.toLowerCase()) ? value_to_apply : '';
            this.setState({ order_payload: order_payload, selected_share_class: value_to_apply });
        } else if ( key == 'share_class_text' ){
            const { order_payload } = this.state;
            // order_payload['share_class'] += " " + value_to_apply;
            this.setState({ order_payload: order_payload, share_class_text: value_to_apply });
        } else {
            const { order_payload } = this.state;
            
            if( key == "price" ){
                const { provide_type } = this.state;
                order_payload[provide_type] = Number(value_to_apply);
            } else if(key == "size"){
                order_payload[key] = Number(value_to_apply);
            }else if (
                [
                    'is_available_financial_info',
                    'is_rofr_waived',
                    'is_corporate_approval_obtain',
                    'is_company_sponsored',
                    'invisible',
                    'held_in_spv',
                    'primary'
                ].indexOf(key) > -1
            ){
                const value_to_bool = typeof value_to_apply == "string" ? value_to_apply.trim() ? true : false : value_to_apply;
                order_payload[key] = value_to_bool;
            } else {
                order_payload[key] = value_to_apply;
            }
            
            this.setState({ order_payload: order_payload });
        }

        const { handleAlertNeeded } = this.props;
        
        if( handleAlertNeeded ){
            handleAlertNeeded();
        }
    }

    handleOpen( open, evt ) {
        this.setState({ open: open });
    }

    handleSubmitOrder = (evt) => {
        const { order_payload, provide_type, buy_type, share_class_text } = this.state;

        
        
        let error_message = '';
        if( !order_payload.company_id ){
            error_message += 'Please provide us the company you are submitting an order for.<br />';
        }

        if( !order_payload.size ){
            error_message += 'Please provide us your target size.<br />';
        }

        if( order_payload.order_type.toLowerCase() != "interest" ){
            const target_level = provide_type == "valuation" ? order_payload['valuation'] : order_payload['price'];

            if( !target_level ){
                error_message += 'Please provide either a target price or valuation.<br />';
            }
        }
        
        if( !error_message.trim() ){
            if(['preferred', 'others'].includes(order_payload['share_class'].toLowerCase()) && share_class_text){
                order_payload['share_class'] += " " + share_class_text;
            }
            if( provide_type == 'valuation' ){
                // delete order_payload['price'];
                order_payload['price'] = null;
                // console.log( order_payload['valuation'] );
                if( order_payload['order_type'] == 'Interest' && !order_payload['valuation'] ) order_payload['valuation']=null;
            } else {
                // delete order_payload['valuation'];
                order_payload['valuation'] = null;
                // console.log( order_payload['price'] );
                if( order_payload['order_type'] == 'Interest' && !order_payload['price'] ) order_payload['price']=null
            }

            if( order_payload['held_in_spv'] ){
                order_payload['investment_type'] = "SPV"
            } else {
                order_payload['investment_type'] = "Direct";
            }

            delete order_payload['held_in_spv'];

            const order_type_url = buy_type == "buy" ? "demand" : "offer";
            
            if( buy_type == 'buy' ){
                // delete order_payload['fee_management'];
                // delete order_payload['fee_upfront'];
                // delete order_payload['fee_carry_interest'];
                order_payload['fee_management'] = null;
                order_payload['fee_upfront'] = null;
                order_payload['fee_carry_interest'] = null;
            } else {
                if( order_payload['fee_management'] === undefined ) {
                    order_payload['fee_management'] = 0;
                }else{
                    order_payload['fee_management'] = Number(order_payload['fee_management']);
                }
                if( order_payload['fee_upfront'] === undefined ) {
                    order_payload['fee_upfront'] = 0;
                }else{
                    order_payload['fee_upfront'] =  Number(order_payload['fee_upfront']);
                }
                if( order_payload['fee_carry_interest'] === undefined ) {
                    order_payload['fee_carry_interest'] = 0;
                }else {
                    order_payload['fee_carry_interest'] = Number(order_payload['fee_carry_interest']);
                }
            }

            const { postPlaceOrder } = this.props;
            // const post_payload = {
            //     // order_type_url: order_type_url,
            //     qs: order_payload,
            // };

            // if( order_payload['comment'].trim() ){
            //     post_payload['comment'] = order_payload['comment'].trim();
            //     delete order_payload['comment'];
            // }

            order_payload['order_type_url'] = order_type_url;
            // console.log( order_payload );
            // postPlaceOrder(post_payload);
            const empty_string_to_null = ['share_class', 'comment'];
            empty_string_to_null.forEach(item => {
                if( !order_payload[item].trim() ) order_payload[item] = null;
            })

            
            order_payload['size'] = parseFloat(order_payload['size'])
            if( order_payload['valuation'] ) order_payload['valuation'] = parseFloat( order_payload['valuation'] )
            if( order_payload['price'] ) order_payload['price'] = parseFloat( order_payload['price'] );

            
            const {
                appConfig,
                loginUserData: { email },
            } = this.props;
    
            const flag_payload = feature_flags.extract_payload(
                appConfig,
                feature_flags.flags.SecondaryActivity.flag_name,
                feature_flags.flags.SecondaryActivity.attributes.real_time_permission,
                email
            )
            
            const post_payload = {
                order_payload: order_payload,
                qs: {}
            }

            if( 
                flag_payload.real_time_permission && 
                flag_payload.real_time_permission.company_ids &&
                flag_payload.real_time_permission.company_ids.indexOf(order_payload['company_id']) > -1 ){
                    post_payload['qs']['flag'] = true;
                }

            postPlaceOrder(post_payload);
        } else {
            this.setState({
                snackBar: {
                    error: true,
                    open: true,
                    message: error_message
                }
            });
        }
        
    }

    handleSnackbarClose = (evt, reason) => {
        if ( reason === "clickaway") {
            return;
        }

        const { clearPlaceOrderMsg } = this.props;
        clearPlaceOrderMsg();
        this.setState({
            snackBar: {
                error: false,
                open: false,
                message: ''
            }
        })
    }

    render(){
        const { 
            open, 
            options,
            provide_type,
            buy_type,
            order_payload,
            snackBar,
            share_class_options,
            selected_share_class,
            share_class_text,
            company_not_selectable,
            selected_company,
            show_prefill_message,
            latest_funding_round,
        } = this.state;

        // console.log( latest_funding_round );

        const { 
            search_company_loading, 
            classes,
            placeorder,
            lock_company,
            
        } = this.props;
        
        const price_label = provide_type == 'valuation' ? "Valuation (US$M)" : "Price (US$)";

        return (
            <section className={placeorder && placeorder.buy_type ? classes.BoxDarker : classes.Box}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Box>
                            <Typography variant="h6">Express Interest</Typography>
                            <p className="elaborate-text">Indicate your interest levels in order to receive automated market intelligence notifications and follow up from the Gateway team.</p>
                            {
                                show_prefill_message ? 
                                    <p className='placeorder-msg'>
                                        Thank you for expressing your {buy_type} interest. 
                                        Kindly also fill in your {buy_type == 'buy' ? 'target investment size and other conditions' : 'offer size and other details'} and click "Submit".</p> 
                                    : ''
                            }
                        </Box>
                    </Grid>

                    {
                        !company_not_selectable && !lock_company ? <Grid item xs={12}>
                                <Autocomplete 
                                    id="company-name-input"
                                    open={open}
                                    onOpen={this.handleOpen.bind(this, true)}
                                    onClose={this.handleOpen.bind(this, false)}
                                    onInputChange={this.handleSearchCompany}
                                    onChange={this.handleSelectCompany}
                                    getOptionSelected={(option, value) => option.company_name === value.company_name}
                                    getOptionLabel={(option) => option.company_name}
                                    options={options}
                                    loading={search_company_loading}
                                    value={selected_company}
                                    placeholder="Company Name"
                                    size="small"
                                    renderInput={(params) => {
                                        return (
                                            <TextField
                                                {...params}
                                                // label="Company Name"
                                                placeholder="Company Name"
                                                className={classes.TextField}
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                                value={selected_company.company_name}
                                                variant="outlined"
                                                InputProps={{
                                                    ...params.InputProps,
                                                    endAdornment: (
                                                        <React.Fragment>
                                                            {search_company_loading ? <CircularProgress color="inherit" size={20} /> : null}
                                                            {params.InputProps.endAdornment}
                                                        </React.Fragment>
                                                    ),
                                                }}
                                                />
                                        )
                                    }}
                                    />
                            </Grid> : ''
                    }

                    <Grid item xs={12}>
                        <FormLabel 
                            // style={{ float: 'left', display: 'inline-block', width: 'auto' }} 
                            component="legend" 
                            className={classes.FormLabel}>Type</FormLabel>
                        <ToggleButtonGroup 
                            className={classes.ToggleButtonGroup}
                            exclusive 
                            value={buy_type} 
                            onChange={this.handleInputChange.bind(this, 'buy_type')}>
                            <ToggleButton className={classes.ToggleButton} value="buy" aria-label="left aligned">
                                Buy
                            </ToggleButton>
                            <ToggleButton className={classes.ToggleButton} value="sell" aria-label="right aligned">
                                Sell
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </Grid>

                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <FormLabel 
                                    style={{ float: 'left', display: 'inline-block', width: 'auto' }} 
                                    component="legend" 
                                    className={classes.FormLabel}>Interest Level</FormLabel>
                                <OrderTypeTooltip
                                    style={{ marginLeft: 5, float: 'left', padding: 0 }}
                                    />
                                <div className='clear'></div>
                                <ToggleButtonGroup 
                                    className={classes.ToggleButtonGroup}
                                    exclusive 
                                    value={order_payload.order_type} 
                                    onChange={this.handleInputChange.bind(this, 'order_type')}>
                                    {/* <ToggleButton className={classes.ToggleButton} value="Interest" aria-label="interest">
                                        Interest
                                    </ToggleButton> */}
                                    <ToggleButton className={classes.ToggleButton} value="Indication" aria-label="indication">
                                        Indication
                                    </ToggleButton>
                                    <ToggleButton className={classes.ToggleButton} value="Firm" aria-label="firm">
                                        Firm
                                    </ToggleButton>
                                </ToggleButtonGroup>
                                <Divider style={{ marginTop: 16 }} />
                            </Grid>
                        </Grid>
                    </Grid>
                    
                    <Grid item xs={12}>
                        <FormLabel 
                            component="legend" 
                            className={classes.FormLabel}>Price</FormLabel>
                        <ToggleButtonGroup 
                            className={classes.ToggleButtonGroup}
                            exclusive 
                            value={provide_type} 
                            onChange={this.handleInputChange.bind(this, "provide_type")}>
                            <ToggleButton className={classes.TighterToggleButton} value="valuation" aria-label="left aligned">
                                Valuation Amount
                            </ToggleButton>
                            <ToggleButton className={classes.TighterToggleButton} value="price" aria-label="right aligned">
                                Price Per Share
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField 
                            id="price-input"
                            // label={price_label}
                            placeholder={price_label}
                            name="price"
                            variant="outlined"
                            className={classes.TextField}
                            InputLabelProps={{
                                shrink: true
                            }}
                            size="small"
                            type="number"
                            value={provide_type == "valuation" ? order_payload.valuation : order_payload.price}
                            onChange={this.handleInputChange.bind(this, 'price')}
                            />
                    </Grid>
                    {
                        latest_funding_round.price && latest_funding_round.valuation && provide_type == 'price' ?
                            <Grid item xs={12}>
                                <Box style={{ textAlign: 'center' }}>
                                        <span style={{ fontSize: 12 }}>Implied Valuation (est.): </span>
                                        { order_payload.price ? 
                                            <span style={{ fontSize: 12 }}>
                                                {getImpliedVal(order_payload.price, latest_funding_round.price, latest_funding_round.valuation)}
                                            </span> : <span style={{opacity: 0}}>000000</span> } 
                                </Box>
                            </Grid> : ''
                    }
                    <Grid item xs={12}>
                        <FormLabel 
                            component="legend" 
                            className={classes.FormLabel}>Size</FormLabel>
                        <TextField 
                            id="size-input"
                            name="size"
                            // label="Size (US$M)"
                            placeholder="Size (US$M)"
                            variant="outlined"
                            className={classes.TextField}
                            value={order_payload.size}
                            size="small"
                            type="number"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={this.handleInputChange.bind(this, 'size')}
                            />
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={0}>
                            <Grid item xs={12}>
                                <FormLabel 
                                    component="legend" 
                                    className={classes.FormLabel}>Share Class</FormLabel>
                            </Grid>
                            <Grid item xs={12}>
                                <ToggleButtonGroup 
                                    className={classes.ToggleButtonGroup}
                                    exclusive 
                                    value={selected_share_class} 
                                    onChange={this.handleInputChange.bind(this, "select_share_class")}>
                                    {
                                        share_class_options.map((item, index) => {
                                            return (
                                                <ToggleButton className={classes.ToggleButton} value={item} key={index}>{item}</ToggleButton>
                                            )
                                        })
                                    }
                                </ToggleButtonGroup>
                                <Divider style={{ marginTop: 16 }} />
                            </Grid>
                            
                                {
                                    ['preferred', 'others'].indexOf( selected_share_class.toLowerCase() ) > -1 ?
                                    <Grid item xs={12} style={{marginTop: 8}}>
                                        <TextField
                                            id="share-class-input"
                                            placeholder={selected_share_class.toLowerCase() == "preferred" ? "Series (E.g. A, B , C)" : "Share class"}
                                            variant="outlined"
                                            size="small"
                                            className={classes.TextField}
                                            value={share_class_text}
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            onChange={this.handleInputChange.bind(this, 'share_class_text')}
                                            />
                                        </Grid> : ''
                                }
                            
                        </Grid>
                        
                        
                    </Grid>
                    {
                        buy_type=='sell' ? <Grid item xs={12}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <FormControl required component="fieldset" size="small">
                                            <FormLabel component="legend" className={classes.FormLabel}>Transaction Terms</FormLabel>
                                            <p style={{fontSize: 10, fontStyle: 'italic'}}>Providing the following information may help attract more and higher bids.</p>
                                            <FormGroup>
                                                <FormControlLabel
                                                    className={classes.FormControlLabel}
                                                    control={
                                                        <Checkbox 
                                                            checked={order_payload.is_available_financial_info}
                                                            name="is_available_financial_info" 
                                                            onChange={this.handleInputChange.bind(this, "is_available_financial_info")} />
                                                    }
                                                    label={<Typography className={classes.FormControlLabel}>{"Information available"}</Typography>}
                                                    />
                                                <FormControlLabel
                                                    className={classes.FormControlLabel}
                                                    control={
                                                        <Checkbox 
                                                            checked={order_payload.is_rofr_waived}
                                                            name="is_rofr_waived" 
                                                            onChange={this.handleInputChange.bind(this, "is_rofr_waived")} />
                                                        }
                                                    label={
                                                        <Typography className={classes.FormControlLabel}>{"ROFR waived or not required"}<ROFRTooltip /></Typography>}
                                                    />
                                                <FormControlLabel
                                                    className={classes.FormControlLabel}
                                                    control={
                                                        <Checkbox 
                                                            checked={order_payload.is_corporate_approval_obtain}
                                                            name="is_corporate_approval_obtain" 
                                                            onChange={this.handleInputChange.bind(this, "is_corporate_approval_obtain")} />
                                                        }
                                                    label={
                                                        <Typography className={classes.FormControlLabel}>{"Corporate approval obtained or not required"}</Typography>}
                                                    />
                                                <FormControlLabel
                                                    className={classes.FormControlLabel}
                                                    control={
                                                        <Checkbox 
                                                            checked={order_payload.is_company_sponsored}
                                                            name="is_company_sponsored" 
                                                            onChange={this.handleInputChange.bind(this, "is_company_sponsored")} />
                                                        }
                                                    label={<Typography className={classes.FormControlLabel}>{"Company sponsored"}</Typography>}
                                                    />
                                                <FormControlLabel
                                                    className={classes.FormControlLabel}
                                                    control={
                                                        <Checkbox 
                                                            checked={order_payload.primary}
                                                            name="primary" 
                                                            onChange={this.handleInputChange.bind(this, "primary")} />
                                                        }
                                                    label={<Typography className={classes.FormControlLabel}>{"Primary Fundraising"}</Typography>}
                                                    />
                                                <FormControlLabel
                                                    className={classes.FormControlLabel}
                                                    control={
                                                        <Checkbox 
                                                            checked={order_payload.held_in_spv}
                                                            name="held_in_spv" 
                                                            onChange={this.handleInputChange.bind(this, "held_in_spv")} />
                                                        }
                                                    label={<Typography className={classes.FormControlLabel}>{"Shares held in SPV"}</Typography>}
                                                    />
                                                {
                                                    order_payload.held_in_spv ? 
                                                        (
                                                            <Grid container spacing={2}>
                                                                <Grid item xs={4}>
                                                                    <TextField
                                                                        id="fee-management-input"
                                                                        size="small"
                                                                        // label="Management fee"
                                                                        placeholder="Management fee"
                                                                        variant="outlined"
                                                                        className={classes.TextField}
                                                                        value={order_payload.fee_management}
                                                                        type="number"
                                                                        InputLabelProps={{
                                                                            shrink: true
                                                                        }}
                                                                        onChange={this.handleInputChange.bind(this, 'fee_management')}
                                                                        />
                                                                </Grid>
                                                                <Grid item xs={4}>
                                                                    <TextField
                                                                        id="fee-upfront-input"
                                                                        size="small"
                                                                        // label="Upfront fee"
                                                                        placeholder="Upfront fee"
                                                                        variant="outlined"
                                                                        className={classes.TextField}
                                                                        value={order_payload.fee_upfront}
                                                                        type="number"
                                                                        InputLabelProps={{
                                                                            shrink: true
                                                                        }}
                                                                        onChange={this.handleInputChange.bind(this, 'fee_upfront')}
                                                                        />
                                                                </Grid>
                                                                <Grid item xs={4}>
                                                                    <TextField
                                                                        id="fee-carry-input"
                                                                        size="small"
                                                                        // label="Carry interest"
                                                                        placeholder="Carry interest"
                                                                        variant="outlined"
                                                                        className={classes.TextField}
                                                                        value={order_payload.fee_carry_interest}
                                                                        type="number"
                                                                        InputLabelProps={{
                                                                            shrink: true
                                                                        }}
                                                                        onChange={this.handleInputChange.bind(this, 'fee_carry_interest')}
                                                                        />
                                                                </Grid>
                                                            </Grid>
                                                        ) : ''
                                                }
                                                
                                            </FormGroup>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Grid> : ''
                    }
                    <Grid item xs={12}>
                        <FormControl required component="fieldset">
                            <FormGroup>
                                <FormControlLabel
                                    className={classes.FormControlLabel}
                                    control={<Checkbox name="invisible" onChange={this.handleInputChange.bind(this, "invisible")} />}
                                    label={<Typography className={classes.FormControlLabel}>{"Only visible to Gateway"}<OnlyGPMVisibleTooltip /></Typography>}
                                    />
                            </FormGroup>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id='comment'
                            // label='Comment'
                            placeholder='Additional notes relating to your order.'
                            variant="outlined"
                            multiline={true}
                            rows={4}
                            rowsMax={4}
                            className={classes.TextField}
                            value={order_payload.comment}
                            size="small"
                            fullWidth
                            InputLabelProps={{
                                shrink: true
                            }}
                            onChange={this.handleInputChange.bind(this, 'comment')} 
                            />
                    </Grid>
                    <Grid item xs={12}>
                        <Button 
                            className="btn-submit blue-btn"
                            style={{ fontSize: ReScaleForDeviceScale(1.5, global150BodyFont, globalBodyFont) }}
                            onClick={this.handleSubmitOrder}>
                                Submit
                        </Button>
                        <p className="elaborate-text" style={{marginTop: 10}}>Submitting an expression of interest is not straight through execution, you will receive further communication from the Gateway team.</p>
                    </Grid>
                </Grid>
                    

                <Snackbar
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center'
                    }}
                    openStyle={snackBar.open}
                    handleSnackbarClose={this.handleSnackbarClose}
                    type={snackBar.error ? "error" : "success"}
                    message={snackBar.message}
                    upTime={10000}
                    />
                
            </section>
        )
    }
}

export default withStyles(FormStyle)(PlaceOrder);