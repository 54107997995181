import { 
    CreateApiAction as _createApiAction, 
    CreateKeyAction as _createKeyAction 
} from '../../utils';

const key = "PIPELINE";

const CreateApiAction = _createApiAction(key);
const CreateKeyAction = _createKeyAction(key);

const getPipeline = CreateApiAction('GET_PIPELINE');
const clearPopUp = CreateKeyAction('CLEAR_POPUP');
const expressInterest = CreateApiAction('EXPRESS_INTEREST');
const resetExpressInterest = CreateKeyAction('RESET_EXPRESS_INTEREST');
const headquaterCountry = CreateApiAction('HEADQUATER_COUNTRY');
const verticals = CreateApiAction('VERTICALS');


const getAdBanner1 = CreateApiAction('GET_AD_BANNER1');

export {
    getPipeline,
    clearPopUp,
    expressInterest,
    headquaterCountry,
    verticals,
    resetExpressInterest,
    getAdBanner1
};