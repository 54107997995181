import React, { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = "/pdf.min.js";

export default function PdfDocument(props) {
	const [numPages, setNumPages] = useState(null);

	function onDocumentLoadSuccess({ numPages }) {
		setNumPages(numPages);
	}

	function onDocumentItemClick({ pageNumber }){
		// console.log( pageNumber );
	}

	const { pdf } = props;
	// console.log( pdfjs.version );
	
	return (
		<div className="pdf-wrapper">
			<Document
				file={pdf}
				// options={{ workerSrc: `//cdn.jsdelivr.net/npm/pdfjs-dist@2.5.207/build/pdf.worker.js` }}
				onLoadSuccess={onDocumentLoadSuccess}
				onItemClick={onDocumentItemClick}>
				{Array.from(new Array(numPages), (el, index) => (
					<Page key={`page_${index + 1}`} pageNumber={index + 1} scale={2.0} />
				))}
			</Document>
		</div>
	);
}
