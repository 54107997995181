import React from 'react';

import {
    Grid,
    Box,
    Typography,
    withStyles,
    TextField,
    CircularProgress,
    FormLabel,
    Select,
    MenuItem,
    Divider,
    Button,
} from '@material-ui/core';

import {
    Autocomplete,
} from '@material-ui/lab';

import {
    sendGAEvent,
} from '../../../../utils/ga';

import {
    FormStyle,
} from '../../../orderbook/common/FormStyle';

import {
    ReScaleForDeviceScale,
} from '../../../../utils/util';

import {
    global150BodyFont,
    globalBodyFont,
} from '../../../styles';

import { Snackbar } from '../../../common';

class PlaceInterest extends React.Component{
    state = {
        open: false,
        options: [],
        loading: false,
        order_payload: {
            company_id: undefined,
            price: '',
            size: '',
            valuation: undefined,
            order_type: 'Indication',
            is_available_financial_info: false,
            is_rofr_waived: false,
            is_corporate_approval_obtain: false,
            is_company_sponsored: false,
            held_in_spv: false,
            share_class: '',
            invisible: false,
            primary: false,
            fee_management: undefined,
            fee_upfront: undefined,
            fee_carry_interest: undefined,
            comment: '',

            // company: null,
            // company_name: '',
            // price: undefined,
            // notional: undefined,
            // comment: '',
            // note: '',
            // firm_interest: false,
            // interest_type: 'INVEST'
        },
        selected_company: {
            company_id: undefined,
            company_name: ''
        },
        snackBar: {
            error: false,
            open: false,
            message: ''
        }
    }

    constructor(props){
        super(props);
    }

    static getDerivedStateFromProps(nextProps, prevState){
        const {
            searchCompanyResponse,
            postPlaceInterestResponse,
            search_company_loading,
            no_match,
            placeorder,
            lock_company,
        } = nextProps;

        // console.log( placeorder );

        if( search_company_loading && !prevState.loading ){
            return { loading: true }
        } else if ( !search_company_loading && prevState.loading ){
            const payload = {
                options: searchCompanyResponse,
                loading: false,
            }

            if( no_match ){
                // console.log(prevState.selected_company);
                const { selected_company, order_payload } = prevState;
                order_payload['company_name'] = selected_company['company_name'];
                payload['order_payload'] = order_payload;
            }

            return payload;
        } else if (
            !postPlaceInterestResponse.loading &&
            postPlaceInterestResponse.message.trim() &&
            postPlaceInterestResponse.message != prevState.snackBar.message
        ){
            const new_state = {
                snackBar: {
                    open: true,
                    message: postPlaceInterestResponse.message,
                    error: postPlaceInterestResponse.error
                },
                order_payload: {
                    company_id: undefined,
                    price: '',
                    size: '',
                    valuation: undefined,
                    order_type: 'Indication',
                    is_available_financial_info: false,
                    is_rofr_waived: false,
                    is_corporate_approval_obtain: false,
                    is_company_sponsored: false,
                    held_in_spv: false,
                    share_class: '',
                    invisible: false,
                    primary: false,
                    fee_management: undefined,
                    fee_upfront: undefined,
                    fee_carry_interest: undefined,
                    comment: '',

                    // company: null,
                    // company_name: '',
                    // price: undefined,
                    // notional: undefined,
                    // comment: '',
                    // note: '',
                    // firm_interest: false,
                    // interest_type: 'INVEST'
                }
            }

            if( !lock_company ){
                new_state['selected_company'] = {
                    company_id: undefined,
                    company_name: '',
                }
            }

            return new_state;
        } else if (
            placeorder.company_id && 
            !prevState.order_payload.company &&
            !prevState.selected_company.company_id 
            ) {
            const new_state = {
                order_payload: {
                    company_id: placeorder.company_id,
                    price: placeorder.price,
                    size: undefined,
                    valuation: undefined,
                    order_type: 'Indication',
                    is_available_financial_info: false,
                    is_rofr_waived: false,
                    is_corporate_approval_obtain: false,
                    is_company_sponsored: false,
                    held_in_spv: false,
                    share_class: '',
                    invisible: false,
                    primary: false,
                    fee_management: undefined,
                    fee_upfront: undefined,
                    fee_carry_interest: undefined,
                    comment: '',

                    // company: placeorder.company_id,
                    // company_name: placeorder.company_name,
                    // price: placeorder.price,
                    // notional: 0,
                    // comment: '',
                    // note: '',
                    // firm_interest: false,
                    // interest_type: 'INVEST'
                },
                selected_company: {
                    company_id: placeorder.company_id,
                    company_name: placeorder.company_name
                }
            }
            return new_state;
        } else {
            return null;
        }
    }

    shouldComponentUpdate(nextProps, nextState){
        if( 
            !nextProps.search_company_loading && 
            nextProps.no_match && 
            !this.props.no_match
        ){
            const { clearNoMatch } = this.props;
            clearNoMatch();
        }

        return true;
    }

    handleOpen = (open, evt) => {
        this.setState({ open: open });
    }

    handleSearchCompany = (evt) => {
        if( evt ){
            const { target: { name, value } } = evt;
            const { getSearchCompany } = this.props;
            if( value && value.trim() ){
                sendGAEvent({
                    type: 'wealth_placeinterest_search_company',
                    data: {
                        keyword: value
                    }
                });
                getSearchCompany({ qs: { search_string: value } });
                
                const { selected_company, order_payload, options } = this.state;
                selected_company['company_name'] = value;
                const matched_company = options.filter(item => item.company_name.toLowerCase() == value.toLowerCase());
                // order_payload['company_name'] = value;
                if( matched_company.length ){
                    order_payload['company_id'] = matched_company[0].company_id;
                } else {
                    order_payload['company_id'] = null;
                }
                this.setState({ selected_company, order_payload })
            }
        }
    }

    handleSelectCompany = (evt, value) => {
        const { order_payload } = this.state;
        // console.log( value );
        
        // const { lock_company } = this.props;
        if( value && value.company_id && value.company_name ){
            order_payload['company_id'] = value.company_id;

            if( 'company_name' in order_payload ) delete order_payload['company_name'];
            
            sendGAEvent({
                type: 'wealth_placeinterest_select_company',
                data: {
                    company_id: value.company_id
                }
            });

            // console.log( order_payload );
            this.setState({ selected_company: value, order_payload: order_payload });
            // this.setState({ selected_company: value, order_payload: order_payload }, () => {
            //     if(!lock_company){
            //         const { getFundingRound } = this.props;
            //         getFundingRound({ company_id: value.company_id });
            //     }
            // });
        } else if ( !value ){
            this.setState({ selected_company: { company_id: 0, company_name: '' } });
        }
    }

    handleInputChange = (key, evt, newValue) => {
        const value_to_apply = newValue ? newValue : evt.target && evt.target.value ? evt.target.value : '';

        // console.log( key );
        // console.log( value_to_apply );
        
        sendGAEvent({
            type: 'wealth_placeinterest_input_change',
            data: {
                key: value_to_apply
            }
        });

        const { order_payload } = this.state;
        order_payload[key] = value_to_apply;

        this.setState({ order_payload: order_payload });
    }

    handleChangeValue = (key, evt) => {
        const { target: { value } } = evt;
        const { order_payload } = this.state;
        order_payload[key] = value;
        this.setState({ order_payload });
    }

    handleSubmitInterest = (evt) => {
        const { order_payload } = this.state;
        const { postPlaceInterest } = this.props;

        // console.log( order_payload );

        let error_message = '';
        if( !order_payload.company_id ){
            error_message += 'Please provide us the company you are submitting an order for.<br />';
        }

        if( !order_payload.size ){
            error_message += 'Please provide us your target size.<br />';
        }

        if( order_payload.order_type.toLowerCase() != "interest" ){
            // const target_level = provide_type == "valuation" ? order_payload['valuation'] : order_payload['price'];
            const target_level = order_payload['price'];
            if( !target_level ){
                error_message += 'Please provide either a target price or valuation.<br />';
            }
        }

        if( !error_message.trim() ){
            order_payload['valuation'] = null;
            // console.log( order_payload['price'] );
            if( order_payload['order_type'] == 'Interest' && !order_payload['price'] ) order_payload['price']=null
            
            // if( provide_type == 'valuation' ){
            //     order_payload['price'] = null;
            //     // console.log( order_payload['valuation'] );
            //     if( order_payload['order_type'] == 'Interest' && !order_payload['valuation'] ) order_payload['valuation']=null;
            // } else {
            //     order_payload['valuation'] = null;
            //     // console.log( order_payload['price'] );
            //     if( order_payload['order_type'] == 'Interest' && !order_payload['price'] ) order_payload['price']=null
            // }

            order_payload['investment_type'] = "Direct";
            
            order_payload['fee_management'] = null;
            order_payload['fee_upfront'] = null;
            order_payload['fee_carry_interest'] = null;
            order_payload['order_type_url'] = 'demand';

            const empty_string_to_null = ['share_class', 'comment'];
            empty_string_to_null.forEach(item => {
                if( !order_payload[item].trim() ) order_payload[item] = null;
            })

            if( order_payload['price'] ) order_payload['price'] = parseFloat( order_payload['price'] );
            if( order_payload['size'] ) order_payload['size'] = parseFloat( order_payload['size'] );
            if( order_payload['valuation'] ) order_payload['valuation'] = parseFloat( order_payload['valuation'] );
            delete order_payload['held_in_spv'];

            postPlaceInterest(order_payload);
        } else {
            this.setState({
                snackBar: {
                    error: true,
                    open: true,
                    message: error_message,
                }
            })
        }

    }

    handleSnackbarClose = (evt, reason) => {
        if( reason === "clickaway" ){
            return;
        }

        const { clearPlaceInterestMsg } = this.props;
        clearPlaceInterestMsg();
        this.setState({
            snackBar: {
                error: false,
                open: false,
                message: '',
            }
        })
    }

    render(){
        const {
            classes,
            search_company_loading,
        } = this.props;

        const { 
            open, 
            options, 
            order_payload, 
            snackBar,
            selected_company,
        } = this.state;

        // console.log( order_payload );

        return (
            <section className={classes.Box}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Box>
                            <Typography variant="h6">Express Interest</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Autocomplete
                            // freeSolo
                            id="express-interest-input"
                            open={open}
                            onOpen={(evt) => this.handleOpen(true, evt)}
                            onClose={(evt) => this.handleOpen(false, evt)}
                            onInputChange={this.handleSearchCompany}
                            onChange={this.handleSelectCompany}
                            getOptionSelected={(option, value) => option.company_name === value.company_name}
                            getOptionLabel={(option) => option.company_name}
                            options={options}
                            value={selected_company}
                            loading={search_company_loading}
                            size="small"
                            renderInput={(params) => {
                                return (
                                    <TextField
                                        {...params}
                                        placeholder="Company Name"
                                        className={classes.TextField}
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        variant="outlined"
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: (
                                                <React.Fragment>
                                                    {search_company_loading ? <CircularProgress color="inherit" size={20} /> : null}
                                                    {params.InputProps.endAdornment}
                                                </React.Fragment>
                                            )
                                        }}
                                        />
                                )
                            }}
                            />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            name="price"
                            variant="outlined"
                            className={classes.TextField}
                            value={order_payload.price}
                            InputLabelProps={{
                                shrink: true
                            }}
                            size="small"
                            placeholder="Price"
                            onChange={this.handleInputChange.bind(this, 'price')}
                            />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            name="size"
                            variant="outlined"
                            className={classes.TextField}
                            value={order_payload.size}
                            InputLabelProps={{
                                shrink: true
                            }}
                            size="small"
                            placeholder="Size (US$M)"
                            onChange={this.handleInputChange.bind(this, 'size')}
                            />
                    </Grid>
                    <Grid item xs={12}>
                        <FormLabel
                            component="legend"
                            className={classes.FormLabel}
                            >
                                Express Interest
                        </FormLabel>
                    </Grid>
                    <Grid item xs={12}>
                        <Select
                            value={order_payload.order_type}
                            size="small"
                            variant='outlined'
                            style={{ height: 35, fontSize: 12 }}
                            onChange={(evt) => this.handleChangeValue('order_type', evt)}
                            >
                            <MenuItem style={{ fontSize: 12 }} value={'Indication'}>Interested, want to find out more</MenuItem>
                            <MenuItem style={{ fontSize: 12 }} value={'Firm'}>Yes, I am ready to sign binding mandate</MenuItem>
                        </Select>
                        <Divider style={{marginTop: 16}} />
                    </Grid>
                    <Grid item xs={12}>
                        <FormLabel
                            component="legend"
                            className={classes.FormLabel}
                            >
                                Are there any questions you need answers for to confirm your interest?
                            </FormLabel>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            name="comment"
                            variant="outlined"
                            className={classes.TextField}
                            size="small"
                            placeholder="Comment"
                            multiline
                            rows={4}
                            maxRows={4}
                            onChange={this.handleInputChange.bind(this, 'comment')}
                            value={order_payload.comment}
                            />
                    </Grid>
                    {/* <Grid item xs={12}>
                        <FormLabel
                            component="legend"
                            className={classes.FormLabel}
                            >
                                Note to Gateway:
                            </FormLabel>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            name="note"
                            variant="outlined"
                            className={classes.TextField}
                            size="small"
                            placeholder="E.g. Open to SPV structure, Pref. only...etc"
                            multiline
                            rows={4}
                            maxRows={4}
                            onChange={this.handleInputChange.bind(this, 'note')}
                            value={order_payload.note}
                            />
                    </Grid> */}
                    <Grid item xs={12}>
                        <Button 
                            className="btn-submit blue-btn"
                            style={{ fontSize: ReScaleForDeviceScale(1.5, global150BodyFont, globalBodyFont) }}
                            onClick={this.handleSubmitInterest}>
                                Submit
                        </Button>
                    </Grid>
                </Grid>

                <Snackbar
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center'
                    }}
                    openStyle={snackBar.open}
                    handleSnackbarClose={this.handleSnackbarClose}
                    type={snackBar.error ? "error" : "success"}
                    message={snackBar.message}
                    upTime={10000}
                    />
            </section>
        )
    }
}

export default withStyles(FormStyle)(PlaceInterest);