import { combineReducers } from 'redux';
import InitialState from './InitialState';

import {
	getFundraisingsByUser,
} from "./Action";

export default combineReducers({
    reducer(state=InitialState, action){
        switch( action.type ){
            case getFundraisingsByUser.request.toString():
				return Object.assign({}, state, {
					fundRaisingsResponse: []
				});
			case getFundraisingsByUser.success.toString():
				return Object.assign({}, state, {
					fundRaisingsResponse: action.payload,
				});
			case getFundraisingsByUser.failure.toString():
				return state;
            default:
                return state;
        }
    }
})