import React, { Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Styles } from "../../styles";
import { Link } from "react-router-dom";
import { 
	CompanyStatusCalculate, 
	isMobile,
	showBlurred,
	longBlurText, } from "../../../utils/util";
import { sendGAEvent } from "../../../utils/ga";
import { ReadQueryString, GetToken } from "../../../utils";
import WhatshotIcon from '@material-ui/icons/Whatshot';
import {
	StarBorder,
	Star,
	ExpandMore,
	ExpandLess,
} from "@material-ui/icons";
import {
    Sort,
} from '../../orderbook/pipeline/components';
import {
	Filters,
	FilterWithId,
	PermissionedDeals,
	Events,
} from './components';
import {
    CompanySearch,
	PlaceInterestPositionWrapper,
} from '../common';

import {
	feature_flags,
} from '../../../config';

import {
	Grid, 
	Button,
} from "@material-ui/core";
import { BiDetail } from "react-icons/bi";
import { PipelineSidebar } from "../../common";
import { WatchListDialog } from "../../common/watchlist/dialog"
import { WatchlistGroup } from "../../common/watchlist/group"
import Tooltip from "@material-ui/core/Tooltip";
import DisclaimerData from '../../common/DisclaimerData';
import { content_placeholders } from '../../../config';
import { sendLogEvent } from '../../../utils/ga';
import {
    WatchlistSnackbar,
} from '../../orderbook/common/components';


let liveDealsTop, marketIntelligenceTop, watchlistTop;
class Pipeline extends React.Component {
	state = {
		tabFocusIndex: 0,
		page: 0,
		rowsPerPage: 50,
		showWatchList: false,
		expandMore: false,
		valuation: {
			min: 100,
			max: 30000,
		},
		newDealList: [],
		showDetailId: -1,
		verticals: [],
        selected_vertical: [],
        geography: [],
        selected_geography: [],
		valuations: [
            '$200M - $500M',
            '$500M - $1B',
            '$1B - $5B',
            '$5B+'
        ],
        selected_valuations: [
            // '$200M - $500M',
            // '$500M - $1B',
            // '$1B - $5B',
            // '$5B+'
        ],
		tags: [
            'ROFR Waived',
            'Company Sponsored',
            'Information Available',
            'Primary Fundraising',
            'Direct to Captable',
            'SPV Structure',
            'Gateway SPV',
        ],
        selected_tags: [
            // 'ROFR Waived',
            // 'Company Sponsored',
            // 'Information Available',
            // 'Primary Fundraising',
            // 'Direct to Captable',
            // 'SPV Structure',
            // 'Gateway SPV',
		],
		reset_company_search: false,
		selected_companies: [],
        default_sort: 'Most Active',
		selected_sort: 'Most Active',
        sort_options: [
            // 'Live',
            'A to Z',
            'Z to A',
            'Most Active'
        ],
		deal_types: [
            'GP Co-Investment',
            'Company Primary',
            'Secondary',
        ],
        selected_deal_types: [
            'GP Co-Investment',
            'Company Primary',
            'Secondary',
        ],
		last_search: {
            selected_vertical: [],
            selected_geography: [],
            selected_valuations: [
                // '$200M - $500M',
                // '$500M - $1B',
                // '$1B - $5B',
                // '$5B+'
            ],
            selected_tags: [
                // 'ROFR Waived',
                // 'Company Sponsored',
                // 'Information Available',
                // 'Primary Fundraising',
                // 'Direct to Captable',
                // 'SPV Structure',
                // 'Gateway SPV',
            ],
            selected_companies: [],
            selected_sort: 'Most Active',
            valuation: {},
			selected_deal_types: [],
			selected_gpCompany: [],
        },
		distanceBottom: 5,
		get_more_in_process: false,
		watchListDialog:{
			open: false,
			companyId:0,
			newCompanyName:'',
		},
		gpCompany:[],
		selected_gpCompany:[],
		dealTimeline: [],
		isMobile: false,
		express_interest: {
			open: false,
			prompt_express: false
		}
	};

	constructor(props) {
		super(props);
	}

	componentDidMount() {
		const { location : { search }, getDealTimeline, getPermissionedDeal, clearWatchlistMessage } = this.props;
		clearWatchlistMessage();
		const { tabFocusIndex } = this.state;
		const query_string_obj = ReadQueryString(search);
		
		if( query_string_obj.tab && parseInt(query_string_obj.tab) != tabFocusIndex ){
			this.onTabClick(parseInt(query_string_obj.tab));
		}
		this.onPageScroll();
		this.getData();
		this.getListHeight();

		window.addEventListener('scroll', this.handleScrollEvent);
		getDealTimeline({});
		getPermissionedDeal({});
		this.setState({ 
			isMobile: isMobile()
		});
		window.addEventListener('resize', this.handleWindowResize);
		document.addEventListener('visibilitychange', this.handleWindowVisibilitychange);
	}

	componentWillUnmount(){
        window.removeEventListener('scroll', this.handleScrollEvent);
		window.removeEventListener('resize', this.handleWindowResize);
		document.removeEventListener('visibilitychange', this.handleWindowVisibilitychange);
    }

	handleWindowVisibilitychange = (evt) => {
		// console.log('WindowVisibilitychange')
		const { ValidateUserInBackground } = this.props;
		if(document.visibilityState === 'visible'){
            const accessToken = GetToken();
            if( accessToken && accessToken.token && accessToken.email && accessToken.set_time ){
                accessToken['currentUserType'] = content_placeholders.user_type.wealth_user
                ValidateUserInBackground(accessToken);
            }
		}
	}

	handleWindowResize = (evt) => {
		if(this.state.isMobile !== isMobile()){
			this.setState({ 
				isMobile: isMobile()
			});
		}
	}

	handleScrollEvent = (evt) => {
        const { distanceBottom, get_more_in_process, } = this.state;
		const { deal_loading, no_more_deal_data } = this.props;
        const current_scroll = window.scrollY;
        const window_height = window.innerHeight;
        const page_height = document.documentElement.offsetHeight;
		let disclaimer_height = 0;
		if(document.getElementsByClassName("disclaimer").length>0){
			disclaimer_height = document.getElementsByClassName("disclaimer")[0].offsetHeight;
		}
        if (
            (current_scroll + window_height) > (page_height - disclaimer_height - distanceBottom) &&
            !deal_loading && 
            !get_more_in_process &&
			!no_more_deal_data
            ) {
            this.getNextDeal();
        }
    }

	getNextDeal = () => {
        this.setState({ 
            page: this.state.page+1, 
            get_more_in_process: true 
        }, () => this.getNewDeal(true));
    }

	componentDidUpdate(prevProps, prevState) {
		const { location : { search } } = this.props;
		const { tabFocusIndex } = this.state;
		const query_string_obj = ReadQueryString(search);
		if(search != prevProps.location.search && query_string_obj.tab && parseInt(query_string_obj.tab) != tabFocusIndex ){
			this.onTabClick(parseInt(query_string_obj.tab));
		}
		this.getListHeight();
	}

	onTabClick = (index) => {
		// let homeRight = document.getElementById("home-right");
		let homeRight = window;
		let scrollToHeight = liveDealsTop;
		let showWatchList = false;
		let eventGAName = ""
		switch (index) {
			case 0:
				scrollToHeight = liveDealsTop;
				eventGAName = "click_deal_showcase"
				break;
			case 1:
				scrollToHeight = marketIntelligenceTop;
				eventGAName = "click_market_intelligence"
				break;
			case 2:
				scrollToHeight = liveDealsTop - 10;
				showWatchList = true;
				eventGAName = "click_watchlist"
				break;
			default:
				break;
		}

		this.setState({
			tabFocusIndex: index,
			showWatchList: showWatchList,
		});
		this.props.updateProps({
			showWatchList: showWatchList,
		});

		if (!showWatchList) {
			// console.log("Try scrolling.")
			try{
				homeRight.scrollTo({
					top: scrollToHeight + 10,
					behavior: "smooth",
				});
			} catch(err){
				console.log(err);
			}

		} else {
			// console.log("Try watchlist.")
			if (index == 2) {

				try{
					homeRight.scrollTo({
						top: scrollToHeight + 10,
						behavior: "smooth",
					});
				} catch(err){
					console.log(err);
				}

			}
		}
		sessionStorage.setItem("tabIndex", index);
		sendGAEvent({
			type:eventGAName,
			data:{
			}
		})
	};
	getListHeight = () => {
		let { showWatchList } = this.state;
		if (!!showWatchList) {
			return;
		}
		let liveDeals = document.getElementById("liveDeals");
		if(liveDeals) liveDealsTop = liveDeals.offsetTop - 90;

		// let watchlist = document.getElementById("watchlist");
		let marketIntelligence = document.getElementById("marketIntelligence");

		// watchlistTop = watchlist.offsetTop - 90;
		marketIntelligenceTop = marketIntelligence.offsetTop - 90;
	};

	onPageScroll = () => {
		let _this = this;
		window.onscroll = () => {
			let scrollTop =
				document.documentElement.scrollTop || document.body.scrollTop;
			let tabFocusIndex = 0;
			if (scrollTop > liveDealsTop && scrollTop < marketIntelligenceTop) {
				tabFocusIndex = 0;
			}
			if (scrollTop > marketIntelligenceTop) {
				tabFocusIndex = 1;
			}
			if (!!this.state.showWatchList) {
				tabFocusIndex = 2;
			}
			_this.setState({
				tabFocusIndex: tabFocusIndex,
			});
		};
	};

	getData = () => {
		const {
			getRegionList,
			getVerticalsList,
			getViewSummary,
			getGpCompany,
		} = this.props;
		let _this = this;

		setTimeout(function () {
			_this.getNewDeal();
			getRegionList();
			getVerticalsList();
			getViewSummary();
			getGpCompany();
		}, 300);


	};

	static getDerivedStateFromProps(nextProps, prevState) {
		const {
			regionListResponse,
			verticalsListResponse,
			dealResponse,
			gpCompanyResponse,
			dealTimeline,
		} = nextProps;
		let { newDealList, page, rowsPerPage } = prevState;

		if (
			verticalsListResponse &&
			verticalsListResponse.length != prevState.verticals.length
		) {
			const vert = verticalsListResponse.map(item => item.name);
			return { verticals: vert };
		} else if (
			regionListResponse &&
			regionListResponse.length != prevState.geography.length
		) {
			return { geography: regionListResponse };
		} else if (
			gpCompanyResponse &&
			gpCompanyResponse.length != prevState.gpCompany.length
		) {
			const company = gpCompanyResponse.map(item => item.name);
			return { gpCompany: gpCompanyResponse };
		}  else if (
			dealTimeline &&
			dealTimeline.length != prevState.dealTimeline.length
		) {
			return { dealTimeline: dealTimeline };
		} else if (
			dealResponse && 
			dealResponse.resultList
		) {
			return {
				newDealList: [...dealResponse["resultList"]],
				get_more_in_process: false,
			};
		}  else {
			return null;
		}
	}

	renderStatus(parms) {
		let result = CompanyStatusCalculate(parms);
		return (
			<div
				className="flex-row-center showcase-status-container"
				style={{ color: result.color, fontSize: "1.4rem" }}
			>
				{result.icon}
				<span className="home-status" style={{ marginLeft: "0.3rem" }}>
					{result.statusTip}
				</span>
			</div>
		);
	}

	doToggleWatchList = (parms, event) => {
		let { companyId, watchlist } = parms;
		if(event && event.stopPropagation) event.stopPropagation();
		if(event && event.preventDefault) event.preventDefault();
		const { watchListDialog } = this.state;
		watchListDialog["open"] = true;
		watchListDialog["companyId"] = companyId;
		watchListDialog['active'] = watchlist;
		watchListDialog['newCompanyName'] = '';
		this.setState({ watchListDialog: watchListDialog });
	};


	handleCloseWatchListDialog = (event) =>{
		const { watchListDialog } = this.state;
		watchListDialog["open"] = false;
		watchListDialog["companyId"] = 0;
		watchListDialog['newCompanyName'] = '';
		this.setState({ watchListDialog: watchListDialog });
	}
	
	handleWatchListStar = (companyId, active) => {
		// let { newDealList } = this.state;
		// if (newDealList && newDealList.length > 0) {
		// 	for (let index in newDealList) {
		// 		if (newDealList[index]["company_id"] == companyId) {
		// 			newDealList[index]["watchlist"] = active;
		// 		}
		// 	}
		// }
		// this.setState({
		// 	newDealList: newDealList,
		// });
	}


	getScrollTop = () => {
		var scrollTop = 0;
		if (document.documentElement && document.documentElement.scrollTop) {
			scrollTop = document.documentElement.scrollTop;
		} else if (document.body) {
			scrollTop = document.body.scrollTop;
		}
		return scrollTop;
	};

	getClientHeight = () => {
		let clientHeight = 0;
		if (document.body.clientHeight && document.documentElement.clientHeight) {
			clientHeight = Math.min(
				document.body.clientHeight,
				document.documentElement.clientHeight
			);
		} else {
			clientHeight = Math.max(
				document.body.clientHeight,
				document.documentElement.clientHeight
			);
		}
		return clientHeight;
	};

	getScrollHeight = () => {
		return Math.max(
			document.body.scrollHeight,
			document.documentElement.scrollHeight
		);
	};

	renderCompanyLiveTag = () => {
		return (
			<div
				className="flex-row-center home-card-status"
				style={{ background: "#FCA311" }}
			>
				<span className="home-status">Live</span>
			</div>
		);
	};

	onDetailMouseEnter = (event, id) => {
		// console.log("in enter ", event.target, event.relatedTarget);
		if(event && event.stopPropagation) event.stopPropagation();
		this.setState(
			{
				showDetailId: id,
			},
			() => {
				// console.log(this.state.showDetailId, "this.state.showDetailId");
			}
		);
		sendGAEvent({
			type:"hover_company_description",
			data:{
				company_id: id,
			}
		})
	};

	onDetailMouseLeave = (event) => {
		if(event && event.stopPropagation) event.stopPropagation();
		this.setState({ showDetailId: -1, });
	};

	renderCardList = (listArray, type) => {
		const { classes, appConfig, loginUserData: { email } } = this.props;
		const { isMobile } = this.state;
		const target_date = new Date();
		target_date.setDate(target_date.getDate() - 14);
		const blur_text = longBlurText();

		return (
			<div className="flex-row market-intelligence-list">
				{listArray &&
					listArray.length > 0 &&
					listArray.map((item, index) => {
						const class_str = item && item.deal_class_str ? item.deal_class_str.split('|') : [];
						// console.log( class_str )
						const date_obj = new Date(item.date_added+"T00:00:00");
						return (
							<div
								key={index}
								className="home-card market-item"
							>
								{
									date_obj > target_date && item.status == "WEALTH DEAL LIVE CONFIRMED" ?
										<div className="new-label">
											Recently<br />Added
										</div>
										: ''
								}
								{this.state.showDetailId == item.company_id && (
									<div className="flex-row market-about">
										<div className="line-ten">{
											showBlurred(item.description) ?
											<span className="blurred">{blur_text}</span> :
											item.description
										}</div>
									</div>
								)}
								<div className="src-logo-container">
								{item.src_info_logo_list && item.src_info_logo_list.length > 0 &&
									
										item.src_info_logo_list.slice(0, 2).map((infoLogn, logoIndex) => {
											return <img className="case-img-logo" key={logoIndex} src={infoLogn} alt="" />
										})
									
								}
								</div>
								<div className="flex-row-between">
									<div style={{width: '100%'}}>
										<div className="home-company-name-market">
											{item.name}
										</div>
										<div className="home-company-info line-two company-info-height">
											{item.primary_industry_code}
											<div className="flex-row company-icon-wrap">
												{
													item.focus_deal ? <WhatshotIcon style={{ color: '#FF0000' }} /> : ""
												}
												{
													isMobile ? 
														<div
															onTouchStart={(evt) => 
																this.onDetailMouseEnter(evt, item.company_id)}
															onTouchEnd={(evt) => this.onDetailMouseLeave(evt)}
															onMouseOver={(evt) => 
																this.onDetailMouseEnter(evt, item.company_id)}
															onMouseOut={(evt) => this.onDetailMouseLeave(evt)}
															className="flex-row detail-icon">
																<BiDetail className="pointer-none" />
														</div>
													:
														<div
															onMouseOver={(evt) =>
																this.onDetailMouseEnter(evt, item.company_id)}
															onMouseOut={(evt) => this.onDetailMouseLeave(evt)}
															className="flex-row detail-icon">
																<BiDetail className="pointer-none" />
														</div>
												}
												
										{
											feature_flags.feature_is_available(
												appConfig,
												feature_flags.watchlist_flags.Watchlist.flag_name,
												feature_flags.watchlist_flags.Watchlist.attributes.add_remove_watchlist,
												email
											) ?
												item.watchlist ? (
													<span
														onClick={(event) =>
															this.doToggleWatchList(
																{
																	companyId: item.company_id,
																	watchlist: item.watchlist,
																},
																event
															)
														}
														style={{ color: "#FFC50D", cursor: "pointer" }}
													>
														<Tooltip title="Remove from watchlist">
															<Star />
														</Tooltip>
													</span>
												) : (
													<span
														onClick={(event) =>
															this.doToggleWatchList(
																{
																	companyId: item.company_id,
																	watchlist: item.watchlist,
																},
																event
															)
														}
														style={{ color: "#AFABAB", cursor: "pointer" }}
													>
														<Tooltip title="Add to watchlist">
															<StarBorder />
														</Tooltip>
													</span>
												)
											: ''
										}
										
											</div>
										</div>
									</div>
								</div>
								{item.vertical_category_list && item.vertical_category_list.length > 0 &&
									<div className="vertical-category-container">
										{
											item.vertical_category_list.map((categoryitem, categoryIndex) => {
												return <span className="card-vertical-category-item" key={categoryIndex}>#{categoryitem} </span>
											})
										}
									</div>
								}
								{
									showBlurred(item.logo) ?
										<div className="case-img mt-2"></div>
										: <img className="case-img mt-2" src={item.logo} alt="" />
								}
								
								<div className="pr-1 market-info">
									{/* <div className="valuation-wrap">
										<span className="market-label">Valuation: </span>
										<span className="home-company-info">
											{item.selected_post_money_valuation
												? item.selected_post_money_valuation > 1000
													? "US$" +
														(item.selected_post_money_valuation / 1000).toFixed(
															1
														) +
														"B"
													: "US$" +
														item.selected_post_money_valuation.toFixed(0) +
														"M"
												: "-"}
										</span>
									</div> */}
									<div className="location-wrap">
										<span className="market-label">Location: </span>
										<span className="home-company-info">
											{ showBlurred(item.headquarter_country) ? <span className="blurred">{item.headquarter_country}</span> : item.headquarter_country}
										</span>
									</div>
									<div className="stage-wrap">
										<span className="market-label">Stage: </span>
										<span className="home-company-info">
											{item.selected_deal_type_1 && item.selected_deal_type_1.indexOf('Stage VC') > -1 ? item.selected_deal_type_1 : "-"}
											{item.selected_deal_type_1 &&
											item.selected_deal_type_1.indexOf('Stage VC') > -1 &&
											item.selected_deal_type_2 &&
											item.selected_deal_type_2.trim() ? " (Series " + item.selected_deal_type_2 + ")" : ""}
										</span>
									</div>
									<div className="deal-type-tag-wrap in-pipeline-page">
										{ class_str.indexOf(content_placeholders.deal_class_str.gpled) > -1 && <span className={classes.GPLedDeal}>GP Co-investment</span>}
										{ 
											class_str.indexOf(content_placeholders.deal_class_str.primary) > -1 &&
											class_str.indexOf(content_placeholders.deal_class_str.gpled) == -1 && 
												<span className={classes.PrimaryDeal}>Company Primary</span>
										}
										{ class_str.indexOf(content_placeholders.deal_class_str.companyled) > -1 &&
											class_str.indexOf(content_placeholders.deal_class_str.primary) == -1 &&
											class_str.indexOf(content_placeholders.deal_class_str.gpled) == -1 && 
												<span className={classes.CompLedDeal}>Company Arranged</span>}
										{
											!class_str.length && <span className={classes.DirectSecondaryDeal}>Secondary</span>
										}
									</div>
									{item.status == "DEAL LIVE CONFIRMED" &&
										this.renderCompanyLiveTag()}
								</div>
								<Link
									to={{
										pathname: `/wealth/company-detail/${item.company_id}`,
										state: { type: type },
									}}>
									<Button variant="outlined" className="view-detail-btn">View Details</Button>
								</Link>
							</div>
						);
					})}
			</div>
		);
	};

	getNewDeal = (extend=false) => {
		const {
			rowsPerPage,
			page,
			selected_vertical,
			selected_geography,
			selected_valuations,
			selected_gpCompany,
			selected_companies,
			last_search,
			selected_sort,
			selected_deal_types,
		} = this.state;

		const qs_payload = {
            live: true,
            limit: rowsPerPage,
            offset: page,
        };
		if( selected_vertical.length ){ 
			qs_payload['vertical_list'] = encodeURIComponent('{'+selected_vertical.join(',')+'}');
			last_search['selected_vertical'] = selected_vertical;
		}

		if( selected_geography.length ){ 
			qs_payload['region_list'] = encodeURIComponent('{'+selected_geography.join(',')+'}');
			last_search['selected_geography'] = selected_geography;
		}

		if( selected_valuations.length ){
			qs_payload['val_list'] = encodeURIComponent('{'+selected_valuations.join(',')+'}');
			last_search['selected_valuations'] = selected_valuations;
		}

		if( selected_gpCompany.length ){
			qs_payload['gp_company'] = encodeURIComponent('{'+selected_gpCompany.map(item => item.name).join(',')+'}');
			last_search['selected_gpCompany'] = selected_gpCompany;
		}

		if( selected_companies.length ){
			qs_payload['comp_list'] = encodeURIComponent('{'+selected_companies.join(',')+'}');
			last_search['selected_companies'] = selected_companies;
		}

		if( selected_deal_types.length ){
			qs_payload['deal_class'] = encodeURIComponent('{'+selected_deal_types.join(',')+'}');
			last_search['selected_deal_types'] = selected_deal_types;
		}

		switch( selected_sort ){
			case 'Live':
				qs_payload['order_field'] = 'has_live';
				qs_payload['asc_or_desc'] = 'asc';
				last_search['selected_sort'] = 'Live';
				break;
			case 'A to Z':
				qs_payload['order_field'] = 'name';
				qs_payload['asc_or_desc'] = 'asc';
				last_search['selected_sort'] = 'A to Z';
				break;
			case 'Z to A':
				qs_payload['order_field'] = 'name';
				qs_payload['asc_or_desc'] = 'desc';
				last_search['selected_sort'] = 'Z to A';
				break;
			case 'Most Active':
				qs_payload['order_field'] = 'order_count';
				qs_payload['asc_or_desc'] = 'desc';
				last_search['selected_sort'] = 'Most Active';
				break;
			default:
				console.log('Nothing to do.');
		}
		const { getDeal, getMoreDeal } = this.props;
		this.setState({
            last_search: last_search
        }, () => {
            if( extend ){
				getMoreDeal(qs_payload)
            } else {
                getDeal(qs_payload)
            }
        });
        
	};

	renderDeal() {
		const { loaderState, loginUserData } = this.props;
		let { newDealList: newDealList, verticals, selected_vertical, geography, 
			selected_geography, valuations, selected_valuations, tags, selected_tags, deal_types, selected_deal_types,
			reset_company_search, sort_options, selected_sort, selected_companies, default_sort,
			gpCompany, selected_gpCompany, isMobile} = this.state;
		const { activeAccessRight: { access_right: { permission } } } = loginUserData;
		const de_select_all_text = "Clear all filters / sorts";
		const show_reset = selected_vertical.length ||
                            selected_geography.length ||
                            selected_valuations.length ||
                            selected_tags.length ||
                            selected_sort != default_sort ||
							selected_deal_types.length ||
							selected_gpCompany.length ||
                            selected_companies.length ? true : false;
		return (
			<div id="marketIntelligence" className="market-intelligence">
				<div className="flex-row-between">
					<div className="home-list-name">
						
						<h4 
							style={{ marginBottom: 0, display: 'inline-block' }}
							className="subheading-color company-item-title">
							<span className="company-item-rectangle"></span>
							Deals
						</h4>
						{
							show_reset ? <Button 
							onClick={() => this.handleResetFilter()} 
							style={{ fontSize: 8, textAlign: 'right' }}>
								{de_select_all_text}
						</Button> : ''
						}
					</div>
				</div>
				<Grid container spacing={2}>
					{
						<Fragment>
							<Grid item xs={12}>
							 	<Filters
								 	handleSelectChange={this.handleSelectChange}
								 	handleResetFilter={this.handleResetFilter}
								 	{...{
									 	verticals,
									 	selected_vertical,
									 	geography,
									 	selected_geography,
									 	valuations,
									 	selected_valuations,
									 	deal_types,
									 	selected_deal_types,
								 	}}
								 />
						 	</Grid>
						 	<Grid item xs={isMobile ? 12 : 9}>
								{/* {
									permission == content_placeholders.user_type.wealth_user ?
										<CompanySearch
											// handleSearchCompany={this.handleSearchCompany}
										 	// reset_company_search={reset_company_search}
										 /> : <CompanySearch />
							 	}
								 */}
								<CompanySearch
									handleShowExpressInterest={this.handleShowExpressInterest}
									handlePromptExpress={this.handlePromptExpress}
									handleShowWatchlistFromSearchCompany={this.handleShowWatchlistFromSearchCompany}
									/>
						 	</Grid>
							{/* <Grid item xs={isMobile ? 6 : 3}>
								<FilterWithId
								 	handleSelectChange={this.handleSelectChange}
									handleResetFilter={this.handleResetFilter}
									options = {gpCompany}
									selected_options = {selected_gpCompany}
									id = "gpCompany"
									name = "selected_gpCompany"
									placeholder = "General Partner (GP)"/>
							</Grid> */}
						 	<Grid item xs={isMobile ? 6 : 3}>
							 	<Sort
								 	handleSelectChange={this.handleSelectChange}
								 	{...{
									 	sort_options,
									 	selected_sort,
								 	}} />
						 	</Grid>
						</Fragment>
					}
                </Grid>
				{/* <Grid container spacing={2}>
					<Grid item xs={12}>

					</Grid>
				</Grid> */}
				{this.renderCardList(newDealList, "marketintelligence")}
			</div>
		);
	}

	renderWatchList() {
		const { watchListResponse } = this.props;
		let watch_list_response =
			watchListResponse && watchListResponse.results
				? watchListResponse.results
				: [];
		return (
			<div>
				<div className="wealth-watchlist-group">
					<WatchlistGroup
						companyDetailType="wealth"
					/>
				</div>
			</div>
		);
	}

	toggleBottomForm = () => {
		this.setState({
			expandMore: !this.state.expandMore,
		});
		sendGAEvent({
			type:"click_tell_us",
			data:{
				type: this.state.expandMore ? 'collapse' : 'expand',
			}
		})
	};

	handleResetFilter = (name) => {
        const { default_sort, } = this.state;
        const new_state = name ? { [name]: [] } : { 
            selected_vertical: [], 
            selected_geography: [],  
            selected_sort: default_sort,
            selected_valuations: [],
            selected_tags: [],
            selected_companies: [],
			selected_deal_types: [],
            reset_company_search: true,
			selected_gpCompany: [],
        }
        
        this.setState(new_state, () => { 
            this.getRefreshWealth(2000);
            const self = this;
            setTimeout(function(){
                self.setState({ reset_company_search: false });
            }, 1000);
        });
    }

	handleSearchCompany = (evt, name) => {
        const selected_companies = name.trim() ? [name] : [];
        
        const new_state = { selected_companies: selected_companies }
        this.setState(new_state, () => this.getRefreshWealth());
    }

	handleShowWatchlistFromSearchCompany = (evt, search_name) => {
        const company_name = search_name.trim() ? search_name.trim() : ''
        if(company_name){
            const { watchListDialog } = this.state;
		    watchListDialog["open"] = true;
		    watchListDialog["companyId"] = null;
            watchListDialog['active'] = false;
            watchListDialog['newCompanyName'] = company_name;
		    this.setState({ watchListDialog: watchListDialog });
        }
    }

	handleSelectChange = (evt) => {
        const { target: { name, value } } = evt;
        const new_state = { [name]: value };
        this.setState(new_state, () => this.getRefreshWealth());
    }

	getRefreshWealth = (delay_time) => {
        if( this.timeout ) clearTimeout( this.timeout );
        const self = this;
        const delay_in_ms = delay_time ? delay_time : 1000;
        // TO DO compare last_search against current search
        this.timeout = setTimeout(() => {
            if( self.searchCriteriaChanged( ) ){
                // Get wealth data when different from last Search
                self.setState({ page: 0 }, () => self.getNewDeal());
            }
        }, delay_in_ms);
    }

	searchCriteriaChanged = () => {
        const { 
            last_search,
            selected_sort,
            selected_vertical,
            selected_geography,
            selected_valuations,
            selected_tags,
            selected_companies,
			selected_deal_types,
			selected_gpCompany,
        } = this.state;

        if(
            !selected_vertical.equals( last_search.selected_vertical ) ||
            !selected_geography.equals( last_search.selected_geography ) ||
            !selected_valuations.equals( last_search.selected_valuations ) ||
            !selected_tags.equals( last_search.selected_tags ) ||
            selected_sort != last_search.selected_sort ||
            selected_companies.length != last_search.selected_companies.length ||
			!selected_gpCompany.equals( last_search.selected_gpCompany ) ||
			!selected_deal_types.equals( last_search.selected_deal_types ) ||
            (
                selected_companies.length && 
                last_search.selected_companies.length &&
                selected_companies[0] != last_search.selected_companies[0]
            )
        ){
            return true;
        } else {
            return false;
        }
    }

	handlePromptExpress = (evt, show) => {
		const { express_interest } = this.state;
		express_interest['prompt_express'] = show;
		this.setState({ express_interest });
	}

	handleShowExpressInterest = (evt) => {
		const { express_interest } = this.state;
		express_interest['open'] = !express_interest['open'];
		this.setState({ express_interest });
	}

	handleSnackbarClose = (evt, reason) => {
		if (reason === "clickaway") {
			return;
		}
		const { clearWatchlistMessage } = this.props;
		clearWatchlistMessage();
	};

	render() {
		let {
			tabFocusIndex,
			showWatchList,
			expandMore,
			watchListDialog,
			dealTimeline,
			express_interest,
		} = this.state;

		const { appConfig, loginUserData, permissioned_deals, toggle_watchlist } = this.props;

		const {
			collapseSidebar
		} = this.props;

		const home_right_class = collapseSidebar ? 'home-right narrow' : 'home-right';
		
		let control_panel_class = 'control-panel';
		if( expandMore ) control_panel_class += " show-panel-more";
		if( collapseSidebar ) control_panel_class += ' narrow';

		return (

				<div className="home">
					<div className="top-padding"></div>
					<PipelineSidebar
						tabFocusIndex={tabFocusIndex}
						collapse={true}
						{...this.props}
						/>
					<div className={home_right_class} id="home-right">
						{!showWatchList ? (
							<div className="pipeline_bg">
								<div className="container">
									<div className="row">
										{
											dealTimeline &&
											dealTimeline.length > 0 &&
											<div className="col col-12">
												<h4 
													style={{ marginBottom: 0 }}
													className="mt-3 subheading-color company-item-title">
													<span className="company-item-rectangle"></span>
													Gateway Events
												</h4>
												<Events
													eventData =  {dealTimeline}
													{...{
														appConfig,
														loginUserData
													}}
													/>
											</div>
										}
										
									</div>
								</div>
								{
									permissioned_deals.length ? 
										<PermissionedDeals
											classes={this.props.classes}
											type="marketintelligence"
											doToggleWatchList={this.doToggleWatchList}
											{...{ permissioned_deals }}
											/> : ''
								}
								<div className="home-content">
									{ this.renderDeal() }
								</div>
								<div className="row">
									<div className="col col-12 mt-3">
										<DisclaimerData />
									</div>
								</div>
							</div>
						) : (
							<div className="home-content">{this.renderWatchList()}</div>
						)}
						
						{!this.state.showWatchList && (
							<div className={control_panel_class}>
								<div
									onClick={this.toggleBottomForm}
									className="flex-row arrow-wrap"
								>
									{!expandMore ? <ExpandLess /> : <ExpandMore />}
								</div>
							</div>
						)}
					</div>

					<PlaceInterestPositionWrapper
						handleShowExpressInterest={this.handleShowExpressInterest}
						open={express_interest.open}
						popOut={express_interest.prompt_express}
						placeorder={{}}
						{...this.props}
						/>


					{watchListDialog.open ? 
						<WatchListDialog
						{...watchListDialog}
						// companyId={watchListDialog.companyId}
						closeDialog={this.handleCloseWatchListDialog}
						updateStarStatus={this.handleWatchListStar}
					/> : null}

					<WatchlistSnackbar
                    	handleSnackbarClose={this.handleSnackbarClose}
                    	open={toggle_watchlist.message.trim() ? true : false}
                    	error={toggle_watchlist.error}
                    	message={toggle_watchlist.message}
                	/>
				</div>

		);
	}
}

export default withStyles(Styles)(Pipeline);
