import React, { Fragment } from 'react';
import CropFreeIcon from '@material-ui/icons/CropFree';
import ReactECharts from 'echarts-for-react';

import { 
    Typography,
    IconButton,
    Grid,
    Tooltip,
    withStyles,
    Checkbox,
    Button,
} from '@material-ui/core';

import {
    ToggleButtonGroup,
    ToggleButton,
} from '@material-ui/lab';

import { sendGAEvent } from '../../../../utils/ga';

import {
    numberWithCommas,
    ReScaleForDeviceScale,
    getImpliedVal,
    roundNumber,
    getLatestFundingRound,
} from '../../../../utils/util';

import {
    get_first_price,
    handle_missing_data,
    add_percent_change,
    reference_price_change,
} from '../../../../utils/PriceChartUtil';

import {
    globalFontFamily,
    global150BodyFont,
    globalBodyFont,
} from '../../../styles';

import {
    default as SecondaryPermissionedDeals,
} from './SecondaryPermissionedDeals';

import {
    content_placeholders,
    feature_flags,
} from '../../../../config';

import {
    LockedGraph
} from '../../../common/LockedGraph';
// import { RoundedCornerRounded } from '@material-ui/icons';

import { getOption } from './ChartConfig';

const PriceChartStyle = theme => ({
    ToggleButton: {
        fontSize: ReScaleForDeviceScale(1.5, global150BodyFont, globalBodyFont),
        fontFamily: globalFontFamily,
        color: theme.palette.common.black,
        borderColor: theme.palette.common.black,
    },
    Checkbox: {
        padding: 2
    }
});

class PriceChart extends React.Component{
    state = {
        company_id: 0,
        date: [],
        average_bid: [],
        average_offer: [],
        highest_bid: [],
        lowest_offer: [],
        count_bid: [],
        count_offer: [],
        bid_size: [],
        offer_size: [],
        average_bid_change: [],
        average_offer_change: [],
        highest_bid_change: [],
        lowest_offer_change: [],
        bitcoin_price: [],
        ether_price: [],
        bitcoin_price_change: [],
        ether_price_change: [],
        reference_prices: {},
        max_percent: 100,
        latest_funding_rounds: [],
        latest_funding_rounds_val: [],
        min_y: 0,
        max_y: 0,
        show_latest_funding_rounds: false,
        show_ref_price: false,
        price_chart_type: 'actual',
        price_line_type: 'average_price',
        bar_type: 'notional',
        zoom_level: 'all',
        annotation: {
            date: '',
            buy: 0,
            sell: 0,
            lfr_price: 0,
            lfr_val: 0,
        },
        average_bid_visual_map: [],
        average_offer_visual_map: [],
        highest_bid_visual_map: [],
        lowest_offer_visual_map: [],

        average_bid_change_visual_map: [],
        average_offer_change_visual_map: [],
        highest_bid_change_visual_map: [],
        lowest_offer_change_visual_map: [],

        lfr_visual_map: [],
        showBlurImage: false,
        showEmptyGraph: false,
        click_evt_binded: false,
        fullscreen_click_evt_binded: false,
    }

    constructor(props){
        super(props);
        this.myChart = React.createRef();
    }

    static getDerivedStateFromProps(nextProps, prevState){
        const {
            company_id,
            historical_pricing,
            reference_pricing,
            funding_rounds,
            blockchain,
        } = nextProps;
        if( company_id != prevState.company_id ){
            
            if( historical_pricing.historical_pricing.length ){
                let payload = {
                    date: [],
                    average_bid: [],
                    average_offer: [],
                    highest_bid: [],
                    lowest_offer: [],
                    count_bid: [],
                    count_offer: [],
                    bid_size: [],
                    offer_size: [],
                    average_bid_change: [],
                    average_offer_change: [],
                    highest_bid_change: [],
                    lowest_offer_change: [],
                    bitcoin_price: [],
                    ether_price: [],
                    bitcoin_price_change: [],
                    ether_price_change: [],
                    latest_funding_rounds: [],
                    latest_funding_rounds_val: [],
                    min_y: 0,
                    max_y: 0,
                    max_percent: 0,
                    average_bid_visual_map: [],
                    average_offer_visual_map: [],
                    highest_bid_visual_map: [],
                    lowest_offer_visual_map: [],

                    average_bid_change_visual_map: [],
                    average_offer_change_visual_map: [],
                    highest_bid_change_visual_map: [],
                    lowest_offer_change_visual_map: [],

                    lfr_visual_map: [],
                    showBlurImage: false,
                    showEmptyGraph: false,
                    reference_prices: {}
                }

                // console.log( reference_pricing );
                let reference_payload = {}
                if( reference_pricing && reference_pricing.reference_names.length && reference_pricing.reference_pricing.length ){
                    reference_payload = reference_price_change(reference_pricing.reference_names, reference_pricing.reference_pricing);
                }
                // console.log( reference_payload );

                const first_price = get_first_price(historical_pricing.historical_pricing);

                let ave_bid_check_payload = {
                    last_actual_data: first_price.average_bid ? first_price.average_bid : 0,
                    null_index: -1,
                    last_data_checkpoint: 0
                };

                let ave_offer_check_payload = {
                    last_actual_data: first_price.average_offer ? first_price.average_offer : 0,
                    null_index: -1,
                    last_data_checkpoint: 0,
                };

                let highest_bid_check_payload = {
                    last_actual_data: first_price.highest_bid ? first_price.highest_bid : 0,
                    null_index: -1,
                    last_data_checkpoint: 0,
                }

                let lowest_offer_check_payload = {
                    last_actual_data: first_price.lowest_offer ? first_price.lowest_offer : 0,
                    null_index: -1,
                    last_data_checkpoint: 0,
                }

                let pc_ave_bid_check_payload = {
                    last_actual_data: 0,
                    null_index: -1,
                    last_data_checkpoint: 0
                };

                let pc_ave_offer_check_payload = {
                    last_actual_data: 0,
                    null_index: -1,
                    last_data_checkpoint: 0,
                };

                let pc_highest_bid_check_payload = {
                    last_actual_data: 0,
                    null_index: -1,
                    last_data_checkpoint: 0,
                }

                let pc_lowest_offer_check_payload = {
                    last_actual_data: 0,
                    null_index: -1,
                    last_data_checkpoint: 0,
                }

                let max_percent = 0;
                
                if( historical_pricing.historical_pricing.length ){
                    
                    const fabv = historical_pricing.historical_pricing.filter(item => item.average_bid);
                    const faov = historical_pricing.historical_pricing.filter(item => item.average_offer);
                    const fhbv = historical_pricing.historical_pricing.filter(item => item.highest_bid);
                    const flov = historical_pricing.historical_pricing.filter(item => item.lowest_offer);
                    const fbv = historical_pricing.historical_pricing.filter(item => item.btc_price);
                    const fev = historical_pricing.historical_pricing.filter(item => item.eth_price);
                    
                    const hist_pricing_items = add_percent_change(
                        historical_pricing.historical_pricing,
                        {
                            'average_bid': fabv.length ? fabv[0].average_bid : 0, 
                            'average_offer': faov.length ? faov[0].average_offer : 0,
                            'highest_bid': fhbv.length ? fhbv[0].highest_bid : 0,
                            'lowest_offer': flov.length ? flov[0].lowest_offer : 0,
                            'bitcoin_price': fbv.length ? fbv[0].btc_price : 0,
                            'ether_price': fev.length ? fev[0].ether_price : 0
                        },
                        {
                            "average_bid": 'average_bid_change',
                            "average_offer": 'average_offer_change',
                            "highest_bid": "highest_bid_change",
                            "lowest_offer": "lowest_offer_change",
                            "bitcoin_price": 'bitcoin_price_change',
                            "ether_price": 'ether_price_change'
                        })

                    // console.log( hist_pricing_items )
                        
                    for( var ii=0; ii< hist_pricing_items.length; ii++ ){
    
                        payload['date'].push(hist_pricing_items[ii].date);
                        payload['average_bid'].push( roundNumber(hist_pricing_items[ii].average_bid) );
                        payload['average_offer'].push( roundNumber(hist_pricing_items[ii].average_offer) );
                        payload['highest_bid'].push( roundNumber(hist_pricing_items[ii].highest_bid) );
                        payload['lowest_offer'].push( roundNumber(hist_pricing_items[ii].lowest_offer) );
                        payload['count_bid'].push( hist_pricing_items[ii].count_bid );
                        payload['count_offer'].push( hist_pricing_items[ii].count_offer );
                        payload['bid_size'].push( roundNumber(hist_pricing_items[ii].bid_size) );
                        payload['offer_size'].push( roundNumber(hist_pricing_items[ii].offer_size) );
    
                        max_percent = Math.abs(hist_pricing_items[ii].average_bid_change) > max_percent ? Math.abs(hist_pricing_items[ii].average_bid_change) : max_percent;
                        max_percent = Math.abs(hist_pricing_items[ii].average_offer_change) > max_percent ? Math.abs(hist_pricing_items[ii].average_offer_change) : max_percent;
                        max_percent = Math.abs(hist_pricing_items[ii].highest_bid_change) > max_percent ? Math.abs(hist_pricing_items[ii].highest_bid_change) : max_percent;
                        max_percent = Math.abs(hist_pricing_items[ii].lowest_offer_change) > max_percent ? Math.abs(hist_pricing_items[ii].lowest_offer_change) : max_percent;
                    
                        payload['average_bid_change'].push( hist_pricing_items[ii].average_bid_change );
                        payload['average_offer_change'].push( hist_pricing_items[ii].average_offer_change );
                        payload['highest_bid_change'].push( hist_pricing_items[ii].highest_bid_change );
                        payload['lowest_offer_change'].push( hist_pricing_items[ii].lowest_offer_change );
                        payload['bitcoin_price_change'].push( hist_pricing_items[ii].bitcoin_price_change );
                        payload['ether_price_change'].push( hist_pricing_items[ii].ether_price_change );
                        
                        if( blockchain ){
                            max_percent = Math.abs( hist_pricing_items[ii].bitcoin_price_change ) > max_percent ? Math.abs( hist_pricing_items[ii].bitcoin_price_change ) : max_percent;
                            max_percent = Math.abs( hist_pricing_items[ii].ether_price_change ) > max_percent ? Math.abs( hist_pricing_items[ii].ether_price_change ) : max_percent;
                        }
                        
    
                        let response = handle_missing_data(ii, ave_bid_check_payload, hist_pricing_items, payload, '69,97,168', 'average_bid');
                        payload = response['payload'];
                        ave_bid_check_payload = response['check_payload'];
    
                        response = handle_missing_data(ii, ave_offer_check_payload, hist_pricing_items, payload, '252,163,16', 'average_offer');
                        payload = response['payload'];
                        ave_offer_check_payload = response['check_payload'];
    
                        response = handle_missing_data(ii, highest_bid_check_payload, hist_pricing_items, payload, '69,97,168', 'highest_bid');
                        payload = response['payload'];
                        highest_bid_check_payload = response['check_payload'];
    
                        response = handle_missing_data(ii, lowest_offer_check_payload, hist_pricing_items, payload, '252,163,16', 'lowest_offer');
                        payload = response['payload'];
                        lowest_offer_check_payload = response['check_payload'];
    
                        response = handle_missing_data(ii, pc_ave_bid_check_payload, hist_pricing_items, payload, '69,97,168', 'average_bid_change');
                        payload = response['payload'];
                        pc_ave_bid_check_payload = response['check_payload'];

                        response = handle_missing_data(ii, pc_ave_offer_check_payload, hist_pricing_items, payload, '252,163,16', 'average_offer_change');
                        payload = response['payload'];
                        pc_ave_offer_check_payload = response['check_payload'];

                        response = handle_missing_data(ii, pc_highest_bid_check_payload, hist_pricing_items, payload, '69,97,168', 'highest_bid_change');
                        payload = response['payload'];
                        pc_highest_bid_check_payload = response['check_payload'];

                        response = handle_missing_data(ii, pc_lowest_offer_check_payload, hist_pricing_items, payload, '252,163,16', 'lowest_offer_change');
                        payload = response['payload'];
                        pc_lowest_offer_check_payload = response['check_payload'];
    
                        const lfr_response = getLatestFundingRound(funding_rounds, (fr_item) => fr_item['deal_date'] < hist_pricing_items[ii]['date']);
                        
                        if( lfr_response.price && lfr_response.valuation ){
                            payload['latest_funding_rounds'].push(lfr_response.price);
                            payload['latest_funding_rounds_val'].push(lfr_response.valuation);
                        }
                        
                    }
            
                }
                // console.log( max_percent );
                payload['max_percent'] = max_percent;

                const lfr_visual_map_payload = {
                    color: 'rgba(34,139,34,1)',
                    gte: 0,
                    lt: historical_pricing.historical_pricing.length,
                    symbol: 'none'
                }

                payload['lfr_visual_map'].push(lfr_visual_map_payload);
                payload['showBlurImage'] = !historical_pricing.allow;
                payload['showEmptyGraph'] = !historical_pricing.data;
                payload['reference_prices'] = reference_payload;
                return payload;
            } else {
                let payload = {
                    showBlurImage: false,
                    showEmptyGraph: false,
                    average_bid: [],
                    average_offer: [],
                    highest_bid: [],
                    lowest_offer: [],
                    count_bid: [],
                    count_offer: [],
                    bid_size: [],
                    offer_size: [],
                    latest_funding_rounds: [],
                    latest_funding_rounds_val: [],
                    min_y: 0,
                    max_y: 0,
                }
                payload['showBlurImage'] = !historical_pricing.allow;
                payload['showEmptyGraph'] = !historical_pricing.data;
                return payload;
            }
        } else {
            return null;
        }
    }

    handleChangePriceLineType(key, evt, newValue){
        const { price_line_type, bar_type } = this.state;
        if( ['zoom_level', 'price_line_type', 'bar_type'].indexOf(key) > -1 && ( newValue == price_line_type || newValue == bar_type  || !newValue ) ) return;
        const { company_id } = this.props;
        sendGAEvent({
            type: key=='bar_type' ? 'orderbook_click_vol_chart' : 'orderbook_click_price_chart',
            data: {
                company_id: company_id,
                metrix_name: key + '_' + newValue
            }
        });

        const new_state = {
            [key]: newValue
        };

        if( key != 'zoom_level' ){
            new_state['zoom_level'] = 'all';
        }
        
        this.setState(new_state, () => {
            if( key == 'zoom_level' ){
                
                if( this.myChart && this.myChart.current && this.myChart.current.getEchartsInstance() ){
                    
                    const { date } = this.state;
                    
                    let startVal, endVal;
                    switch(newValue){
                        case '1m':
                            startVal=date.length-30;
                            endVal=date.length-1;
                            break;
                        case '3m':
                            startVal=date.length-91;
                            endVal=date.length-1;
                            break;
                        case '6m':
                            startVal=date.length-183;
                            endVal=date.length-1;
                            break;
                        case '1y':
                            startVal=date.length-365;
                            endVal=date.length-1;
                            break;
                        case '3y':
                            startVal=0;
                            endVal=date.length-1;
                            break;
                        default:
                            startVal=0;
                            endVal=date.length-1;
                    }

                    this.myChart.current.getEchartsInstance().dispatchAction({
                        type: 'dataZoom',
                        startValue: startVal,
                        endValue: endVal,
                    });
                }
            }
        });

    }

    render(){
        const { 
            show_latest_funding_rounds,
            latest_funding_rounds,
            average_bid,
            average_offer,
            price_line_type,
            bar_type,
            zoom_level,
            showBlurImage,
            showEmptyGraph,
            show_ref_price,
            price_chart_type,
        } = this.state;

        const {
            hidefullscreen,
            handlePriceChartFullScreen,
            classes,
            secondary_activity_deals,
            handlePrefillPlaceOrder,
            public_mode,
            company_detail,
            blockchain,
            appConfig,
            loginUserData: { email },
            reference_pricing,
        } = this.props;

        const total_bid = average_bid && average_bid.length ? average_bid.reduce((total, num) => total+num) : 0;
        const total_offer = average_offer && average_offer.length ? average_offer.reduce((total, num) => total+num) : 0;

        const average_classname = price_line_type=='weighted_price' ? 'legend-item orderbook clickable orderbook-price average' : 'legend-item orderbook clickable orderbook-price high-low';
        
        const latest_funding_rounds_classname = show_latest_funding_rounds ? "legend-item orderbook lfr clickable" : "legend-item orderbook lfr clickable dim"; 
        const total_lfr = latest_funding_rounds && latest_funding_rounds.length ? latest_funding_rounds.reduce((total, num) => total+num) : 0;
        
        return showEmptyGraph && (total_bid+total_offer) == 0 && !public_mode ? (
            <section className="price-chart-section">
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h6">Secondary Activity - Pricing and Volume History</Typography>
                        <p className="elaborate-text">Data displayed represents live order (offer or bid) prices at that point in time and not executed prices. All data is sourced from Gateway network.</p>
                    </Grid>
                    {
                        secondary_activity_deals && secondary_activity_deals.length ?
                            <Grid item xs={12}>
                                <SecondaryPermissionedDeals
                                    {...{secondary_activity_deals}}
                                    />
                            </Grid> : ''
                    }
                    <Grid item xs={12} style={{cursor: 'pointer'}} onClick={(evt) => handlePrefillPlaceOrder(evt, 'buy', {bid_price: 0, is_primary: false})}>
                        <span style={{fontSize: 12}}>Limited market activity in this name. Be the first to place an order.</span>
                    </Grid>
                </Grid>
            </section>
        ) : (
            <section className="price-chart-section orderbook" id="price-chart-section">
                <Grid container spacing={2}>
                    <Grid item xs={hidefullscreen || public_mode || showBlurImage ? 12 : 11}>
                        <Typography variant="h6">Secondary Activity - Pricing and Volume History</Typography>
                        <p className="elaborate-text">Data displayed represents live order (offer or bid) prices at that point in time and not executed prices. All data is sourced from Gateway network.</p>
                    </Grid>
                    {
                        !hidefullscreen && !public_mode && !showBlurImage ?
                            <Grid item xs={1}>
                                <Tooltip title="Fullscreen view">
                                    <IconButton aria-label="fullscreen" onClick={(evt) => handlePriceChartFullScreen(evt)}>
                                        <CropFreeIcon />
                                    </IconButton>
                                </Tooltip>
                            </Grid> : ''
                    }
                </Grid>
                {
                    secondary_activity_deals && secondary_activity_deals.length ?
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <SecondaryPermissionedDeals
                                    handleShowOrderDetailDialog={this.props.handleShowOrderDetailDialog}
                                    {...{secondary_activity_deals}}
                                    />
                            </Grid>
                        </Grid>
                            : ''
                }
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <div className='legend-wrapper mt-1'>
                            
                            <div className="legend-item price-chart-type">
                                <ToggleButtonGroup exclusive value={price_chart_type} onChange={this.handleChangePriceLineType.bind(this, 'price_chart_type')}>
                                    <ToggleButton className={classes.ToggleButton} value="actual" aria-label="left aligned">
                                        Price
                                    </ToggleButton>
                                    <ToggleButton className={classes.ToggleButton} value="percent" aria-label="right aligned">
                                        % Change
                                    </ToggleButton>
                                </ToggleButtonGroup>
                            </div>

                            <div className={average_classname}>
                                <ToggleButtonGroup exclusive value={price_line_type} onChange={this.handleChangePriceLineType.bind(this, 'price_line_type')}>
                                    <ToggleButton className={classes.ToggleButton} value="average_price" aria-label="left aligned">
                                        Weighted Average
                                    </ToggleButton>
                                    <ToggleButton className={classes.ToggleButton} value="highest_lowest" aria-label="right aligned">
                                        Highest / Lowest
                                    </ToggleButton>
                                </ToggleButtonGroup>
                            </div>
                            {
                                latest_funding_rounds.length && total_lfr ? <div className={"legend-item orderbook lfr clickable"}>
                                        <Checkbox 
                                            className={classes.Checkbox}
                                            checked={show_latest_funding_rounds}
                                            onChange={this.handleChangePriceLineType.bind(this, 'show_latest_funding_rounds')}
                                            style={{
                                                color: 'rgba(34,139,34,1)'
                                            }}
                                            />
                                        <span className="label-text" style={{color: 'rgba(34,139,34,1)'}}>Last Funding Round Price (LFR)</span>
                                    </div> : ''
                            }
                            {
                                ( blockchain || ( reference_pricing.reference_names.length && reference_pricing.reference_pricing.length ) ) &&
                                price_chart_type == 'percent' && 
                                feature_flags.feature_is_available(
                                    appConfig, 
                                    feature_flags.flags.SecondaryActivity.flag_name, 
                                    feature_flags.flags.SecondaryActivity.attributes.btc_eth_permission,
                                    email
                                    ) ? <div className={'legend-item orderbook ref-price clickable'}>
                                        <Checkbox
                                            className={classes.Checkbox}
                                            checked={show_ref_price}
                                            onChange={this.handleChangePriceLineType.bind(this, 'show_ref_price')}
                                            style={{ color: '#873e23' }}
                                            />
                                        <span className='label-text' style={{ color: '#873e23' }}>Reference Price</span>
                                    </div> : ''
                            }
                        </div>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <span style={{ marginRight: 5, fontSize: 12 }}>Zoom</span>
                        {
                                feature_flags.feature_is_available(
                                    appConfig,
                                    feature_flags.flags.SecondaryActivity.flag_name,
                                    feature_flags.flags.SecondaryActivity.attributes.extended_price_chart,
                                    email
                                ) ?
                                    <ToggleButtonGroup exclusive value={zoom_level} onChange={this.handleChangePriceLineType.bind(this, 'zoom_level')}>
                                        <ToggleButton className={classes.ToggleButton} value="1m" aria-label="1m">
                                            1m
                                        </ToggleButton>
                                        <ToggleButton className={classes.ToggleButton} value="3m" aria-label="3m">
                                            3m
                                        </ToggleButton>
                                        <ToggleButton className={classes.ToggleButton} value="6m" aria-label="6m">
                                            6m
                                        </ToggleButton>
                                        <ToggleButton className={classes.ToggleButton} value="1y" aria-label="1y">
                                            1y
                                        </ToggleButton>
                                        <ToggleButton className={classes.ToggleButton} value="3y" aria-label="3y">
                                            3y
                                        </ToggleButton>
                                        <ToggleButton className={classes.ToggleButton} value="all" aria-label="all">
                                            all
                                        </ToggleButton>
                                    </ToggleButtonGroup>

                                : <ToggleButtonGroup exclusive value={zoom_level} onChange={this.handleChangePriceLineType.bind(this, 'zoom_level')}>
                                    <ToggleButton className={classes.ToggleButton} value="1m" aria-label="1m">
                                        1m
                                    </ToggleButton>
                                    <ToggleButton className={classes.ToggleButton} value="3m" aria-label="3m">
                                        3m
                                    </ToggleButton>
                                    <ToggleButton className={classes.ToggleButton} value="6m" aria-label="6m">
                                        6m
                                    </ToggleButton>
                                    
                                    <ToggleButton className={classes.ToggleButton} value="all" aria-label="all">
                                        all
                                    </ToggleButton>
                                </ToggleButtonGroup>
                            }
                        
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12} style={{position: 'relative'}}>
                        <div className="chart-wrapper">
                            {
                                showBlurImage || public_mode
                                ?
                                    <LockedGraph 
                                        {...{
                                            public_mode,
                                            company_detail
                                        }}
                                        company_id={company_detail.id}
                                        company_name={company_detail.name} />
                                :
                                <ReactECharts 
                                    ref={this.myChart}
                                    option={getOption(this.state, this.props)} 
                                    style={{height: hidefullscreen ? 700 : 550}}
                                    notMerge={true}
                                    />
                            }
                        </div>
                        {
                            !showBlurImage ? 
                                <ToggleButtonGroup 
                                    style={{ position: 'absolute', right: !hidefullscreen ? 45 : 120, top: !hidefullscreen ? 280 : '50%' }} 
                                    exclusive value={bar_type} onChange={this.handleChangePriceLineType.bind(this, 'bar_type')}>
                                    <ToggleButton className={classes.ToggleButton} value="notional" aria-label="left aligned">
                                        Notional
                                    </ToggleButton>
                                    <ToggleButton className={classes.ToggleButton} value="order_count" aria-label="right aligned">
                                        Order Count
                                    </ToggleButton>
                                </ToggleButtonGroup> : ''
                        }
                        
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        {/* <span className='chart-disclaimer'><sup>(1)</sup> Price per share, </span> */}
                        {/* <span className='chart-disclaimer'><sup>(2)</sup> No live orders available, </span> */}
                        {/* <span className='chart-disclaimer'><sup>(3)</sup> Implied valuation (estimated),</span> */}
                        {/* <span className='chart-disclaimer'><sup>(4)</sup> Last funding round price </span> */}
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <p className='elaborate-text' style={{ marginTop: '10px' }}>The content is provided for informational purposes only. Gateway does not guarantee nor make any representation or warranty as to the accuracy, validity, timeliness, completement or continued availability of the content.</p>
                    </Grid>
                </Grid>
            </section>
        )
    }
}

export default withStyles(PriceChartStyle)(PriceChart);