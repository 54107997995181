import { 
    Grid, 
    Paper,
    Typography,
    Button, 
} from '@material-ui/core';
import React from 'react';

import {
    numberWithCommas,
    showBlurred,
    longBlurText,
} from '../../../../utils/util';

import {
    withStyles,
} from '@material-ui/core';

import {
    globalFontFamily
} from '../../../styles';

import {
    WatchlistButton,
} from '../../../orderbook/common/components';

import {
    feature_flags,
} from '../../../../config';

function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
}

const CompanyInfoStyle = theme => ({
    HeaderOne: {
        fontSize: '2.5rem',
        fontFamily: globalFontFamily,
    },
    HeaderBlurred: {
        fontSize: '1.5rem',
        fontFamily: globalFontFamily,
    }
})

export default withStyles(CompanyInfoStyle)((props) => {
    let categories = props.vertical_categories && props.vertical_categories.length > 0 ? props.vertical_categories : []

    categories = categories.filter(onlyUnique);

    const { appConfig, loginUserData: { email } } = props;
    const blur_text = longBlurText();
    return (
        <Paper style={{ padding: 16 }}>
            <Grid container spacing={2}>
                <Grid item xs={6} sm={3} className="image-design">
                    <div className={props.logo_url ? "logo-hoder-2" : "logo-hoder-2 wider"}>
                        {
                            props.logo_url && !showBlurred(props.logo_url) ? 
                                <img
                                    className="w-100"
                                    src={ props.logo_url }
                                    alt={props.name}
                                    /> : 
                                <Typography 
                                    className={
                                        showBlurred(props.logo_url) ?
                                        props.classes.HeaderBlurred :
                                        props.classes.HeaderOne} 
                                        variant="h1">{props.name}</Typography>
                        }
                    </div>
                    {/* {
                        feature_flags.feature_is_available(
                            appConfig,
                            feature_flags.flags.Watchlist.flag_name,
                            feature_flags.flags.Watchlist.attributes.add_remove_watchlist,
                            email
                        ) ? <span onClick={(evt) => props.handleToggleWatchList(evt, { company_id: props.id, watchlist: props.watchlist })}>
                                <WatchlistButton
                                    active_watchlist={props.watchlist}
                                    />
                            </span> : ''
                    } */}
                </Grid>
                <Grid item  xs={6} sm={1}>
                    <span className="company-info-label">Founded</span>
                    <span className="smallText orderbook">
                        {
                            showBlurred(props.founded) ?
                                <span className="blurred">{props.founded}</span> :
                            props.founded
                        }
                    </span>
                </Grid>
                <Grid item  xs={6} sm={1}>
                    <span className="company-info-label">Team Size</span>
                    <span className="smallText orderbook">
                        {
                            props.team_size ?
                                showBlurred(props.team_size) ?
                                    <span className="blurred">{props.team_size}</span> :
                                numberWithCommas(props.team_size, 0) : ''}
                    </span>
                </Grid>
                <Grid item xs={6} sm={2}>
                    <span className="company-info-label">Total Raised(US$M)</span>
                    <span className="smallText orderbook">
                        {
                            props.total_raised ?
                                showBlurred(props.total_raised) ?
                                    <span className="blurred">{props.total_raised}</span> :
                                `$${numberWithCommas(props.total_raised, 0)}M` : 
                            ' - '
                        }
                    </span>
                </Grid>
                <Grid item xs={6} sm={2}>
                    <span className="company-info-label">Headquarter Country</span>
                    <span className="smallText orderbook">
                        {
                            props.headquarter_country ?
                                showBlurred(props.headquarter_country) ?
                                    <span className="blurred">{props.headquarter_country}</span> :
                                props.headquarter_country : ' - '
                        }
                    </span>
                </Grid>
                <Grid item xs={6} sm={2}>
                    <span className="company-info-label">Website</span>
                    <span className="smallText orderbook">
                        {
                            props.website ?
                                showBlurred(props.website) ?
                                    <span className="blurred">{props.website}</span> :
                                <a href={props.website.indexOf('http') > -1 ? props.website : 'http://'+props.website} target="_blank">{props.website}</a>
                                : ''
                        }
                    </span>
                </Grid>
                <Grid item xs={12}>
                    <span className="company-info-label orderbook">{ categories.join(", ") }</span>
                </Grid>
                <Grid item xs={12} container>
                    <Grid item xs={12}>
                        <span className="company-info-label orderbook">Description</span>
                        <span className="smallText orderbook">
                            {
                                showBlurred(props.description) ?
                                    <span className="blurred">{blur_text}</span> :
                                    props.description
                            }
                        </span>
                    </Grid>
                </Grid>
            </Grid>
            {
                // props.similar_companies.resultList.length ? 
                //     <Grid container spacing={2}>
                //         <Grid item xs={12}>
                //             <span className="company-info-label orderbook" style={{ marginBottom: 5 }}>Similar Company</span>
                //             {
                //                 props.similar_companies.resultList.map((item, index) => {
                //                     return (
                //                         <Button key={index} href={`/orderbook/company-detail/${item.id}`} className="btn-similar-company">
                //                             {/* {item.logo_url ? <img src={item.logo_url} /> : ''} */}
                //                             <span>{item.name}</span>
                //                         </Button>
                //                     )
                //                 })
                //             }
                //         </Grid>
                //     </Grid> : ''
            }
            
        </Paper>
        )
});
