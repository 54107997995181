import { connect } from 'react-redux';
import { default as PlaceOrder } from './PlaceOrder';

import {
    postPlaceOrder,
    getSearchCompany,
    clearPlaceOrderMsg,
    lockCompany,
    getFundingRound,
} from './Action';

export default connect(
    state => ({
        placeorderResponse: state.orderbookPlaceOrder.reducer.placeorderResponse,
        searchCompanyResponse: state.orderbookPlaceOrder.reducer.searchCompanyResponse,
        search_company_loading: state.orderbookPlaceOrder.reducer.search_company_loading,
        frs: state.orderbookPlaceOrder.reducer.frs,
        // lock_company: state.orderbookPlaceOrder.reducer.lock_company,
        company_detail: state.orderbookCompany.reducer.company_detail,
    }),
    dispatch => ({
        postPlaceOrder: (payload) => dispatch(postPlaceOrder.request(payload)),
        getSearchCompany: (payload) => dispatch(getSearchCompany.request(payload)),
        clearPlaceOrderMsg: () => dispatch(clearPlaceOrderMsg()),
        lockCompany: (payload) => dispatch(lockCompany(payload)),
        getFundingRound: (payload) => dispatch(getFundingRound.request(payload)),
    })
)(PlaceOrder);