import React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Slider from "@material-ui/core/Slider";


export const FilterSlider = withStyles({
    root: {
      color: "#FFC50D",
      height: 2,
      padding: "15px 0"
    },
    thumb: {
        height: 18,
        width: 18,
        backgroundColor: "#fff",
        border: "2px solid currentColor",
        marginTop: -8,
        marginLeft: -12,
        "&:focus, &:hover, &$active": {
          boxShadow: "inherit"
        }
      },
    active: {},
    valueLabel: {
      left: "calc(-50% + 12px)",
      top: -22,
      "& *": {
        background: "transparent",
        color: "#000"
      }
    },
    track: {
      height: 4
    },
    rail: {
      height: 4,
      opacity: 0.5,
      backgroundColor: "#bfbfbf"
    },
    mark: {
      backgroundColor: "#bfbfbf",
      height: 8,
      width: 1,
      marginTop: -3
    },
    markActive: {
      opacity: 1,
      backgroundColor: "currentColor"
    }
  })(Slider);
  
 export const InterestSlider = withStyles({
    root: {
      color: "#FFC50D",
      height: 8
    },
    thumb: {
      height: 18,
      width: 18,
      backgroundColor: "#fff",
      border: "2px solid currentColor",
      marginTop: -8,
      marginLeft: -12,
      "&:focus, &:hover, &$active": {
        boxShadow: "inherit"
      }
    },
    active: {},
    valueLabel: {
      left: "calc(-50% + 4px)"
    },
    track: {
      height: 4,
      borderRadius: 4
    },
    rail: {
      height: 4,
      borderRadius: 4
    }
  })(Slider);
  