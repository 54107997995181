import {
    roundNumber
} from '../../../../utils/util';

export const getOption = (state) => {

    const {
        trends,
        date,
        min_y,
    } = state;

    console.log( min_y );

    // console.log( trends );
    // console.log( date );

    const option = {
        legend: {
            bottom: '0%',
            padding: [
                50, 0, 0, 0
            ],
            show: false
        },
        xAxis: {
            type: 'category',
            boundaryGap: false,
            data: date,
            nameTextStyle: {
                fontFamily: 'Roboto',
                fontSize: 12
            },
            axisLabel: {
                fontFamily: 'Roboto',
                fontSize: 12,
                rich: {
                    fontFamily: 'Roboto',
                    fontSize: 12
                }
            }
        },
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'cross'
            },
            extraCssText: 'z-index: 9999'
        }
    }

    const series= [];

    for( var key in trends ){
        series.push({
            name: key,
            type: 'line',
            data: trends[key].map( item => { return roundNumber(item) }),
        })
    }

    option['series'] = series;
    option['yAxis'] = {
        min: min_y,
        type: 'value',
        nameTextStyle: {
            fontFamily: 'Roboto',
            fontSize: 12
        },
        axisLabel: {
            formatter: '{value}',
            fontFamily: 'Roboto',
            fontSize: 12,
            rich: {
                fontFamily: 'Roboto',
                fontSize: 12
            }
        },
        axisPointer: {
            snap: true
        }

    }

    return option['xAxis'] && option['yAxis'] && option['series'] ? option : {};
}