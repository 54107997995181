import {
    CreateApiAction as _createApiAction,
    CreateKeyAction as _createKeyAction,
} from '../../../../../utils';

const key = 'PORTFOLIO_HOLDING_CONTROL';

const CreateApiAction = _createApiAction(key);

const getSearchCompany = CreateApiAction('SEARCH_COMPANY');
const postSubmitPortfolio = CreateApiAction('POST_SUBMIT_PORTFOLIO');

const CreateKeyAction = _createKeyAction(key);
const lockCompany = CreateKeyAction('LOCK_COMPANY');

export {
    getSearchCompany,
    lockCompany,
    postSubmitPortfolio,
}