import React, { Component } from 'react';
import logoImg from '../../assets/images/logo.png';

export class Maintenance extends Component{
    state={}
    
    constructor(props){
        super(props);
    }

    render(){

        return (
            <div className="maintenance-container">
                <img src={logoImg} alt="Gateway Private Markets" className="logo-img" />
                <h1>Under Maintenance</h1>
                <p>The Gateway portal is under scheduled maintenance. Please visit back later. Thank you.</p>
            </div>
        )
    }

}