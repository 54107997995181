import {
    put,
    call,
    takeLatest,
    all,
    fork,
} from 'redux-saga/effects';

function* saga(){
    yield all([
        
    ])
}

export default saga;