import { combineReducers } from "redux";
import {
    updateNotificationPreferences
} from './Action';

import {
    resetSnackBar
} from '../../account/Action'

import { default as InitialState } from './InitialState';

export default combineReducers({
    reducer(state = InitialState, action){
        switch( action.type ){
            case resetSnackBar.toString():
            case updateNotificationPreferences.request.toString():
                return Object.assign({}, state, {
                    updateNotificationPreferencesResponse: ''
                })
            case updateNotificationPreferences.success.toString():
                return Object.assign({}, state, {
                    updateNotificationPreferencesResponse: 'Notification preference updated.'
                })
            case updateNotificationPreferences.failure.toString():
                return Object.assign({}, state, {
                    updateNotificationPreferencesResponse: 'Error occured. Please try again later. If the problem persist, kindly contact us at info@gatewaypmx.com.'
                })
            default:
                return state;
        }
    }
})