import React from 'react';
import {
    default as Base
} from './BaseTooltip';

export default (props) => {
    return <Base 
                {...props}
                items={
                    [
                        // 'Interest: You are expressing general interest in the company without a specific price in mind.',
                        'Indication: You are expressing interest in the company with an indication of specific price and size to find a matching counterparty to transact.',
                        'Firm: You are expressing interest to place a firm order with a specified price and size.  The Gateway team will follow up on the commitment terms with you.',
                    ]
                }
                /> 
}