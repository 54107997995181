import { combineReducers } from 'redux';

import {
    postPlaceInterest,
    getSearchCompany,
    clearNoMatch,
    clearPlaceInterestMsg,
} from './Action';

import { default as InitialState } from './InitialState';

export default combineReducers({
    reducer(state=InitialState, action){
        switch( action.type ){
            case getSearchCompany.request.toString():
                return Object.assign({}, state, {
                    searchCompanyResponse: [],
                    search_company_loading: true,
                    no_match: false,
                });
            case getSearchCompany.failure.toString():
                return Object.assign({}, state, {
                    searchCompanyResponse: [],
                    search_company_loading: false,
                    no_match: false,
                });
            case getSearchCompany.success.toString():
                return Object.assign({}, state, {
                    searchCompanyResponse: action.payload,
                    search_company_loading: false,
                    no_match: action.payload.length ? false : true
                });
            case postPlaceInterest.request.toString():
                return Object.assign({}, state, {
                    postPlaceInterestResponse: {
                        loading: true,
                        error: false,
                        message: ''
                    }
                });
            case postPlaceInterest.failure.toString():
                return Object.assign({}, state, {
                    postPlaceInterestResponse: {
                        loading: false,
                        error: true,
                        message: 'There are some issue in submitting your interest. Please try again later. If the problem persist, kindly contact us via email or live chat.'
                    }
                });
            case postPlaceInterest.success.toString():
                return Object.assign({}, state, {
                    postPlaceInterestResponse: {
                        loading: false,
                        error: false,
                        message: 'We have received your interest. Your Gateway representative will reach out to you shortly.'
                    }
                });
            case clearNoMatch.toString():
                return Object.assign({}, state, {
                    no_match: false
                });
            case clearPlaceInterestMsg.toString():
                return Object.assign({}, state, {
                    postPlaceInterestResponse: {
                        loading: false,
                        error: false,
                        message: ''
                    }
                });

            default: 
                return state;
        }
    }
})