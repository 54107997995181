import React from 'react';
import { Helmet } from 'react-helmet';
import {
	Grid,
	Typography,
} from '@material-ui/core';


import { content_placeholders } from '../../config';
import FrontPageHeader from './FrontPageHeader';
import { default as ConnectedInvestorLogin } from './ConnectedInvestorLogin';
import { withRouter } from 'react-router-dom';
import PortalBanner from '../../assets/images/PortalBanner.jpg';

import {
	Footer,
} from '../common';

class Login extends React.Component {

	constructor(props) {
		super(props);
	}

	componentDidMount() {
		// if(GetToken()){
		// 	this.props.history.push('/app/Pipeline');
		// }
	}

	render() {
		const title = 'Login | ' + content_placeholders.title_prefix;
		return (
			<div>
				<Helmet>
					<title>{title}</title>
					<meta name="description" content={"Gateway Private Markets"} />
				</Helmet>
				<div className="main-page login">
					<div className="bg-color2">
						<FrontPageHeader />

						<Grid container spacing={2}>
							<Grid item xs={12} md={6}>
								<Grid container spacing={2}>
									<Grid item xs={0} md={1} style={{height: 1}}></Grid>
									<Grid item xs={12} md={11}>
									<div style={{paddingTop: 100}} className="tagline">
										<Typography variant="h6">
											The Platform For PE/VC Secondaries
										</Typography>
										<p>Network | Infrastructure | Data</p>
									</div>
									<ConnectedInvestorLogin {...this.props} />

									</Grid>
								</Grid>
							</Grid>
							<Grid item xs={12} md={6}>
								<div className="style-container">
									<div className="inner-style-container">
										<img src={PortalBanner} />
									</div>
									
									<div className="style-bar-container">
										<div className="third style-bar"></div>	
										<div className="primary style-bar"></div>
										<div className="secondary style-bar"></div>
									</div>
									
								</div>
								
							</Grid>
						</Grid>
					</div>
				</div>
				
				<Footer />
			</div>
		);
	}
}

export default withRouter(Login);