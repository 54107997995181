import React, { Fragment } from "react";
import { 
	Button,
	Tooltip,
 } from "@material-ui/core";
import arrowIcon from "../../../../assets/images/arrow-icon.png";
import docIcon from "../../../../assets/images/doc-icon.png";
import LaptopWindowsIcon from '@material-ui/icons/LaptopWindows';
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";

import { CompanyStatusCalculate, showBlurred } from "../../../../utils/util";
import { content_placeholders } from '../../../../config';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import BorderColorIcon from '@material-ui/icons/BorderColor';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

export default (props) => {
	const {

        handleShowExpressInterest,
        handlePromptExpress,
	} = props;

	let result = CompanyStatusCalculate(props.clubDetail);
	const { loginUserData } = props;
	let user_type = ""
	if( loginUserData ){
		const { activeAccessRight: { access_right: { permission } } } = loginUserData;
		user_type = permission;
	}
	const button_icon = props.collapse ? <ArrowForwardIosIcon /> : <ArrowBackIosIcon />;
	return (
		<div>
			

			<div className="cover">
				<Button onClick={props.handleSidebarCollapse}>{button_icon}</Button>
			</div>

			<div className="sidebar_menu">
				<section className="comp-title-container sidebar-section">
					<div className="image-design flex-row">
						<div className="logo-hoder-2">
							{props.companyDetailResponse &&
							props.companyDetailResponse.logo_url &&
							!showBlurred(props.companyDetailResponse.logo_url) ? (
								<img
									className="w-100"
									src={props.companyDetailResponse.logo_url}
									alt={props.companyDetailResponse.name}
								/>
							) : (
								""
							)}
						</div>

					</div>

					
					<div className="clear"></div>
				</section>

				<section className="control-container sidebar-section">
					<div className="row">
						<div className="col col-12">
							<Button
								className="btn-mid express-interest"
								// onClick={(e) =>
								// 	props.handleExpressBuySellDialogOpen(
								// 		{
								// 			price: props.clubDetail.price,
								// 			min_size: props.clubDetail.min_size,
								// 			company: props.companyDetailResponse.name,
								// 			logo: props.companyDetailResponse.logo,
								// 			dialogTitle: content_placeholders.buttons.express_interest,
								// 			interest_type: "INVEST",
								// 		},
								// 		e
								// 	)
								// }
								onMouseEnter={(evt) => handlePromptExpress(evt, true)}
								onMouseLeave={(evt) => handlePromptExpress(evt, false)}
								onClick={(evt) => handleShowExpressInterest(evt, true)}
							>
								<Tooltip
									title={content_placeholders.buttons.express_interest}
									>
									<BorderColorIcon />
								</Tooltip>
								
								{
									!props.collapse ?
										props.loginUserData.activeAccessRight &&
										props.loginUserData.activeAccessRight.access_right &&
										props.loginUserData.activeAccessRight.access_right.permission == content_placeholders.user_type.trial_user ? 
											content_placeholders.buttons.request_full_access :
											props.clubDetail.price 
											? content_placeholders.buttons.invest_now
											: content_placeholders.buttons.express_interest
										: ''
								}
							</Button>

							<Button
								className="btn-mid request-information"
								onClick={(e) =>
									props.handleRequestInformationDialogOpen(
										{
											price: props.clubDetail.price,
											min_size: props.clubDetail.min_size,
											company: props.companyDetailResponse.name,
											logo: props.companyDetailResponse.logo,
											dialogTitle: content_placeholders.buttons.request_for_information,
											interest_type: "INVEST",
										},
										e
									)
								}
							>
								<Tooltip
									title={'Request Info'}
									>
									<HelpOutlineIcon />
								</Tooltip>
								
								{!props.collapse ? 'Request Info' : ''}
							</Button>
						</div>
					</div>
					{/* {props.clubDetail && props.clubDetail.company && !props.clubDetail.company.name && (
						<div className="row pt-2">
							<div className="col col-12">
								<Button
									className="btn-mid express-interest"
									onClick={(e) =>
										props.handleExpressBuySellDialogOpen(
											{
												price: props.clubDetail.price,
												min_size: props.clubDetail.min_size,
												company: props.companyDetailResponse.name,
												logo: props.companyDetailResponse.logo,
												dialogTitle: content_placeholders.buttons.express_sell_interest,
												interest_type: "SELL",
											},
											e
										)
									}
								>
									<HelpOutlineIcon />
									{props.loginUserData.activeAccessRight &&
									 props.loginUserData.activeAccessRight.access_right &&
									 props.loginUserData.activeAccessRight.access_right.permission == content_placeholders.user_type.trial_user ? 
										content_placeholders.buttons.request_full_access :
											props.clubDetail.price
											? content_placeholders.buttons.invest_now
											: content_placeholders.buttons.express_sell_interest}
									<img src={arrowIcon} className="arrow-icon" />
								</Button>
							</div>
						</div>
					)} */}

					{props.clubDetail && props.clubDetail.committed_by && props.clubDetail.committed_by.toLowerCase().indexOf('gateway blurry') == -1 ? (
						<div className="row">
							<div className="col col-12">
								<div className="content-wrapper no-border">
									<div className="row">
										<div className="col col-12">
											<span className="committed-by">
												Indicative commitment by{" "}
												<strong>{props.clubDetail.committed_by}</strong>
											</span>
										</div>
									</div>
								</div>
							</div>
						</div>
					) : (
						""
					)}

					{props.clubDetail && props.clubDetail.committed_by && props.clubDealLinks.map((item, index) => {
						if (
							item.file_type == "application/pdf"
						) {
							return (
								<div className="row" key={index}>
									<div className="col col-12">
										<div className="content-wrapper no-border">
											
												<a
													href={item.url}
													key={index}
													onClick={(e) =>
														props.handleTeaserDialogOpen(
															item.url,
															item.link_name,
															e
														)
													}
													className="btn-mid"
												>
													<img src={docIcon} className="doc-icon" />
													View {item.link_name}
												</a>
											
										</div>
									</div>
								</div>
							);
						} else if( item.file_type == "video/mp4" ){
							return (
								<div className="row" key={index}>
									<div className="col col-12">
										<div className="content-wrapper no-border">
											
												<a
													href={item.url}
													key={index}
													onClick={(e) =>
														props.handleVideoDialogOpen(
															item.url,
															item.link_name,
															e
														)
													}
													className="btn-mid"
												>
													<LaptopWindowsIcon />
													View {item.link_name}
												</a>
											
										</div>
									</div>
								</div>
							)
						}
					})}
				</section>

			</div>
		</div>
	);
};
