import React, { useState, useRef, } from 'react';

import {
    Box,
    Grid,
    Paper,
    Button,
    useMediaQuery,
    Tooltip,
} from "@material-ui/core";

import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ShareIcon from '@material-ui/icons/Share';

import {
    CompanyInfo,
    FundingRounds,
    LiveOrderbook,
    PriceChart,
} from './components';

import {
    CompanySearch,
} from '../common';
import DisclaimerData from '../../common/DisclaimerData';

import {
    feature_flags,
} from '../../../config';

import {
    globalMD,
} from '../../styles';

export default (props) => {
    const {
        company_detail,
        company_verticals,
        similar_companies,
        appConfig,
        loginUserData,
        loginUserData: { email },
        public_mode,
        primary_activity_deals,
        funding_rounds,
        historical_pricing,
        reference_pricing,
        live_offers,
        live_demand,
        secondary_activity_deals,
        // id,
        longest_digit,
        live_offers_watchlist,
        live_demand_watchlist,
        vci_historical_revenue,
        vci_cap_table,
        vci_funding_rounds,
        sharedPage,
        match: { params : { id, token } },
        location: { pathname },
    } = props;
    const isMobile = useMediaQuery(`(max-width:${globalMD}px)`);
    const watermark_class = sharedPage && email ? `watermark-container` : '';
    const watermark_wording = sharedPage && email ? `Gateway Private Markets - Private & Confidential, Prepared for ${email}` : ''
    const fundingRoundRef = useRef(null);
    
    const blockchain_vertical = company_verticals.filter(ver => ver && ver.vertical && (ver.vertical.name && ver.vertical.name.indexOf('Blockchain') > -1 || ver.vertical.name && ver.vertical.name.indexOf('Crypto') > -1 || ver.vertical.name && ver.vertical.name.indexOf('Gaming') > -1));
    
    return (
        <Box flexGrow={1} m={2}>
            <Grid container spacing={2}>

                <Grid item xs={12}>
                    <Grid container spacing={2} style={{paddingTop: !isMobile && '15px'}}>
                        <Grid item xs={isMobile ? 12 : 7}>
                            {
                                email && !public_mode ?
                                    <Button onClick={props.handleGoBack}>
                                        <ArrowBackIcon />
                                    </Button> : ''
                            }
                            {
                                props.handleShowDealShareDialog &&
                                    !feature_flags.feature_is_available(//temp, need update later
                                        appConfig,
                                        feature_flags.wealth_flags.DealShare.flag_name,//temp, need update later
                                        feature_flags.wealth_flags.DealShare.attributes.share_button,//temp, need update later
                                        email
                                    )
                                    ?
                                    <Tooltip
                                        title={'Share this deal opportunity'}
                                    >
                                        <Button
                                            onClick={(evt) => props.handleShowDealShareDialog(evt)}
                                        >
                                            <ShareIcon />
                                            <span className="smallText orderbook" style={{marginLeft: '5px'}}>
                                                Share this company
                                            </span>
                                        </Button>

                                    </Tooltip> : ''
                            }
                        </Grid>
                        <Grid item xs={isMobile ? 12 : 5}>
                            {
                                !sharedPage ?
                                    <CompanySearch 
                                        containerId='company-name-input-container'
                                        handleShowWatchlistFromSearchCompany={props.handleShowWatchlistFromSearchCompany} />
                                    : ''
                            }
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12} className={watermark_class}>
                    <CompanyInfo
                        handleToggleWatchList={props.handleToggleWatchList}
                        handleClickVciFundingRound={() => fundingRoundRef.current?.scrollIntoView({behavior: 'smooth'})}
                        {...{
                            ...company_detail,
                            company_verticals,
                            similar_companies,
                            appConfig,
                            loginUserData,
                            sharedPage,
                            public_mode,
                            vci_historical_revenue,
                            vci_cap_table,
                            vci_funding_rounds,
                        }}
                    />
                    {
                        sharedPage && email ?
                            <div className="watermark-label">{watermark_wording}</div>
                            : ''
                    }
                </Grid>

                <Grid item container spacing={2} ref={fundingRoundRef}>
                    <Grid item xs={12} md={6} className={watermark_class}>
                        <Paper style={{ padding: 16, width: '100%', height: '100%', position: 'relative' }}>
                            <FundingRounds
                                handleShowOrderDetailDialog={props.handleShowOrderDetailDialog}
                                {...{
                                    company_detail,
                                    vci_funding_rounds,
                                    primary_activity_deals,
                                    appConfig,
                                    loginUserData,
                                }}
                                {...funding_rounds}
                            />
                        </Paper>
                        {
                            sharedPage && email ?
                                <div className="watermark-label">{watermark_wording}</div>
                                : ''
                        }
                    </Grid>
                    <Grid item xs={12} md={6} className={watermark_class}>
                        <Paper style={{ padding: 16, width: "100%", height: '100%' }}>
                            <PriceChart
                                {...{
                                    ...{ company_id: id, token: token, blockchain: blockchain_vertical.length },
                                    historical_pricing,
                                    reference_pricing,
                                    funding_rounds,
                                    live_offers,
                                    live_demand,
                                    secondary_activity_deals,
                                    public_mode,
                                    company_detail,
                                    appConfig,
                                    loginUserData,
                                }}
                                handleShowOrderDetailDialog={props.handleShowOrderDetailDialog}
                                handlePriceChartFullScreen={props.handlePriceChartFullScreen}
                                handlePrefillPlaceOrder={props.handlePrefillPlaceOrder}
                            />
                        </Paper>
                        {/* {
                            sharedPage && email ?
                                <div className="watermark-label">{watermark_wording}</div>
                                : ''
                        } */}
                    </Grid>
                    <Grid item xs={12} className={watermark_class}>
                        <Paper style={{ padding: 16, width: '100%', height: '100%' }}>
                            <LiveOrderbook
                                handlePrefillPlaceOrder={props.handlePrefillPlaceOrder}
                                handleShowExpressInterest={props.handleShowExpressInterest}
                                handleShowOrderDetailDialog={props.handleShowOrderDetailDialog}
                                handlePromptExpress={props.handlePromptExpress}
                                {...{
                                    live_offers,
                                    live_demand,
                                    funding_rounds,
                                    historical_pricing,
                                    longest_digit,
                                    appConfig,
                                    loginUserData,
                                    live_offers_watchlist,
                                    live_demand_watchlist,
                                }}
                            />
                        </Paper>
                        {
                            sharedPage && email ?
                                <div className="watermark-label">{watermark_wording}</div>
                                : ''
                        }
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <DisclaimerData
                        {...{ token, pathname }}
                        />
                </Grid>
            </Grid>
        </Box>
    )
}