import {
    CreateApiAction as _createApiAction,
    CreateKeyAction as _createKeyAction,
} from '../../../../utils';

const key = 'WEALTH_PLACEINTEREST';

const CreateApiAction = _createApiAction(key);

const postPlaceInterest = CreateApiAction('PLACEINTEREST');
const getSearchCompany = CreateApiAction('SEARCH_COMPANY');

const CreateKeyAction = _createKeyAction(key);
const clearPlaceInterestMsg = CreateKeyAction('CLEAR_PLACEINTEREST_MSG');
const clearNoMatch = CreateKeyAction('CLEAR_NO_MATCH');

export {
    postPlaceInterest,
    getSearchCompany,
    clearPlaceInterestMsg,
    clearNoMatch,
}