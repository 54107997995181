import React, { Component } from "react";
import { Link } from 'react-router-dom';
import {
    AddCircleOutline,
    Star,
    MoreHoriz,
    Equalizer,
} from "@material-ui/icons";

import {
    IconButton,
    Menu,
    MenuItem,
    Box,
    Grid,
    Paper,
    Card,
    Tooltip,
} from "@material-ui/core";
import {
    ToggleButtonGroup,
    ToggleButton,
} from '@material-ui/lab';
import {
	global150BodyFont,
	globalBodyFont,
	globalFontFamily,
} from '../../../styles';

import {
    WatchlistGraph
} from '../graph';
import { withRouter } from "react-router-dom";

import {
    DeleteCompanyDialog,
    DeleteGroupDialog,
    EditGroupDialog,
    CreateNewGroupDialog,
    PriceChart,
    WatchlistGroupView,
} from "./components";

import {
    WatchlistSnackbar,
} from '../../../orderbook/common/components';

import {
    feature_flags,
} from '../../../../config';

import {
    numberWithCommas,
    displayVal,
    getImpliedVal,
    redirectPortalByUserType,
    ReScaleForDeviceScale,
    showBlurred,
} from '../../../../utils/util';

class WatchlistGroup extends Component {
    state = {
        selectedGroupAtIndex: 0,
        menuAnchorEl: null,
        menuOpen: false,
        watchlistGroupList: [],
        watchlistGroupItem: {},
        deleteDialigOpen: false,
        groupNameInDialog: '',
        groupIdInDialog: 0,
        editDialigOpen: false,
        groupDescriptionInDialog: '',
        newDialigOpen: false,
        deleteCompanyDialog: {
            open: false,
            companyId: 0,
            groupId: 0,
        },
        createWatchListResponse: {},
        isOrderBookPortal: window.location.pathname.includes('/orderbook/'), // temporary
        show_grid: 'watchlist_grid',
        graph_payload: {
            company_id: '',
        }
    }

    componentDidMount() {
        const {
            watchlistGroupList,
        } = this.props;
        if (watchlistGroupList && watchlistGroupList.length > 0) {
            this.updateGroupItemDetail()
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {
            watchlistGroupList,
        } = this.state;
        if (
            watchlistGroupList && watchlistGroupList.length > 0 && 
            watchlistGroupList != prevState.watchlistGroupList) {
            this.updateGroupItemDetail()
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const {
            watchlistGroupList,
            watchlistGroupItem,
            createWatchListResponse,

            getWatchlistGroupItem,
        } = nextProps;
        const {
            selectedGroupAtIndex,
            isOrderBookPortal,
        } = prevState;
        if (
            watchlistGroupList &&
            watchlistGroupList.length != prevState.watchlistGroupList.length
        ) {
            return {
                watchlistGroupList: watchlistGroupList,
            }
        } else if (watchlistGroupItem &&
            watchlistGroupItem != prevState.watchlistGroupItem
        ) {
            return {
                watchlistGroupItem: watchlistGroupItem,
            }
        } if (
            createWatchListResponse &&
            JSON.stringify(createWatchListResponse) !== JSON.stringify(prevState.createWatchListResponse)
        ) {
            // console.log("refresh right group list")
            getWatchlistGroupItem({
                grpId: watchlistGroupList[selectedGroupAtIndex].id,
                path: isOrderBookPortal ? '/v2/orderbook' : 'v2/wealth', 
            })
            return {
                createWatchListResponse: createWatchListResponse,
            };
        } else {
            return null
        }
    }

    updateGroupItemDetail = () => {
        const {
            getWatchlistGroupItem,
        } = this.props;
        let {
            watchlistGroupList,
            selectedGroupAtIndex,
            isOrderBookPortal,
        } = this.state;
        if (selectedGroupAtIndex >= watchlistGroupList.length) {
            selectedGroupAtIndex = watchlistGroupList.length - 1
        }
        this.setState({
            selectedGroupAtIndex: selectedGroupAtIndex
        }, () => {
            getWatchlistGroupItem({
                grpId: watchlistGroupList[selectedGroupAtIndex].id,
                path: isOrderBookPortal ? '/v2/orderbook' : 'v2/wealth', 
            })
        })
    }

    selectGroupAtindex = (index, event) => {
        this.setState({
            selectedGroupAtIndex: index
        }, () => {
            this.updateGroupItemDetail()
        })
    }

    handleClicMorekMenu = (event) => {
        this.setState({
            menuAnchorEl: event.currentTarget,
            menuOpen: true
        })
    }

    handleMoreMenuClose = () => {
        this.setState({
            menuAnchorEl: null,
            menuOpen: false
        })
    }

    handleMoreMenuItemClick = (event, index, name, id, description) => {
        this.handleMoreMenuClose()
        if (index === 0) {//edit
            this.setState({
                editDialigOpen: true,
                groupNameInDialog: name,
                groupIdInDialog: id,
                groupDescriptionInDialog: description,
            })
        } else if (index === 1) {//delete
            this.setState({
                deleteDialigOpen: true,
                groupNameInDialog: name,
                groupIdInDialog: id,
            })
        }
    }

    handleNewWatchlistGroupDialogOpen = () => {
        this.setState({
            newDialigOpen: true,
        })
    }

    handleCompanyDeleteDialogOpen = (evt, companyId, groupId) => {
        if (evt && evt.stopPropagation) evt.stopPropagation();
        if (evt && evt.preventDefault) evt.preventDefault();
        this.setState({
            deleteCompanyDialog: {
                open: true,
                companyId: companyId,
                groupId: groupId,
            }
        })
    }

    handleCloseCompanyDeleteDialog = (evt, confirmDelete) => {
        const {
            createWatchlist,
            createWatchlistV2,
        } = this.props;
        const {
            isOrderBookPortal,
            deleteCompanyDialog,
        } = this.state;
        if (confirmDelete) {
            const payload = {
                company_id: deleteCompanyDialog.companyId,
                watchlist_grp_id: deleteCompanyDialog.groupId,
                active: false,
                watchlist_type: null,
                price: null,
                valuation: null,
                deal_size: null,
                company_name: '',
                website: '',
            }
            isOrderBookPortal ? createWatchlistV2(payload) : createWatchlist(payload);
        }
        this.setState({
            deleteCompanyDialog: {
                open: false,
                companyId: 0,
                groupId: 0,
            }
        })
    }

    handleCloseGroupDeleteDialog = (evt, confirmDelete) => {
        let {
            deleteWatchlistGroupItem,
        } = this.props;
        let {
            groupIdInDialog,
            isOrderBookPortal,
        } = this.state;

        this.setState({
            deleteDialigOpen: false
        }, () => {
            if (confirmDelete) {
                deleteWatchlistGroupItem({
                    grpId: groupIdInDialog,
                    path: isOrderBookPortal ? '/v2/orderbook' : '/v2/wealth',
                })
            }
        })
    }

    handleCloseGroupEditDialog = (evt, confirmUpdate, name, description) => {
        let {
            updateWatchlistGroupItem,
        } = this.props;
        let {
            groupIdInDialog,
            isOrderBookPortal,
        } = this.state;
        this.setState({
            editDialigOpen: false
        }, () => {
            if (confirmUpdate) {
                updateWatchlistGroupItem({
                    grpId: groupIdInDialog,
                    watchlist_grp_name: name,
                    watchlist_grp_description: description,
                    notification_required: true,
                    path: isOrderBookPortal ? '/v2/orderbook' : 'v2/wealth', 
                })
            }
        })
    }

    handleCloseGroupCreateDialog = (evt, confirmCreate, name, description) => {
        const {
            createWatchlistGroupOnly
        } = this.props;
        const {
            isOrderBookPortal
        } = this.state;
        this.setState({
            newDialigOpen: false,
        }, () => {
            if(confirmCreate){
                createWatchlistGroupOnly({
                    watchlistName: name,
                    watchlistDescription: description,
                    path: isOrderBookPortal ? '/v2/orderbook' : '/v2/wealth',
                })
            }
        })
    }

    handleSnackbarClose = (evt, reason) => {
        const { resetCompanyWatchlistData } = this.props;
        resetCompanyWatchlistData();
    }

    handleShowCompGraph = (evt, company_id) => {
        const { graph_payload } = this.state;
        const { 
            history,
            getWatchlistHistoricalPrices,
            getFundingRound,
            appConfig,
            loginUserData,
        } = this.props;

        // console.log( loginUserData );
        if( graph_payload['company_id'] == company_id ){
            graph_payload['company_id'] = ''
        } else {
            const { activeAccessRight: { access_right: { permission } } } = loginUserData;
            graph_payload['company_id'] = company_id;

            const root_path = redirectPortalByUserType(permission, 'v2/orderbook');

            getWatchlistHistoricalPrices({
                root_path: root_path,
                sub_path: 'weighted-prices',
                company_identifier: '',
                qs: { company_id: company_id }
            });

            getFundingRound({
                root_path: 'v2',
                sub_path: redirectPortalByUserType(permission, 'orderbook'),
                company_identifier: '',
                qs: { company_id: company_id, show_all: 1 }
            });

            history.push(`#graph-${company_id}`)   
        }

        this.setState({ graph_payload });
        
    }

    handleToggleChange = (evt, newValue) => {
        const { history } = this.props;
        history.push('#graph')
        const value_to_apply = newValue ? newValue : evt.target && evt.target.value ? evt.target.value : 'watchlist_grid';
        this.setState({ show_grid: value_to_apply })
    }

    render() {
        let {
            watchlistGroupList,
            companyDetailType,
            createWatchListError,
            createWatchListMessage,
            appConfig,
            loginUserData,
            historical_pricing,
            funding_rounds,
        } = this.props;

        const { email } = loginUserData;

        let {
            selectedGroupAtIndex,
            menuAnchorEl,
            menuOpen,
            watchlistGroupItem,
            deleteDialigOpen,
            groupNameInDialog,
            editDialigOpen,
            groupDescriptionInDialog,
            newDialigOpen,
            deleteCompanyDialog,
            show_grid,
            graph_payload,
            isOrderBookPortal,
        } = this.state;

        // console.log( graph_payload );
        
        const paper_style = { padding: 16, width: '100%' }
        const header_title_style = {
            fontFamily: "'Roboto', sans-serif",
            fontSize: '12px',
            lineHeight: '14px',
            fontWeight: '700',
            color: 'white',
        }
        const item_grid_style = {
            display: 'flex',
            alignItems: 'center',
            cursor: 'default',
        }
        const item_sub_title_style = {
            fontFamily: "'Roboto', sans-serif",
            fontSize: '12px',
            lineHeight: '14px',
            fontWeight: '400',
            color: 'black',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            width: '100%',
            display: 'block',
        }

        const tighter_toggle_button_style = {
            fontSize: ReScaleForDeviceScale(1.5, global150BodyFont, globalBodyFont),
            fontFamily: globalFontFamily,
            padding: '0.2rem 0.35rem !important',
            width: '50%',
        }

        const multi_watchlist_available = feature_flags.feature_is_available(
            appConfig,
            feature_flags.watchlist_flags.Watchlist.flag_name,
            feature_flags.watchlist_flags.Watchlist.attributes.multiple_watchlist_group,
            email
        )

        const watchlist_graph_available = feature_flags.feature_is_available(
            appConfig,
            feature_flags.watchlist_flags.Watchlist.flag_name,
            feature_flags.watchlist_flags.Watchlist.attributes.watchlist_graph,
            email
        )

        const watchlist_action_available = multi_watchlist_available ? ['Edit', 'Delete'] : ['Edit'];

        return (
            <div className="watchlist-group-container">
                <Box m={0}>
                    {
                        multi_watchlist_available && <Grid item xs={12} sm={3}>
                            <Paper style={paper_style}>
                                <div>
                                    <span className="group-name">Watchlist</span>
                                    <div className="add-new-group" onClick={this.handleNewWatchlistGroupDialogOpen}>
                                        <AddCircleOutline style={{ fontSize: 20 }} /> <span> Create New Watchlist</span>
                                    </div>
                                    <div className="current-watchlist">
                                        {
                                            watchlistGroupList && watchlistGroupList.length > 0 &&
                                            watchlistGroupList.map((item, index) => {
                                                return <div key={index}
                                                    className="watchlist-item"
                                                    onClick={(event) => this.selectGroupAtindex(index, event)}>
                                                    <span>{item.name}</span>
                                                </div>
                                            })
                                        }
                                    </div>
                                </div>
                            </Paper>
                        </Grid>
                    }

                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={multi_watchlist_available ? 9 : 12}>
                            <Paper style={paper_style}>
                                <div>
                                    <span className="group-name">{watchlistGroupItem.name}</span>
                                    <span className="group-more-action-icon">
                                        <IconButton
                                            aria-label="more"
                                            id="long-button"
                                            aria-controls={menuOpen ? 'long-menu' : undefined}
                                            aria-expanded={menuOpen ? 'true' : undefined}
                                            aria-haspopup="true"
                                            size="small"
                                            onClick={this.handleClicMorekMenu}
                                        >
                                            <MoreHoriz style={{ fontSize: 20 }} />
                                        </IconButton>
                                        <Menu
                                            id="long-menu"
                                            MenuListProps={{
                                                'aria-labelledby': 'long-button',
                                            }}
                                            anchorEl={menuAnchorEl}
                                            open={menuOpen}
                                            onClose={this.handleMoreMenuClose}
                                            PaperProps={{
                                                style: {
                                                    maxHeight: 200,
                                                    width: "20ch"
                                                }
                                            }}
                                            anchorOrigin={{
                                                vertical: "bottom",
                                                horizontal: "center",
                                            }}
                                            transformOrigin={{
                                                vertical: "top",
                                                horizontal: "left",
                                            }}
                                        >
                                            {watchlist_action_available.map((option, index) => (
                                                <MenuItem
                                                    key={option}
                                                    onClick={event => this.handleMoreMenuItemClick(event, index,
                                                        watchlistGroupItem.name, watchlistGroupItem.id,
                                                        watchlistGroupItem.description)}
                                                >
                                                    {option}
                                                </MenuItem>
                                            ))}
                                        </Menu>
                                    </span>
                                    {/* <p className="group-description">{watchlistGroupItem.description}</p> */}
                                </div>
                                                        

                                {
                                    show_grid === 'watchlist_grid' ? 
                                        <WatchlistGroupView 
                                            {...{
                                                appConfig,
                                                loginUserData,
                                                watchlistGroupItem,
                                                companyDetailType,
                                                graph_payload,
                                                historical_pricing,
                                                funding_rounds,
                                            }}
                                            handleCompanyDeleteDialogOpen={this.handleCompanyDeleteDialogOpen}
                                            handleShowCompGraph={this.handleShowCompGraph}
                                            />
                                            :
                                            <Grid container spacing={2}>
                                                <WatchlistGraph
                                                    {...{
                                                        selectedGroupAtIndex,
                                                        graph_payload,
                                                        isOrderBookPortal,
                                                    }}
                                                />
                                            </Grid>
                                }
                            
                            </Paper>

                            
                        </Grid>
                    </Grid>
{/*                     
                    {
                        watchlist_graph_available ? <Grid container spacing={2}>
                            <Grid item xs={12} sm={multi_watchlist_available ? 9 : 12}>
                                <ToggleButtonGroup
                                    style={{ float: 'right' }}
                                    exclusive
                                    value={show_grid}
                                    onChange={this.handleToggleChange}
                                >
                                    <ToggleButton style={tighter_toggle_button_style} value="watchlist_grid" aria-label="left aligned">
                                        WATCHLIST
                                    </ToggleButton>
                                    <ToggleButton style={tighter_toggle_button_style} value="graph_grid" aria-label="right aligned">
                                        GRAPH
                                    </ToggleButton>
                                </ToggleButtonGroup>
                            </Grid>
                        </Grid> : ''
                    }
                     */}

                    
                </Box>

                <DeleteCompanyDialog
                    open={deleteCompanyDialog.open}
                    handleCloseCompanyDeleteDialog={this.handleCloseCompanyDeleteDialog}
                />

                <DeleteGroupDialog
                    open={deleteDialigOpen}
                    group_name={groupNameInDialog}
                    handleCloseGroupDeleteDialog={this.handleCloseGroupDeleteDialog}
                />

                <EditGroupDialog
                    open={editDialigOpen}
                    group_name={groupNameInDialog}
                    group_description={groupDescriptionInDialog}
                    handleCloseGroupEditDialog={this.handleCloseGroupEditDialog}
                />

                <CreateNewGroupDialog
                    open={newDialigOpen}
                    handleCloseGroupCreateDialog={this.handleCloseGroupCreateDialog}
                />
                <WatchlistSnackbar
                    handleSnackbarClose={this.handleSnackbarClose}
                    open={createWatchListMessage.trim() ? true : false}
                    error={createWatchListError}
                    message={createWatchListMessage}
                />
            </div>
        )
    }
}

export default withRouter(WatchlistGroup);


{/* <Grid container spacing={2}>
                            <Grid item xs={12} sm={multi_watchlist_available ? 9 : 12}>
                                <Paper style={paper_style}>
                                    <div>
                                        
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <Grid container spacing={2} style={{ background: '#263F7F', borderRadius: '4px' }}>
                                                    <Grid item xs={1}>

                                                    </Grid>
                                                    <Grid item xs={1}>
                                                        <span style={header_title_style}>Company</span>
                                                    </Grid>
                                                    <Grid item xs={1}>
                                                        <span style={header_title_style}>Type</span>
                                                    </Grid>
                                                    <Grid item xs={2}>
                                                        <span style={header_title_style}>Price / Size</span>
                                                    </Grid>
                                                    <Grid item xs={2}>
                                                        <span style={header_title_style}>
                                                            Highest Bid (Implied Val.)
                                                        </span>
                                                    </Grid>
                                                    <Grid item xs={2}>
                                                        <span style={header_title_style}>
                                                            Lowest Offer (Implied Val.)
                                                        </span>
                                                    </Grid>
                                                    <Grid item xs={2}>
                                                        <span style={header_title_style}>
                                                            Last Funding Round (Valuation)
                                                        </span>
                                                    </Grid>
                                                    <Grid item xs={1}>

                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                            {
                                                watchlistGroupItem && watchlistGroupItem.company && watchlistGroupItem.company.length > 0 &&
                                                watchlistGroupItem.company.map((item, index) => {
                                                    // console.log( item );
                                                    let type_label = '';
                                                    if (item.my_bid_price || item.my_bid_valuation) type_label += 'Buy';
                                                    if (item.my_ask_price || item.my_ask_valuation) type_label += type_label.trim() ? ' / Sell' : 'Sell';
                                                    if (!type_label.trim()) type_label = '-';

                                                    let target_label = '';
                                                    if (type_label.indexOf('Buy') > -1) {
                                                        target_label += item.my_bid_price ?
                                                            `$${numberWithCommas(item.my_bid_price)}` :
                                                            item.my_bid_valuation ?
                                                                displayVal(item.my_bid_valuation) : '';
                                                        if (item.my_bid_size) target_label += `/ ${numberWithCommas(item.my_bid_size)}`;
                                                    } else if (type_label.indexOf('Sell') > -1) {
                                                        target_label += item.my_ask_price ?
                                                            `$${numberWithCommas(item.my_ask_price)}` :
                                                            item.my_ask_valuation ?
                                                                displayVal(item.my_ask_valuation) : '';
                                                        if (item.my_ask_size) target_label += `/ ${numberWithCommas(item.my_ask_size)}`;
                                                    }
                                                    if (!target_label.trim()) target_label = '-';

                                                    let highest_bid_label = '-';
                                                    if (item.highest_bid || item.highest_bid_valuation) {
                                                        highest_bid_label = item.highest_bid ? `$${numberWithCommas(item.highest_bid)}` : '-';
                                                        if (item.selected_price && item.selected_post_money_valuation && item.highest_bid)
                                                            highest_bid_label += ` (${getImpliedVal(item.highest_bid, item.selected_price, item.selected_post_money_valuation)})`;
                                                        else if (item.highest_bid_valuation)
                                                            highest_bid_label += ` (${displayVal(item.highest_bid_valuation)})`;
                                                    }


                                                    let lowest_ask_label = '-';
                                                    if (item.lowest_ask || item.lowest_ask_valuation) {
                                                        lowest_ask_label = item.lowest_ask ? `$${numberWithCommas(item.lowest_ask)}` : '-';
                                                        if (item.selected_price && item.selected_post_money_valuation && item.lowest_ask)
                                                            lowest_ask_label += ` (${getImpliedVal(item.lowest_ask, item.selected_price, item.selected_post_money_valuation)})`;
                                                        else if (item.lowest_ask_valuation)
                                                            lowest_ask_label += ` (${displayVal(item.lowest_ask_valuation)})`;
                                                    }

                                                    let lfr_label = '-';
                                                    if (item.selected_price || item.selected_post_money_valuation) {
                                                        lfr_label = '';
                                                        if (item.selected_price) lfr_label += `$${numberWithCommas(item.selected_price)}`;
                                                        if (item.selected_post_money_valuation) lfr_label += ` (${displayVal(item.selected_post_money_valuation)})`;
                                                    }


                                                    return (
                                                        <Grid item xs={12} key={index}>
                                                            <Card
                                                                style={{ cursor: 'pointer', padding: 16, marginLeft: '-8px', marginRight: '-8px' }}>

                                                                <Grid container spacing={2}>
                                                                    <Grid item xs={1} style={item_grid_style}>
                                                                        {
                                                                            !showBlurred(item.logo_url) ? <Link to={{
                                                                                        pathname: `/${companyDetailType}/company-detail/${item.id}`,
                                                                                    }}>
                                                                                        <img src={item.logo_url} style={{ maxWidth: 50 }} />
                                                                                </Link> : ''
                                                                        }
                                                                    </Grid>
                                                                    <Grid item xs={1} style={item_grid_style}>
                                                                        <Link to={{
                                                                                pathname: `/${companyDetailType}/company-detail/${item.id}`,
                                                                            }} style={{ display: 'block', width: '100%' }}>
                                                                                <span style={item_sub_title_style}>{item.name}</span>
                                                                        </Link>
                                                                    </Grid>
                                                                    <Grid item xs={1} style={item_grid_style}>
                                                                        <span style={item_sub_title_style}>{type_label}</span>
                                                                    </Grid>
                                                                    <Grid item xs={2} style={item_grid_style}>
                                                                        <span style={item_sub_title_style}>{target_label}</span>
                                                                    </Grid>
                                                                    <Grid item xs={2} style={item_grid_style}>
                                                                        <span style={item_sub_title_style}>{highest_bid_label}</span>
                                                                    </Grid>
                                                                    <Grid item xs={2} style={item_grid_style}>
                                                                        <span style={item_sub_title_style}>{lowest_ask_label}</span>
                                                                    </Grid>
                                                                    <Grid item xs={2} style={item_grid_style}>
                                                                        <span style={item_sub_title_style}>{lfr_label}</span>
                                                                    </Grid>
                                                                    <Grid item xs={1} style={item_grid_style}>
                                                                        <Tooltip title="Remove From Watchlist">
                                                                            <Star style={{ color: "#FFC50D" }}
                                                                                onClick={(event) => this.handleCompanyDeleteDialogOpen(event, item.id, watchlistGroupItem.id)} />
                                                                        </Tooltip>
                                                                        {
                                                                            watchlist_graph_available ? <Tooltip title="Show graph">
                                                                                <Equalizer style={{}}
                                                                                    onClick={(event) => this.handleShowCompGraph(event, item.id)}
                                                                                    />
                                                                            </Tooltip> : ''
                                                                        }
                                                                    </Grid>
                                                                </Grid>
                                                                
                                                                {
                                                                    graph_payload && graph_payload.company_id == item.id ?
                                                                        <Grid container spacing={2}>
                                                                            <Grid item xs={12}>
                                                                                <PriceChart
                                                                                    {...{
                                                                                        ...graph_payload,
                                                                                        historical_pricing,
                                                                                        funding_rounds,
                                                                                        companyDetailType,
                                                                                    }}
                                                                                    />
                                                                            </Grid>
                                                                        </Grid> : ''
                                                                }
                                                                
                                                            </Card>
                                                        </Grid>
                                                    )
                                                })
                                            }
                                        </Grid>
                                    </div>
                                </Paper>
                            </Grid>
                        </Grid> */}