import { 
    CreateApiAction as _createApiAction,
    CreateKeyAction as _createKeyAction
} from '../../utils';

const key = "LOGIN";

const CreateApiAction = _createApiAction(key);
const CreateKeyAction = _createKeyAction(key);

const Unauthorized = CreateKeyAction('UNAUTHORIZED');
const ClearTempLoader = CreateKeyAction('CLEAR_TEMP_LOADER');

const SignInWithEmailAndPassword = CreateApiAction('SIGN_IN_WITH_EMAIL_AND_PASSWORD');
const ValidateUser = CreateApiAction('VALIDATE_USER');

const GetPiAgreement = CreateApiAction('GET_PI_AGREEMENT');
const SetAgreementFalse = CreateApiAction('SET_AGREEMENT_FALSE');
const SetUpdatePasswordFalse = CreateApiAction('SET_AGREEMENT_FALSE');
const SetAgreementAndPassword = CreateApiAction('SET_AGREEMENT_AND_PASSWORD');

const GetTermsOfUse = CreateApiAction('GET_TERMS_OF_USE');
const GetPrivacyPolicy = CreateApiAction('GET_PRIVACY_POLICY');
const GetConfidentialityAgreement = CreateApiAction('GET_CONFIDENTIALITY_AGREEMENT');
const GetPlatformGuide = CreateApiAction('GET_PLATFORM_GUIDE');
const GetOrderbookAgreement = CreateApiAction('GET_ORDERBOOK_AGREEMENT');
const GetWealthPlatformGuide = CreateApiAction('GET_WEALTH_PLATFORM_GUIDE');
const GetWealthAgreement = CreateApiAction('GET_WEALTH_AGREEMENT');

const GetOBWealthPlatformGuide = CreateApiAction('GET_OB_WEALTH_PLATFORM_GUIDE');
const GetOBWealthAgreement = CreateApiAction('GET_OB_WEALTH_AGREEMENT');

const ValidateUserInBackground = CreateApiAction('VALIDATE_USER_IN_BACKGROUND');
const ShowUserTypeError = CreateKeyAction('SHOW_USER_TYPE_ERROR');

export {
    Unauthorized,
    ClearTempLoader,

    SignInWithEmailAndPassword,
    ValidateUser,
    GetPiAgreement,
    SetAgreementFalse,
    SetUpdatePasswordFalse,
    SetAgreementAndPassword,
    GetTermsOfUse,
    GetPrivacyPolicy,
    GetConfidentialityAgreement,
    GetPlatformGuide,
    GetOrderbookAgreement,
    GetWealthPlatformGuide,
    GetWealthAgreement,
    ValidateUserInBackground,
    ShowUserTypeError,

    GetOBWealthPlatformGuide,
    GetOBWealthAgreement,
};
