import {
    CreateApiAction as _createApiAction,
} from '../../../utils';

const key = "PORTFOLIO_HOLDING";

const CreateApiAction = _createApiAction(key);

const getPriceIndex = CreateApiAction('GET_PRICE_INDEX');
const getCompanyDetail = CreateApiAction('GET_COMPANY_DETAIL');
const getFundingRound = CreateApiAction('GET_FUNDING_ROUND');
const getMyPortfolio = CreateApiAction('GET_MY_PORTFOLIO');

export {
    getPriceIndex,
    getCompanyDetail,
    getFundingRound,
    getMyPortfolio,
}