import React from 'react';


import {
    makeStyles,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    Card,
    Paper,
} from '@material-ui/core';

import {
    HeaderTableCell,
    TighterTableCell,
    ClickableTableCell,
    StyledTableRow,
    StyledHoverTableRow,
} from '../../common/Table';

import {
    numberWithCommas,
    convertSizeLabel,
    displayVal,
} from '../../../../utils/util';


const useStyles = makeStyles((theme) => ({
    Card: {
        padding: 16
    },
    SubTitle: {
        fontSize: 12
    }
}));

export default (props) => {
    const classes = useStyles();
    const { primary_activity_deals } = props;

    // console.log( primary_activity_deals );

    let primary_activity_wording = `The company is in the process of fundraising `;
    if( primary_activity_deals.length && primary_activity_deals[0].valuation ){
        primary_activity_wording += `at US${displayVal(primary_activity_deals[0].valuation)}. `;
    }
    primary_activity_wording += ` As a selected group of investors within Gateway Investor Network, you are invited to participate in this round. `;
    

    return (
        <React.Fragment>
            <Card
                className={classes.Card}
                >
                <p className={classes.SubTitle}>
                    {primary_activity_wording}
                    { 
                        primary_activity_deals.length ? 
                        <React.Fragment>
                            Click <a 
                                    style={{ textDecoration: 'underline' }}
                                    href="javascript:void(0)" 
                                    onClick={(evt) => props.handleShowOrderDetailDialog(evt, 'buy', primary_activity_deals[0])}>here</a> to learn more.</React.Fragment> : ''  }
                </p>

                <TableContainer component={Paper} className={classes.container}>
                    <Table stickyHeader size="small" aria-label="simple table">
                        <TableHead>
                            <StyledTableRow>
                                <HeaderTableCell className="price-column">
                                    Offer (PPS)
                                </HeaderTableCell>
                                <HeaderTableCell className="size-column">
                                    Size
                                </HeaderTableCell>
                                <HeaderTableCell>Terms<sup>(1)</sup></HeaderTableCell>
                            </StyledTableRow>
                        </TableHead>
                        <TableBody>
                            { 
                                primary_activity_deals.map((o_item, o_index) => {
                                    const transaction_terms = [];
                                    if(o_item.is_rofr_waived) transaction_terms.push("R")
                                    if(o_item.is_company_sponsored) transaction_terms.push("C")
                                    if(o_item.is_available_financial_info) transaction_terms.push("I")
                                    if(o_item.is_primary) transaction_terms.push("P")
                                    if(o_item.is_spv && !o_item.is_gateway_spv) transaction_terms.push("S")
                                    if(o_item.is_gateway_spv) transaction_terms.push("G")

                                    return (
                                        <StyledHoverTableRow key={o_index}>
                                            <ClickableTableCell
                                                onClick={(evt) => props.handleShowOrderDetailDialog(evt, 'buy', primary_activity_deals[0])}
                                                className="price-column">{o_item.bid_price ? numberWithCommas(o_item.bid_price) : '-'}</ClickableTableCell>
                                            <ClickableTableCell
                                                onClick={(evt) => props.handleShowOrderDetailDialog(evt, 'buy', primary_activity_deals[0])}
                                                className="size-column">{o_item.size_range ? convertSizeLabel(o_item.size_range) : '-'}</ClickableTableCell>
                                            <ClickableTableCell
                                                onClick={(evt) => props.handleShowOrderDetailDialog(evt, 'buy', primary_activity_deals[0])}
                                                >
                                                    <span>{transaction_terms.join(',')}</span>
                                                </ClickableTableCell>
                                        </StyledHoverTableRow>
                                    )
                                })
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Card>
        </React.Fragment>
    )
}