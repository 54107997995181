import React from 'react';

import {
    Dialog,
    DialogContent,
    Button,
} from '@material-ui/core';

import {
    globalFontFamily,
} from '../../../../styles';

export default (props) => {
    return (
        <Dialog
            open={props.open}
            onClose={(evt) => props.handleCloseGroupDeleteDialog(evt, false)}
            fullWidth
            maxWidth="sm"
        >
            <DialogContent className="watchlist-dialog">
                <div className="title">
                    WatchList
                </div>
                <div style={{ marginTop: '10px', marginBottom: '15px', borderTop: '1px solid #F6F6F6' }}>
                    <p style={{
                        fontFamily: globalFontFamily, fontStyle: 'normal', fontWeight: '400', fontSize: '12px',
                        lineHeight: '14px', letterSpacing: '0.1em', color: 'black', marginTop: '10px', marginBottom: '-4px',
                    }}>Confirm your intention to remove watchlist '{props.group_name}'?</p>
                    <Button
                        className="watchlist_add_btn"
                        onClick={(evt) => props.handleCloseGroupDeleteDialog(evt, true)}>
                        Remove this watchlist
                    </Button>
                </div>
            </DialogContent>
        </Dialog>
    )
}