import {
    put,
    takeLatest,
    all,
    fork,
    call,
    delay
} from 'redux-saga/effects';

import {
    SignInWithEmailAndPassword,
    ValidateUser,
    GetPiAgreement,
    SetAgreementFalse,
    SetUpdatePasswordFalse,
    SetAgreementAndPassword,
    GetTermsOfUse,
    GetPrivacyPolicy,
    GetConfidentialityAgreement,
    GetPlatformGuide,
    GetOrderbookAgreement,
    GetWealthPlatformGuide,
    GetWealthAgreement,
    ValidateUserInBackground,
    ShowUserTypeError,
    GetOBWealthPlatformGuide,
    GetOBWealthAgreement,
} from './Action';

import {
    ShowLoader,
    HideLoader,
    checkMaintenance,
} from '../root/Action';

import {
    showHideCampaignPopUp,
} from '../orderbook/pipeline/Action';

import {
    auth,
    web_content,
    api_call,
} from '../../api';

import {
    GetToken,
    RemoveToken,
    SetToken,
    NewRelicError
} from '../../utils';

import {
    handleCatchSagaError,
    compileQueryString,
    getActiveAccessRight,
} from '../../utils/util';

import { content_placeholders } from '../../config';

// import { logEventInternallyAndNewRelic } from '../../utils/ga';

import { flag_to_check } from '../../config/Content';

function* SignInWithEmailAndPasswordResponder({payload}){
    yield put(ShowLoader(true));
    try{
        const { data } = yield call(auth.SignInWithEmailAndPassword, payload);
        console.log( data );
        const { user } = data;
        if( 
            user && user.user_type && 
            user.user_type == content_placeholders.user_candidate_type.lifetime_candidate 
            ){
                yield put(showHideCampaignPopUp({ show: true }));
            }
        data['set_time'] = Date.now();
        const accessToken = GetToken();
        if( accessToken && accessToken.token ) RemoveToken("authData");
        SetToken(data);
        // initializeLiveChat(data);
        yield put(checkMaintenance.request({
            qs: {
                feature_flags: flag_to_check
            }
        }));
        yield put(SignInWithEmailAndPassword.success(data));
    } catch (e){
        yield handleCatchSagaError(e, 'SignInWithEmailAndPasswordResponder');
        yield put(SignInWithEmailAndPassword.failure(e));
    } finally {
        yield put(HideLoader(true));
    }
}

function* ValidateUserResponder({ payload }){
    try{
        const { data } = yield call(auth.ValidateUser, payload.id, payload);
        // initializeLiveChat(data);
        yield put(ValidateUser.success(data));
        if( payload.reset_agreement ){
            yield put(SetAgreementFalse.success(data));
        } else if( payload.reset_agreement_and_password ){
            yield put(SetAgreementAndPassword.success(data));
        }
    } catch (e){
        yield handleCatchSagaError(e, 'ValidateUserResponder');
        // console.log( e );
        yield put(ValidateUser.failure(e));
    } finally {

    }
}

function* GetPiAgreementResponder({ payload }){
    yield put(ShowLoader(true));
    try{
        const { path } = payload;
        const query_string = compileQueryString(payload.qs);
        const { data } = yield call(api_call.get, path, query_string, {});
        const response_payload = data.content_text ? JSON.parse(data.content_text) : { "content": [], subject: '' };
        yield put(GetPiAgreement.success({...response_payload, ...{subject: data.subject}}));
    } catch(e) {
        yield handleCatchSagaError(e, 'GetPiAgreementResponder');
        yield put(GetPiAgreement.failure(e));
    } finally {
        yield put(HideLoader(true));
    }
}

function* GetTermsOfUseResponder({ payload }){
    yield put(ShowLoader(true));
    try{
        const { path } = payload;
        const query_string = compileQueryString(payload.qs);
        const { data } = yield call(api_call.get, path, query_string, {});
        const response_payload = data.content_text ? JSON.parse(data.content_text) : { "content": [], subject: '' };
        yield put(GetTermsOfUse.success({...response_payload, ...{subject: data.subject}}));
    } catch(e){
        yield handleCatchSagaError(e, 'GetTermsOfUseResponder');
        yield put(GetTermsOfUse.failure(e));
    } finally{
        yield put(HideLoader(true));
    }
}

function* GetPrivacyPolicyResponder({ payload }){
    yield put(ShowLoader(true));
    try{
        const { path } = payload;
        const query_string = compileQueryString(payload.qs);
        const { data } = yield call(api_call.get, path, query_string, {});
        const response_payload = data.content_text ? JSON.parse(data.content_text) : { "content": [], subject: '' };
        yield put(GetPrivacyPolicy.success({...response_payload, ...{subject: data.subject}}));
    } catch(e){
        yield handleCatchSagaError(e, 'GetPrivacyPolicyResponder');
        yield put(GetPrivacyPolicy.failure(e));
    } finally{
        yield put(HideLoader(true));
    }
}

function* GetConfidentialityAgreementResponder({ payload }){
    yield put(ShowLoader(true));
    try{
        console.log( payload );
        const { path } = payload;
        const query_string = compileQueryString(payload.qs);
        const { data } = yield call(api_call.get, path, query_string, {});
        const response_payload = data.content_text ? JSON.parse(data.content_text) : { "content": [], subject: '' };
        yield put(GetConfidentialityAgreement.success({...response_payload, ...{subject: data.subject}}));
    } catch(e){
        yield handleCatchSagaError(e, 'GetConfidentialityAgreementResponder');
        yield put(GetConfidentialityAgreement.failure(e));
    } finally{
        yield put(HideLoader(true));
    }
}


function* GetPlatformGuideResponder({ payload }){
    yield put(ShowLoader(true));
    try{
        const { path } = payload;
        const query_string = compileQueryString(payload.qs);
        const { data } = yield call(api_call.get, path, query_string, {});
        const response_payload = data.content_text ? JSON.parse(data.content_text) : { "content": [], subject: '' };
        yield put(GetPlatformGuide.success({...response_payload, ...{subject: data.subject}}));
    } catch(e){
        yield handleCatchSagaError(e, 'GetPlatformGuideResponder');
        yield put(GetPlatformGuide.failure(e));
    } finally{
        yield put(HideLoader(true));
    }
}


function* GetOrderbookAgreementResponder({ payload }){
    yield put(ShowLoader(true));
    try{
        const { path } = payload;
        const query_string = compileQueryString(payload.qs);
        const { data } = yield call(api_call.get, path, query_string, {});
        const response_payload = data.content_text ? JSON.parse(data.content_text) : { "content": [], subject: '' };
        yield put(GetOrderbookAgreement.success({...response_payload, ...{subject: data.subject}}));
    } catch(e){
        yield handleCatchSagaError(e, 'GetOrderbookAgreementResponder');
        yield put(GetOrderbookAgreement.failure(e));
    } finally{
        yield put(HideLoader(true));
    }
}

function* SetAgreementFalseResponder({ payload }){
    yield put(ShowLoader(true));
    try{
        yield delay(1500);
        const { data } = yield call(web_content.SetAgreementFalse, payload.user_id, payload);
        payload['reset_agreement'] = true;
        yield put(ValidateUser.request(payload));
    } catch(e) {
        yield handleCatchSagaError(e, 'SetAgreementFalseResponder');
        yield put(SetAgreementFalse.failure(e));
    } finally {
        yield put(HideLoader(true));
    }
}

function* SetUpdatePasswordFalseResponder({ payload }){
    yield put(ShowLoader(true));
    try{
        yield delay(3000);
        const { data } = yield call(web_content.SetUpdatePasswordFalse, payload.user_id,payload);
        yield put(SetUpdatePasswordFalse.success(data));
    } catch(e) {
        yield handleCatchSagaError(e, 'SetUpdatePasswordFalseResponder');
        yield put(SetUpdatePasswordFalse.failure(e));
    } finally {
        yield put(HideLoader(true));
    }
}

function* SetAgreementAndPasswordResponder({ payload }){
    yield put(ShowLoader(true));
    try{
        yield delay(2000);
        const { data } = yield call(web_content.SetAgreementAndPassword, payload.user_id, payload);
        payload['reset_agreement_and_password'] = true;
        yield put(ValidateUser.request(payload));
    } catch(e){
        yield handleCatchSagaError(e, 'SetAgreementAndPasswordResponder');
        yield put( SetAgreementAndPassword.failure(e) );
    } finally{
        yield put( HideLoader(true) );
    }
}

function* GetWealthPlatformGuideResponder({ payload }){
    yield put(ShowLoader(true));
    try{
        const query_string = compileQueryString(payload.qs);
        const { data } = yield call(web_content.getWebContent, payload.root_path, payload.sub_path, query_string, {});
        const response_payload = data.content_text ? JSON.parse(data.content_text) : { "content": [], subject: '' };
        yield put(GetWealthPlatformGuide.success({...response_payload, ...{subject: data.subject}}));
    } catch(e){
        yield handleCatchSagaError(e, 'GetWealthPlatformGuideResponder');
        yield put(GetWealthPlatformGuide.failure(e));
    } finally{
        yield put(HideLoader(true));
    }
}

function* GetWealthAgreementResponder({ payload }){
    yield put(ShowLoader(true));
    try{
        const query_string = compileQueryString(payload.qs);
        const { data } = yield call(web_content.getWebContent, payload.root_path, payload.sub_path, query_string, {});
        const response_payload = data.content_text ? JSON.parse(data.content_text) : { "content": [], subject: '' };
        yield put(GetWealthAgreement.success({...response_payload, ...{subject: data.subject}}));
    } catch(e){
        yield handleCatchSagaError(e, 'GetWealthAgreementResponder');
        yield put(GetWealthAgreement.failure(e));
    } finally{
        yield put(HideLoader(true));
    }
}

function* GetOBWealthPlatformGuideResponder({ payload }){
    yield put(ShowLoader(true));
    try{
        const { path } = payload;
        const query_string = compileQueryString(payload.qs);
        const { data } = yield call(api_call.get, path, query_string, {});
        const response_payload = data.content_text ? JSON.parse(data.content_text) : { "content": [], subject: '' }
        yield put(GetOBWealthPlatformGuide.success({ ...response_payload, ...{ subject: data.subject }}))
    } catch(e){
        yield handleCatchSagaError(e, '');
        yield put(GetOBWealthPlatformGuide.failure(e));
    } finally{
        yield put(HideLoader(true));
    }
}

function* GetOBWealthAgreementResponder({ payload }){
    yield put(ShowLoader(true));
    try{
        const { path } = payload;
        const query_string = compileQueryString(payload.qs);
        const { data } = yield call(api_call.get, path, query_string, {});
        const response_payload = data.content_text ? JSON.parse(data.content_text) : { "content": [], subject: '' }
        yield put(GetOBWealthAgreement.success({ ...response_payload, ...{ subject: data.subject }}))
    } catch(e){
        yield handleCatchSagaError(e, '');
        yield put(GetOBWealthAgreement.failure(e));
    } finally{
        yield put(HideLoader(true));
    }
}

function* ValidateUserInBackgroundResponder({ payload }){
    try{
        const { data } = yield call(auth.ValidateUser, payload.id, payload);
        const loginUserData = getActiveAccessRight(data);
        const { activeAccessRight: { access_right: { permission } } } = loginUserData;
        if( 
			permission !== payload['currentUserType']
		){
            yield put( ShowUserTypeError() );
        }
        yield put(ValidateUserInBackground.success(data));
    } catch (e){
        yield handleCatchSagaError(e, 'ValidateUserInBackgroundResponder');
        yield put(ValidateUserInBackground.failure(e));
    } finally {

    }
}

function* saga(){
    yield all([
        takeLatest(SignInWithEmailAndPassword.request, SignInWithEmailAndPasswordResponder),
        takeLatest(ValidateUser.request, ValidateUserResponder),
        takeLatest(GetPiAgreement.request, GetPiAgreementResponder),
        takeLatest(SetAgreementFalse.request, SetAgreementFalseResponder),
        takeLatest(SetUpdatePasswordFalse.request, SetUpdatePasswordFalseResponder),
        takeLatest(SetAgreementAndPassword.request, SetAgreementAndPasswordResponder),
        takeLatest(GetTermsOfUse.request, GetTermsOfUseResponder),
        takeLatest(GetPrivacyPolicy.request, GetPrivacyPolicyResponder),
        takeLatest(GetConfidentialityAgreement.request, GetConfidentialityAgreementResponder),
        takeLatest(GetPlatformGuide.request, GetPlatformGuideResponder),
        takeLatest(GetOrderbookAgreement.request, GetOrderbookAgreementResponder),
        takeLatest(GetWealthPlatformGuide.request, GetWealthPlatformGuideResponder),
        takeLatest(GetWealthAgreement.request, GetWealthAgreementResponder),
        takeLatest(ValidateUserInBackground.request, ValidateUserInBackgroundResponder),
        takeLatest(GetOBWealthPlatformGuide.request, GetOBWealthPlatformGuideResponder),
        takeLatest(GetOBWealthAgreement.request, GetOBWealthAgreementResponder),
    ])
}

export default saga;
