import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Popover,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@material-ui/core';
import Check from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles({
    cell: {
        padding: 3,
        textAlign: 'center',
        width: '100px',
        borderBottom: '1px solid black',
        borderRight: '1px solid black',
    },
    cellWithoutBorderRight:{
        borderRight: 'none',
    },
    cellWithoutBorderBottom:{
        borderBottom: 'none',
    }
});

export default (props) => {
    const classes = useStyles();

    const open = Boolean(props.anchorEl);
    const id = open ? 'simple-popover' : undefined;
    function createData(name, allDashboards, allLiveOrders, secondaryActivity, fullAccess, paid) {
        return { name, allDashboards, allLiveOrders, secondaryActivity, fullAccess, paid };
    }
    const rows = [
        createData('Basic', true, true, false, false, false),
        createData('Premium', true, true, true, false, false),
        createData('Pro', true, true, true, true, true),
    ];
    return (
        <Popover
            id={id}
            open={open}
            anchorEl={props.anchorEl}
            onClose={props.handleClosePopper}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
        >
            <div style={{ padding: 8, textAlign: 'left' }}>
                <Typography variant='h6'>Subscription Tiers</Typography>
                <TableContainer >
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell className={classes.cell}></TableCell>
                                <TableCell className={classes.cell}>All Dashboard</TableCell>
                                <TableCell className={classes.cell}>All Live Orders</TableCell>
                                <TableCell className={classes.cell}>Secondary Activity</TableCell>
                                <TableCell className={classes.cell}>Full Access</TableCell>
                                <TableCell className={[classes.cell, classes.cellWithoutBorderRight]}>Paid</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((row,index) => (
                                <TableRow key={row.name}>
                                    <TableCell component="th" scope="row" className={index != 2 ? classes.cell: [classes.cell, classes.cellWithoutBorderBottom]}>
                                        {row.name}
                                    </TableCell>
                                    <TableCell className={index != 2 ? classes.cell: [classes.cell, classes.cellWithoutBorderBottom]}>{row.allDashboards
                                        ? <Check style={{ fontSize: 20, color: '#29a329' }} />
                                        : <CloseIcon style={{ fontSize: 20, color: 'gray' }} />
                                    }</TableCell>
                                    <TableCell className={index != 2 ? classes.cell: [classes.cell, classes.cellWithoutBorderBottom]}>{row.allLiveOrders
                                        ? <Check style={{ fontSize: 20, color: '#29a329' }} />
                                        : <CloseIcon style={{ fontSize: 20, color: 'gray' }} />
                                    }</TableCell>
                                    <TableCell className={index != 2 ? classes.cell: [classes.cell, classes.cellWithoutBorderBottom]}>{row.secondaryActivity
                                        ? <Check style={{ fontSize: 20, color: '#29a329' }} />
                                        : <CloseIcon style={{ fontSize: 20, color: 'gray' }} />
                                    }</TableCell>
                                    <TableCell className={index != 2 ? classes.cell: [classes.cell, classes.cellWithoutBorderBottom]}>{row.fullAccess
                                        ? <Check style={{ fontSize: 20, color: '#29a329' }} />
                                        : <CloseIcon style={{ fontSize: 20, color: 'gray' }} />
                                    }</TableCell>
                                    <TableCell className={index != 2 ? [classes.cell, classes.cellWithoutBorderRight] : [classes.cell, classes.cellWithoutBorderRight, classes.cellWithoutBorderBottom]}>{row.paid
                                        ? <Typography style={{ fontSize: 20, color: '#29a329' }} >Y</Typography>
                                        : <Typography style={{ fontSize: 20, color: 'gray' }} >N</Typography>
                                    }</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Typography variant='body2'>What does each subscription tier mean?</Typography>
                <Typography variant='body2'>Basic - Basic users have been vetted and have an account created for the Gateway Orderbook</Typography>
                <Typography variant='body2'>Premium - Premium users are active participants who contribute to the Gateway Orderbook</Typography>
                <Typography variant='body2'>Pro - Pro users have full access to the Gateway Orderbook and is a paid annual subscription</Typography>

            </div>
        </Popover>
    )
}