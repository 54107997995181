// import * as React from 'react';
import { connect } from 'react-redux';

import {
    getAllCompanies,
    getFundingRound,
    getLatestFundingRound,
    getCompanyInvestors,
    getVerticals,
} from './Action';

import {
    getPipeline,
    expressInterest
} from '../pipeline/Action';

import { default as CompanyDetail } from './CompanyDetail';
export default connect(
    state => ({
        AllCompaniesResponse:state.company.reducer.AllCompaniesResponse,
        verticals:state.company.reducer.verticals,
        fundingRounds:state.company.reducer.fundingRounds.results,
        latestFundingRound: state.company.reducer.latestFundingRound,
        investors:state.company.reducer.investors,
        pipelineResponse:state.pipeline.reducer.pipelineResponse,
        expressInterestError: state.pipeline.reducer.expressInterestError,
        expressInterestResponseMessage: state.pipeline.reducer.expressInterestResponseMessage,
		loginUserData: state.login.reducer.loginUserData,
    }),
    dispatch => ({ 
        getAllCompanies: payload => dispatch(getAllCompanies.request(payload)),
        getFundingRound: payload => dispatch(getFundingRound.request(payload)),
        getLatestFundingRound: payload => dispatch(getLatestFundingRound.request(payload)),
        getCompanyInvestors: payload => dispatch(getCompanyInvestors.request(payload)),
        getVerticals: payload => dispatch(getVerticals.request(payload)),
        getPipeline: payload => dispatch(getPipeline.request(payload)),
        expressInterest: payload => dispatch(expressInterest.request(payload)),
    })
)(CompanyDetail);
