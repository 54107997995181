import
React,
{
    Fragment,
    useState,
    useEffect,
    forwardRef,
    useRef,
    useImperativeHandle
}
    from 'react';

import {
    Table,
    TableBody,
    TableContainer,
    TableHead,
    Typography,
    Paper,
    TextField,
    Select,
    MenuItem,
    FormControl,
    makeStyles,
    Checkbox,
} from '@material-ui/core';
import { withStyles } from "@material-ui/core/styles";

import {
    StyledTableRow,
    TableLabelStyle,
    TighterHeaderTableCell,
    TighterTableCell1,
} from '../../../orderbook/common/Table';


const useStyles = makeStyles({
    input: {
        "& input": {
            fontSize: '12px',
            lineHeight: '14px'
        },
        "& input::placeholder": {
            fontSize: '12px !important',
            lineHeight: '14px'
        },
        marginBottom: '15px',
    }
});

const CustomeCheckbox = withStyles({
    root: {
        "&$checked": {
            color: "#fff",
            "& .MuiIconButton-label": {
                position: "relative",
                zIndex: 0
            },
            "& .MuiIconButton-label:after": {
                content: '""',
                left: '-2px',
                top: '6px',
                height: '1rem',
                width: '1rem',
                position: "absolute",
                backgroundColor: "black",
                zIndex: -1
            }
        }
    },
    checked: {}
})((props) => <Checkbox color="default" {...props} />);

export default forwardRef((props, ref) => {
    const classes = useStyles();
    const tableClasses = TableLabelStyle();
    const [investors, setInvestors] = useState(props.investors);
    const [checks, setChecks] = useState(Array(props.investors.length).fill(false))
    const [approvalInHeader, setApprovalInHeader] = useState(true)
    const [checkInHeader, setCheckInHeader] = useState(false)
    const [showConfirmAndSubmitBTN, setShowConfirmAndSubmitBTN] = useState(true)
    const handleApprovalChange = (event, index) => {
        if (event.target.value) {
            setInvestors(
                investors.map((investorItem, investorIndex) =>
                    investorIndex === index
                        ? { ...investorItem, comment: [], approval: event.target.value }
                        : investorItem
                ))
        } else {
            setInvestors(
                investors.map((investorItem, investorIndex) =>
                    investorIndex === index
                        ? { ...investorItem, approval: event.target.value }
                        : investorItem
                ))
            if (props.handleShowRejectReason) {
                props.handleShowRejectReason(index);
            }
        }
    }
    const handleItemCheckboxChange = (event, index) => {
        setChecks(
            checks.map((checkItem, checkIndex) =>
                checkIndex === index
                    ? event.target.checked
                    : checkItem
            ))
    }
    const handleApprovalInHeaderChange = (event) => {
        setApprovalInHeader(event.target.value)
    }
    const handleCheckboxInHeaderChange = (event, index) => {
        const { target: { checked } } = event;
        setChecks(Array(props.investors.length).fill(checked));
        setCheckInHeader(event.target.checked)
    }

    const confirmApprovals = () => {
        setShowConfirmAndSubmitBTN(true)
        if (props.handleConfirmShowRejectReasonInHeader) {
            props.handleConfirmShowRejectReasonInHeader(approvalInHeader);
        }
    }

    const submitApprovalSelections = (evt) => {
        if (props.handleSubmitApproval) {
            props.handleSubmitApproval(evt, investors);
        }
    }

    useImperativeHandle(ref, () => ({
        updateReasons(index, reasons) {
            if (index > -1) {
                setInvestors(
                    investors.map((investorItem, investorIndex) =>
                        investorIndex === index
                            ? { ...investorItem, comment: reasons }
                            : investorItem
                    ))
            }
            if (index == -1) {
                setInvestors(
                    investors.map((investorItem, investorIndex) =>
                        checks[investorIndex]
                            ? { ...investorItem, comment: reasons, approval: false }
                            : investorItem
                    ))
            }
        }
    }));

    useEffect(() => {
        setInvestors(props.investors);
        if (checks.length == 0) {
            setChecks(Array(props.investors.length).fill(false))
        }
    }, [props.company_id, props.investors])

    return (
        <React.Fragment>
            <Typography variant="h6"> Investor vetting
                {showConfirmAndSubmitBTN &&
                    <span
                        className={`approval-confirm-btn ${!(investors.map(item => item.approval).includes(true) || investors.map(item => item.approval).includes(false)) && "approval-confirm-btn-disable"}`}
                        style={{ float: 'right', paddingLeft: '25px', paddingRight: '25px' }}
                        onClick={(investors.map(item => item.approval).includes(true) || investors.map(item => item.approval).includes(false)) && ((e) => submitApprovalSelections(e))}>
                        Submit
                    </span>
                }
            </Typography>
            <p className="company-dashboard-subtitle">
                Please see below investors for your consideration. Please select Yes/No in the “Approval” column for each investor - Gateway will only begin outreach to approved investors for your primary round.
            </p>
            {/* <TextField
                classes={{ root: classes.input }}
                margin="dense"
                id="investor_name"
                name="investor_name"
                placeholder="Investor Name"
                fullWidth
                multiline={false}
                variant="outlined"
            /> */}
            <TableContainer component={Paper} className={tableClasses.container}>
                <Table stickyHeader size="small" aria-label="simple table">
                    <colgroup>
                        <col style={{ width: '12%' }} />
                        <col style={{ width: '12%' }} />
                        {/* <col style={{ width: '12%' }} /> */}
                        <col style={{ width: '12%' }} />
                        <col style={{ width: '12%' }} />
                        <col style={{ width: '15%' }} />
                        <col style={{ width: '25%' }} />
                    </colgroup>
                    <TableHead>
                        <StyledTableRow>
                            <TighterHeaderTableCell className="investor-name">Investor Name</TighterHeaderTableCell>
                            <TighterHeaderTableCell className="investor-type">Investor Type</TighterHeaderTableCell>
                            {/* <TighterHeaderTableCell className="is-direct">Direct / Gateway SPV</TighterHeaderTableCell> */}
                            <TighterHeaderTableCell className="ticket-size">Ticket size (range)</TighterHeaderTableCell>
                            <TighterHeaderTableCell className="is-strategic">Strategic / Passive</TighterHeaderTableCell>
                            <TighterHeaderTableCell className="approval">
                                {
                                    checks.includes(true) ?
                                        <div>
                                            <FormControl sx={{ m: 1 }} style={{ backgroundColor: '#D9D9D9', minWidth: '56px', width: '80%' }}>
                                                <Select
                                                    value={approvalInHeader}
                                                    onChange={(e) => handleApprovalInHeaderChange(e)}
                                                    displayEmpty
                                                    inputProps={{ 'aria-label': 'Without label' }}
                                                    style={{ fontSize: '12px', fontFamily: "'Roboto', sans-serif", fontWeight: '400', lineHeight: '14px', paddingLeft: '13px' }}
                                                >
                                                    <MenuItem value={true} key={0} style={{ fontSize: 12, fontFamily: "'Roboto', sans-serif", fontWeight: '400', lineHeight: '14px' }}>
                                                        Yes
                                                    </MenuItem>
                                                    <MenuItem value={false} key={1} style={{ fontSize: 12, fontFamily: "'Roboto', sans-serif", fontWeight: '400', lineHeight: '14px' }}>
                                                        No
                                                    </MenuItem>
                                                </Select>
                                            </FormControl>
                                            <FormControl className='company-vetting-checkbox-container' sx={{ m: 1 }} style={{  width: '28px', height: '28px', marginLeft: '3px' }}>
                                                <CustomeCheckbox type="checkbox" checked={checkInHeader}
                                                    onChange={(e) => handleCheckboxInHeaderChange(e)}
                                                />
                                            </FormControl>
                                        </div>
                                        : 
                                        <div>
                                            <FormControl sx={{ m: 1 }} style={{ minWidth: '56px', width: '80%' }}>
                                                Approval
                                            </FormControl>
                                            <FormControl className='company-vetting-checkbox-container' sx={{ m: 1 }} style={{  width: '28px', height: '28px', marginLeft: '3px' }}>
                                                <CustomeCheckbox type="checkbox" checked={checkInHeader}
                                                    onChange={(e) => handleCheckboxInHeaderChange(e)}
                                                />
                                            </FormControl>
                                        </div>
                                }</TighterHeaderTableCell>
                            <TighterHeaderTableCell colSpan={2} className="reason">{checks.includes(true) ?
                                <span className="approval-confirm-btn" onClick={confirmApprovals}>
                                    Confirm
                                </span> : ""}
                            </TighterHeaderTableCell>
                        </StyledTableRow>
                    </TableHead>
                    <TableBody>
                        {
                            investors.map((row, index) => {
                                return (
                                    <React.Fragment key={index}>
                                        <StyledTableRow>
                                            <TighterTableCell1
                                                className='investor-name'>{row.investor}</TighterTableCell1>
                                            <TighterTableCell1
                                                className="investor-type">{row.investor_type}</TighterTableCell1>
                                            {/* <TighterTableCell1
                                                className="is-direct">{row.isDirect ? 'Direct' : 'Gateway SPV'}</TighterTableCell1> */}
                                            <TighterTableCell1
                                                className="ticket-size">{row.ticket_size}</TighterTableCell1>
                                            <TighterTableCell1
                                                className="is-strategic">{row.isStrategic ? 'Strategic' : 'Passive'}</TighterTableCell1>
                                            <TighterTableCell1
                                                className="approval">
                                                <FormControl sx={{ m: 1 }} style={{ backgroundColor: '#D9D9D9', minWidth: '56px', width: '80%' }}>
                                                    <Select
                                                        value={row.approval}
                                                        onChange={(e) => handleApprovalChange(e, index)}
                                                        displayEmpty
                                                        inputProps={{ 'aria-label': 'Without label' }}
                                                        style={{ fontSize: '12px', fontFamily: "'Roboto', sans-serif", fontWeight: '400', lineHeight: '14px', paddingLeft: '13px' }}
                                                    >
                                                        <MenuItem 
                                                            value={true} 
                                                            key={0} 
                                                            style={{ fontSize: 12, fontFamily: "'Roboto', sans-serif", fontWeight: '400', lineHeight: '14px' }}>
                                                            Yes
                                                        </MenuItem>
                                                        <MenuItem 
                                                            value={false} 
                                                            key={1} 
                                                            style={{ fontSize: 12, fontFamily: "'Roboto', sans-serif", fontWeight: '400', lineHeight: '14px' }}>
                                                            No
                                                        </MenuItem>
                                                    </Select>
                                                </FormControl>
                                                <FormControl 
                                                    className='company-vetting-checkbox-container' 
                                                    sx={{ m: 1 }} 
                                                    style={{ backgroundColor: '#D9D9D9', width: '28px', height: '28px', marginLeft: '3px' }}>
                                                    <CustomeCheckbox type="checkbox" checked={checks[index]}
                                                        onChange={(e) => handleItemCheckboxChange(e, index)}
                                                    />
                                                </FormControl>
                                            </TighterTableCell1>
                                            <TighterTableCell1
                                                className='reason comment-table-row-cell'>{row.comment && row.comment.length > 0 ? row.comment.join(';') : ''}</TighterTableCell1>
                                        </StyledTableRow>
                                    </React.Fragment>
                                )
                            })
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </React.Fragment>
    )
})