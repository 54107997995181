// import * as React from 'react';
import { connect } from 'react-redux';

import {
    SignInWithEmailAndPassword
} from './Action';

import InvestorLogin from './InvestorLogin';

export default connect(
    state => ({
        loginError: state.login.reducer.loginError,
        loginResponse: state.login.reducer.loginResponse,
        loginUserData: state.login.reducer.loginUserData,
        showSnackbarInLogin: state.login.reducer.showSnackbarInLogin,
        getPasscodeResponse: state.login.reducer.getPasscodeResponse
    }),
    dispatch => ({ 
        SignInWithEmailAndPassword: payload => dispatch(SignInWithEmailAndPassword.request(payload))
    })
    
)(InvestorLogin);