import { connect } from 'react-redux';
import { default as Dashboard } from './Dashboard';

import {
    UpdateMyAccess,
    clearAccessResponsePayload,
} from './Action';

export default connect(
    state => ({
        loginUserData: state.login.reducer.loginUserData,
        accessRightResponse: state.dashboard.reducer.accessRightResponse,
    }),
    dispatch => ({
        UpdateMyAccess: (payload) => dispatch(UpdateMyAccess.request(payload)),
        clearAccessResponsePayload: () => dispatch(clearAccessResponsePayload()),
    })
)(Dashboard);