import Cookies from 'universal-cookie';

export default () => {
    const cookies = new Cookies();
    const debug_cookie_key = "gpm_debug";
    let payload = {}
    if(
        cookies.get(debug_cookie_key)
    ) {
        payload = { debug: cookies.get(debug_cookie_key) }
    }

    return payload;
}