import React, { Fragment } from 'react';

import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    makeStyles,
    Typography,
    Card,
    Grid,
} from '@material-ui/core';
import { HeaderTableCell, StyledTableRow, recentCellClasses } from '../../../orderbook/common/Table';

import {
    numberWithCommas,
    getCompanyValuation,
    showBlurred,
    displayVal
} from '../../../../utils/util';

const useStyles = makeStyles((theme) => ({
    container: {
        // minHeight: 50,
        // maxHeight: 50,
    },
    SubTitle: {
        fontSize: 12,
        // textAlign: 'center',
        display: 'block',
    },
    Card: {
        padding: 16,
        marginTop: 16,
        marginBottom: 16,
    }
}));

export default (props) => {
    const classes = useStyles();
    const rcc = recentCellClasses();

    const valid_funding_rounds = props.results.filter(item => item.price || item.post_money_valuation  || item.deal_amount);
    const company_valuation = getCompanyValuation(props);
    return (
        <Fragment>
            {
                company_valuation.valuation ? 
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <Card className={classes.Card}>
                                <span className={classes.SubTitle}>Company Valuation</span>
                                <Typography variant="h6">
                                    {
                                        showBlurred(company_valuation.valuation) ? 
                                        <span className="blurred">{company_valuation.valuation}</span> : 
                                        displayVal(company_valuation.valuation, 2)
                                    }
                                </Typography>
                            </Card>
                        </Grid>
                        <Grid item xs={6}>
                            <Card className={classes.Card}>
                                <span className={classes.SubTitle}>Valuation Date</span>
                                <Typography variant="h6">
                                    {
                                        showBlurred(company_valuation.date) ?
                                        <span className="blurred">{company_valuation.date}</span> :
                                        company_valuation.date
                                    }    
                                </Typography>
                            </Card>
                        </Grid>
                    </Grid> 
                    : ''
            }
            
            <TableContainer component={Paper} className={classes.container}>
                <Table stickyHeader size="small" aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <HeaderTableCell>Series</HeaderTableCell>
                            <HeaderTableCell>Deal Size (US$M)</HeaderTableCell>
                            <HeaderTableCell>Price</HeaderTableCell>
                            <HeaderTableCell>Valuation (US$M)</HeaderTableCell>
                            <HeaderTableCell>Date</HeaderTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            valid_funding_rounds.map((row, index) => {
                                return (
                                    <StyledTableRow key={index}>
                                        <TableCell>{row.deal_type_2 ? 
                                            showBlurred(row.deal_type_2) ? <span className="blurred">{row.deal_type_2}</span> : row.deal_type_2
                                            : ''}</TableCell>
                                        <TableCell>{row.deal_amount ? 
                                            showBlurred(row.deal_amount) ? <span className="blurred">{row.deal_amount}</span> : numberWithCommas(row.deal_amount, 0) 
                                        : ''}</TableCell>
                                        <TableCell>{row.price ? 
                                            showBlurred(row.price) ? <span className="blurred">{row.price}</span> : row.price.toFixed(2) 
                                        : ''}</TableCell>
                                        <TableCell>{row.post_money_valuation ? 
                                            showBlurred(row.post_money_valuation) ? <span className="blurred">{row.post_money_valuation}</span> : numberWithCommas(row.post_money_valuation, 0) 
                                            : ''}</TableCell>
                                        <TableCell>{row.deal_date ?
                                            showBlurred(row.deal_date) ? <span className="blurred">{row.deal_date}</span> : row.deal_date
                                            : ''}</TableCell>
                                    </StyledTableRow>
                                )
                            })
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            <p className='elaborate-text' style={{marginTop: '10px'}}>The content is provided for informational purposes only. Gateway does not guarantee nor make any representation or warranty as to the accuracy, validity, timeliness, completement or continued availability of the content.</p>
        </Fragment>
    )
}