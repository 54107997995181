import React, { Component, Fragment } from "react";

import {
	AppBar,
	Toolbar,
	withStyles,
	Menu,
	MenuItem,
	Button,
	ListItemIcon,
	Typography,
	FormHelperText,
	TextField,
	Grid,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	DialogContentText,
	FormControl,
	Badge,
} from "@material-ui/core";
import { HeaderStyles } from "../../styles";
// import classNames from "classnames";
import AppsIcon from "@material-ui/icons/Apps";
// import logoImg from "../../../assets/images/logo1.png";
// import clubLogoImg from "../../../assets/images/ClubLogo.png";
// import BetaImg from "../../../assets/images/Beta.png";
import logoCompany from "../../../assets/images/logoCompany.png";
import { Link } from "react-router-dom";
import {
	// KeyboardArrowDown,
	// ExitToApp,
	// FullscreenExit,
	Notifications,
} from "@material-ui/icons";
import { green } from "@material-ui/core/colors";
// import { withRouter } from "react-router-dom";
// import AccountCircleIcon from "@material-ui/icons/AccountCircle";
// import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import { Formik } from "formik";
import * as Yup from "yup";
import { Snackbar } from "../../common";
import PasswordStrengthMeter from "../../resetPassword/updatePassword/PasswordStrengthMeter";
import { GetToken } from "../../../utils";
import { passwordRegExp } from "../Funcs";
import { SellSharesDialog, NotificationPreferencesDialog } from "./dialogs";
import { SubscriptionPopover } from "./popper";
import { 
	replaceEnterCharacter, 
	destroyLiveChat,
	extractPortalType,
} from "../../../utils/util";
import { sendGAEvent } from '../../../utils/ga'
import { ReadQueryString } from '../../../utils';
import * as uuid from "uuid";
import { content_placeholders } from '../../../config';

import {
	VideoDialog
} from '../../club_client/company/components';

const passwordsMatch = (value, allValues) => {
	if (value !== allValues.get("password")) {
		return "Passwords dont match";
	}
	return undefined;
};

const validations = {
	new_password1: Yup.string()
		.matches(passwordRegExp, "*password invalid")
		.min(12, "*min 12 character required")
		.required("")
		.trim(),

	new_password2: Yup.string("app.error-invalid-string")
		// .min(3, 'app.error-min-character')
		.required("")
		.trim()
		.oneOf([Yup.ref("new_password1"), null], "*Passwords not match"),
};

class Header extends Component {
	state = {
		pendingUserState: true,
		openMenu: false,
		openComplianceMenu: false,
		anchorEl: null,
		new_password1: "",
		new_password2: "",
		open: false,
		showMobile: false,
		confirmPassError: "",
		closeBanner: true,
		openSell: false,

		openStyle: false,
		responseMsg: "",
		passwordError: false,

		// Sell interest properties
		company: "",
		notional: "",
		price: "",
		comment: "",
		openNotice: false,

		video: {
			open: false,
			url: "https://media.gatewayprivatemarkets.com/video/portal/demo-video/final-cut.mp4",
			link_name: "Demo Video",
		},

		notification_preferences: {
			open: false,
			weekly_update: false,
			product_update: false,
			all_new_block: false,
			best_price_movement: false,
			email_noti: true,
			email_noti_options: [
				{ value: true, label: 'Send me email and portal notifications' },
				{ value: false, label: 'Send me portal notifications only' },
			],
			watchlist_pref: 'BEST PRICE MOVEMENT'
		},
		subscription_popover:{
			anchorEl: null,
		}
	};

	constructor(props) {
		super(props);
		this.toggleMobileMenu = this.toggleMobileMenu.bind(this);

		this.openAccountPopup = this.openAccountPopup.bind(this);
		this.closeAccountPopup = this.closeAccountPopup.bind(this);
		
		this.openSellPopup = this.openSellPopup.bind(this);
		this.closeSellPopup = this.closeSellPopup.bind(this);

		this.notificationPrefPopup = this.notificationPrefPopup.bind(this);
		this.onNotiValueChange = this.onNotiValueChange.bind(this);
		this.onNotiSelectChange = this.onNotiSelectChange.bind(this);
		this.onSubmitNotiPref = this.onSubmitNotiPref.bind(this);


		this.handleSubmitSellInterest = this.handleSubmitSellInterest.bind(this);
		// this.handleSnackbarClose = this.handleSnackbarClose.bind(this);

		this.handleVideoDialogOpen = this.handleVideoDialogOpen.bind(this);
		this.handleVideoDialogClose = this.handleVideoDialogClose.bind(this);
	}

	componentDidMount() {
		const {
			// markNotificationAllUnRead,
			getNotifications,
			getWatchlistGroup,
			location: { search },
			loginUserData,
		} = this.props;

		const { 
			accessRight,
			activeAccessRight: { access_right: { permission } }
		} = loginUserData;
		// const personal_token = GetToken();

		// for testing we mark all notification unread
		//markNotificationAllUnRead();
		if( permission ){
			const notification_type = ['GENERAL'];
			const feat_notification = permission == content_placeholders.user_type.wealth_user ? 'WEALTH' : 'ORDERBOOK';
			notification_type.push(`${feat_notification}_NEW_FEATURE`);
			notification_type.push(`${feat_notification}_AVAILABLE_FEATURE`);

			const qs_payload = {
				type: encodeURIComponent(`{${notification_type.join(',')}}`)
			}

			getNotifications({ qs: qs_payload });
			if([content_placeholders.user_type.wealth_user, content_placeholders.user_type.orderbook_user].includes(permission)){
				let path = content_placeholders.user_type.wealth_user === permission ? 'wealth' : 'orderbook'
				let payload = {root_path: '/v2/'+path}
				getWatchlistGroup(payload);
			}
		}
		const query_string = ReadQueryString(search);
		
		if( query_string['noti-pref'] && query_string['noti-pref'] == "show" ){
			const { notification_preferences } = this.state;
			notification_preferences['open'] = true;
			this.setState({ notification_preferences });
		}
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		const { pendingUserState, loginUserData, showSnakbar, passwordError, responseMsg, updateNotificationPreferencesResponse } = nextProps;
		
		if (
			showSnakbar &&
			responseMsg != prevState.responseMsg &&
			responseMsg !== undefined
		) {
			return {
				openStyle: showSnakbar,
				responseMsg: responseMsg,
				passwordError: passwordError,
			};
		} else if( pendingUserState != prevState.pendingUserState  ){
			const payload = { pendingUserState: pendingUserState }
			if( !pendingUserState ){
				const { notification_preferences } = prevState;
				
				notification_preferences['weekly_update'] = loginUserData.weekly_update;
				notification_preferences['product_update'] = loginUserData.product_update;
				notification_preferences['watchlist_pref'] = loginUserData.watchlist_pref;
				notification_preferences['email_noti'] = loginUserData.email_noti;
				
				if( loginUserData.watchlist_pref && loginUserData.watchlist_pref.indexOf("BEST PRICE MOVEMENT") > -1 ){
					notification_preferences['best_price_movement'] = true;
				}
				if( loginUserData.watchlist_pref && loginUserData.watchlist_pref.indexOf("ALL NEW BLOCK") > -1 ){
					notification_preferences['all_new_block'] = true;
				}
				

				payload['notification_preferences'] = notification_preferences;
			}
		
			return payload;
		} else if( updateNotificationPreferencesResponse.trim() ){
			const payload = {
				responseMsg: updateNotificationPreferencesResponse,
				openStyle: true,
				passwordError: updateNotificationPreferencesResponse.toLowerCase().indexOf('error') > -1 ? true : false,
			}

			const { notification_preferences } = prevState;
			notification_preferences['open'] = false;
			payload['notification_preferences'] = notification_preferences;
			return payload;

		} else {
			return null;
		}
	}

	toggleMobileMenu(evt) {
		const { showMobile } = this.state;
		this.setState({ showMobile: !showMobile });
	}

	handleClick = (event, type) => {
		if( type == 'lifeTimeUser' ){
			const { subscription_popover } = this.state;
			subscription_popover.anchorEl = event.currentTarget;
			this.setState({ subscription_popover });
		}
		
		this.setState({
			[type]: !this.state[type],
			anchorEl: event.currentTarget,
		});
	};

	handleClosePopper = () => {
		const { subscription_popover } = this.state;
		subscription_popover.anchorEl = null;
		this.setState({ subscription_popover });
	}

	handleOpenNotice = () => {
		this.handleNoticeAllRead();
		this.setState({
			openNotice: !this.state.openNotice,
		});
	};

	handleNoticeAllRead = () => {
		sendGAEvent({
			type:"click_notification_button",
			data:{
			}
		})
		const { markNotificationAllRead, getNotifications, loginUserData, } = this.props;
		const { 
			accessRight,
			activeAccessRight: { access_right: { permission } }
		} = loginUserData;
		const notification_type = ['GENERAL'];
		const feat_notification = permission == content_placeholders.user_type.wealth_user ? 'WEALTH' : 'ORDERBOOK';
		notification_type.push(`${feat_notification}_NEW_FEATURE`);
		notification_type.push(`${feat_notification}_AVAILABLE_FEATURE`);

		const qs_payload = {
			type: encodeURIComponent(`{${notification_type.join(',')}}`)
		}
		markNotificationAllRead();
		setTimeout(function(){
			getNotifications({ qs: qs_payload });
		}, 1000);
		
	};

	handleVideoDialogOpen(){
		const { video } = this.state;
		video["open"] = true;
		this.setState({ video: video });
	}

	handleVideoDialogClose() {
		const { video } = this.state;
		video["open"] = false;
		this.setState({ video: video });
	}

	handleClose = (evt, type) => {
		this.setState({
			[type]: false,
			anchorEl: null,
		});
	};

	closeAccountPopup() {
		this.setState({ open: false });
	}

	notificationPrefPopup(){
		const { notification_preferences } = this.state;
		notification_preferences['open'] = !notification_preferences['open'];
		this.setState({ notification_preferences });
	}

	onNotiValueChange(evt) {
		const { name, checked } = evt.target;
		const { notification_preferences } = this.state;
		notification_preferences[name] = checked;
		this.setState({ notification_preferences });
	}

	onNotiSelectChange(evt){
		const { name, value } = evt.target;
		const { notification_preferences } = this.state;
		notification_preferences[name] = value === "false" ? false : true;
		this.setState({ notification_preferences });
	}

	onSubmitNotiPref(evt){
		const { notification_preferences } = this.state;
		
		const payload = {
			weekly_update: notification_preferences.weekly_update,
			product_update: notification_preferences.product_update,
			email_noti: notification_preferences.email_noti,
		}

		let watchlist_pref = '';
		if( notification_preferences.all_new_block ) watchlist_pref += "ALL NEW BLOCK|";
		if( notification_preferences.best_price_movement ) watchlist_pref += "BEST PRICE MOVEMENT";
		payload['watchlist_pref'] = watchlist_pref;

		const { updateNotificationPreferences } = this.props;
		updateNotificationPreferences(payload);
	}

	openAccountPopup() {
		this.setState({ open: true });
	}

	openSellPopup() {
		sendGAEvent({
			type:"click_express_sell",
			data:{}		 
		})
		this.setState({ openSell: true });
	}

	closeSellPopup() {
		this.setState({ openSell: false });
	}

	handleSnackbarClose = (event, reason) => {
		const { passwordError } = this.state;

		if (!passwordError) {
			this.setState({
				openStyle: false,
				openSell: false,
				company: "",
				notional: "",
				price: "",
				comment: "",
			});
		} else {
			this.setState({ openStyle: false });
		}

		const { resetSnackBar } = this.props;
		resetSnackBar();
	}

	handleLogOut(evt) {
		sessionStorage.removeItem("tabIndex");
		const accessToken = GetToken();
		this.props.logoutWithEmail({ email: accessToken.email, type: false });
		this.setState({ anchorEl: null, openMenu: false });
		destroyLiveChat();
	}

	handlePassChange = (e) => {
		const passwordInput = e.target.value;
		const { new_password1 } = this.state;
		if (new_password1 === passwordInput) {
			this.setState({ confirmPassError: "Password Match" });
		} else {
			this.setState({ confirmPassError: "" });
		}
	};

	onSubmitPassword = (e) => {
		e.preventDefault();
		const { old_password, new_password1, new_password2 } = this.state;
		const { confirmPasswordChange, passwordError } = this.props;
		confirmPasswordChange({ old_password, new_password1, new_password2 });
		if (!passwordError) {
			// Not sure why it is not working
			this.setState({
				old_password: "",
				new_password1: "",
				new_password2: "",
				open: false,
			});
		}
	};

	handleChange = (e) => {
		const { name, value } = e.target;
		this.setState({ [name]: value });
	};

	closetheBanner = () => {
		this.setState({ closeBanner: false });
	};

	passwordMatch = () => {
		const { new_password1, new_password2 } = this.state;
		if (new_password2 !== "" && new_password1 !== new_password2) {
			this.setState({ confirmPassError: "Password not match" });
		}
	};

	handlePassChange = (e) => {
		const passwordInput = e.target.value;
		const { new_password1 } = this.state;
		if (new_password1 === passwordInput) {
			this.setState({ confirmPassError: "Password Match" });
		} else {
			this.setState({ confirmPassError: "" });
		}
	};

	handleSubmitSellInterest(evt) {
		const { company, notional, price, comment } = this.state;

		if (company.trim()) {
			let message = "Sell interest received: \n";
			message += "Company: " + company.trim() + " \n";
			message += "Target Notional: " + notional + " \n";
			message += "Target Price: " + price + " \n";
			message += "Comment: " + comment + " \n";

			const payload = { message: message };

			const { createUserMessage } = this.props;
			createUserMessage(payload);
		} else {
			this.setState({
				openStyle: true,
				passwordError: true,
				responseMsg: "Please fill in the company you are interested to sell.",
			});
		}
	}

	handleLink = (item, evt) => {
		if (item.url) {
			let newUrl = "";
			if (
				item.url.trim().indexOf("http:") > -1 ||
				item.url.trim().indexOf("https:") > -1
			) {
				newUrl = item.url.trim();
			} else {
				newUrl = "http://" + item.url.trim();
			}
			javascript: window.open(newUrl, "_blank");
		} else if (item.company && item.company.id) {
			this.props.history.push(`/club/company-detail/${item.company.id}`);
		}
		this.props.getNotifications();
	};

	renderNotice = () => {
		// console.log(this.props);
		let { notificationsResponse } = this.props;
		if (notificationsResponse && notificationsResponse.length > 0) {
			notificationsResponse.map((item) => {
				item.showContent = `${item.content}`;
				return item;
			});
		}

		return (
			<div 
				className="mask">
				<div className="heard-notice-window">
					<div className="flex-row-center">
						<span style={{ color: "#002060" }}>NOTIFICATIONS</span>
						<Notifications
							style={{ color: "#002060", fontSize: "1.2em", marginLeft: "1em" }}
						/>
					</div>
					{notificationsResponse &&
						notificationsResponse.length > 0 &&
						notificationsResponse.map((item, index) => {
							// console.log( item );
							if( 
								item.notification_type == "AVAILABLE_FEATURE" ||
								item.notification_type == "NEW_FEATURE" ){
									let featureName = ''
									let description = ''
									try{
										featureName = item.showContent.match("\\[.*]")[0];
										description = item.showContent.split("]")[1];
									}catch(e){
										description = item.showContent;
									}

									// console.log( description )
									let featureType = item.notification_type == "AVAILABLE_FEATURE" ? "PRODUCT FEATURE" : "PRODUCT ROADMAP" 
									return (
										<div key={index} className="notice-item">
											<div>
												<p className="notice-item-type">{featureType}</p>
												{featureName && <p className={item.notification_type == "AVAILABLE_FEATURE" ? 
															"notice-item-feature-name" : "notice-item-feature-name notice-item-new-feature"}>{featureName}</p>}
											</div>
											{description && 
											<div className={item.notification_type == "AVAILABLE_FEATURE" ? 
															"notice-item-description" : "notice-item-description notice-item-new-feature"}
											dangerouslySetInnerHTML={{
												__html: replaceEnterCharacter(description),
											}} />}
												
										</div>
									)
							} else {
								const date_string = item.created_at.split(" ");
								const date_obj = date_string.length > 1 ? new Date(date_string[0] + "T" + date_string[1]) : new Date();
								return (
									<div
										key={index}
										onClick={(e) => this.handleLink(item, e)}
										className={`flex-row ${
											index == notificationsResponse.length - 1
												? ""
												: "heard-notice-border"
										} `}>
										{item.company && item.company.logo ? (
											<img
												className="notice-logo"
												src={item.image ? item.image : item.company.logo}
											/>
										) : (
											<span className="notice-logo"></span>
										)}

										<div
											className="heard-notice-text"
											>
												<span className="date">{ date_obj.toDateString() }</span>
											<div
												dangerouslySetInnerHTML={{
													__html: replaceEnterCharacter(item.showContent),
												}} />
										</div>
									</div>
								)
							}
							
						}
						)}
				</div>
			</div>
		);
	};

	handleLogoClick = () => {
		const { 
			showWatchList, 
			loginUserData: { activeAccessRight: { access_right: { permission } } } 
		} = this.props;
		sessionStorage.removeItem("tabIndex");
		if (!!showWatchList) {
			let path = window.location.pathname;
			if (path.indexOf(`/club/pipeline`) > -1) {
				window.location.reload();
				return;
			}
		}
		let logo_link = `/app/Pipeline`;
		if(permission){
			if(permission == content_placeholders.user_type.new_version_user){
				logo_link = `/club/Pipeline`;
			} else if(permission == content_placeholders.user_type.wealth_user){
				logo_link = `/wealth/pipeline`;
			} else if(permission == content_placeholders.user_type.orderbook_user){
				logo_link = `/orderbook/pipeline`;
			} else if(permission == content_placeholders.user_type.boost_user){
				logo_link = `/boost/pipeline`;
			}
		}
		this.props.history.push(logo_link);
	};

	render() {
		const { 
			classes, loginUserData, pi_content, ca_content, match, location,
			notificationsResponse } = this.props;

		const { params } = match;
		
		const unread_noti = notificationsResponse.filter(item => !item.read);
		// let tmpId = uuid.v4();
		const {
			openMenu,
			openComplianceMenu,
			anchorEl,
			open,
			openStyle,
			old_password,
			new_password1,
			new_password2,
			showMobile,
			confirmPassError,
			closeBanner,
			openSell,

			company,
			notional,
			price,
			comment,

			passwordError,
			responseMsg,
			notification_preferences,
			video,
			subscription_popover,
		} = this.state;

		let initial = "";
		if (loginUserData.first_name) initial += loginUserData.first_name[0];
		if (loginUserData.last_name) initial += loginUserData.last_name[0];
		const { user_type, activeAccessRight: { access_right: { permission } } } = loginUserData;

		let logo_url = '';
		switch( permission ){
			case content_placeholders.user_type.wealth_user:
				logo_url = content_placeholders.logos.wealth;
				break;
			default:
				logo_url = content_placeholders.logos.gateway;
		}

		if( location.pathname.indexOf( '/wealth/' ) > -1 ){
			logo_url = content_placeholders.logos.wealth;
		}

		if( location.pathname.indexOf( '/dashboard' ) > -1 ){
			logo_url = content_placeholders.logos.gateway;
		}

		if( location.pathname.indexOf( '/boost/' ) > -1 ){
			logo_url = content_placeholders.logos.boost;
		}
		let publicPageToken = '';
		let portal_type_sq = '';
		if( location.pathname.indexOf( '/public/company-detail/' ) > -1 ){
			publicPageToken = location.pathname.split("/").pop();
			portal_type_sq = extractPortalType(location.pathname);
		}
		const email = loginUserData.email ? loginUserData.email : "";
		const content_to_render = pi_content.content.filter((item) => !item.overlay_hide);

		const header_menu_classes = showMobile
			? "align-right header-menu expand"
			: "align-right header-menu";

		return (
			<div>
				<AppBar className={classes.appBar}>
					<Formik
						initialValues={{
							new_password1: "",
							new_password2: "",
						}}
						validationSchema={Yup.object().shape(validations)}
					>
						{({ touched, errors, handleChange, handleBlur }) => (
							<Fragment>
								<Grid container justify="center" direction="column">
									<Dialog
										open={open}
										onClose={this.closeAccountPopup}
										aria-labelledby="form-dialog-title"
										fullWidth
									>
										<form>
											<DialogTitle id="form-dialog-title" className="no-margin">
												Change Password
											</DialogTitle>
											<DialogContent>
												<DialogContentText id="alert-dialog-description">
													<FormControl fullWidth>
														<TextField
															autoFocus
															margin="dense"
															id="old_password"
															label="Old Password"
															type="password"
															name="old_password"
															onChange={(e) => {
																this.handleChange(e);
															}}
															required
															value={old_password}
															fullWidth
														/>
														{errors.old_password && touched.old_password ? (
															<div className="input-feedback">
																{errors.old_password ? (
																	<FormHelperText id="old_password">
																		{errors.old_password}
																	</FormHelperText>
																) : (
																	""
																)}
															</div>
														) : (
															<div className="bottom-margin-5"></div>
														)}
													</FormControl>
													<FormControl
														fullWidth
														className={classes.formControl2}
													>
														<TextField
															margin="dense"
															id="new_password1"
															label="New Password"
															type="password"
															name="new_password1"
															onBlur={(e) => {
																handleBlur(e);
															}}
															onChange={(e) => {
																this.handleChange(e);
																handleBlur(e);
																handleChange(e);
															}}
															required
															value={new_password1}
															validate={[passwordsMatch]}
															fullWidth
														/>
														{errors.new_password1 && touched.new_password1 ? (
															<div className="input-feedback">
																{errors.new_password1 ? (
																	<FormHelperText
																		id="new_password1"
																		className={
																			classes.errorRedColorPasswordRequired
																		}
																	>
																		<font color="red">
																			{errors.new_password1}
																		</font>
																	</FormHelperText>
																) : (
																	""
																)}
															</div>
														) : (
															<div className="bottom-margin-5"></div>
														)}
													</FormControl>
													<FormControl fullWidth>
														<TextField
															margin="dense"
															id="new_password2"
															label="Confirm New Password"
															type="password"
															required
															name="new_password2"
															validate={[passwordsMatch]}
															onBlur={(e) => {
																handleBlur(e);
																this.passwordMatch(e);
															}}
															onChange={(e) => {
																this.handleChange(e);
																this.handlePassChange(e);
																handleBlur(e);
																handleChange(e);
															}}
															value={new_password2}
															fullWidth
														/>
														{errors.new_password2 && touched.new_password2 ? (
															<div className="input-feedback">
																{errors.new_password2 ? (
																	<FormHelperText id="new_password2">
																		<font color="red">
																			{errors.new_password2}
																		</font>
																	</FormHelperText>
																) : (
																	""
																)}
															</div>
														) : (
															<div className="bottom-margin-5"></div>
														)}
													</FormControl>
													<Typography
														variant="caption"
														component={"span"}
														gutterBottom
														align="left"
													>
														Password need to have at least 12 characters and
														includes the following 4 types:
														<br />
														- Uppercase
														<br />
														- Lowercase
														<br />
														- Digit
														<br />
														- One of the following special characters:
														<br />
														~!@#$%^&amp;*()-_=+{}|[]\:;?,./
													</Typography>
													<PasswordStrengthMeter password={new_password1} />
												</DialogContentText>
											</DialogContent>
											<DialogActions>
												<Button
													onClick={this.closeAccountPopup}
													color="primary"
												>
													Close
												</Button>
												<Button
													type="submit"
													color="primary"
													onClick={(e) => {
														this.onSubmitPassword(e);
													}}
													disabled={
														!confirmPassError ||
														!touched.new_password2 ||
														errors.new_password2 ||
														!touched.new_password1 ||
														errors.new_password1
													}
												>
													Confirm Change
												</Button>
											</DialogActions>
										</form>
									</Dialog>
								</Grid>
							</Fragment>
						)}
					</Formik>

					<SellSharesDialog
						closeSellPopup={this.closeSellPopup}
						handleSubmitSellInterest={this.handleSubmitSellInterest}
						openSell={openSell}
						handleChange={this.handleChange}
						{...{ company, notional, price, comment }}
					/>

					<NotificationPreferencesDialog
						onClose={this.notificationPrefPopup}
						onNotiValueChange={this.onNotiValueChange}
						onNotiSelectChange={this.onNotiSelectChange}
						onSubmitNotiPref={this.onSubmitNotiPref}
						open={notification_preferences.open}
						notification_preferences={notification_preferences}
						/>

					<SubscriptionPopover
						anchorEl = {subscription_popover.anchorEl}
						handleClosePopper = {this.handleClosePopper}
						/>
					<Toolbar className={classes.Toolbar}>
						<Grid container spacing={2}>
							<Grid item xs={12} md={6}>
								<div className="logo-tagline-container">
									<div
										onClick={() => permission ? this.handleLogoClick() : null}
										// to= {logo_link}
										className="header-logo">
										<img
											src={logo_url}
											alt="Gateway Private Markets"
											className="logo-img"
										/>
									</div>
									<div className="tagline">
										<React.Fragment>
											<Typography variant="subtitle1">The Platform For PE/VC Secondaries</Typography>
												<p>Network | Infrastructure | Data</p>
											</React.Fragment>
									</div>
									<div className="clear"></div>
								</div>
								
								{closeBanner &&
									permission != content_placeholders.user_type.new_version_user &&
									permission != content_placeholders.user_type.wealth_user &&
									permission != content_placeholders.user_type.trial_user
								? (
									<Fragment></Fragment>
								) : permission == content_placeholders.user_type.trial_user ?
									(
										<span className="trial-label">Trial</span>
									) : ""
								}
								<div className="clear"></div>
								<Button
									className="btn-mobile-menu"
									onClick={this.toggleMobileMenu}
									>
									<ListItemIcon>
										<AppsIcon style={{ color: green[50] }} />
									</ListItemIcon>
								</Button>
							</Grid>
							<Grid item xs={12} md={6}>
								<div className={header_menu_classes}>

									{/* {
										loginUserData.activeAccessRight &&
										loginUserData.activeAccessRight.access_right &&
										loginUserData.activeAccessRight.access_right.permission != content_placeholders.user_type.trial_user ?
										<Fragment>
											<Button onClick={this.openSellPopup} className="sell-interest">
												<span className="header-menu-button inverted font-control">
													Express sell interest
												</span>
											</Button>
										</Fragment>
											
										: ''
									}

									<Button onClick={this.handleVideoDialogOpen}>
										<span className="header-menu-button font-control">
											Demo Video
										</span>
									</Button> */}

									{
										permission == content_placeholders.user_type.orderbook_user &&
										user_type == content_placeholders.user_candidate_type.pro_user ?
											<Button 
												style={{ 
													background: 'rgba(252,163,16,0.8)',
													textTransform: 'none',
												}} 
												onClick={(evt) => this.handleClick(evt, 'lifeTimeUser')}>
												Pro
											</Button> :
												permission == content_placeholders.user_type.orderbook_user &&
												(user_type == content_placeholders.user_candidate_type.lifetime_candidate ||
													user_type == content_placeholders.user_candidate_type.lifetime_user
												?
													<Button 
														style={{ 
															background: 'rgba(252,163,16,0.8)',
															textTransform: 'none',
														}} 
														onClick={(evt) => this.handleClick(evt, 'lifeTimeUser')}>
														Premium
													</Button>
													: 
													<Button 
														style={{ 
															background: 'rgba(69,97,168,0.8)',
															textTransform: 'none',
														}} 
														onClick={(evt) => this.handleClick(evt, 'lifeTimeUser')}>
														Basic
													</Button>)
									}

									<Button onClick={(evt) => this.handleClick(evt, 'openComplianceMenu')}>
										<span className="header-menu-button font-control compliance">
											Compliance
										</span>
									</Button>

									{
										permission ? 
											<Button onClick={(evt) => this.handleClick(evt, 'openMenu')}>
												<span className="header-menu-button font-control">
													Account{" "}
													{/* <i
														className="arrow down"
														style={{ fontSize: "17px" }}
													></i> */}
												</span>
											</Button> : ''
									}

									{
										permission ?
											<Button onClick={this.handleLogOut.bind(this)}>
												<span className="header-menu-button font-control">
													LogOut
												</span>
											</Button> : ''
									}
									
									{
										[
											content_placeholders.user_type.wealth_user,
											content_placeholders.user_type.orderbook_user
										].indexOf(permission) > -1 &&
											<Button
												className="heard-notice-wrap"
												onClick={this.handleOpenNotice}
												>
												<div className="notice-inner">
													<Badge
														badgeContent={
															// this.props.notificationsResponse &&
															// !this.state.openNotice
															// 	? this.props.notificationsResponse.length
															// 	: 0
															unread_noti.length
														}
														color="error">
														<Notifications
															style={{ color: "#002060", fontSize: "2em" }}
															/>
													</Badge>
												</div>
												{this.state.openNotice && this.renderNotice()}
											</Button>
									}
									
								</div>

								<Menu 
									className="sub-menu"
									open={openComplianceMenu}
									anchorEl={anchorEl}
									keepMounted
									variant="menu"
									onClose={(evt) => this.handleClose(evt, 'openComplianceMenu')}
									>
									{
										permission != content_placeholders.user_type.trial_user &&
										permission != content_placeholders.user_type.orderbook_user &&
										permission != content_placeholders.user_type.wealth_user ?
											<MenuItem>
												<Link to={publicPageToken ? `/confidentiality-agreement/${publicPageToken}${portal_type_sq ? `?portal_type=${portal_type_sq}` : ''}` : "/confidentiality-agreement"} target="_blank">Confidentiality Agreement</Link>
											</MenuItem>
										: ''
									}

									{
										permission == content_placeholders.user_type.orderbook_user ? (
											<MenuItem>
												<Link to={publicPageToken ? `/ob-platform-guide/${publicPageToken}${portal_type_sq ? `?portal_type=${portal_type_sq}` : ''}` :"/ob-platform-guide"} target="_blank">Orderbook Platform Guide</Link>
											</MenuItem>
										) : ''
										
									}

								<MenuItem>
									<Link to={publicPageToken ? `/privacy-policy/${publicPageToken}${portal_type_sq ? `?portal_type=${portal_type_sq}` : ''}` :"/privacy-policy"} target="_blank">Privacy Policy</Link>
								</MenuItem>


								{
									permission == content_placeholders.user_type.trial_user ||
									permission == content_placeholders.user_type.wealth_user ||
									permission == content_placeholders.user_type.new_version_user ?
										<MenuItem>
											<Link to={publicPageToken ? `/risk-disclosure/${publicPageToken}${portal_type_sq ? `?portal_type=${portal_type_sq}` : ''}` :"/risk-disclosure"} target="_blank">Risk Disclosure</Link>
										</MenuItem>
									: ''
								}

								{
									permission == content_placeholders.user_type.trial_user ||
									permission == content_placeholders.user_type.wealth_user ||
									permission == content_placeholders.user_type.new_version_user ?
										<MenuItem>
											<Link to={publicPageToken ? `/terms-of-use/${publicPageToken}${portal_type_sq ? `?portal_type=${portal_type_sq}` : ''}` :"/terms-of-use"} target="_blank">Terms of Use</Link>
										</MenuItem>
									: ''
								}

								</Menu>

								<Menu
									className="header-popup"
									id="simple-menu"
									anchorEl={anchorEl}
									keepMounted
									variant="menu"
									open={openMenu}
									onClose={(evt) => this.handleClose(evt, 'openMenu')}
									>
								<li className="first-item">
									<span className="avatar">{initial}</span>
									{email}
								</li>
								<MenuItem
									className="p-2 move-up"
									onClick={this.openAccountPopup}
								>
									Change Password
								</MenuItem>
								{/* <MenuItem
									onClick={this.notificationPrefPopup}
									className="p-2"
									>
										Notification Preferences
								</MenuItem> */}
								</Menu>
							</Grid>
						</Grid>
					</Toolbar>

					<Snackbar
						anchorOrigin={{
							vertical: "top",
							horizontal: "center",
						}}
						openStyle={openStyle}
						handleSnackbarClose={this.handleSnackbarClose}
						type={passwordError ? "error" : "success"}
						message={responseMsg}
						/>


					<VideoDialog
						open={video.open}
						url={video.url}
						link_name={video.link_name}
						closePopup={this.handleVideoDialogClose}
						/>
				</AppBar>
			</div>
		);
	}
}

export default withStyles(HeaderStyles)(Header);
