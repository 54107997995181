import {
	put,
	takeLatest,
	all,
	call,
	delay
} from 'redux-saga/effects';
// import { showLoader as showLoaderAction } from '../common/Loader';

import {
	getPipeline,
	expressInterest,
	headquaterCountry,
	verticals,
	resetExpressInterest,
	getAdBanner1
} from './Action';

import { Unauthorized } from '../login/Action';

import {
	ShowLoader,
	HideLoader
} from '../root/Action';

import {
	pipeline,
	web_content
} from '../../api';

function* getPipelineResponder({ payload }) {
	yield put(ShowLoader(true));
	try{
		let company_names = [], investor_names = [], vertical_names = [], headquarter_country = [];
		
		if (payload.tags && payload.tags.length){

			company_names = payload.tags.filter(item => item.indexOf("Company:") > -1)
			investor_names = payload.tags.filter(item => item.indexOf("Investor:") > -1)
			// vertical_names = payload.tags.filter(item => item.indexOf("Vertical:") > -1)
			// headquarter_country = payload.tags.filter(item => item.indexOf("Headquarter Country:") > -1)
			
			company_names = company_names.map(item => {
				let a = item.split(":");
				return encodeURIComponent(a[a.length - 1])
			});

			investor_names = investor_names.map(item => {
				let a = item.split(":");
				return encodeURIComponent(a[a.length - 1])
			});

			// vertical_names = vertical_names.map(item => {
			// 	let a = item.split(":");
			// 	return encodeURIComponent(a[a.length - 1])
			// });

			// headquarter_country = headquarter_country.map(item => {
			// 	let a = item.split(":");
			// 	return encodeURIComponent(a[a.length - 1])
			// })

		}
		let query_string = "?"
		if(payload.sortDisc===0 || payload.sortDisc===1){
			if(payload.sortDisc===0){
				query_string+="sortDisc=0&";
			}
			else if(payload.sortDisc===1){
				query_string+="sortDisc=1&";
			}
		}
		if(payload.sortComp===0 || payload.sortComp===1){
			if(payload.sortComp===0){
				query_string+="sortComp=0&";
			}
			else if(payload.sortComp===1){
				query_string+="sortComp=1&";
			}
		}
		if(payload.sortPost===0 || payload.sortPost===1){
			if(payload.sortPost===0){
				query_string+="sortPost=0&";
			}
			else if(payload.sortPost===1){
				query_string+="sortPost=1&";
			}
		}

		if(payload.selected){
			
			let selected_disc_prem = payload.selected.filter(item => item.selected);
			if(selected_disc_prem.length && selected_disc_prem.length != payload.selected.length){
				selected_disc_prem = selected_disc_prem.map(item => item.query_string)
				query_string += "disc_prem=" + selected_disc_prem.join("|") + "&";
			}
			
		}

		if(payload.selectedTotal){
			
			let selected_ent_val = payload.selectedTotal.filter(item => item.selected)
			if(selected_ent_val.length && selected_ent_val.length != payload.selectedTotal.length){
				selected_ent_val = selected_ent_val.map(item => item.query_string);
				query_string += "latest_val=" + selected_ent_val.join("|") + "&";
			}
			
		}

		if(payload.selectedVertical){
			// console.log(payload.selectedVertical)
			let selected_vertical = payload.selectedVertical.filter(item => item.selected);
			if( selected_vertical.length != payload.selectedVertical.length ){
				selected_vertical = selected_vertical.map(item => item.id);
				query_string += "vertical="+selected_vertical.join("|") + "&";
			}
			
		}

		if(payload.selectedHQCountry){
			
			let selected_hq_country = payload.selectedHQCountry.filter(item => item.selected);
			if( selected_hq_country.length != payload.selectedHQCountry.length ){
				selected_hq_country = selected_hq_country.map(item => item.name);
				query_string += "headquarter_country="+selected_hq_country.join("|")+"&";
			}
			
		}

		query_string += company_names.length ? "company_name="+ company_names.join("|") + "&" : "";
		query_string += investor_names.length ? "investor_name="+investor_names.join("|") + "&" : "";
		// query_string += vertical_names.length ? "vertical="+vertical_names.join("|")+"&" : "";
		// query_string += headquarter_country.length ? "headquarter_country="+headquarter_country.join("|")+"&" : "";
		query_string += payload.limit ? "limit=" + payload.limit + "&" : "";
		query_string += payload.offset ? "offset=" + payload.offset + "&" : "";
		if (payload.company_id) query_string += "company_id="+payload.company_id;
		const { data } = yield call(
			pipeline.getPipeline,
			query_string,
			payload
		);
		yield put(getPipeline.success(data));
	} catch (e){
		
		if(e.response.status == 401){
			yield put(Unauthorized(e));
		}
		
		yield put(getPipeline.failure(e));
	} finally {
		yield put(HideLoader(true));
	}
}

function* expressInterestResponder({ payload }){
	// console.log(payload);
	yield put(ShowLoader(true));
	try{
		const { data } = yield call(pipeline.expressInterest, payload);
		yield put(expressInterest.success(data));
		yield delay(3000);
		yield put(resetExpressInterest());
	} catch(e){
		if(e.response.status == 401){
			yield put(Unauthorized(e));
		}
		yield put(expressInterest.failure(e));
	} finally{
		yield put(HideLoader(true));
	}
}

function* headquaterCountryResponder({ payload }){
	try{
		yield delay(2000);
		const { data } = yield call(pipeline.headquaterCountry, payload);
		yield put(headquaterCountry.success(data));
	} catch(e){
		if(e.response.status == 401){
			yield put(Unauthorized(e));
		}
		yield put(headquaterCountry.failure(e));
	} finally{

	}
}

function* verticalsResponder({ payload }){
	try{
		yield delay(3000);
		const { data } = yield call(pipeline.verticals, payload);
		yield put(verticals.success(data));
	} catch(e){
		if(e.response.status == 401){
			yield put(Unauthorized(e));
		}
		yield put(verticals.failure(e));
	} finally{

	}
}

function* getAdBanner1Responder(){
	try{
		const { data } = yield call(web_content.getAdBanner1);
		// const response_payload = data.content_text ? JSON.parse(data.content_text) : { };
		// yield put(getAdBanner1.success(response_payload));
		let response_payload = data.results ? data.results.map(item => {
			try{
				return JSON.parse(item.content_text);
			} catch(e){
				return {}
			}
		}) : [];
		response_payload = response_payload.filter(item => item.image_url && item.company_id);
		yield put(getAdBanner1.success(response_payload));
	} catch(e){
		if(e.response.status == 401){
			yield put(Unauthorized(e));
		}
		yield put(getAdBanner1.failure(e));
	} finally {

	}
}

function* saga(){
	yield all([
		takeLatest(getPipeline.request, getPipelineResponder),
		takeLatest(expressInterest.request, expressInterestResponder),
		takeLatest(headquaterCountry.request, headquaterCountryResponder),
		takeLatest(verticals.request, verticalsResponder),
		takeLatest(getAdBanner1.request, getAdBanner1Responder)
	])
}

export default saga;