import React from 'react';
import { withStyles } from '@material-ui/core';

import { Styles } from '../../styles';
import { default as CompanyDetailContent } from './CompanyDetailContent';
import { default as DealShareRiskDisclosure } from './components/DealShareRiskDisclosure';

import {
    FullScreenPriceChartDialog,
} from './components';

class PublicCompanyDetail extends React.Component{
    state = {
        full_screen_open: false,
    }

    constructor(props){
        super(props);
    }

    componentDidMount(){
        const { 
            match: { params: { id, token } },
            getRiskDisclosure,
            getSharedUser,
            addCount,
        } = this.props;

        getRiskDisclosure({ 
            public_url: true,
            // root_path: 'public', 
            // sub_path: 'search',
            // company_identifier: token,
            qs: { token: token }
        });

        getSharedUser({
            qs: {
                token: token
            }
        });

        addCount({ token: token });
        
    }

    handlePriceChartFullScreen = (event) => {
		const { full_screen_open } = this.state;
        this.setState({ full_screen_open: !full_screen_open });
	}

    handleAcknowledgeRiskDisclosure = (evt) => {
        const { 
            match: { params: { id, token } },
            confirmRiskDisclosure,
            getAllCompanies,
            getFundingRound,
            getLatestFundingRound,
            getDealTimeline,
            getHistoricalPrice,
            getLiveOffers,
            getLiveDemand,
            getLiveOffersWatchlist,
            getLiveDemandWatchlist,
            getDealOfferDetail,
            getDealHighlight,
            getDealType,
            getGPDetail,
           
        } = this.props;
        confirmRiskDisclosure();

        getAllCompanies({ 
            public_url: true,
            company_identifier: token 
        });

        // getLatestFundingRound({ 
            
        //     root_path: 'public', sub_path: 'latest', company_identifier: token });
        getFundingRound({ 
            public_url: true,
            // root_path: 'v2', 
            // sub_path: 'wealth/public',
            // company_identifier: token,
            qs: { token: token, show_all: 1 }
        });
        // getHistoricalPrice({ 
        //     // root_path: 'public', 
        //     // sub_path: 'get-weighted-price',
        //     public_url: true,
        //     qs: { token: token, last_N_day: 120 }
        // });
        getDealType({ 
            public_url: true,
            qs: { token: token }
        });

        getGPDetail({ 
            public_url: true,
            // root_path: 'v2', 
            // sub_path: 'wealth/public',
            // company_identifier: token 
            qs: { token: token }
        });
        
        getLiveOffers({ 
            public_url: true,
            // root_path: 'v2', 
            // sub_path: 'wealth/public/offers',
            // company_identifier: token,
            qs: { token: token, live: true, asc_or_desc: 'asc' } });

        getLiveDemand({ 
            public_url: true,
            // root_path: 'v2', 
            // sub_path: 'wealth/public/demands',
            // company_identifier: token,
            qs: { token: token, live: true }  });
        getLiveOffersWatchlist({
            root_path: 'v2',
            sub_path: 'wealth/public/offers/watchlist-total-notional',
            company_identifier: '',
            qs: { token: token, live: true, asc_or_desc: 'asc' }
        });
        getLiveDemandWatchlist({
            root_path: 'v2',
            sub_path: 'wealth/public/demands/watchlist-total-notional',
            company_identifier: '',
            qs: { token: token, live: true }
        });
        getDealTimeline({ 
            public_url: true,
            // root_path: 'v2',
            // sub_path: 'wealth/public', 
            // company_identifier: token,
            qs: { token: token }
        });

        getDealHighlight({ 
            // root_path: 'v2', 
            // sub_path: 'wealth/public', 
            public_url: true,
            qs: { token:  token} 
        });
        // getDealOfferDetail({ root_path: 'public', company_identifier: token });
        
    }

    render(){
        const { full_screen_open } = this.state;

        const { 
            riskDisclosureConfirmed,
            riskDisclosureResponse,
            AllCompaniesResponse,
            shared_user,
            loginUserData,
            historicalPrice,
            fundingRounds,
            match: { params: { token } },
        } = this.props;
        // let id = this.props.match.params.id;
        let companyDetailResponse = AllCompaniesResponse;

        loginUserData['email'] = shared_user && shared_user.user_email ? shared_user.user_email : ''; 
        
        return (
            <div className="home">
                <div className="top-padding"></div>
                <div className="home_main">
                    <div className="sidebar-container company-page narrow">

                    </div>
                    <div className="home-right company-detail-right narrow" id="home-right">
                        {
                            riskDisclosureConfirmed ?
                                <CompanyDetailContent
                                    sharedPage={true}
                                    public_mode={true}
                                    handlePriceChartFullScreen={this.handlePriceChartFullScreen}
                                    {...this.props}
                                    {...{
                                        companyDetailResponse,
                                    }}
                                    /> : 
                                <DealShareRiskDisclosure
                                    public_mode={true}
                                    handleAcknowledgeRiskDisclosure={this.handleAcknowledgeRiskDisclosure}
                                    {...{
                                        riskDisclosureResponse,
                                    }}
                                    />
                        }
                        

                        <FullScreenPriceChartDialog
                            open={full_screen_open}
                            price_chart_payload={{...{
                                ...{ token: token },
                                historical_pricing: this.props.historicalPrice,
                                funding_rounds: this.props.fundingRounds,
                                public_mode: true
                            }}}
                            handlePriceChartFullScreen={this.handlePriceChartFullScreen}
                            />
                    </div>
                </div>
            </div>
        )
    }
}

export default withStyles(Styles)(PublicCompanyDetail);