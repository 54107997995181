import React, { Fragment } from "react";
import { Styles } from "../../styles";
import { PipelineSidebar } from "../../common";
import {
    Box,
    Grid,
    Button,
    Typography,
    withStyles,
    Paper,
    Card,
} from "@material-ui/core";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import {
    InvestorVetting,
    RejectReasonWrapper,
    ConfirmSubmitApprovalDialog,
} from "./components";

class Dashboard extends React.Component {
    state = {
        tabFocusIndex: 0,
        currentIndexShowingInPopup: 0,
        showReasonPopup: false,
        investors: [
            { 'investor': 'ABC Company', 'investor_type': 'Asset Manager', 'isDirect': true, 'ticket_size': '1M-5M', 'strategic_or_passive': false, 'approval': true, 'comment': [] },
            { 'investor': 'Family Office 1', 'investor_type': 'Family Office', 'isDirect': true, 'ticket_size': '5M-10M', 'strategic_or_passive': true, 'approval': true, 'comment': [] },
            { 'investor': 'ABC Company', 'investor_type': 'Asset Manager', 'isDirect': true, 'ticket_size': '1M-5M', 'strategic_or_passive': false, 'approval': true, 'comment': [] },
            { 'investor': 'Family Office 1', 'investor_type': 'Family Office', 'isDirect': true, 'ticket_size': '5M-10M', 'strategic_or_passive': true, 'approval': true, 'comment': [] },
        ],
        distanceBottom: 5,
        get_more_in_process: false,
        fixHeaderPadding: 210,
        fixHeader: false,
        qs: {
            fundraising_id: 0,
            offset: 0,
            limit: 30
        },
        submitConfirmDialog: {
            open: false,
            investors: []
        }
    }

    constructor(props) {
        super(props);
        this.handleConfirmRejectReason = this.handleConfirmRejectReason.bind(this);
        this.investorVetting = React.createRef();
    }

    componentDidMount(){
        const { 
            match: { params : { id } },
            getProspectInvestorList,
        } = this.props;

        const { qs } = this.state;
        qs['fundraising_id'] = id;

        getProspectInvestorList({ fundraising_id: id, qs });
        this.setState({ qs });
        window.addEventListener('scroll', this.handleScrollEvent);
    }

    componentWillUnmount(){
        window.removeEventListener('scroll', this.handleScrollEvent);
    }

    static getDerivedStateFromProps(nextProps, prevState){
        const { 
            // match: { params: { id } },
            prospect_investors,
            refresh_data,
        } = nextProps;

        if(
            // ( id != prevState.company_id && prospect_investors.resultList.length ) ||
            prospect_investors.resultList.length != prevState.investors.length ||
            refresh_data
        ) {

            const investors = prospect_investors.resultList.map(item => {
                item.approval = null;
                item.reasons = [];
                return item;
            });

            const new_state = {
                // company_id: id,
                investors: investors
            }

            return new_state
        } else {
            return null;
        }
    }

    shouldComponentUpdate(nextProps, nextState){
        if(
            nextProps.refresh_data &&
            !this.props.refresh_data
        ) {
            const { clearRefreshData } = this.props;
            clearRefreshData();
        }

        return true;
    }

    handleScrollEvent = (evt) => {
        const { 
            distanceBottom, 
            get_more_in_process, 
            fixHeaderPadding,
            qs,
        } = this.state;

        const { prospect_loading } = this.props;

        const current_scroll = window.scrollY;
        const window_height = window.innerHeight;
        const page_height = document.documentElement.offsetHeight;

        if(
            (current_scroll + window_height) > (page_height - distanceBottom) &&
            !prospect_loading &&
            !get_more_in_process
        ) {

        }
    }

    pageGoBack = () => {
        const { history } = this.props;
        history.push(`/boost/dashboard/${this.props.match.params.id}`);
    };

    handleShowRejectReason = (index) => {
        const { showReasonPopup, currentIndexShowingInPopup } = this.state;
        this.setState({
            showReasonPopup: !showReasonPopup,
            currentIndexShowingInPopup: index
        });
    }

    handleConfirmRejectReason = (index, reasons) => {
        const { showReasonPopup, currentIndexShowingInPopup, investors } = this.state;
        this.setState({
            showReasonPopup: false,
            currentIndexShowingInPopup: index
        });
        this.investorVetting.current.updateReasons(index, reasons);
    }

    handleConfirmShowRejectReasonInHeader = (approval) => {
        if (!approval) {
            const { showReasonPopup, currentIndexShowingInPopup } = this.state;
            this.setState({
                showReasonPopup: !showReasonPopup,
                currentIndexShowingInPopup: -1 //-1 means: show the reject reason drawer and apply it to multiple selected rows
            });
        }
    }

    handleSubmitApproval = (evt, investors) => {
        const { submitConfirmDialog  } = this.state;
        const processed_investors = investors.filter(investor => investor.approval !== null);
        submitConfirmDialog['open'] = true;
        submitConfirmDialog['investors'] = processed_investors;
        this.setState({ submitConfirmDialog: submitConfirmDialog });
    }

    handleConfirmSubmitClose = (evt) => {
        this.setState({ 
            submitConfirmDialog: {
                open: false,
                investors: []
            }
        });
    }

    handleConfirmSubmission = (evt) => {
        const { postSubmitInvestorApproval } = this.props;
        const { 
            qs: { fundraising_id }, 
            submitConfirmDialog : { investors } } = this.state;

        if( investors.length ){
            const payload = {
                fundraising_id: parseInt(fundraising_id),
                result: investors.map(item => {
                    if(item.comment && Array.isArray(item.comment) && item.comment.length > 0){
                        return { id: item.id, approval: item.approval, comment: item.comment }
                    }else{
                        return { id: item.id, approval: item.approval}
                    }
                })
            }
            postSubmitInvestorApproval(payload);
            this.handleConfirmSubmitClose(evt);
        }
    }

    render() {
        let {
            tabFocusIndex,
            showReasonPopup,
            currentIndexShowingInPopup,
            investors,
            submitConfirmDialog
        } = this.state;

        const { 
            appConfig, 
            loginUserData, 
            permissioned_deals, 
            collapseSidebar,
            match: { params: id },
        } = this.props;

        const home_right_class = collapseSidebar ? 'home-right narrow' : 'home-right';
        const paper_style = { padding: 16, width: '100%' }
        const paper_style_full_height = { padding: 16, width: '100%', height: '100%', marginTop: '10px' }

        return (
            <div className="home">
                <div className="top-padding"></div>
                <PipelineSidebar
                    tabFocusIndex={tabFocusIndex}
                    collapse={true}
                    {...this.props}
                />
                <div className={home_right_class} id="home-right">
                    <Box m={2}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Button onClick={this.pageGoBack}>
                                    <ArrowBackIcon />
                                </Button>
                            </Grid>
                        </Grid>
                        <Paper style={paper_style_full_height}>
                            <InvestorVetting ref={this.investorVetting}
                                handleSubmitApproval={this.handleSubmitApproval}
                                handleShowRejectReason={this.handleShowRejectReason}
                                handleConfirmShowRejectReasonInHeader={this.handleConfirmShowRejectReasonInHeader}
                                {...{ company_id: id, investors }} />
                        </Paper>
                    </Box>
                </div>

                <RejectReasonWrapper
                    handleShowRejectReason={this.handleShowRejectReason}
                    handleConfirmRejectReason={this.handleConfirmRejectReason}
                    open={showReasonPopup}
                    index={currentIndexShowingInPopup}
                    {...this.props}
                    />
                
                <ConfirmSubmitApprovalDialog
                    handleConfirmSubmitClose={this.handleConfirmSubmitClose}
                    handleConfirmSubmission={this.handleConfirmSubmission}
                    {...submitConfirmDialog}
                    />
            </div>
        )
    }
}

export default withStyles(Styles)(Dashboard);
