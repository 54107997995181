import { 
    CreateApiAction as _createApiAction,
    CreateKeyAction as _createKeyAction,
} from '../../../utils';

const key = 'LOCKED_GRAPH';

const CreateApiAction = _createApiAction(key);

export const requestUnlock = CreateApiAction('REQUEST_UNLOCK');

const CreateKeyAction = _createKeyAction(key);
export const clearRequestUnlockMsg = CreateKeyAction('CLEAR_REQUEST_INFO_MSG');

