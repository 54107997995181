
const key_map = {
    dataroom_access: 'Dataroom / financial info',
    financial_info: '',
    min_investment_size_m: '',
    prelim_investment_size_m: 'Preliminary interest amount',
    fee_structure: 'Fee structure',
    timeline: 'Timeline',
    share_class: 'Share class'
}

export {
    key_map,
}