import React from 'react';
import {
    Dialog,
    DialogContent,
    DialogActions,
    Button,
    makeStyles,

    Table,
    TableBody,
    TableContainer,
    TableHead,
    Paper,
} from '@material-ui/core';

import {
    StyledTableRow,
    TableLabelStyle,
    TighterHeaderTableCell,
    TighterTableCell1,
} from '../../../orderbook/common/Table';

const useStyles = makeStyles((theme) => ({
    dialogPaper: {
        minHeight: '50vh',
        minWidth: '50vw',
    },
}));

export default (props) => {
    const { open, investors } = props;
    const classes = useStyles();
    const tableClasses = TableLabelStyle();

    return (
        <Dialog
            open={open}
            classes={{ paper: classes.dialogPaper }}
            >
            <DialogContent>
                <TableContainer component={Paper} className={tableClasses.container}>
                    <colgroup>
                        <col style={{ width: '12%' }} />
                        <col style={{ width: '12%' }} />
                        {/* <col style={{ width: '12%' }} /> */}
                        <col style={{ width: '12%' }} />
                        <col style={{ width: '12%' }} />
                        <col style={{ width: '15%' }} />
                        <col style={{ width: '25%' }} />
                    </colgroup>
                    <TableHead>
                        <StyledTableRow>
                            <TighterHeaderTableCell className='investor-name'>Investor Name</TighterHeaderTableCell>
                            <TighterHeaderTableCell className='investor-name'>Investor Type</TighterHeaderTableCell>
                            <TighterHeaderTableCell className='investor-name'>Ticket size (range)</TighterHeaderTableCell>
                            <TighterHeaderTableCell className='investor-name'>Strategic / Passive</TighterHeaderTableCell>
                            <TighterHeaderTableCell className='investor-name'>Approve</TighterHeaderTableCell>
                            <TighterHeaderTableCell className='comment'>Comment</TighterHeaderTableCell>
                        </StyledTableRow>
                    </TableHead>
                    <TableBody>
                        {
                            investors.map((row, index) => {
                                return (
                                    <React.Fragment key={index}>
                                        <StyledTableRow>
                                            <TighterTableCell1
                                                className="investor-name">{row.investor}</TighterTableCell1>
                                            <TighterTableCell1
                                                className="investor-type">{row.investor_type}</TighterTableCell1>
                                            <TighterTableCell1
                                                className="ticket-size">{row.ticket_size}</TighterTableCell1>
                                            <TighterTableCell1
                                                className="is-strategic">{row.isStrategic ? 'Strategic' : 'Passive'}</TighterTableCell1>
                                            <TighterTableCell1
                                                className="approval">{row.approval ? 'Yes' : 'No'}</TighterTableCell1>
                                            <TighterTableCell1
                                                className='comment comment-table-row-cell'>{row.comment && row.comment.length > 0 ? row.comment.join(';') : ''}</TighterTableCell1>
                                        </StyledTableRow>
                                    </React.Fragment>
                                )
                            })
                        }
                    </TableBody>
                </TableContainer>
            </DialogContent>
            <DialogActions>
                <Button onClick={(evt) => props.handleConfirmSubmitClose(evt)}>
                    Cancel
                </Button>
                <Button onClick={(evt) => props.handleConfirmSubmission(evt)}>
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    )
}