import { fromJS, List, Map } from 'immutable';

const initialState = {
    showSnackbarInPassword:false,
    passwordError: false,
    passwordResponse: '',

}

const initialImmutableState = fromJS(initialState)
export default initialImmutableState;