export default {
  pipelineResponse: [],
  // headquarterCountryResponse:[],
  // verticalsResponse:[],
  dealResponse: {
    resultCount: 0,
    resultList: []
  },
  toggle_watchlist: {
    error: false,
    message: ''
  },
  deal_loading:false,
  no_more_deal_data:false,
  page: 0,
  showWatchList: false,
  dealTimeline: [],
  permissioned_deals: {}
};
