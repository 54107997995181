import React, { Component, Fragment } from "react";
import { Helmet } from 'react-helmet';
import { content_placeholders } from '../../../config';
import FrontPageHeader from '../FrontPageHeader';
// import { default as ConnectedInvestorLogin } from '../ConnectedInvestorLogin';
import { Link, withRouter } from 'react-router-dom';
import { GetToken } from '../../../utils';
import { Checkbox, Button, Grid, Paper, Box } from '@material-ui/core';

import * as Yup from 'yup';
import { passwordRegExp } from '../../common/Funcs';
import { sendLogEvent } from "../../../utils/ga";
import { ResetPassword } from "../../common";
import { generateWebContentPayload } from "../../../utils/util";


const passwordsMatch = (value, allValues) => {
	if (value !== allValues.get('password')) {
		return 'Passwords dont match';
	}
	return undefined;
};

// const passwordRegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^\&\*\(\)_\-=+\{\}|\[\]\\:;\?,\.\/\~])(?=.{12,})/
const validations = {
	new_password1: Yup.string().matches(passwordRegExp, '*password invalid')
		.min(12, '*min 12 character required')
		.required('')
		.trim(),

	new_password2: Yup.string('app.error-invalid-string')
		// .min(3, 'app.error-min-character')
		.required('')
		.trim()
		.oneOf([Yup.ref('new_password1'), null], '*Passwords not match')

};

class Disclaimer extends Component {

	constructor(props) {
		super(props);
	}

	state = {
		old_password: '',
		new_password1: '',
		new_password2: '',
		resource_data: { subject: '', content: [] },
		optional_resource_data: { subject: '', content: [] },
		disclaimer_data: { subject: '', content: [] },
		allow_submit: false,
	}

	componentDidMount() {
		const {
			loginUserData, 
		} = this.props;

		const { accessRight, activeAccessRight: { access_right: { permission } } } = loginUserData;
		// console.log( permission );
		if(accessRight.length > 1) {
			const { GetOBWealthAgreement } = this.props;
			GetOBWealthAgreement( generateWebContentPayload({loginUserData, public_url: false, identifier: 'ob-w-agreement'}) );
		} else if( permission == content_placeholders.user_type.orderbook_user ||
			permission == content_placeholders.user_type.orderbook_p1_user
			 ){
			const {
				GetOrderbookAgreement,
			} = this.props;

			GetOrderbookAgreement( generateWebContentPayload({loginUserData, public_url: false, identifier: 'orderbook-agreement'}) );
			sendLogEvent(false, 'ob_disclaimer_componentDidMount', {}, this.props);
		} else if (
			permission == content_placeholders.user_type.wealth_user
		){
			const { GetWealthAgreement } = this.props;

			GetWealthAgreement( generateWebContentPayload({loginUserData, public_url: false, identifier: 'wealth-agreement'}) );
			sendLogEvent(false, 'wp_disclaimer_componentDidMount', {}, this.props);
		} else {
			const { 
				GetPiAgreement, 
				GetConfidentialityAgreement 
			} = this.props;	

			if( permission == content_placeholders.user_type.trial_user ){
				GetPiAgreement({ get_trial: true });
			} else {
				GetPiAgreement({ });
			}

			GetConfidentialityAgreement({});
			sendLogEvent(false, 'cp_disclaimer_componentDidMount', {}, this.props);
		}
	}

	static getDerivedStateFromProps(nextProps, prevState){
		const {
			ca_content,
			pi_content,
			oba_content,
			wa_content,
			obwa_content,
			loginUserData,
			loginUserData : { 
				accessRight,
				activeAccessRight: { access_right: { permission } } } 
		} = nextProps;

		if( 
			accessRight.length > 1 
			// && 
			// obwa_content.subject != prevState.resource_data.subject 
		) {
			const next_state = {
				resource_data: obwa_content
			}
			if( !loginUserData.update_password_needed ) next_state['allow_submit'] = true;
			return next_state;
		} else if( (
				permission == content_placeholders.user_type.orderbook_user ||
				permission == content_placeholders.user_type.orderbook_p1_user 
				) && (
			oba_content.subject != prevState.resource_data.subject 
		) ){
			const next_state = {
				resource_data: oba_content,
			}
			if( !loginUserData.update_password_needed ) next_state['allow_submit'] = true;
			return next_state;
		} else if ( permission == content_placeholders.user_type.wealth_user ) {
			const next_state = {
				resource_data: wa_content
			}
			if( !loginUserData.update_password_needed ) next_state['allow_submit'] = true;
			return next_state;
		} else if( (
			permission == content_placeholders.user_type.new_version_user ||
			permission.indexOf(content_placeholders.user_type.single_company_user) > -1
			) && (
			pi_content.subject != prevState.resource_data.subject ||
			ca_content.subject != prevState.optional_resource_data.subject
		) ){
			const next_state = {
				resource_data: {
					subject: pi_content.subject,
					content: pi_content.content.filter(item => !item.update_password_needed) 
				},
				optional_resource_data: ca_content,
				disclaimer_data: {
					subject: '',
					content: pi_content.content.filter(item => item.update_password_needed)
				},
			}
			if( !loginUserData.update_password_needed ) next_state['allow_submit'] = true;
			return next_state;
		} else if( (
			permission == content_placeholders.user_type.trial_user
			) && (
			pi_content.subject != prevState.resource_data.subject
		) ){
			const next_state = {
				resource_data: {
					subject: pi_content.subject,
					content: pi_content.content.filter(item => !item.update_password_needed) 
				},
			}
			if( !loginUserData.update_password_needed ) next_state['allow_submit'] = true;
			return next_state;
		} else {
			return null;
		}
	}

	handleLogOut = (evt)=>{
		sessionStorage.removeItem("tabIndex");
		const accessToken = GetToken();
		this.props.logoutWithEmail({ email: accessToken.email, type: false });
	}

	shouldComponentUpdate(nextProps, nextState){
		const { agreementAndPasswordSet, loginUserData } = nextProps;
		if( agreementAndPasswordSet && agreementAndPasswordSet != this.props.agreementAndPasswordSet ){
			// console.log( loginUserData.accessRight.access_right.permission );
			const { accessRight, activeAccessRight: { access_right: { permission }}} = loginUserData;
			
			if(
				permission.indexOf( content_placeholders.user_type.single_company_user ) > -1
			) {
				let company_id = permission.split('_');
                company_id = company_id[company_id.length - 1];
                
                if( company_id ){
                    this.props.history.push('/club/company-detail/' + company_id);
                } else {
					this.props.history.push('/app/Pipeline');
				}
			} else if (accessRight.length > 1) {
				this.props.history.push('/dashboard');
			} else if (
				permission == content_placeholders.user_type.orderbook_user ||
				permission == content_placeholders.user_type.orderbook_p1_user ){
					this.props.history.push('/orderbook/pipeline');
			} else if (
				permission == content_placeholders.user_type.wealth_user
			) {
				this.props.history.push('/wealth/pipeline');
			} else if(
				permission == content_placeholders.user_type.new_version_user ){
					this.props.history.push('/club/pipeline');
			} else {
				this.props.history.push('/app/Pipeline');
			}

		}
		return true;
	}

	handleToggle = (key, index) => {
		const items = this.state[key];
		items.content[index].selected = !items.content[index].selected ? true : false;
		this.setState({[key]: items});
	}

	handleChange = (e, errors, touched) => {
		this.handleTextFieldChangeAndBlur(e, errors, touched, 'change');
	}

	handleBlur = (e, errors, touched) => {
		this.handleTextFieldChangeAndBlur(e, errors, touched, 'blur');
	}

	handleKeyUp = (e, errors, touched) => {
		this.handleTextFieldChangeAndBlur(e, errors, touched, 'keyup');
	}

	handleTextFieldChangeAndBlur = (evt, errors, touched, type) => {
		const { target : { name, value } } = evt;
		console.log( name );
		console.log( value );
		const new_state = { [name]: value };
		if( ( touched.new_password1 || touched.new_password2 ) && !errors.new_password1 && !errors.new_password2 ){
			new_state['allow_submit'] = true;
		}
		console.log( new_state );
		this.setState(new_state);
	}

	// passwordMatch = (e, errors) => {
	// 	console.log('Text field blur')
	// 	console.log( errors );
	// 	const { new_password1, new_password2 } = this.state;
	// 	if (new_password2 !== '') {
	// 		if (new_password1 !== new_password2) {
	// 			this.setState({ confirmPassError: 'Password not match' })
	// 		}
	// 	}
	// }
	
	// handlePassChange = (e) => {

	// 	const passwordInput = e.target.value
	// 	const { new_password1 } = this.state
	// 	if (new_password1 === passwordInput) {
	// 		this.setState({ confirmPassError: 'Password Match' })
	// 	} else {
	// 		this.setState({ confirmPassError: '' })
	// 	}

	// }

	setAgreement = (data) => {
		const { SetAgreementFalse } = this.props

		if( this.checkCheckboxSelected() ){
			SetAgreementFalse({
				user_id: data.id,
				id: data.id
			});
		}
	}

	setAgreementandPassword = (e, data) => {
		e.preventDefault();
		const {
			confirmPasswordChange,
			SetAgreementAndPassword,
		} = this.props;

		if( this.checkCheckboxSelected() ){
			SetAgreementAndPassword({
				user_id: data.id,
				id: data.id,
			})
	
			const { new_password1, new_password2 } = this.state;
			confirmPasswordChange({ new_password1, new_password2 });
			const initial_payload = { user_id: data.id, password1: new_password1, password2: new_password2 };
			sendLogEvent(true, 'disclaimer_setAgreementandPassword', initial_payload, this.props);
		}
	}

	onSubmitPassword = (e, data) => {
		e.preventDefault();
		const { new_password1, new_password2 } = this.state;
		const { confirmPasswordChange,SetUpdatePasswordFalse } = this.props;
		confirmPasswordChange({ new_password1, new_password2 });
		SetUpdatePasswordFalse({
			user_id: data.id
		});
		const initial_payload = { user_id: data.id, password1: new_password1, password2: new_password2 };
		sendLogEvent(true, 'disclaimer_onSubmitPassword', initial_payload, this.props);
		// this.props.history.push('/app/Pipeline');
	}


	checkCheckboxSelected = (e) => {
		const {
			resource_data,
			optional_resource_data,
			disclaimer_data,
		} = this.state;

		let all_checkbox_selected = true;
		const checker_items = [resource_data.content, optional_resource_data.content, disclaimer_data.content];

		for( let iii=0; iii<checker_items.length; iii++ ){
			for( let ii=0; ii<checker_items[iii].length; ii++ ){
				if( checker_items[iii][ii].checkbox && !checker_items[iii][ii].selected ) all_checkbox_selected = false;
			}
		}

		return all_checkbox_selected;
	}

	render() {
		const title = 'Disclaimer | ' + content_placeholders.title_prefix;
		// const content_printed = this.props.content;
		const { loginUserData } = this.props;
		
		const { 
			resource_data, 
			optional_resource_data, 
			disclaimer_data,
			new_password1, 
			new_password2,
			allow_submit,
		} = this.state; 
		
		return (
			<div>
				<Helmet>
					<title>{title}</title>
					<meta name="description" content={"Gateway Private Markets"} />
				</Helmet>
				<div className="main-page disclaimer">
					<div className="bg-color2">
						<FrontPageHeader />

						<section className="container-fluid pt-4">
							<Paper>
								<Box m={3}>
									<Grid container spacing={2}>
										{
											loginUserData.update_password_needed ? 
												<Grid item xs={12}>
													<div className="align-center">
														<h2>Password Reset</h2>
													</div>
													
													<ResetPassword
														// handleChange={this.handleChange}
														handleCancel={this.handleLogOut}
														// customDisableBtnCheck={this.checkCheckboxSelected}
														// handleConfirmAndAccept={this.setAgreementandPassword}
														// handleConfirm={this.onSubmitPassword}
														handlePwFieldChange={this.handleChange}
														handlePwFieldBlur={this.handleBlur}
														handlePwFieldKeyUp={this.handleKeyUp}
														{...{
															new_password1,
															new_password2,
															loginUserData,
														}}
														/>
													
												</Grid>
												: ''
										}


										{
											loginUserData.agreement_needed ? (
												<Grid item xs={12}>
													<div className="align-center">
														<h2>{resource_data.subject}</h2>
													</div>
													{resource_data.content.map((value, index) => {
														if (value.p) {
															return <div style={{ marginBottom: '20px' }} key={index} dangerouslySetInnerHTML={{__html: value.p}} />;
														} else if (value.buttons && !loginUserData.update_password_needed){
															return (
																<Grid item style={{ display: 'flex', justifyContent: 'center' }} xs={12}>
																	{
																		value.buttons.map((btn_item, btn_index) => {
																			switch( btn_item.type ){
																				case 'accept':
																					return (
																						<Button 
																							disabled={!this.checkCheckboxSelected()} 
																							onClick={() => (this.setAgreement(loginUserData))}
																							className="btn-disc" 
																							key={btn_index}>
																								{btn_item.button}
																						</Button>
																						)
																				case 'cancel':
																					return (
																						<Button 
																						onClick={() => this.handleLogOut()}
																							className="btn-disc" 
																							key={btn_index}>
																								{btn_item.button}
																						</Button>
																						)
																				default:
																					return <Fragment></Fragment>
																			}
																		})
																	}
																</Grid>
															)
														} else if (value.button && !loginUserData.update_password_needed) {
															return <Button key={index} disabled={!this.checkCheckboxSelected()} className="btn-accept" onClick={() => (this.setAgreement(loginUserData))}>{value.button}</Button>
														} else if (value.checkbox) {
															return (
																<Grid container spacing={2} key={index}>
																	<Grid style={{ display: 'flex' }} item xs={12}>
																		<Checkbox type="checkbox"
																			onChange={() => this.handleToggle('resource_data', index)}
																			checked={value.selected ? true : false}
																		></Checkbox>
																		<div style={{padding: '9px 3px'}} dangerouslySetInnerHTML={{__html: value.checkbox}}  />
																	</Grid>
																</Grid>
															)
														} 
													})}
												</Grid>
												)
												: ''
										}

										{
											optional_resource_data.content.length ? ( 
												<Grid item xs={12}>
													<div id="confidentiality-agreement">
														<div className="align-center">
															<h2>{optional_resource_data.subject}</h2>
														</div>
														{
															optional_resource_data.content.map((value, index) => {
																if( value.h2 ){
																	return (
																		<h2 key={index}>{value.h2}</h2>
																	)
																} else if( value.p ){
																	return <div style={{ marginBottom: '20px' }} key={index} dangerouslySetInnerHTML={{ __html: value.p }} />;
																} else if ( value.ul ){
																	return <ul key={index}>{
																		value.ul.map((ul_val, ul_ind) => {
																			if( ul_val.li ){
																				return <li key={ul_ind}>{ul_val.li}</li>
																			} else if( ul_val.ul ){
																				return <ul key={ul_ind}>{
																					ul_val.ul.map((uul_val, uul_ind) => {
																						return <li key={uul_ind}>{uul_val.li}</li>;
																					})
																					}</ul>
																			}
																		})
																		}</ul>
																}
															})
														}
													</div>
												</Grid> 
												) 
												: ''
										}

										{disclaimer_data.content.length ?
											<Grid item xs={12}>
												<div>
													
													{
														disclaimer_data.content.map((value, index) => {
															if( value.h2 ){
																return (
																	<h2 key={index}>{value.h2}</h2>
																)
															} else if( value.p ){
																return <div style={{ marginBottom: '20px' }} key={index} dangerouslySetInnerHTML={{__html: value.p}} />;
															} else if ( value.ul ){
																return <ul key={index}>{
																	value.ul.map((ul_val, ul_ind) => {
																		if( ul_val.li ){
																			return <li key={ul_ind}>{ul_val.li}</li>
																		} else if( ul_val.ul ){
																			return <ul key={ul_ind}>{
																				ul_val.ul.map((uul_val, uul_ind) => {
																					return <li key={uul_ind}>{uul_val.li}</li>;
																				})
																				}</ul>
																		}
																	})
																	}</ul>
															}
														})
													}

												</div>

											</Grid> : ''}

											<Grid item style={{ display: 'flex', justifyContent: 'center' }} xs={12}>
												<Button onClick={() => this.handleLogOut()}>
													Cancel
												</Button>
												{loginUserData.update_password_needed && loginUserData.agreement_needed ?
													<Button
														className="btn-disc"
														disabled={!this.checkCheckboxSelected() || !allow_submit}
														onClick={(e) => (this.setAgreementandPassword(e, loginUserData))}
													>Confirm and I Accept*</Button> : ''}
												{loginUserData.update_password_needed && !loginUserData.agreement_needed ?
													<Button
														className="btn-disc"
														disabled={!this.checkCheckboxSelected() || !allow_submit}
														onClick={(e) => { this.onSubmitPassword(e, loginUserData); }}
													>Confirm</Button> : ''}
												{!loginUserData.update_password_needed && loginUserData.agreement_needed ?
													<Button
														className="btn-disc"
														disabled={!this.checkCheckboxSelected() || !allow_submit}
														onClick={(e) => { this.setAgreement(loginUserData); }}
													>I Accept*</Button>
													: ''}
											</Grid>
											
									</Grid>
								</Box>
							</Paper>
							
						</section>
					</div>
				</div>
			</div >
		);
	}
}

export default withRouter(Disclaimer);
