import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { content_placeholders } from '../../../config';
import FrontPageHeader from '../../login/FrontPageHeader';
import ConnectedChangePassword from './ConnectedChangePassword';
import { withRouter } from 'react-router-dom';
import PortalBanner from '../../../assets/images/PortalBanner.jpg';

import {
	Grid,
	Typography,
} from '@material-ui/core';

import {
	Footer,
} from '../../common';

class UpdateResetPassword extends Component{
    
    constructor(props){
        super(props);
    }

    render(){
        const title = content_placeholders.title_prefix + ' - Set New Password';
        // console.log(this.props);
        const { match: { params: { uid, token } } } = this.props;
        return (
            <div>
                <Helmet>
					<title>{title}</title>
					<meta name="description" content={"Gateway Private Markets"} />
				</Helmet>
				<div className="main-page login">
					<div className="bg-color2">
						<FrontPageHeader />

                        <Grid container spacing={2}>
							<Grid item xs={12} md={6}>
								<Grid container spacing={2}>
									<Grid item xs={0} md={1} style={{height: 1}}></Grid>
									<Grid item xs={12} md={11}>
									<div style={{paddingTop: 100}} className="tagline">
										<Typography variant="h6">
											The Venture Marketplace For Asia
										</Typography>
										<p>Fundraising | Investing | Liquidity</p>
                                        <p>Please confirm the changed password.</p>
									</div>
									<ConnectedChangePassword uid={uid} token={token} />

									</Grid>
								</Grid>
							</Grid>
							<Grid item xs={12} md={6}>
								<div className="style-container">
									<div className="inner-style-container">
										<img src={PortalBanner} />
									</div>
									
									<div className="style-bar-container">
										<div className="third style-bar"></div>	
										<div className="primary style-bar"></div>
										<div className="secondary style-bar"></div>
										
									</div>
									
								</div>
								
							</Grid>
						</Grid>

						{/* <section className="container-fluid pt-4">
							<div className=" bg-color-box ">

                                <div className="row justify-content-between h-100">
                                    <div className="col-lg-5 align-self-center">
                                        <h1>Please Confirm the Changed Password.</h1>
                                    </div>
                                    <ConnectedChangePassword uid={uid} token={token} />
                                </div>
							</div>
						</section> */}
					</div>
				</div>

				<Footer />
            </div>
        )
    }

}

export default withRouter(UpdateResetPassword);