import React from 'react';

import {
    makeStyles,
    Typography,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    container: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: '#FFF',
        zIndex: 10000,
        display: 'flex',
        justifyContent: 'center'
    },
    container_hide: {
        display: 'none',
    }
}));

export default (props) => {
    const { UserTypeError }  = props;
    const classes = useStyles();
    
    const container_class = UserTypeError.open ? classes.container : classes.container_hide;
    return (
        <div className={container_class}>
            <Typography variant="h6">{UserTypeError.message}</Typography>
        </div>
    )
}