import {
    all,
    fork
} from 'redux-saga/effects';

import { saga as loginSaga } from '../modules/login';
import { saga as resetPasswordSaga } from '../modules/resetPassword';
import { saga as pipelineSaga } from '../modules/pipeline';
import { saga as companySaga } from '../modules/company';
import { saga as headerSaga } from './../modules/common/header';
import { saga as accountSaga } from './../modules/account';
import { saga as root } from './../modules/root';

import { saga as clubPipelineSaga } from './../modules/club_client/pipeline';
import { saga as clubCompanySaga } from './../modules/club_client/company';
import { saga as orderbookPipelineSaga } from './../modules/orderbook/pipeline';
import { saga as orderbookCompanySaga } from './../modules/orderbook/company';
import { saga as orderbookPlaceOrderSaga } from './../modules/orderbook/common/place_order';
import { saga as orderbookCompanySearchSaga } from './../modules/orderbook/common/company_search';
import { saga as portfolioHoldingSaga } from './../modules/orderbook/portfolio_holding';
import { saga as orderbookWatchlistSaga } from './../modules/orderbook/watchlist';
import { saga as wealthPipelineSaga } from './../modules/wealth/pipeline';
import { saga as wealthCompanySaga } from './../modules/wealth/company';
import { saga as wealthTrendsSaga } from './../modules/wealth/trends';
import { saga as watchlistSaga } from './../modules/common/watchlist';
import { saga as wealthCompanySearchSaga } from './../modules/wealth/common/company_search';
import { saga as wealthPlaceInterestSaga } from './../modules/wealth/common/place_interest';
import { saga as dashboardSaga } from './../modules/dashboard';
import { saga as boostDashboardSaga } from './../modules/boost/dashboard';
import { saga as boostVettingSaga } from './../modules/boost/vetting';
import { saga as boostPipelineSaga } from './../modules/boost/pipeline';
import { saga as submitPortfolioSaga } from './../modules/common/client_portfolio/submit_portfolio';
import { saga as requestUnlockSaga } from './../modules/common/LockedGraph';

export default function* rootSaga(){
    yield all([
        fork(loginSaga),
        fork(resetPasswordSaga),
        fork(pipelineSaga),
        fork(companySaga),
        fork(headerSaga),
        fork(accountSaga),
        fork(root),

        fork(clubPipelineSaga),
        fork(clubCompanySaga),
        fork(orderbookPipelineSaga),
        fork(orderbookCompanySaga),
        fork(orderbookPlaceOrderSaga),
        fork(orderbookCompanySearchSaga),
        fork(portfolioHoldingSaga),
        fork(orderbookWatchlistSaga),
        fork(wealthPipelineSaga),
        fork(wealthCompanySaga),
        fork(wealthTrendsSaga),
        fork(watchlistSaga),
        fork(wealthCompanySearchSaga),
        fork(wealthPlaceInterestSaga),
        fork(dashboardSaga),
        fork(boostDashboardSaga),
        fork(boostVettingSaga),
        fork(boostPipelineSaga),
        fork(submitPortfolioSaga),
        fork(requestUnlockSaga),
    ])
}