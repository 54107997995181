import { Grid } from '@material-ui/core';
import React from 'react';

import { default as DemandOrderbook } from './DemandOrderbook';
import { default as OfferOrderbook } from './OfferOrderbook';

import { 
    Typography, 
    Box,
    makeStyles,
    Button,
} from '@material-ui/core';

import { TableLabelStyle } from '../../common/Table';
import { AgeOfOrders } from '../../../orderbook/common/place_order/components';

import {
    getLatestFundingRound,
} from '../../../../utils/util';

const useStyles = makeStyles((theme) => ({
    Footnote: {
        fontSize: 12,
    },
    Button: {
        position: 'fixed',
        bottom: 10,
        right: 10,
        zIndex: 1000,
        background: theme.palette.primary.dark,
        color: theme.palette.common.white,
        '&:hover': {
            color: theme.palette.common.black,
            backgroundColor: 'rgba(213,226,253,1)',
        }
    }
}));

export default (props) => {
    const classes = useStyles();
    const table_classes = TableLabelStyle();

    const valid_ave_bids = props.historical_pricing.filter(item => item.average_bid);
    const latest_ave_bid = valid_ave_bids.length ? {price: valid_ave_bids[valid_ave_bids.length - 1]['average_bid']} : { price: 0 }

    const valid_ave_offers = props.historical_pricing.filter(item => item.average_offer);
    const latest_ave_offer = valid_ave_offers.length ? {price: valid_ave_offers[valid_ave_offers.length - 1]['average_offer']}: { price: 0 }

    const lfr_response = getLatestFundingRound(props.funding_rounds);

    return (
        <Grid container spacing={2} style={{marginTop: '-16px'}}>
            <Grid item xs>
                <Box>
                    <Typography variant="h6">Live Bid</Typography>
                </Box>
                
                <DemandOrderbook 
                    // handlePrefillPlaceOrder={props.handlePrefillPlaceOrder}
                    handlePromptExpress={props.handlePromptExpress}
                    handleShowOrderDetailDialog={props.handleShowOrderDetailDialog}
                    {...{
                        ...props.live_demand, 
                        appConfig: props.appConfig,
                        loginUserData: props.loginUserData,
                        latest_funding_round: lfr_response,
                        latest_ave_bid: latest_ave_bid,
                        longest_digit: props.longest_digit,
                        watchlist: props.live_demand_watchlist,
                    }}
                    />
            </Grid>
            <Grid item xs>
                <Box>
                    <Typography variant="h6">Live Offer</Typography>
                </Box>
                
                <OfferOrderbook 
                    // handlePrefillPlaceOrder={props.handlePrefillPlaceOrder} 
                    handlePromptExpress={props.handlePromptExpress}
                    handleShowOrderDetailDialog={props.handleShowOrderDetailDialog}
                    {...{
                        ...props.live_offers, 
                        appConfig: props.appConfig,
                        loginUserData: props.loginUserData,
                        latest_funding_round: lfr_response,
                        latest_ave_offer: latest_ave_offer,
                        longest_digit: props.longest_digit,
                        watchlist: props.live_offers_watchlist,
                    }}
                    />
            </Grid>
            <Grid item xs={12}>
                <Box>
                    <Typography className={classes.Footnote} variant="body1">
                        <sup>(1)</sup> R = ROFR Waived; C = Company Sponsored; I = Information Available; P = Primary Fundraising; S = SPV structure; G = Gateway SPV
                        <sup>(2)</sup> IR = Information Required
                    </Typography>
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="body1" className={table_classes.FirmOrder}><span>Firm Order</span></Typography>
                {/* <Typography variant="body1" className={table_classes.ThirtyDay}><span>Within 30 days</span></Typography>
                <Typography variant="body1" className={table_classes.SixtyDay}><span>30 - 60 days</span></Typography> */}
                <AgeOfOrders />
            </Grid>
            {/* <Button
                className={classes.Button}
                onClick={props.handleShowExpressInterest}
                onMouseEnter={(evt) => props.handlePromptExpress(evt, true)}
                onMouseLeave={(evt) => props.handlePromptExpress(evt, false)}
                >
                Express Interest
            </Button> */}
        </Grid>
    )
}