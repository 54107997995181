import { combineReducers } from 'redux';
import { resetPasswordWithEmail } from './Action';
import { createReducer } from 'redux-act';
import { default as InitialState } from './InitialState';
import { reducer as updatePassword } from './updatePassword'

export default combineReducers({
    updatePassword,
    reducer(state=InitialState, action){
        switch( action.type ){
            case resetPasswordWithEmail.success.toString():
                return Object.assign({}, state, {
                    validEmail:true,
                    emailToSend:action.payload,
                    showSnackbarInReset:!(state.showSnackbarInReset),
                    emailError:false,
                    emailResponse: 'If a Gateway account exists corresponding to the entered email id, a password reset link has been sent to it!'
                });
            case resetPasswordWithEmail.failure.toString():
                return Object.assign({}, state, {
                    showSnackbarInReset:!(state.showSnackbarInReset),
                    emailError:true,
                    emailResponse: 'Please enter a valid email address.'
                });
            default:
                return state;
        }
    }
})
