import React from 'react';

import {
    Table,
    TableBody,
    TableContainer,
    TableHead,
    Typography,
    Paper,
    TextField,
    makeStyles,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import {
    StyledTableRow,
    TableLabelStyle,
    TighterHeaderTableCell,
    TighterTableCell,
} from '../../../orderbook/common/Table';
const useStyles = makeStyles({
    input: {
        "& input": {
            fontSize: '12px',
            lineHeight: '14px'
        },
        "& input::placeholder": {
            fontSize: '12px !important',
            lineHeight: '14px'
        },
        marginBottom: '15px',
    },
    ul: {
        padding: 0
    },
});
export default (props) => {
    const classes = useStyles();
    const tableClasses = TableLabelStyle();
    const tempList = [
        { 'profiled_investors': 'ABC Comp', 'fundrising_stage': 'Committed', 'committed': 'US$1.5M', 'feedback': '' },
        { 'profiled_investors': 'BBC Limit', 'fundrising_stage': 'Committed', 'committed': 'US$2M', 'feedback': '' },
        { 'profiled_investors': 'John Doe', 'fundrising_stage': 'Committed', 'committed': 'US$3M', 'feedback': '' },
        { 'profiled_investors': 'T. Lam', 'fundrising_stage': 'Pass', 'committed': 'N/A', 'feedback': 'Too early stage' },
    ]
    return (
        <React.Fragment>
            <Typography variant="h6">
                Track your vetted investors
                <span className="company-dashboard-link">There is a new list of prospect investors for your review.
                    <Link to={{ pathname: `/boost/vetting/${props.company_id}`, }} >
                        Review now
                    </Link>
                </span>
            </Typography>
            <TextField
                classes={{ root: classes.input }}
                margin="dense"
                id="investor_name"
                name="investor_name"
                placeholder="Investor Name"
                fullWidth
                multiline={false}
                variant="outlined"
                onKeyUp={(evt) => props.handleSearchInvestors(evt)}
            />
            <TableContainer component={Paper} className={tableClasses.container}>
                <Table stickyHeader size="small" aria-label="simple table">
                    <TableHead>
                        <StyledTableRow>
                            <TighterHeaderTableCell className="profiled-investors">Profiled Investors</TighterHeaderTableCell>
                            <TighterHeaderTableCell className="fundrising-stage">Fundraising Stage</TighterHeaderTableCell>
                            <TighterHeaderTableCell className="commited">Committed $</TighterHeaderTableCell>
                            <TighterHeaderTableCell className="feedback">Comment / Feedback</TighterHeaderTableCell>
                        </StyledTableRow>
                    </TableHead>
                    <TableBody>
                        {props.vettedInvestors && props.vettedInvestors.length > 0 &&
                            props.vettedInvestors.map((row, index) => {
                                return (
                                    <React.Fragment key={index}>
                                        <StyledTableRow>
                                            <TighterTableCell
                                                className='profiled-investors'>{row.profiled_investor ? row.profiled_investor : '-'}</TighterTableCell>
                                            <TighterTableCell
                                                className="fundrising-stage">{row.stage ? row.stage : '-'}</TighterTableCell>
                                            <TighterTableCell
                                                className="commited">
                                                {row.committed_amount
                                                    ? "US$" +
                                                    row.committed_amount.toFixed(2) +
                                                    "M"
                                                    : "N/A"}
                                            </TighterTableCell>
                                            <TighterTableCell
                                                className="feedback">{row.feedback && Array.isArray(row.feedback) && row.feedback.length > 0 ?
                                                    <ul className={classes.ul} style={{ listStyleType: 'none', marginBottom: 0 }}>
                                                        {
                                                            row.feedback.map((c_item, c_index) => {
                                                                return (
                                                                    <li key={c_index}>{c_item}</li>
                                                                )
                                                            })
                                                        }
                                                    </ul> : '-'}</TighterTableCell>
                                        </StyledTableRow>
                                    </React.Fragment>
                                )
                            })
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </React.Fragment>
    )
}