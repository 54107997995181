import React, { Component, Fragment } from "react";
import { Link } from 'react-router-dom';
import { extractPortalType } from '../../utils/util';

class DisclaimerData extends React.Component {

	render() {
		const { token, pathname } = this.props;

		let portal_type_sq = '';
		if( pathname ){
			portal_type_sq = extractPortalType(pathname);
		}

		return (

			<Fragment>
				<div className="disclaimer">
					<p>
						The information contained herein is provided for general informational and educational purposes only and is not, and must not be treated as, investment advice, an investment recommendation, or investment research, nor is it an offer or solicitation for the purchase or sale of any financial instrument or service in any jurisdiction. No representation is made as to the continued availability of exposure to the companies shown here, the companies themselves or accompanying information about the companies is subject to change without notice. Any analysis and information presented herein is based on data retrieved from various sources, GPMHK makes no representation or warranty, express or implied, regarding the accuracy, completeness or adequacy of the information, nor has this information been independently reviewed or verified by GPMHK and has not been reviewed or in any way approved or endorsed by the company or companies it relates to or any of their employees, advisers or affiliates.
					</p>
					<p>
						Any information relating to, amongst other things, price, valuation, discounts/premiums, funding rounds where provided is sourced from varies sources including Pitchbook Data, Inc. and is not guaranteed in any way. GPMHK accepts no liability for any failure to meet such indications or for any errors or omissions contained herein.
					</p>
					<p>
						Investment involves risk. Past performance is not indicative of future performance. Please refer to the applicable offering document for further details including the risk factors. This material has not been reviewed by the Securities and Futures Commission.
					</p>
					<p>
						GPMHK is licensed by the Securities and Futures Commission of Hong Kong with CE number BOF598 for the Regulated Activities of Dealing in Securities and Asset Management.
					</p>
					<p>
						©2022 GPMHK, all rights reserved.
					</p>
					
				</div>
				<div className="policies-container">
					<Link to={token ? `/terms-of-use/${token}${portal_type_sq ? `?portal_type=${portal_type_sq}` : ''}` : "/terms-of-use"} target="_blank">Terms of Use</Link>
					<Link to={token ? `/privacy-policy/${token}${portal_type_sq ? `?portal_type=${portal_type_sq}` : ''}` : "/privacy-policy"} target="_blank">Privacy Policy</Link>
					<Link to={token ? `/confidentiality-agreement/${token}${portal_type_sq ? `?portal_type=${portal_type_sq}` : ''}` : "/confidentiality-agreement"} target="_blank">Confidentiality Agreement</Link>
					<Link to={token ? `/risk-disclosure/${token}${portal_type_sq ? `?portal_type=${portal_type_sq}` : ''}` : "/risk-disclosure"} target="_blank">Risk Disclosure</Link>
				</div>
			</Fragment>
            
		);
	}
}

export default DisclaimerData;