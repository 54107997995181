import { connect } from 'react-redux';
import { default as Pipeline } from './Pipeline';

import {
    getVerticalsList,
    getGeographyList,
    getOrderbookView,
    getMoreOrderbook,
    getOpportunityHighlight,
    getCompanyNameById,
    clearOrderbookCompany,
    internalSort,
    getLiveOfferCount,
    getLiveBidCount,
    toggleWatchList,
    clearWatchlistMessage,
    getPermissionedDeals,
    updateUserType,
    showHideCampaignPopUp,
    getDealMetrics,
} from './Action';

import {
    CollapseSidebar,
} from '../../root/Action';

import {
    ValidateUserInBackground
} from '../../login/Action';

import {
    getWatchlistGroupItem,
} from '../../common/watchlist/Action';

export default connect(
    state => ({
        watchlistGroupList: state.watchlist.reducer.watchlistGroupList,
		watchlistGroupItem: state.watchlist.reducer.watchlistGroupItem,
		verticalsListResponse: state.orderbookPipeline.reducer.verticalsListResponse,
        geographyResponse: state.orderbookPipeline.reducer.geographyResponse,
        orderbook: state.orderbookPipeline.reducer.orderbook,
        showCampaignDialog: state.orderbookPipeline.reducer.showCampaignDialog,
        orderbook_loading: state.orderbookPipeline.reducer.orderbook_loading,
        opportunity_highlight: state.orderbookPipeline.reducer.opportunity_highlight,
        live_offer_count: state.orderbookPipeline.reducer.live_offer_count,
        live_bid_count: state.orderbookPipeline.reducer.live_bid_count,
        company: state.orderbookPipeline.reducer.company,
        toggle_watchlist: state.orderbookPipeline.reducer.toggle_watchlist,
        permissioned_deals: state.orderbookPipeline.reducer.permissioned_deals,
        collapseSidebar: state.root.reducer.collapseSidebar,
		loginUserData: state.login.reducer.loginUserData,
        sort_type: state.login.reducer.sort_type,
        sort_order: state.login.reducer.sort_order,
        appConfig: state.root.reducer.appConfig,
        watchlistGroupItem: state.watchlist.reducer.watchlistGroupItem,
        historical_pricing: state.watchlist.reducer.historical_pricing.historical_pricing,
        funding_rounds: state.watchlist.reducer.funding_rounds,
        dealMetricsResponse: state.orderbookPipeline.reducer.dealMetricsResponse,
        watchlistGroupItem_loading: state.watchlist.reducer.watchlistGroupItem_loading,
    }),
    dispatch => ({
        getVerticalsList: (payload) => dispatch(getVerticalsList.request(payload)),
        getGeographyList: (payload) => dispatch(getGeographyList.request(payload)),
        getOrderbookView: (payload) => dispatch(getOrderbookView.request(payload)),
        getMoreOrderbook: (payload) => dispatch(getMoreOrderbook.request(payload)),
        getOpportunityHighlight: (payload) => dispatch(getOpportunityHighlight.request(payload)),
        getCompanyNameById: payload => dispatch(getCompanyNameById.request(payload)),
        getLiveOfferCount: () => dispatch(getLiveOfferCount.request()),
        getLiveBidCount: () => dispatch(getLiveBidCount.request()),
        toggleWatchList: (payload) => dispatch(toggleWatchList.request(payload)),
        getPermissionedDeals: (payload) => dispatch(getPermissionedDeals.request(payload)),
        CollapseSidebar: (payload) => dispatch(CollapseSidebar(payload)),
        clearOrderbookCompany: () => dispatch(clearOrderbookCompany()),
        internalSort: () => dispatch(internalSort()),
        clearWatchlistMessage: () => dispatch(clearWatchlistMessage()),
        ValidateUserInBackground: (payload) => dispatch(ValidateUserInBackground.request(payload)),
        getWatchlistGroupItem: (payload) => dispatch(getWatchlistGroupItem.request(payload)),
        updateUserType: (payload) => dispatch(updateUserType.request(payload)),
        showHideCampaignPopUp: (payload) => dispatch(showHideCampaignPopUp(payload)),
        getDealMetrics: (payload) => dispatch(getDealMetrics.request(payload)),
    })
)(Pipeline);