import { combineReducers } from "redux";
import { confirmPasswordChange, resetSnackBar } from "./Action";
import {
  createUserMessage,
  getNotifications,
  markNotificationAllRead,
  markNotificationAllUnRead,
} from "../common/header/Action";
import { createReducer } from "redux-act";
import { default as InitialState } from "./InitialState";
import { content_placeholders } from "../../config";

export default combineReducers({
  reducer(state = InitialState, action) {
    switch (action.type) {
      case confirmPasswordChange.success.toString():
        return Object.assign({}, state, {
          showSnakbar: !state.showSnakbar,
          passwordError: false,
          responseMsg: "Password Successfully Changed",
        });
      case confirmPasswordChange.failure.toString():
        return Object.assign({}, state, {
          showSnakbar: !state.showSnakbar,
          passwordError: true,
          responseMsg:
            action.payload &&
            action.payload.response &&
            action.payload.response.data.new_password2[0],
        });
      case createUserMessage.success.toString():
        return Object.assign({}, state, {
          showSnakbar: !state.showSnakbar,
          passwordError: false,
          responseMsg: action.payload.responseMessage,
        });
      case createUserMessage.failure.toString():
        return Object.assign({}, state, {
          showSnakbar: !state.showSnakbar,
          passwordError: true,
          responseMsg: content_placeholders.error_message.general,
        });
      case getNotifications.success.toString():
        return Object.assign({}, state, {
            notificationsResponse: action.payload,
        });
      case getNotifications.failure.toString():
        return state;
      case markNotificationAllRead.success.toString():
        return Object.assign({}, state, {
          markNotificationAllReadResponse: action.payload,
        });
      case markNotificationAllRead.failure.toString():
        return state;
      case markNotificationAllUnRead.success.toString():
        return Object.assign({}, state, {
          markNotificationAllUnReadResponse: action.payload,
        });
      case markNotificationAllUnRead.failure.toString():
        return state;
      case resetSnackBar.toString():
        return Object.assign({}, state, {
          showSnakbar: false,
          passwordError: false,
          responseMsg: "",
        });
      default:
        return state;
    }
  },
});
