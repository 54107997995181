import GA4React from "ga-4-react";
import { logs } from '../api';
import { default as NewRelicError } from './NewRelicError';
import { default as RetrieveDebug } from './RetrieveDebug';
import { default as RetrieveEmailCampaign } from './RetrieveEmailCampaign';

let ga4react;

export async function GA4Init(G) {
    if (!GA4React.isInitialized() && G && process.browser) {
        ga4react = new GA4React(G, { debug_mode: !process.env.production });
        try {
            await ga4react.initialize();
        } catch (error) {
            NewRelicError(error);
        }
    }
}

function logEventInternally(parms){
    let { type, data } = parms;
    data['type'] = type;
    const campaign_payload = RetrieveEmailCampaign();
    if( ( Object.keys(campaign_payload) ).length ){
        data = {...data, ...campaign_payload};
    }
    try{
        logs.logUrl(data, (response) => {});
    } catch( error ){
        NewRelicError(error);
    }
}

export function logEventInternallyAndNewRelic(params){
    let { type, data, err } = params;
    data['type'] = type;
    try{
        logs.logUrl(data, (response) => {});
        NewRelicError(err);
    } catch( error ){
        NewRelicError(error);
    }
}

export function sendGAEvent(parms) {
    let { type, data } = parms;
    const campaign_payload = RetrieveEmailCampaign();
    if( ( Object.keys(campaign_payload) ).length ){
        data = {...data, ...campaign_payload};
    }
    // console.log(type, "GA_type");
    // console.log(data, "GA_data");
    if (GA4React.isInitialized()) {
        ga4react.gtag("event", type, data);
    }

    logEventInternally(parms);
}

export function sendLogEvent(debug, type, log_payload, custom_obj){

    try{
        const debug_obj = RetrieveDebug();
        const { location: { pathname, search } } = custom_obj;
        if( 
            !debug || 
            ( search && search.indexOf('debug=') > -1 ) || 
            ( debug_obj && debug_obj.debug ) 
        ){
            const userAgent = window.navigator.userAgent;
            // log_payload['type'] = type;

            log_payload['pathname'] = pathname;
            log_payload['search'] = search;
            log_payload['userAgent'] = userAgent;
            // console.log( log_payload );
            sendGAEvent({
                type: type,
                data: log_payload
            });
        }
    } catch(error){
        console.log( error );
        NewRelicError(error);
    }
}

export function setGAUserId(parms) {
    // console.log(parms.user_id, "GA_data");
    // ga4react.gtag("config", "MEASUREMENT_ID", {
    //     user_id: parms.user_id,
    // });
    // console.log('set', 'user_id', parms.user_id);
    ga4react.gtag('set', 'user_id', parms.user_id);
}
