import {
    put,
    takeLatest,
    all,
    call,
} from 'redux-saga/effects';

import {
    getFundraisingParameters,
    getGoalSummary,
    getProspectSummary,
    getInvestorSummary,
    getVettedInvestorList,
    getSimilarCompany,
    getCompanyVerticals,
    getCompanyDetail,
} from './Action';

import { Unauthorized } from "../../login/Action";

import {
    ShowLoader,
    HideLoader,
} from '../../root/Action';

import {
    boost,
    companies,
} from '../../../api';

import {
    handleCatchSagaError,
    compileQueryString,
} from '../../../utils/util';

import {
    NewRelicError
} from '../../../utils';

import {
    logEventInternallyAndNewRelic
} from '../../../utils/ga';

function* getFundraisingParametersResponder({ payload }){
    try{
        yield put(ShowLoader(true));
        const query_string = compileQueryString(payload.qs);
        const { data } = yield call(boost.getFundraisingParameters, payload.fundraising_id, query_string, payload);
        yield put(getFundraisingParameters.success(data));
    } catch(e){
        yield handleCatchSagaError(e, 'getFundraisingParametersResponder');
        yield put(getFundraisingParameters.failure(e));
    } finally{
        yield put(HideLoader(true));
    }
}

function* getGoalSummaryResponder({ payload }){
    try{
        yield put(ShowLoader(true));
        const query_string = compileQueryString(payload.qs);
        const { data } = yield call(boost.getGoalSummary, payload.fundraising_id, query_string, payload);
        yield put(getGoalSummary.success(data));
    } catch(e){
        yield handleCatchSagaError(e, 'getGoalSummaryResponder');
        yield put(getGoalSummary.failure(e));
    } finally{
        yield put(HideLoader(true));
    }
}

function* getProspectSummaryResponder({ payload }){
    try{
        yield put(ShowLoader(true));
        const query_string = compileQueryString(payload.qs);
        const { data } = yield call(boost.getProspectSummary, payload.fundraising_id, query_string, payload);
        yield put(getProspectSummary.success(data));
    } catch(e){
        yield handleCatchSagaError(e, 'getProspectSummaryResponder');
        yield put(getProspectSummary.failure(e));
    } finally{
        yield put(HideLoader(true));
    }
}

function* getInvestorSummaryResponder({ payload }){
    try{
        yield put(ShowLoader(true));
        const query_string = compileQueryString(payload.qs);
        const { data } = yield call(boost.getInvestorSummary, payload.fundraising_id, query_string, payload);
        yield put(getInvestorSummary.success(data));
    } catch(e){
        yield handleCatchSagaError(e, 'getInvestorSummaryResponder');
        yield put(getInvestorSummary.failure(e));
    } finally{
        yield put(HideLoader(true));
    }
}

function* getVettedInvestorListResponder({ payload }){
    try{
        yield put(ShowLoader(true));
        const query_string = compileQueryString(payload.qs);
        const { data } = yield call(boost.getVettedInvestorList, payload.fundraising_id, query_string, payload);
        yield put(getVettedInvestorList.success(data));
    } catch(e){
        yield handleCatchSagaError(e, 'getVettedInvestorListResponder');
        yield put(getVettedInvestorList.failure(e));
    } finally{
        yield put(HideLoader(true));
    }
}

function* getSimilarCompanyResponder({ payload }){
    try{
        const query_string = compileQueryString(payload.qs);
        const { data } = yield call(companies.getSimilarCompany, query_string, payload);
        yield put(getSimilarCompany.success(data));
    } catch(e){
        if( e.message && e.response ){
            const err_payload = {
                type: 'api_failure_boostgetSimilarCompanyResponder',
                data: {
                    config: e.response.config,
                    data: e.response.data,
                    status: e.response.status
                },
                err: new Error("API Failure boostgetSimilarCompanyResponder: " + e.message)
            }
            logEventInternallyAndNewRelic(err_payload);
        } else if (e.message) {
            NewRelicError( new Error("API Failure boostgetSimilarCompanyResponder: " + e.message) );
        } else {
            NewRelicError( new Error("API Failure boostgetSimilarCompanyResponder with no message") );
        }

        if (e.response.status == 401) {
            yield put(Unauthorized(e));
        }
        yield put(getSimilarCompany.failure(e));
    } finally{

    }
}

function* getCompanyVerticalsResponder({ payload }){
    try{
        const { data } = yield call(companies.getVerticals, payload.company_id, payload);
        yield put(getCompanyVerticals.success(data));
    } catch(e){
        if( e.message && e.response ){
            const err_payload = {
                type: 'api_failure_boostgetCompanyVerticalsResponder',
                data: {
                    config: e.response.config,
                    data: e.response.data,
                    status: e.response.status
                },
                err: new Error("API Failure boostgetCompanyVerticalsResponder: " + e.message)
            }
            logEventInternallyAndNewRelic(err_payload);
        } else if (e.message) {
            NewRelicError( new Error("API Failure boostgetCompanyVerticalsResponder: " + e.message) );
        } else {
            NewRelicError( new Error("API Failure boostgetCompanyVerticalsResponder with no message") );
        }

        if (e.response.status == 401) {
            yield put(Unauthorized(e));
        }
        yield put(getCompanyVerticals.failure(e));
    } finally{

    }
}

function* getCompanyDetailResponder({ payload }){
    try {
        const { data } = yield call(boost.getAllCompanies, payload.root_path, payload.sub_path, payload.company_identifier, '', payload);

        yield put(getCompanyDetail.success(data));
    } catch (e) {
        console.log(e)
        yield handleCatchSagaError(e, 'boostgetCompanyDetailResponder')
        yield put(getCompanyDetail.failure(e));
    } finally {

    }
}

function* saga(){
    yield all([
        takeLatest(getFundraisingParameters.request, getFundraisingParametersResponder),
        takeLatest(getGoalSummary.request, getGoalSummaryResponder),
        takeLatest(getProspectSummary.request, getProspectSummaryResponder),
        takeLatest(getInvestorSummary.request, getInvestorSummaryResponder),
        takeLatest(getVettedInvestorList.request, getVettedInvestorListResponder),
        takeLatest(getSimilarCompany.request, getSimilarCompanyResponder),
        takeLatest(getCompanyVerticals.request, getCompanyVerticalsResponder),
        takeLatest(getCompanyDetail.request, getCompanyDetailResponder),
    ])
}

export default saga;