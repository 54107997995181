import React, { Fragment } from "react";
import { PipelineSidebar } from '../../common';
import { Styles } from '../../styles';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { 
    content_placeholders,
    feature_flags,
} from "../../../config";

import {
    ReadQueryString,
    GetToken,
} from '../../../utils';

import {
    getLongestDigit,
} from '../../../utils/util';

import {
    Filters,
    OrderbookView,
    Sort,
    OpportunityHighlight,
    MarketActivity,
    PermissionedDeals,
    CampaignDialog,
    OldOrderbookView,
    TempOrderbookView,
} from './components';

import {
    // PlaceOrder,
    CompanySearch,
    PlaceOrderPositionWrapper,
} from '../common';

import {
    WatchlistSnackbar,
} from '../common/components';

import { 
    Box, 
    Grid,  
    Button,
    Typography, 
    withStyles, 
    Paper,    
    Card,
} from "@material-ui/core";

import {
    ToggleButtonGroup,
    ToggleButton,
} from '@material-ui/lab';
import { WatchListDialog } from "../../common/watchlist/dialog";

import {
    WatchlistGroupView,
} from '../../common/watchlist/group/components';

import {
    SubmitPortfolioPositionWrapper,
} from '../../common/client_portfolio/submit_portfolio';

import { sendGAEvent } from '../../../utils/ga';

class Pipeline extends React.Component{
    state = {
        offset: 0,
        limit: 50,
        get_more_in_process: false,
        reset_company_search: false,
        verticals: [],
        selected_vertical: [],
        geography: [],
        selected_geography: [],
        valuations: [
            '$200M - $500M',
            '$500M - $1B',
            '$1B - $5B',
            '$5B+'
        ],
        selected_valuations: [
            // '$200M - $500M',
            // '$500M - $1B',
            // '$1B - $5B',
            // '$5B+'
        ],
        tags: [
            'ROFR Waived',
            // 'Company Sponsored',
            'Information Available',
            'Primary Fundraising',
            'Direct to Captable',
            'SPV Structure',
            'Gateway SPV',
        ],
        selected_tags: [
            // 'ROFR Waived',
            // 'Company Sponsored',
            // 'Information Available',
            // 'Primary Fundraising',
            // 'Direct to Captable',
            // 'SPV Structure',
            // 'Gateway SPV',
        ],
        selected_companies: [],
        default_sort: 'Most Active',
        selected_sort: 'Most Active',
        sort_options: [
            'Live',
            'A to Z',
            'Z to A',
            'Most Active'
        ],
        orderbook: {
            resultCount: 0,
            resultList: []
        },
        orderbook_data: 'all',
        last_search: {
            selected_vertical: [],
            selected_geography: [],
            selected_valuations: [
                // '$200M - $500M',
                // '$500M - $1B',
                // '$1B - $5B',
                // '$5B+'
            ],
            selected_tags: [
                // 'ROFR Waived',
                // 'Company Sponsored',
                // 'Information Available',
                // 'Primary Fundraising',
                // 'Direct to Captable',
                // 'SPV Structure',
                // 'Gateway SPV',
            ],
            selected_companies: [],
            selected_sort: 'Most Active',
            valuation: {},
            orderbook_data: 'all'
        },
        watchListDialog:{
			open: false,
			companyId:0,
            active: false,
            newCompanyName:'',
		},
        campaignDialog:{
            open: false,
            step: 1,
        },
        userTypeUploading: false,
        distanceBottom: 5,
        fixHeaderPadding: 210,
        fixHeader: false,
        fixSideControlPadding: 1000,
        fixSideControl: false,
        longest_digit: 0,
        longest_implied_digit: 0,
        watchlistGroupList: [],
        watchlistGroupItem: {},
        selectedGroupAtIndex: 0,
        isOrderBookPortal: window.location.pathname.includes('/orderbook/'), 
        express_interest_modal_open: false,
        company_id_will_show_in_express_interest: '',
        updatingGroupItemDetail: false,
    }

    constructor(props){
        super(props);
        this.tableEl = React.createRef();
        this.timeout = 0;
    }

    componentDidMount(){
        
        const { 
            getVerticalsList,
            getGeographyList,
            getOpportunityHighlight,
            getCompanyNameById,
            clearOrderbookCompany,
            getLiveOfferCount,
            getLiveBidCount,
            getPermissionedDeals,
            location: { search },
            clearWatchlistMessage,
            getDealMetrics,
        } = this.props;

        clearWatchlistMessage();
        getVerticalsList({});
        getGeographyList({});
        getOpportunityHighlight({ qs: { 
            live: true, 
            one_side_exists: true, 
            order_field: 'order_count', 
            asc_or_desc: 'desc' 
        } });
        this.getOrderbook();
        clearOrderbookCompany();
        //getLiveOfferCount();
        //getLiveBidCount();
        getPermissionedDeals({});
        getDealMetrics({});
        const { 
            default_sort, 
            last_search, 
        } = this.state;
        last_search['selected_sort'] = default_sort;

        this.setState({ 
            selected_sort: default_sort,
            last_search: last_search,
        });

        const query_string = ReadQueryString(search);
        if( query_string.company_id ){
            getCompanyNameById({ company_id: query_string.company_id });
        }
        
        document.getElementById('home-right').addEventListener('scroll', this.handleScrollEvent);
        document.addEventListener('visibilitychange', this.handleWindowVisibilitychange);

        this.updateCampaignDialogStatus();

        const {
            watchlistGroupList,
        } = this.state;
        if (
            watchlistGroupList && watchlistGroupList.length > 0) {
            this.updateGroupItemDetail()
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let {
            watchlistGroupList,
            updatingGroupItemDetail,
        } = this.state;
        const { 
            toggle_watchlist,
            watchlistGroupItem,
        } = this.props;
        if(!updatingGroupItemDetail && toggle_watchlist.message.trim() &&
            watchlistGroupItem && watchlistGroupItem.company && 
            watchlistGroupItem.company.length != toggle_watchlist.item_count){
            updatingGroupItemDetail = true;
            this.updateGroupItemDetail();
            this.setState({
                updatingGroupItemDetail: updatingGroupItemDetail
            })
        }else if(updatingGroupItemDetail && 
                (!toggle_watchlist.message.trim() || 
                    (toggle_watchlist.message.trim() &&
                    watchlistGroupItem && watchlistGroupItem.company && 
                    watchlistGroupItem.company.length == toggle_watchlist.item_count)
                )
                ){
            updatingGroupItemDetail = false;
            this.setState({
                updatingGroupItemDetail: updatingGroupItemDetail
            })
        }

        if (
            watchlistGroupList && watchlistGroupList.length > 0 && 
            watchlistGroupList != prevState.watchlistGroupList) {
            this.updateGroupItemDetail()
        }

        if( this.props.showCampaignDialog && this.props.showCampaignDialog != prevProps.showCampaignDialog ){
            this.updateCampaignDialogStatus();
        }
    }


    shouldComponentUpdate(nextProps, nextState){
        const { company, clearOrderbookCompany } = nextProps;
        
        if( company && company.name ){
            this.getRefreshOrderbook(1000);
            clearOrderbookCompany();
        }
        return true;
    }

    updateGroupItemDetail = () => {
        const {
            getWatchlistGroupItem,
        } = this.props;
        let {
            watchlistGroupList,
            selectedGroupAtIndex,
            isOrderBookPortal,
        } = this.state;
        if (selectedGroupAtIndex >= watchlistGroupList.length) {
            selectedGroupAtIndex = watchlistGroupList.length - 1
        }
        this.setState({
            selectedGroupAtIndex: selectedGroupAtIndex
        }, () => {
            getWatchlistGroupItem({
                grpId: watchlistGroupList[selectedGroupAtIndex].id,
                path: isOrderBookPortal ? '/v2/orderbook' : 'v2/wealth', 
            })
        })
    }

    componentWillUnmount(){
        document.getElementById('home-right').removeEventListener('scroll', this.handleScrollEvent);
        document.removeEventListener('visibilitychange', this.handleWindowVisibilitychange);
    }

    handleWindowVisibilitychange = (evt) => {
		const { ValidateUserInBackground } = this.props;
		if(document.visibilityState === 'visible'){
            const accessToken = GetToken();
            if( accessToken && accessToken.token && accessToken.email && accessToken.set_time ){
                accessToken['currentUserType'] = content_placeholders.user_type.orderbook_user
                ValidateUserInBackground(accessToken);
            }
		}
	}

    handleScrollEvent = (evt) => {
        const { distanceBottom, get_more_in_process, fixHeaderPadding, fixSideControlPadding } = this.state;
        const { orderbook_loading } = this.props;

        // let bottom = this.tableEl.current.scrollHeight - this.tableEl.current.clientHeight;
        
        const current_scroll = document.getElementById('home-right').scrollTop;

        //  console.log( current_scroll );
        const window_height = document.getElementById('home-right').clientHeight;
        const page_height = document.getElementById('home-right').scrollHeight;
        if (
            (current_scroll + window_height) > (page_height - distanceBottom) &&
            !orderbook_loading && 
            !get_more_in_process
            ) {
            this.getNextOrderbook();
        }

        if(
            current_scroll > fixHeaderPadding
        ) {
            this.setState({ fixHeader: true });
        } else {
            this.setState({ fixHeader: false });
        }

        if(
            current_scroll > fixSideControlPadding &&
            window.innerWidth > 960
        ) {
            this.setState({ fixSideControl: true });
        } else {
            this.setState({ fixSideControl: false });
        }
    }

    getOrderbook = (extend=false) => {
        const {
            offset, limit,
            selected_vertical,
            selected_sort,
            selected_geography,
            selected_valuations,
            selected_tags,
            selected_companies,
            last_search,
            orderbook_data,
        } = this.state;

        const { getOrderbookView, getMoreOrderbook } = this.props;

        const qs_payload = {
            live: true,
            // one_side_exists: true,
            limit: limit,
            offset: offset,
        };

        if( orderbook_data == "watchlist" ){
            qs_payload['watchlist'] = true;
            last_search['orderbook_data'] = orderbook_data;
        } else {
            if( selected_vertical.length ){ 
                qs_payload['vertical_list'] = encodeURIComponent('{'+selected_vertical.join(',')+'}');
            }
            last_search['selected_vertical'] = selected_vertical;
    
            if( selected_geography.length ){ 
                qs_payload['region_list'] = encodeURIComponent('{'+selected_geography.join(',')+'}');
            }
            last_search['selected_geography'] = selected_geography;
    
            if( selected_valuations.length ){
                qs_payload['val_list'] = encodeURIComponent('{'+selected_valuations.join(',')+'}');
            }
            last_search['selected_valuations'] = selected_valuations;
    
            if( selected_companies.length ){
                qs_payload['comp_list'] = encodeURIComponent('{'+selected_companies.join(',')+'}');
            }
            last_search['selected_companies'] = selected_companies;
    
            if( selected_tags.length ){
                qs_payload['tag_list'] = encodeURIComponent(selected_tags.join(','));
            }
            last_search['selected_tags'] = selected_tags;
    
            switch( selected_sort ){
                case 'Live':
                    qs_payload['order_field'] = 'has_live';
                    qs_payload['asc_or_desc'] = 'asc';
                    last_search['selected_sort'] = 'Live';
                    break;
                case 'A to Z':
                    qs_payload['order_field'] = 'company_name';
                    qs_payload['asc_or_desc'] = 'asc';
                    last_search['selected_sort'] = 'A to Z';
                    break;
                case 'Z to A':
                    qs_payload['order_field'] = 'company_name';
                    qs_payload['asc_or_desc'] = 'desc';
                    last_search['selected_sort'] = 'Z to A';
                    break;
                case 'Most Active':
                    qs_payload['order_field'] = 'order_count';
                    qs_payload['asc_or_desc'] = 'desc';
                    last_search['selected_sort'] = 'Most Active';
                    break;
                default:
                    console.log('Nothing to do.');
            }
        }

        this.setState({
            last_search: last_search
        }, () => {
            if( extend ){
                getMoreOrderbook({ qs: qs_payload });
            } else {
                getOrderbookView({ qs: qs_payload });
            }
        });
        
    }

    handleToggleWatchList = (event, parms) => {
		let { company_id, watchlist } = parms;
		if(event && event.stopPropagation) event.stopPropagation();
		if(event && event.preventDefault) event.preventDefault();
		const { watchListDialog } = this.state;
		watchListDialog["open"] = true;
		watchListDialog["companyId"] = company_id;
        watchListDialog['active'] = watchlist;
        watchListDialog['newCompanyName'] = '';

        sendGAEvent({
            type: 'orderbook_open_watchlist_dialog',
            data: watchListDialog
        });

		this.setState({ watchListDialog: watchListDialog });
	};


	handleCloseWatchListDialog = (event) =>{
		const { watchListDialog } = this.state;
		watchListDialog["open"] = false;
		watchListDialog["companyId"] = 0;
        watchListDialog['newCompanyName'] = '';
		this.setState({ watchListDialog: watchListDialog });
	}

    handleWatchListStar = (companyId, active) => {
        let { orderbook } = this.state;
		if (orderbook && orderbook.resultList && orderbook.resultList.length > 0) {
			for (let index in orderbook.resultList) {
				if (orderbook.resultList[index]["company_id"] == companyId) {
					orderbook.resultList[index]["active_watchlist"] = active;
				}
			}
		}
		this.setState({
			orderbook: orderbook,
		});
    }

    handleInternalSort = (key, order) => {
        const { 
            internalSort, 
        } = this.props;

        internalSort({
            sort_type: key,
            sort_order: order
        });
    }

    handleSnackbarClose = (evt, reason) => {
        if ( reason === "clickaway") {
            return;
        }

        const { clearWatchlistMessage } = this.props;
        clearWatchlistMessage();
    }

    getNextOrderbook = () => {
        this.setState({ 
            offset: this.state.offset+1, 
            get_more_in_process: true 
        }, () => this.getOrderbook(true));
    }

    searchCriteriaChanged = () => {
        const { 
            last_search,
            selected_sort,
            selected_vertical,
            selected_geography,
            selected_valuations,
            selected_tags,
            selected_companies,
            orderbook_data,
        } = this.state;

        if(
            !selected_vertical.equals( last_search.selected_vertical ) ||
            !selected_geography.equals( last_search.selected_geography ) ||
            !selected_valuations.equals( last_search.selected_valuations ) ||
            !selected_tags.equals( last_search.selected_tags ) ||
            selected_sort != last_search.selected_sort ||
            selected_companies.length != last_search.selected_companies.length ||
            orderbook_data != last_search.orderbook_data ||
            (
                selected_companies.length && 
                last_search.selected_companies.length &&
                selected_companies[0] != last_search.selected_companies[0]
            )
        ){
            return true;
        } else {
            return false;
        }
    }

    getRefreshOrderbook = (delay_time) => {
        if( this.timeout ) clearTimeout( this.timeout );
        const self = this;
        const delay_in_ms = delay_time ? delay_time : 1000;
        // TO DO compare last_search against current search
        this.timeout = setTimeout(() => {
            if( self.searchCriteriaChanged( ) ){
                // Get orderbook data when different from last Search
                self.setState({ offset: 0 }, () => self.getOrderbook());
            }
        }, delay_in_ms);
    }

    static getDerivedStateFromProps(nextProps, prevState){
        const {
            watchlistGroupList,
            watchlistGroupItem,
            verticalsListResponse,
            geographyResponse,
            orderbook,
            company,
            toggle_watchlist,
            loginUserData,
            updateUserType,
        } = nextProps;

        if( verticalsListResponse.length != prevState.verticals.length ){
            const verticals = verticalsListResponse.map(item => item.name);
            // const { last_search } = prevState;
            // last_search['selected_verticals'] = verticals;
            // return { verticals: verticals, selected_vertical: verticals, last_search: last_search }
            return { verticals: verticals }
        } else if( geographyResponse.length != prevState.geography.length ){
            // console.log( geographyResponse );
            // const { last_search } = prevState;
            // last_search['selected_geography'] = geographyResponse;
            // return { geography: geographyResponse, selected_geography: geographyResponse, last_search: last_search }
            return { geography: geographyResponse }
        } else if ( 
            watchlistGroupList &&
            watchlistGroupList.length != prevState.watchlistGroupList.length
            ) {
                return { watchlistGroupList: watchlistGroupList }
        } else if ( 
                orderbook.resultCount != prevState.orderbook.resultCount 
                && orderbook.resultList ) {
            const payload = { orderbook: orderbook, get_more_in_process: false };

            const bids = orderbook.resultList.map(item => item['highest_bid']);
            const asks = orderbook.resultList.map(item => item['lowest_ask']);

            const orders = bids.concat(asks);
            
            const bids_implied = orderbook.resultList.map(item => item['bid_implied_val']);
            const asks_implied = orderbook.resultList.map(item => item['ask_implied_val']);

            const implied = bids_implied.concat(asks_implied);

            payload['longest_digit'] = getLongestDigit(orders);
            payload['longest_implied_digit'] = getLongestDigit(implied);
            return payload;
        } else if ( company.name ) {
            return { selected_companies: [company.name] }
        } else if ( toggle_watchlist.item_count >= 3 &&
            loginUserData.user_type == content_placeholders.user_candidate_type.lifetime_candidate &&
            !prevState.userTypeUploading){
            updateUserType()
            return { userTypeUploading : true }
        }else {
            return null;
        }
    }


    handleResetFilter = (name) => {
        const { default_sort, } = this.state;
        const new_state = name ? { [name]: [] } : { 
            selected_vertical: [], 
            selected_geography: [],  
            selected_sort: default_sort,
            selected_valuations: [],
            selected_tags: [],
            selected_companies: [],
            reset_company_search: true,
        }
        
        this.setState(new_state, () => { 
            this.getRefreshOrderbook(2000);
            const self = this;
            setTimeout(function(){
                self.setState({ reset_company_search: false });
            }, 1000);
        });
    }

    handleDirectSectorSelect = (evt, value) => {
        const { verticals } = this.state;
        const filtered_verticals = verticals.filter(item => item == value);
        this.setState({ selected_vertical: filtered_verticals }, () => this.getRefreshOrderbook());
    }

    handleSwitchOBData = (key, evt, newValue) => {
        this.setState({ [key]: newValue }, () => this.getRefreshOrderbook());
    }

    handleSelectChange = (evt) => {
        const { target: { name, value } } = evt;
        
        // Auto set Gateway SPV when SPV Structure is set
        if( name == "selected_tags" && value.indexOf('SPV Structure') > -1 ){
            value.push('Gateway SPV');
        }
        const new_state = { [name]: value };
        this.setState(new_state, () => this.getRefreshOrderbook());
    }

    handleSearchCompany = (evt, name) => {
        const selected_companies = name.trim() ? [name] : [];
        // console.log( selected_companies );
        
        const new_state = { selected_companies: selected_companies }
        this.setState(new_state, () => this.getRefreshOrderbook());
    }

    handleShowWatchlistFromSearchCompany = (evt, search_name) => {
        const company_name = search_name.trim() ? search_name.trim() : ''
        if(company_name){
            const { watchListDialog } = this.state;
		    watchListDialog["open"] = true;
		    watchListDialog["companyId"] = null;
            watchListDialog['active'] = false;
            watchListDialog['newCompanyName'] = company_name;
		    this.setState({ watchListDialog: watchListDialog });
        }
    }

    handleGoToCompanyDetail = (evt, company_id) => {
        if( company_id ){
            const { history } = this.props;
            history.push(`/orderbook/company-detail/${company_id}`);
        }
    }

    updateCampaignDialogStatus = () => {
        const { 
            loginUserData,
            showCampaignDialog,
        } = this.props;
        const { campaignDialog } = this.state;

        
        if( showCampaignDialog ){
            sendGAEvent({
                type: 'orderbook_open_campaign_dialog',
                data: {}
            });
        }

        campaignDialog['open'] = showCampaignDialog;
        this.setState({ campaignDialog: campaignDialog });
    
    }

    handleCloseCampaignialog = (evt, follow_up) => {
        let { campaignDialog, userTypeUploading } = this.state;
        const { showHideCampaignPopUp, watchlistGroupItem, loginUserData, updateUserType, watchlistGroupItem_loading } = this.props;
		campaignDialog["open"] = false;
        if(watchlistGroupItem_loading){
            setTimeout(() => {
                this.handleCloseCampaignialog(evt, follow_up);
              }, 1000);
        }else{
            if( follow_up ){
                campaignDialog['step'] = 2;
                if ( watchlistGroupItem && watchlistGroupItem.company && watchlistGroupItem.company.length >= 3 &&
                    loginUserData.user_type == content_placeholders.user_candidate_type.lifetime_candidate &&
                    !userTypeUploading){
                        campaignDialog['step'] = 1;
                        updateUserType()
                        userTypeUploading = true;
                    }
            }
    
            const evt_type = follow_up ? 'orderbook_confirm_lifetime_click' : 'orderbook_close_campaign_dialog';
    
            sendGAEvent({ type: evt_type, data: {} });
            showHideCampaignPopUp({ show: false });
            this.setState({ campaignDialog: campaignDialog, userTypeUploading: userTypeUploading });
        }
    }

    handleShowExpressInterest = (evt) => {
        const { express_interest_modal_open,company_id_will_show_in_express_interest } = this.state;
        this.setState({ express_interest_modal_open: !express_interest_modal_open ,
            company_id_will_show_in_express_interest:''});
    }

    handleShowExpressInterestWithCompanyId = (company_id) => {
        const { express_interest_modal_open, company_id_will_show_in_express_interest } = this.state;
        this.setState({ 
            express_interest_modal_open: true, 
            company_id_will_show_in_express_interest: company_id 
        });
    }

    render(){
        const {
            verticals,
            selected_vertical,
            geography,
            selected_geography,
            valuations,
            selected_valuations,
            tags,
            selected_tags,
            selected_companies,
            orderbook,
            sort_options,
            default_sort,
            selected_sort,
            fixHeader,
            fixSideControl,
            reset_company_search,
            longest_digit,
            longest_implied_digit,
            orderbook_data,
            watchListDialog,
            campaignDialog,
            express_interest_modal_open,
            company_id_will_show_in_express_interest,
        } = this.state;
        
        const { 
            orderbook_loading, 
            opportunity_highlight,
            collapseSidebar,
            sort_type,
            sort_order,
            loginUserData,
            live_offer_count,
            live_bid_count,
            toggle_watchlist,
            classes,
            appConfig,
            permissioned_deals,
            watchlistGroupItem,
            historical_pricing,
            funding_rounds,
            dealMetricsResponse,
        } = this.props;
        
        const { email, activeAccessRight: { access_right: { permission } } } = loginUserData;

        const show_reset = selected_vertical.length ||
                            selected_geography.length ||
                            selected_valuations.length ||
                            selected_tags.length ||
                            selected_sort != default_sort ||
                            selected_companies.length ? true : false;

        const sideControlStyle = fixSideControl ? 
                                collapseSidebar ? 
                                    { 
                                        marginTop: 16, 
                                        padding: 16, 
                                        position: 'fixed', 
                                        top: 64, 
                                        width: 'calc((100% - 64px) * 0.41666667 - 24px)',
                                        display: 'block',
                                    } : { 
                                        marginTop: 16, 
                                        padding: 16, 
                                        position: 'fixed', 
                                        top: 64, 
                                        width: 'calc((100% - 288px) * 0.41666667 - 24px)',
                                        display: 'block',
                                    } 
                                : { 
                                    marginTop: 16, 
                                    padding: 16, 
                                    width: '100%',
                                    display: 'none',
                                };

        const home_right_class = collapseSidebar ? 'home-right narrow' : 'home-right';
        const de_select_all_text = "Clear all filters / sorts";
        const market_activity_style = permissioned_deals && permissioned_deals.length ? 
                                        { marginTop: 16, padding: 16, width: '100%' } : 
                                        { padding: 16, width: '100%' }
        const flag_payload = feature_flags.extract_payload(
            appConfig,
            feature_flags.flags.SectorTrend.flag_name,
            feature_flags.flags.SectorTrend.attributes.external_doc,
            email
        )

        const show_watchlist_view = feature_flags.feature_is_available(
            appConfig,
            feature_flags.watchlist_flags.Watchlist.flag_name,
            feature_flags.watchlist_flags.Watchlist.attributes.watchlist_view,
            email
        )

        const submit_portfolio_available = feature_flags.feature_is_available(
            appConfig,
            feature_flags.flags.PortfolioPage.flag_name,
            feature_flags.flags.PortfolioPage.attributes.submission,
            email
        )

        const graph_payload = { company_id: '' }
        // console.log( flag_payload );
        const new_orderbook_view = feature_flags.feature_is_available(
            appConfig,
            feature_flags.flags.OrderList.flag_name,
            feature_flags.flags.OrderList.attributes.new_orderbook_view,
            email
        )
        const isTemp = true// window.location.search.includes('temp=') // temporary

        const lifetime_subscription_style = {
            background: 'rgba(252,163,16,0.8)',
            paddingTop: 16,
            paddingBottom: 16,
            paddingLeft: 16,
            fontWeight: 'bolder',
            textAlign: 'center'
        }

        // console.log( orderbook );
        // console.log( watchListDialog );

        let company_name = '';
        if( watchListDialog.companyId && orderbook.resultList && orderbook.resultList.length ){
            const { resultList } = orderbook;
            const company_item = resultList.filter(item => item.company_id == watchListDialog.companyId);
            company_name = company_item.length ? company_item[0].company_name : '';
        }

        return (
            <div className="home orderbook">
                <div className="top-padding"></div>
                <Helmet>
                    <title>Orderbook | {content_placeholders.title_prefix}</title>
                </Helmet>
                <PipelineSidebar
                    tabFocusIndex="3" 
                    collapse={true}
                    {...this.props}
                    />
                    {/* onScroll={this.handleScrollEvent} ref={this.tableEl} */}
                <div className={home_right_class} id="home-right">
                    {
                        campaignDialog.step > 1 && 
                        loginUserData.user_type == content_placeholders.user_candidate_type.lifetime_candidate ?
                            <div style={lifetime_subscription_style}>Thank you confirming your interest in Life-time subscription. Please now add 3 companies to your watchlist to claim the subscription.</div>
                            : ''
                    }
                    <Box m={2}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={new_orderbook_view ? 8 : 7}>
                                <Paper style={{ padding: 16, width: '100%' }}>
                                    <MarketActivity
                                        handleDirectSectorSelect={this.handleDirectSectorSelect}
                                        {...{
                                            live_offer_count,
                                            live_bid_count,
                                            dealMetricsResponse,
                                        }} />
                                </Paper>
                                <Paper style={{marginTop: 16, padding: 16, width: '100%' }}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <Typography variant="h6" className="orderbook-paper-title">Gateway Orderbook</Typography>    
                                            
                                            {
                                                show_reset ? <Button 
                                                        onClick={() => this.handleResetFilter()} 
                                                        style={{ fontSize: 8, textAlign: 'right' }}>
                                                            {de_select_all_text}
                                                    </Button> : ''
                                            }
                                            
                                        </Grid>
                                    </Grid>
                                    
                                    {
                                        feature_flags.feature_is_available(
                                            appConfig,
                                            feature_flags.watchlist_flags.Watchlist.flag_name,
                                            feature_flags.watchlist_flags.Watchlist.attributes.watchlist_filter,
                                            email
                                       ) ? <Grid container spacing={2} style={{marginBottom: 8}}>
                                                <Grid item xs={12}>
                                                    <ToggleButtonGroup
                                                        className={classes.ToggleButtonGroup}
                                                        exclusive
                                                        value={orderbook_data}
                                                        onChange={this.handleSwitchOBData.bind(this, 'orderbook_data')}
                                                        >
                                                            <ToggleButton className={classes.ToggleButton} value="all" aria-label="left aligned">
                                                                Orderbook
                                                            </ToggleButton>
                                                            <ToggleButton className={classes.ToggleButton} value="watchlist" aria-label="right aligned">
                                                                Watchlist
                                                            </ToggleButton>
                                                    </ToggleButtonGroup>
                                                </Grid>
                                            </Grid> : ''
                                    }
                                    
                                    
                                    <Grid container spacing={2}>
                                        {
                                            orderbook_data == 'all' ? 
                                                <Fragment>
                                                    <Grid item xs={12}>
                                                        <Filters
                                                            handleSelectChange={this.handleSelectChange}
                                                            handleResetFilter={this.handleResetFilter}
                                                            {...{
                                                                verticals,
                                                                selected_vertical,
                                                                geography,
                                                                selected_geography,
                                                                valuations,
                                                                selected_valuations,
                                                                tags,
                                                                selected_tags,
                                                            }}
                                                            />
                                                    </Grid>
                                                    <Grid item xs={9}>
                                                        {
                                                            permission == content_placeholders.user_type.orderbook_p1_user ? 
                                                                <CompanySearch 
                                                                    handleSearchCompany={this.handleSearchCompany}
                                                                    reset_company_search={reset_company_search}
                                                                    containerId='company-name-input-container'
                                                                    handleShowWatchlistFromSearchCompany={this.handleShowWatchlistFromSearchCompany}
                                                                    /> 
                                                                    : 
                                                                <CompanySearch
                                                                    containerId='company-name-input-container'
                                                                    handleShowWatchlistFromSearchCompany={this.handleShowWatchlistFromSearchCompany}
                                                                    />
                                                        }
                                                    </Grid>
                                                    <Grid item xs={3}>
                                                        <Sort
                                                            handleSelectChange={this.handleSelectChange}
                                                            {...{
                                                                sort_options,
                                                                selected_sort,
                                                            }} />
                                                    </Grid>
                                                </Fragment> : ''
                                        }
                                        
                                        <Grid item xs={12}>
                                            {
                                                isTemp ?
                                                <TempOrderbookView 
                                                getNextOrderbook={this.getNextOrderbook}
                                                handleInternalSort={this.handleInternalSort}
                                                handleGoToCompanyDetail={this.handleGoToCompanyDetail}
                                                handleToggleWatchList={this.handleToggleWatchList}
                                                {...{
                                                    orderbook,
                                                    orderbook_loading,
                                                    fixHeader,
                                                    collapseSidebar,
                                                    sort_type,
                                                    sort_order,
                                                    loginUserData,
                                                    longest_digit,
                                                    longest_implied_digit,
                                                    appConfig,
                                                }}
                                            /> :
                                            new_orderbook_view ? 
                                            <OrderbookView 
                                                getNextOrderbook={this.getNextOrderbook}
                                                handleInternalSort={this.handleInternalSort}
                                                handleGoToCompanyDetail={this.handleGoToCompanyDetail}
                                                handleToggleWatchList={this.handleToggleWatchList}
                                                {...{
                                                    orderbook,
                                                    orderbook_loading,
                                                    fixHeader,
                                                    collapseSidebar,
                                                    sort_type,
                                                    sort_order,
                                                    loginUserData,
                                                    longest_digit,
                                                    longest_implied_digit,
                                                    appConfig,
                                                }}
                                            /> :
                                            <OldOrderbookView 
                                                getNextOrderbook={this.getNextOrderbook}
                                                handleInternalSort={this.handleInternalSort}
                                                handleGoToCompanyDetail={this.handleGoToCompanyDetail}
                                                handleToggleWatchList={this.handleToggleWatchList}
                                                {...{
                                                    orderbook,
                                                    orderbook_loading,
                                                    fixHeader,
                                                    collapseSidebar,
                                                    sort_type,
                                                    sort_order,
                                                    loginUserData,
                                                    longest_digit,
                                                    longest_implied_digit,
                                                    appConfig,
                                                }}
                                            />
                                            }
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                            <Grid item xs={12} md={new_orderbook_view ? 4 : 5}>
                                {/* <Paper style={{ padding: 16, width: '100%' }}>
                                    <PlaceOrder {...this.props} />
                                </Paper> */}
                                {
                                    show_watchlist_view ?
                                        <Paper style={{ padding: 16, width: '100%' }}>
                                            <Typography variant="h6" className="orderbook-paper-title">My Watchlist</Typography>    
                                            <WatchlistGroupView
                                                {...{
                                                    appConfig,
                                                    loginUserData,
                                                    watchlistGroupItem,
                                                    historical_pricing,
                                                    funding_rounds,
                                                    graph_payload,
                                                }}
                                                companyDetailType="orderbook"
                                                handleCompanyDeleteDialogOpen={(evt) => console.log(evt)}
                                                handleShowCompGraph={(evt) => console.log(evt)}
                                                shortenedMode={true}
                                                />
                                        </Paper> 
                                        : ''
                                }

                                <Paper style={{ marginTop: 16, padding: 16, width: '100%' }}>
                                    <PermissionedDeals
                                        handleGoToCompanyDetail={this.handleGoToCompanyDetail}
                                        {...{ permissioned_deals }}
                                        />
                                </Paper>

                                <Paper style={{ marginTop: 16, padding: 16, width: '100%' }}>
                                    <OpportunityHighlight 
                                        handleGoToCompanyDetail={this.handleGoToCompanyDetail}
                                        {...{
                                            opportunity_highlight,
                                            loginUserData,
                                            longest_digit,
                                        }}
                                        />
                                </Paper>

                                {
                                    flag_payload && flag_payload.external_doc && flag_payload.external_doc.url &&
                                        <Paper style={market_activity_style}>
                                            <span style={{ fontSize: 12, display: 'block', lineHeight: '14px', height: '20px',overflow:'hidden'}}>
                                                Gateway Private Market Intelligence
                                            </span>
                                            <Typography variant="h6" style={{ lineHeight: '1rem', height: '2rem',overflow:'hidden', marginTop: '4px' }}>Sector Performance Trends</Typography>
                                            
                                            <Card>
                                                <div
                                                    style={{ height: '10rem', marginTop: '4px'  }}>
                                                        <img style={{ width: 'auto', height: '100%', margin: '0 auto', display: 'block', objectFit: 'contain' }} src={'https://media.gatewayprivatemarkets.com/portal/WealthPortal/GatewaySectorPerformance.png'} />
                                                </div>
                                                <Button className="btn-submit blue-btn" onClick={()=> window.open(flag_payload.external_doc.url, "_blank")}>
                                                    Detail
                                                </Button>
                                            </Card>
                                        </Paper>
                                }
                                
                                
                                
                                {/* <Paper style={sideControlStyle}>
                                    {
                                        orderbook_data == 'all' ? 
                                            <Fragment>
                                                {
                                                    permission == content_placeholders.user_type.orderbook_p1_user ? 
                                                        <CompanySearch 
                                                            handleSearchCompany={this.handleSearchCompany}
                                                            reset_company_search={reset_company_search}
                                                            containerId='company-name-input-container-side'
                                                            handleShowWatchlistFromSearchCompany={this.handleShowWatchlistFromSearchCompany}
                                                            /> 
                                                            :
                                                        <CompanySearch
                                                            containerId='company-name-input-container-side' 
                                                            handleShowWatchlistFromSearchCompany={this.handleShowWatchlistFromSearchCompany}
                                                            />
                                                }
                                                <div style={{ marginTop: 16 }}></div>
                                                <Filters
                                                    handleSelectChange={this.handleSelectChange}
                                                    handleResetFilter={this.handleResetFilter}
                                                    verticalPositioning={true}
                                                    {...{
                                                        verticals,
                                                        selected_vertical,
                                                        geography,
                                                        selected_geography,
                                                        valuations,
                                                        selected_valuations,
                                                        tags,
                                                        selected_tags,
                                                    }}
                                                    />
                                                <Sort
                                                    handleSelectChange={this.handleSelectChange}
                                                    {...{
                                                        sort_options,
                                                        selected_sort,
                                                    }} />

                                                    {
                                                        show_reset ? <Button 
                                                                onClick={() => this.handleResetFilter()} 
                                                                style={{ fontSize: 8, textAlign: 'right' }}>
                                                                    {de_select_all_text}
                                                            </Button> : ''
                                                    }
                                            </Fragment>
                                                : ''
                                    }
                                    
                                </Paper> */}
                            </Grid>
                        </Grid>
                    </Box>

                    <PlaceOrderPositionWrapper
                        handleShowExpressInterest={this.handleShowExpressInterest}
                        open={express_interest_modal_open}
                        lock_company={company_id_will_show_in_express_interest ? true : false}
                        company_id_from_watchlist={company_id_will_show_in_express_interest}
                        {...this.props}
                        />
                    {
                        submit_portfolio_available ?
                            <SubmitPortfolioPositionWrapper
                                lock_company={false}
                                {...this.props}
                                /> : ''
                    }

                    <WatchlistSnackbar
                        handleSnackbarClose={this.handleSnackbarClose}
                        open={toggle_watchlist.message.trim() ? true : false}
                        error={toggle_watchlist.error}
                        message={toggle_watchlist.message}
                        upTime={toggle_watchlist.upTime}
                        />
                </div>

                {watchListDialog.open ? 
						<WatchListDialog
                        {...watchListDialog}
                        {...{
                            company_name,
                        }}
						// companyId={watchListDialog.companyId}
						closeDialog={this.handleCloseWatchListDialog}
						updateStarStatus={this.handleWatchListStar}
                        handleShowExpressInterest={() => this.handleShowExpressInterestWithCompanyId(watchListDialog.companyId)}
					/> : null}
                
                <CampaignDialog
                    open={campaignDialog.open}
                    handleCloseCampaignialog={this.handleCloseCampaignialog}
                    />
            </div>
        )
    }
}

export default withStyles(Styles)(Pipeline);