import { connect } from 'react-redux';
import { default as RequestInformation } from './RequestInformation';

import {
    postRequestInfo,
    clearRequestInfoMsg,
} from './Action';

export default connect(
    state => ({
        request_info_response: state.orderbookCompany.request_info_reducer.reducer.request_info_response,
    }),
    dispatch => ({
        postRequestInfo: payload => dispatch(postRequestInfo.request(payload)),
        clearRequestInfoMsg: () => dispatch(clearRequestInfoMsg()),
    })
)(RequestInformation);