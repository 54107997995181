import {
	put,
	takeLatest,
	all,
	fork,
	call
} from 'redux-saga/effects';

import {
	NewRelicError
} from '../../../utils';

import {
	logEventInternallyAndNewRelic
} from '../../../utils/ga';

import { Unauthorized } from "../../login/Action";

import { ShowLoader, HideLoader } from '../../root/Action';

import {
	createWatchlistGroup,
	createWatchlist,
	getWatchlistGroup,
	getWatchlistByCompanyId,
	getWatchlistGroupItem,
	deleteWatchlistGroupItem,
	updateWatchlistGroupItem,
	createWatchlistGroupOnly,
	createWatchlistV2,
	getWatchlistCompanies,
    getPriceIndex,
    getFundingRound,
    getVerticals,
    getWatchlistPipeline,
	getWatchlistHistoricalPrices,
} from './Action';

import { watch_list, auth, companies, orderbook, wealth_pipeline } from "../../../api";
import {
    compileQueryString,
    handleCatchSagaError,
} from '../../../utils/util';

function* createWatchlistGroupResponder({ payload }) {
	try {
		const body = {
			'watchlist_grp_name': payload.watchlistName,
			'watchlist_grp_description': ' ',
			'notification_required':true,
		}
		const { data } = yield call(watch_list.createWatchlistGrp, payload.path, body);
		yield put(createWatchlistGroup.success(data));
		if(data && data.id){
			const createWatchlistPayload = {
				'company_id': payload.companyId,
				'active': true,
				'watchlist_grp_id': data.id,
				'update_watchlist_group': true,
				'watchlist_type': null,
				'price': null,
				'valuation': null,
				'deal_size': null,
				'website': null,
				'company_name': null,
			}
			if(payload.path.indexOf('wealth') > -1){
				yield put(createWatchlist.request(createWatchlistPayload));
			}else{
				delete createWatchlistPayload["update_watchlist_group"];
				yield put(createWatchlistV2.request(createWatchlistPayload));
			}
		}
	} catch (e) {
		yield handleCatchSagaError(e, 'createWatchlistGroupResponder');
		yield put(createWatchlistGroup.failure(e));
	} finally {
	}
}

function* createWatchlistResponder({ payload }) {
	try {
		const filteredPayload = Object.assign({}, payload);
		if('update_watchlist_group' in filteredPayload){
			delete filteredPayload["update_watchlist_group"];
		}
		const { data } = yield call(watch_list.toggleWatchList, filteredPayload);
		yield put(createWatchlist.success(data));
		if(payload.update_watchlist_group){
			yield put(getWatchlistGroup.request({root_path: '/v2/wealth'}))
		}
		if(payload.company_name){
			const newPayload = {
				"company_id": data.company_id,
				"company_name": payload.company_name,
				"interest_type": "NEW_COMPANY",
				"firm_interest": false,
				"comment": "",
				"note": "",
				"notional": null,
				"price": data.price,
			}
			yield call(wealth_pipeline.interests, newPayload);
		}
	} catch (e) {
		yield handleCatchSagaError(e, 'createWatchlistResponder');
		yield put(createWatchlist.failure(e));
	} finally {
	}
}

function* getWatchlistGroupResponder({ payload }) {
	try {
		const { data } = yield call(watch_list.getWatchlistGrp, payload.root_path, payload);
		yield put(getWatchlistGroup.success(data));
	} catch (e) {
		yield handleCatchSagaError(e, 'getWatchlistGroupResponder');
		yield put(getWatchlistGroup.failure(e));
	} finally {
	}
}

function* getWatchlistByCompanyIdResponder({ payload }) {
	try {
		let companyId = payload.companyId;
		let root_path = payload.path;
		const { data } = yield call(watch_list.getWatchlistByCompanyId, root_path, companyId, payload);
		yield put(getWatchlistByCompanyId.success(data));
	} catch (e) {
		yield handleCatchSagaError(e, 'getWatchlistGroupResponder');
		yield put(getWatchlistByCompanyId.failure(e));
	} finally {
	}
}

function* getWatchlistGroupItemResponder({ payload }) {
	try {
		let grpId = payload.grpId;
		let root_path = payload.path;
		const { data } = yield call(watch_list.getWatchlistGrpByGrpId, root_path, grpId, payload);
		yield put(getWatchlistGroupItem.success(data));
	} catch (e) {
		yield handleCatchSagaError(e, 'getWatchlistGroupItemResponder');
		yield put(getWatchlistGroupItem.failure(e));
	} finally {
	}
}

function* deleteWatchlistGroupItemResponder({ payload }) {
	try {
		let grpId = payload.grpId;
		let root_path = payload.path;
		const { data } = yield call(watch_list.deleteWatchlistGrp, root_path, grpId, payload);
		yield put(deleteWatchlistGroupItem.success(data));
		yield put(getWatchlistGroup.request({root_path: payload.path}));
	} catch (e) {
		yield handleCatchSagaError(e , 'deleteWatchlistGroupItemResponder');
		yield put(deleteWatchlistGroupItem.failure(e));
	} finally {
	}
}

function* updateWatchlistGroupItemResponder({ payload }) {
	try {
		let grpId = payload.grpId;
		let root_path = payload.path;
		delete payload.grpId
		delete payload.path
		const { data } = yield call(watch_list.updateWatchlistGrp, root_path, grpId, payload);
		yield put(updateWatchlistGroupItem.success(data));
		yield put(getWatchlistGroupItem.request({grpId: grpId, path: root_path}));
	} catch (e) {
		yield handleCatchSagaError(e, 'updateWatchlistGroupItemResponder');
		yield put(updateWatchlistGroupItem.failure(e));
	} finally {
	}
}

function* createWatchlistGroupOnlyResponder({ payload }) {
	try {
		const body = {
			'watchlist_grp_name': payload.watchlistName,
			'watchlist_grp_description': payload.watchlistDescription,
			'notification_required':true,
		}
		const { data } = yield call(watch_list.createWatchlistGrp, payload.path, body);
		yield put(createWatchlistGroupOnly.success(data));
		yield put(getWatchlistGroup.request({root_path: payload.path}))
	} catch (e) {
		yield handleCatchSagaError(e, 'createWatchlistGroupOnlyResponder')
		yield put(createWatchlistGroupOnly.failure(e));
	} finally {
	}
}

function* createWatchlistV2Responder({ payload }) {
	yield put(ShowLoader(true));
	try {
		const { data } = yield call(watch_list.toggleWatchListV2, payload);
		yield put(createWatchlistV2.success(data));
		if(payload.company_name){
			const newPayload = {
				"company_id": data.company_id,
				"interest_type": "NEW_COMPANY",
				// "firm_interest": true,
				// "comment": "",
				// "note": "",
				// "notional": null,
				// "price": data.price,
			}
			yield call(orderbook.interests, newPayload);
		}
	} catch (e) {
		yield handleCatchSagaError(e , 'createWatchlistV2Responder');
		yield put(createWatchlistV2.failure(e));
	} finally {
		yield put(HideLoader(true));
	}
}

function* getWatchlistCompaniesResponder({ payload }){
    try{
        let query_string = "?watchlist=1&offset=0";
        const { data } = yield call(
            watch_list.getWatchList,
            query_string,
            payload
        );

        const company_ids = data.results.map(item => item.company_id);

        if( company_ids.length ){
            const req_payload = {
                qs: {
                    company_ids: company_ids.join(",")
                }
            }
            yield put(getPriceIndex.request({ qs: { group_id: payload.group_id, path: payload.path }}));
            yield put(getFundingRound.request(req_payload));
            yield put(getVerticals.request(req_payload));
        }
        
        yield put(getWatchlistCompanies.success(data));
    } catch(e){
        yield handleCatchSagaError(e, 'getWatchlistCompaniesResponder')
        yield put(getWatchlistCompanies.failure(e));
    } finally{

    }
}

function* getWatchlistPipelineResponder({ payload }){
    try{
        const query_string = compileQueryString(payload.qs);
        const { data } = yield call(companies.getOrderbookView, query_string, payload);
        yield put(getWatchlistPipeline.success(data));
    } catch(e){
        yield handleCatchSagaError(e, 'kWatchlistgetWatchlistPipelineResponder');
        yield put(getWatchlistPipeline.failure(e));
    } finally{

    }
}

function* getPriceIndexResponder({ payload }){
    try {
		let root_path = payload.qs.path
		delete payload.qs['path']
        const query_string = compileQueryString(payload.qs);
        const { data } = yield call(watch_list.getPriceIndex, root_path, query_string, payload);
        yield put( getPriceIndex.success(data) );
    } catch(e){
        yield handleCatchSagaError(e, 'WatchlistgetPriceIndexResponder');
        yield put(getPriceIndex.failure(e));
    } finally{

    }
}

function* getFundingRoundResponder({ payload }){
    try {
        const query_string = compileQueryString(payload.qs);
        // const { data } = yield call(portfolio_holding.getFundingRound, query_string, payload);
		const { data } = yield call(orderbook.getFundingRound, payload.root_path, payload.sub_path, payload.company_identifier, query_string, payload);
        yield put(getFundingRound.success(data));
    } catch(e){
        yield handleCatchSagaError(e, 'WatchlistgetFundingRoundResponder');
        yield put(getFundingRound.failure(e));
    } finally{}
}

function* getVerticalsResponder({ payload }){
    try {
        const query_string = compileQueryString(payload.qs);
        const { data } = yield call(watch_list.getVerticals, query_string, payload);
        yield put(getVerticals.success(data));
    } catch(e){
        yield handleCatchSagaError(e, 'WatchlistgetVerticalsResponder');
        yield put(getVerticals.failure(e));
    } finally{

    }
}

function* getWatchlistHistoricalPricesResponder({ payload }){
	try{
		const query_string = compileQueryString(payload.qs);
		const { data } = yield call(orderbook.getHistoricalSecondaryPricing, payload.root_path, payload.sub_path, payload.company_identifier, query_string, payload);
		yield put(getWatchlistHistoricalPrices.success(data));
	} catch(e){
		yield handleCatchSagaError(e, 'getWatchlistHistoricalPricesResponder');
		yield put(getWatchlistHistoricalPrices.failure(e));
	} finally{}
}

function* saga() {
	yield all([
		takeLatest(createWatchlistGroup.request, createWatchlistGroupResponder),
		takeLatest(createWatchlist.request, createWatchlistResponder),
		takeLatest(getWatchlistGroup.request, getWatchlistGroupResponder),
		takeLatest(getWatchlistByCompanyId.request, getWatchlistByCompanyIdResponder),
		takeLatest(getWatchlistGroupItem.request, getWatchlistGroupItemResponder),
		takeLatest(deleteWatchlistGroupItem.request, deleteWatchlistGroupItemResponder),
		takeLatest(updateWatchlistGroupItem.request, updateWatchlistGroupItemResponder),
		takeLatest(createWatchlistGroupOnly.request, createWatchlistGroupOnlyResponder),
		takeLatest(createWatchlistV2.request, createWatchlistV2Responder),
		takeLatest(getWatchlistCompanies.request, getWatchlistCompaniesResponder),
        takeLatest(getPriceIndex.request, getPriceIndexResponder),
        takeLatest(getFundingRound.request, getFundingRoundResponder),
        takeLatest(getVerticals.request, getVerticalsResponder),
        takeLatest(getWatchlistPipeline.request, getWatchlistPipelineResponder),
		takeLatest(getWatchlistHistoricalPrices.request, getWatchlistHistoricalPricesResponder),
	])
}

export default saga;