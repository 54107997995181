import {
    put,
    takeLatest,
    all,
    fork,
    call
} from 'redux-saga/effects';


import {
    resetPasswordWithEmail
} from './Action';

import { saga as updatePassword } from './updatePassword';

import {
    auth
} from '../../api';


function* resetPasswordWithEmailResponder({ payload }) {
    try {
        const { data } = yield call(auth.resetPasswordWithEmail, payload);
        yield put(resetPasswordWithEmail.success(data))
    } catch (e) {
        yield put(resetPasswordWithEmail.failure(e));
    } finally {
    }
}


function* saga(){
    yield all([
        takeLatest(resetPasswordWithEmail.request, resetPasswordWithEmailResponder),
        fork(updatePassword)
    ])
}


export default saga;