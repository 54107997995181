import React from 'react';

import {
    Table,
    TableBody,
    TableContainer,
    TableHead,
    Typography,
    Paper,
} from '@material-ui/core';

import {
    StyledTableRow,
    TableLabelStyle,
    TighterHeaderTableCell,
    TighterTableCell,
} from '../../../orderbook/common/Table';

export default (props) => {
    const classes = TableLabelStyle();
    return (
        <React.Fragment>
            <Typography variant="h6">Prospective Investors - Summary</Typography>
            <p className="company-dashboard-subtitle">
                Total Investor Prospects: { props.prospectSummary && props.prospectSummary.total ? props.prospectSummary.total : '0' }
            </p>
            <TableContainer component={Paper} className={classes.container}>
                <Table stickyHeader size="small" aria-label="simple table">
                    <TableHead>
                        <StyledTableRow>
                            <TighterHeaderTableCell className="investor-type">Investor Type</TighterHeaderTableCell>
                            <TighterHeaderTableCell className="number-of-prospects">Number of Prospects</TighterHeaderTableCell>
                        </StyledTableRow>
                    </TableHead>
                    <TableBody>
                        {   props.prospectSummary && props.prospectSummary.types && props.prospectSummary.types.length > 0 &&
                            props.prospectSummary.types.map((row, index) => {
                                return (
                                    <React.Fragment key={index}>
                                        <StyledTableRow>
                                            <TighterTableCell
                                                className='investor-type'>{row.name}</TighterTableCell>
                                            <TighterTableCell
                                                className="number-of-prospects">{row.count}</TighterTableCell>
                                        </StyledTableRow>
                                    </React.Fragment>
                                )
                            })
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </React.Fragment>
    )
}