import { combineReducers } from "redux";

import {
    getTrends,
} from './Action';

import { default as InitialState } from './InitialState';

function rearrange_trends( trends ){
    const sectors = {}
    
    trends.forEach(item => {
        if( !sectors[item['sectors']] ){
            sectors[item['sectors']] = []
        }
        
        sectors[item['sectors']].push(item);
    });

    return sectors;
}

function rearrange_trend_date( dates ){
    
}

export default combineReducers({
    reducer( state=InitialState, action ){
        switch( action.type ){
            case getTrends.request.toString():
            case getTrends.failure.toString():
                return Object.assign({}, state, {
                    trends: { }
                });
            case getTrends.success.toString():
                // console.log( action.payload );
                const { data, error } = action.payload;
                let sector_trends = [], trend_date = [];
                try{
                    if( data ){
                        sector_trends = JSON.parse(data.sector_trend);
                        trend_date = JSON.parse(data.trend_date)
                        console.log( trend_date)
                    }
                } catch(e){

                }
                // console.log( trends );
                return Object.assign({}, state, {
                    trends: rearrange_trends(sector_trends) 
                });
            default:
                return state;
        }
    }
})