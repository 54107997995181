import {
    put,
    call,
    takeLatest,
    all,
    fork,
} from 'redux-saga/effects';

import {
    getCompanyDetail,
    getFundingRound,
    getHistoricalSecondaryPricing,
    getReferencePricing,
    getLiveOffers,
    getLiveDemand,
    getCompanyVerticals,
    getSimilarCompany,
    toggleWatchList,
    getPermissionedDeal,
    getLiveOffersWatchlist,
    getLiveDemandWatchlist,
    getRiskDisclosure,
    shareDeal,
    getSharedUser,
    addCount,
    getVciHistoricalRevenue,
    getVciCapTable,
    getVciFundingRound,
} from './Action';

import {
    orderbook,
    companies,
    watch_list,
    api_call,
} from '../../../api';

import {
    compileQueryString, handleCatchSagaError,
} from '../../../utils/util';

import { saga as request_info_saga } from './components/RequestInformation';

function* getCompanyDetailResponder({ payload }){
    try {
        const { public_url, company_identifier } = payload;
        const path = public_url ? `/v2/orderbook/public/companies/?token=${company_identifier}` : `/v2/orderbook/companies/${company_identifier}/`;
        // const query_string = compileQueryString(payload.qs);
        const query_string = ''; 
        const { data } = yield call(
                            api_call.get,
                            path,
                            query_string,
                            payload);
        yield put(getCompanyDetail.success(data));
    } catch (e) {
        yield handleCatchSagaError(e, 'orderbookgetCompanyDetailResponder')
        yield put(getCompanyDetail.failure(e));
    } finally {}
}

function* getFundingRoundResponder({ payload }) {
    try {
        const query_string = compileQueryString(payload.qs);
        const { data } = yield call(orderbook.getFundingRound, payload.root_path, payload.sub_path, payload.company_identifier, query_string, payload);

        yield put(getFundingRound.success(data));
    } catch (e) {
        yield handleCatchSagaError(e, 'orderbookGetFundingRoundResponder');
        yield put(getFundingRound.failure(e));
    } finally {}
}

function* getHistoricalSecondaryPricingResponder({ payload }){
    try{
        const query_string = compileQueryString(payload.qs);
        const { data } = yield call(orderbook.getHistoricalSecondaryPricing, payload.root_path, payload.sub_path, payload.company_identifier, query_string, payload);
        yield put(getHistoricalSecondaryPricing.success(data));
    } catch(e){
        yield handleCatchSagaError(e, 'orderbookgetHistoricalSecondaryPricing')
        yield put(getHistoricalSecondaryPricing.failure(e));
    } finally {}
}

function* getReferencePricingResponder({ payload }){
    try{
        const query_string = compileQueryString(payload.qs);
        const { data } = yield call(orderbook.getReferencePricing, payload.root_path, payload.sub_path, payload.company_identifier, query_string, payload);
        yield put(getReferencePricing.success(data));
    } catch(e){
        yield handleCatchSagaError(e, 'getReferencePricingResponder')
        yield put(getReferencePricing.failure(e));
    } finally {}
}

function* getLiveOffersResponder({ payload }){
    try{
        const query_string = compileQueryString(payload.qs);
        const { data } = yield call(orderbook.listLiveOffer, payload.root_path, payload.sub_path, payload.company_identifier, query_string, payload);
        yield put(getLiveOffers.success(data));
    } catch(e){
        yield handleCatchSagaError(e, 'orderbookgetLiveOffersResponder')
        yield put(getLiveOffers.failure(e));
    } finally {}
}

function* getLiveDemandResponder({ payload }){
    try{
        const query_string = compileQueryString(payload.qs);
        const { data } = yield call(orderbook.listLiveDemand, payload.root_path, payload.sub_path, payload.company_identifier, query_string, payload);
        yield put(getLiveDemand.success(data));
    } catch(e){
        yield handleCatchSagaError(e, 'orderbookgetLiveDemandResponder');
        yield put(getLiveDemand.failure(e));
    } finally {}
}

function* getCompanyVerticalsResponder({ payload }){
    try{
        const { public_url } = payload;
        const root_path = public_url ? 'v2/orderbook/public': 'v2/orderbook'
        const query_string = compileQueryString(payload.qs);
        const { data } = yield call(orderbook.getVerticals, root_path, query_string, payload);
        yield put(getCompanyVerticals.success(data));
    } catch(e){
        yield handleCatchSagaError(e, 'orderbookgetCompanyVerticalsResponder');
        yield put(getCompanyVerticals.failure(e));
    } finally{}
}


function* getSimilarCompanyResponder({ payload }){
    try{
        const { public_url } = payload;
        const root_path = public_url ? 'v2/orderbook/public' : 'v2/orderbook'
        const query_string = compileQueryString(payload.qs);
        const { data } = yield call(orderbook.getSimilarCompany, root_path, query_string, payload);
        yield put(getSimilarCompany.success(data));
    } catch(e){
        yield handleCatchSagaError(e, 'orderbookgetSimilarCompanyResponder');
        yield put(getSimilarCompany.failure(e));
    } finally {}
}

function* toggleWatchListResponder({ payload }){
    try{
        const { data } = yield call(watch_list.toggleWatchList, payload);
        yield put(toggleWatchList.success(data));
    } catch(e) {
        yield handleCatchSagaError(e, 'orderbookCompToggleWatchlistResponder');
        yield put(toggleWatchList.failure(e));
    } finally {}
}

function* getPermissionedDealResponder({ payload }){
    try{
        const { data } = yield call(orderbook.getPermissionedDeal, payload.company_id, payload);
        yield put(getPermissionedDeal.success(data));
    } catch(e){
        yield handleCatchSagaError(e, 'orderbookgetPermissionedDealResponder');
        yield put(getPermissionedDeal.failure(e));
    } finally {}
}

function* getLiveOffersWatchlistResponder({ payload }){
    try{
        //temporary api calling
        const query_string = compileQueryString(payload.qs);
        const { data } = yield call(orderbook.getLiveOffersWatchlist, payload.root_path, payload.sub_path, payload.company_identifier, query_string, payload);
        yield put(getLiveOffersWatchlist.success(data));
    } catch(e){
        yield handleCatchSagaError(e, 'orderbookgetLiveOffersWatchlistResponder')
        yield put(getLiveOffersWatchlist.failure(e));
    } finally {}
}

function* getLiveDemandWatchlistResponder({ payload }){
    try{
        //temporary api calling
        const query_string = compileQueryString(payload.qs);
        const { data } = yield call(orderbook.getLiveDemandWatchlist, payload.root_path, payload.sub_path, payload.company_identifier, query_string, payload);
        yield put(getLiveDemandWatchlist.success(data));
    } catch(e){
        yield handleCatchSagaError(e, 'orderbookgetLiveDemandWatchlistResponder');
        yield put(getLiveDemandWatchlist.failure(e));
    } finally {}
}

function* getRiskDisclosureResponder({ payload }){
    try{
        const { public_url } = payload;
        const identifier_key = public_url ? 'token' : 'company_id';
        const { data } = yield call(orderbook.getRiskDisclosure, payload.root_path, payload.sub_path, `?identifier=deal-share-agreement&single=true&${identifier_key}=${payload.company_identifier}`, payload);
        const response_payload = data.content_text ? JSON.parse(data.content_text) : { "content": [], subject: '' };
        yield put(getRiskDisclosure.success(response_payload));
    } catch(e){
        yield handleCatchSagaError(e, 'orderbookgetRiskDisclosureResponder');
        yield put(getRiskDisclosure.failure(e));
    }
}

function* shareDealResponder({ payload }){
    try{
        const { data } = yield call(orderbook.shareDeal, payload);
        yield put(shareDeal.success(data));
    } catch(e){
        yield handleCatchSagaError(e, 'orderbookshareDealResponder');
        yield put(shareDeal.failure(e));
    }
}

function* getSharedUserResponder({ payload }){
    try{
        const query_string = compileQueryString(payload.qs);
        const { data } = yield call(orderbook.getSharedUser, 'v2/orderbook', query_string, payload);
        yield put(getSharedUser.success(data));
    } catch(e){
        yield handleCatchSagaError(e, 'orderbookgetSharedUserResponder');
        yield put(getSharedUser.failure(e))
    }
}

function* addCountResponder({ payload }){
    try{
        const { data } = yield call(orderbook.addCount, 'v2/orderbook', payload);
        yield put(addCount.success(data));
    } catch(e){
        yield handleCatchSagaError(e, 'addCountResponder');
        yield put(addCount.failure(e));
    }
}

function* getVciHistoricalRevenueResponse({ payload }){
    try{
        const { company_id, token } = payload;
        const { data } = token ? yield call(orderbook.getPublicVciHistoricalRevenue, token, payload)
                               :  yield call(orderbook.getVciHistoricalRevenue, company_id, payload);
        yield put(getVciHistoricalRevenue.success(data));
    } catch(e){
        yield handleCatchSagaError(e, 'getVciHistoricalRevenueResponse');
        yield put(getVciHistoricalRevenue.failure(e))
    }
}

function* getVciCapTableResponse({ payload }){
    try{
        const { company_id, token } = payload;
        const { data } = token ? yield call(orderbook.getPublicVciCapTable, token, payload)
                               : yield call(orderbook.getVciCapTable, company_id, payload);
        yield put(getVciCapTable.success(data));
    } catch(e){
        yield handleCatchSagaError(e, 'getVciCapTableResponse');
        yield put(getVciCapTable.failure(e))
    }
}

function* getVciFundingRoundResponse({ payload }){
    try{
        const { company_id, token } = payload;
        const { data } = token ? yield call(orderbook.getPublicVciFundingRound, token, payload)
                               : yield call(orderbook.getVciFundingRound, company_id, payload);
        yield put(getVciFundingRound.success(data));
    } catch(e){
        yield handleCatchSagaError(e, 'getVciFundingRoundResponse');
        yield put(getVciFundingRound.failure(e))
    }
}

function* saga(){
    yield all([
        fork(request_info_saga),
        takeLatest(getCompanyDetail.request, getCompanyDetailResponder),
        takeLatest(getFundingRound.request, getFundingRoundResponder),
        takeLatest(getHistoricalSecondaryPricing.request, getHistoricalSecondaryPricingResponder),
        takeLatest(getReferencePricing.request, getReferencePricingResponder),
        takeLatest(getLiveOffers.request, getLiveOffersResponder),
        takeLatest(getLiveDemand.request, getLiveDemandResponder),
        takeLatest(getCompanyVerticals.request, getCompanyVerticalsResponder),
        takeLatest(getSimilarCompany.request, getSimilarCompanyResponder),
        takeLatest(toggleWatchList.request, toggleWatchListResponder),
        takeLatest(getPermissionedDeal.request, getPermissionedDealResponder),
        takeLatest(getLiveOffersWatchlist.request, getLiveOffersWatchlistResponder),
        takeLatest(getLiveDemandWatchlist.request, getLiveDemandWatchlistResponder),
        takeLatest(getRiskDisclosure.request, getRiskDisclosureResponder),
        takeLatest(shareDeal.request, shareDealResponder),
        takeLatest(getSharedUser.request, getSharedUserResponder),
        takeLatest(addCount.request, addCountResponder),
        takeLatest(getVciHistoricalRevenue.request, getVciHistoricalRevenueResponse),
        takeLatest(getVciCapTable.request, getVciCapTableResponse),
        takeLatest(getVciFundingRound.request, getVciFundingRoundResponse),
    ])
}

export default saga;