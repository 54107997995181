import React from 'react';

import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    Paper,
    makeStyles,
    Typography,
} from '@material-ui/core';

import {
    StyledTableRow,
} from '../../../orderbook/common/Table';

const useStyles = makeStyles((theme) => ({
    container: {
        // minHeight: 50,
        // maxHeight: 50,
    },
    SubTitle: {
        fontSize: 12,
        // textAlign: 'center',
        display: 'block',
    },
    Card: {
        padding: 16,
        marginTop: 16,
        marginBottom: 16,
    },
    ul: {
        padding: 0
    },
    LabelCell: {
        width: '50% !important'
    },
    ValueCell: {
        width: '50% !important'
    }
}));


export default (props) => {

    const classes = useStyles();

    return (
        <React.Fragment>
            <Typography variant="h6">Round Details</Typography>
            <TableContainer component={Paper} className={classes.container}>
                <Table stickyHeader size="small" aria-label="simple table">
                    <TableBody>
                        <StyledTableRow>
                            <TableCell className={classes.LabelCell}>Round Type</TableCell>
                            <TableCell className={classes.ValueCell}>
                                {props.fundraisingParams.deal_type_1 && props.fundraisingParams.deal_type_1.indexOf('Stage VC') > -1 ? props.fundraisingParams.deal_type_1 : "-"}
                                {props.fundraisingParams.deal_type_1 &&
                                    props.fundraisingParams.deal_type_1.indexOf('Stage VC') > -1 &&
                                    props.fundraisingParams.deal_type_2 &&
                                    props.fundraisingParams.deal_type_2.trim() ? " (Series " + props.fundraisingParams.deal_type_2 + ")" : ""}
                            </TableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                            <TableCell className={classes.LabelCell}>Target Valuation</TableCell>
                            <TableCell className={classes.ValueCell}>
                                {props.fundraisingParams.target_valudation
                                    ? props.fundraisingParams.target_valudation > 1000
                                        ? "US$" +
                                        (props.fundraisingParams.target_valudation / 1000).toFixed(
                                            1
                                        ) +
                                        "B"
                                        : "US$" +
                                        props.fundraisingParams.target_valudation.toFixed(0) +
                                        "M"
                                    : "-"}
                            </TableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                            <TableCell className={classes.LabelCell}>Timeline</TableCell>
                            <TableCell className={classes.ValueCell}>{props.fundraisingParams.timeline ? props.fundraisingParams.timeline : '-'}</TableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                            <TableCell className={classes.LabelCell}>Min. Ticket Per Captable Investor</TableCell>
                            <TableCell className={classes.ValueCell}>{props.fundraisingParams.min_ticket ? props.fundraisingParams.min_ticket : '-'}</TableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                            <TableCell className={classes.LabelCell}>Lead Investor</TableCell>
                            <TableCell className={classes.ValueCell}>{props.fundraisingParams.lead_investor ? props.fundraisingParams.lead_investor : '-'}</TableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                            <TableCell className={classes.LabelCell}>Price Per Share</TableCell>
                            <TableCell className={classes.ValueCell}>{props.fundraisingParams.price_per_share ? props.fundraisingParams.price_per_share : '-'}</TableCell>
                        </StyledTableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            <Typography variant="h6" style={{ marginTop: '20px' }}>Goals / parameters</Typography>
            {
                props.fundraisingParams.parameters && props.fundraisingParams.parameters.length > 0 &&
                <ol>
                    {
                        props.fundraisingParams.parameters.map((item, index) => {
                            return <li key={index} className='company-dashboard-subtitle'>{item}</li>
                        })
                    }
                </ol>
            }
        </React.Fragment>
    )
}