import React, { useState, useEffect, Fragment } from 'react';
import { default as RejectReason } from './RejectReason';

import {
    Drawer,
    Paper,
    makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    DrawerPaper: {
        maxWidth: 350,
        padding: 16,
        minHeight: '100%',
        height: 'auto',
        boxShadow: 'none',
    },
    Drawer: {
        maxWidth: 350
    },
}));

export default (props) => {
    const classes = useStyles();
    const [open, setOpen] = useState(props.open ? props.open : false);

    const handleOpen = (should_open) => {
        if (props.handleShowRejectReason) {
            props.handleShowRejectReason(props.index);
        }
        setOpen(should_open);
    }

    useEffect(() => {
        if (props.open != open) setOpen(props.open);
    }, [props.open]);

    return (
        <Fragment>
            <Drawer
                anchor="right"
                className={classes.Drawer}
                open={open}
                onClose={(evt) => handleOpen(false)}>
                <Paper
                    className={classes.DrawerPaper}>
                    <RejectReason
                        index={props.index}
                        handleConfirmRejectReason={props.handleConfirmRejectReason}
                         />
                </Paper>
            </Drawer>
        </Fragment>
    )
}