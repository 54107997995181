import { 
    Grid, 
    Paper,
    Typography,
    Button, 
    Box,
} from '@material-ui/core';
import React, { useState } from 'react';

import {
    numberWithCommas,
    showBlurred,
    longBlurText,
} from '../../../../utils/util';

import {
    withStyles,
} from '@material-ui/core';

import {
    globalFontFamily
} from '../../../styles';

import {
    WatchlistButton,
} from '../../common/components';
import { default as VciRevenueDialog } from './VciRevenueDialog';
import { default as VciCapTableDialog } from './VciCapTableDialog';
import {
    feature_flags,
} from '../../../../config';
import { sendGAEvent } from '../../../../utils/ga';
import vci_logo from '../../../../assets/images/vci_logo.png';

function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
}

const CompanyInfoStyle = theme => ({
    HeaderOne: {
        fontSize: '2.5rem',
        fontFamily: globalFontFamily,
    },
    HeaderBlurred: {
        fontSize: '1.5rem',
        fontFamily: globalFontFamily,
    }
})

export default withStyles(CompanyInfoStyle)((props) => {
    const [showVciRevenue, setShowVciRevenue] = useState(false);
    const [showVciCapTable, setShowVciCapTable] = useState(false);
    const closeRevenueDialog = () => {
        setShowVciRevenue(false)
    }
    const closeCapTableDialog = () => {
        setShowVciCapTable(false)
    }

    let categories = props.company_verticals.map(item => {
        return item.vertical && item.vertical.category ? item.vertical.category : '';
    });

    categories = categories.filter(onlyUnique);

    const { appConfig, loginUserData: { email }, vci_historical_revenue, vci_cap_table } = props;
    const blur_text = longBlurText();
    return (
        <Paper style={{ padding: 16 }}>
            <Grid container spacing={2}>
                <Grid item xs={6} sm={3} className="image-design">
                    <div className={props.logo_url ? "logo-hoder-2" : "logo-hoder-2 wider"}>
                        {
                            props.logo_url && !showBlurred(props.logo_url) ? 
                                <img
                                    className="w-100"
                                    src={ props.logo_url }
                                    alt={props.name}
                                    /> : 
                                <Typography 
                                    className={
                                        showBlurred(props.logo_url) ?
                                        props.classes.HeaderBlurred :
                                        props.classes.HeaderOne} 
                                        variant="h1">{props.name}</Typography>
                        }
                    </div>
                    {
                        !props.sharedPage && !props.public_mode && feature_flags.feature_is_available(
                            appConfig,
                            feature_flags.watchlist_flags.Watchlist.flag_name,
                            feature_flags.watchlist_flags.Watchlist.attributes.add_remove_watchlist,
                            email
                        ) ? <span onClick={(evt) => props.handleToggleWatchList(evt, { company_id: props.id, watchlist: props.watchlist })}>
                                <WatchlistButton
                                    active_watchlist={props.watchlist}
                                    />
                            </span> : ''
                    }
                </Grid>
                <Grid item  xs={6} sm={1}>
                    <span className="company-info-label">Founded</span>
                    <span className="smallText orderbook">
                        {
                            showBlurred(props.founded) ?
                                <span className="blurred">{props.founded}</span> :
                            props.founded
                        }
                    </span>
                </Grid>
                <Grid item  xs={6} sm={1}>
                    <span className="company-info-label">Team Size</span>
                    <span className="smallText orderbook">
                        {
                            props.team_size ?
                                showBlurred(props.team_size) ?
                                    <span className="blurred">{props.team_size}</span> :
                                numberWithCommas(props.team_size, 0) : ''}
                    </span>
                </Grid>
                <Grid item xs={6} sm={2}>
                    <span className="company-info-label">Total Raised(US$M)</span>
                    <span className="smallText orderbook">
                        {
                            props.total_raised ?
                                showBlurred(props.total_raised) ?
                                    <span className="blurred">{props.total_raised}</span> :
                                `$${numberWithCommas(props.total_raised, 0)}M` : 
                            ' - '
                        }
                    </span>
                </Grid>
                <Grid item xs={6} sm={2}>
                    <span className="company-info-label">Headquarter Country</span>
                    <span className="smallText orderbook">
                        {
                            props.headquarter_country ?
                                showBlurred(props.headquarter_country) ?
                                    <span className="blurred">{props.headquarter_country}</span> :
                                props.headquarter_country : ' - '
                        }
                    </span>
                </Grid>
                <Grid item xs={6} sm={2}>
                    <span className="company-info-label">Website</span>
                    <span className="smallText orderbook">
                        {
                            props.website ?
                                showBlurred(props.website) ?
                                    <span className="blurred">{props.website}</span> :
                                <a href={props.website.indexOf('http') > -1 ? props.website : 'http://'+props.website} target="_blank">{props.website}</a>
                                : ''
                        }
                    </span>
                </Grid>
                {
                    feature_flags.feature_is_available(
                        appConfig,
                        feature_flags.flags.VciIntegration.flag_name,
                        feature_flags.flags.VciIntegration.attributes.vci_integration,
                        email
                    ) && props.vci && 
                    <Grid item container xs={12} spacing={2}>
                        <Grid item component={Box} sm={3} display={{ xs: "none", sm: "block" }} />
                        
                        <Grid item  xs={6} sm={'auto'}>
                            <Button className="btn-company-vci" onClick={() => {
                                props.handleClickVciFundingRound()
                                sendGAEvent({
                                    type:"view_vci_valuation_data",
                                    data:{
                                        company_id: props.id,
                                    }
                                })
                            }}>
                                <span>Valuation Data</span>
                            </Button>
                        </Grid>
                        <Grid item  xs={6} sm={'auto'}>
                            <Button className="btn-company-vci" onClick={() => {
                                setShowVciRevenue(true)
                                sendGAEvent({
                                    type:"view_vci_historical_revenue",
                                    data:{
                                        company_id: props.id,
                                    }
                                })
                            }} 
                                disabled={!(vci_historical_revenue !== undefined && vci_historical_revenue.resultCount !== 0)}>
                                <span>Historical Revenue</span>
                            </Button>
                        </Grid>
                        <Grid item  xs={6} sm={'auto'}>
                            <Button className="btn-company-vci" onClick={() => {
                                setShowVciCapTable(true)
                                sendGAEvent({
                                    type:"view_vci_cap_table",
                                    data:{
                                        company_id: props.id,
                                    }
                                })
                            }}
                                disabled={!(vci_cap_table !== undefined && vci_cap_table.resultCount !== 0)}>
                                <span>Cap Table</span>
                            </Button>
                        </Grid>
                        <Grid item xs={6} sm={'auto'}>
                            <div className="powered-by-vci">
                                <p>Powered by <a href="https://www.venturecapinsights.com"  target="_blank" >
                                    <img style={{background: '#1287ff', width: 60 }}
                                        src={vci_logo} alt="Venture Capital Insight"/>
                                </a></p>
                                
                            </div>
                            
                        </Grid>
                    </Grid>
                }
                <Grid item xs={12}>
                    <span className="company-info-label orderbook">{ categories.join(", ") }</span>
                </Grid>
                <Grid item xs={12} container>
                    <Grid item xs={12}>
                        <span className="company-info-label orderbook">Description</span>
                        <span className="smallText orderbook">
                            {
                                showBlurred(props.description) ?
                                    <span className="blurred">{blur_text}</span> :
                                    props.description
                            }
                        </span>
                    </Grid>
                </Grid>
            </Grid>
            {
                props.similar_companies.resultList.length ? 
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <span className="company-info-label orderbook" style={{ marginBottom: 5 }}>Similar Company Based on Verticals</span>
                            {
                                props.similar_companies.resultList.map((item, index) => {
                                    return (
                                        <Button key={index} href={(props.public_mode || props.sharedPage)? '' : `/orderbook/company-detail/${item.id}`} className="btn-similar-company">
                                            {/* {item.logo_url ? <img src={item.logo_url} /> : ''} */}
                                            <span>{item.name}</span>
                                        </Button>
                                    )
                                })
                            }
                        </Grid>
                    </Grid> : ''
            }

            <VciRevenueDialog 
                open = {showVciRevenue}
                closeRevenueDialog={closeRevenueDialog}
                {...vci_historical_revenue}
                />
            
            <VciCapTableDialog
                open = {showVciCapTable}
                closeCapTableDialog={closeCapTableDialog}
                {...vci_cap_table}
                />
        </Paper>
        )
});
