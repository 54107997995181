import {
    CreateApiAction as _createApiAction,
} from '../../../utils';

const key='BOOST_PIPELINE';

const CreateApiAction = _createApiAction(key);
const getFundraisingsByUser = CreateApiAction('GET_FUNDRAISINGS_BY_USER');

export {
    getFundraisingsByUser,
}
