import { connect } from 'react-redux';
import { default as Company } from './Company';

import {
    getCompanyDetail,
    getFundingRound,
    getHistoricalSecondaryPricing,
    getReferencePricing,
    getLiveOffers,
    getLiveDemand,
    getCompanyVerticals,
    getSimilarCompany,
    toggleWatchList,
    clearWatchlistMessage,
    getPermissionedDeal,
    getLiveOffersWatchlist,
    getLiveDemandWatchlist,
    getRiskDisclosure,
    confirmRiskDisclosure,
    shareDeal,
    getVciHistoricalRevenue,
    getVciCapTable,
    getVciFundingRound,
} from './Action';

import {
    CollapseSidebar,
} from '../../root/Action';
import {
    ValidateUserInBackground
} from '../../login/Action';
export default connect(
    state => ({
        company_detail: state.orderbookCompany.reducer.company_detail,
        funding_rounds: state.orderbookCompany.reducer.funding_rounds,
        historical_pricing: state.orderbookCompany.reducer.historical_pricing,
        reference_pricing: state.orderbookCompany.reducer.reference_pricing,
        live_offers: state.orderbookCompany.reducer.live_offers,
        live_demand: state.orderbookCompany.reducer.live_demand,
        collapseSidebar: state.root.reducer.collapseSidebar,
		loginUserData: state.login.reducer.loginUserData,
        company_verticals: state.orderbookCompany.reducer.company_verticals,
        similar_companies: state.orderbookCompany.reducer.similar_companies,
        toggle_watchlist: state.orderbookCompany.reducer.toggle_watchlist,
        permission_deal: state.orderbookCompany.reducer.permission_deal,
        appConfig: state.root.reducer.appConfig,
        live_offers_watchlist: state.orderbookCompany.reducer.live_offers_watchlist,
        live_demand_watchlist: state.orderbookCompany.reducer.live_demand_watchlist,
        shareDealResponse: state.orderbookCompany.reducer.shareDealResponse,
        riskDisclosureConfirmed: state.orderbookCompany.reducer.riskDisclosureConfirmed,
        riskDisclosureResponse: state.orderbookCompany.reducer.riskDisclosureResponse,
        vci_historical_revenue: state.orderbookCompany.reducer.vci_historical_revenue,
        vci_cap_table: state.orderbookCompany.reducer.vci_cap_table,
        vci_funding_rounds: state.orderbookCompany.reducer.vci_funding_rounds,
    }),
    dispatch => ({
        getCompanyDetail: payload => dispatch(getCompanyDetail.request(payload)),
        getFundingRound: payload => dispatch(getFundingRound.request(payload)),
        getHistoricalSecondaryPricing: payload => dispatch(getHistoricalSecondaryPricing.request(payload)),
        getReferencePricing: payload => dispatch(getReferencePricing.request(payload)),
        getLiveOffers: payload => dispatch(getLiveOffers.request(payload)),
        getLiveDemand: payload => dispatch(getLiveDemand.request(payload)),
        CollapseSidebar: (payload) => dispatch(CollapseSidebar(payload)),
        getCompanyVerticals: (payload) => dispatch(getCompanyVerticals.request(payload)),
        getSimilarCompany: (payload) => dispatch(getSimilarCompany.request(payload)),
        toggleWatchList: (payload) => dispatch(toggleWatchList.request(payload)),
        getPermissionedDeal: (payload) => dispatch(getPermissionedDeal.request(payload)),
        clearWatchlistMessage: () => dispatch(clearWatchlistMessage()),
        ValidateUserInBackground: (payload) => dispatch(ValidateUserInBackground.request(payload)),
        getLiveOffersWatchlist: payload => dispatch(getLiveOffersWatchlist.request(payload)),
        getLiveDemandWatchlist: payload => dispatch(getLiveDemandWatchlist.request(payload)),
        getRiskDisclosure: payload => dispatch(getRiskDisclosure.request(payload)),
        confirmRiskDisclosure: () => dispatch(confirmRiskDisclosure()),
        shareDeal: (payload) => dispatch(shareDeal.request(payload)),
        getVciHistoricalRevenue: payload => dispatch(getVciHistoricalRevenue.request(payload)),
        getVciCapTable: payload => dispatch(getVciCapTable.request(payload)),
        getVciFundingRound: payload => dispatch(getVciFundingRound.request(payload)),
    })
)(Company);