import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { Button } from "@material-ui/core";
import PriorityHighIcon from "@material-ui/icons/PriorityHigh";
// import MultiSlider from "multi-slider";
import Slider from '@material-ui/core/Slider';

function hslPalette(n, s, l) {
  var c = [];
  for (var i = 0; i < n; ++i) {
    c.push(
      "hsl(" + ~~((255 * i) / n) + "," + (s || "50%") + "," + (l || "50%") + ")"
    );
  }
  return c;
}

class RangeDoubleSide extends Component {
  state = {};

  constructor(props) {
    super(props);
  }

  componentDidMount() {}

  onChange = (values) => {
    this.setState({
      values: values,
    });
    if (this.props.onChange) {
      this.props.onChange(values);
    }
  };

  render() {
    return (
      <div>
        <div style={{ width: "100%",marginTop:10,marginBottom:10 }}>
          {/* <MultiSlider
            trackSize={this.props.trackSize ? this.props.trackSize : "6px"}
            handleSize={this.props.handleSize ? this.props.handleSize : "8px"}
            onChange={this.onChange}
            colors={
              this.props.colors
                ? this.props.colors
                : ["#FFC50D", "#FFC50D", "#FFC50D"]
            }
            length={2}
            defaultValues={this.props.defaultValues}
            bg={this.props.bg ? this.props.bg : "#FFC50D"}
          /> */}
          <Slider
            disabled defaultValue={30}
          />
        </div>
      </div>
    );
  }
}

export default RangeDoubleSide;
