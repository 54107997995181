import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import classNames from 'classnames';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import WarningIcon from '@material-ui/icons/Warning';
import Grid from '@material-ui/core/Grid';
import green from '@material-ui/core/colors/green';
import amber from '@material-ui/core/colors/amber';
import {
	Portal
} from '@material-ui/core';

const variantIcon = {
  	success: CheckCircleIcon,
  	warning: WarningIcon,
	error: ErrorIcon,
  	info: InfoIcon,
};

const styles1 = theme => ({
  	success: {
		backgroundColor: green[600],
  	},
  	error: {
		backgroundColor: theme.palette.error.dark,
  	},
  	info: {
		backgroundColor: theme.palette.primary.dark,
  	},
  	warning: {
		backgroundColor: amber[700],
  	},
  	icon: {
		fontSize: 20,
  	},
  	iconVariant: {
		opacity: 0.9,
		marginRight: theme.spacing(1),
  	},
  	message: {
		display: 'flex',
		alignItems: 'center',
  	},
});

function MySnackbarContent(props) {
  	const {
			classes,
			className,
			message,
			onClose,
			variant,
			...other
  	} = props;
  	const Icon = variantIcon[variant];

  	return (
		<SnackbarContent
			className={classNames(classes[variant], className)}
			aria-describedby="client-snackbar"
			message={(
				<span id="client-snackbar" className={classes.message}>
				<Icon className={classNames(classes.icon, classes.iconVariant)} />
					{message}
				</span>
			)}
			action={[
				<IconButton
					key="close"
					aria-label="Close"
					color="inherit"
					className={classes.close}
					onClick={onClose}>
				<CloseIcon className={classes.icon} />
				</IconButton>,
			]}
			{...other}
		/>
  	);
}

// MySnackbarContent.propTypes = {
//   	classes: PropTypes.object.isRequired,
//   	className: PropTypes.string.isRequired,
//   	message: PropTypes.node.isRequired,
//   	onClose: PropTypes.func,
//   	variant: PropTypes.oneOf(['success', 'warning', 'error', 'info']).isRequired,
// };

// MySnackbarContent.defaultProps = {
//   	onClose: () => { }
// };

const MySnackbarContentWrapper = withStyles(styles1)(MySnackbarContent);

const styles = theme => ({
  	snackbar: {
		margin: theme.spacing(1),
		zIndex: 9999
  	},
  	divider: {
		margin: `${theme.spacing(3)}px 0`,
  	},
  	margin: {
		margin: theme.spacing(1)
  	}
});

const action = (
  	<Button color="secondary" size="small">
		Action
  	</Button>
);

class StyledNotif extends React.Component {
  	state = {
		openStyle: true,
  	};

  	handleClickStyle = () => {
		this.setState({ openStyle: true });
  	};

  	handleCloseStyle = (event, reason) => {
		if (reason === 'clickaway') {
	  		return;
		}
		this.setState({ openStyle: false });
  	};

  	render() {
		const {
			classes,
			openStyle,
			handleSnackbarClose,
			type,
			message,
			upTime,
			anchorOrigin
		} = this.props;

		return (

			<Portal>
				<Snackbar
					anchorOrigin={anchorOrigin ? anchorOrigin : {
						vertical: 'top',
						horizontal: 'right',
						}}
					open={openStyle}
					autoHideDuration={upTime ? upTime : 2000}
					onClose={handleSnackbarClose}
					style={{
						zIndex: 2000
					}}
					>
					<MySnackbarContentWrapper
						onClose={handleSnackbarClose}
						variant={type}
						message={<span dangerouslySetInnerHTML={{ __html: message }} ></span>}
						/>
				</Snackbar>
			</Portal>

		);
  	}
}

export default withStyles(styles)(StyledNotif);
// export default StyledNotif;
