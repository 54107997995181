import { combineReducers } from "redux";
import {
	getPipeline,
	getDeal,
	getMoreDeal,
	getVerticalsList,
	getRegionList,
	getDealTimeline,
	getGpCompany,
	getPermissionedDeal,
	clearWatchlistMessage,
} from "./Action";
import {
    createWatchlist,
} from '../../common/watchlist/Action';
import { resetSnackBar } from "../../account/Action";

import { default as InitialState } from "./InitialState";

function updateWatchListItem(wealth_list, company_id){
    const new_wealth_list = wealth_list.map((item, index) => {
        item.watchlist = item.company_id == company_id ? !item.watchlist : item.watchlist;
        return item;
    });
    return new_wealth_list;
}

export default combineReducers({
	reducer(state = InitialState, action) {
		switch (action.type) {
			case "UPDATE_PROPS":
				return Object.assign({}, state, {
					...action.payload,
				});
			case getPipeline.request.toString():
				return Object.assign({}, state, {
					pipelineResponse: []
				});
			case getPipeline.success.toString():
				return Object.assign({}, state, {
					pipelineResponse: action.payload,
				});
			case getPipeline.failure.toString():
				return state;
			case getDeal.request.toString():
				return Object.assign({}, state, {
					dealResponse: {
						resultCount: 0,
						resultList: []
					},
					deal_loading: true,
					no_more_deal_data: false,
				});
			case getDeal.success.toString():
				return Object.assign({}, state, {
					dealResponse: action.payload,
					deal_loading: false,
					no_more_deal_data: false,
				});
			case getDeal.failure.toString():
				return Object.assign({}, state, {
                    deal_loading: true,
					no_more_deal_data: false,
                });
			case getMoreDeal.request.toString():
			case getMoreDeal.failure.toString():
				return Object.assign({}, state, {
                    deal_loading: true,
					no_more_deal_data: false,
                });
			case getMoreDeal.success.toString():
				return Object.assign({}, state, {
                    dealResponse: {
                        resultCount: state.dealResponse.resultCount + action.payload.resultCount,
                        resultList: state.dealResponse.resultList.concat(action.payload.resultList),
                    },
					deal_loading: false,
					no_more_deal_data: action.payload.resultCount === 0,
                });
			case resetSnackBar.toString():
				return Object.assign({}, state, {
					toggleWatchListError: false,
					toggleWatchListMessage: "",
				});
			case getRegionList.success.toString():
				return Object.assign({}, state, {
					regionListResponse: action.payload,
				});
			case getRegionList.failure.toString():
				return state;
			case getVerticalsList.success.toString():
				return Object.assign({}, state, {
					verticalsListResponse: action.payload,
				});
			case getVerticalsList.failure.toString():
				return state;
			case getDealTimeline.request.toString():
			case getDealTimeline.failure.toString():
				return Object.assign({}, state, {
					dealTimeline: []
				});
			case getDealTimeline.success.toString():
				return Object.assign({}, state, {
					dealTimeline: action.payload
				});
			case createWatchlist.success.toString():
                return Object.assign({}, state, {
                    toggle_watchlist: {
                        error: false,
                        message: action.payload.message
                    },
					dealResponse: {
                        resultCount: state.dealResponse.resultCount,
                        resultList: updateWatchListItem(state.dealResponse.resultList, action.payload.company_id),
                    },
                });
			case clearWatchlistMessage.toString():
                return Object.assign({}, state, {
                    toggle_watchlist: {
                        error: false,
                        message: ''
                    }
                });
			case getGpCompany.success.toString():
				return Object.assign({}, state, {
					gpCompanyResponse: action.payload,
				});
			case getGpCompany.failure.toString():
				return state;
			case getPermissionedDeal.request.toString():
			case getPermissionedDeal.failure.toString():
				return Object.assign({}, state, {
					permissioned_deals: {}
				});
			case getPermissionedDeal.success.toString():
				return Object.assign({}, state, {
					permissioned_deals: action.payload
				});
			default:
				return state;
		}
	},
});
