import { 
    makeStyles,  
    Table,
    TableCell, 
    TableRow, 
    withStyles 
} from "@material-ui/core";

import {
    ReScaleForDeviceScale
} from '../../../utils/util';

import { 
    globalBodyFont, 
    global150BodyFont,
    globalFontFamily,
} from "../../styles";

export const ControlledFixedTable = withStyles((theme) => ({
    root: {
        '&.header-fix': {
            position: 'fixed',
            top: 64,
            zIndex: 10,
            width: 'calc(100% - 66px)',
            [theme.breakpoints.up('md')]: {
                width: 'calc((100% - 170px) * 0.58333333 - 56px)',
                '&.narrow': {
                    width: 'calc((100% - 64px) * 0.58333333 - 56px)',
                },
            }
        },
        '&.header-top-fix': {
            position: 'fixed',
            top: 0,
            zIndex: 10,
            width: 'calc(100% - 66px)',
            [theme.breakpoints.up('md')]: {
                width: 'calc((100% - 170px) * 0.58333333 - 56px)',
                '&.narrow': {
                    width: 'calc((100% - 64px) * 0.58333333 - 56px)',
                },
            }
        }
    }
}))(Table);

export const HeaderTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.primary.dark,
        color: theme.palette.common.white,
        fontSize: ReScaleForDeviceScale(1.5, global150BodyFont, globalBodyFont),
        textAlign: 'left',
        // padding: '6px 2px 6px 5px !important',
        fontFamily: globalFontFamily,
    },
}))(TableCell);

export const TighterHeaderTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.primary.dark,
        color: theme.palette.common.white,
        fontSize: ReScaleForDeviceScale(1.5, global150BodyFont, globalBodyFont),
        textAlign: 'left',
        padding: '6px 2px 6px 5px !important',
        fontFamily: globalFontFamily,
    },
}))(TableCell);

export const TighterTableCell = withStyles((theme) => ({
    root: {
        padding: '6px 2px 6px 5px !important',
        fontFamily: globalFontFamily,
    }
}))(TableCell);

export const TighterClickableTableCell = withStyles((theme) => ({
    root: {
        padding: '6px 2px 6px 5px !important',
        fontFamily: globalFontFamily,
    }
}))(TableCell);

export const ClickableTableCell = withStyles((theme) => ({
    root: {
        // padding: '6px 2px 6px 5px !important',
        fontFamily: globalFontFamily,
    }
}))(TableCell);

export const StyledHoverTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
        '& .MuiTableCell-body': {
            fontSize: ReScaleForDeviceScale(1.5, global150BodyFont, globalBodyFont),
            textAlign: 'left',
            '&.company-name': {
                textAlign: 'left',
            },
            '&.size-column': {
                textAlign: 'left',
            },
            '&.watchlist': {
                width: 30
            }
        },
        '& .MuiTableCell-head': {
            '&.company-name': {
                textAlign: 'left',
            },
            '&.size-column': {
                textAlign: 'left',
            },
            '&.watchlist': {
                width: 30
            }
        },
        '&:hover': {
            backgroundColor: 'rgba(213,226,253,1)',
            '& .MuiTableCell-body': {
                color: theme.palette.common.black,
            }
        }
    }
}))(TableRow);

export const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
        '& .MuiTableCell-body': {
            fontSize: ReScaleForDeviceScale(1.5, global150BodyFont, globalBodyFont),
            textAlign: 'left',
            '&.company-name': {
                textAlign: 'left',
            },
            '&.size-column': {
                textAlign: 'left',
            },
            '&.watchlist': {
                width: 30
            }
        },
        '& .MuiTableCell-head': {
            '&.company-name': {
                textAlign: 'left',
            },
            '&.size-column': {
                textAlign: 'left',
            },
            '&.watchlist': {
                width: 30
            }
        }
    },
}))(TableRow);



export const TableLabelStyle = makeStyles((theme) => ({
    container: {
        // minHeight: "500",
        position: 'relative',
        // maxHeight: "100%",
    },
    FirmOrder: {
        display: 'inline-block',
        '& span': {
            display: 'inline-block',
            fontSize: ReScaleForDeviceScale(1.5, global150BodyFont, globalBodyFont),
            background: 'green',
            color: '#FFFFFF',
            padding: '5px 10px',
            fontWeight: 'bolder',
        }
    },
    ThirtyDay: {
        display: 'inline-block',
        '& span': {
            display: 'inline-block',
            fontSize: ReScaleForDeviceScale(1.5, global150BodyFont, globalBodyFont),
            color: 'green',
            padding: '5px 10px',
            fontWeight: 'bolder',
        }
    },
    SixtyDay: {
        display: 'inline-block',
        '& span': {
            display: 'inline-block',
            fontSize: ReScaleForDeviceScale(1.5, global150BodyFont, globalBodyFont),
            color: 'orange',
            padding: '5px 10px',
            fontWeight: 'bolder',
        }
    }
}));

const msInDay = 1000 * 60 * 60 * 24;

export const recentCellClasses = () => {
    const cellStyles = makeStyles({
        cellFirmGreen: {
            backgroundColor: "#29a329",
            color: "white"
        },
        cellGreen: {
            color: "green"
        },
        cellOrange: {
            color: "orange"
        }
    })();
    const dateClassName = (date, firm=false) => {
        const dealDate = new Date(date);
        const diff = (new Date() - dealDate) / msInDay;
        // console.log(dealDate + " " + diff);
        if ( firm ){
            return cellStyles.cellFirmGreen;
        // } else if (diff < 30) {
        //     // console.log("< 30");
        //     return cellStyles.cellGreen;
        // } else if (diff < 60) {
        //     // console.log("< 60");
        //     return cellStyles.cellOrange;
        } else {
            return "";
        }
    };

    return {
        dateClassName
    };
};