import React from 'react';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Button,
    Checkbox,
    FormGroup,
    FormControlLabel,
} from '@material-ui/core';

export default (props) => (
    <Dialog
        open={props.open}
        onClose={props.onClose}
        fullWidth
        maxWidth={'md'}
        >
        <DialogTitle>Notification Preferences</DialogTitle>
        <DialogContent>            
            <div className="row">
                <div className="col col-12">
                    <FormGroup>
                        <div className="row">
                            <div className="col col-12">
                                <h6>Platform / Market updates</h6>
                                <div className="row">
                                    <div className="col col-12">
                                        <FormControlLabel
                                            control={
                                                <Checkbox 
                                                    checked={props.notification_preferences.weekly_update}
                                                    onChange={e => props.onNotiValueChange(e)}
                                                    name="weekly_update"
                                                    fullWidth
                                                    />
                                            }
                                            label="Receive weekly update email"
                                            />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col col-12">
                                        <FormControlLabel
                                            control={
                                                <Checkbox 
                                                    checked={props.notification_preferences.product_update}
                                                    onChange={e => props.onNotiValueChange(e)}
                                                    name="product_update"
                                                    fullWidth
                                                    />
                                            }
                                            label="Receive product and portal feature updates from Gateway"
                                            />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col col-12">
                                <h6>Watchlist Companies</h6>
                                <div className="row">
                                    <div className="col col-12">
                                        <span className="watchlist-notification-pref-label">Notification Type&nbsp;</span>
                                        <select
                                            name="email_noti"
                                            onChange={e => props.onNotiSelectChange(e)}
                                            >
                                                {
                                                    props.notification_preferences.email_noti_options.map((item, index) => {
                                                        return item.value == props.notification_preferences.email_noti ?
                                                            <option value={item.value} key={index} selected>{item.label}</option> :
                                                            <option value={item.value} key={index}>{item.label}</option>
                                                    })
                                                }
                                            </select>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col col-12">
                                        <FormControlLabel
                                            control={
                                                <Checkbox 
                                                    checked={props.notification_preferences.all_new_block}
                                                    onChange={e => props.onNotiValueChange(e)}
                                                    name="all_new_block"
                                                    fullWidth
                                                    />
                                            }
                                            label="Notify me on all new buy / sell orders"
                                            />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col col-12">
                                        <FormControlLabel
                                            control={
                                                <Checkbox 
                                                    checked={props.notification_preferences.best_price_movement}
                                                    onChange={e => props.onNotiValueChange(e)}
                                                    name="best_price_movement"
                                                    fullWidth
                                                    />
                                            }
                                            label="Notify me when the best price has changed"
                                            />
                                    </div>
                                </div> 
                            </div>
                        </div>                       
                    </FormGroup>
                </div>
            </div>
        </DialogContent>
        <DialogActions>
            <Button onClick={props.onClose} color="primary">Cancel</Button>
            <Button onClick={e => props.onSubmitNotiPref(e)}>Confirm</Button>
        </DialogActions>
    </Dialog>
)