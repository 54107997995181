import { Loadable } from '../utils';

export const Root = Loadable(
    () => import('./root/ConnectedRoot')
);

export const Login = Loadable(
    () => import('./login/Login')
);

export const LoginCampaign = Loadable(
    () => import('./login/LoginCampaign')
);

export const ForgotPassword = Loadable(
    () => import('./resetPassword/ForgotPassword')
);

export const Pipeline = Loadable(
    () => import('./pipeline/ConnectedPipeline')
);

export const UpdateResetPassword = Loadable(
    () => import('./resetPassword/updatePassword/UpdateResetPassword')
);

export const Disclaimer = Loadable(
    () => import('./login/Disclaimer/ConnectedDisclaimer')
);

export const TermsOfUse = Loadable(
    () => import('./terms_of_use/ConnectedTermsOfUse')
);

export const PrivacyPolicy = Loadable(
    () => import('./privacy_policy/ConnectedPrivacyPolicy')
);

export const ConfidentialityAgreement = Loadable(
    () => import('./confidentiality_agreement/ConnectedConfientialityAgreement')
);

export const RiskDisclosure = Loadable(
    () => import('./risk_disclosure/ConnectedRiskDisclosure')
);

export const ClubPipeline = Loadable(
    () => import('./club_client/pipeline/ConnectedPipeline')
);

export const ClubCompanyDetail = Loadable(
    () => import('./club_client/company/ConnectedCompany')
);

export const OrderbookPipeline = Loadable(
    () => import('./orderbook/pipeline/ConnectedPipeline')
)

export const OrderbookCompany = Loadable(
    () => import('./orderbook/company/ConnectedCompany')
)

export const PublicOrderbookCompany = Loadable(
    () => import('./orderbook/company/ConnectedPublicCompany')
)

export const PlatformGuide = Loadable(
    () => import('./orderbook/platform_guide/ConnectedPlatformGuide')
)

export const OrderbookAgreement = Loadable(
    () => import('./orderbook/agreement/ConnectedAgreement')
)

export const PortfolioHolding = Loadable(
    () => import('./orderbook/portfolio_holding/ConnectedPortfolioHolding')
)

export const OrderbookWatchlist = Loadable(
    () => import('./orderbook/watchlist/ConnectedWatchlist')
)

export const WealthPipeline = Loadable(
    () => import('./wealth/pipeline/ConnectedPipeline')
);

export const WealthCompanyDetail = Loadable(
    () => import('./wealth/company/ConnectedCompany')
);

export const PublicWealthCompanyDetail = Loadable(
    () => import('./wealth/company/ConnectedPublicCompanyDetail')
);

export const WealthTrends = Loadable(
    () => import('./wealth/trends/ConnectedTrends')
);

export const WealthPlatformGuide = Loadable(
    () => import('./wealth/platform_guide/ConnectedPlatformGuide')
);

export const OBWPlatformGuide = Loadable(
    () => import('./ob_w_platform_guide/ConnectedOBWPlatformGuide')
);

export const Dashboard = Loadable(
    () => import('./dashboard/ConnectedDashboard')
);

export const BoostDashboard = Loadable(
    () => import('./boost/dashboard/ConnectedDashboard')
);

export const BoostVetting = Loadable(
    () => import('./boost/vetting/ConnectedVetting')
);

export const BoostPipeline = Loadable(
    () => import('./boost/pipeline/ConnectedPipeline')
);

// export { Root } from './root';
// export { Login } from './login';
// export { ForgotPassword } from './resetPassword';
// export { Pipeline } from './pipeline';
// export { CompanyDetail } from './company';
// export { UpdateResetPassword } from './resetPassword';
// export { Disclaimer } from './login';
// export { TermsOfUse } from './terms_of_use';
// export { PrivacyPolicy } from './privacy_policy';
// export { ConfidentialityAgreement } from './confidentiality_agreement';
// export { RiskDisclosure } from './risk_disclosure';

// export { ClubPipeline, ClubCompanyDetail } from './club_client';