import axios from "axios";
import { GetToken, RemoveToken } from "../utils";
import { GetCurrentUrl } from '../utils/util';
import { RetrieveEmailCampaign } from '../utils';

let alertshown = false;
let shown = false;

const mainInstance = axios.create({
    baseURL: "/api",
});

const makeRequest = (instance) => (method, url, ...params) => {
    // console.log(method, url, ...params);
    
    const non_cached_url = (
        url.indexOf('watchlist=1') > -1 ||
        url.indexOf('/deals/highlight/') > -1 ||
        url.indexOf('/company/') > -1
        ) ? ( url.indexOf("?") > -1 ? url + "&" + Date.now() : url + "?" + Date.now() ) : url;

    instance.interceptors.request.use((config) => {
        // console.log( config );
        const accessToken = GetToken();
        const campaign_payload = RetrieveEmailCampaign();
        if( ( Object.keys(campaign_payload) ).length ){
            if( config.url.indexOf('?') == -1 ){
                config.url += '?';
            } else if (config.url.slice(-1) != "&") {
                config.url += '&';
            }

            for( var key in campaign_payload ){
                if( config.url.indexOf(key) == -1 ){
                    config.url += `${key}=${campaign_payload[key]}&`;
                }
            }
        }
        
        if (accessToken && accessToken.token && 
            url.indexOf('/auth/login') == -1 &&
            url.indexOf('/auth/reset-password') == -1
            ){
                // console.log("adding login token.");
                config.headers.Authorization = `Token ${accessToken.token}`;
        } 
        
        return config;
    });

    instance.interceptors.response.use(
        (response) => {
            return response;
        },
        (error) => {
            // console.log( error );
            if (alertshown) {
                alertshown = false;
            } else if (!shown) {
                alertshown = true;
            }

            if (error && error.response && error.response.status === 401) {
                const accessToken = GetToken();
                // console.log( accessToken )
                // console.log( GetCurrentUrl() );
                if ( alertshown && accessToken && accessToken.email && GetCurrentUrl() != "/" ) {
                    // alert("Please login again.");
                    shown = true;
                }

                setTimeout(() => {
                    alertshown = false;
                    shown = false;
                }, 5000);

                if (accessToken) RemoveToken("authData");
            }

            return Promise.reject(error);
            // return error;
        }
    );

    return instance[method](non_cached_url, ...params);
};

const request = (method, url) => (...params) =>
    makeRequest(mainInstance)(method, url, ...params);


function compileAPIUrl(root_path, sub_path, company_identifier, query_string){
    let url = `/${root_path}${sub_path}`
    const company_id_check = /^\d+$/gi;
    
    if( company_identifier && company_identifier.trim() ){
        url += company_id_check.exec(company_identifier) ? 
                `${company_identifier}/${query_string}` :
                    query_string.trim() ?
                    `${query_string}&token=${company_identifier}` :
                    `?token=${company_identifier}`;
    } else {
        url += query_string;
    }
    
    return url;
}

const logs = {
    logUrl: (...params) => request('post', `/logs/`)(...params),
    getViewSummary: () => request('get', `/logs/view-summary/`)(),
}

const account = {
    req: (...params) => request("post", `/request-account/`)(...params),
};

const auth = {
    SignInWithEmailAndPassword: (...params) =>
        request("post", `/auth/login/`)(...params),
    ValidateUser: (user_id, ...params) =>
        // request("get", `/get-my-user-details/`)(...params),
        request("get", `/user/${user_id}/`)(...params),
    resetPasswordWithEmail: (...params) =>
        request("post", `/auth/reset-password/`)(...params),
    logoutWithEmail: (...params) => request("post", `/auth/logout/`)(...params),
    ConfirmPasswordReset: (...params) =>
        request("post", `/auth/confirm-reset/`)(...params),
    confirmPasswordChange: (...params) =>
        request("post", `/auth/password-change/`)(...params),
    UpdateUserType: (...params) =>
        request("put", `/v2/users/me/types/`)(...params),
};

const companies = {
    getAllCompanies: (query_string, ...param) =>
        request("get", `/company/${query_string}/`)(param),
    getCompanyInvestors: (company_id, ...params) =>
        request("get", `/investor/get-by-company/${company_id}/`)(...params),
    getVerticals: (company_id, ...params) =>
        request("get", `/vertical/get-by-company/${company_id}/`)(...params),
    getFundingRound: (company_id, query_string, ...params) =>
        request("get", `/funding-round/get-by-company/${company_id}/${query_string}`)(...params),
    getLatestFundingRound: (company_id, ...params) =>
        request("get", `/funding-round/latest/${company_id}/`)(...params),
    getOrderbookView: (query_string, ...params) =>
        request("get", `/v2/orderbook/companies/${query_string}`)(...params),
    getSimilarCompany: (query_string, ...params) =>
        request("get", `/company/similar-company/${query_string}`)(...params),
};

const pipeline = {
    getPipeline: (query_string, ...params) =>
        request("get", "/investment-offering/highlight/" + query_string)(...params),
    expressInterest: (...params) =>
        request("post", `/v2/club/interests/`)(...params),
    headquaterCountry: (...params) =>
        request("get", `deals/hq-country/`)(...params),
    verticals: (...params) => request("get", `deals/vertical/`)(...params),
};

const club_pipeline = {
    getPipeline: () => request("get", "/club-deals/")(),
    getMarketIntelligence: (query_string, ...params) =>
        request("get", "/deals/highlight/" + query_string)(...params),
    getWatchList: (query_string, ...params) =>
        request("get", "/deals/highlight/" + query_string)(...params),
    expressInterest: (...params) =>
        request("post", `/v2/club/interests/`)(...params),
    getHeadquaterCountryList: (...params) =>
        request("get", `deals/hq-country/`)(...params),
    getVerticalsList: (...params) => request("get", `deals/vertical/`)(...params),
    getRegion: (...params) =>
        request("get", `/deals/region/`)(...params),
};

const club_company = {
    getCompany: (company_id) =>
        request("get", `/club-deals/get-by-company/${company_id}/`)(),
    getDealLinks: (company_id) =>
        request("get", `/club-deal-links/get-by-company/${company_id}/`)(),
    getDealTimeline: (company_id) =>
        request("get", `/club-deal-timeline/get-by-company/${company_id}/`)(),
    getHistoricalPrice: (company_id) =>
        request("get", `/deals/historical-price/${company_id}/`)(),
};

const watch_list = {
    toggleWatchList: (...params) => request("post", `v2/wealth/watchlists/`)(...params),
    getWatchList: (query_string, ...params) =>
        request("get", "/watchlist/highlight/" + query_string)(...params),
    getVerticals: (query_string, ...params) =>
        request("get", `/watchlist/verticals/${query_string}`)(...params),
    getWatchlistGrp: (root_path,...params) =>
        request('get',  `${root_path}/watchlist-groups/`)(...params),
    createWatchlistGrp: (root_path,...params) =>
        request('post', `${root_path}/watchlist-groups/`)(...params),
    updateWatchlistGrp: (root_path, watchlist_grp_id, ...params) =>
        request('put', `${root_path}/watchlist-groups/${watchlist_grp_id}/`)(...params),
    deleteWatchlistGrp: (root_path, watchlist_grp_id, ...params) =>
        request('delete', `${root_path}/watchlist-groups/${watchlist_grp_id}/`)(...params),
    getWatchlistByCompanyId: (root_path, companyId,...params) =>
        request('get', `${root_path}/watchlist-groups/?company_id=${companyId}`)(...params),
    getWatchlistGrpByGrpId: (root_path, watchlist_grp_id,...params) =>
        request('get', `${root_path}/watchlist-groups/${watchlist_grp_id}/`)(...params),
    toggleWatchListV2: (...params) => request("post", `/v2/orderbook/watchlists/`)(...params),
    getPriceIndex: (root_path, query_string, ...params) =>
        request('get', `${root_path}/deals/price-indices/${query_string}&last_n_day=100`)(...params),
};

const notification = {
    getNotifications: (query_string) => request("get", `/v2/users/me/notifications/${query_string}`)(),
    markNotificationAllRead: () => request("put", `/v2/users/me/notifications/read/`)(),
    markNotificationAllUnRead: () =>
        request("put", `/notification/mark-unread/`)(),
    updateNotificationPreferences: (...params) => request('post', `/user/notification-preferences/`)(...params)
};

const referral = {
    postReferral: (...params) => request("post", `/referral/`)(...params),
};

const employee = {
    getByCompany: (company_id) =>
        request("get", `/employee/get-by-company/${company_id}/`)(),
};

const public_news_article = {
    getByCompany: (company_id) =>
        request("get", `/news-article/get-by-company/${company_id}/`)(),
};

const web_content = {
    getWebContent: (root_path, sub_path, query_string, ...params) =>
        request(
            "get",
            compileAPIUrl(root_path,  `/web-contents/${sub_path}/`, '', query_string)
        )(...params),
    getPIAgreement: (...params) =>
        request(
            "get",
            `/v2/web-contents/wealth-search/?identifier=gateway-pi-agreement&single=1`
        )(...params),
    getTrialPIAgreement: () =>
        request(
            "get",
            `/v2/web-contents/wealth-search/?identifier=trial-gateway-pi-agreement&single=1`
        )(),
    getTermsOfUse: (root_path, sub_path, query_string, ...params) =>
        request(
            "get",
            compileAPIUrl(root_path,  `/web-contents/${sub_path}/`, '', query_string)
        )(...params),
    getPrivacyPolicy: (...params) =>
        request(
            "get",
            `/v2/web-contents/wealth-search/?identifier=gateway-privacy-policy&single=1`
        )(...params),
    getConfidentialityAgreement: () =>
        request(
            "get",
            `/v2/web-contents/wealth-search/?identifier=confidentiality-agreement&single=1`
        )(),
    GetPlatformGuide: (...params) =>
        request(
            'get',
            `/v2/web-contents/wealth-search/?identifier=platform-guide&single=1`
        )(...params),
    GetOrderbookAgreement: (root_path, sub_path, ...params) =>
        request(
            'get',
            compileAPIUrl(root_path, `/web-contents/${sub_path}/`, '', '?identifier=orderbook-agreement&single=1')
        )(...params),
    
    SetAgreementFalse: (user_id, ...params) =>
        request(
            "post",
            `/client-agreement/${user_id}/accept-agreement/`
        )(...params),
    SetUpdatePasswordFalse: (user_id, ...params) =>
        request(
            "post",
            `/client-agreement/${user_id}/force-password-update/`
        )(...params),
    SetAgreementAndPassword: (user_id, ...params) =>
        request(
            "post",
            `/client-agreement/${user_id}/accept-agreement-update-password/`
        )(...params),

    // Main Ad Banner
    getAdBanner1: (...params) =>
        request(
            "get",
            `/web-content/search/?identifier=gateway-portal-banner1`
        )(...params),
};

const health = {
    checkMaintenance: () => request("get", `/maintenance/`)(),
};

const user_message = {
    createUserMessage: (...params) =>
        request("post", `/user-message/`)(...params),
};

const orderbook = {
    getAllCompanies: (root_path, sub_path, company_identifier, query_string, ...param) =>
        request("get", compileAPIUrl(root_path, `/${sub_path}/companies/`, company_identifier, query_string))(...param),
    listLiveOffer: (root_path, sub_path, company_identifier, query_string, ...params) =>
        request("get", compileAPIUrl(root_path, `/${sub_path}/`, company_identifier, query_string))(...params),
    listLiveDemand: (root_path, sub_path, company_identifier, query_string, ...params) =>
        request("get", compileAPIUrl(root_path, `/${sub_path}/`, company_identifier, query_string))(...params),
    getLiveOffersWatchlist: (root_path, sub_path, company_identifier, query_sting, ...params) =>
        request('get', compileAPIUrl(root_path, `/${sub_path}/`, company_identifier, query_sting))(...params),
    getLiveDemandWatchlist: (root_path, sub_path, company_identifier, query_sting, ...params) =>
        request('get', compileAPIUrl(root_path, `/${sub_path}/`, company_identifier, query_sting))(...params),
    // getHistoricalSecondaryPricing: (query_string, ...params) =>
    //     request('get', `/deals/get-weighted-price/${query_string}`)(...params),
    getHistoricalSecondaryPricing: (root_path, sub_path, company_identifier, query_string) =>
        request("get", compileAPIUrl(root_path, `/deals/${sub_path}/`, company_identifier, query_string))(),
    getReferencePricing: (root_path, sub_path, company_identifier, query_string) =>
        request('get', compileAPIUrl(root_path, `/deals/${sub_path}/`, company_identifier, query_string))(), 
    postPlaceOrder: (order_type, query_string, ...params) =>
        request('post', `/v2/orderbook/deals/place-order/${order_type}/${query_string}`)(...params),
    getSearchCompany:  (query_string, ...params) =>
        request('get', `/v2/orderbook/deals/companies/${query_string}`)(...params),
    getPermissionedDeals: (...params) => 
        request('get', `/v2/orderbook/deals/permission-deals/`)(...params),
    getPermissionedDeal: (company_id, ...params) =>
        request('get', `/v2/orderbook/deals/permission-deals/?company_id=${company_id}`)(...params),
    getVerticalCat: (...params) =>
        request('get', `/deals/vertical-cat/`)(...params),
    getLiveOfferCount: () =>
        request("get", `/deals/live-offer-count/`)(),
    getLiveBidCount: () =>
        request("get", `/deals/live-bid-count/`)(),
    requestInformation: (...params) =>
        request("post", `/v2/orderbook/interests/request-info/`)(...params),
    getFundingRound: (root_path, sub_path, company_identifier, query_string, ...params) =>
        request("get", compileAPIUrl(root_path, `/${sub_path}/funding-rounds/`, company_identifier, query_string) )(...params),
    getVerticals: (root_path, query_string, ...params) =>
        request("get", compileAPIUrl(root_path, `/verticals/`, '', query_string))(...params),
    getRiskDisclosure: (root_path, sub_path, query_string, ...params) =>
        request(
            'get',
            compileAPIUrl(root_path, `/web-contents/${sub_path}/`, '', query_string)
        )(...params),
    shareDeal: (...params) =>
        request('post', `/v2/orderbook/company-sharings/`)(...params),
    getSharedUser: (root_path, query_string, ...params) =>
        request('get', `/${root_path}/public/company-sharings/user/${query_string}`)(...params),
    addCount: (root_path, ...params) =>
        request('post', `/${root_path}/public/company-sharings/count/`)(...params),
    getSimilarCompany: ((root_path ,query_string, ...params) =>
        request("get", `/${root_path}/companies/similar-companies/${query_string}`)(...params)),
    interests: (...params) =>
        request("post", `/v2/orderbook/interests/`)(...params),
    getDealMetrics: (...params) =>
        request('get', `/v2/orderbook/deals/metrics/`)(...params),
    getVciHistoricalRevenue: (company_id,...params) =>
        request('get', `/v2/orderbook/companies/${company_id}/historical-revenue/`)(...params),
    getVciCapTable: (company_id,...params) =>
        request('get', `/v2/orderbook/companies/${company_id}/cap-table/`)(...params),
    getVciFundingRound: (company_id,...params) =>
        request('get', `/v2/orderbook/funding-rounds/?company_id=${company_id}&src=vci`)(...params),
    getPublicVciHistoricalRevenue: (token,...params) =>
        request('get', `/v2/orderbook/public/companies/historical-revenue/?token=${token}`)(...params),
    getPublicVciCapTable: (token,...params) =>
        request('get', `/v2/orderbook/public/companies/cap-table/?token=${token}`)(...params),
    getPublicVciFundingRound: (token,...params) =>
        request('get', `/v2/orderbook/public/funding-rounds/?token=${token}&src=vci`)(...params)
}

const portfolio_holding = {
    getPriceIndex: (query_string, ...params) =>
        request("get", `/portfolio-holding/price-index/${query_string}`)(...params),
    getCompanyDetail: (query_string, ...params) =>
        request('get', `/company/detail-by-companies/${query_string}`)(...params),
    getFundingRound: (query_string, ...params) =>
        request('get', `/funding-round/get-by-companies/${query_string}`)(...params),
    postSubmitPortfolio: (...params) =>
        request('post', ``)(...params),
}

const trends = {
    getTrends: (query_string, ...params) =>
        request('get', `/trend/sector/${query_string}`)(...params),
}

const wealth_pipeline = {
    getDeal: (query_string, ...params) =>
        request("get", "/v2/wealth/deals/" + query_string)(...params),
    getWatchList: (query_string, ...params) =>
        request("get", "/deals/highlight/" + query_string)(...params),
    // expressInterest: (...params) =>
    //     request("post", `/express-interest/`)(...params),
    interests: (...params) =>
        request("post", `/v2/wealth/interests/`)(...params),
    expressInterest: (order_type, ...params) =>
        request('post', `/v2/wealth/deals/place-order/${order_type}/`)(...params),
    getVerticalsList: (...params) => request("get", `/deals/vertical-cat/`)(...params),
    getRegion: (...params) =>
        request("get", `/deals/region/`)(...params),
    getDealTimeline: (...params) =>
        request('get', `/v2/wealth/deal-timelines/`)(...params),
    getGpCompany: ( ...params) =>
        request("get", `/v2/wealth/sources/gp-companies/`)(...params),
    getSearchDealCompany: (query_string, ...params) =>
        request('get', `/v2/wealth/deals/companies/${query_string}`)(...params),
    getPermissionedDeal: (...params) => 
        request('get', `/v2/wealth/deals/permission-deals/`)(...params),
};

const api_call = {
    get: (path, query_string, ...params) =>
        request('get', `${path}${query_string}`)(...params)
}


const wealth_company = {
    getCompany: (company_id) =>
        request("get", `/club-deals/get-by-company/${company_id}/`)(),
    getDealLinks: (company_id) =>
        request("get", `/club-deal-links/get-by-company/${company_id}/`)(),
    getDealTimeline: (company_id) =>
        request("get", `/club-deal-timeline/get-by-company/${company_id}/`)(),
    getHistoricalPrice: (path, query_string, ...params) =>
        request("get", `${path}${query_string}`)(...params),
    // getDealOffer: (root_path, company_identifier) =>
    //     request("get", compileAPIUrl(root_path, `/deals/offer/`, company_identifier, ''))(),
    requestInformation: (...params) =>
        request("post", `/express-interest/request-info/`)(...params),
    getDealTimeline: (root_path, sub_path, company_identifier, query_string, ...params) =>
        request('get', compileAPIUrl(root_path, `/${sub_path}/deal-timelines/highlights/`, company_identifier, query_string))(...params),
    // getDealHighLight: (path, query_string, ...params) =>
    //     request('get', `${path}${query_string}`)(...params),
    // getDealType: (path, query_sting, ...params) =>
    //     request('get', `${path}${query_sting}`)(...params),
    // getGPDetail: (path, query_string, ...params) =>
    //     request('get', `${path}${query_string}`)(...params),
    requestWealthInformation: (...params) =>
        request("post", `/v2/wealth/interests/request-info/`)(...params),
    shareDeal: (...params) =>
        request('post', `/v2/wealth/offer-sharings/`)(...params),
    getRiskDisclosure: (root_path, sub_path, query_string, ...params) =>
        request(
            'get',
            compileAPIUrl(root_path, `/web-contents/${sub_path}/`, '', query_string)
        )(...params),
    getLatestFundingRound: (root_path, sub_path, company_identifier, query_string, ...params) =>
        request("get", compileAPIUrl(root_path, `/${sub_path}/funding-rounds/latest/${query_string}`, company_identifier, ''))(...params),
    // listLiveOffer: (root_path, sub_path, company_identifier, query_string, ...params) =>
    //     request("get", compileAPIUrl(root_path, `/${sub_path}/`, company_identifier, query_string))(...params),
    // listLiveDemand: (root_path, sub_path, company_identifier, query_string, ...params) =>
    //     request("get", compileAPIUrl(root_path, `/${sub_path}/`, company_identifier, query_string))(...params),
    // getFundingRound: (root_path, sub_path, company_identifier, query_string, ...params) =>
    //     request("get", compileAPIUrl(root_path, `/${sub_path}/funding-rounds/`, company_identifier, query_string) )(...params),
    getAllCompanies: (path, query_string, ...param) =>
        request("get", `${path}${query_string}`)(...param),
    getVerticals: (path, query_string, ...params) =>
        request('get', `${path}${query_string}`)(...params),
    getLiveOffersWatchlist: (root_path, sub_path, company_identifier, query_sting, ...params) =>
        request('get', compileAPIUrl(root_path, `/${sub_path}/`, company_identifier, query_sting))(...params),
    getLiveDemandWatchlist: (root_path, sub_path, company_identifier, query_sting, ...params) =>
        request('get', compileAPIUrl(root_path, `/${sub_path}/`, company_identifier, query_sting))(...params),
};

const shared_page = {
    getSharedUser: (query_string, ...params) =>
        request('get', `/v2/wealth/public/offer-sharings/user/${query_string}`)(...params),
    postViewCount: (...params) =>
        request('post', `/v2/wealth/public/offer-sharings/count/`)(...params),
}

const access_right = {
    updateAccessRight: (...params) =>
        request('put', `/access-right/update-my-access/`)(...params),
    addCount: (...params) =>
        request('post', `/v2/wealth/public/offer-sharings/count/`)(...params),
}

const boost = {
    getFundraisingParameters: (fundraising_id, query_string, ...params) => 
        request('get', `/v2/boost/fundraisings/${fundraising_id}/parameters/${query_string}`)(...params),
    getGoalSummary: (fundraising_id, query_string, ...params) => 
        request('get', `/v2/boost/fundraisings/${fundraising_id}/goal-summary/${query_string}`)(...params),
    getProspectSummary: (fundraising_id, query_string, ...params) => 
        request('get', `/v2/boost/fundraisings/${fundraising_id}/prospect-summary/${query_string}`)(...params),
    getInvestorSummary: (fundraising_id, query_string, ...params) => 
        request('get', `/v2/boost/fundraisings/${fundraising_id}/investor-summary/${query_string}`)(...params),
    getVettedInvestorList: (fundraising_id, query_string, ...params) => 
        request('get', `/v2/boost/fundraisings/${fundraising_id}/investors/${query_string}`)(...params),
    getProspectInvestorList: (fundraising_id, query_string, ...params) => 
        request('get', `/v2/boost/fundraisings/${fundraising_id}/prospects/${query_string}`)(...params),
    postSubmitInvestorApproval: (fundraising_id, ...params) => 
        request('put', `/v2/boost/fundraisings/${fundraising_id}/approve-prospects/`)(...params),
    getFundraisingsByUser: (...params) =>
        request('get', `/v2/users/me/fundraisings/`)(...params),
    getAllCompanies: (root_path, sub_path, company_identifier, query_string, ...param) =>
        request("get", compileAPIUrl(root_path, `/${sub_path}/fundraisings/`, company_identifier, `company/`, query_string))(...param),
}

export {
    auth,
    pipeline,
    companies,
    web_content,
    account,
    health,
    user_message,
    club_pipeline,
    club_company,
    employee,
    public_news_article,
    referral,
    watch_list,
    notification,
    logs,
    orderbook,
    portfolio_holding,
    trends,
    wealth_pipeline,
    wealth_company,
    shared_page,
    access_right,
    boost,
    api_call,
};
