import React, { Fragment } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import PasswordStrengthMeter from '../resetPassword/updatePassword/PasswordStrengthMeter';
import {
    TextField,
    FormHelperText,
    Typography,
    Button,
    FormControl,
    Grid,
} from '@material-ui/core';
import { passwordRegExp } from './Funcs';

const passwordsMatch = (value, allValues) => {
	if (value !== allValues.get('password')) {
		return 'Passwords dont match';
	}
	return undefined;
};

// const passwordRegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^\&\*\(\)_\-=+\{\}|\[\]\\:;\?,\.\/\~])(?=.{12,})/
const validations = {
	new_password1: Yup.string().matches(passwordRegExp, '*password invalid')
		.min(12, '*min 12 character required')
		.required('')
		.trim(),

	new_password2: Yup.string('app.error-invalid-string')
		// .min(3, 'app.error-min-character')
		.required('')
		.trim()
		.oneOf([Yup.ref('new_password1'), null], '*Passwords not match')

};

export default (props) => {

    return (
        <Formik
            initialValues={{
                new_password1: '',
                new_password2: ''
            }}
            validationSchema={Yup.object().shape(validations)}>
            {
                ({ touched, errors, handleChange, handleBlur }) => (
                    <Fragment>

                        <form>

                            <FormControl fullWidth >
                                <TextField
                                    margin="dense"
                                    id="new_password1"
                                    label="New Password"
                                    type="password"
                                    name='new_password1'
                                    onBlur={(e) => {
                                        handleBlur(e);
                                        props.handlePwFieldBlur(e, errors, touched); 
                                    }}
                                    onChange={(e) => {  
                                        props.handlePwFieldChange(e, errors, touched);
                                        handleBlur(e); 
                                        handleChange(e); 
                                    }}
                                    onKeyUp={(e) => {
                                        props.handlePwFieldKeyUp(e, errors, touched);
                                    }}
                                    required
                                    value={props.new_password1}
                                    validate={[passwordsMatch]}
                                    fullWidth
                                />
                                {
                                    errors.new_password1 && touched.new_password1 && <div className="input-feedback">
                                        {errors.new_password1 ?
                                            <FormHelperText id="new_password1"
                                            // className={classes.errorRedColorPasswordRequired}
                                            ><font color="red">{errors.new_password1}</font></FormHelperText>
                                            : ''}
                                    </div>
                                }
                            </FormControl>
                            <PasswordStrengthMeter
                                password={props.new_password1}
                                />
                            <FormControl fullWidth>
                                <TextField
                                    margin="dense"
                                    id="new_password2"
                                    label="Confirm New Password"
                                    type="password"
                                    required
                                    name='new_password2'
                                    validate={[passwordsMatch]}
                                    onBlur={(e) => { 
                                        handleBlur(e); 
                                        props.handlePwFieldBlur(e, errors, touched); 
                                    }}
                                    onChange={(e) => { 
                                        props.handlePwFieldChange(e, errors, touched);
                                        handleBlur(e); 
                                        handleChange(e); 
                                    }}
                                    onKeyUp={(e) => {
                                        props.handlePwFieldKeyUp(e, errors, touched);
                                    }}
                                    value={props.new_password2}
                                    fullWidth
                                />
                                {
                                    errors.new_password2 && touched.new_password2 &&
                                    <div className="input-feedback">
                                        {errors.new_password2 ?
                                            <FormHelperText id="new_password2"><font color="red">{errors.new_password2}</font></FormHelperText>
                                            : ""}

                                    </div>
                                }
                            </FormControl>
                            <Typography variant="caption" component={'span'} gutterBottom align="left">
                                Password need to have at least 12 characters and includes the following 4 types:<br />
                                - Uppercase<br />
                                - Lowercase<br />
                                - Digit<br />
                                - One of the following special characters:<br />
                                ~!@#$%^&amp;*()-_=+{}|[]\:;?,./
                            </Typography>
                        </form>
                        {/* <Grid container spacing={2}>
                            <Grid item style={{ display: 'flex', justifyContent: 'center' }} xs={12}>
                                <Button onClick={() => props.handleCancel()}>
                                    Cancel
                                </Button>
                                {props.loginUserData.update_password_needed && props.loginUserData.agreement_needed ?
                                    <Button
                                        className="btn-disc"
                                        disabled={
                                            !props.customDisableBtnCheck() ||
                                            !touched.new_password2 || errors.new_password2 || !touched.new_password1 || errors.new_password1
                                        }
                                        onClick={(e) => (props.handleConfirmAndAccept(e, props.loginUserData))}
                                    >Confirm and I Accept*</Button> : ''}
                                {props.loginUserData.update_password_needed && !props.loginUserData.agreement_needed ?
                                    <Button
                                        className="btn-disc"
                                        disabled={
                                            !props.customDisableBtnCheck() ||
                                            !touched.new_password2 || errors.new_password2 || !touched.new_password1 || errors.new_password1
                                        }
                                        onClick={(e) => { props.handleConfirm(e, props.loginUserData); }}
                                    >Confirm</Button> : ''}
                            </Grid>
                        </Grid> */}

                    </Fragment>
                )
            }
        </Formik>
    )
}
