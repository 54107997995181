import { put, takeLatest, all, call, delay } from "redux-saga/effects";

import {
  getPipeline,
  getMarketIntelligence,
  expressInterest,
  getHeadquaterCountryList,
  getVerticalsList,
  resetExpressInterest,
  getAdBanner1,
  postReferral,
  getWatchList,
  toggleWatchList,
  getViewSummary,
} from "./Action";

import { Unauthorized } from "../../login/Action";

import { ShowLoader, HideLoader } from "../../root/Action";

import { club_pipeline, web_content, referral, watch_list, logs } from "../../../api";

import {
  NewRelicError
} from '../../../utils';

import {
  logEventInternallyAndNewRelic
} from '../../../utils/ga';


function* postReferralResponder({ payload }) {
  try {
    const { data } = yield call(referral.postReferral, payload);
    yield put(postReferral.success(data));
  } catch (e) {
    if( e.message && e.response ){
        const err_payload = {
            type: 'api_failure_postReferralResponder',
            data: {
                config: e.response.config,
                data: e.response.data,
                status: e.response.status
            },
            err: new Error("API Failure postReferralResponder: " + e.message)
        }
        logEventInternallyAndNewRelic(err_payload);
    } else if (e.message) {
        NewRelicError( new Error("API Failure postReferralResponder: " + e.message) );
    } else {
        NewRelicError( new Error("API Failure postReferralResponder with no message") );
    }

    if (e.response.status == 401) {
        yield put(Unauthorized(e));
    }
    yield put(postReferral.failure(e));
  } finally {
  }
}

function* getPipelineResponder({ payload }) {
  yield put(ShowLoader(true));
  try {
    const { data } = yield call(club_pipeline.getPipeline);
    yield put(getPipeline.success(data));
  } catch (e) {
    if( e.message && e.response ){
        const err_payload = {
            type: 'api_failure_getPipelineResponder',
            data: {
                config: e.response.config,
                data: e.response.data,
                status: e.response.status
            },
            err: new Error("API Failure getPipelineResponder: " + e.message)
        }
        logEventInternallyAndNewRelic(err_payload);
    } else if (e.message) {
        NewRelicError( new Error("API Failure getPipelineResponder: " + e.message) );
    } else {
        NewRelicError( new Error("API Failure getPipelineResponder with no message") );
    }
    if (e.response.status == 401) {
      yield put(Unauthorized(e));
    }
    yield put(getPipeline.failure(e));
  } finally {
    yield put(HideLoader(true));
  }
}

function* getMarketIntelligenceResponder({ payload }) {
  yield put(ShowLoader(true));
  try {
    let company_names = [],
      investor_names = [],
      vertical_names = [],
      headquarter_country = [];

    if (payload.tags && payload.tags.length) {
      company_names = payload.tags.filter(
        (item) => item.indexOf("Company:") > -1
      );
      investor_names = payload.tags.filter(
        (item) => item.indexOf("Investor:") > -1
      );
      // vertical_names = payload.tags.filter(item => item.indexOf("Vertical:") > -1)
      // headquarter_country = payload.tags.filter(item => item.indexOf("Headquarter Country:") > -1)

      company_names = company_names.map((item) => {
        let a = item.split(":");
        return encodeURIComponent(a[a.length - 1]);
      });

      investor_names = investor_names.map((item) => {
        let a = item.split(":");
        return encodeURIComponent(a[a.length - 1]);
      });

      // vertical_names = vertical_names.map(item => {
      // 	let a = item.split(":");
      // 	return encodeURIComponent(a[a.length - 1])
      // });

      // headquarter_country = headquarter_country.map(item => {
      // 	let a = item.split(":");
      // 	return encodeURIComponent(a[a.length - 1])
      // })
    }
    let query_string = "?";
    if (payload.sortDisc === 0 || payload.sortDisc === 1) {
      if (payload.sortDisc === 0) {
        query_string += "sortDisc=0&";
      } else if (payload.sortDisc === 1) {
        query_string += "sortDisc=1&";
      }
    }
    if (payload.sortComp === 0 || payload.sortComp === 1) {
      if (payload.sortComp === 0) {
        query_string += "sortComp=0&";
      } else if (payload.sortComp === 1) {
        query_string += "sortComp=1&";
      }
    }
    if (payload.sortPost === 0 || payload.sortPost === 1) {
      if (payload.sortPost === 0) {
        query_string += "sortPost=0&";
      } else if (payload.sortPost === 1) {
        query_string += "sortPost=1&";
      }
    }
    // console.log('payload',payload)
    if (payload.valuation && payload.valuation.max > 0) {
      query_string += `val_lower=${payload.valuation.min}&val_upper=${payload.valuation.max}&`;
    }

    if (payload.selected) {
      let selected_disc_prem = payload.selected.filter((item) => item.selected);
      if (
        selected_disc_prem.length &&
        selected_disc_prem.length != payload.selected.length
      ) {
        selected_disc_prem = selected_disc_prem.map(
          (item) => item.query_string
        );
        query_string += "disc_prem=" + selected_disc_prem.join("|") + "&";
      }
    }

    if (payload.selectedTotal) {
      let selected_ent_val = payload.selectedTotal.filter(
        (item) => item.selected
      );
      if (
        selected_ent_val.length &&
        selected_ent_val.length != payload.selectedTotal.length
      ) {
        selected_ent_val = selected_ent_val.map((item) => item.query_string);
        query_string += "latest_val=" + selected_ent_val.join("|") + "&";
      }
    }

    if (payload.selectedVertical) {
      let selected_vertical = payload.selectedVertical.filter(
        (item) => item.selected
      );
      if (selected_vertical.length != payload.selectedVertical.length) {
        selected_vertical = selected_vertical.map((item) => item.id);
        query_string += "vertical=" + selected_vertical.join("|") + "&";
      }
    }

    if (payload.selectedHQCountry) {
      let selected_hq_country = payload.selectedHQCountry.filter(
        (item) => item.selected
      );
      if (selected_hq_country.length != payload.selectedHQCountry.length) {
        selected_hq_country = selected_hq_country.map((item) => item.name);
        query_string +=
          "headquarter_country=" + selected_hq_country.join("|") + "&";
      }
    }

    if (payload.watchist && payload.watchlist === 1) {
      query_string += "watchist=1&";
    }
    query_string += company_names.length
      ? "company_name=" + company_names.join("|") + "&"
      : "";
    query_string += investor_names.length
      ? "investor_name=" + investor_names.join("|") + "&"
      : "";

    query_string += payload.order_by.trim() ? "order_by="+payload.order_by+"&" : "";

    // query_string += vertical_names.length ? "vertical="+vertical_names.join("|")+"&" : "";
    // query_string += headquarter_country.length ? "headquarter_country="+headquarter_country.join("|")+"&" : "";
    query_string += payload.limit ? "limit=" + payload.limit + "&" : "";
    query_string += payload.offset ? "offset=" + payload.offset + "&" : "";
    // console.log( payload );
    // console.log('query_string',query_string)
    if (payload.company_id) query_string += "company_id=" + payload.company_id;
    const { data } = yield call(
      club_pipeline.getMarketIntelligence,
      query_string,
      payload
    );
    yield put(getMarketIntelligence.success(data));
  } catch (e) {
    if( e.message && e.response ){
        const err_payload = {
            type: 'api_failure_getMarketIntelligenceResponder',
            data: {
                config: e.response.config,
                data: e.response.data,
                status: e.response.status
            },
            err: new Error("API Failure getMarketIntelligenceResponder: " + e.message)
        }
        logEventInternallyAndNewRelic(err_payload);
    } else if (e.message) {
        NewRelicError( new Error("API Failure getMarketIntelligenceResponder: " + e.message) );
    } else {
        NewRelicError( new Error("API Failure getMarketIntelligenceResponder with no message") );
    }

    if (e.response.status == 401) {
      yield put(Unauthorized(e));
    }

    yield put(getMarketIntelligence.failure(e));
  } finally {
    yield put(HideLoader(true));
  }
}

function* getWatchListResponder({ payload }) {
  yield put(ShowLoader(true));
  try {
    let query_string = "?watchlist=1&offset=0";
    const { data } = yield call(
      watch_list.getWatchList,
      query_string,
      payload
    );
    // console.log("getWatchListResponder", payload);
    yield put(getWatchList.success(data));
  } catch (e) {
    if( e.message && e.response ){
        const err_payload = {
            type: 'api_failure_getWatchListResponder',
            data: {
                config: e.response.config,
                data: e.response.data,
                status: e.response.status
            },
            err: new Error("API Failure getWatchListResponder: " + e.message)
        }
        logEventInternallyAndNewRelic(err_payload);
    } else if (e.message) {
        NewRelicError( new Error("API Failure getWatchListResponder: " + e.message) );
    } else {
        NewRelicError( new Error("API Failure getWatchListResponder with no message") );
    }

    if (e.response.status == 401) {
      yield put(Unauthorized(e));
    }
    yield put(getWatchList.failure(e));
  } finally {
    yield put(HideLoader(true));
  }
}

function* getVerticalsListResponder({ payload }) {
  try {
    yield delay(3000);
    const { data } = yield call(club_pipeline.getVerticalsList, payload);
    yield put(getVerticalsList.success(data));
  } catch (e) {
    if( e.message && e.response ){
        const err_payload = {
            type: 'api_failure_getVerticalsListResponder',
            data: {
                config: e.response.config,
                data: e.response.data,
                status: e.response.status
            },
            err: new Error("API Failure getVerticalsListResponder: " + e.message)
        }
        logEventInternallyAndNewRelic(err_payload);
    } else if (e.message) {
        NewRelicError( new Error("API Failure getVerticalsListResponder: " + e.message) );
    } else {
        NewRelicError( new Error("API Failure getVerticalsListResponder with no message") );
    }
    if (e.response.status == 401) {
      yield put(Unauthorized(e));
    }
    yield put(getVerticalsList.failure(e));
  } finally {
  }
}

function* getHeadquaterCountryListResponder({ payload }) {
  try {
    yield delay(2000);
    const { data } = yield call(
      club_pipeline.getHeadquaterCountryList,
      payload
    );
    yield put(getHeadquaterCountryList.success(data));
  } catch (e) {
    if( e.message && e.response ){
        const err_payload = {
            type: 'api_failure_getHeadquaterCountryListResponder',
            data: {
                config: e.response.config,
                data: e.response.data,
                status: e.response.status
            },
            err: new Error("API Failure getHeadquaterCountryListResponder: " + e.message)
        }
        logEventInternallyAndNewRelic(err_payload);
    } else if (e.message) {
        NewRelicError( new Error("API Failure getHeadquaterCountryListResponder: " + e.message) );
    } else {
        NewRelicError( new Error("API Failure getHeadquaterCountryListResponder with no message") );
    }

    if (e.response.status == 401) {
      yield put(Unauthorized(e));
    }
    yield put(getHeadquaterCountryList.failure(e));
  } finally {
  }
}

function* toggleWatchListResponder({ payload }) {
  try {
    // yield delay(2000);
    const { data } = yield call(watch_list.toggleWatchList, payload);
    yield put(toggleWatchList.success(data));
  } catch (e) {
    if( e.message && e.response ){
        const err_payload = {
            type: 'api_failure_toggleWatchListResponder',
            data: {
                config: e.response.config,
                data: e.response.data,
                status: e.response.status
            },
            err: new Error("API Failure toggleWatchListResponder: " + e.message)
        }
        logEventInternallyAndNewRelic(err_payload);
    } else if (e.message) {
        NewRelicError( new Error("API Failure toggleWatchListResponder: " + e.message) );
    } else {
        NewRelicError( new Error("API Failure toggleWatchListResponder with no message") );
    }

    if (e.response.status == 401) {
      yield put(Unauthorized(e));
    }
    yield put(toggleWatchList.failure(e));
  } finally {
  }
}

function* getViewSummaryResponder({ }){
  try{
    const { data } = yield call(logs.getViewSummary);
    yield put(getViewSummary.success(data));
  } catch (e){
    if( e.message && e.response ){
        const err_payload = {
            type: 'api_failure_getViewSummaryResponder',
            data: {
                config: e.response.config,
                data: e.response.data,
                status: e.response.status
            },
            err: new Error("API Failure getViewSummaryResponder: " + e.message)
        }
        logEventInternallyAndNewRelic(err_payload);
    } else if (e.message) {
        NewRelicError( new Error("API Failure getViewSummaryResponder: " + e.message) );
    } else {
        NewRelicError( new Error("API Failure getViewSummaryResponder with no message") );
    }

    if (e.response.status == 401) {
      yield put(Unauthorized(e));
    }
    yield put(getViewSummary.failure(e));
  } finally {

  }
}

function* saga() {
  yield all([
    takeLatest(getPipeline.request, getPipelineResponder),
    takeLatest(getMarketIntelligence.request, getMarketIntelligenceResponder),
    takeLatest(postReferral.request, postReferralResponder),
    takeLatest(getVerticalsList.request, getVerticalsListResponder),
    takeLatest(
      getHeadquaterCountryList.request,
      getHeadquaterCountryListResponder
    ),
    takeLatest(getWatchList.request, getWatchListResponder),
    takeLatest(toggleWatchList.request, toggleWatchListResponder),
    takeLatest(getViewSummary.request, getViewSummaryResponder),
    // takeLatest(expressInterest.request, expressInterestResponder),
    // takeLatest(getAdBanner1.request, getAdBanner1Responder)
  ]);
}

export default saga;
