import React from 'react';

import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    makeStyles,
    Typography,
    Card,
    Grid,
} from '@material-ui/core';

import { 
    HeaderTableCell, 
    StyledTableRow, 
    recentCellClasses 
} from '../../../orderbook/common/Table';

import {
    roundNumber,
    numberWithCommas,
    displayVal,
    showBlurred,
    timeFormatConvert,
    showValueByFormat,
    remainingAllocation,
    getImpliedVal,
    getLatestFundingRound,
} from '../../../../utils/util'

import {
    content_placeholders
} from '../../../../config'

const useStyles = makeStyles((theme) => ({
    container: {
        // minHeight: 50,
        // maxHeight: 50,
    },
    SubTitle: {
        fontSize: 12,
        // textAlign: 'center',
        display: 'block',
    },
    Card: {
        padding: 16,
        marginTop: 16,
        marginBottom: 16,
    },
    ul: {
        padding: 0
    },
    LabelCell: {
        width: '25% !important'
    },
    ValueCell: {
        width: '75% !important'
    }
}));


export default (props) => {
    
    const classes = useStyles();
    const { dealHighlight, gp_detail, dealType, companyDetailResponse, fundingRounds } = props;
    
    const lfr_response = getLatestFundingRound(fundingRounds);
    let transactions = []
    try{
        transactions = JSON.parse(dealHighlight.transaction_closing_consideration.value);
    } catch(e){

    }
    let fees = []
    
    const carry_interest = dealHighlight.fees.filter(fee => fee.fee_name == 'Carry Interest');
    const mngt_fee = dealHighlight.fees.filter(fee => fee.fee_name == 'Management Fee');
    const upfront_fee = dealHighlight.fees.filter(fee => fee.fee_name == 'Upfront Fee');

    if(dealHighlight.brokerage_fee && dealHighlight.brokerage_fee.value){
        fees.push(dealHighlight.brokerage_fee.value)
    }
    if(dealHighlight.vehicle_fee && dealHighlight.vehicle_fee.value){
        const v_fees = dealHighlight.vehicle_fee.value.split('\\n');
        fees = fees.concat(v_fees);
    }
    if( upfront_fee.length ){
        const u_fee = upfront_fee[0];
        fees.push( `${u_fee.x}${u_fee.fee_unit == 'Percentage' ? '%' : u_fee.fee_unit} upfront fee` );
    }

    if( mngt_fee.length ){
        const m_fee = mngt_fee[0];
        fees.push( `${m_fee.x}${m_fee.fee_unit == 'Percentage' ? '%' : m_fee.fee_unit} p.a. management fee` );
    }
    if( carry_interest.length ){
        const c_interest = carry_interest[0]
        fees.push( `${c_interest.x}${c_interest.fee_unit == 'Percentage' ? '%' : c_interest.fee_unit} carry interest` );
    }
    let stage = '-'
    if(fundingRounds && fundingRounds.results.length>0){
        const selectedRound = fundingRounds.results.find(result => result.deal_type_1 && !result.deal_type_1.includes('Secondary Transaction'))
        if(selectedRound){
            stage = typeof selectedRound.deal_type_1 == 'string' ? selectedRound.deal_type_1.replace('VC', 'Venture') : selectedRound.deal_type_1;
            if( stage.indexOf('PE Growth') > -1 && lfr_response.valuation ){
                stage = lfr_response.valuation >= 1000 ? 'Later Stage Venture' : lfr_response.valuation >= 200 ? 'Growth Stage Venture' : 'Early Stage Venture';
            }
        }
    }
    let gpNames = ''
    if(gp_detail && gp_detail.length > 0){
        gpNames = gp_detail.map(item => item.name).join(', ')
    }
    const class_str = dealType && dealType.deal_class_str ? dealType.deal_class_str.split('|') : []
    const isSecondary = (class_str.indexOf(content_placeholders.deal_class_str.companyled) > -1 &&
                        class_str.indexOf(content_placeholders.deal_class_str.primary) == -1 &&
                        class_str.indexOf(content_placeholders.deal_class_str.gpled)) || (!class_str.length)
    const re = new RegExp('^[0-9\.]+$');
    return (
        <React.Fragment>
            <TableContainer component={Paper} className={classes.container}>
                <Table stickyHeader size="small" aria-label="simple table">
                    
                    <TableBody>
                        <StyledTableRow>
                            <TableCell className={classes.LabelCell}>Company Name</TableCell>
                            <TableCell className={classes.ValueCell}>{companyDetailResponse.name ? companyDetailResponse.name : '-'}</TableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                            <TableCell className={classes.LabelCell}>Deal Type</TableCell>
                            <TableCell className={classes.ValueCell}>
                            { class_str.indexOf(content_placeholders.deal_class_str.gpled) > -1 && 
                                <span className={classes.GPLedDeal}>GP Co-investment - 
                                    {
                                        class_str.indexOf(content_placeholders.deal_class_str.primary) > -1 ?
                                            ' Primary' : ' Secondary'
                                    }
                                </span>}
                            { 
                                class_str.indexOf(content_placeholders.deal_class_str.primary) > -1 &&
                                class_str.indexOf(content_placeholders.deal_class_str.gpled) == -1 && 
                                    <span className={classes.PrimaryDeal}>Company Primary</span>
                            }
                            { class_str.indexOf(content_placeholders.deal_class_str.companyled) > -1 &&
                                class_str.indexOf(content_placeholders.deal_class_str.primary) == -1 &&
                                class_str.indexOf(content_placeholders.deal_class_str.gpled) == -1 && 
                                    <span className={classes.CompLedDeal}>Secondary</span>}
                            {
                                !class_str.length && <span className={classes.DirectSecondaryDeal}>Secondary</span>
                            }
                                {/* {dealType.is_gp_led ? (dealType.is_primary ? 'GP Co-Investment Primary' : 'GP Co-Investment Secondary') : (dealType.is_primary ? 'Company Primary' : (dealType.is_company_led_secondary ? 'Secondary':'-'))} */}
                            </TableCell>
                        </StyledTableRow>
                        {
                            dealHighlight.transaction_format && dealHighlight.transaction_format.value &&
                                <StyledTableRow>
                                    <TableCell className={classes.LabelCell}>Transaction Format</TableCell>
                                    <TableCell className={classes.ValueCell}>{dealHighlight.transaction_format.value}</TableCell>
                                </StyledTableRow>
                        }
                        {
                            gpNames && 
                            <StyledTableRow>
                            <TableCell className={classes.LabelCell}>General Partner</TableCell>
                            <TableCell className={classes.ValueCell}>{showBlurred(gpNames) ? <span className="blurred">{gpNames}</span> : gpNames}</TableCell>
                        </StyledTableRow>
                        }
                        <StyledTableRow>
                            <TableCell className={classes.LabelCell}>Stage</TableCell>
                            <TableCell className={classes.ValueCell}>{showBlurred(stage) ? <span className="blurred">{stage}</span>:stage}</TableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                            <TableCell className={classes.LabelCell}>Allocation Size</TableCell>
                            <TableCell className={classes.ValueCell}>
                                {
                                    dealHighlight.deal_size ? 
                                        <React.Fragment>
                                            {'US$'+numberWithCommas(dealHighlight.deal_size)+'M'}
                                            {
                                                dealHighlight.demand_size ?
                                                remainingAllocation(dealHighlight.deal_size, dealHighlight.demand_size) :
                                                ''
                                            }
                                        </React.Fragment>
                                        : 
                                        '-'
                                }
                            </TableCell>
                        </StyledTableRow>
                        {
                            isSecondary &&
                            <StyledTableRow>
                                <TableCell className={classes.LabelCell}>Price</TableCell>
                                <TableCell className={classes.ValueCell}>{dealHighlight.price ? 'US$'+roundNumber(dealHighlight.price)+' per share' : '-'}</TableCell>
                            </StyledTableRow>
                        }
                        <StyledTableRow>
                            <TableCell className={classes.LabelCell}>{isSecondary ? "Implied Valuation" : "Valuation"}</TableCell>
                            <TableCell className={classes.ValueCell}>{
                                dealHighlight.valuation && dealHighlight.valuation.value
                                 ? 
                                    re.exec(dealHighlight.valuation.value) ?
                                        displayVal(parseFloat(dealHighlight.valuation.value)) : 
                                        dealHighlight.valuation.value :
                                    dealHighlight.price && lfr_response.price && lfr_response.valuation ?
                                        getImpliedVal(dealHighlight.price, lfr_response.price, lfr_response.valuation) :
                                    '-'}</TableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                            <TableCell className={classes.LabelCell}>Information</TableCell>
                            <TableCell className={classes.ValueCell}>
                                {
                                    showValueByFormat(dealHighlight.information)
                                }
                            </TableCell>
                        </StyledTableRow>
                        {/* <StyledTableRow>
                            <TableCell className={classes.LabelCell}>Target Purchase Price</TableCell>
                            <TableCell className={classes.ValueCell}>{dealHighlight.price ? 'US$'+roundNumber(dealHighlight.price)+' per share' : '-'}</TableCell>
                        </StyledTableRow> */}
                        {/* <StyledTableRow>
                            <TableCell className={classes.LabelCell}>Share Type</TableCell>
                            <TableCell className={classes.ValueCell}>{dealHighlight.share_type ? dealHighlight.share_type : '-'}</TableCell>
                        </StyledTableRow> */}
                        {
                            

                            dealHighlight.min_size && dealHighlight.min_size.value &&
                                <StyledTableRow>
                                    <TableCell className={classes.LabelCell}>Minimum Investment Size</TableCell>
                                    <TableCell className={classes.ValueCell}>{
                                        re.exec(dealHighlight.min_size.value) ?
                                            `US$${numberWithCommas(parseFloat(dealHighlight.min_size.value), 0)}` : 
                                            dealHighlight.min_size.value
                                    }</TableCell>
                                </StyledTableRow>
                                
                        }
                        
                        <StyledTableRow>
                            <TableCell className={classes.LabelCell}>Fee</TableCell>
                            <TableCell className={classes.ValueCell}>
                                { fees && fees.length > 0 ? 
                                    <ul className={classes.ul} style={{listStyleType: 'none', marginBottom: 0}}>
                                        {
                                           fees.map((item, index) => {
                                                return <li key={index}>{item}</li>
                                           }) 
                                        }
                                    </ul> : ' - '
                                }
                            </TableCell>
                        </StyledTableRow>
                        {/* <StyledTableRow>
                            <TableCell className={classes.LabelCell}>Transaction Closing Consideration</TableCell>
                            <TableCell className={classes.ValueCell}>
                                { transactions && transactions.length > 0 ? 
                                    <ul className={classes.ul}>
                                        {
                                           transactions.map((item, index) => {
                                                return <li key={index}>{item}</li>
                                           }) 
                                        }
                                    </ul> : ' - '
                                }
                            </TableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                            <TableCell className={classes.LabelCell}>Eligible Participants</TableCell>
                            <TableCell className={classes.ValueCell}>{dealHighlight.eligible_participants && dealHighlight.eligible_participants.value ? dealHighlight.eligible_participants.value : '-'}</TableCell>
                        </StyledTableRow> */}
                        <StyledTableRow>
                            <TableCell className={classes.LabelCell}>Closing</TableCell>
                            <TableCell className={classes.ValueCell}>
                                {
                                    showValueByFormat(dealHighlight.closing)
                                }
                            </TableCell>
                        </StyledTableRow>
                        

                    </TableBody>
                </Table>    
                
            </TableContainer>
        </React.Fragment>
    )
}