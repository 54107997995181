import {
    CreateApiAction as _createApiAction,
    CreateKeyAction as _createKeyAction,
} from '../../../../utils';

const key = 'ORDERBOOK_PLACEORDER';

const CreateApiAction = _createApiAction(key);


const postPlaceOrder = CreateApiAction('PLACEORDER');
const getSearchCompany = CreateApiAction('SEARCH_COMPANY');
const getFundingRound = CreateApiAction('GET_FUNDING_ROUND');


const CreateKeyAction = _createKeyAction(key);
const clearPlaceOrderMsg = CreateKeyAction('CLEAR_PLACEORDER_MSG');
const lockCompany = CreateKeyAction('LOCK_COMPANY');

export {
    postPlaceOrder,
    getSearchCompany,
    clearPlaceOrderMsg,
    lockCompany,
    getFundingRound,
}