import React from 'react';
import {
    Grid,
    Card,
    Typography,
    makeStyles,
} from '@material-ui/core';

import {
    roundNumber,
    get_average,
} from '../../../../utils/util';

const useStyles = makeStyles((theme) => ({
    container: {},
    SubTitle: {
        fontSize: 12,
        display: 'block',
    },
    InlineSubTitle: {
        fontSize: 12
    },
    Card: {
        padding: 16,
    }
}));

function latest_trend(trends){
    const items = [];
    for( var key in trends ){
        items.push( trends[key][ trends[key].length -1 ]['final'] )
    }
    return items;
}

export default (props) => {
    const classes = useStyles();
    const { trends } = props;

    const trends_keys = Object.keys(trends);

    const average_index = get_average( latest_trend(trends) );
    // console.log( average_index );
    return (
        <React.Fragment>
            <Grid container spacing={2}>
                {
                    trends_keys.map((key, index) => {
                        const trend_item = trends[key];
                        return (
                            <Grid item xs={6} key={index}>
                                <Card className={classes.Card}>
                                    <span className={classes.SubTitle}>{key}</span>
                                    <Typography variant="h6">
                                    <span className={classes.InlineSubTitle}>YTD performance: </span>{
                                            roundNumber((trend_item[trend_item.length - 1]['cum_percent_change']-1)*100)
                                        }% 
                                    </Typography>
                                    <Typography variant="h6">
                                        <span className={classes.InlineSubTitle}>performing at </span>{
                                            roundNumber(Math.abs(trend_item[trend_item.length - 1]['final']-average_index) / average_index * 100)
                                        }% <span className={classes.InlineSubTitle}>{ trend_item[trend_item.length - 1]['final'] > average_index ? 'above' : 'below' } average</span>
                                    </Typography>
                                </Card>
                            </Grid>
                        )
                    })
                }
                
            </Grid>
        </React.Fragment>
    )
}