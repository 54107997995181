import { 
    CreateApiAction as _createApiAction, 
    CreateKeyAction as _createKeyAction 
} from '../../../utils';

const key = "WEALTH_PIPELINE";

const CreateApiAction = _createApiAction(key);
const CreateKeyAction = _createKeyAction(key);

const getPipeline = CreateApiAction('GET_PIPELINE');
const getDeal = CreateApiAction('GET_DEAL');
const getMoreDeal = CreateApiAction('GET_MORE_DEAL');
const clearPopUp = CreateKeyAction('CLEAR_POPUP');
const getRegionList = CreateApiAction('REGION_LIST');
const getVerticalsList = CreateApiAction('VERTICALS_LIST');
const updateProps = CreateApiAction('UPDATE_PROPS');
const getDealTimeline = CreateApiAction('GET_DEAL_TIMELINE');

const getViewSummary = CreateApiAction('GET_VIEW_SUMMARY');
const getGpCompany = CreateApiAction('GET_GP_COMPANY');

const getPermissionedDeal = CreateApiAction('GET_PERMISSIONED_DEAL');
const clearWatchlistMessage = CreateKeyAction('CLEAR_WATCHLIST_MESSAGE');

export {
    getPipeline,
    getDeal,
    getMoreDeal,
    clearPopUp,
    getRegionList,
    getVerticalsList,
    updateProps,

    getViewSummary,
    getDealTimeline,
    getGpCompany,
    getPermissionedDeal,
    clearWatchlistMessage,
};