import React from 'react';

import {
    Dialog,
    DialogContent,
    makeStyles,
    Grid,
    Typography,
    DialogActions,
    Button,
} from '@material-ui/core';

import {
    roundNumber,
    getImpliedVal,
    getLatestFundingRound,
    numberWithCommas,
    convertSizeLabel,
} from '../../../../utils/util';

import {
    global150BodyFont,
    globalBodyFont,
} from '../../../styles';

const useStyles = makeStyles((theme) => ({
    LabelText: {
        fontSize: globalBodyFont,
        padding: '6px 8px',
        lineHeight: 1.75,
        display: 'inline-block'
    },
    Button: {
        fontSize: globalBodyFont,
    },
    Grid: {
        padding: '2px !important'
    }
}));

function dialog_payload(order_detail, company_detail, funding_rounds){
    const payload = [];

    if( order_detail ){
        payload.push({
            title: 'Transaction Terms',
        });
    
        payload.push({
            label: 'Company',
            value: company_detail.name
        });
    
        // const tran_key = order_detail.is_primary ? 'Primary' : 'Secondary';
        const order_type = order_detail.buy_type=='sell' ? 'Bid' : 'Offer'
        
        const transaction_payload = {
            label: 'Transaction',
            value: order_detail.is_primary ? 'Primary Fundraising' : order_type =='Bid' ? order_type : 'Secondary Transaction - ' + order_type,
            blurred: order_detail.is_invisible ? true : false
        }

        // if(tran_key=='Primary'){
        //     transaction_payload['onClick'] = {
        //         label: 'Request Info',
        //         property: 'timeline'
        //     }
        // } 
        payload.push(transaction_payload);

        const price_payload = {
            label: 'Price',
            value: order_detail.bid_price ? 'US$' + numberWithCommas(roundNumber(order_detail.bid_price)) + ' per share' : '-',
            blurred: order_detail.is_invisible ? true : false
        }

        // if( !order_detail.bid_price ){
        //     price_payload['onClick'] = {
        //         label: 'Request Info',
        //         property: 'price'
        //     }
        // }

        payload.push(price_payload);
        const lfr = getLatestFundingRound(funding_rounds);
        const implied_val = order_detail.valuation && !order_detail.bid_price ? `$${numberWithCommas(order_detail.valuation, 0)}M` : order_detail.bid_price && lfr.price && lfr.valuation ? 
                                getImpliedVal(order_detail.bid_price, lfr.price, lfr.valuation) :
                                '-';
    
        payload.push({
            label: 'Implied Valuation',
            value: implied_val != '-' ? 'US'+implied_val : implied_val,
            blurred: order_detail.is_invisible ? true : false
        });
        
        const format_payload = {
            label: 'Format',
            value: !order_detail.is_spv ? order_detail.is_primary ? 'Direct to captable' : 'Direct transfer of shares on captable' : 'SPV Structure',
            blurred: order_detail.is_invisible ? true : false
        }

        // if( order_detail.is_spv ){
        //     format_payload['onClick'] = {
        //         label: 'Request Info',
        //         property: 'fee_structure'
        //     }
        // }

        payload.push(format_payload);
    
        payload.push({
            label: 'Size',
            value: order_detail.size ? convertSizeLabel(order_detail.size) : order_detail.size_range ? convertSizeLabel(order_detail.size_range) : '-',
            blurred: order_detail.is_invisible ? true : false
            // onClick: {
            //     label: 'Request Size Info',
            //     property: 'size'
            // }
        });

        if( order_type == 'Offer' ){
            const share_class_payload = {
                label: 'Share Type',
                blurred: order_detail.is_invisible ? true : false
            }

            if( order_detail.is_primary ){
                share_class_payload['value'] = 'Preferred Share';
            } else {
                share_class_payload['value'] = '-';
                // share_class_payload['blurred'] = true;
            }

            payload.push(share_class_payload);
        }
        
    }
    
    return payload;
}

export default (props) => {
    // console.log( props );
    const classes = useStyles();

    const pl = dialog_payload(props.data, props.company_detail, props.funding_rounds);
    return (
        <Dialog
            open={props.open}
            >
                <DialogContent>
                    <Grid container spacing={2}>
                        <Button 
                            style={{ position: 'absolute', top: 10, right: 10 }}
                            onClick={evt => props.handleShowRequestInformation(evt, 'all', props.data)}>Request Info</Button>
                        {
                            pl.map((item, index) => {

                                return (
                                    <React.Fragment key={index}>
                                        <Grid className={classes.Grid} item xs={item.value || item.onClick ? 4 : 12}>
                                            {
                                                item.title ? 
                                                    <Typography
                                                        variant="h6">{item.title}</Typography> :
                                                        <strong className={classes.LabelText}>{ item.label }:</strong>
                                            }
                                        </Grid>
                                        {
                                            item.value || item.onClick ? 
                                                <Grid className={classes.Grid} item xs={8}>
                                                    <span className={item.blurred ? `blurred ${classes.LabelText}` : classes.LabelText}>
                                                        { item.value }
                                                    </span>
                                                    {
                                                        item.onClick ?
                                                            <Button className={classes.Button} 
                                                                onClick={(evt) => props.handleShowRequestInformation(evt, item.onClick.property, props.data)}>
                                                                {item.onClick.label}
                                                            </Button> : ''
                                                    }
                                                </Grid> : 
                                                ''
                                        }      
                                    </React.Fragment>
                                )
                                
                            })
                        }
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={(evt) => props.handleShowOrderDetailDialog(evt, '', {})}>Close</Button>
                    {
                        props.data && props.data.buy_type ?   
                            <Button
                                onMouseEnter={(evt) => props.handlePromptExpress(evt, true)}
                                onMouseLeave={(evt) => props.handlePromptExpress(evt, false)}
                                onClick={(evt) => props.handlePrefillPlaceOrder(evt, props.data.buy_type, props.data)}>Express Interest</Button> :
                            ''
                    }
                </DialogActions>
        </Dialog>
    )
}