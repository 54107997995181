import React, { Fragment } from "react";
import { Button } from "@material-ui/core";
import expressInterestIcon from "../../../../assets/images/express-interest-icon.png";
import handIcon from "../../../../assets/images/hand.png";
import arrowIcon from "../../../../assets/images/arrow-icon.png";
import docIcon from "../../../../assets/images/doc-icon.png";
import LaptopWindowsIcon from '@material-ui/icons/LaptopWindows';
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import PriorityHighIcon from "@material-ui/icons/PriorityHigh";
import RadioButtonCheckedIcon from "@material-ui/icons/RadioButtonChecked";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import { CompanyStatusCalculate } from "../../../../utils/util";
import { content_placeholders } from '../../../../config';
import { AiOutlineLeftCircle } from "react-icons/ai";
import { Link } from "react-router-dom";

export default (props) => {
	let result = CompanyStatusCalculate(props.clubDetail);
	const { loginUserData } = props;
	let user_type = ""
	if( loginUserData ){
		user_type = loginUserData.activeAccessRight &&
			loginUserData.activeAccessRight.access_right &&
			loginUserData.activeAccessRight.access_right.permission ? loginUserData.activeAccessRight.access_right.permission : '';
	}
	
	return (
		<div className="flex-row">
			{
				user_type.indexOf("SINGLE_COMPANY") == -1 ? 
					<Link
						to={{
							pathname: `/club/pipeline`,
							state: { type: props.formType },
						}}
						key={1}
						className="back-arrow" >
						<AiOutlineLeftCircle />
					</Link> : ''
			}

			<div className="sidebar_menu">
				<section className="comp-title-container sidebar-section">
					<div className="image-design flex-row">
						<div className="logo-hoder-2">
							{props.companyDetailResponse &&
							props.companyDetailResponse.logo_url ? (
								<img
									className="w-100"
									src={props.companyDetailResponse.logo_url}
									alt={props.companyDetailResponse.name}
								/>
							) : (
								""
							)}
						</div>

						{props.clubDetail &&
							props.clubDetail.live &&
							props.clubDetail.gateway_club &&
							Object.keys(props.clubDetail).length > 0 && (
								<Fragment>
									<div className="deal-status">
										{
											<img
												className="w-100"
												src="https://media.gatewayprivatemarkets.com/company-logos/full/GatewayClub.png"
											/>
										}
									</div>
								
								</Fragment>
								
							)}
					</div>

					{props.clubDetail &&
						props.clubDetail.live &&
						Object.keys(props.clubDetail).length > 0 && (
							
							<div
								className="flex-row-center"
								style={{ color: result.color }}
							>
								{result.icon}
								<span className="home-status">{result.statusTip}</span>
							</div>
							
						)}

					
					<div className="clear"></div>
				</section>

				<section className="control-container sidebar-section">
					<div className="row">
						<div className="col col-12">
							<Button
								className="btn-mid express-interest"
								onClick={(e) =>
									props.handleExpressBuySellDialogOpen(
										{
											price: props.clubDetail.price,
											min_size: props.clubDetail.min_size,
											company: props.companyDetailResponse.name,
											logo: props.companyDetailResponse.logo,
											dialogTitle: content_placeholders.buttons.express_buy_interest,
											interest_type: "INVEST",
										},
										e
									)
								}
							>
								{/* <img src={expressInterestIcon} className="express-icon" /> */}
								{props.loginUserData.activeAccessRight &&
								 props.loginUserData.activeAccessRight.access_right &&
								 props.loginUserData.activeAccessRight.access_right.permission == content_placeholders.user_type.trial_user ? 
								 	content_placeholders.buttons.request_full_access :
										props.clubDetail.price 
										? content_placeholders.buttons.invest_now
										: content_placeholders.buttons.express_buy_interest}
								<img src={arrowIcon} className="arrow-icon" />
							</Button>
						</div>
					</div>
					{props.clubDetail && props.clubDetail.company && !props.clubDetail.company.name && (
						<div className="row pt-2">
							<div className="col col-12">
								<Button
									className="btn-mid express-interest"
									onClick={(e) =>
										props.handleExpressBuySellDialogOpen(
											{
												price: props.clubDetail.price,
												min_size: props.clubDetail.min_size,
												company: props.companyDetailResponse.name,
												logo: props.companyDetailResponse.logo,
												dialogTitle: content_placeholders.buttons.express_sell_interest,
												interest_type: "SELL",
											},
											e
										)
									}
								>
									{/* <img src={handIcon} className="express-icon" /> */}
									{props.loginUserData.activeAccessRight &&
									 props.loginUserData.activeAccessRight.access_right &&
									 props.loginUserData.activeAccessRight.access_right.permission == content_placeholders.user_type.trial_user ? 
										content_placeholders.buttons.request_full_access :
											props.clubDetail.price
											? content_placeholders.buttons.invest_now
											: content_placeholders.buttons.express_sell_interest}
									<img src={arrowIcon} className="arrow-icon" />
								</Button>
							</div>
						</div>
					)}

					{props.clubDetail && props.clubDetail.committed_by && props.clubDetail.committed_by.toLowerCase().indexOf('gateway blurry') == -1 ? (
						<div className="row">
							<div className="col col-12">
								<div className="content-wrapper no-border">
									<div className="row">
										<div className="col col-12">
											<span className="committed-by">
												Indicative commitment by{" "}
												<strong>{props.clubDetail.committed_by}</strong>
											</span>
										</div>
									</div>
								</div>
							</div>
						</div>
					) : (
						""
					)}

					{props.clubDetail && props.clubDetail.committed_by && props.clubDealLinks.map((item, index) => {
						if (
							item.file_type == "application/pdf"
						) {
							return (
								<div className="row" key={index}>
									<div className="col col-12">
										<div className="content-wrapper no-border">
											
												<a
													href={item.url}
													key={index}
													onClick={(e) =>
														props.handleTeaserDialogOpen(
															item.url,
															item.link_name,
															e
														)
													}
													className="btn-mid"
												>
													<img src={docIcon} className="doc-icon" />
													View {item.link_name}
												</a>
											
										</div>
									</div>
								</div>
							);
						} else if( item.file_type == "video/mp4" ){
							return (
								<div className="row" key={index}>
									<div className="col col-12">
										<div className="content-wrapper no-border">
											
												<a
													href={item.url}
													key={index}
													onClick={(e) =>
														props.handleVideoDialogOpen(
															item.url,
															item.link_name,
															e
														)
													}
													className="btn-mid"
												>
													<LaptopWindowsIcon />
													View {item.link_name}
												</a>
											
										</div>
									</div>
								</div>
							)
						}
					})}
				</section>

				<div className="sidebar-section-group">
					{
					props.clubDetail && 
					Object.keys(props.clubDetail).length > 0 && 
					(props.clubDetail.committed_by || props.clubDetail.other_deal_terms) && (
						<section className="term-container sidebar-section">
							{props.clubDetail && props.clubDetail.min_size && (
								<Fragment>
									<div className="row">
										<div className="col col-12">
											<div className="title-container">
												<h4>Terms</h4>
												<Button
													onClick={(e) => props.handleScrollToTermsContainer(e)}
													>
													more <ArrowRightAltIcon />
												</Button>
											</div>
										</div>
									</div>
									<div className="row">
										<div className="col col-12">
											<div className="content-wrapper">
												<div className="row">
													<div className="col col-12">
														<span className="label">Min. Investment Size</span>
													</div>
													<div className="col col-12">
														{
															props.clubDetail.min_size.toLowerCase().indexOf(content_placeholders.error_message.blurred_text) > -1 ? 
															<span className="blurred value">
																{props.clubDetail.min_size}
															</span> : <span className="value">
																{props.clubDetail.min_size}
															</span>
														}
														
													</div>
												</div>
											</div>
										</div>
									</div>
								</Fragment>
							)}

							{props.clubDetail && props.clubDetail.brokerage_fee_note ? (
								<div className="row">
									<div className="col col-12">
										<div className="content-wrapper">
											<div className="row">
												<div className="col col-12">
													<span className="label">Brokerage Fee</span>
												</div>
												<div className="col col-12">
													{
														props.clubDetail.brokerage_fee_note.toLowerCase().indexOf(content_placeholders.error_message.blurred_text) > -1 ? 
														<span className="blurred value">
															{props.clubDetail.brokerage_fee_note}
														</span> : props.clubDetail.brokerage_fee_note
															.split("\\n")
															.map((item, index) => {
																return (
																	<span className="value" key={index}>
																		{item}
																	</span>
																);
															})
													}
												</div>
											</div>
										</div>
									</div>
								</div>
							) : ""}

							{props.clubDetail && props.clubDetail.transaction_format ? (
								<div className="row">
									<div className="col col-12">
										<div className="content-wrapper">
											<div className="row">
												<div className="col col-12">
													<span className="label">Transaction Format</span>
												</div>
												<div className="col col-12">
													{props.clubDetail.transaction_format.toLowerCase().indexOf(content_placeholders.error_message.blurred_text) > -1 ? <span className="blurred value">
														{props.clubDetail.transaction_format}
													</span> : 
													props.clubDetail.transaction_format
														.split("\\n")
														.map((item, index) => {
															return (
																<span className="value" key={index}>
																	{item}
																</span>
															);
														})}
												</div>
											</div>
										</div>
									</div>
								</div>
							) : (
								""
							)}

							{props.clubDetail && props.clubDetail.other_deal_terms ? (
								<div className="row">
									<div className="col col-12">
										<div className="content-wrapper">
											<div className="row">
												<div className="col col-12">
													<span className="label">Detail Terms</span>
												</div>
												<div className="col col-12">
													{props.clubDetail.other_deal_terms.toLowerCase().indexOf(content_placeholders.error_message.blurred_text) > -1 ? <span className="blurred value">
														{props.clubDetail.other_deal_terms}
													</span> : 
													props.clubDetail.other_deal_terms
														.split("\r\n")
														.map((item, index) => {
															return (
																<span className="value" style={{marginBottom: "5px"}} key={index}>
																	{item}
																</span>
															);
														})}
												</div>
											</div>
										</div>
									</div>
								</div>
							) : (
								""
							)}
						</section>
					)}

					{props.clubDetail && props.clubDetail.price ? (
						<section className="indicative-offer-container sidebar-section">
							<div className="row">
								<div className="col col-12">
									<div className="title-container">
										<h4>Indicative Offer</h4>
										<Button
											onClick={(e) => props.handleScrollToIndicativePrice(e)}>
											more <ArrowRightAltIcon />
										</Button>
									</div>
								</div>
							</div>

							<div className="row">
								<div className="col col-12">
									<div className="content-wrapper">
										<div className="row">
											<div className="col col-12">
												<span className="label">Price</span>
											</div>
											<div className="col col-12">
												<span className="value">
													{
														props.loginUserData.activeAccessRight &&
														props.loginUserData.activeAccessRight.access_right &&
														props.loginUserData.activeAccessRight.access_right.permission == content_placeholders.user_type.trial_user ? 
															<span className="blurred">XXXXX</span> : <span>${props.clubDetail.price}</span>
													}
												</span>
											</div>
										</div>
									</div>
								</div>
							</div>

							{props.clubDetail.allocation && !props.clubDetail.allocation_note ? (
								<div className="row">
									<div className="col col-12">
										<div className="content-wrapper">
											<div className="row">
												<div className="col col-12">
													<span className="label">Deal Size</span>
												</div>
												<div className="col col-12">
													<span className="value">
														{
															props.loginUserData.activeAccessRight &&
															props.loginUserData.activeAccessRight.access_right &&
															props.loginUserData.activeAccessRight.access_right.permission == content_placeholders.user_type.trial_user ? 
																<span className="blurred">XXXXX</span> : <span>${(props.clubDetail.allocation/1000000).toFixed(1)+"M"}</span>
														}
													</span>
												</div>
											</div>
										</div>
									</div>
								</div>
							) : ''}

							{props.latestFundingRound && props.latestFundingRound.selected_price ? (
								<div className="row">
									<div className="col col-12">
										<div className="content-wrapper">
											<div className="row">
												<div className="col col-12">
													<span className="label">Disc / Prem</span>
												</div>
												<div className="col col-12">
													<span className="value">
														{(
															Math.abs((props.clubDetail.price /
																props.latestFundingRound.selected_price -
																1) *
															100)
														).toFixed(1)}
														%
														{props.clubDetail.price >
															props.latestFundingRound.selected_price
															? " Premium"
															: " Discount"}
													</span>
												</div>
											</div>
										</div>
									</div>
								</div>
							) : (
								""
							)}

							{(props.latestFundingRound &&
							props.latestFundingRound.selected_price &&
							props.latestFundingRound.selected_post_money_valuation ) || props.clubDetail.implied_valuation ? (
								<Fragment>
									<div className="row">
										<div className="col col-12">
											<div className="content-wrapper">
												<div className="row">
													<div className="col col-12">
														<span className="label">Implied Valuation</span>
													</div>
													<div className="col col-12">
														<span className="value">
															{ props.clubDetail.implied_valuation ?
																props.clubDetail.implied_valuation>1000 ?  
																(props.clubDetail.implied_valuation/1000).toFixed(1) + "B" :
																props.clubDetail.implied_valuation.toFixed(0) + "M"
															: (props.clubDetail.price /
																props.latestFundingRound.selected_price) *
																props.latestFundingRound.selected_post_money_valuation >
															1000
																? "$" +
																	(
																		((props.clubDetail.price /
																			props.latestFundingRound.selected_price) *
																			props.latestFundingRound
																				.selected_post_money_valuation) /
																		1000
																	).toFixed(1) +
																	"B"
																: "$" +
																	(
																		(props.clubDetail.price /
																			props.latestFundingRound.selected_price) *
																		props.latestFundingRound.selected_post_money_valuation
																	).toFixed(0) +
																	"M" 
														}
																<sup>E</sup>
														</span>
													</div>
												</div>
												
											</div>
										</div>
									</div>
									<div className="row">
										<div className="col col-12">
											<p className="mb-0 disclaimer">
												<sup>E</sup> Estimated
											</p>
											
											<p className="mb-0 disclaimer">
												All figures in US$
											</p>
										</div>
									</div>
								</Fragment>
							) : (
								""
							)}
						</section>
					) : (
						""
					)}
				 
					{props.clubDetail.committed_by && props.clubDealTimelines && props.clubDealTimelines.length > 0 && (
						<section className="timeline-container sidebar-section">
							<div className="row">
								<div className="col col-12">
									<div className="title-container">
										<h4>Timeline</h4>
										<Button
											onClick={(e) => props.handleScrollToIndicativeTimeline(e)}
										>
											more <ArrowRightAltIcon />
										</Button>
									</div>
								</div>
							</div>

							{props.clubDealTimelines.map((item, index) => {
								return (
									<div className="row" key={index}>
										<div className="col col-12">
											<div className="content-wrapper">
												<div className="row">
													<div className="col col-12">
														<span className="label">{item.timeline_tag}</span>
													</div>
													<div className="col col-12">
														<span className="value">{item.timeline_date}</span>
													</div>
												</div>
											</div>
										</div>
									</div>
								);
							})}
						</section>
						)}
				</div>
			</div>
		</div>
	);
};
