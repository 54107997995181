import React, { useState } from 'react';

import {
    Dialog,
    DialogContent,
    AppBar,
    Toolbar,
    withStyles,
    Slide,
    IconButton,
    TextField,
    Button,
    Snackbar,    
} from '@material-ui/core';

import MuiAlert from '@material-ui/lab/Alert';


import CloseIcon from '@material-ui/icons/Close';

import { default as CompanyContent } from './CompanyContent';
import { default as DealShareRiskDisclosure } from '../../wealth/company/components/DealShareRiskDisclosure';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const FSDSDialogStyle = theme => ({
    AppBar: {
        background: theme.palette.common.white,
        boxShadow: 'none',
    },
    IconButton: {
        position: 'absolute',
        right: 0,
    },
    Textfield: {
        maxWidth: 400
    },
    LinkControl: {
        display: 'flex',
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '50%'
        }
    }
})

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default withStyles(FSDSDialogStyle)((props) => {
    // console.log( props );
    const { 
        riskDisclosureConfirmed, 
        riskDisclosureResponse,
        handleAcknowledgeRiskDisclosure,
        company_id,
        shareDealResponse : { token },
    } = props;

    const [showSnack, setShowSnack] = useState(false);
    const [snackStatus, setSnackStatus] = useState('success');
    const [snackMsg, setSnackMsg] = useState('')

    const handleCopyToClipboard = (url, evt) => {
        if( navigator && navigator.clipboard ){
            navigator.clipboard.writeText(url).then(() => {
                setShowSnack(true);
                setSnackStatus('success');
                setSnackMsg('Link copied.');
            }, (err) => {
                setShowSnack(true);
                setSnackStatus('error');
                setSnackMsg('Error occured - Please copy the link manually.');
            })
        }
    }

    const handleSnackClose = (evt, reason) => {
        if( reason == 'clickaway' ) return;
        setShowSnack(false);
        setSnackStatus('success');
        setSnackMsg('');
    }

    const url = token && riskDisclosureConfirmed ? `${window.location.origin}/orderbook/public/company-detail/${token}` : ''

    return (
        <Dialog
            open={props.open}
            fullScreen
            TransitionComponent={Transition}
            >
                <AppBar className={props.classes.AppBar}>
                    <Toolbar>
                        {
                            token && riskDisclosureConfirmed ?
                            <div className={props.classes.LinkControl}>
                                <TextField 
                                    disabled
                                    className={props.classes.Textfield} 
                                    variant="outlined"
                                    size="small"
                                    value={url} 
                                    />
                                <Button onClick={(evt) => handleCopyToClipboard(url, evt)}>Copy Link</Button>
                            </div>
                                : ''
                        }
                        <IconButton
                            onClick={props.handleShowDealShareDialog}
                            className={props.classes.IconButton}
                            >
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <DialogContent>
                    {
                        riskDisclosureConfirmed ? 
                            <CompanyContent
                                {...props}
                                {...{
                                    sharedPage: true
                                }}
                                /> :
                            <DealShareRiskDisclosure
                                {...{ riskDisclosureResponse, handleAcknowledgeRiskDisclosure }} />
                    }
                    <Snackbar 
                        open={showSnack}
                        autoHideDuration={4000}
                        onClose={(evt, reason) => handleSnackClose(evt, reason)}
                        
                        >
                            <Alert
                                severity={snackStatus}
                                >
                                {snackMsg}
                            </Alert>
                        </Snackbar>
                </DialogContent>
        </Dialog>
    )
})