import React from 'react';

import {
    Tooltip,
    IconButton,
    List,
    ListItem,
    ListItemText,
    makeStyles,
} from '@material-ui/core';

import InfoIcon from '@material-ui/icons/Info';

import {
    ReScaleForDeviceScale
} from '../../../../../utils/util';

import {
    global150BodyFont,
    globalBodyFont,
} from '../../../../styles';

const useStyles = makeStyles((theme) => ({
    ListItemText: {
        fontSize: ReScaleForDeviceScale(1.5, global150BodyFont, globalBodyFont),
    },
    ListItem: {
        padding: 0,
        margin: 0,
    },
    List: {
        padding: 0,
    }
}));

export default (props) => {
    const classes = useStyles();
    return (
        <Tooltip enterTouchDelay={0}
            title={
                <React.Fragment>
                    <List className={classes.List}>
                        {
                            props.items.map((item, index) => {
                                return (
                                    <ListItem key={index} classes={{ primary: classes.ListItem }}>
                                        <ListItemText classes={{ primary: classes.ListItemText }} primary={item} />
                                    </ListItem>
                                )
                            })
                        }
                    </List>
                </React.Fragment>
            }>
            <IconButton {...props} aria-label="info">
                <InfoIcon style={{ fontSize: ReScaleForDeviceScale(1.5, global150BodyFont, globalBodyFont) }} />
            </IconButton>
        </Tooltip>
    )
}