export { default as Styles } from './User-jss';
export { default as TableStyles } from './TableStyle-jss';
export { default as HeaderStyles } from './Header-jss';
export { default as ApplicationTheme } from './ApplicationTheme';

export const globalBodyFont=12;
export const global150BodyFont=12;

export const globalLabelFont=16;
export const global150LabelFont=16;

export const globalFontFamily="'Roboto', sans-serif";


export const CommonStyles = {
    FormControlLabel: {
        fontSize: globalBodyFont,
        fontFamily: globalFontFamily,
        marginBottom: 0,
        '& $label': {
            fontSize: globalBodyFont
        }
    },
    Checkbox: {
        padding: 2
    }
}

export const globalMD=768;
export const globalLG=992;
export const globalXL=1200;