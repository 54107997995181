import { combineReducers } from "redux";

import {
    default as InitialState
} from './InitialState';

export default combineReducers({
    reducer(state=InitialState, action){
        switch( action.type ){
            default:
                return state;
        }
    }
})