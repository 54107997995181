import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Grid, Button, FormControl, Typography, FormHelperText } from '@material-ui/core';

import { Snackbar, CustomInput } from '../../common';
import { Styles } from '../../styles';
import PasswordStrengthMeter from './PasswordStrengthMeter';
import { passwordRegExp } from '../../common/Funcs';

const passwordsMatch = (value, allValues) => {
	if (value !== allValues.get('password')) {//
		return 'Passwords dont match';
	}
	return undefined;
};

// const passwordRegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^\&\*\(\)_\-=+\{\}|\[\]\\:;\?,\.\/\~])(?=.{12,})/
const validations = {
	new_password1: Yup.string().matches(passwordRegExp, '*password invalid')
		.min(12, '*min 12 character required')
		.required('*password required')
		.trim(),

	new_password2: Yup.string('app.error-invalid-string')
		// .min(3, 'app.error-min-character')
		.required('*Confirm password required')
		.trim()
		.oneOf([Yup.ref('new_password1'), null], '*Passwords not match')

};

class ChangePassword extends Component{

    state = {
		new_password1: '',
		new_password2: '',
		confirmPassError: '',
		uid: '',
		token: '',
		openStyle:false,
    };

    constructor(props){
        super(props);
    }

    handleChange = (e) => {
		const { name, value } = e.target;
		this.setState({ [name]: value })
	}

    passwordMatch = () => {
		const { new_password1, new_password2 } = this.state;
		if (new_password2 !== '') {
			if (new_password1 !== new_password2) {
				this.setState({ confirmPassError: 'Password not match' })
			}
		}
    }

    handlePassChange = (e) => {

		const passwordInput = e.target.value
		const { new_password1 } = this.state
		if (new_password1 === passwordInput) {
			this.setState({ confirmPassError: 'Password Match' })
		} else {
			this.setState({ confirmPassError: '' })
		}

    }

    onSubmitPassword = (e, uid, token) => {
		const { name, value } = e.target;
		e.preventDefault();
		// this.setState({[name]:value});
		const { new_password1, new_password2 } = this.state;
		// console.log({ new_password1, new_password2, uid, token });
		this.props.ConfirmPasswordReset({ token, uid, new_password1, new_password2 });
		this.setState({
			new_password1:'',
			new_password2:'',
		})

	}

	handleSnakbarClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
		this.setState({ openStyle: false });
	};

	componentWillUpdate(prevProps) {
		const { showSnackbarInPassword } = this.props;
		if (showSnackbarInPassword !== prevProps.showSnackbarInPassword) {
			this.setState({ openStyle: true })
		}
	}

    render(){
        const {
			classes,
			handleSubmit,
			passwordChange,
			passwordError,
			passwordResponse,
			uid,
			token,
			deco
		} = this.props;


		const { new_password1, new_password2, confirmPassError, } = this.state;


        return (
            <Grid item xs={12} className="LoginCustomSection">
                <Formik
                    initialValues={{
                        new_password1: '',
                        new_password2: ''
                    }}
                    validationSchema={Yup.object().shape(validations)}>
					{
						({ touched, errors, handleChange, handleBlur }) => (
							<Fragment>
								<form>
								{/* className={classes.formControl2} */}
									<FormControl fullWidth className={classes.formControl}>

										<CustomInput
											margin="normal"
											id="new_password1"
											type="password"
											name="new_password1"
											placeholder="Password"
											onBlur={handleBlur}
											onChange={(e) => { this.handleChange(e); handleChange(e); handleBlur(e) }}
											required
											value={new_password1}
											validate={[passwordsMatch]}
											fullWidth
										/>
										{
											errors.new_password1 && touched.new_password1 && <div className="input-feedback">
												{errors.new_password1 ?
													<FormHelperText id="new_password1"
													// className={classes.errorRedColorPasswordRequired}
													><font color="red">{errors.new_password1}</font></FormHelperText>
													: ''}
											</div>
										}

									</FormControl>
									<PasswordStrengthMeter
										password={new_password1}
									/>
									 {/* className={classes.formControl} */}
									<FormControl fullWidth>
										<CustomInput
											margin="normal"
											id="new_password2"
											placeholder="Confirm Password *"
											type="password"
											required
											name="new_password2"
											validate={[passwordsMatch]}
											onBlur={(e) => { handleBlur(e); this.passwordMatch(e) }}
											onChange={(e) => { this.handleChange(e); handleChange(e); this.handlePassChange(e); handleBlur(e) }}
											value={new_password2}
											fullWidth
										/>
										{
											errors.new_password2 && touched.new_password2 &&
											<div className="input-feedback">
												{errors.new_password2 ?
												// className={classes.errorRedColor}
													<FormHelperText id="new_password2"><font color="red">{errors.new_password2}</font></FormHelperText>
													: ""}
											</div>
										}
									</FormControl>
									<Typography variant="caption" component={'span'} gutterBottom align="left">
									Password need to have at least 12 characters and includes the following 4 types:<br />
									- Uppercase<br />
									- Lowercase<br />
									- Digit<br />
									- One of the following special characters:<br />
									~!@#$%^&amp;*()-_=+{}|[]\:;?,./
									</Typography>
								</form>
								
								<div className="row" style={{ alignItems: "center" }}>

									<div className="col-12 col-lg-8 col-md-12" data-for="name">

										<Button className="btn btn-1 code customStyle"
											type="submit"
											disabled={!touched.new_password2 || errors.new_password2 || !touched.new_password1 || errors.new_password1}
											onClick={(e) => { this.onSubmitPassword(e, uid, token) }}>
											Change Password
                                        </Button>

									</div>

								</div>

							</Fragment>
						)
					}
				</Formik>
				<Snackbar
					openStyle={this.state.openStyle}
					handleSnakbarClose={() => this.handleSnakbarClose}
					type={passwordError ? 'error' : 'success'}
					message={passwordResponse}
				/>

			</Grid>
        )
    }

}

export default withStyles(Styles)(ChangePassword);
