import React from 'react';
import ReactECharts from 'echarts-for-react';

import {
    Dialog,
    DialogContent,
    Slide,
    AppBar,
    Toolbar,
    IconButton,
    Table,
    TableCell,
    TableBody,
    TableContainer,
    TableHead,
    Typography,
    Paper,
    withStyles,
    useMediaQuery,
} from '@material-ui/core';
import {
    StyledTableRow,
    TighterTableCell,
    TighterHeaderTableCell,
} from '../../../orderbook/common/Table';
import {
    globalFontFamily
} from '../../../styles';
import {
    numberWithCommas,
    displayVal,
} from '../../../../utils/util';

import CloseIcon from '@material-ui/icons/Close';
import vci_logo from '../../../../assets/images/vci_logo.png';

const FSPCDialogStyle = theme => ({
    AppBar: {
        background: theme.palette.common.white,
        boxShadow: 'none',
        position: 'relative',
    },
    IconButton: {
        position: 'absolute',
        right: 0,
    }
})

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const getOption = (resultList) => {
    let xAxisData = [];
    let revenueData = [];
    let ebitData = [];
    let liabilitiesData = [];
    resultList.forEach(item => {
        xAxisData.push(item.date)
        revenueData.push(item.revenue)
        ebitData.push(item.ebit)
        liabilitiesData.push(item.liabilities)
    });
    const option = {
        grid: {
            bottom: 121,
            // containLabel: true
        },
        legend: {
            data: ['Revenue', 'Ebit'],
            y: 20,
        },
        title: [
            {
                text: 'Historical Revenue',
                textStyle: {
                    color: 'gray',
                }
            },
            {
                text: '\nREVENUE',
                bottom: 60,
                textStyle: {
                    color: '#258ac6',
                    lineHeight: 0,
                    fontWeight: 'normal',
                    fontSize: 12,
                    fontFamily: globalFontFamily,
                }
            },
            {
                text: '\nEBIT',
                bottom: 30,
                textStyle: {
                    color: 'black',
                    lineHeight: 0,
                    fontWeight: 'normal',
                    fontSize: 12,
                    fontFamily: globalFontFamily
                }
            },
            {
                text: '\nLIABILITIES',
                bottom: 0,
                textStyle: {
                    color: 'black',
                    lineHeight: 0,
                    fontWeight: 'normal',
                    fontSize: 12,
                    fontFamily: globalFontFamily
                }
            },],
        tooltip: {
            trigger: 'item',
            formatter: function (params) {
                return `
                 ${params.name}</br>
                 <b>${params.seriesName}</b></br>
                 ${displayVal(params.value, 2, 2)}
                `
            }
        },
        xAxis: [
            {
                data: xAxisData,
                axisTick: {
                    show: false,
                },
                axisLine: {
                    onZero: true,
                    lineStyle: {
                        color: 'lightgray',
                    },
                },
                axisLabel: {
                    color: 'gary',
                    fontSize: 12,
                    fontFamily: globalFontFamily
                }
            },
            {
                data: revenueData.map(item => displayVal(item, 2, 2)),
                position: 'bottom',
                offset: 30,
                axisTick: {
                    show: false,
                },
                axisLine: {
                    show: false,
                },
                axisLabel: {
                    color: '#258ac6',
                    fontSize: 12,
                    fontFamily: globalFontFamily
                }
            },
            {
                data: ebitData.map(item => displayVal(item, 2, 2)),
                position: 'bottom',
                offset: 60,
                axisTick: {
                    show: false,
                },
                axisLine: {
                    show: false,
                },
                axisLabel: {
                    color: 'black',
                    fontSize: 12,
                    fontFamily: globalFontFamily
                }
            },
            {
                data: liabilitiesData.map(item => displayVal(item, 2, 2)),
                position: 'bottom',
                offset: 90,
                axisTick: {
                    show: false,
                },
                axisLine: {
                    show: false,
                },
                axisLabel: {
                    color: 'black',
                    fontSize: 12,
                    fontFamily: globalFontFamily
                }
            }],
        yAxis: {
            name: 'US$M',
            nameLocation: 'middle',
            nameGap: 100,
        },
        series: [
            {
                name: 'Revenue',
                type: 'bar',
                stack: 'one',
                data: revenueData,
                itemStyle: { color: '#258ac6' },
            },
            {
                name: 'Ebit',
                type: 'bar',
                stack: 'two',
                data: ebitData,
                itemStyle: { color: 'lightgray' },
            },
        ],
    }
    return option
}

export default withStyles(FSPCDialogStyle)((props) => {
    const { resultList } = props;
    const isDesktopScreen = useMediaQuery(`(min-width:576px)`);
    return (
        <Dialog
            open={props.open}
            fullScreen
            TransitionComponent={Transition}
            sx={{ overflowY: 'scroll' }}
        >
            <AppBar className={props.classes.AppBar}>
                <Toolbar>
                    {/* <a href="https://www.venturecapinsights.com"  target="_blank" style={{position: 'absolute', right: 60}}>
                        <img style={{background: '#1287ff', width: 100, height: 42}}
                            src={vci_logo} alt="Venture Capital Insight"/>
                    </a> */}
                    <IconButton
                        onClick={() => props.closeRevenueDialog()}
                        className={props.classes.IconButton}>
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <DialogContent>
                {
                    isDesktopScreen
                        ?
                        <div className="chart-wrapper">
                            <ReactECharts
                                option={getOption(resultList)}
                                style={{ height: 400 }}
                                notMerge={true}
                            />
                        </div>
                        :
                        <div>
                            <Typography variant='h6' align='center'>Historical Revenue</Typography>
                            <TableContainer component={Paper} style={{ position: 'relative', marginTop: 15, overflowX: "initial" }}>
                                <Table stickyHeader size="small" aria-label="simple table">
                                    <TableHead>
                                        <StyledTableRow>
                                            <TighterHeaderTableCell className="vci-revenue-date">Date</TighterHeaderTableCell>
                                            <TighterHeaderTableCell className="vci-revenue-revenue">Revenue</TighterHeaderTableCell>
                                            <TighterHeaderTableCell className="vci-revenue-ebit">Ebit</TighterHeaderTableCell>
                                            <TighterHeaderTableCell className="vci-revenue-liabilities">Liabilities</TighterHeaderTableCell>
                                        </StyledTableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            resultList.map((row, index) => {
                                                return (
                                                    <React.Fragment key={index}>
                                                        <StyledTableRow>
                                                            <TighterTableCell className="vci-revenue-date">
                                                                {row.date ? row.date : '-'}
                                                            </TighterTableCell>
                                                            <TighterTableCell className="vci-revenue-revenue">
                                                                {row.revenue ? displayVal(row.revenue, 2, 2) : '-'}
                                                            </TighterTableCell>
                                                            <TighterTableCell className="vci-revenue-ebit">
                                                                {row.ebit ? displayVal(row.ebit, 2, 2) : '-'}
                                                            </TighterTableCell>
                                                            <TighterTableCell className="vci-revenue-liabilities">
                                                                {row.liabilities ? displayVal(row.liabilities, 2, 2) : '-'}
                                                            </TighterTableCell>
                                                        </StyledTableRow>
                                                    </React.Fragment>
                                                )
                                            })
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                }
                <div style={{ position: 'absolute', bottom: 10, right: 20 }}>
                    <div className="powered-by-vci">
                        <p>Powered by <a href="https://www.venturecapinsights.com"  target="_blank" >
                                <img style={{background: '#1287ff', width: 60 }}
                                    src={vci_logo} alt="Venture Capital Insight"/>
                            </a>
                        </p>
                    </div>
                </div>
            </DialogContent>
            
        </Dialog>
    )
})