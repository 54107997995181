import React from 'react';

import { 
    MenuItem,
    FormControl,
    ListItemText,
    makeStyles,
    Grid,
    OutlinedInput,
} from '@material-ui/core';

import {
    StyledInputLabel,
    StyledSelect,
} from '../../common/Label';

import {
    SelectPlaceholder
} from '../../common';

import {
    ReScaleForDeviceScale
} from '../../../../utils/util';

import {
    global150BodyFont,
    globalBodyFont,
    globalFontFamily,
} from '../../../styles';

const FormStyle = makeStyles({
    FormControl: {
        '& .MuiListItemText-root': {
            marginTop: 0,
            marginBottom: 0,
            '& .MuiTypography-body1': {
                lineHeight: 1
            }
        }
    },
    ListItemText: {
        '& span': {
            fontSize: ReScaleForDeviceScale(1.5, global150BodyFont, globalBodyFont),
            fontFamily: globalFontFamily,
        }
    },
    OutlinedInput: {
        '& .MuiOutlinedInput-inputMarginDense': {
            paddingTop: '8.5px !important',
            paddingBottom: '8.5px !important',
        }
    }
});



export default (props) => {
    const classes = FormStyle();
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <FormControl variant="outlined" className={classes.FormControl} size="small">
                    {/* <StyledInputLabel shrink id="sort-label">Sort By</StyledInputLabel> */}
                    <StyledSelect
                        labelId="sort-label"
                        id='sort-select'
                        name='selected_sort'
                        value={props.selected_sort}
                        onChange={props.handleSelectChange}
                        renderValue={(selected) => selected}
                        input={<OutlinedInput className={classes.OutlinedInput} notched placeholder="Sort By" />} //label={"Sort By"} 
                        >
                            <MenuItem value="none" disabled>
                                <ListItemText className={classes.ListItemText} primary="Sort By" />
                            </MenuItem>
                            {
                                props.sort_options.map((item, index) => {
                                    return (
                                        <MenuItem
                                            value={item}
                                            key={index}>
                                                <ListItemText className={classes.ListItemText} primary={item} />
                                        </MenuItem>
                                    )
                                })
                            }
                    </StyledSelect>
                </FormControl>
            </Grid>
        </Grid>
    )
}