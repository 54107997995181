import React, { Component, Fragment } from 'react';
import Plot from 'react-plotly.js';
import {
    Button
} from '@material-ui/core';
import { content_placeholders } from '../../../../config';
import { sendGAEvent } from '../../../../utils/ga';
import { GetToken, NewRelicError } from "../../../../utils";
import { formatDate } from '../../../../utils/util';

if (!String.prototype.replaceAll) {
	String.prototype.replaceAll = function(str, newStr){

		// If a regex pattern
		if (Object.prototype.toString.call(str).toLowerCase() === '[object regexp]') {
			return this.replace(str, newStr);
		}

		// If a string
		return this.replace(new RegExp(str, 'g'), newStr);

	};
}

class PriceChart extends Component{

    state = {
        company_id: null,
        date: [],
        min_buy: [],
        max_buy: [],
        min_sell: [],
        max_sell: [],
        sell_connector: [],
        buy_connector: [],
        latest_funding_rounds: [],
        latest_funding_rounds_val: [],
        buy_volume: [],
        sell_volume: [],
        total_volume: [],
        price_trend: [],
        show_price_trend: false,
        show_latest_funding_rounds: false,
        live_offers_date: [],
        live_offers_price: [],
        live_offers: [],
        live_demands_date: [],
        live_demands_price: [],
        live_demands: [],
        show_live_offers: true,
        max_y_axis: 0,
        min_y_axis: 0,
        max_y2_axis: 0,
        revision: 0,
        hover: {
            max_buy: 0,
            min_buy: 0,
            max_sell: 0,
            min_sell: 0
        },
        annotation:{
            max_buy: 0,
            min_buy: 0,
            max_sell: 0,
            min_sell: 0,
            lfr_price: 0,
            lfr_val: 0,
            date: ''
        },
        last_blur: {
            index: -1,
            date: ''
        }
    }

    constructor(props){
        super(props);
    }

    static getDerivedStateFromProps(nextProps, prevState){
        const { hist_data, live_offers, live_demands, company_id, fundingRounds, finishedCollectFundingRounds, loginUserData } = nextProps;
        let payload = {}
        if( company_id != prevState.company_id && finishedCollectFundingRounds ){
            payload = {
                date: [],
                min_buy: [],
                max_buy: [],
                min_sell: [],
                max_sell: [],
                buy_connector: [],
                sell_connector: [],
                latest_funding_rounds: [],
                latest_funding_rounds_val: [],
                buy_volume: [],
                sell_volume: [],
                total_volume: [],
                price_trend: [],
                live_offers_date: [],
                live_offers_price: [],
                live_offers: [],
                live_demands_date: [],
                live_demands_price: [],
                live_demands: [],
                max_y_axis: 0,
                min_y_axis: 0,
                max_y2_axis: 0,
                last_blur: {
                    index: -1,
                    date: ''
                }
            }

            let all_hist_data = [];
            if( hist_data && hist_data.length ){

                if( loginUserData.activeAccessRight &&
                    loginUserData.activeAccessRight.access_right &&
                    loginUserData.activeAccessRight.access_right.permission == content_placeholders.user_type.trial_user ){
                        let start_date = new Date();
                        start_date.setDate(start_date.getDate() - 120);
                        let end_date = new Date();
                        end_date.setDate(end_date.getDate() - 31);
                        let blur_index = 0;

                        for( let ii=start_date; ii <= end_date; ii.setDate(ii.getDate() + 1) ){
                            const this_date = ii.getFullYear() + '-' + (ii.getMonth()+1) + '-' + ii.getDate();
                            all_hist_data.push({
                                date: this_date,
                                max_buy: null,
                                min_buy: null,
                                max_sell: null,
                                min_sell: null,
                                buy_volume: null,
                                sell_volume: null
                            });

                            blur_index++;
                            payload['last_blur']['index'] = blur_index;
                            payload['last_blur']['date'] = this_date;
                        }
                    }

                all_hist_data = all_hist_data.concat(hist_data);

                const fr_with_timestamps = fundingRounds.results.map(item => {
                    item.timestamp = 0;
                    try{
                        let dates = item.deal_date.split("-");
                        dates = new Date(dates[0], dates[1], dates[2]);
                        item.timestamp = dates.getTime();
                    } catch(error){
                        NewRelicError(error);
                    }

                    return item;
                });
                // console.log( fr_with_timestamps );

                let prev_price_trend_num = 0;
                let prev_price_trend_factor = 0;
                let last_max_sell = 0;
                let last_max_buy = 0;
                let should_continue = true;
                let sell_null_index = -1;
                let buy_null_index = -1;
                for( var ii=0; ii < all_hist_data.length; ii++ ){
                    if( should_continue ){
                        let h_dates = 0;
                        try{
                            h_dates = all_hist_data[ii]['date'].split("-");
                            h_dates = new Date(h_dates[0], h_dates[1], h_dates[2]);
                            h_dates = h_dates.getTime();
                        } catch(err){

                        }

                        payload['date'].push( all_hist_data[ii]['date'] );
                        payload['min_buy'].push( all_hist_data[ii]['min_buy'] );
                        payload['max_buy'].push( all_hist_data[ii]['max_buy'] );
                        payload['min_sell'].push( all_hist_data[ii]['min_sell'] );
                        payload['max_sell'].push( all_hist_data[ii]['max_sell'] );
                        payload['buy_volume'].push( all_hist_data[ii]['buy_volume'] );
                        payload['sell_volume'].push( all_hist_data[ii]['sell_volume'] );

                        payload['buy_connector'].push( all_hist_data[ii]['max_buy'] );
                        payload['sell_connector'].push( all_hist_data[ii]['max_sell'] );

                        if( ii && all_hist_data[ii]['max_buy'] && buy_null_index > -1 ){
                            const difference = all_hist_data[ii]['max_buy'] - last_max_buy;
                            const step = difference ? Math.round( difference / ( ii - buy_null_index ) ) : difference;
                            // console.log( `Debug - Difference: ${difference}, Step: ${step}, Buy Null: ${buy_null_index}, Current Index: ${ii}, Current Val: ${all_hist_data[ii]['max_buy']}, Last Max Buy: ${last_max_buy}` );

                            let to_add = last_max_buy;
                            for( let bii=buy_null_index; bii < ii; bii++ ){
                                to_add = to_add+step;
                                payload['buy_connector'][bii] = to_add;
                            }

                            buy_null_index = -1;
                        }

                        if( ii && all_hist_data[ii]['max_sell'] && sell_null_index > -1 ){
                            const difference = all_hist_data[ii]['max_sell'] - last_max_sell;
                            const step = difference ? Math.round( difference / ( ii - sell_null_index ) ) : difference;

                            let to_add = last_max_sell;
                            for( let sii=sell_null_index; sii < ii; sii++ ){
                                to_add = to_add+step;
                                payload['sell_connector'][sii] = to_add;
                            }

                            sell_null_index = -1;
                        }

                        if( all_hist_data[ii]['max_buy'] ){
                            last_max_buy = all_hist_data[ii]['max_buy'];
                        } else if ( buy_null_index == -1 ) {
                            buy_null_index = ii;
                        }

                        if( all_hist_data[ii]['max_sell'] ){
                            last_max_sell = all_hist_data[ii]['max_sell'];
                        } else if ( sell_null_index == -1 ) {
                            sell_null_index = ii;
                        }

                        let total_volume = all_hist_data[ii]['buy_volume'] + all_hist_data[ii]['sell_volume'];
                        if( total_volume > payload['max_y2_axis'] ){
                            payload['max_y2_axis'] = total_volume;
                        }

                        payload['total_volume'].push( Math.round(total_volume) );

                        let price_trend_num = 0;
                        let price_trend_factor = 0;
                        if( all_hist_data[ii]['min_buy'] && all_hist_data[ii]['max_buy'] ){
                            price_trend_num += all_hist_data[ii]['min_buy'] + all_hist_data[ii]['max_buy'];
                            price_trend_factor += 2;
                        }
                        if( all_hist_data[ii]['min_sell'] && all_hist_data[ii]['max_sell'] ){
                            price_trend_num += all_hist_data[ii]['min_sell'] + all_hist_data[ii]['max_sell'];
                            price_trend_factor += 2;
                        }

                        if( !price_trend_num && prev_price_trend_num ){
                            payload['price_trend'].push( prev_price_trend_num / prev_price_trend_factor );
                        } else {
                            payload['price_trend'].push( price_trend_num / price_trend_factor );
                            prev_price_trend_num = price_trend_num;
                            prev_price_trend_factor = price_trend_factor;
                        }

                        if( all_hist_data[ii]['max_buy'] > all_hist_data[ii]['max_sell'] &&
                            all_hist_data[ii]['max_buy'] > payload['max_y_axis'] ) {
                            payload['max_y_axis'] = all_hist_data[ii]['max_buy'];
                        } else if ( all_hist_data[ii]['max_sell'] > payload['max_y_axis'] ){
                            payload['max_y_axis'] = all_hist_data[ii]['max_sell'];
                        }

                        if( !payload['min_y_axis'] ){
                            if( all_hist_data[ii]['min_buy'] < all_hist_data[ii]['min_sell'] &&
                                ( all_hist_data[ii]['min_buy'] && all_hist_data[ii]['min_sell'] ) ){
                                payload['min_y_axis'] = all_hist_data[ii]['min_buy'];
                            } else if ( all_hist_data[ii]['min_buy'] ) {
                                payload['min_y_axis'] = all_hist_data[ii]['min_buy']
                            } else if ( all_hist_data[ii]['min_sell'] ){
                                payload['min_y_axis'] = all_hist_data[ii]['min_sell']
                            }
                        } else if( all_hist_data[ii]['min_buy'] < all_hist_data[ii]['min_sell'] &&
                            all_hist_data[ii]['min_buy'] < payload['min_y_axis'] && all_hist_data[ii]['min_buy'] ){
                            payload['min_y_axis'] = all_hist_data[ii]['min_buy'];
                        } else if ( all_hist_data[ii]['min_sell'] < payload['min_y_axis'] && all_hist_data[ii]['min_sell'] ){
                            payload['min_y_axis'] = all_hist_data[ii]['min_sell'];
                        }

                        const filtered_fr = fr_with_timestamps.filter(fr => fr.timestamp <= h_dates && fr.price > 0);
                        // console.log( filtered_fr );

                        if( filtered_fr.length ){
                            const anchor_price = filtered_fr[0]['price'];
                            const post_val = filtered_fr[0]['post_money_valuation'];

                            payload['latest_funding_rounds'].push(anchor_price);
                            payload['latest_funding_rounds_val'].push( post_val );
                            if( anchor_price > payload['max_y_axis'] ) payload['max_y_axis'] = anchor_price;
                            if( anchor_price < payload['min_y_axis'] ) payload['min_y_axis'] = anchor_price;
                        } else {
                            payload['latest_funding_rounds'].push(null);
                        }
                    }

                    // if( all_hist_data[ii]['date'] == '2022-04-11' ){
                    //     should_continue = false;
                    // }

                }

                payload['min_y_axis'] = payload['min_y_axis'] * 0.7;
                payload['max_y2_axis'] = payload['max_y2_axis'] * 1.1;
            }

            if( all_hist_data && live_offers ){
                payload['live_offers'] = live_offers;
                for( var ii=0; ii < live_offers.length; ii++ ){
                    payload['live_offers_price'].push( live_offers[ii].price );
                    payload['live_offers_date'].push( all_hist_data[all_hist_data.length-1]['date'] )
                }
            }

            if( all_hist_data && live_demands ){
                payload['live_demands'] = live_demands;
                for( var ii=0; ii < live_demands.length; ii++ ){
                    payload['live_demands_price'].push( live_demands[ii].price );
                    payload['live_demands_date'].push( all_hist_data[all_hist_data.length-1]['date'] )
                }
            }

            payload['company_id'] = company_id;
            payload['revision'] = Date.now();
        }

        return payload;
    }

    handleChartHover(data){
        const { points } = data;
        if( points && points.length ){
            const { annotation } = this.state;
            annotation['date'] = points[0].x;
            const { date, latest_funding_rounds, latest_funding_rounds_val, last_blur } = this.state;
            const item_index = date.indexOf( annotation['date'] );

            if( item_index > last_blur.index ){
                if( latest_funding_rounds && latest_funding_rounds.length > item_index ) {
                    annotation['lfr_price'] = latest_funding_rounds[item_index];
                }

                if( latest_funding_rounds_val && latest_funding_rounds_val.length > item_index ) {
                    annotation['lfr_val'] = latest_funding_rounds_val[item_index];
                }

                for( var ii=0; ii < points.length; ii++ ){
                    const item = points[ii];

                    if( item.data && item.data.name && item.data.name == "Max Buy Price" && item.x == annotation['date'] ){
                        annotation['max_buy'] = item.y;
                    } else if( item.data && item.data.name && item.data.name == "Min Buy Price" && item.x == annotation['date'] ){
                        annotation['min_buy'] = item.y;
                    } else if( item.data && item.data.name && item.data.name == "Max Sell Price" && item.x == annotation['date'] ){
                        annotation['max_sell'] = item.y;
                    } else if( item.data && item.data.name && item.data.name == "Min Sell Price" && item.x == annotation['date'] ){
                        annotation['min_sell'] = item.y;
                    } else if( item.data && item.data.name && item.data.name == "Gateway Live Bids" && item.x == annotation['date'] ){
                        // console.log("Hovered live bids: " + item.y);
                    } else if( item.data && item.data.name && item.data.name == "Gateway Live Offers" && item.x == annotation['date'] ){
                        // console.log("Hovered live offers: " + item.y);
                    }
                }
                sendGAEvent({
                    type:"view_price_chart",
                    data:{
                        company_id: this.props.company_id,
                        view_time : annotation['date']
                    }
                })
                this.setState({ annotation: annotation });
            }

        }
    }

    handleChartUnhover(data){
        this.setState({
            annotation: {
                max_buy: 0,
                min_buy: 0,
                max_sell: 0,
                min_sell: 0
            }
        });
    }

    handleVolumeHover(data){
        const { points } = data;
        if( points && points.length ){
            sendGAEvent({
                type:"view_volume_chart",
                data:{
                    company_id: this.props.company_id,
                    view_time : points[0].x,
                }
            });
        }
    }

    handleChartClick(data){
        const { live_offers } = this.state;
        // console.log(data);
        // if( live_offers.length ){
        //     this.setState({ show_live_offers: true });
        // }
    }

    handleAdditionalMetrixClick(metrix_name, evt){
        this.setState({[metrix_name]: !this.state[metrix_name]})
    }

    render(){
        const {
            min_buy,
            max_buy,
            min_sell,
            max_sell,
            buy_connector,
            sell_connector,
            latest_funding_rounds,
            // total_volume,
            buy_volume,
            sell_volume,
            price_trend,
            date,
            max_y_axis,
            min_y_axis,
            live_offers_date,
            live_offers_price,
            live_offers,
            live_demands_date,
            live_demands_price,
            live_demands,
            revision,
            // show_live_offers,
            show_price_trend,
            show_latest_funding_rounds,
            annotation,
            max_y2_axis,
            last_blur,
        } = this.state;

        // console.log( buy_connector );
        // console.log( sell_connector );

        const { finishedCollectFundingRounds, loginUserData } = this.props;

        const total_max_buy = max_buy && max_buy.length ? max_buy.reduce((total, num) => total+num) : 0;
        const total_min_buy = min_buy && min_buy.length ? min_buy.reduce((total, num) => total+num) : 0;
        const total_max_sell = max_sell && max_sell.length ? max_sell.reduce((total, num) => total+num) : 0;
        const total_min_sell = min_sell && min_sell.length ? min_sell.reduce((total, num) => total+num) : 0;
        const total_lfr = latest_funding_rounds && latest_funding_rounds.length ? latest_funding_rounds.reduce((total, num) => total+num) : 0;

        const { companyDetailResponse } = this.props;

        const annotation_item_bid_class = annotation.date ? "annotation-item bid-range" : "annotation-item";
        const annotation_item_offer_class = annotation.date ? "annotation-item offer-range" : "annotation-item";
        const live_offer_panel_classname = !live_demands.length ? "live-offers-panel order-panel full-width" : "live-offers-panel order-panel";
        const live_demand_panel_classname = !live_offers.length ? "live-demands-panel order-panel full-width" : "live-demands-panel order-panel";

        const price_trend_classname = show_price_trend ? "legend-item clickable" : "legend-item clickable dim";
        const latest_funding_rounds_classname = show_latest_funding_rounds ? "legend-item clickable" : "legend-item clickable dim";

        const metrics = [];

        if( !show_latest_funding_rounds ){
            metrics.push({
                type: 'scatter',
                mode: 'lines',
                name: 'LFR Price',
                x: date,
                y: latest_funding_rounds,
                line: {
                    dash: 'dashdot',
                    color: '#FFF'
                },
                hoverinfo: 'none'
            })
        }

        metrics.push({
            type: 'scatter',
            mode: 'lines',
            name: 'Min Buy Price',
            fill: 'tozeroy',
            x: date,
            y: min_buy,
            fillcolor: 'rgba(255,255,255,0.3)',
            line: { color: '#7FB7DF' },
            hoverinfo: 'none',
        })

        metrics.push({
            type: "scatter",
            mode: 'lines',
            name: 'Max Buy Price',
            fill: 'tonexty',
            x: date,
            y: max_buy,
            fillcolor: 'rgba(127,183,223,0.3)',
            line: { color: '#7FB7DF' },
            hoverinfo: 'none',
        });

        metrics.push({
            type: 'scatter',
            mode: 'lines',
            name: 'Buy Connector',
            x: date,
            y: buy_connector,
            line: { dash: 'dot', color: '#7FB7DF' },
            hoverinfo: 'none',
        });

        metrics.push({
            type: "scatter",
            mode: "lines",
            name: 'Min Sell Price',
            fill: 'tozeroy',
            x: date,
            y: min_sell,
            fillcolor: 'rgba(255,255,255,0.3)',
            line: { color: '#CDCDCD' },
            hoverinfo: 'none',
        })

        metrics.push({
            type: "scatter",
            mode: "lines",
            name: 'Max Sell Price',
            fill: 'tonexty',
            x: date,
            y: max_sell,
            fillcolor: 'rgba(205,205,205,0.3)',
            line: { color: '#CDCDCD' },
            hoverinfo: 'none',
        });

        metrics.push({
            type: "scatter",
            mode: "lines",
            name: 'Sell Connector',
            x: date,
            y: sell_connector,
            line: { dash: 'dot', color: '#CDCDCD' },
            hoverinfo: 'none'
        });

        if( show_price_trend ){
            metrics.push({
                type: "scatter",
                mode: "lines",
                name: "Price Trend",
                x: date,
                y: price_trend,
                line: { color: '#263f7f' },
                hoverinfo: 'none'
            })
        }

        if( show_latest_funding_rounds ){
            metrics.push({
                type: 'scatter',
                mode: 'lines',
                name: 'LFR Price',
                x: date,
                y: latest_funding_rounds,
                line: {
                    dash: 'dashdot',
                    color: '#228B22'
                },
                hoverinfo: 'none'
            })
        }

        if( loginUserData.activeAccessRight &&
            loginUserData.activeAccessRight.access_right &&
            loginUserData.activeAccessRight.access_right.permission != content_placeholders.user_type.trial_user ){
                metrics.push(
                    {
                        type: 'scatter',
                        mode: 'markers',
                        name: 'Gateway Live Offers',
                        marker: { color: '#FCA310', size: 12 },
                        x: live_offers_date,
                        y: live_offers_price,
                        hoverinfo: 'none',
                        hovermode: 'closest',
                        cliponaxis: false
                    }
                )

                metrics.push(
                    {
                        type: 'scatter',
                        mode: 'markers',
                        name: 'Gateway Live Bids',
                        marker: { color: '#4561a8', size: 10 },
                        x: live_demands_date,
                        y: live_demands_price,
                        hoverinfo: 'none',
                        hovermode: 'closest',
                        cliponaxis: false
                    }
                )
            }

        const price_layout = {
            autosize: true,
            xaxis: {
                range: [ date[0], date[date.length-1] ],
                showgrid: false,
                fixedrange: true,
            },
            yaxis: {
                title: {
                    text: 'Price $ / share',
                    font: {
                        size: 12
                    },
                },
                range: [min_y_axis, max_y_axis],
                showgrid: false,
                fixedrange: true,
            },
            showlegend: false,
            margin: {
                l:80,
                r:20,
                t:20,
                b:30
            },
            datarevision: revision,
            height: 270
        }

        const volume_layout = {
            autosize: true,
            xaxis: {
                range: [ date[0], date[date.length-1] ],
                showgrid: false,
                fixedrange: true,
                visible: false,
            },
            yaxis: {
                title: {
                    text: 'Vol. $ M*',
                    font: {
                        size: 12
                    },
                },
                range: [0, max_y2_axis],
                showgrid: false,
                overlaying: 'y',
                fixedrange: true,
                hoverformat: '.2f',
            },
            showlegend: false,
            margin: {
                l:80,
                r:20,
                t:20,
                b:30
            },
            datarevision: revision,
            height: 100
        }

        const volume_data = [

            {
                type: 'bar',
                x: date,
                y: sell_volume,
                name: 'Sell Vol.',
                yaxis: 'y',
                marker: { color: 'rgba(68,84,106,.2)' },
                hovertemplate: 'Sell: %{y:$.0f}M<extra></extra>'
            },

            {
                type: 'bar',
                x: date,
                y: buy_volume,
                name: 'Buy Vol.',
                yaxis: 'y',
                marker: { color: 'rgba(127,183,223,0.3)' },
                hovertemplate: 'Buy: %{y:$.0f}M<extra></extra>'
            }

        ];

        if( loginUserData.activeAccessRight &&
            loginUserData.activeAccessRight.access_right &&
            loginUserData.activeAccessRight.access_right.permission == content_placeholders.user_type.trial_user ){
                const blur_index = last_blur.index > -1 ? last_blur.index : 0;
                const custom_text = date.map((item, ii) => ii < blur_index ? "Get onboarded to unlock data." : "");

                volume_data[0]['text'] = custom_text;

                price_layout['shapes'] = [{
                    type: 'rect',
                    xref: 'x',
                    yref: '',
                    x0: date[0],
                    y0: min_y_axis,
                    x1: date[blur_index],
                    y1: max_y_axis,
                    fillcolor: 'rgba(38,63,127,0.1)',
                    opacity: 1,
                    line: {
                        width: 0
                    }
                }];

                volume_layout['shapes'] = [{
                    type: 'rect',
                    xref: 'x',
                    yref: '',
                    x0: date[0],
                    y0: 0,
                    x1: date[blur_index],
                    y1: max_y2_axis,
                    fillcolor: 'rgba(38,63,127,0.1)',
                    opacity: 1,
                    line: {
                        width: 0
                    },
                }]
            }

        return (total_max_buy + total_min_buy + total_max_sell + total_min_sell) == 0 ? (
            <section className="price-chart-section">
                <span>Insufficient data over the past 120 days.</span>
            </section>
        ) : (
            <section className="price-chart-section">
                <div className="row">
                    <div className="col col-12">
                        <div className="legend-wrapper">
                            <div className="legend-item">
                                <span className="color-label bid-range"></span>
                                <span className="label-text">
                                    {
                                        annotation.min_buy && annotation.max_buy && annotation.lfr_price && annotation.lfr_val ?
                                            <Fragment>Demand Range (Implied Val.<sup>E</sup>)</Fragment> : <Fragment>Demand Range</Fragment>
                                    }
                                </span>
                                {annotation.min_buy || annotation.max_buy ?

                                        <span className={annotation_item_bid_class}>
                                            ${annotation.min_buy.toFixed(1)} - {annotation.max_buy.toFixed(1)} p.s.
                                            {
                                                annotation.min_buy && annotation.max_buy &&
                                                annotation.lfr_price && annotation.lfr_val ?
                                                    <Fragment>
                                                        &nbsp;({
                                                            annotation.min_buy / annotation.lfr_price * annotation.lfr_val >= 1000 ?
                                                                "$" + (annotation.min_buy / annotation.lfr_price * annotation.lfr_val / 1000).toFixed(1) + "B" :
                                                                "$" + (annotation.min_buy / annotation.lfr_price * annotation.lfr_val).toFixed(0) + "M"
                                                        } - {
                                                            annotation.max_buy / annotation.lfr_price * annotation.lfr_val >= 1000 ?
                                                                "$" + (annotation.max_buy / annotation.lfr_price * annotation.lfr_val / 1000).toFixed(1) + "B" :
                                                                "$" + (annotation.max_buy / annotation.lfr_price * annotation.lfr_val).toFixed(0) + "M"
                                                        })
                                                    </Fragment> : ''
                                            }
                                        </span>

                                    :
                                    <span className={annotation_item_bid_class}>
                                        No Demand
                                    </span>
                                    }
                            </div>
                            <div className="legend-item">
                                <span className="color-label offer-range"></span>
                                <span className="label-text">{
                                    annotation.min_sell && annotation.max_sell && annotation.lfr_price && annotation.lfr_val ?
                                        <Fragment>Offer Range (Implied Val.<sup>E</sup>)</Fragment> : <Fragment>Offer Range</Fragment>
                                }</span>
                                {annotation.min_sell || annotation.max_sell ?
                                    <span className={annotation_item_offer_class}>
                                        ${annotation.min_sell.toFixed(1)} - {annotation.max_sell.toFixed(1)} p.s.
                                        {
                                            annotation.min_sell && annotation.max_sell &&
                                            annotation.lfr_price && annotation.lfr_val ?
                                                <Fragment>
                                                    &nbsp;({
                                                        annotation.min_sell / annotation.lfr_price * annotation.lfr_val >= 1000 ?
                                                            "$" + (annotation.min_sell / annotation.lfr_price * annotation.lfr_val / 1000).toFixed(1) + "B" :
                                                            "$" + (annotation.min_sell / annotation.lfr_price * annotation.lfr_val).toFixed(0) + "M"
                                                    } - {
                                                        annotation.max_sell / annotation.lfr_price * annotation.lfr_val >= 1000 ?
                                                            "$" + (annotation.max_sell / annotation.lfr_price * annotation.lfr_val / 1000).toFixed(1) + "B" :
                                                            "$" + (annotation.max_sell / annotation.lfr_price * annotation.lfr_val).toFixed(0) + "M"
                                                    })
                                                </Fragment> : ''
                                        }
                                    </span>
                                    :
                                    <span className={annotation_item_offer_class}>
                                        No offers
                                    </span>
                                    }
                            </div>

                            <div className="legend-item date-item">
                                <span className="color-label"></span>
                                <span className="label-text"></span>
                                {annotation.date ?
                                    <span className="annotation-item pricing-date">{annotation.date}</span>
                                    : ''}
                            </div>

                            {
                                loginUserData.activeAccessRight &&
                                loginUserData.activeAccessRight.access_right &&
                                loginUserData.activeAccessRight.access_right.permission != content_placeholders.user_type.trial_user &&
                                live_offers.length ? <div className="legend-item live-item">
                                    <span className="color-label live-offers"></span>
                                    <span className="label-text">Live Offers</span>
                                </div> : ''
                            }
                            {
                                loginUserData.activeAccessRight &&
                                loginUserData.activeAccessRight.access_right &&
                                loginUserData.activeAccessRight.access_right.permission != content_placeholders.user_type.trial_user &&
                                live_demands.length ? <div className="legend-item live-item">
                                    <span className="color-label live-bids"></span>
                                    <span className="label-text">Live Demand</span>
                                </div> : ''
                            }

                            <div className="clear"></div>

                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col col-12">
                        <div className="legend-wrapper mt-1">
                            <div className={price_trend_classname}
                                onClick={e => this.handleAdditionalMetrixClick('show_price_trend', e)}>
                                <span className="color-label price-trend"></span>
                                <span className="label-text">Price Trend</span>
                            </div>
                            {
                                latest_funding_rounds.length && total_lfr ? <div className={latest_funding_rounds_classname}
                                    onClick={e => this.handleAdditionalMetrixClick('show_latest_funding_rounds', e)}>
                                    <span className="color-label anchor-lfr">
                                        <span className="dot"></span>
                                        <span className="line"></span>
                                        <span className="dot"></span>
                                    </span>
                                    <span className="label-text">LFR Price<sup>#</sup></span>
                                    {
                                        show_latest_funding_rounds && annotation.lfr_price
                                            ? <span className="label-value">${annotation.lfr_price.toFixed(1)}p.s. {
                                                annotation.lfr_val ?
                                                    <Fragment>(${annotation.lfr_val >= 1000 ?
                                                        (annotation.lfr_val / 1000).toFixed(1) + "B" : annotation.lfr_val.toFixed(0) + "M" })</Fragment> : ""
                                            }</span> : ''
                                    }
                                    </div> : ''
                            }
                        </div>
                    </div>
                </div>
                <div className="row">

                    <div className="col col-12">
                        {
                            date.length && revision && finishedCollectFundingRounds &&

                                <Fragment>

                                    <div className="chart-wrapper">
                                        <Plot
                                            data={metrics}
                                            layout={price_layout}
                                            revision={revision}
                                            config={{
                                                displayModeBar: false,
                                                responsive: true
                                            }}
                                            onHover={this.handleChartHover.bind(this)}
                                            onUnhover={this.handleChartUnhover.bind(this)}
                                            onClick={this.handleChartClick.bind(this)}
                                            style={{ width: '100%' }}
                                        />
                                    </div>

                                    <div className="chart-wrapper">
                                        <Plot
                                            data={volume_data}
                                            layout={volume_layout}
                                            onHover={this.handleVolumeHover.bind(this)}
                                            revision={revision}
                                            config={{
                                                displayModeBar: false,
                                                responsive: true
                                            }}
                                            style={{ width: '100%' }}
                                        />
                                    </div>

                                </Fragment>

                        }
                        <p className="footnote">*Daily demand / offers volume (Round to the nearest million)</p>
                        {
                            latest_funding_rounds.length && total_lfr ?
                                <p className="footnote"><sup>#</sup>Latest funding round (LFR) share price as of that date</p> :
                                ''
                        }
                        <p className="footnote">All figures in US$</p>
                        <p className="footnote"><sup>E</sup> Estimated</p>
                    </div>

                </div>

                <div className="live-offers-overlay">
                    <div className="content">

                        {
                            live_offers.length ? <div className={live_offer_panel_classname}>
                                    <h6>Live Offers</h6>
                                    {
                                        live_offers.map((offer, index) => {
                                            return (
                                                <div className="historical-price-range-section"
                                                    key={index}
                                                    onClick={(e) =>
                                                        this.props.handleExpressBuySellDialogOpen(
                                                            {
                                                                price: offer.price,
                                                                min_size: 0,
                                                                company: companyDetailResponse.name,
                                                                logo: companyDetailResponse.logo,
                                                                dialogTitle: "EXPRESS BUY INTEREST",
                                                                interest_type: "INVEST",
                                                            },
                                                            e
                                                        )}>
                                                    <div className="range-value">
                                                        <span>Price: </span>
                                                        {
                                                            loginUserData.activeAccessRight &&
                                                            loginUserData.activeAccessRight.access_right &&
                                                            loginUserData.activeAccessRight.access_right.permission == content_placeholders.user_type.trial_user ?
                                                                <span className="blurred white">${offer.price.toFixed(1)} p.s.</span> :
                                                                <span>${offer.price.toFixed(1)} p.s.</span>
                                                        }
                                                    </div>

                                                    <div className="range-value">
                                                        <span>Size: </span>
                                                        {
                                                            loginUserData.activeAccessRight &&
                                                            loginUserData.activeAccessRight.access_right &&
                                                            loginUserData.activeAccessRight.access_right.permission == content_placeholders.user_type.trial_user ?
                                                                <span className="blurred white">{offer.deal_size_range ? offer.deal_size_range.replaceAll(' mn', ' M') : '-'}</span> :
                                                                <span>{offer.deal_size_range ? offer.deal_size_range.replaceAll(' mn', ' M') : '-'}</span>
                                                        }

                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                    <div className="clear"></div>
                                </div> : ''
                        }

                        {
                            live_demands.length ? <div className={live_demand_panel_classname}>
                                    <h6>Live Demand</h6>
                                    {
                                        live_demands.map((demand, index) => {
                                            return (
                                                <div className="historical-price-range-section buy-section"
                                                    key={index}
                                                    onClick={(e) =>
                                                        this.props.handleExpressBuySellDialogOpen(
                                                            {
                                                                price: demand.price,
                                                                min_size: 0,
                                                                company: companyDetailResponse.name,
                                                                logo: companyDetailResponse.logo,
                                                                dialogTitle: "EXPRESS SELL INTEREST",
                                                                interest_type: "SELL",
                                                            },
                                                            e
                                                        )}>
                                                    <div className="range-value">
                                                        <span>Price: </span>
                                                        {
                                                            loginUserData.activeAccessRight &&
                                                            loginUserData.activeAccessRight.access_right &&
                                                            loginUserData.activeAccessRight.access_right.permission == content_placeholders.user_type.trial_user ?
                                                                <span className="blurred white">${demand.price.toFixed(1)} p.s.</span> :
                                                                <span>${demand.price.toFixed(1)} p.s.</span>
                                                        }
                                                    </div>
                                                    <div className="range-value">
                                                        <span>Size: </span>
                                                        {
                                                            loginUserData.activeAccessRight &&
                                                            loginUserData.activeAccessRight.access_right &&
                                                            loginUserData.activeAccessRight.access_right.permission == content_placeholders.user_type.trial_user ?
                                                                <span className="blurred white">{demand.deal_size_range ? demand.deal_size_range.replaceAll(' mn', ' M') : '-'}</span> :
                                                                <span>{demand.deal_size_range ? demand.deal_size_range.replaceAll(' mn', ' M') : '-'}</span>
                                                        }

                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                    <div className="clear"></div>
                                </div> : ''
                        }

                        <div className="clear"></div>
                    </div>

                </div>

            </section>
        )
    }

}

export default PriceChart;
