import { connect } from "react-redux";

import { default as Dashboard } from "./Dashboard";

import { CollapseSidebar } from "../../root/Action";

import {
    getFundraisingParameters,
    getGoalSummary,
    getProspectSummary,
    getInvestorSummary,
    getVettedInvestorList,
	getSimilarCompany,
	getCompanyVerticals,
	getCompanyDetail,
} from './Action';

export default connect(
	(state) => ({
		collapseSidebar: state.root.reducer.collapseSidebar,
		loginUserData: state.login.reducer.loginUserData,
		loaderState: state.root.reducer.loaderState,
		appConfig: state.root.reducer.appConfig,
		fundraising_params: state.boostDashboard.reducer.fundraising_params,
		prospect_summary: state.boostDashboard.reducer.prospect_summary,
		vetted_investors_response: state.boostDashboard.reducer.vetted_investors_response,
		goal_summary: state.boostDashboard.reducer.goal_summary,
		similar_companies: state.boostDashboard.reducer.similar_companies,
		company_verticals: state.boostDashboard.reducer.company_verticals,
		company_detail: state.boostDashboard.reducer.company_detail,
	}),
	(dispatch) => ({
		CollapseSidebar: (payload) => dispatch(CollapseSidebar(payload)),
		getFundraisingParameters: payload => dispatch(getFundraisingParameters.request(payload)),
		getGoalSummary: payload => dispatch(getGoalSummary.request(payload)),
		getProspectSummary: payload => dispatch(getProspectSummary.request(payload)),
		getInvestorSummary: payload => dispatch(getInvestorSummary.request(payload)),
		getVettedInvestorList: payload => dispatch(getVettedInvestorList.request(payload)),
		getSimilarCompany: (payload) => dispatch(getSimilarCompany.request(payload)),
		getCompanyVerticals: (payload) => dispatch(getCompanyVerticals.request(payload)),
		getCompanyDetail: payload => dispatch(getCompanyDetail.request(payload)),
	})
)(Dashboard);