import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Styles } from "../../styles";

import { Snackbar } from "../../common";
import { content_placeholders } from '../../../config';
import {
	ExpressBuySellDialog,
	Sidebar,
	RequestInformationDialog,
	FullScreenPriceChartDialog,
} from "./components";

import { default as CompanyDetailContent } from './CompanyDetailContent';
import {
	default as DealShareDialog
} from './DealShareDialog';
import {
	getTodayHistoricalPrice,
	isMobile,
	showBlurred,
} from "../../../utils/util";
import { GetToken } from "../../../utils";
import { sendGAEvent } from "../../../utils/ga";
import { sendLogEvent } from "../../../utils/ga";
import { WatchListDialog } from "../../common/watchlist/dialog";
import { PlaceInterestPositionWrapper } from '../common';
import {
    WatchlistSnackbar,
} from '../../orderbook/common/components';

class CompanyDetail extends Component {
	state = {
		expressInterest: {
			open: false,
			price_slider: true,
			deal: {
				price: 0,
				min_size: 0,
				company: "",
				logo: "",
			},
			target: {
				price: 0,
				notional: 0,
				comment: "",
				firmInterest: 0,
				implied_valuation: 0,
			},
		},
		requestInformation:{
			open: false,
			dialogTitle:'',
			qs_payload: {
				comment: {
					min_investment_size_m: false,
					dataroom_access: false,
					financial_info: false,
					share_class: false,
					fee_structure: false,
					timeline: false,
					company_presentation: false,
					management_call: false,
					gp_info: false,
					company_info: false,
					others: null,
					offer: null,
					demand: null
				}
			},
		},
		dealSharing: {
			open: false,
		},
		tabIndex: 0,
		element: {},
		snackBar: {
			open: false,
			error: false,
			message: "",
		},
		price: "",
		comment: "",
		formType: "",
		toggleWatchError: false,
		toggleWatchListMessage: "",
		expressInterestResponseMessage: "",
		investorPanelExpanded: false,
		showExpandButton: false,
		requestInformationtResponseMessage: "",
		watchListDialog:{
			open: false,
			companyId:0,
			newCompanyName:''
		},
		collapse: true,
		full_screen_open: false,
		isMobile: false,
		express_interest: {
			open: false,
			prompt_express: false,
			payload: {
				company_id: 0,
				company_name: '',
				price: 0,
			}
		}
	};

	constructor(props) {
		super(props);
		this.handleSubmitCommitment = this.handleSubmitCommitment.bind(this);
		this.handleSubmitRequestInformationCommitment = this.handleSubmitRequestInformationCommitment.bind(this);

		this.handleChangePriceInputType = this.handleChangePriceInputType.bind( this );
		
		this.dummyRef = React.createRef();
		this.TermsContainer = React.createRef();
		this.IndicativePrice = React.createRef();
		this.IndicativeTimeline = React.createRef();
	}

	componentDidMount() {
		const {
			match: {
				params: { id, type },
			},
			getLatestFundingRound,
			getFundingRound,
			getVerticals,
			getDealTimeline,
			getAllCompanies,
			getHistoricalPrice,
			// getDealOfferDetail,
			getLiveOffers,
            getLiveDemand,
			getLiveOffersWatchlist,
            getLiveDemandWatchlist,
			getDealHighlight,
			getDealType,
			getGPDetail,
			resetSnackBar,
		} = this.props;
		// console.log(this.props.location.state);
		if (
			this.props.location &&
			this.props.location.state &&
			this.props.location.state.type
		) {
			switch (this.props.location.state.type) {
				case "showcase":
					this.setState({
						formType: "showcase",
					});
					break;
				case "watchList":
					this.setState({
						formType: "watchList",
					});
					break;
				default:
					break;
			}
		}
		resetSnackBar()
		getAllCompanies({
			public_url: false,
			company_identifier: id,
		});

		// getLatestFundingRound({ 
		// 	root_path: 'v2', 
		// 	sub_path: 'wealth', 
		// 	company_identifier: '',
		// 	qs: { company_id: id }
		// });

		// getVerticals({ company_id: id });

		getDealTimeline({ 
			public_url: false,
			// root_path: 'v2', 
			// sub_path: 'wealth',
			// company_identifier: '',
			qs: { company_id: id } 
		});

		getDealHighlight({ 
			// root_path: 'v2', 
			// sub_path: 'wealth', 
			public_url: false,
			qs: { company_id: id } 
		});

		getDealType({ 
			public_url: false,
			qs: { company_id: id }
		});

		getGPDetail({ 
			public_url: false,
			// root_path: 'v2', 
			// sub_path: 'wealth',
			// company_identifier: '',
			qs: { company_id: id, }
		});

		setTimeout(function(){
			getHistoricalPrice({ 
				// root_path: 'v2',
				// sub_path: 'wealth-get-weighted-price',
				public_url: false,
				qs: { company_id: id, last_N_day: 120 } });
			getFundingRound({ 
				public_url: false,
				// root_path: 'v2',
				// sub_path: 'wealth',
				// company_identifier: '',
				qs: { company_id: id, show_all: 1 }
				});
			// getDealOfferDetail({ root_path: 'v2', company_identifier: id });
			getLiveOffers({ 
				public_url: false,
				// root_path: 'v2', 
				// sub_path: 'wealth/offers',
				// company_identifier: '', 
				qs: { company_id: id, live: true, asc_or_desc: 'asc' } });
        	getLiveDemand({ 
				public_url: false,
				// root_path: 'v2', 
				// sub_path: 'wealth/demands',
				// company_identifier: '', 
				qs: { company_id: id, live: true } });
			getLiveOffersWatchlist({
				root_path: 'v2',
				sub_path: 'wealth/offers/watchlist-total-notional',
				company_identifier: '',
				qs: { company_id: id, live: true, asc_or_desc: 'asc' }
			});
			getLiveDemandWatchlist({
				root_path: 'v2',
				sub_path: 'wealth/demands/watchlist-total-notional',
				company_identifier: '',
				qs: { company_id: id, live: true }
			});
		}, 1500);

		this.setState({ 
			isMobile: isMobile()
		});

		window.addEventListener('resize', this.handleWindowResize);
		document.addEventListener('visibilitychange', this.handleWindowVisibilitychange);
		sendLogEvent(false, 'companydetail_componentDidMount', {}, this.props);
		document.documentElement.scrollTop = 0
	}

	componentDidUpdate(prevProps, prevState, snapshot){
		const InvestRow = document.getElementById('InvestRow');
		const InvestorsItemWrapper = document.getElementById('InvestorsItemWrapper');
		if( InvestRow && InvestorsItemWrapper ){
			const { showExpandButton, investorPanelExpanded } = this.state;
			if( InvestRow.clientHeight < InvestorsItemWrapper.clientHeight && !showExpandButton ){
				this.setState({ showExpandButton : true });
			} else if ( InvestRow.clientHeight > InvestorsItemWrapper.clientHeight && !investorPanelExpanded && showExpandButton ) {
				this.setState({ showExpandButton : false });
			}
		}
	}

	componentWillUnmount(){
		window.removeEventListener('resize', this.handleWindowResize);
		document.removeEventListener('visibilitychange', this.handleWindowVisibilitychange);
    }

	handleWindowVisibilitychange = (evt) => {
		const { ValidateUserInBackground } = this.props;
		if(document.visibilityState === 'visible'){
            const accessToken = GetToken();
            if( accessToken && accessToken.token && accessToken.email && accessToken.set_time ){
                accessToken['currentUserType'] = content_placeholders.user_type.wealth_user
                ValidateUserInBackground(accessToken);
            }
		}
	}
	
	handleWindowResize = (evt) => {
		if(this.state.isMobile !== isMobile()){
			this.setState({ 
				isMobile: isMobile()
			});
		}
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		const {
			expressInterestError,
			expressInterestResponseMessage,
			toggleWatchError,
			toggleWatchListMessage,
			requestInformationtError,
			requestInformationtResponseMessage,
			match: {
				params: { id },
			},
		} = nextProps;

		if (
			expressInterestResponseMessage !=
			prevState.expressInterestResponseMessage &&
			expressInterestResponseMessage !== undefined
		) {
			const new_open_style = expressInterestResponseMessage.trim()
				? true
				: false;
			return {
				snackBar: {
					open: new_open_style,
					error: expressInterestError,
					message: expressInterestResponseMessage,
				},
			};
		} else if (
			requestInformationtResponseMessage !=
			prevState.requestInformationtResponseMessage &&
			requestInformationtResponseMessage !== undefined
		) {
			const new_open_style = requestInformationtResponseMessage.trim()
				? true
				: false;
			return {
				snackBar: {
					open: new_open_style,
					error: requestInformationtError,
					message: requestInformationtResponseMessage,
				},
			};
		} else if (
			toggleWatchListMessage != prevState.toggleWatchListMessage &&
			toggleWatchListMessage !== undefined
		) {
			const new_open_style = toggleWatchListMessage.trim() ? true : false;
			return {
				snackBar: {
					open: new_open_style,
					error: toggleWatchError,
					message: toggleWatchListMessage,
				},
			};
		} else {
			return null;
		}
	}

	handleSidebarCollapse = () => {
		const { collapse } = this.state;
		this.setState({ collapse: !collapse });
	}

	pageGoBack = () => {
		// this.props.history.goBack();
		const { history } = this.props;
		history.push('/wealth/pipeline');
	};

	doToggleWatchList1 = () => {
		let { AllCompaniesResponse, toggleWatchList } = this.props;
		const watchListDialog = {
			open: true,
			companyId:AllCompaniesResponse.id,
			active: AllCompaniesResponse.watchlist,
		}
		this.setState({
			watchListDialog: watchListDialog,
		})
	};

	handleCloseWatchListDialog = (event) =>{
		const { watchListDialog } = this.state;
		watchListDialog["open"] = false;
		watchListDialog["companyId"] = 0;
		watchListDialog["newCompanyName"]='';
		this.setState({ watchListDialog: watchListDialog });
	}

	handleWatchListStar = (companyId, active) => {
		// let { AllCompaniesResponse } = this.props;
		// AllCompaniesResponse.watchlist = active;
		// this.setState({
		// 	AllCompaniesResponse: AllCompaniesResponse,
		// });
	}

	handleChangeValue = (evt) => {
		const { name, value } = evt.target;
		const { expressInterest } = this.state;
		expressInterest["target"][name] = value;
		this.setState({ expressInterest: expressInterest });
	};

	onValuationChange = (event, newValue) => {
		let target_price = newValue;

		if(typeof target_price == 'string'){
			target_price = target_price.replace(/[^\d.]/g, '');
			target_price = parseFloat(target_price);
		}
		
		// console.log(typeof target_price);

		// let re = /^([0-9]+)(?:\.)(?:[0-9]+)$/;
		// console.log( re.exec(target_price) );
	
		const { latestFundingRound } = this.props;
		const { expressInterest } = this.state;
		expressInterest["target"]["price"] = target_price;

		let implied_val = ""
		if( latestFundingRound.selected_price && latestFundingRound.selected_post_money_valuation ) {
			implied_val = (target_price / latestFundingRound.selected_price) * latestFundingRound.selected_post_money_valuation;
			implied_val = implied_val >= 1000 ? ( implied_val / 1000 ).toFixed(1) + "B" : implied_val.toFixed(0) + "M";
		} 
		
		expressInterest["target"]["implied_valuation"] = implied_val;
		this.setState({ expressInterest: expressInterest });
	};

	setValuation = (min_sell) => {
		const { expressInterest } = this.state;
		expressInterest["target"]["price"] = min_sell;
		this.setState({ expressInterest: expressInterest });
	};

	handleSnackbarClose = (evt, reason) => {
		if (reason === "clickaway") {
			return;
		}
		const { resetSnackBar } = this.props;
		resetSnackBar();

		this.setState({
			snackBar: {
				open: false,
				error: false,
				message: "",
			},
			toggleWatchListMessage: "",
			expressInterest: {
				open: false,
				deal: {
					price: 0,
					min_size: 0,
					company: "",
					logo: "",
				},
				target: {
					price: 0,
					notional: 0,
					comment: "",
					other_comment: "",
					firmInterest: 0,
					implied_valuation: 0,
				},
			},
		});
	};

	handleExpressBuySellDialogOpen = (deal, evt) => {
		let { 
			historicalPrice, 
			latestFundingRound, 
			loginUserData,
			loginUserData: { activeAccessRight: { access_right: { permission } } },
		} = this.props;
		const event_payload = {
			company_id: this.props.match.params.id,
		}
		if( 
			permission == content_placeholders.user_type.trial_user
		){
			event_payload['onboarding'] = 1;
		} else {
			let todayHistoricalPrice = getTodayHistoricalPrice(
				historicalPrice["hist_data"]
			);
			let { min, max } = todayHistoricalPrice;
			let min_value = min;
			let default_price = deal.price ? deal.price : min_value;
			evt.preventDefault();
			const { expressInterest } = this.state;
			expressInterest["open"] = true;
			expressInterest["deal"] = deal;
			expressInterest["target"]["price"] = default_price;

			
			let implied_val = ""
			if( latestFundingRound.selected_price && latestFundingRound.selected_post_money_valuation ){
				implied_val = (default_price / latestFundingRound.selected_price) * latestFundingRound.selected_post_money_valuation;
				implied_val = implied_val >= 1000 ? ( implied_val / 1000 ).toFixed(1) + "B" : implied_val.toFixed(0) + "M";
			} 
			expressInterest["target"]['implied_valuation'] = implied_val;
			
			expressInterest["dialogTitle"] = deal.dialogTitle;
			// expressInterest['company'] = companyDetailResponse.id;
			this.setState({ expressInterest: expressInterest });
			event_payload['expressInterest'] = expressInterest;
		}
		
		sendGAEvent({
			type:"click_live_offer",
			data: event_payload,
		});
	};

	handleExpressBuySellDialogClose = () => {
		const { expressInterest } = this.state;
		expressInterest["open"] = false;
		this.setState({ expressInterest: expressInterest });
	};

	handleRequestInformationDialogOpen = (request, evt) => {
		const event_payload = {
			company_id: this.props.match.params.id,
		}
		evt.preventDefault();
		const { requestInformation } = this.state;
		requestInformation["open"] = true;
		requestInformation["dialogTitle"] = request.dialogTitle;
		this.setState({ requestInformation: requestInformation });
		event_payload['requestInformation'] = requestInformation;
		
		sendGAEvent({
			type:"click_live_offer",
			data: event_payload,
		});
	};

	handleRequestInformationDialogClose = () => {
		const { requestInformation } = this.state;
		requestInformation["open"] = false;
		requestInformation["dialogTitle"] = '';
		requestInformation['qs_payload'] = {
			comment: {
				min_investment_size_m: false,
				dataroom_access: false,
				financial_info: false,
				share_class: false,
				fee_structure: false,
				timeline: false,
				company_presentation: false,
				management_call: false,
				gp_info: false,
				company_info: false,
				others: null,
				offer: null,
				demand: null
			}
		} ;
		this.setState({ requestInformation: requestInformation });
	};

	handleRequestInformationChangeValue = (key, value) => {
		const { requestInformation } = this.state;
		requestInformation["qs_payload"][key] = value;
		this.setState({ requestInformation: requestInformation });
	};

	handleCancelCommitment = () => {
		let expressInterest = {
			open: false,
			deal: {
				price: 0,
				min_size: 0,
				company: "",
				logo: "",
			},
			target: {
				price: 0,
				notional: 0,
				comment: "",
				other_comment: "",
				firmInterest: 1,
			},
		};
		let requestInformation = {
			open: false,
			dialogTitle:'',
			qs_payload: {},
		};
		this.setState({ 
			expressInterest: expressInterest, 
			requestInformation: requestInformation 
		});
	};

	handleSubmitCommitment = (evt) => {
		const {
			expressInterest: { deal, target },
		} = this.state;

		if (target.price || deal.price) {
			const payload = {
				price: target.price ? target.price : deal.price,
				notional: Number(target.notional),
				interest_type: deal.interest_type,
				firm_interest: Number(target.firmInterest),
				// interest_type: deal.price ? "CLUBDEAL_INVEST" : "CLUBDEAL_INTEREST",
			};
			const {
				expressInterest,
				clubDetail: { company },
				AllCompaniesResponse,
			} = this.props;

			let all_comment = '';
			if( target.comment ){
				all_comment += target.comment;
			}
			if( target.other_comment ){
				// all_comment += "\n" + target.other_comment;
				payload['note'] = target.other_comment;
			}

			if( all_comment.trim() ) payload['comment'] = all_comment.trim();
			payload["company"] = AllCompaniesResponse.id;
			expressInterest(payload);
			this.handleCancelCommitment();
		} else {
			this.setState({
				snackBar: {
					open: true,
					error: true,
					message: deal.price
						? "Please provide us your target notional"
						: "Please provide us your target price and notional",
				},
			});
		}
	};

	handleSubmitRequestInformationCommitment = (evt) => {
		const {
			requestInformation: { qs_payload },
		} = this.state;
		const {
			requestInformation,
			AllCompaniesResponse,
			dealType,
		} = this.props;
		if(qs_payload['prelim_investment_size_m'] && qs_payload['prelim_investment_size_m'].length>0){
			qs_payload['prelim_investment_size_m'] = parseFloat(qs_payload['prelim_investment_size_m'])
		}
		if(qs_payload['others'] === ""){
			delete qs_payload['others']
		}
		if(qs_payload['offer'] && dealType && dealType.offer_id){
			qs_payload['offer']['id'] = dealType.offer_id
		}
		const payload = {}
		payload["company_id"] = AllCompaniesResponse.id;
		payload["interest_type"] = 'WEALTH_REQUEST';
		payload["comment"] = {
			"min_investment_size_m": false,
        	"dataroom_access": false,
        	"financial_info": false,
        	"share_class": false,
        	"timeline": false,
        	"fee_structure": false,
			"company_presentation": false,
			"management_call": false,
			"gp_info": false,
			"company_info": false,
        	"others": " ",
			"offer": null,
			"demand": null
		};
		for (const [key, value] of Object.entries(payload["comment"])) {
			if(key in qs_payload){
				payload['comment'][key]=qs_payload[key]
			}
		}
		requestInformation(payload);
		this.handleCancelCommitment();
	};

	handleChangePriceInputType(evt){
		const { expressInterest } = this.state;
		expressInterest['price_slider'] = !expressInterest.price_slider;
		this.setState({ expressInterest: expressInterest });
	}

	handleShowDealShareDialog = (evt) => {
		const { match : { params: { id } } } = this.props;
		const { dealSharing } = this.state;
		dealSharing['open'] = !dealSharing['open'];
		
		if( dealSharing['open'] ){
			const { getRiskDisclosure } = this.props;
			getRiskDisclosure({ 
				public_url: false,	
				root_path: 'v2/wealth', 
				sub_path: 'search', 
				company_identifier: id,
				qs: { company_id: id } 
			});
		}
		
		this.setState({ dealSharing: dealSharing });
	}

	handleAcknowledgeRiskDisclosure = (evt) => {
		const { confirmRiskDisclosure, shareDeal } = this.props;
		confirmRiskDisclosure();
		shareDeal({ 
			company_id: this.props.match.params.id 
		});
	}

	doToggleWatchList = (parms, event) => {
		let { companyId, watchlist } = parms;
		if(event && event.stopPropagation) event.stopPropagation();
		if(event && event.preventDefault) event.preventDefault();
		const { toggleWatchList } = this.props;
		toggleWatchList({
			company: companyId,
			active: !watchlist,
		});
		this.updateCompanyStar(parms);
	};

	handlePriceChartFullScreen = (event) => {
		const { full_screen_open } = this.state;
        this.setState({ full_screen_open: !full_screen_open });
	}

	handleFakeClick = (event) => {

	}

	handlePromptExpress = (evt, show) => {
		const { express_interest } = this.state;
		express_interest['prompt_express'] = show;
		this.setState({ express_interest });
	}

	handleShowExpressInterest = (evt, withData) => {
		const { express_interest } = this.state;
		express_interest['open'] = !express_interest['open'];

		if( withData ){
			const { AllCompaniesResponse, dealHighlight } = this.props;

			express_interest['payload'] = {
				company_id: AllCompaniesResponse.id,
				company_name: AllCompaniesResponse.name,
				price: dealHighlight.price,
			}
		}

		this.setState({ express_interest });
	}

	handleShowWatchlistFromSearchCompany = (evt, search_name) => {
        const company_name = search_name.trim() ? search_name.trim() : ''
        if(company_name){
            const { watchListDialog } = this.state;
		    watchListDialog["open"] = true;
		    watchListDialog["companyId"] = null;
            watchListDialog['active'] = false;
            watchListDialog['newCompanyName'] = company_name;
		    this.setState({ watchListDialog: watchListDialog });
        }
    }

	render() {
		const {
			snackBar,
			expressInterest,
			requestInformation,
			watchListDialog,
			collapse,
			full_screen_open,
			dealSharing,
			isMobile,
			express_interest,
		} = this.state;
		
		const {
			AllCompaniesResponse,
			fundingRounds,
			latestFundingRound,
			clubDetail,
			clubDealLinks,
			historicalPrice,
			loginUserData,
			loginUserData: { 
				activeAccessRight: { 
					access_right: { permission } 
				} 
			},
			gp_detail,
			dealHighlight,
			dealType,
			toggle_watchlist,
		} = this.props;


		const date_arr = latestFundingRound.selected_deal_date
			? latestFundingRound.selected_deal_date.split("-")
			: [];

		let lf_date;
		if (date_arr.length) {
			lf_date = new Date(date_arr[0], date_arr[1] - 1, date_arr[2]);
		}

		let companyDetailResponse = AllCompaniesResponse;

		let id = this.props.match.params.id;

		let user_type = ""
		if( loginUserData ){
			user_type = permission;
		}

		const sidebar_class = collapse ? "sidebar-container company-page narrow" : "sidebar-container company-page";
		const home_right_class = collapse ? "home-right company-detail-right narrow" : "home-right company-detail-right";

		let companyDetailIsBlurred = false
		if(companyDetailResponse){
			if((companyDetailResponse.description && showBlurred(companyDetailResponse.description)) ||
				(companyDetailResponse.logo_url && showBlurred(companyDetailResponse.logo_url)) ||
				(companyDetailResponse.founded && showBlurred(companyDetailResponse.founded)) ||
				(companyDetailResponse.team_size && showBlurred(companyDetailResponse.team_size)) ||
				(companyDetailResponse.total_raised && showBlurred(companyDetailResponse.total_raised)) ||
				(companyDetailResponse.headquarter_country && showBlurred(companyDetailResponse.headquarter_country)) ||
				(companyDetailResponse.website && showBlurred(companyDetailResponse.website))){
				companyDetailIsBlurred = true
			}
		}

		let gpDetailIsBlurred = false
		if(gp_detail && gp_detail.length>0){
			for(let gpItem of gp_detail){
				if((gpItem.blurb && showBlurred(gpItem.blurb)) ||
					(gpItem.logo && showBlurred(gpItem.logo)) ||
					(gpItem.headquarter_country && showBlurred(gpItem.headquarter_country)) ||
					(gpItem.investment_focus && showBlurred(gpItem.investment_focus)) ||
					(gpItem.aum && showBlurred(gpItem.aum)) ||
					(gpItem.website && showBlurred(gpItem.website))){
					gpDetailIsBlurred = true
					break
				}
			}
		}

		return (
			<div className="home">
				<div className="top-padding"></div>
				<div className="home_main">
					<div className={sidebar_class}>
						<Sidebar
							{...{ collapse, dealHighlight } }
							handleSidebarCollapse={this.handleSidebarCollapse}
							pageGoBack={this.pageGoBack}
							// formType={this.state.formType}
							companyDetailResponse={companyDetailResponse}
							clubDetail={clubDetail}
							latestFundingRound={latestFundingRound}
							clubDealLinks={clubDealLinks}
							// handleExpressBuySellDialogOpen={
							// 	this.handleExpressBuySellDialogOpen
							// }
							handleRequestInformationDialogOpen={
								this.handleRequestInformationDialogOpen
							}

							handleShowExpressInterest={this.handleShowExpressInterest}
							handlePromptExpress={this.handlePromptExpress}
							
							loginUserData={loginUserData}
						/>
					</div>
					<div className={home_right_class} id="home-right">
						<CompanyDetailContent
							pageGoBack={this.pageGoBack}
							handleShowDealShareDialog={this.handleShowDealShareDialog}
							doToggleWatchList1={this.doToggleWatchList1}
							// handleExpressBuySellDialogOpen={this.handleExpressBuySellDialogOpen}
							handleRequestInformationDialogOpen={this.handleRequestInformationDialogOpen}
							handleShowExpressInterest={this.handleShowExpressInterest}
							handlePromptExpress={this.handlePromptExpress}
							handlePriceChartFullScreen={this.handlePriceChartFullScreen}
							handleShowWatchlistFromSearchCompany={this.handleShowWatchlistFromSearchCompany}
							{...this.props}
							{...{
								company_id: id,
								companyDetailResponse: companyDetailResponse,
								isMobile: isMobile,
								gpDetailIsBlurred: gpDetailIsBlurred,
								companyDetailIsBlurred: companyDetailIsBlurred,

							}}
							/>
					</div>
				</div>

				<ExpressBuySellDialog
					open={expressInterest.open}
					deal={expressInterest.deal}
					historicalPrice={historicalPrice["hist_data"]}
					logo_url={companyDetailResponse.logo_url}
					target={expressInterest.target}
					price_slider={this.state.expressInterest.price_slider}
					handleChange={this.handleChangeValue}
					closePopup={this.handleExpressBuySellDialogClose}
					onValuationChange={this.onValuationChange}
					setValuation={this.setValuation}
					handleSubmitInterest={this.handleSubmitCommitment}
					handleCancelInterest={this.handleCancelCommitment}
					handleChangePriceInputType={this.handleChangePriceInputType}
					/>

				<RequestInformationDialog
					open={requestInformation.open}
					dialogTitle={requestInformation.dialogTitle}
					logo_url={companyDetailResponse.logo_url}
					gpInfoIncluded = { gpDetailIsBlurred }
					companyInfoIncluded = { companyDetailIsBlurred }

					{...{
						dealType,
						dealHighlight,
					}}

					handleChange={this.handleRequestInformationChangeValue}
					closePopup={this.handleRequestInformationDialogClose}
					handleSubmitRequest={this.handleSubmitRequestInformationCommitment}
					handleCancelRequest={this.handleCancelCommitment}
					/>

				<DealShareDialog
					open={dealSharing.open}
					handleShowDealShareDialog={this.handleShowDealShareDialog}
					handleAcknowledgeRiskDisclosure={this.handleAcknowledgeRiskDisclosure}
					pageGoBack={this.pageGoBack}
					doToggleWatchList1={this.doToggleWatchList1}
					handleExpressBuySellDialogOpen={this.handleExpressBuySellDialogOpen}
					handleRequestInformationDialogOpen={this.handleRequestInformationDialogOpen}
					handlePriceChartFullScreen={this.handlePriceChartFullScreen}
					{...this.props}
					{...{
						company_id: id,
						companyDetailResponse: companyDetailResponse,
					}}
					/>

				<PlaceInterestPositionWrapper
					handleShowExpressInterest={this.handleShowExpressInterest}
					open={express_interest.open}
					popOut={express_interest.prompt_express}
					lock_company={true}
					placeorder={express_interest.payload.company_id ? express_interest.payload : {}}
					{...this.props}
					/>

				<Snackbar
					anchorOrigin={{
						vertical: "top",
						horizontal: "center",
					}}
					openStyle={snackBar.open}
					handleSnackbarClose={this.handleSnackbarClose}
					type={snackBar.error ? "error" : "success"}
					message={snackBar.message}
					autoHideDuration={4000}
					/>

				{watchListDialog.open ? 
					<WatchListDialog
						{...watchListDialog}
						// companyId={companyDetailResponse.id}
						closeDialog={this.handleCloseWatchListDialog}
						updateStarStatus={this.handleWatchListStar}
					/> : null}

				<WatchlistSnackbar
                    handleSnackbarClose={this.handleSnackbarClose}
                    open={toggle_watchlist.message.trim() ? true : false}
                    error={toggle_watchlist.error}
                    message={toggle_watchlist.message}
                />
				
				<FullScreenPriceChartDialog
                    open={full_screen_open}
                    price_chart_payload={{...{
                        ...{ company_id: id },
                        historical_pricing: historicalPrice,
						funding_rounds: fundingRounds,
						isMobile: isMobile,
                    }}}
                    handlePriceChartFullScreen={this.handlePriceChartFullScreen}
                    />
			</div>
		);
	}
}

export default withStyles(Styles)(CompanyDetail);
