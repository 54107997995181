import React, { useState } from 'react';
import {
    Dialog,
    // DialogActions,
    DialogContent,
    DialogTitle,
} from '@material-ui/core';

import { PdfDocument } from '../../../common';

export default function Teaser(props){
    // console.log(props);
    return (
        <Dialog
            open={props.open}
            onClose={props.closePopup}
            fullWidth
            maxWidth={"lg"}>
                <DialogTitle>{props.link_name}</DialogTitle>
                <DialogContent>
                    <PdfDocument pdf={props.url} />
                </DialogContent>
        </Dialog>
    )
} 

