import { connect } from 'react-redux';
import { default as LockedGraph } from './LockedGraph';

import {
    requestUnlock,
    clearRequestUnlockMsg,   
} from './Action';

export default connect(
    state => ({
        request_info_response: state.requestUnlock.reducer.request_info_response,
    }),
    dispatch => ({
        requestUnlock: payload => dispatch(requestUnlock.request(payload)),
        clearRequestUnlockMsg: () => dispatch(clearRequestUnlockMsg()),
    })
)(LockedGraph);