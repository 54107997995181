import { connect } from "react-redux";

import { default as Vetting } from "./Vetting";

import {
	getProspectInvestorList,
	postSubmitInvestorApproval,
	clearRefreshData,
} from './Action';

import { CollapseSidebar } from "../../root/Action";

export default connect(
	(state) => ({
		collapseSidebar: state.root.reducer.collapseSidebar,
		loginUserData: state.login.reducer.loginUserData,
		loaderState: state.root.reducer.loaderState,
		appConfig: state.root.reducer.appConfig,
		prospect_loading: state.boostVetting.reducer.prospect_loading,
		prospect_investors: state.boostVetting.reducer.prospect_investors,
		refresh_data: state.boostVetting.reducer.refresh_data,
	}),
	(dispatch) => ({
		CollapseSidebar: (payload) => dispatch(CollapseSidebar(payload)),
		getProspectInvestorList: payload => dispatch(getProspectInvestorList.request(payload)),
		postSubmitInvestorApproval: payload => dispatch(postSubmitInvestorApproval.request(payload)),
		clearRefreshData: () => dispatch(clearRefreshData()),
	})
)(Vetting);