import { combineReducers } from "redux";
import {
	getPipeline,
	getMarketIntelligence,
	getVerticalsList,
	getHeadquaterCountryList,
	toggleWatchList,
	expressInterest,
	resetExpressInterest,
	getAdBanner1,
	postReferral,
	getWatchList,
	updateProps,
	getViewSummary,
} from "./Action";

import { resetSnackBar } from "../../account/Action";

import { default as InitialState } from "./InitialState";

export default combineReducers({
	reducer(state = InitialState, action) {
		switch (action.type) {
			case "UPDATE_PROPS":
				return Object.assign({}, state, {
					...action.payload,
				});
			case getPipeline.request.toString():
				return Object.assign({}, state, {
					pipelineResponse: []
				});
			case getPipeline.success.toString():
				return Object.assign({}, state, {
					pipelineResponse: action.payload,
				});
			case getPipeline.failure.toString():
				return state;
			case getMarketIntelligence.request.toString():
				return Object.assign({}, state, {
					marketIntelligenceResponse: [],
				});
			case getMarketIntelligence.success.toString():
				return Object.assign({}, state, {
					marketIntelligenceResponse: action.payload,
				});
			case getMarketIntelligence.failure.toString():
				return state;
			case getWatchList.success.toString():
				return Object.assign({}, state, {
					watchListResponse: action.payload,
				});
			case getWatchList.failure.toString():
				return state;
			case resetExpressInterest.toString():
			case expressInterest.request.toString():
				return Object.assign({}, state, {
					expressInterestError: false,
					expressInterestResponseMessage: "",
				});
			case expressInterest.success.toString():
				return Object.assign({}, state, {
					expressInterestError: false,
					expressInterestResponseMessage:
						"Thank you for your interest. Our team will reach out to discuss further.",
				});
			case expressInterest.failure.toString():
				return Object.assign({}, state, {
					expressInterestError: true,
					expressInterestResponseMessage:
						"There is an error, please refresh this page and try again.",
				});
			case postReferral.request.toString():
				return Object.assign({}, state, {
					referralError: false,
					referralResponseMessage: "",
				});
			case postReferral.success.toString():
				return Object.assign({}, state, {
					referralError: false,
					referralResponseMessage:
						"Thank you for your referral. Our team will reach out to the invitee.",
				});
			case postReferral.failure.toString():
				return Object.assign({}, state, {
					referralError: true,
					referralResponseMessage:
						"There is an error, please refresh this page and try again.",
				});
			case getAdBanner1.success.toString():
				return Object.assign({}, state, {
					adBanner1Response: action.payload,
				});
			case getAdBanner1.failure.toString():
				return Object.assign({}, state, {
					adBanner1Response: {},
				});
			case resetSnackBar.toString():
				return Object.assign({}, state, {
					referralError: false,
					referralResponseMessage: "",
					expressInterestError: false,
					expressInterestResponseMessage: "",
					toggleWatchListError: false,
					toggleWatchListMessage: "",
				});
			case getHeadquaterCountryList.success.toString():
				return Object.assign({}, state, {
					headquaterCountryListResponse: action.payload,
				});
			case getHeadquaterCountryList.failure.toString():
				return state;
			case toggleWatchList.success.toString():
				return Object.assign({}, state, {
					toggleWatchError: false,
					toggleWatchListResponse: action.payload,
					toggleWatchListMessage: action.payload.message
				});
			case toggleWatchList.failure.toString():
				return Object.assign({}, state, {
					toggleWatchListError: true,
					toggleWatchListMessage: "There is an error, please try again later.",
				});
			case getVerticalsList.success.toString():
				return Object.assign({}, state, {
					verticalsListResponse: action.payload,
				});
			case getVerticalsList.failure.toString():
				return state;
			case getViewSummary.request.toString():
				return Object.assign({}, state, {
					popular_companies: []
				});
			case getViewSummary.failure.toString():
				return state;
			case getViewSummary.success.toString():
				const companies = action.payload.filter((ele, index) => index<4);
				return Object.assign({}, state, {
					popular_companies: companies
				});
			default:
				return state;
		}
	},
});
