import React, { Fragment } from 'react';

import {
    Table,
    TableBody,
    TableContainer,
    TableHead,
    Paper,
    Grid,
    Box,
    Typography,
    Button,
    useMediaQuery,
} from '@material-ui/core';

import StarIcon from '@material-ui/icons/Star';
import StarBorderIcon from '@material-ui/icons/StarBorder';

import {
    AgeOfOrders
} from '../../common/place_order/components';

import {
    WatchlistTooptip,
    WatchlistButton,
} from '../../common/components';

import { 
    HeaderTableCell, 
    StyledTableRow, 
    recentCellClasses,
    TableLabelStyle,
    ControlledFixedTable,
    TighterTableCell,
    StyledHoverTableRow,
    TighterClickableTableCell,
    TighterHeaderTableCell,
    discPremClasses,
    BidTighterClickableTableCell,
    OfferTighterClickableTableCell,
} from '../../common/Table';

import {
    numberWithCommas,
    convertSizeLabel,
    getDigitPadding,
    getImpliedVal,
    roundNumber,
    displayVal,
} from '../../../../utils/util';

import { 
    content_placeholders,
    feature_flags,
} from '../../../../config';

import { default as OrderbookTooltip } from './OrderbookTooltip';

export default (props) => {
    const classes = TableLabelStyle();
    const rcc = recentCellClasses();
    const dpc = discPremClasses();
    const isNOTSmallScreen = useMediaQuery(`(min-width:576px)`);
    const isLargeScreen = useMediaQuery(`(min-width:1200px)`);
    const header_class = props.fixHeader ? props.collapseSidebar ? 'header-fix narrow' : 'header-fix' : 'header-top-fix';

    const { appConfig, loginUserData: { email, activeAccessRight: { access_right: { permission } } } } = props;
    
    return (
        <Fragment>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="body1" className={classes.FirmOrder}><span>Firm Order</span></Typography>
                    {/* <Typography variant="body1" className={classes.ThirtyDay}><span>Within 30 days</span></Typography>
                    <Typography variant="body1" className={classes.SixtyDay}><span>30 - 60 days</span></Typography> */}
                    <AgeOfOrders />
                </Grid>
            </Grid>
            <TableContainer component={Paper} className={classes.container} style={{overflowX: "initial"}}>
                <Table stickyHeader size="small" aria-label="simple table">
                    <TableHead>
                        <StyledTableRow>
                            <TighterHeaderTableCell></TighterHeaderTableCell>
                            <TighterHeaderTableCell colSpan={isNOTSmallScreen ? 3: 2} style={{textAlign: 'center'}}>Bid</TighterHeaderTableCell>
                            <TighterHeaderTableCell colSpan={isNOTSmallScreen ? 3: 2} style={{textAlign: 'center'}}>Offer</TighterHeaderTableCell>
                            {
                                feature_flags.feature_is_available(
                                    appConfig,
                                    feature_flags.watchlist_flags.Watchlist.flag_name,
                                    feature_flags.watchlist_flags.Watchlist.attributes.add_remove_watchlist,
                                    email
                                ) ? <TighterHeaderTableCell style={{width: '30px'}}></TighterHeaderTableCell> : ''
                            }
                        </StyledTableRow>
                        <StyledTableRow>
                            <TighterHeaderTableCell className='company-name' style={{minWidth: isLargeScreen ? '180px' : '90px'}}>Name</TighterHeaderTableCell>
                            {
                                isNOTSmallScreen &&
                                <TighterHeaderTableCell className="size-column">Bid (PPS)</TighterHeaderTableCell>
                            }
                            <TighterHeaderTableCell>Implied Val.</TighterHeaderTableCell>
                            <TighterHeaderTableCell>Disc / Prem</TighterHeaderTableCell>
                            {
                                isNOTSmallScreen &&
                                <TighterHeaderTableCell className="size-column">Offer (PPS)</TighterHeaderTableCell>
                            }
                            <TighterHeaderTableCell className='price-column'>Implied Val.</TighterHeaderTableCell>
                            <TighterHeaderTableCell>Disc / Prem</TighterHeaderTableCell>
                            {
                                feature_flags.feature_is_available(
                                    appConfig,
                                    feature_flags.watchlist_flags.Watchlist.flag_name,
                                    feature_flags.watchlist_flags.Watchlist.attributes.add_remove_watchlist,
                                    email
                                ) ? <TighterHeaderTableCell className="watchlist"></TighterHeaderTableCell> : ''
                            }
                        </StyledTableRow>
                    </TableHead>
                    <TableBody>
                        {
                            props.orderbook.resultList.map((row, index) => {
                                // console.log( row );
                                const bid_padding = getDigitPadding(row.highest_bid, props.longest_digit);
                                const offer_padding = getDigitPadding(row.lowest_ask, props.longest_digit);
                                const bid_imp_padding = getDigitPadding(row.bid_implied_val, props.longest_implied_digit);
                                const offer_imp_padding = getDigitPadding(row.ask_implied_val, props.longest_implied_digit);

                                return (
                                    <React.Fragment key={index}>
                                        <OrderbookTooltip
                                            placement="right"
                                            data={row}
                                            >
                                            {
                                                permission == content_placeholders.user_type.orderbook_user ? 
                                                    <StyledHoverTableRow key={index}>
                                                        <TighterClickableTableCell style={{wordBreak: 'break-word', minWidth: isLargeScreen ? '180px' : '90px'}}
                                                            onClick={(evt) => props.handleGoToCompanyDetail(evt, row.company_id)}
                                                            className="company-name">{row.company_name}</TighterClickableTableCell>
                                                        {
                                                            isNOTSmallScreen &&
                                                            <BidTighterClickableTableCell 
                                                            onClick={(evt) => props.handleGoToCompanyDetail(evt, row.company_id)}
                                                            className={ `price-column ${rcc.dateClassName(row.highest_bid_date, row.firm_bid)}`}>
                                                                {bid_padding}
                                                                {row.highest_bid ? '$'+numberWithCommas(row.highest_bid) : '-'}
                                                                </BidTighterClickableTableCell>
                                                        }
                                                        <BidTighterClickableTableCell 
                                                            onClick={(evt) => props.handleGoToCompanyDetail(evt, row.company_id)}
                                                            className={ `price-column ${rcc.dateClassName(row.highest_bid_date, row.firm_bid)}`}>
                                                                {isNOTSmallScreen && bid_imp_padding}
                                                                {
                                                                    row.highest_bid && row.selected_price && row.selected_post_money_valuation ?
                                                                        getImpliedVal(row.highest_bid, row.selected_price, row.selected_post_money_valuation, isNOTSmallScreen ? 1 : 0) : 
                                                                        row.bid_valuation ?
                                                                            displayVal(row.bid_valuation,1,1) : '-'
                                                                }
                                                                </BidTighterClickableTableCell>
                                                        <BidTighterClickableTableCell 
                                                            onClick={(evt) => props.handleGoToCompanyDetail(evt, row.company_id)}
                                                            className={`price-column ${dpc.discPremClassName(row.bid_disc_prem)}`}>
                                                            {row.bid_disc_prem ? `${roundNumber(row.bid_disc_prem, isNOTSmallScreen ? 100 : 1)}%` : '-'}
                                                        </BidTighterClickableTableCell>
                                                        {
                                                            isNOTSmallScreen &&
                                                            <OfferTighterClickableTableCell 
                                                            onClick={(evt) => props.handleGoToCompanyDetail(evt, row.company_id)}
                                                            className={ `price-column ${rcc.dateClassName(row.lowest_ask_date, row.firm_ask)}` }>
                                                                {offer_padding}
                                                                {row.lowest_ask ? '$'+numberWithCommas(row.lowest_ask) : '-'}
                                                                </OfferTighterClickableTableCell>
                                                        }
                                                        <OfferTighterClickableTableCell 
                                                            onClick={(evt) => props.handleGoToCompanyDetail(evt, row.company_id)}
                                                            className={ `price-column ${rcc.dateClassName(row.lowest_ask_date, row.firm_ask)}` }>
                                                                {isNOTSmallScreen && offer_imp_padding}
                                                                {
                                                                    row.lowest_ask && row.selected_price && row.selected_post_money_valuation ?
                                                                        getImpliedVal(row.lowest_ask, row.selected_price, row.selected_post_money_valuation, isNOTSmallScreen ? 1 : 0 ) :
                                                                        row.ask_valuation ?
                                                                            displayVal(row.ask_valuation,1,1) : '-'
                                                                }
                                                                </OfferTighterClickableTableCell>
                                                        <OfferTighterClickableTableCell 
                                                            onClick={(evt) => props.handleGoToCompanyDetail(evt, row.company_id)}
                                                            className={`price-column ${dpc.discPremClassName(row.ask_disc_prem)}`}>
                                                            {row.ask_disc_prem ? `${roundNumber(row.ask_disc_prem, isNOTSmallScreen ? 100 : 1)}%` : '-'}
                                                        </OfferTighterClickableTableCell>
                                                        {
                                                            feature_flags.feature_is_available(
                                                                appConfig,
                                                                feature_flags.watchlist_flags.Watchlist.flag_name,
                                                                feature_flags.watchlist_flags.Watchlist.attributes.add_remove_watchlist,
                                                                email
                                                            ) ? 
                                                            <TighterClickableTableCell
                                                                onClick={(evt) => props.handleToggleWatchList(evt, { company_id: row.company_id, watchlist: row.active_watchlist })}
                                                                className='watchlist' style={{textAlign: 'center'}}>
                                                                    <WatchlistButton 
                                                                        active_watchlist={row.active_watchlist}
                                                                        />
                                                            </TighterClickableTableCell> : ''
                                                        }
                                                    </StyledHoverTableRow> : 
                                                    <StyledTableRow>
                                                        <TighterTableCell 
                                                            className='size-column'>
                                                                {bid_padding}
                                                                {row.highest_bid ? numberWithCommas(row.highest_bid) : '-'}
                                                            </TighterTableCell>
                                                        <TighterTableCell 
                                                            className={ `price-column ${rcc.dateClassName(row.highest_bid_date, row.firm_bid)}` }>
                                                                {bid_padding}
                                                                {row.highest_bid ? numberWithCommas(row.highest_bid) : '-'}
                                                                </TighterTableCell>
                                                        <TighterTableCell 
                                                            className="company-name">{row.company_name}
                                                            </TighterTableCell>
                                                        <TighterTableCell 
                                                            className={ `price-column ${rcc.dateClassName(row.lowest_ask_date, row.firm_ask)}` }>
                                                                {offer_padding}
                                                                {row.lowest_ask ? numberWithCommas(row.lowest_ask) : '-'}
                                                                </TighterTableCell>
                                                        <TighterTableCell 
                                                            className='size-column'>
                                                                {offer_padding}
                                                                {row.lowest_ask ? numberWithCommas(row.lowest_ask) : '-'}
                                                                </TighterTableCell>
                                                        {
                                                            feature_flags.feature_is_available(
                                                                appConfig,
                                                                feature_flags.flags.Watchlist.flag_name,
                                                                feature_flags.flags.Watchlist.attributes.add_remove_watchlist,
                                                                email
                                                            ) ? 
                                                            <TighterClickableTableCell
                                                                onClick={(evt) => props.handleToggleWatchList(evt, { company_id: row.company_id, watchlist: row.active_watchlist })}
                                                                className='watchlist'>
                                                                    <WatchlistButton 
                                                                        active_watchlist={row.active_watchlist}
                                                                        />
                                                            </TighterClickableTableCell> : ''
                                                        }
                                                        
                                                    </StyledTableRow>
                                            }
                                        </OrderbookTooltip>
                                    </React.Fragment>
                                )
                            })
                        }
                    </TableBody>
                </Table>
                <ControlledFixedTable
                    className={header_class}
                    stickyHeader 
                    size="small" 
                    aria-label="simple table"
                    style={{display: 'none'}}>
                    <TableHead>
                        <StyledTableRow>
                            <TighterHeaderTableCell className='company-name'>Name</TighterHeaderTableCell>
                            <TighterHeaderTableCell className="size-column">Bid (PPS)</TighterHeaderTableCell>
                            <TighterHeaderTableCell>Implied Val.</TighterHeaderTableCell>
                            <TighterHeaderTableCell>Disc / Prem</TighterHeaderTableCell>
                            <TighterHeaderTableCell className="size-column">Offer (PPS)</TighterHeaderTableCell>
                            <TighterHeaderTableCell className='price-column'>Implied Val.</TighterHeaderTableCell>
                            <TighterHeaderTableCell>Disc / Prem</TighterHeaderTableCell>
                            {
                                feature_flags.feature_is_available(
                                    appConfig,
                                    feature_flags.watchlist_flags.Watchlist.flag_name,
                                    feature_flags.watchlist_flags.Watchlist.attributes.add_remove_watchlist,
                                    email
                                ) ? <TighterHeaderTableCell className="watchlist"></TighterHeaderTableCell> : ''
                            }
                        </StyledTableRow>
                    </TableHead>
                </ControlledFixedTable>

            </TableContainer>
        </Fragment>
    )
}
