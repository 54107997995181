import { combineReducers } from 'redux';

import {
    getFundraisingParameters,
    getGoalSummary,
    getProspectSummary,
    getInvestorSummary,
    getVettedInvestorList,
    getSimilarCompany,
    getCompanyVerticals,
    getCompanyDetail,
} from './Action';

import {
    default as InitialState
} from './InitialState';

export default combineReducers({
    reducer(state = InitialState, action) {
        switch (action.type) {
            case getFundraisingParameters.request.toString():
            case getFundraisingParameters.failure.toString():
                return Object.assign({}, state, {
                    fundraising_params: {}
                });
            case getFundraisingParameters.success.toString():
                return Object.assign({}, state, {
                    fundraising_params: action.payload
                });
            case getProspectSummary.request.toString():
            case getProspectSummary.failure.toString():
                return Object.assign({}, state, {
                    prospect_summary: {}
                });
            case getProspectSummary.success.toString():
                return Object.assign({}, state, {
                    prospect_summary: action.payload
                })
            case getVettedInvestorList.request.toString():
            case getVettedInvestorList.failure.toString():
                return Object.assign({}, state, {
                    vetted_investors_response: {
						resultCount: 0,
						resultList: []
					}
                });
            case getVettedInvestorList.success.toString():
                return Object.assign({}, state, {
                    vetted_investors_response: action.payload
                });
            case getGoalSummary.request.toString():
            case getGoalSummary.failure.toString():
                return Object.assign({}, state, {
                    goal_summary: {}
                })
            case getGoalSummary.success.toString():
                return Object.assign({}, state, {
                    goal_summary: action.payload
                })
            case getSimilarCompany.request.toString():
            case getSimilarCompany.failure.toString():
                return Object.assign({}, state, {
                    similar_companies: {
                        resultCount: 0,
                        resultList: []
                    }
                })
            case getSimilarCompany.success.toString():
                return Object.assign({}, state, {
                    similar_companies: action.payload
                });
            case getCompanyVerticals.request.toString():
            case getCompanyVerticals.failure.toString():
                return Object.assign({}, state, {
                    company_verticals: []
                });
            case getCompanyVerticals.success.toString():
                return Object.assign({}, state, {
                    company_verticals: action.payload
                });
            case getCompanyDetail.request.toString():
            case getCompanyDetail.failure.toString():
                return Object.assign({}, state, {
                    company_detail: {},
                });
            case getCompanyDetail.success.toString():
                return Object.assign({}, state, {
                    company_detail: action.payload,
                });
            default:
                return state;
        }
    }
})