import { combineReducers } from "redux";

import {
    postSubmitPortfolio,
    getSearchCompany,
} from './Action';

import { default as InitialState } from './InitialState';

export default combineReducers({
    reducer(state=InitialState, action){
        switch( action.type ){
            case getSearchCompany.request.toString():
                return Object.assign({}, state, {
                    searchCompanyResponse: [],
                    search_company_loading: true
                });
            case getSearchCompany.success.toString():
                return Object.assign({}, state, {
                    searchCompanyResponse: action.payload,
                    search_company_loading: false
                });
            case getSearchCompany.failure.toString():
                return Object.assign({}, state, {
                    searchCompanyResponse: [],
                    search_company_loading: false
                });
            case postSubmitPortfolio.request.toString():
                return Object.assign({}, state, {
                    submitPortfolioResponse: {
                        loading: true,
                        error: false,
                        message: ''
                    }
                });
            default:
                return state;
        }
    }
})