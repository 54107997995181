import React, { Component, Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Styles } from '../styles';
// import { NavLink, Link } from "react-router-dom";
import deal from "./../../assets/images/deal.jpg";
import CompanyInfo from './CompanyInfo';
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Button } from "@material-ui/core";
import DealDetail from './DealDetail';
import DisclaimerData from "../common/DisclaimerData";
import Tooltip from '@material-ui/core/Tooltip';
import { Snackbar } from '../common';

class CompanyDetail extends Component {

	state = {
		deleteId: "",
		deleteModal: false,
		formModal: false,
		formModalEdit: false,

		// Historical Offer Pagination
		offer_page: 0,
		offer_rowsPerPage: 10,
		// Historical Demand Pagination
		demand_page: 0,
		coffer_page: 0,
		demand_rowsPerPage: 10,
		coffer_rowPerPage: 10,
		open: false,
		tabIndex: 0,
		element: {},
		openStyle: false
	};

	constructor(props) {
		super(props);
		this.handleSnackbarClose = this.handleSnackbarClose.bind(this);
	}

	componentDidMount() {
		const { match: { params: { id } } } = this.props;
		// console.log(id)
		// const {
		// 	offer_page,
		// 	offer_rowsPerPage,
		// 	demand_page,
		// 	coffer_page,
		// 	demand_rowsPerPage,
		// 	coffer_rowPerPage
		// } = this.state;
		this.props.getAllCompanies({ company_id: id });
		this.props.getCompanyInvestors({ company_id: id });
		this.props.getVerticals({ company_id: id });
		// this.props.getFundingRound({ company_id: id });
		this.props.getPipeline({ company_id: id });
		this.props.getLatestFundingRound({ company_id: id });
	}

	thousands_separators = (num) => {
		return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	}

	handleSnackbarClose(evt, reason) {
		if (reason === 'clickaway') {
			return;
		}
		this.setState({ openStyle: false });
	}

	static getDerivedStateFromProps(nextProps, prevState){
		const {
			expressInterestError,
			expressInterestResponseMessage
		} = nextProps;

		if(expressInterestResponseMessage != prevState.expressInterestResponseMessage && expressInterestResponseMessage !== undefined){
			const new_open_style = expressInterestResponseMessage.trim() ? true : false;
			return {
				openStyle: new_open_style,
				expressInterestResponseMessage: expressInterestResponseMessage,
				expressInterestError: expressInterestError
			}
		} else {
			return null;
		}
	}

	render() {
		const {
			openStyle,
			expressInterestError,
			expressInterestResponseMessage
		} = this.state;

		const {
			AllCompaniesResponse,
			// fundingRounds,
			investors,
			verticals,
			pipelineResponse
		} = this.props;

		const dealDetail = pipelineResponse && pipelineResponse.results && pipelineResponse.results.length ? pipelineResponse.results[0] : {};
		const date_arr = dealDetail.selected_deal_date ? dealDetail.selected_deal_date.split("-") : [];
		let lf_date;
		if (date_arr.length) {
			lf_date = new Date(date_arr[0], date_arr[1] - 1, date_arr[2])
		}
		const companyDetailResponse = AllCompaniesResponse ? AllCompaniesResponse : {};
		let id = this.props.match.params.id;
		var website_name = companyDetailResponse.website && companyDetailResponse.website.trim() ?
			(companyDetailResponse.website.trim().substring('http:') == 0 ? companyDetailResponse.website.trim() :
				"http://" + companyDetailResponse.website.trim()) : "";
		// console.log(fundingRounds)
		const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
		// const notable_investors = investors && investors.filter(item => item.category);
		dealDetail.company_id = companyDetailResponse.id;
		// console.log(companyDetailResponse)
		return (
			<Fragment>
				<div className="gateway-notification">
					<div className="container">
						<div className="row">
							<div className="col col-12">
								<h3>New Gateway Portal is now live, please reach out to us to request access <a href="https://www.gatewayprivatemarkets.com/portal/" target="_blank">here</a>.</h3>
							</div>
						</div>
					</div>
				</div>
				<section className="container header-padding">
					<Fragment>
						<div className="mt-3">
							<div className="row">
								<div className="col-12 col-lg-6">
									<div className="box p-4">
										<div className="row">
											<div className="col-4 image-design">
												<div className="logo-hoder-2">
													<img
														className="w-100"
														src={
															companyDetailResponse.logo_url
																? companyDetailResponse.logo_url
																: deal
														}
														alt={companyDetailResponse.name}
													/>
												</div>
											</div>
											<div className="col-8">
												<h2 className="heading">
													{companyDetailResponse.name}
												</h2>
												<div className="content-item">
													<span className="samllText">Primary Industry</span>
													<br />
													<p>{companyDetailResponse.primary_industry_code}</p>
												</div>
												<div className="content-item">
													<span className="samllText">Vertical</span>
													<br />
													<p>
														{
															verticals.map((ver, ii) => {
																if (ii) return ver.vertical && ver.vertical.name ? <span key={ii}>, {ver.vertical.name}</span> : <span key={ii}></span>;
																else return ver.vertical && ver.vertical.name ? <span key={ii}>{ver.vertical.name}</span> : <span key={ii}></span>;
															})
														}
													</p>
												</div>
												<div className="content-item">
													<span className="samllText">Description</span>
													<br />
													<p>{companyDetailResponse.description}</p>
												</div>
												<div className="content-item">
													<span className="samllText">Website</span>
													<br />
													<p>{website_name.trim() && (<a href={website_name} target="_blank">{website_name}</a>)}</p>
												</div>
											</div>
										</div>

									</div>
								</div>
								<div className="col-12 col-lg-6 vertical-flex">


									<div className="box p-4 marginal-top first-row">
										<div className="row">
											<div className="col-md-4 text-centered-3 no-pad">
												<span className="samllText">Founded</span>
												<br />
												<span className="bigText-1">
													{companyDetailResponse.founded}
												</span>
											</div>
											<div className="col-md-4 text-centered-3 no-pad">
												<span className="samllText">Team Size</span>
												<br />
												<span className="bigText-1">
													{companyDetailResponse.team_size ? this.thousands_separators(companyDetailResponse.team_size) : '-'}
												</span>
											</div>
											<div className="col-md-4 text-centered-3 no-pad">
												<span className="samllText">Headquarter Country</span>
												<br />
												<span className="bigText-1">
													{companyDetailResponse.headquarter_country}
												</span>
											</div>
										</div>
									</div>
									<div className="box p-4 mt-3 second-row">
										<div className="row">
											<div className="col-12 no-pad text-centered-3">
												<h4 className="subheading-color">
													Latest Funding Round
												</h4>
											</div>
										</div>
										<div className="row mt-3">

											<div className="col-md-3 col-lg-6 col-xl-3 col-6 no-pad text-centered-3">
												<span className="samllText">Post-Money Valuation</span>
												<br />
												<span className="bigText-1">
													{
														dealDetail.selected_post_money_valuation ?

															(
																dealDetail.selected_post_money_valuation > 1000 ?
																	("$" + (dealDetail.selected_post_money_valuation / 1000).toFixed(2) + "B")
																	: ("$" + dealDetail.selected_post_money_valuation.toFixed(0) + "M")
															)

															: '-'}
												</span>
											</div>

											<div className="col-md-3 col-lg-6 col-xl-3 col-6 no-pad text-centered-3">
												<span className="samllText">Share Price</span>
												<br />
												<span className="bigText-1">
													{dealDetail.selected_price ? "$" + dealDetail.selected_price.toFixed(2) : '-'}
													{dealDetail['selected_price_disclaimer'] == "pitchbook pre money valuation price" ? <sup>E</sup> : ""}
													{dealDetail['selected_price_disclaimer'] == "pitchbook post money valuation price" ? <sup>E</sup> : ""}
													{dealDetail['selected_price_disclaimer'] == "manually selected" && dealDetail['selected_external_price_estimated'] ? <sup>E</sup> : ""}
													{dealDetail['selected_price_disclaimer'] == "other price" && dealDetail['selected_other_price_estimated'] ? <sup>E</sup> : ""}
													{dealDetail['selected_price_disclaimer'] == "pitchbook latest price" && dealDetail['selected_pb_price_estimated'] ? <sup>E</sup> : ""}
													{dealDetail['selected_price_disclaimer'] == "pitchbook with price" && dealDetail['selected_pb_wp_price_estimated'] ? <sup>E</sup> : ""}
												</span>
											</div>

											<div className="col-md-3 col-lg-6 col-xl-3 col-6 no-pad text-centered-3">
												<span className="samllText">Deal Date</span>
												<br />
												<span className="bigText-1">
													{dealDetail.selected_deal_date ? lf_date.getDate() + '-' + months[lf_date.getMonth()] + '-' + lf_date.getFullYear() : '-'}
												</span>
											</div>

											<div className="col-md-3 col-lg-6 col-xl-3 col-6 no-pad text-centered-3">
												<span className="samllText">Total Raised</span>
												<br />
												<span className="bigText-1">
													{
														companyDetailResponse && companyDetailResponse.total_raised ?

															(
																companyDetailResponse.total_raised >= 1000 ?
																	("$" + (companyDetailResponse.total_raised / 1000).toFixed(2) + "B")
																	: ("$" + companyDetailResponse.total_raised.toFixed(2) + "M")
															)

															: '-'
													}
												</span>
											</div>

										</div>
										<div className="row">

											<div className="col-12">
												<div className="margin-remove margin-top-area disclaimer">
												{dealDetail && (
													dealDetail['selected_price_disclaimer'] == "pitchbook pre money valuation price" ||
													dealDetail['selected_price_disclaimer'] == "pitchbook post money valuation price" ||
													dealDetail['selected_price_disclaimer'] == "manually selected" && dealDetail['selected_external_price_estimated'] ||
													dealDetail['selected_price_disclaimer'] == "other price" && dealDetail['selected_other_price_estimated'] ||
													dealDetail['selected_price_disclaimer'] == "pitchbook latest price" && dealDetail['selected_pb_price_estimated'] ||
													dealDetail['selected_price_disclaimer'] == "pitchbook with price" && dealDetail['selected_pb_wp_price_estimated']
												) ? <div><br /><p className="margin-remove"><sup>E</sup> Estimated</p> </div> : ''}
												<p className="mb-0">All figures in US$</p> </div>
											</div>

										</div>
										<div className="row">
											<div className="col col-12">
												{dealDetail['selected_price_disclaimer'] == "manually selected" && dealDetail['selected_external_post_money_valuation_external_note'] ? <p><sup>1</sup>{dealDetail['selected_external_post_money_valuation_external_note']}</p> : ""}
												{dealDetail['selected_price_disclaimer'] == "other price" && dealDetail['selected_other_post_money_valuation_external_note'] ? <p><sup>2</sup>{dealDetail['selected_other_post_money_valuation_external_note']}</p> : ""}
												{dealDetail['selected_price_disclaimer'] == "pitchbook latest price" && dealDetail['selected_pb_post_money_valuation_external_note'] ? <p><sup>3</sup>{dealDetail['selected_pb_post_money_valuation_external_note']}</p> : ""}
												{dealDetail['selected_price_disclaimer'] == "pitchbook with price" && dealDetail['selected_pb_wp_post_money_valuation_external_note'] ? <p><sup>4</sup>{dealDetail['selected_exteselected_pb_wp_post_money_valuation_external_noternal_post_money_valuation_external_note']}</p> : ""}
											</div>
										</div>
									</div>

								</div>

								<DealDetail {...dealDetail} expressInterest={this.props.expressInterest} />

								<div className="col-12 mt-3">
									<div className="box">
										<div className="row p-4">
											<div className="col-12">
												<h4 className="investor-section">Investors</h4>
											</div>
											<div className="col-12">
												{investors && investors.map((item, index) => {
													return (
														<Tooltip key={index} title={item && item.source_name}><span className="samllTextBlod investor-link" key={index}>{item && item.source_name}</span></Tooltip>
													)
												}
												)}
											</div>

										</div>
									</div>
								</div>

							</div>
							<br />
							<DisclaimerData />
						</div>

						<Snackbar
							anchorOrigin={{
								vertical: 'top',
								horizontal: 'center',
							}}
							openStyle={openStyle}
							handleSnackbarClose={this.handleSnackbarClose}
							type={expressInterestError ? 'error' : 'success'}
							message={expressInterestResponseMessage}
						/>
					</Fragment>
				</section>
			</Fragment>
		);
	}
}

export default withStyles(Styles)(CompanyDetail);
