import {
    CreateApiAction as _createApiAction,
    CreateKeyAction as _createKeyAction,
} from '../../../../../utils';

const key = 'ORDERBOOK_REQUESTINFO';

const CreateApiAction = _createApiAction(key);

const postRequestInfo = CreateApiAction('ORDERBOOK_REQUESTINFO');

const CreateKeyAction = _createKeyAction(key);
const clearRequestInfoMsg = CreateKeyAction('CLEAR_REQUEST_INFO_MSG');

export {
    postRequestInfo,
    clearRequestInfoMsg,
}