import React from 'react';

import {
    Grid,
} from '@material-ui/core';

export default (props) => {
    return (
        <footer>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={0} md={1} style={{height: 1}}></Grid>
                        <Grid item xs={12} md={11}>
                            <span>
                                &copy; 2023 by Gateway Private Markets Limited<br />
                                Gateway Private Markets (HK) Limited is licensed by the Securities and Future Commission of <br />
                                Hong Kong for Regulated Activities of Dealing in Securities and Asset Management.
                            </span>
                        </Grid>
                    </Grid>
                    
                </Grid>
                
            </Grid>
        </footer>
    )
}