import {
    put,
    takeLatest,
    all,
    fork,
    call
} from 'redux-saga/effects';

import { Unauthorized } from "../../login/Action";

import {
    getFundingRound,
    getAllCompanies,
    getCompanyInvestors,
    getVerticals,
    getLatestFundingRound,
    getClubDetail,
    getEmployee,
    getPublicNewsArticle,
    getDealLinks,
    getDealTimeline,
    getHistoricalPrice,
    toggleWatchList,
    RequestOnboarding,
    getSimilarCompanies,
} from './Action';

import {
    companies,
    club_company,
    employee,
    public_news_article,
    watch_list,
    user_message,
    club_pipeline
} from '../../../api';

import { content_placeholders } from '../../../config';

import {
    NewRelicError
} from '../../../utils';

import {
    logEventInternallyAndNewRelic
} from '../../../utils/ga';

function* getFundingRoundResponder({ payload }) {
    try {
        const { data } = yield call(companies.getFundingRound, payload.company_id, '?show_all=1', payload);

        yield put(getFundingRound.success(data));
    } catch (e) {
        if( e.message && e.response ){
            const err_payload = {
                type: 'api_failure_getFundingRoundResponder',
                data: {
                    config: e.response.config,
                    data: e.response.data,
                    status: e.response.status
                },
                err: new Error("API Failure getFundingRoundResponder: " + e.message)
            }
            logEventInternallyAndNewRelic(err_payload);
        } else if (e.message) {
            NewRelicError( new Error("API Failure getFundingRoundResponder: " + e.message) );
        } else {
            NewRelicError( new Error("API Failure getFundingRoundResponder with no message") );
        }

        if (e.response.status == 401) {
            yield put(Unauthorized(e));
        }
        yield put(getFundingRound.failure(e));
    } finally {

    }
}

function* getLatestFundingRoundResponder({ payload }) {
    try {
        const { data } = yield call(companies.getLatestFundingRound, payload.company_id, payload);
        yield put(getLatestFundingRound.success(data));
    } catch (e) {
        if( e.message && e.response ){
            const err_payload = {
                type: 'api_failure_getLatestFundingRoundResponder',
                data: {
                    config: e.response.config,
                    data: e.response.data,
                    status: e.response.status
                },
                err: new Error("API Failure getLatestFundingRoundResponder: " + e.message)
            }
            logEventInternallyAndNewRelic(err_payload);
        } else if (e.message) {
            NewRelicError( new Error("API Failure getLatestFundingRoundResponder: " + e.message) );
        } else {
            NewRelicError( new Error("API Failure getLatestFundingRoundResponder with no message") );
        }

        if (e.response.status == 401) {
            yield put(Unauthorized(e));
        }
        yield put(getLatestFundingRound.failure(e));
    } finally {

    }
}

function* getVerticalsResponder({ payload }) {
    try {
        const { data } = yield call(companies.getVerticals, payload.company_id, payload);
        // console.log( data );
        if( data.length ){
            let verticals = data.map(item => {
                const { vertical } = item;
                return {
                    id: vertical.id,
                    name: vertical.name,
                    selected: true
                }
            });
            
            // console.log( verticals );
            verticals = verticals.filter( item => ['saas', 'tmt'].includes(item.name.toLowerCase()) == false );
            
            yield put(getSimilarCompanies.request({ selectedVertical: verticals }));
        }
        
        yield put(getVerticals.success(data));
    } catch (e) {
        if( e.message && e.response ){
            const err_payload = {
                type: 'api_failure_getVerticalsResponder',
                data: {
                    config: e.response.config,
                    data: e.response.data,
                    status: e.response.status
                },
                err: new Error("API Failure getVerticalsResponder: " + e.message)
            }
            logEventInternallyAndNewRelic(err_payload);
        } else if (e.message) {
            NewRelicError( new Error("API Failure getVerticalsResponder: " + e.message) );
        } else {
            NewRelicError( new Error("API Failure getVerticalsResponder with no message") );
        }

        if (e.response.status == 401) {
            yield put(Unauthorized(e));
        }
        yield put(getVerticals.failure(e));
    } finally {

    }
}

function* getCompanyInvestorsResponder({ payload }) {
    try {
        const { data } = yield call(companies.getCompanyInvestors, payload.company_id, payload);

        yield put(getCompanyInvestors.success(data));
    } catch (e) {
        if( e.message && e.response ){
            const err_payload = {
                type: 'api_failure_getCompanyInvestorsResponder',
                data: {
                    config: e.response.config,
                    data: e.response.data,
                    status: e.response.status
                },
                err: new Error("API Failure getCompanyInvestorsResponder: " + e.message)
            }
            logEventInternallyAndNewRelic(err_payload);
        } else if (e.message) {
            NewRelicError( new Error("API Failure getCompanyInvestorsResponder: " + e.message) );
        } else {
            NewRelicError( new Error("API Failure getCompanyInvestorsResponder with no message") );
        }

        if (e.response.status == 401) {
            yield put(Unauthorized(e));
        }
        yield put(getCompanyInvestors.failure(e));
    } finally {

    }
}


function* getClubDetailResponder({ payload }) {
    try {
        const { data } = yield call(club_company.getCompany, payload.company_id);
        yield put(getClubDetail.success(data));
    } catch (e) {
        if( e.message && e.response ){
            const err_payload = {
                type: 'api_failure_getClubDetailResponder',
                data: {
                    config: e.response.config,
                    data: e.response.data,
                    status: e.response.status
                },
                err: new Error("API Failure getClubDetailResponder: " + e.message)
            }
            logEventInternallyAndNewRelic(err_payload);
        } else if (e.message) {
            NewRelicError( new Error("API Failure getClubDetailResponder: " + e.message) );
        } else {
            NewRelicError( new Error("API Failure getClubDetailResponder with no message") );
        }

        if (e.response.status == 401) {
            yield put(Unauthorized(e));
        }
        yield put(getClubDetail.failure(e));
    } finally {

    }
}

function* getDealTimelineResponder({ payload }) {
    try {
        const { data } = yield call(club_company.getDealTimeline, payload.company_id);
        yield put(getDealTimeline.success(data));
    } catch (e) {
        if( e.message && e.response ){
            const err_payload = {
                type: 'api_failure_getDealTimelineResponder',
                data: {
                    config: e.response.config,
                    data: e.response.data,
                    status: e.response.status
                },
                err: new Error("API Failure getDealTimelineResponder: " + e.message)
            }
            logEventInternallyAndNewRelic(err_payload);
        } else if (e.message) {
            NewRelicError( new Error("API Failure getDealTimelineResponder: " + e.message) );
        } else {
            NewRelicError( new Error("API Failure getDealTimelineResponder with no message") );
        }

        if (e.response.status == 401) {
            yield put(Unauthorized(e));
        }
        yield put(getDealTimeline.failure(e));
    } finally {

    }
}

function* getEmployeeResponder({ payload }) {
    try {
        const { data } = yield call(employee.getByCompany, payload.company_id);
        yield put(getEmployee.success(data));
    } catch (e) {
        if( e.message && e.response ){
            const err_payload = {
                type: 'api_failure_getEmployeeResponder',
                data: {
                    config: e.response.config,
                    data: e.response.data,
                    status: e.response.status
                },
                err: new Error("API Failure getEmployeeResponder: " + e.message)
            }
            logEventInternallyAndNewRelic(err_payload);
        } else if (e.message) {
            NewRelicError( new Error("API Failure getEmployeeResponder: " + e.message) );
        } else {
            NewRelicError( new Error("API Failure getEmployeeResponder with no message") );
        }

        if (e.response.status == 401) {
            yield put(Unauthorized(e));
        }
        yield put(getEmployee.failure(e));
    } finally {

    }
}

function* getPublicNewsArticleResponder({ payload }) {
    try {
        const { data } = yield call(public_news_article.getByCompany, payload.company_id);
        yield put(getPublicNewsArticle.success(data));
    } catch (e) {
        if( e.message && e.response ){
            const err_payload = {
                type: 'api_failure_getPublicNewsArticleResponder',
                data: {
                    config: e.response.config,
                    data: e.response.data,
                    status: e.response.status
                },
                err: new Error("API Failure getPublicNewsArticleResponder: " + e.message)
            }
            logEventInternallyAndNewRelic(err_payload);
        } else if (e.message) {
            NewRelicError( new Error("API Failure getPublicNewsArticleResponder: " + e.message) );
        } else {
            NewRelicError( new Error("API Failure getPublicNewsArticleResponder with no message") );
        }

        if (e.response.status == 401) {
            yield put(Unauthorized(e));
        }
        yield put(getPublicNewsArticle.failure(e));
    } finally {

    }
}

function* getDealLinksResponder({ payload }) {
    try {
        const { data } = yield call(club_company.getDealLinks, payload.company_id);
        yield put(getDealLinks.success(data));
    } catch (e) {
        if( e.message && e.response ){
            const err_payload = {
                type: 'api_failure_getDealLinksResponder',
                data: {
                    config: e.response.config,
                    data: e.response.data,
                    status: e.response.status
                },
                err: new Error("API Failure getDealLinksResponder: " + e.message)
            }
            logEventInternallyAndNewRelic(err_payload);
        } else if (e.message) {
            NewRelicError( new Error("API Failure getDealLinksResponder: " + e.message) );
        } else {
            NewRelicError( new Error("API Failure getDealLinksResponder with no message") );
        }

        if (e.response.status == 401) {
            yield put(Unauthorized(e));
        }
        yield put(getDealLinks.failure(e));
    } finally {

    }
}

function* getHistoricalPriceResponder({ payload }) {
    try {
        const { data } = yield call(club_company.getHistoricalPrice, payload.company_id);
        yield put(getHistoricalPrice.success(data));
    } catch (e) {
        if( e.message && e.response ){
            const err_payload = {
                type: 'api_failure_getHistoricalPriceResponder',
                data: {
                    config: e.response.config,
                    data: e.response.data,
                    status: e.response.status
                },
                err: new Error("API Failure getHistoricalPriceResponder: " + e.message)
            }
            logEventInternallyAndNewRelic(err_payload);
        } else if (e.message) {
            NewRelicError( new Error("API Failure getHistoricalPriceResponder: " + e.message) );
        } else {
            NewRelicError( new Error("API Failure getHistoricalPriceResponder with no message") );
        }

        if (e.response.status == 401) {
            yield put(Unauthorized(e));
        }
        yield put(getHistoricalPrice.failure(e));
    } finally {}
}

function* getAllCompaniesResponder({ payload }) {
    var query_string = ''
    if (payload.paginationStr) {
        query_string += payload.paginationStr
    }
    if (payload.company_id) {
        query_string += payload.company_id
    }
    try {
        const { data } = yield call(companies.getAllCompanies, query_string, payload);

        yield put(getAllCompanies.success(data));
    } catch (e) {
        if( e.message && e.response ){
            const err_payload = {
                type: 'api_failure_getAllCompaniesResponder',
                data: {
                    config: e.response.config,
                    data: e.response.data,
                    status: e.response.status
                },
                err: new Error("API Failure getAllCompaniesResponder: " + e.message)
            }
            logEventInternallyAndNewRelic(err_payload);
        } else if (e.message) {
            NewRelicError( new Error("API Failure getAllCompaniesResponder: " + e.message) );
        } else {
            NewRelicError( new Error("API Failure getAllCompaniesResponder with no message") );
        }

        if (e.response.status == 401) {
            yield put(Unauthorized(e));
        }
        yield put(getAllCompanies.failure(e));
    } finally {

    }
}

function* toggleWatchListResponder({ payload }) {
    try {
        // yield delay(2000);
        const { data } = yield call(watch_list.toggleWatchList, payload);
        yield put(toggleWatchList.success(data));
    } catch (e) {
        if( e.message && e.response ){
            const err_payload = {
                type: 'api_failure_toggleWatchListResponder',
                data: {
                    config: e.response.config,
                    data: e.response.data,
                    status: e.response.status
                },
                err: new Error("API Failure toggleWatchListResponder: " + e.message)
            }
            logEventInternallyAndNewRelic(err_payload);
        } else if (e.message) {
            NewRelicError( new Error("API Failure toggleWatchListResponder: " + e.message) );
        } else {
            NewRelicError( new Error("API Failure toggleWatchListResponder with no message") );
        }

        if (e.response.status == 401) {
            yield put(Unauthorized(e));
        }
        yield put(toggleWatchList.failure(e));
    } finally {}
}

function* RequestOnboardingResponder({ payload }){
    try{
        const { data } = yield call(user_message.createUserMessage, payload);
        yield put(RequestOnboarding.success({
            ...data,
            ...{ error: false, responseMessage: content_placeholders.response_message.onboarding }
        }));
    } catch(e){
        if( e.message && e.response ){
            const err_payload = {
                type: 'api_failure_RequestOnboardingResponder',
                data: {
                    config: e.response.config,
                    data: e.response.data,
                    status: e.response.status
                },
                err: new Error("API Failure RequestOnboardingResponder: " + e.message)
            }
            logEventInternallyAndNewRelic(err_payload);
        } else if (e.message) {
            NewRelicError( new Error("API Failure RequestOnboardingResponder: " + e.message) );
        } else {
            NewRelicError( new Error("API Failure RequestOnboardingResponder with no message") );
        }
        
        if (e.response.status == 401) {
            yield put(Unauthorized(e));
        }

        yield put(RequestOnboarding.failure(...e, ...{ error: true, responseMessage: content_placeholders.error_message.general }));
    } finally {

    }
}

function* getSimilarCompaniesResponder({ payload }){
    try{
        let query_string = "?";
        if (payload.selectedVertical) {
            // console.log(payload.selectedVertical);
            let selected_vertical = payload.selectedVertical.filter( (item) => item.selected );
            
            selected_vertical = selected_vertical.map((item) => item.id);
            query_string += "vertical=" + selected_vertical.join("|") + "&";
            
        }
  
        const { data } = yield call(club_pipeline.getMarketIntelligence, query_string, payload);
        yield put(getSimilarCompanies.success(data));
    } catch(e){
        if( e.message && e.response ){
            const err_payload = {
                type: 'api_failure_getSimilarCompaniesResponder',
                data: {
                    config: e.response.config,
                    data: e.response.data,
                    status: e.response.status
                },
                err: new Error("API Failure getSimilarCompaniesResponder: " + e.message)
            }
            logEventInternallyAndNewRelic(err_payload);
        } else if (e.message) {
            NewRelicError( new Error("API Failure getSimilarCompaniesResponder: " + e.message) );
        } else {
            NewRelicError( new Error("API Failure getSimilarCompaniesResponder with no message") );
        }

        if (e.response.status == 401) {
            yield put(Unauthorized(e));
        }
        yield put(getSimilarCompanies.failure(e));
    } finally{

    }
}

function* saga() {
    yield all([
        takeLatest(getFundingRound.request, getFundingRoundResponder),
        takeLatest(getLatestFundingRound.request, getLatestFundingRoundResponder),
        takeLatest(getAllCompanies.request, getAllCompaniesResponder),
        takeLatest(getCompanyInvestors.request, getCompanyInvestorsResponder),
        takeLatest(getVerticals.request, getVerticalsResponder),
        takeLatest(getClubDetail.request, getClubDetailResponder),
        takeLatest(getEmployee.request, getEmployeeResponder),
        takeLatest(getPublicNewsArticle.request, getPublicNewsArticleResponder),
        takeLatest(getDealLinks.request, getDealLinksResponder),
        takeLatest(getHistoricalPrice.request, getHistoricalPriceResponder),
        takeLatest(getDealTimeline.request, getDealTimelineResponder),
        takeLatest(toggleWatchList.request, toggleWatchListResponder),
        takeLatest(RequestOnboarding.request, RequestOnboardingResponder),
        takeLatest(getSimilarCompanies.request, getSimilarCompaniesResponder),
    ])
}

export default saga;