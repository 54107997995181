import React from 'react';

import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    Paper,
    makeStyles,
    Typography,
} from '@material-ui/core';

import {
    StyledTableRow,
} from '../../../orderbook/common/Table';

const useStyles = makeStyles((theme) => ({
    container: {
        // minHeight: 50,
        // maxHeight: 50,
    },
    SubTitle: {
        fontSize: 12,
        // textAlign: 'center',
        display: 'block',
    },
    Card: {
        padding: 16,
        marginTop: 16,
        marginBottom: 16,
    },
    ul: {
        padding: 0
    },
    LabelCell: {
        width: '50% !important'
    },
    ValueCell: {
        width: '50% !important'
    }
}));


export default (props) => {
    const { goal_summary } = props;
    const classes = useStyles();

    return (
        <React.Fragment>
            <TableContainer component={Paper} className={classes.container}>
                <Table stickyHeader size="small" aria-label="simple table">
                    <TableBody>
                        <StyledTableRow>
                            <TableCell className={classes.LabelCell}>Direct</TableCell>
                            <TableCell className={classes.ValueCell}>
                                {goal_summary.direct_total
                                    ? goal_summary.direct_total > 1000
                                        ? "US$" +
                                        (goal_summary.direct_total / 1000).toFixed(1) + "B"
                                        : "US$" +
                                        goal_summary.direct_total.toFixed(1) + "M"
                                    : goal_summary.direct_total==0 ? `US$${goal_summary.direct_total}` : "-"}
                            </TableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                            <TableCell className={classes.LabelCell}>SPV</TableCell>
                            <TableCell className={classes.ValueCell}>
                                {goal_summary.spv_total
                                    ? goal_summary.spv_total > 1000
                                        ? "US$" +
                                        (goal_summary.spv_total / 1000).toFixed(1) + "B"
                                        : "US$" +
                                        goal_summary.spv_total.toFixed(1) + "M"
                                    : goal_summary.spv_total==0 ? `US$${goal_summary.spv_total}` : "-"}
                            </TableCell>
                        </StyledTableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </React.Fragment>
    )
}