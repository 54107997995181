import {
	put,
	takeLatest,
	all,
	fork,
	call
} from 'redux-saga/effects';
import { withRouter } from 'react-router-dom';

import {
	logoutWithEmail,
	createUserMessage,
	getNotifications,
	markNotificationAllRead,
	markNotificationAllUnRead,
	updateNotificationPreferences
} from './Action';

import {
	ValidateUser
} from '../../login/Action';

import {
	auth,
	user_message,
	notification
} from '../../../api';

import {
	GetToken, RemoveToken
} from '../../../utils';

import {
	compileQueryString,
} from '../../../utils/util';


function* logoutWithEmailResponder({ payload }) {
	try {
		const { data } = yield call(auth.logoutWithEmail, payload);
		const accessToken = GetToken();
		if (accessToken) RemoveToken('authData');
		yield put(logoutWithEmail.success(data))
	}
	catch (e) {
		const accessToken = GetToken();
		if (accessToken) RemoveToken('authData');
		yield put(logoutWithEmail.failure(e));
	}
}

function* createUserMessageResponder({ payload }){
	try{
		const { data } = yield call(user_message.createUserMessage, payload);
		yield put(createUserMessage.success({
			...data, 
			...{ responseMessage: 'Thank you for expressing your interest. The Gateway team will reach out to discuss further.' }
		}));
	} catch (e){
		yield put(createUserMessage.failure(e));
	}
}

function* getNotificationsResponder({ payload }) {
	// yield put(ShowLoader(true));
	try{
		const query_string = compileQueryString(payload.qs);
		const { data } = yield call( notification.getNotifications, query_string );
		yield put(getNotifications.success(data));
	} catch (e){
		if(e && e.response && e.response.status == 401){
			// yield put(Unauthorized(e));
		}
		yield put(getNotifications.failure(e));
	} finally {
		// yield put(HideLoader(true));
	}
}


function* markNotificationAllReadResponder({ payload }) {
	// yield put(ShowLoader(true));
	try{
		const { data } = yield call(notification.markNotificationAllRead );
		yield put(markNotificationAllRead.success(data));
	} catch (e){
		if(e.response.status == 401){
			// yield put(Unauthorized(e));
		}
		yield put(markNotificationAllRead.failure(e));
	} finally {
		// yield put(HideLoader(true));
	}
}


function* markNotificationAllUnReadResponder({ payload }) {
	// yield put(ShowLoader(true));
	try{
		const { data } = yield call(notification.markNotificationAllUnRead );
		yield put(markNotificationAllUnRead.success(data));
	} catch (e){
		if(e.response.status == 401){
			// yield put(Unauthorized(e));
		}
		yield put(markNotificationAllUnRead.failure(e));
	} finally {
		// yield put(HideLoader(true));
	}
}

function* updateNotificationPreferencesResponder({ payload }){
	try{
		const { data } = yield call(notification.updateNotificationPreferences, payload);
		const accessToken = GetToken();
		yield put(ValidateUser.request(accessToken));
		yield put(updateNotificationPreferences.success(data));
	} catch(e){
		yield put(updateNotificationPreferences.failure(e));
	} finally{

	}
}


function* saga(){
	yield all([
		takeLatest(logoutWithEmail.request, logoutWithEmailResponder),
		takeLatest(createUserMessage.request, createUserMessageResponder),
		takeLatest(getNotifications.request, getNotificationsResponder),
		takeLatest(markNotificationAllRead.request, markNotificationAllReadResponder),
		takeLatest(markNotificationAllUnRead.request, markNotificationAllUnReadResponder),
		takeLatest(updateNotificationPreferences.request, updateNotificationPreferencesResponder),
	])
}

export default saga;