import React from 'react';

import {
    Tooltip,
    List,
    ListItem,
    ListItemText,
    makeStyles,
} from '@material-ui/core';

import {
    global150BodyFont,
    globalBodyFont,
} from '../../../styles';

import {
    numberWithCommas,
    ReScaleForDeviceScale,
} from '../../../../utils/util';

const useStyles = makeStyles((theme) => ({
    Popper: {
        '& .MuiTooltip-popper': {
            background: theme.palette.common.white,
            boxShadow: '0 0 3px #333',
            color: theme.palette.common.black,
            zIndex: 1000,
        }
    },
    ListItemText: {
        fontSize: ReScaleForDeviceScale(1.5, global150BodyFont, globalBodyFont),
    },
    ListItem: {
        padding: 0,
        margin: 0,
    },
    List: {
        padding: 0,
    }
}));

export default (props) => {
    const classes = useStyles();

    const bid_percent = (props.data.bid_price - props.latest_average.price) / props.latest_average.price * 100;
    const offer_percent = (props.data.bid_price - props.latest_funding_round.price) / props.latest_funding_round.price * 100;

    const compare_to_ave = props.latest_average.price && props.data.bid_price ? 
                                props.data.bid_price < props.latest_average.price ?    
                                    <span>Priced at {numberWithCommas( Math.abs(bid_percent) )}% below latest average price</span> :
                                    props.data.bid_price > props.latest_average.price ?
                                        <span>Priced at {numberWithCommas( Math.abs(bid_percent) )}% above latest average price</span> :
                                        <span>Priced at on par to latest average price</span>
                                :'';

    const compare_to_lfr = props.latest_funding_round.price && props.data.bid_price ?
                                props.data.bid_price < props.latest_funding_round.price ?
                                    <span>Priced at {numberWithCommas( Math.abs(offer_percent) )}% discount to latest funding round</span> :
                                    props.data.bid_price > props.latest_funding_round.price ?
                                        <span>Priced at {numberWithCommas( Math.abs(offer_percent) )}% premium to latest funding round</span>  :
                                        <span>Priced at on par to latest funding round</span> 
                                : '';

    return (
        <Tooltip
            // PopperProps={{
            //     className: classes.Popper
            // }}
            placement={props.placement}
            title={
                <React.Fragment>
                    <List className={classes.List}>
                        {
                            compare_to_ave ? 
                                <ListItem className={classes.ListItem}>
                                    <ListItemText classes={{ primary: classes.ListItemText }} primary={compare_to_ave} />
                                </ListItem> : ''
                        }
                        {
                            compare_to_lfr ? 
                                <ListItem className={classes.ListItem}>
                                    <ListItemText classes={{ primary: classes.ListItemText }} primary={compare_to_lfr} />
                                </ListItem> : ''
                        }
                    </List>
                </React.Fragment>
            }
            >
                {props.children}
        </Tooltip>
    )
}