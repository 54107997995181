// import * as React from 'react';
import { connect } from 'react-redux';

import {
    SignInWithEmailAndPassword,
    GetPiAgreement,
    SetAgreementFalse,
    SetUpdatePasswordFalse,
    SetAgreementAndPassword,
    GetConfidentialityAgreement,
    GetPlatformGuide,
    GetOrderbookAgreement,
    GetWealthPlatformGuide,
    GetWealthAgreement,
    GetOBWealthPlatformGuide,
    GetOBWealthAgreement,
} from '../Action';

import {
    confirmPasswordChange
} from '../../account/Action';

import {
    logoutWithEmail
} from '../../common/header/Action';

import Disclaimer from './Disclaimer';

export default connect(
    state => ({
        loginError: state.login.reducer.loginError,
        loginResponse: state.login.reducer.loginResponse,
        loginUserData: state.login.reducer.loginUserData,
        showSnackbarInLogin: state.login.reducer.showSnackbarInLogin,
        getPasscodeResponse: state.login.reducer.getPasscodeResponse,
        disclaimer: state.login.reducer.disclaimer,
        updatePassword: state.login.reducer.updatePassword,
        pi_content: state.login.reducer.pi_content,
        agreementAndPasswordSet: state.login.reducer.agreementAndPasswordSet,
        ca_content: state.login.reducer.ca_content,
        oba_content: state.login.reducer.oba_content,
        wpg_content: state.login.reducer.wpg_content,
        wa_content: state.login.reducer.wa_content,
        // obwpg_content: state.login.reducer.obwpg_content,
        obwa_content: state.login.reducer.obwa_content,
    }),
    dispatch => ({
        SignInWithEmailAndPassword: payload => dispatch(SignInWithEmailAndPassword.request(payload)),
        GetPiAgreement: payload => dispatch(GetPiAgreement.request(payload)),
        confirmPasswordChange: payload => dispatch(confirmPasswordChange.request(payload)),
        SetAgreementFalse:payload => dispatch(SetAgreementFalse.request(payload)),
        SetUpdatePasswordFalse:payload => dispatch(SetUpdatePasswordFalse.request(payload)),
        SetAgreementAndPassword: payload => dispatch(SetAgreementAndPassword.request(payload)),
        GetConfidentialityAgreement: payload => dispatch(GetConfidentialityAgreement.request(payload)),
        GetPlatformGuide: payload => dispatch(GetPlatformGuide.request(payload)),
        GetOrderbookAgreement: payload => dispatch(GetOrderbookAgreement.request(payload)),
        logoutWithEmail: payload => dispatch(logoutWithEmail.request(payload)),
        GetWealthPlatformGuide: payload => dispatch(GetWealthPlatformGuide.request(payload)),
        GetWealthAgreement: payload => dispatch(GetWealthAgreement.request(payload)),
        // GetOBWealthPlatformGuide: payload => dispatch(GetOBWealthPlatformGuide.request(payload)),
        GetOBWealthAgreement: payload => dispatch(GetOBWealthAgreement.request(payload)),
    })
)(Disclaimer);
