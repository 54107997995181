import {
    put,
    takeLatest,
    all,
    call,
} from 'redux-saga/effects';

import {
    UpdateMyAccess,
} from './Action';

import {
    ShowLoader,
    HideLoader,
} from '../root/Action';

import {
    access_right,
} from '../../api';

import {
    handleCatchSagaError,
} from '../../utils/util';

import {
    ValidateUser,
} from '../login/Action';

function* UpdateMyAccessResponder({ payload }){
    try{
        yield put(ShowLoader(true))
        const { data } = yield call(access_right.updateAccessRight, {permission: payload.permission});
        if( payload.validate ) yield put(ValidateUser.request(payload));
        yield put(UpdateMyAccess.success(data));
    } catch(e){
        yield handleCatchSagaError(e, 'UpdateMyAccessResponder')
        yield put(UpdateMyAccess.failure(e));
    } finally{
        yield put(HideLoader(true))
    }
}

function* saga(){
    yield all([
        takeLatest(UpdateMyAccess.request, UpdateMyAccessResponder)
    ])
}

export default saga;