export default {
  pipelineResponse: [],
  expressInterestError: false,
  expressInterestResponseMessage: "",
  referralError: false,
  referralResponseMessage: "",
  // headquarterCountryResponse:[],
  // verticalsResponse:[],
  adBanner1Response: [],
  marketIntelligenceResponse: [],
  page: 0,
  toggleWatchError: false,
  toggleWatchListMessage: "",
  showWatchList: false,
  popular_companies: [],
};
