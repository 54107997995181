import React from 'react';
import ReactECharts from 'echarts-for-react';
import { Link, withRouter } from 'react-router-dom';

import {
    withStyles,
    Grid,
    Paper,
    Card,
    Typography,
    Button,
} from '@material-ui/core';

import {
    ToggleButton,
    ToggleButtonGroup,
} from '@material-ui/lab';

import { sendGAEvent } from '../../../../../utils/ga';

import {
    get_first_price,
    handle_missing_data,
} from '../../../../../utils/PriceChartUtil';

import {
    ReScaleForDeviceScale,
    getLatestFundingRound,
    roundNumber,
    getImpliedVal,
} from '../../../../../utils/util';

import {
    globalFontFamily,
    global150BodyFont,
    globalBodyFont,
} from '../../../../styles';

import { getOption } from './ChartConfig';
import blurChartImage from '../../../../../assets/images/blur_small.png';

import { LockedGraph } from '../../../LockedGraph';

const PriceChartStyle = theme => ({
    ToggleButton: {
        fontSize: ReScaleForDeviceScale(1.5, global150BodyFont, globalBodyFont),
        fontFamily: globalFontFamily,
        color: theme.palette.common.black,
        borderColor: theme.palette.common.black,
    },
    Checkbox: {
        padding: 2
    },
    Card: {
        padding: 16,
        // marginTop: 16,
        // marginBottom: 16,
    },
    SubTitle: {
        fontSize: 12,
        display: 'block'
    },
    Button: {
        display: 'block',
        width: '100%',
        background: 'rgba(252,163,16,0.8)',
        color: theme.palette.common.black,
        '&.hover': {
            color: theme.palette.common.black,
            backgroundColor: 'rgba(213,226,253,1)',
        }
    },
    DetailButton: {
        display: 'block',
        width: '100%',
        border: '1px solid #CCC',
    },
    cellGreen: {
        color: 'green'
    },
    cellRed: {
        color: 'red'
    }
});

function getDiscPrem(price, lfr_price, classes){
    const disc_prem = ( price - lfr_price ) / lfr_price * 100;
    return disc_prem > 0 ? <span className={classes.cellGreen}>{roundNumber(disc_prem)}%</span> : 
                disc_prem < 0 ? <span className={classes.cellRed}>{roundNumber(disc_prem)}%</span> : <span>{roundNumber(disc_prem)}%</span>;
}

class PriceChart extends React.Component{
    state = {
        company_id: '',
        date: [],
        average_bid: [],
        average_offer: [],
        highest_bid: [],
        lowest_offer: [],
        count_bid: [],
        count_offer: [],
        latest_funding_rounds: [],
        latest_funding_rounds_val: [],
        my_target_price: [],
        min_y: 0,
        max_y: 0,
        show_latest_funding_rounds: false,
        price_line_type: 'average_price',
        zoom_level: 'all',
        annotation: {
            date: '',
            buy: 0,
            sell: 0,
            lfr_price: 0,
            lfr_val: 0,
            highest_bid: 0,
            lowest_offer: 0,
            my_target_price: 0,
        },
        average_bid_visual_map: [],
        average_offer_visual_map: [],
        highest_bid_visual_map: [],
        lowest_offer_visual_map: [],
        lfr_visual_map: [],
        my_target_visual_map: [],
        showBlurImage: false,
        showEmptyGraph: false,
    }

    constructor(props) {
        super(props);
        this.myChart = React.createRef();
        this.handleChartEvents = this.handleChartEvents.bind(this);
    }

    componentDidMount(){
        
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        // console.log( nextProps );
        const {
            company_id,
            funding_rounds,
            historical_pricing,
            item,
        } = nextProps;
        
        if( company_id != prevState.company_id ){
            if( historical_pricing.historical_pricing.length ){
                const my_target_price = item.my_ask_price ? item.my_ask_price : item.my_bid_price;

                let payload = {
                    date: [],
                    average_bid: [],
                    average_offer: [],
                    highest_bid: [],
                    lowest_offer: [],
                    count_bid: [],
                    count_offer: [],
                    latest_funding_rounds: [],
                    latest_funding_rounds_val: [],
                    my_target_price: [],
                    min_y: 0,
                    max_y: 0,
                    average_bid_visual_map: [],
                    average_offer_visual_map: [],
                    highest_bid_visual_map: [],
                    lowest_offer_visual_map: [],
                    lfr_visual_map: [],
                    my_target_visual_map: [],
                    showBlurImage: false,
                    showEmptyGraph: false,
                }

                const first_price = get_first_price(historical_pricing.historical_pricing);

                let ave_bid_check_payload = {
                    last_actual_data: first_price.average_bid ? first_price.average_bid : 0,
                    null_index: -1,
                    last_data_checkpoint: 0
                };

                let ave_offer_check_payload = {
                    last_actual_data: first_price.average_offer ? first_price.average_offer : 0,
                    null_index: -1,
                    last_data_checkpoint: 0,
                };

                let highest_bid_check_payload = {
                    last_actual_data: first_price.highest_bid ? first_price.highest_bid : 0,
                    null_index: -1,
                    last_data_checkpoint: 0,
                }

                let lowest_offer_check_payload = {
                    last_actual_data: first_price.lowest_offer ? first_price.lowest_offer : 0,
                    null_index: -1,
                    last_data_checkpoint: 0,
                }

                for( var ii=0; ii<historical_pricing.historical_pricing.length; ii++ ){
                    payload['date'].push( historical_pricing.historical_pricing[ii].date );
                    payload['average_bid'].push( roundNumber(historical_pricing.historical_pricing[ii].average_bid) );
                    payload['average_offer'].push( roundNumber(historical_pricing.historical_pricing[ii].average_offer) );
                    payload['highest_bid'].push( roundNumber(historical_pricing.historical_pricing[ii].highest_bid) );
                    payload['lowest_offer'].push( roundNumber(historical_pricing.historical_pricing[ii].lowest_offer) );
                    if( my_target_price ){
                        payload['my_target_price'].push( roundNumber(my_target_price) );
                        payload['my_target_visual_map'].push({
                            color: 'rgba(7, 160, 230, 1)',
                            gte: 0,
                            lt: historical_pricing.historical_pricing.length,
                            symbol: 'none'
                        }) 
                    }
                    payload['count_bid'].push( historical_pricing.historical_pricing[ii].count_bid );
                    payload['count_offer'].push( historical_pricing.historical_pricing[ii].count_offer );

                    let response = handle_missing_data(ii, ave_bid_check_payload, historical_pricing.historical_pricing, payload, '69,97,168', 'average_bid');
                    payload = response['payload'];
                    ave_bid_check_payload = response['check_payload'];

                    response = handle_missing_data(ii, ave_offer_check_payload, historical_pricing.historical_pricing, payload, '252,163,16', 'average_offer');
                    payload = response['payload'];
                    ave_offer_check_payload = response['check_payload'];

                    response = handle_missing_data(ii, highest_bid_check_payload, historical_pricing.historical_pricing, payload, '69,97,168', 'highest_bid');
                    payload = response['payload'];
                    highest_bid_check_payload = response['check_payload'];

                    response = handle_missing_data(ii, lowest_offer_check_payload, historical_pricing.historical_pricing, payload, '252,163,16', 'lowest_offer');
                    payload = response['payload'];
                    lowest_offer_check_payload = response['check_payload'];

                    const lfr_response = getLatestFundingRound(funding_rounds, (fr_item) => fr_item['deal_date'] < historical_pricing.historical_pricing[ii]['date']);
                    
                    if( lfr_response.price && lfr_response.valuation ){
                        payload['latest_funding_rounds'].push(lfr_response.price);
                        payload['latest_funding_rounds_val'].push(lfr_response.valuation);
                    }
                    
                }

                const lfr_visual_map_payload = {
                    color: 'rgba(34,139,34,1)',
                    gte: 0,
                    lt: historical_pricing.historical_pricing.length,
                    symbol: 'none'
                }

                payload['lfr_visual_map'].push(lfr_visual_map_payload);
                payload['showBlurImage'] = !historical_pricing.allow;
                payload['showEmptyGraph'] = !historical_pricing.data;
                return payload;
            } else {
                let payload = {
                    showBlurImage: false,
                    showEmptyGraph: false,
                }
                payload['showBlurImage'] = !historical_pricing.allow;
                payload['showEmptyGraph'] = !historical_pricing.data;
                return payload;
            }
        } else {
            return null;
        }
        
    }

    handleChartEvents = (params) => {
        
        try {
            const inst = this.myChart.current.getEchartsInstance();

            const pointInPixel = [params.offsetX, params.offsetY];
            const pointInGrid = inst.convertFromPixel('grid', pointInPixel);
        
            const {
                average_bid,
                average_offer,
                latest_funding_rounds,
                latest_funding_rounds_val,
                annotation,
                highest_bid,
                lowest_offer,
                my_target_price,
            } = this.state;
            
            annotation.buy = average_bid[pointInGrid[0]];
            annotation.sell = average_offer[pointInGrid[0]];
            annotation.lfr_price = latest_funding_rounds[pointInGrid[0]];
            annotation.lfr_val = latest_funding_rounds_val[pointInGrid[0]];
            annotation.highest_bid = highest_bid[pointInGrid[0]];
            annotation.lowest_offer = lowest_offer[pointInGrid[0]];
            annotation.my_target_price = my_target_price.length > pointInGrid[0] ? my_target_price[pointInGrid[0]] : 0;
            this.setState({ annotation });
        } catch(e){
            console.log( e );
        }
    }

    handleChartReady = (instance) => {
        const self = this;
        setTimeout(function(){
            // console.log( self.myChart );

            if( self.myChart && self.myChart.current && self.myChart.current.getEchartsInstance() ){
                const inst = self.myChart.current.getEchartsInstance();
                inst.getZr().on('mousemove', self.handleChartEvents);
                inst.on('mousemove', self.handleChartEvents);
            }

        }, 3000);
    }

    handleButtonClick = (url, evt) => {
        const { history } = this.props;
        history.push(url);
    }

    handleChangePriceLineType(key, evt, newValue){
        const { price_line_type } = this.state;
        if( ['', 'price_line_type'].indexOf(key) > -1 && ( newValue == price_line_type || !newValue ) ) return;
        const { company_id } = this.props;
        sendGAEvent({
            type: 'orderbook_click_price_chart',
            data: {
                company_id: company_id,
                metrix_name: key + '_' + newValue
            }
        });

        const new_state = {
            [key]: newValue
        };
        
        this.setState(new_state);
    }

    render(){

        const { 
            classes, 
            companyDetailType, 
            company_id,
            item,
        } = this.props;


        const { annotation, latest_funding_rounds, price_line_type, showBlurImage, showEmptyGraph} = this.state;
        const average_classname = price_line_type=='weighted_price' ? 'legend-item orderbook clickable orderbook-price average' : 'legend-item orderbook clickable orderbook-price high-low';

        let compare_bid = ''
        let compare_offer = ''
        if( price_line_type == 'average_price' ){
            if( annotation.my_target_price && annotation.buy ){
                const bid_diff = ( annotation.buy - annotation.my_target_price ) / annotation.my_target_price * 100;
                compare_bid = `${roundNumber(bid_diff)}% ${bid_diff > 0 ? 'above' : bid_diff < 0 ? 'below' : 'flat'} to your target`;
            }

            if( annotation.my_target_price && annotation.sell ){
                const offer_diff = ( annotation.sell - annotation.my_target_price ) / annotation.my_target_price * 100;
                compare_offer = `${roundNumber(offer_diff)}% ${offer_diff > 0 ? 'above' : offer_diff < 0 ? 'below' : 'flat'} to your target`;
            }
        } else {
            if( annotation.my_target_price && annotation.highest_bid ){
                const bid_diff = ( annotation.highest_bid - annotation.my_target_price ) / annotation.my_target_price * 100;
                compare_bid = `${roundNumber(bid_diff)}% ${bid_diff > 0 ? 'above' : bid_diff < 0 ? 'below' : 'flat'} to your target`;
            }

            if( annotation.my_target_price && annotation.lowest_offer ){
                const offer_diff = ( annotation.lowest_offer - annotation.my_target_price ) / annotation.my_target_price * 100;
                compare_offer = `${roundNumber(offer_diff)}% ${offer_diff > 0 ? 'above' : offer_diff < 0 ? 'below' : 'flat'} to your target`;
            }
        }

        return (
            <section className="price-chart-section orderbook">
                <Grid container spacing={2}>
                    <Grid item xs={12} md={8}>
                        {
                            showEmptyGraph 
                            ? 
                            <Grid item xs={12} style={{height: "100%", display: 'flex', alignItems:'center', justifyContent: 'center'}} onClick={(evt) => this.handleButtonClick(`/${companyDetailType}/company-detail/${company_id}`, evt)}>
                                <span style={{fontSize: 12}}>Limited market activity in this name. Visit the detail page and be the first to place an order.</span>
                            </Grid>
                            :
                            <>
                                <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <div className='legend-wrapper mt-1'>
                                    <div className={average_classname}>
                                        <ToggleButtonGroup exclusive value={price_line_type} onChange={this.handleChangePriceLineType.bind(this, 'price_line_type')}>
                                            <ToggleButton className={classes.ToggleButton} value="average_price" aria-label="left aligned">
                                                Weighted Average
                                            </ToggleButton>
                                            <ToggleButton className={classes.ToggleButton} value="highest_lowest" aria-label="right aligned">
                                                Highest / Lowest
                                            </ToggleButton>
                                        </ToggleButtonGroup>
                                    </div>
                             </div>
                            </Grid>
                        </Grid>
                        
                        <div className="chart-wrapper">
                            {
                                showBlurImage 
                                ? 
                                    <LockedGraph
                                        public_mode={false}
                                        company_id={company_id}
                                        company_name={item.name}
                                        />
                                :
                                <ReactECharts
                                ref={this.myChart}
                                option={getOption(this.state)}
                                style={{ height: 300 }}
                                onChartReady={this.handleChartReady}
                                notMerge={true}
                                />
                            }
                        </div>
                            </>
                        }
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Grid container spacing={2}>
                            {/* <Grid item xs={12}>
                                <Button 
                                    className={ classes.Button }
                                    onClick={(evt) => this.handleButtonClick(evt)}>Express Interest</Button>
                            </Grid> */}
                            <Grid item xs={6}>
                                <Card className={classes.Card}>
                                    <span className={classes.SubTitle}>
                                        {price_line_type === 'average_price' ? 'Ave. Bid (PPS)' : 'Highest Bid (PPS)'}</span>
                                    <React.Fragment>
                                        <Typography variant="h6">
                                            { price_line_type === 'average_price' ? 
                                            (annotation.buy ? `$${annotation.buy}` : ' - ') :
                                            (annotation.highest_bid ? `$${annotation.highest_bid}` : ' - ' )}
                                        </Typography>
                                        <span style={{ fontSize: 12 }}>{ compare_bid }</span>
                                    </React.Fragment>
                                </Card>
                            </Grid>
                            <Grid item xs={6}>
                                <Card className={classes.Card}>
                                    <span className={classes.SubTitle}>
                                        {price_line_type === 'average_price' ? 'Ave. Offer (PPS)' : 'Lowest Offer (PPS)'}</span>
                                    <React.Fragment>
                                        <Typography variant="h6">
                                            { price_line_type === 'average_price' ? 
                                            (annotation.sell ? `$${annotation.sell}` : ' - ') :
                                            (annotation.lowest_offer ? `$${annotation.lowest_offer}` : ' - ') }
                                        </Typography>
                                        <span style={{ fontSize: 12 }}>{ compare_offer }</span>
                                    </React.Fragment>
                                </Card>
                            </Grid>
                            {
                                // annotation.lfr_price && annotation.lfr_val
                                latest_funding_rounds.length ?
                                    <React.Fragment>
                                        <Grid item xs={6}>
                                            <Card className={classes.Card}>
                                                <span className={classes.SubTitle}>
                                                    Implied Val. (<span className={classes.cellRed}>Disc.</span>/<span className={classes.cellGreen}>Prem.</span>)*
                                                </span>
                                                <Typography variant="h6">
                                                    { price_line_type === 'average_price' ? 
                                                        (
                                                            annotation.buy ? 
                                                            <React.Fragment>
                                                                {getImpliedVal(annotation.buy, annotation.lfr_price, annotation.lfr_val)} 
                                                                ({getDiscPrem(annotation.buy, annotation.lfr_price, classes)})
                                                            </React.Fragment>
                                                            : ' - '
                                                        ):
                                                        (
                                                            annotation.highest_bid ? 
                                                            <React.Fragment>
                                                                {getImpliedVal(annotation.highest_bid, annotation.lfr_price, annotation.lfr_val)} 
                                                                ({getDiscPrem(annotation.highest_bid, annotation.lfr_price, classes)})
                                                            </React.Fragment>
                                                            : ' - '
                                                        )
                                                    }
                                                </Typography>
                                            </Card>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Card className={classes.Card}>
                                                <span className={classes.SubTitle}>
                                                    Implied Val. (<span className={classes.cellRed}>Disc.</span>/<span className={classes.cellGreen}>Prem.</span>)*
                                                </span>
                                                <Typography variant="h6">
                                                    { price_line_type === 'average_price' ? 
                                                        (
                                                            annotation.sell ? 
                                                            <React.Fragment>
                                                                {getImpliedVal(annotation.sell, annotation.lfr_price, annotation.lfr_val)} 
                                                                ({getDiscPrem(annotation.sell, annotation.lfr_price, classes)})
                                                            </React.Fragment>
                                                            : ' - ' 
                                                        ) : 
                                                        (
                                                            annotation.lowest_offer ? 
                                                            <React.Fragment>
                                                                {getImpliedVal(annotation.lowest_offer, annotation.lfr_price, annotation.lfr_val)} 
                                                                ({getDiscPrem(annotation.lowest_offer, annotation.lfr_price, classes)})
                                                            </React.Fragment>
                                                            : ' - ' 
                                                        )
                                                    }
                                                </Typography>
                                            </Card>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <p style={{fontSize: 12}}>*Discount / premium compare to latest funding round.</p>
                                        </Grid>
                                    </React.Fragment> : ''
                            }
                            
                            <Grid item xs={12}>
                                <Button 
                                    className={classes.DetailButton}
                                    onClick={(evt) => this.handleButtonClick(`/${companyDetailType}/company-detail/${company_id}`, evt)}>Detail</Button>
                            </Grid>
                        </Grid>    
                    </Grid>
                </Grid>
            </section>
        )

    }
}

export default withRouter(withStyles(PriceChartStyle)(PriceChart))