import {
    put,
    takeLatest,
    all,
    call,
} from 'redux-saga/effects';

import {
    getProspectInvestorList,
    getMoreProspectInvestors,
    postSubmitInvestorApproval,
} from './Action';

import {
    ShowLoader,
    HideLoader,
} from '../../root/Action';

import {
    boost,
} from '../../../api';

import {
    handleCatchSagaError,
    compileQueryString,
} from '../../../utils/util';


function* getProspectInvestorListResponder({ payload }){
    try{
        yield put(ShowLoader(true));
        const query_string = compileQueryString(payload.qs);
        const { data } = yield call(boost.getProspectInvestorList, payload.fundraising_id, query_string, payload);
        yield put(getProspectInvestorList.success({payload, data}));        
    } catch(e){
        // console.log( e );
        yield handleCatchSagaError(e, 'getProspectInvestorListResponder');
        yield put(getProspectInvestorList.failure(e));
    } finally{
        yield put(HideLoader(true));
    }
}

function* postSubmitInvestorApprovalResponder({ payload }){
    try{
        yield put(ShowLoader(true));
        const { data } = yield call(boost.postSubmitInvestorApproval, payload.fundraising_id, payload);
        yield put(getProspectInvestorList.request({ fundraising_id: payload.fundraising_id, qs: { fundraising_id: payload.fundraising_id }}));
        yield put(postSubmitInvestorApproval.success(data));
    } catch(e){
        yield handleCatchSagaError(e, 'postSubmitInvestorApprovalResponder');
        yield put(postSubmitInvestorApproval.failure(e));
    } finally{
        yield put(HideLoader(true));
    }
}


function* saga(){
    yield all([
        takeLatest(getProspectInvestorList.request, getProspectInvestorListResponder),
        takeLatest(postSubmitInvestorApproval.request, postSubmitInvestorApprovalResponder),
    ])
}

export default saga;