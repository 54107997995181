import { connect } from 'react-redux';
import { default as PortfolioHolding } from './PortfolioHolding';

import {
    getPriceIndex,
    getCompanyDetail,
    getFundingRound,
    getMyPortfolio,
} from './Action';

import {
    CollapseSidebar,
} from '../../root/Action';

export default connect(
    state => ({
        price_index: state.portfolioHolding.reducer.price_index,
        cost_base: state.portfolioHolding.reducer.cost_base,
        company_details: state.portfolioHolding.reducer.company_details,
        funding_rounds: state.portfolioHolding.reducer.funding_rounds,
        collapseSidebar: state.root.reducer.collapseSidebar,
        loginUserData: state.login.reducer.loginUserData,
        appConfig: state.root.reducer.appConfig,
    }),
    dispatch => ({
        getPriceIndex: (payload) => dispatch(getPriceIndex.request(payload)),
        CollapseSidebar: (payload) => dispatch(CollapseSidebar(payload)),
        getCompanyDetail: (payload) => dispatch(getCompanyDetail.request(payload)),
        getFundingRound: (payload) => dispatch(getFundingRound.request(payload)),
        getMyPortfolio: (payload) => dispatch(getMyPortfolio.request(payload)),
    })
)(PortfolioHolding);