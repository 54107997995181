// import * as React from 'react';
import { connect } from "react-redux";

import {
	getPipeline,
	postReferral,
	getMarketIntelligence,
	getVerticalsList,
	getHeadquaterCountryList,
	getWatchList,
	toggleWatchList,
	getViewSummary,
	// updateProps
	// expressInterest,
	// getAdBanner1
} from "./Action";

import { createUserMessage } from "../../common/header/Action";

import { resetSnackBar } from "../../account/Action";

import { CollapseSidebar } from "../../root/Action";

import { default as Pipeline } from "./Pipeline";
export default connect(
	(state) => ({
		pipelineResponse: state.clubPipeline.reducer.pipelineResponse,
		page: state.clubPipeline.reducer.page,
		marketIntelligenceResponse:
			state.clubPipeline.reducer.marketIntelligenceResponse,
		watchListResponse: state.clubPipeline.reducer.watchListResponse,
		verticalsListResponse: state.clubPipeline.reducer.verticalsListResponse,
		headquaterCountryListResponse:
			state.clubPipeline.reducer.headquaterCountryListResponse,
		// expressInterestError: state.pipeline.reducer.expressInterestError,
		// expressInterestResponseMessage: state.pipeline.reducer.expressInterestResponseMessage,
		referralError: state.clubPipeline.reducer.referralError,
		referralResponseMessage: state.clubPipeline.reducer.referralResponseMessage,
		showSnakbar: state.account.reducer.showSnakbar,
		passwordError: state.account.reducer.passwordError,
		responseMsg: state.account.reducer.responseMsg,
		loginUserData: state.login.reducer.loginUserData,
		loaderState: state.root.reducer.loaderState,
		toggleWatchError: state.clubPipeline.reducer.toggleWatchError,
		toggleWatchListMessage: state.clubPipeline.reducer.toggleWatchListMessage,
		popular_companies: state.clubPipeline.reducer.popular_companies,
		collapseSidebar: state.root.reducer.collapseSidebar,
	}),
	(dispatch) => ({
		getPipeline: (payload) => dispatch(getPipeline.request(payload)),
		getMarketIntelligence: (payload) =>
			dispatch(getMarketIntelligence.request(payload)),
		getHeadquaterCountryList: (payload) =>
			dispatch(getHeadquaterCountryList.request(payload)),
		getVerticalsList: (payload) => dispatch(getVerticalsList.request(payload)),
		postReferral: (payload) => dispatch(postReferral.request(payload)),
		createUserMessage: (payload) =>
			dispatch(createUserMessage.request(payload)),
		getWatchList: (payload) => dispatch(getWatchList.request(payload)),
		toggleWatchList: (payload) => dispatch(toggleWatchList.request(payload)),
		updateProps: (payload) =>
			dispatch({ type: "UPDATE_PROPS", payload: payload }),
		// expressInterest: payload => dispatch(expressInterest.request(payload)),
		// getAdBanner1: () => dispatch(getAdBanner1.request())
		getViewSummary: () => dispatch(getViewSummary.request()),
		resetSnackBar: () => dispatch(resetSnackBar()),
		CollapseSidebar: (payload) => dispatch(CollapseSidebar(payload)),
	})
)(Pipeline);
