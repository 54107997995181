import React from 'react';
import ReactECharts from 'echarts-for-react';

import {
    Grid,
    withStyles,
} from '@material-ui/core';

import { getOption } from './ChartConfig';
import { CommonStyles } from '../../../styles';
// import { roundNumber } from '../../../../utils/util';

const useStyles = theme => ({
    FormControlLabel: CommonStyles.FormControlLabel,
    Checkbox: CommonStyles.Checkbox,
});

const month_map = {
    1: 'Jan', 2: 'Feb', 3: 'Mar', 4: 'Apr', 5: 'May', 6: 'Jun', 7: 'Jul', 8: 'Aug', 9: 'Sep', 10: 'Oct', 11: 'Nov', 12: 'Dec'
}

class PriceChart extends React.Component{
    state = {
        trends: {},
        date: [],
        min_y: 0
    }

    constructor(props){
        super(props);
    }

    static getDerivedStateFromProps(nextProps, prevState){
        const {
            trends,
        } = nextProps;

        const state_trends_index = Object.keys( prevState.trends );
        const props_trends_index = Object.keys( trends );
        let min_y = 0;

        if(
            !props_trends_index.equals( state_trends_index )
        ) {
            const dates = props_trends_index.length ? trends[ props_trends_index[0] ].map(item => { return month_map[item['month']]+'-'+item['year'] } ) : [];
            const new_trends = {}

            props_trends_index.forEach(trend_index => {
                const values = trends[trend_index].map(item => {return item.final});
                min_y = Math.min(...values) < min_y || !min_y ? Math.floor( Math.min(...values) * 0.7 ) : min_y;
                new_trends[trend_index] = values;
            });

            return {
                trends: new_trends,
                date: dates,
                min_y: min_y,
            }
        } else {
            return null;
        }
    }

    render(){
        const { classes } = this.props;

        return (
            <section className="price-chart-section">
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <ReactECharts
                            option={getOption(this.state)}
                            style={{ height: 500 }}
                            notMerge={true}
                            />
                    </Grid>
                </Grid>
            </section>
        )
    }
}

export default withStyles(useStyles)(PriceChart);