import Cookies from 'universal-cookie';

export default (payload) => {
    const cookieMaxAge = 600;
    const cookies = new Cookies();
    cookies.set('preservedUrl_pathname', payload.pathname, { maxAge: cookieMaxAge });
    cookies.set('preservedUrl_search', payload.search, { maxAge: cookieMaxAge });
    cookies.set('preservedUrl_set_time', payload.set_time, { maxAge: cookieMaxAge });
} 
// {
//     localStorage.setItem('preservedUrl', JSON.stringify(payload));
// }