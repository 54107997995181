import React, { useState, useEffect } from 'react';

import {
    Dialog,
    DialogContent,
    Button,
    Grid,
    Typography,
    Modal,
} from '@material-ui/core';
import Check from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import OctagonBg from '../../../../assets/images/octagon_bg.svg';

export default (props) => {

    return (
        <Dialog
            open={props.open}
            fullWidth
            maxWidth="md"
        >
            <DialogContent className="campaign-dialog">
                <CloseIcon style={{ position: 'absolute', top: 20, right: 20, cursor: 'pointer' }} onClick={(evt) => props.handleCloseCampaignialog(evt, false)} />
                <div style={{ marginBottom: '15px' }}>
                    <Typography variant="h6" style={{ margin: '0 24px', textAlign: 'center' }}>To Qualify For Your Complimentary Premium Subscription</Typography>
                    <Grid item xs={12} md={12} style={{ color: "black", fontWeight: 'bold', marginBottom: '10px' }}>
                        <span style={{ position: 'relative', textAlign: 'center' }}>
                            <img src={OctagonBg} style={{maxWidth: '40px'}}/>
                            <p style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>1</p>
                        </span>
                        <span style={{ marginLeft: '20px' }}>
                            Login to the Gateway Orderbook
                            <Check style={{ fontSize: 30, color: '#29a329', marginLeft: '10px' }} />
                        </span>
                    </Grid>
                    <Grid item xs={12} md={12} style={{ color: "black", fontWeight: 'bold', marginBottom: '10px' }}>
                        <span style={{ position: 'relative', textAlign: 'center' }}>
                            <img src={OctagonBg} style={{maxWidth: '40px'}}/>
                            <p style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>2</p>
                        </span>
                        <span style={{ marginLeft: '20px' }}>Add 3 companies to your watchlist</span>
                    </Grid>
                    <Grid container spacing={4} alignItems="center" justifyContent="center">
                        <Grid item xs={12} md={4} clsssName='sub-grid-campaign'>
                            <img src='https://media.gatewayprivatemarkets.com/portal/orderbook/CampaignPicture4.png' style={{ width: '100%', marginBottom: '10px' }} />
                            <Typography variant="body2" style={{ color: "black" }}>
                                Click the star icon to add a company to your watchlist
							</Typography>
                        </Grid>
                        <Grid item xs={12} md={4} clsssName='sub-grid-campaign'>
                            <img src='https://media.gatewayprivatemarkets.com/portal/orderbook/CampaignPicture5.png' style={{ width: '100%', marginBottom: '10px' }} />
                            <Typography variant="body2" style={{ color: "black" }}>
                                Set your parameters and click 'Add to my Watchlist'
							</Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={12} style={{ color: "black", fontWeight: 'bold', marginBottom: '10px' }}>
                        <span style={{ position: 'relative', textAlign: 'center' }}>
                            <img src={OctagonBg} style={{maxWidth: '40px'}}/>
                            <p style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>3</p>
                        </span>
                        <span style={{ marginLeft: '20px' }}>Start your Price Discovery</span>
                    </Grid>
                    <Button className="btn-submit campaign-dialog-btn" onClick={(evt) => props.handleCloseCampaignialog(evt, true)}>
                        Upgrade to Premium Subscription
                    </Button>
                </div>
            </DialogContent>
        </Dialog>
    )
}