import React from 'react';
import { Helmet } from 'react-helmet';

import {
    withStyles,
} from '@material-ui/core';
import { Styles } from '../../styles';

import { content_placeholders } from '../../../config';

import {
    FullScreenPriceChartDialog,
} from './components';
import { default as CompanyContent } from './CompanyContent';
import { default as DealShareRiskDisclosure } from '../../wealth/company/components/DealShareRiskDisclosure';

class PublicCompany extends React.Component {
    state = {
        dialog_open: false,
    }

    constructor(props) {
        super(props);
    }

    componentDidMount(){
        const { 
            match: { params: { id, token } },
            getRiskDisclosure,
            getSharedUser,
            addCount,
        } = this.props;

        getRiskDisclosure({ 
            public_url: true,
            root_path: 'v2/orderbook/public', 
            sub_path: 'search',
            company_identifier: token,
        });

        getSharedUser({
            qs: {
                token: token
            }
        });

        addCount({ token: token });
    }

    handlePriceChartFullScreen = (event) => {
		const { dialog_open } = this.state;
        this.setState({ dialog_open: !dialog_open });
	}

    handleFakeClick = (event) => {

	}

    handleAcknowledgeRiskDisclosure = (evt) => {
        const { 
            getCompanyDetail,
            getFundingRound,
            getLiveOffers,
            getLiveDemand,
            getHistoricalSecondaryPricing,
            getCompanyVerticals,
            getSimilarCompany,
            // getPermissionedDeal,
            getLiveOffersWatchlist,
            getLiveDemandWatchlist,
            match: { params: { id, token } },
            confirmRiskDisclosure,
            getVciHistoricalRevenue,
            getVciCapTable,
            getVciFundingRound,
        } = this.props;
        confirmRiskDisclosure();

        getCompanyDetail({ 
            public_url: true,
            company_identifier: token,
        });
        getCompanyVerticals({ public_url: true, qs: {token: token }});
        getSimilarCompany({ public_url: true, qs: { token: token } });
        getFundingRound({ 
            root_path: 'v2',
            sub_path: 'orderbook/public',
            company_identifier: '',
            qs: { token: token, show_all: 1 }
        });
        getLiveOffers({
            root_path: 'v2',
            sub_path: 'orderbook/public/offers',
            company_identifier: '',
            qs: { token: token, live: true, asc_or_desc: 'asc' }
        });
        getLiveDemand({
            root_path: 'v2',
            sub_path: 'orderbook/public/demands',
            company_identifier: '',
            qs: { token: token, live: true }
        });
        getLiveOffersWatchlist({
            root_path: 'v2',
            sub_path: 'orderbook/public/offers/watchlist-total-notional',
            company_identifier: '',
            qs: { token: token, live: true, asc_or_desc: 'asc' }
        });
        getLiveDemandWatchlist({
            root_path: 'v2',
            sub_path: 'orderbook/public/demands/watchlist-total-notional',
            company_identifier: '',
            qs: { token: token, live: true }
        });
        getVciHistoricalRevenue({ token: token });
        getVciCapTable({ token: token });
        getVciFundingRound({ token: token });
        // getHistoricalSecondaryPricing({
        //     root_path: 'v2/orderbook/public',
        //     sub_path: 'weighted-prices',
        //     company_identifier: '',
        //     qs: { token: token }
        // });
        // getPermissionedDeal({ company_id: id });
    }

    render() {
        const { dialog_open } = this.state;

        const { 
            riskDisclosureConfirmed,
            riskDisclosureResponse,
            AllCompaniesResponse,
            shared_user,
            loginUserData,
            match: { params : { token } },
        } = this.props;
        
        let companyDetailResponse = AllCompaniesResponse;

        loginUserData['email'] = shared_user && shared_user.user_email ? shared_user.user_email : ''; 
        
        return (
            <div className="home">
                <div className="top-padding"></div>
                <Helmet>
                    <title>Orderbook | {content_placeholders.title_prefix}</title>
                </Helmet>
                <div className="home_main">
                    <div className="sidebar-container company-page narrow">

                    </div>
                    <div className="home-right company-detail-right narrow" id="home-right">
                        {
                            riskDisclosureConfirmed ?
                                <CompanyContent
                                    sharedPage={true}
                                    public_mode={true}
                                    handlePriceChartFullScreen = {this.handlePriceChartFullScreen}
                                    handleShowOrderDetailDialog={this.handleFakeClick}
                                    handlePrefillPlaceOrder={this.handleFakeClick}
                                    handleShowExpressInterest={this.handleFakeClick}
                                    handlePromptExpress={this.handleFakeClick}
                                    {...this.props}
                                    {...{
                                        companyDetailResponse,
                                    }}
                                    /> : 
                                <DealShareRiskDisclosure
                                    public_mode={true}
                                    handleAcknowledgeRiskDisclosure={this.handleAcknowledgeRiskDisclosure}
                                    {...{
                                        riskDisclosureResponse,
                                    }}
                                    />
                        }
                        

                        <FullScreenPriceChartDialog
                            open={dialog_open}
                            price_chart_payload={{...{
                                ...{ token: token },
                                historical_pricing: this.props.historical_pricing,
                                funding_rounds: this.props.funding_rounds,
                                public_mode: true
                            }}}
                            handlePriceChartFullScreen={this.handlePriceChartFullScreen}
                            />
                    </div>
                </div>
            </div>
        )
    }
}

export default withStyles(Styles)(PublicCompany);