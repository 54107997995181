export default {
    AllCompaniesResponse: {},
    investors: [],
    verticals: [],
    fundingRounds: {
        count: 0,
        results: []
    },
    finishedCollectFundingRounds: false,
    latestFundingRound: {},
    clubDetail: {},
    clubDealLinks: [],
    dealTimelines: [],
    toggleWatchError: false,
    toggleWatchListMessage: "",
    historicalPrice: {
        allow: false,
        data: false,
        historical_pricing: []
    },
    dealOffer:{},
    requestInformationtError: false,
    requestInformationtResponseMessage: '',
    live_demand: {
        resultCount: 0,
        resultList: [],
    },
    live_offers: {
        resultCount: 0,
        resultList: [],
    },
    live_demand_watchlist: 0,
    live_offers_watchlist: 0,
    dealHighlight: {},
    dealType: {},
    gp_detail: [],
    shareDealResponse: {
        token: ''
    },
    toggle_watchlist: {
        error: false,
        message: ''
    },
    riskDisclosureConfirmed: false,
    riskDisclosureResponse: {
        content: []
    },
    shared_user: {
        
    },
    addCountResponse: {}
}

// const initialImmutableState = fromJS(initialState)
// export default initialImmutableState;