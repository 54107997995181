import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import TableCell from '@material-ui/core/TableCell'
import TablePagination from '@material-ui/core/TablePagination'
import { TableStyles } from '../styles';
import { withRouter } from 'react-router-dom';


class HorTable extends React.Component {
	constructor(props, context) {
		super(props, context)
		const { order, orderBy, selected, data, page, rowsPerPage, defaultPerPage, filterText } = this.props
		this.state = { order, orderBy, selected, data, page, rowsPerPage, defaultPerPage, filterText, isModalOpen: false }
	}

	handleRequestSort = (event, property) => {
		const orderByAlias = property
		let orderLet = 'desc'
		const { orderBy, order, data } = this.state
		if (orderBy === property && order === 'desc') {
			orderLet = 'asc'
		}
		const dataAlias = orderLet === 'desc'
			? data.sort((a, b) => (b[orderByAlias] < a[orderByAlias] ? -1 : 1))
			: data.sort((a, b) => (a[orderByAlias] < b[orderByAlias] ? -1 : 1))
		this.setState({ data: dataAlias, order: orderLet, orderBy: orderByAlias })
	}

	handleSelectAllClick = (event, checked) => {
		const { data } = this.state
		if (checked) {
			this.setState({ selected: data.map(n => n.id) })
			return
		}
		this.setState({ selected: [] })
	}

	handleClick = (event, id) => {
		const { clickAbleRow } = this.props
		if (clickAbleRow) {
			this.goToDetailPage(id)
		}
		const { selected } = this.state
		const selectedIndex = selected.indexOf(id)
		let newSelected = []

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, id)
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1))
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1))
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(
				selected.slice(0, selectedIndex),
				selected.slice(selectedIndex + 1),
			)
		}

		this.setState({ selected: newSelected })
	}

	handleChangePage = (event, page) => {
		this.props.pageChange(page)
		this.setState({ page })
	}

	componentDidUpdate(prevProps) {
		if (this.props.filterText !== prevProps.filterText) {
			this.setState({ filterText: this.props.filterText })
		}
	}

	handleChangeRowsPerPage = event => {
		this.props.changeRowsPerPage(event.target.value)
		this.setState({ rowsPerPage: event.target.value })
	}

	// eslint-disable-next-line
	isSelected = id => this.state.selected.indexOf(id) !== -1

	handleUserInput(value) {
		const { data, defaultPerPage } = this.state
		// Show all item first
		if (value !== '') {
			this.setState({ rowsPerPage: data.length })
		} else {
			this.setState({ rowsPerPage: defaultPerPage })
		}
		// Show result base on keyword
		this.setState({ filterText: value.toLowerCase() })
	}

	getUserForEdit = (id) => {
		const { getUserIDForEdit } = this.props
		getUserIDForEdit(id)
	}

	getUserForToggle = (id) => {
		const { getUserIDForToggle } = this.props
		getUserIDForToggle(id)
	}

	getUserForDelete = (id) => {
		const { getUserIDForDelete } = this.props
		getUserIDForDelete(id)
	}

	goToDetailPage = (id) => {
		const { route } = this.props
		this.props.history.push(`${route}${id}`)
	}

	render() {
		const { classes, title, isSimple, isEdit, isDelete, clickAbleRow, isToggleable, columnData, count, data, toggleKey, toggleOnCondition, noRecordMessage } = this.props
		const { order, orderBy, selected, rowsPerPage, page, filterText } = this.state
		const checkcell = true
		const renderCell = (dataArray, keyArray) => keyArray.map((itemCell, index) =>
			dataArray[itemCell.id] ? <TableCell align={'left'} key={index.toString()}>{dataArray[itemCell.id]}</TableCell> : null
		)
		return (
			
			<div >
				<Fragment>
					<TablePagination
						component="div"
						count={count}
						rowsPerPage={rowsPerPage}
						page={page}
						backIconButtonProps={{
							'aria-label': 'Previous Page',
						}}
						nextIconButtonProps={{
							'aria-label': 'Next Page',
						}}
						onChangePage={this.handleChangePage}
						onChangeRowsPerPage={this.handleChangeRowsPerPage}
						/>
				</Fragment>
			</div>
		)
	}
}

HorTable.propTypes = {
	classes: PropTypes.object.isRequired,
	data: PropTypes.array.isRequired,
	order: PropTypes.string.isRequired,
	orderBy: PropTypes.string.isRequired,
	selected: PropTypes.array.isRequired,
	rowsPerPage: PropTypes.number.isRequired,
	page: PropTypes.number.isRequired,
	defaultPerPage: PropTypes.number.isRequired,
	filterText: PropTypes.string.isRequired,
	columnData: PropTypes.array.isRequired,
}

export default withRouter(withStyles(TableStyles)(HorTable))
