// import * as React from 'react';
import { connect } from 'react-redux';

import {
    getAllCompanies,
    getFundingRound,
    getLatestFundingRound,
    getCompanyInvestors,
    getVerticals,

    getClubDetail,
    getEmployee,
    getPublicNewsArticle,
    getDealLinks,
    getDealTimeline,
    getHistoricalPrice,
    toggleWatchList,
    RequestOnboarding,
    getSimilarCompanies,
} from './Action';

import {
    // getPipeline,
    expressInterest,
} from '../../pipeline/Action';

import {
    postReferral,
} from '../pipeline/Action';

import {
    resetSnackBar
} from '../../account/Action';

import {
    createUserMessage
} from '../../common/header/Action';

import { default as CompanyDetail } from './CompanyDetail';
export default connect(
    state => ({
        
        AllCompaniesResponse: state.clubCompany.reducer.AllCompaniesResponse,
        expressInterestError: state.pipeline.reducer.expressInterestError,
        expressInterestResponseMessage: state.pipeline.reducer.expressInterestResponseMessage,
        referralError: state.clubPipeline.reducer.referralError,
        referralResponseMessage: state.clubPipeline.reducer.referralResponseMessage,

        verticals: state.clubCompany.reducer.verticals,
        fundingRounds: state.clubCompany.reducer.fundingRounds,
        finishedCollectFundingRounds: state.clubCompany.reducer.finishedCollectFundingRounds,
        latestFundingRound: state.clubCompany.reducer.latestFundingRound,
        investors: state.clubCompany.reducer.investors,
        clubDetail: state.clubCompany.reducer.clubDetail,
        similar_companies: state.clubCompany.reducer.similar_companies,
        employees: state.clubCompany.reducer.employees,
        publicNewsArticles: state.clubCompany.reducer.publicNewsArticles,
        clubDealLinks: state.clubCompany.reducer.clubDealLinks,
        clubDealTimelines: state.clubCompany.reducer.clubDealTimelines,
        historicalPrice: state.clubCompany.reducer.historicalPrice,
        loaderState: state.root.reducer.loaderState,
        toggleWatchError: state.clubCompany.reducer.toggleWatchError,
        toggleWatchListMessage: state.clubCompany.reducer.toggleWatchListMessage,
        trial_onboarding: state.clubCompany.reducer.trial_onboarding,
        showSnakbar: state.account.reducer.showSnakbar,
        loginUserData: state.login.reducer.loginUserData,
    }),
    dispatch => ({
        getAllCompanies: payload => dispatch(getAllCompanies.request(payload)),
        getFundingRound: payload => dispatch(getFundingRound.request(payload)),
        getLatestFundingRound: payload => dispatch(getLatestFundingRound.request(payload)),
        getCompanyInvestors: payload => dispatch(getCompanyInvestors.request(payload)),
        getVerticals: payload => dispatch(getVerticals.request(payload)),
        // getPipeline: payload => dispatch(getPipeline.request(payload)),
        expressInterest: payload => dispatch(expressInterest.request(payload)),
        getClubDetail: payload => dispatch(getClubDetail.request(payload)),
        getEmployee: payload => dispatch(getEmployee.request(payload)),
        getPublicNewsArticle: payload => dispatch(getPublicNewsArticle.request(payload)),
        getDealLinks: payload => dispatch(getDealLinks.request(payload)),
        getDealTimeline: payload => dispatch(getDealTimeline.request(payload)),
        postReferral: payload => dispatch(postReferral.request(payload)),
        resetSnackBar: () => dispatch(resetSnackBar()),
        getHistoricalPrice: payload => dispatch(getHistoricalPrice.request(payload)),
        toggleWatchList: (payload) => dispatch(toggleWatchList.request(payload)),
        RequestOnboarding: (payload) => dispatch(RequestOnboarding.request(payload)),
        createUserMessage: (payload) => dispatch(createUserMessage.request(payload)),
        getSimilarCompanies: (payload) => dispatch(getSimilarCompanies.request(payload)),
    })
)(CompanyDetail);