import { connect } from 'react-redux';
import { ValidateUser, ClearTempLoader } from '../login/Action';
import { UpdateMyAccess } from '../dashboard/Action';
import { checkMaintenance } from './Action';
import Root from './Root';

export default connect(
    state => ({
        // unauthorized: 
        pendingUserState: state.login.reducer.pendingUserState,
        loginUserData: state.login.reducer.loginUserData,
        loaderState: state.root.reducer.loaderState,
        maintenance: state.root.reducer.maintenance,
        appConfig: state.root.reducer.appConfig,
        agreementAndPasswordSet: state.login.reducer.agreementAndPasswordSet,
    }),
    dispatch => ({ 
        ValidateUser: payload => dispatch(ValidateUser.request(payload)),
        ClearTempLoader: () => dispatch(ClearTempLoader()),
        checkMaintenance: payload => dispatch(checkMaintenance.request(payload)),
        UpdateMyAccess: payload => dispatch(UpdateMyAccess.request(payload)),
    })
)(Root);