// import * as React from 'react';
import { connect } from 'react-redux';

import {
    getPipeline,
    expressInterest,
    headquaterCountry,
    verticals,
    getAdBanner1
} from './Action';

import { default as Pipeline } from './Pipeline';
export default connect(
    state => ({
        pipelineResponse: state.pipeline.reducer.pipelineResponse,
        expressInterestError: state.pipeline.reducer.expressInterestError,
        expressInterestResponseMessage: state.pipeline.reducer.expressInterestResponseMessage,
        headquarterCountryResponse: state.pipeline.reducer.headquarterCountryResponse,
        verticalsResponse: state.pipeline.reducer.verticalsResponse,
        adBanner1Response: state.pipeline.reducer.adBanner1Response,
        loaderState: state.root.reducer.loaderState
    }),
    dispatch => ({
        getPipeline: payload => dispatch(getPipeline.request(payload)),
        expressInterest: payload => dispatch(expressInterest.request(payload)),
        headquaterCountry: payload => dispatch(headquaterCountry.request(payload)),
        verticals: payload => dispatch(verticals.request(payload)),
        getAdBanner1: () => dispatch(getAdBanner1.request())
    })
)(Pipeline);
