
import {
    roundNumber,
} from '../../../../../utils/util';

export const getOption = (state) => {
    const {
        consolidated_indices,
        price_index,
        chart_type,
    } = state;

    // console.log( consolidated_indices );

    const date = consolidated_indices.map(item => item.date);

    const option = {
        legend: {
            show: true
        },
        dataZoom: [
            {
                type: 'slider'
            }
        ],
        xAxis: {
            type: 'category',
            boundaryGap: false,
            data: date,
            nameTextStyle: {
                fontFamily: 'Roboto',
                fontSize: 12
            },
            axisLabel: {
                fontFamily: 'Roboto',
                fontSize: 12,
                rich: {
                    fontFamily: 'Roboto',
                    fontSize: 12
                }
            }
        },
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'cross'
            }
        }
    }

    const series = [];
    const legend_data = [];

    if( chart_type.type == "overall" ){

        const offers = consolidated_indices.map(item => roundNumber(item.offer));
        const bids = consolidated_indices.map(item => roundNumber(item.bid));
        
        series.push({
            name: 'Ave. Offer',
            type: 'line',
            data: offers,
            lineStyle: {
                color: 'rgba(252,163,16,0.8)'
            },
            itemStyle: {
                color: 'rgba(252,163,16,0.8)'
            }
        });

        series.push({
            name: 'Ave. Bid',
            type: 'line',
            data: bids,
            lineStyle: {
                color: 'rgba(69,97,168,0.8)'
            },
            itemStyle: {
                color: 'rgba(69,97,168,0.8)'
            }
        });

        legend_data.push({
            name: 'Ave. Bid',
            icon: 'circle',
            itemStyle: {
                color: 'rgba(69,97,168,0.8)'
            },
            textStyle: {
                fontFamily: "'Roboto', sans-serif",
                color: 'rgba(69,97,168,0.8)'
            }
        });

        legend_data.push({
            name: 'Ave. Offer',
            icon: 'circle',
            itemStyle: {
                color: 'rgba(252,163,16,0.8)'
            },
            textStyle: {
                fontFamily: "'Roboto', sans-serif",
                color: 'rgba(252,163,16,0.8)'
            }
        });

        option['yAxis'] = {
            type: 'value',
            nameTextStyle: {
                fontFamily: 'Roboto',
                fontSize: 12
            },
            axisLabel: {
                formatter: '{value}',
                fontFamily: 'Roboto',
                fontSize: 12,
                rich: {
                    fontFamily: 'Roboto',
                    fontSize: 12
                }
            },
            axisPointer: {
                snap: true
            }
        }

    }

    option['series'] = series;
    option['legend']['data'] = legend_data;

    // console.log( option );

    return option['xAxis'] && option['yAxis'] && option['series'] ? option: {};
}