import {
    roundNumber,
} from './util';

export function get_first_price(payload){
    const first_price = {}
    const keys = payload.length ? Object.keys(payload[0]) : {};

    payload.forEach(item => {
        keys.forEach(key => {
            if( item[key] && !first_price[key] ){
                first_price[key] = item[key];
            }
        })
    })

    return first_price;
}

export function handle_missing_data(index, check_payload, historical_pricing, payload, color, key){
    // console.log( key );
    let { last_actual_data, null_index, last_data_checkpoint } = check_payload;
    const current_data_point = historical_pricing[index][key];
    const previous_data_point = index ? historical_pricing[index-1][key] : 0;

    if( index && 
        ( current_data_point && null_index > -1 ) ||
        (
            index==historical_pricing.length-1 &&
            !current_data_point &&
            null_index > -1
        )
        ){
        const difference = current_data_point ? 
                                current_data_point - last_actual_data :
                                0;
        
        const step = difference ? roundNumber(difference / ( index - null_index )) : difference;
        let to_add = last_actual_data;
        
        if( index == historical_pricing.length - 1 ){
            for( let ii=null_index; ii <= index; ii++ ){
                to_add = to_add+step;   
                payload[key][ii] = roundNumber(to_add);
            }
        } else {
            for( let ii=null_index; ii < index; ii++ ){
                to_add = to_add+step;   
                payload[key][ii] = roundNumber(to_add);
            }
        }
        

        null_index = -1;
    }

    if( current_data_point ){
        last_actual_data = current_data_point;
    } else if ( null_index == -1 ){
        null_index = index;
    }

    if( index && 
        ( 
            ( current_data_point && !previous_data_point ) ||
            ( !current_data_point && previous_data_point ) ||
            ( index == historical_pricing.length - 1 )
        ) 
        ){
            const visual_map_color = previous_data_point ? `rgba(${color},0.8)` : `rgba(${color},0.2)`;

            const visual_map_payload = {
                color: visual_map_color,
                gte: last_data_checkpoint,
                lt: index,
                symbol: 'none',
            }
            payload[`${key}_visual_map`].push( visual_map_payload );
            last_data_checkpoint = index;
    } 

    return {
        payload, 
        check_payload: { last_actual_data, null_index, last_data_checkpoint }
    }
}

export function add_percent_change(list_items, base_items, key_pairs){
    return list_items.map(item => {
        for( let key in key_pairs ){
            item[key_pairs[key]] = item[key] ? roundNumber( (item[key] - base_items[key]) / base_items[key] * 100 ) : 0
        }
        return item;
    })
}

export function reference_price_change(names, pricings){
    const first_val = {}
    const ref_payload = {}
    
    names.forEach(name => {
        if( !first_val[name] ) first_val[name] = 0;
        if( !ref_payload[name] ) ref_payload[name] = [];
        pricings.every(pricing => {
            if(pricing[name]) {
                first_val[name] = pricing[name];
                return false
            } else {
                return true;
            }
        })
    });
    
    pricings.forEach(pricing => {
        names.forEach(name => {
            ref_payload[name].push(pricing[name] ? roundNumber( (pricing[name] - first_val[name]) / first_val[name] * 100 ) : null);
        })
    });

    return ref_payload;
}