import React from 'react';
import ReactECharts from 'echarts-for-react';

import {
    Grid,
    Checkbox,
    FormControlLabel,
    Typography,
    withStyles,
} from '@material-ui/core';

import {
    computeConsolidatedIndex
} from '../../../../../utils/PriceIndex';

import { getOption } from './ChartConfig';

import { CommonStyles } from '../../../../styles';

const useStyles = theme => ({
    FormControlLabel: CommonStyles.FormControlLabel,
    Checkbox: CommonStyles.Checkbox
});

function getCoveredVerticals(selected_company_ids, verticals){
    const vertical_keys = Object.keys(verticals);
    const selected_verticals = [];

    vertical_keys.forEach(key => {
        const verticals_by_company = verticals[key];
        const missing_company_ids = verticals_by_company.filter(company_id => selected_company_ids.indexOf(company_id) == -1);

        if( !missing_company_ids.length ) selected_verticals.push(key);
    });

    return selected_verticals;
}

function getPriceIndex(selected_company_ids, price_index){
    let new_price_index = {}
    if( selected_company_ids.length ){
        selected_company_ids.forEach(company_id => {
            new_price_index[company_id] = price_index[company_id]
        })
    } else {
        new_price_index = price_index
    }
    return new_price_index;
}

function findVerticalWithSameCompanyGroup(vertical_name, verticals){
    let vertical_keys = Object.keys(verticals);
    vertical_keys = vertical_keys.filter(key => key != vertical_name);
    const vert_with_same_comp_group = [];
    vertical_keys.forEach(key => {
        if(verticals[key].equals(verticals[vertical_name])){
            vert_with_same_comp_group.push(key);
        }
    });
    return vert_with_same_comp_group;
}

function rearrangeVerticalsForDisplay(verticals){
    let vertical_keys = Object.keys(verticals);
    const results = [];
    let result_keys = [];
    vertical_keys.forEach(key => {
        if( result_keys.indexOf(key) == -1 ) {
            const vertical_cluster = [key].concat( findVerticalWithSameCompanyGroup(key, verticals) )
            results.push(vertical_cluster);
            result_keys = result_keys.concat(vertical_cluster);
        }
    });
    return results;
}

class PriceChart extends React.Component{
    state = {
        price_index: {},
        selected_company_ids: [],
        consolidated_indices: [],
        verticals: {},
        selected_verticals: [],
        chart_type: {
            type: 'overall',
            company_id: undefined
        }
    }

    constructor(props){
        super(props);
    }

    static getDerivedStateFromProps(nextProps, prevState){
        const {
            price_index,
            verticals,
            selected_company_ids,
            company,
        } = nextProps;

        const state_price_index = Object.keys( prevState.price_index );
        const props_price_index = Object.keys( price_index );
        const state_vertical_index = Object.keys( prevState.verticals );
        const props_vertical_index = verticals.by_cat ? Object.keys( verticals.by_cat ) : [];

        if(
            !props_price_index.equals(state_price_index) &&
            !props_vertical_index.equals(state_vertical_index) 
        ){
            return {
                price_index: price_index,
                selected_company_ids: props_price_index,
                consolidated_indices: computeConsolidatedIndex(price_index, company),
                verticals: verticals.by_cat ? verticals.by_cat : {},
            }
        } else if( !props_price_index.equals(state_price_index) ){
            return {
                price_index: price_index,
                selected_company_ids: props_price_index,
                consolidated_indices: computeConsolidatedIndex(price_index, company)
            }
        } else if ( !props_vertical_index.equals(state_vertical_index) ) {
            return {
                verticals: verticals.by_cat ? verticals.by_cat : {}
            }
        } else if ( !selected_company_ids.equals(prevState.selected_company_ids) && state_vertical_index.length ) {
            // console.log( selected_company_ids );
            return {
                selected_verticals: getCoveredVerticals(selected_company_ids, prevState.verticals),
                selected_company_ids: selected_company_ids,
                consolidated_indices: computeConsolidatedIndex( getPriceIndex(selected_company_ids, prevState.price_index), company )
            }
        } else {
            return null;
        }
    }

    handleVerticalSelection(vertical, evt){
        
        const { 
            selected_verticals,
            verticals,
            price_index,
            // selected_company_ids,
            // chart_type,
        } = this.state;

        const { handleSelectCompany, } = this.props;

        let new_selected_verticals = selected_verticals.filter(item => item != vertical);
        
        if( new_selected_verticals.equals(selected_verticals) ){
            new_selected_verticals.push(vertical);
        }
             
        let company_ids = [];
        new_selected_verticals.forEach(key => {
            company_ids = company_ids.concat( verticals[key] );
        });

        // Remove duplicate value from array
        company_ids = company_ids.filter((val, index, self) => self.indexOf(val) === index)

        if( handleSelectCompany ){
            handleSelectCompany(evt, company_ids);
        } else {
            let new_price_index = {}
            if( company_ids.length ){
                company_ids.forEach(company_id => {
                    new_price_index[company_id] = price_index[company_id]
                })
            } else {
                new_price_index = price_index
            }

            this.setState({
                selected_verticals: new_selected_verticals,
                selected_company_ids: company_ids,
                consolidated_indices: computeConsolidatedIndex(new_price_index)
            });
        }
        
    }

    render(){
        const { 
            verticals,
            selected_verticals,
        } = this.state;

        const { classes } = this.props;

        const vertical_keys = rearrangeVerticalsForDisplay(verticals);
        
        return (
            <section className="price-chart-section orderbook">
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        {
                            vertical_keys.map((key, index) => {
                                return (
                                    <FormControlLabel
                                        className={classes.FormControlLabel}
                                        key={index}
                                        control={
                                            <Checkbox 
                                                className={classes.Checkbox}
                                                checked={selected_verticals.indexOf(key[0]) > -1}
                                                onChange={this.handleVerticalSelection.bind(this, key[0])}
                                                />
                                        }
                                        label={
                                            <Typography className={classes.FormControlLabel}>{key.join(", ")}</Typography>
                                        }
                                        />
                                )
                            })
                        }
                    </Grid>
                    <Grid item xs={12}>
                        <div className="chart-wrapper">
                            <ReactECharts
                                option={getOption(this.state)}
                                style={{ height: 400 }}
                                notMerge={true}
                                />
                        </div>
                    </Grid>
                </Grid>
            </section>
        )       
    }
}

export default withStyles(useStyles)(PriceChart);