import React, { Fragment } from 'react';

import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Grid,
    Typography,
} from '@material-ui/core';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

import { 
    HeaderTableCell, 
    StyledTableRow, 
    recentCellClasses,
    TighterTableCell,
    StyledHoverTableRow,
    TighterClickableTableCell,
    TighterHeaderTableCell,
} from '../../common/Table';

import {
    numberWithCommas,
    convertSizeLabel,
    getDigitPadding,
} from '../../../../utils/util';

import { content_placeholders } from '../../../../config';

const useStyles = makeStyles((theme) => ({
    container: {
        minHeight: "500",
        // maxHeight: "100%",
    }
}));

export default (props) => {
    const classes = useStyles();
    const rcc = recentCellClasses();

    const { loginUserData: { activeAccessRight: { access_right: { permission } } } } = props;

    return (
        <Fragment>
            <Typography variant="h6" className="orderbook-paper-title">Opportunity Highlights</Typography>
            <p className="elaborate-text">Data displayed represents live order (offer or bid) prices of the top 6 most active companies in the secondary market. All data is sourced from Gateway network.</p>
            <TableContainer component={Paper} className={classes.container}>
                <Table stickyHeader size="small" aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TighterHeaderTableCell>Size</TighterHeaderTableCell>
                            <TighterHeaderTableCell>Bid (PPS)</TighterHeaderTableCell>
                            <TighterHeaderTableCell className="company-name">Name</TighterHeaderTableCell>
                            <TighterHeaderTableCell>Offer (PPS)</TighterHeaderTableCell>
                            <TighterHeaderTableCell>Size</TighterHeaderTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            props.opportunity_highlight.resultList.map((row, index) => {
                                if( index < 6 ){
                                    
                                    const bid_padding = getDigitPadding(row.highest_bid, props.longest_digit);
                                    const offer_padding = getDigitPadding(row.lowest_ask, props.longest_digit);
                                    return (
                                        <React.Fragment key={index}>
                                            {
                                                permission == content_placeholders.user_type.orderbook_user ? 
                                                    <StyledHoverTableRow key={index}>
                                                        <TighterClickableTableCell 
                                                            onClick={(evt) => props.handleGoToCompanyDetail(evt, row.company_id)}
                                                            className='size-column'>{row.bid_size ? <span className={row.bid_size.indexOf('<') == -1 ? 'left-gap' : ''}>{convertSizeLabel(row.bid_size)}</span> : '-'}</TighterClickableTableCell>
                                                        <TighterClickableTableCell 
                                                            onClick={(evt) => props.handleGoToCompanyDetail(evt, row.company_id)}
                                                            className={ rcc.dateClassName(row.highest_bid_date, row.firm_bid) }>
                                                                {bid_padding}
                                                            {row.highest_bid ? numberWithCommas(row.highest_bid) : '-'}</TighterClickableTableCell>
                                                        <TighterClickableTableCell 
                                                            onClick={(evt) => props.handleGoToCompanyDetail(evt, row.company_id)}
                                                            className="company-name">{row.company_name}</TighterClickableTableCell>
                                                        <TighterClickableTableCell 
                                                            onClick={(evt) => props.handleGoToCompanyDetail(evt, row.company_id)}
                                                            className={ rcc.dateClassName(row.lowest_ask_date, row.firm_ask) }>
                                                                {offer_padding}
                                                            {row.lowest_ask ? numberWithCommas(row.lowest_ask) : '-'}</TighterClickableTableCell>
                                                        <TighterClickableTableCell 
                                                            onClick={(evt) => props.handleGoToCompanyDetail(evt, row.company_id)}
                                                            className='size-column'>{row.ask_size ? <span className={row.ask_size.indexOf('<') == -1 ? 'left-gap' : ''}>{convertSizeLabel(row.ask_size)}</span> : '-'}</TighterClickableTableCell>
                                                    </StyledHoverTableRow> : 
                                                    <StyledTableRow key={index}>
                                                        <TighterTableCell className='size-column'>{row.bid_size}</TighterTableCell>
                                                        <TighterTableCell className={ rcc.dateClassName(row.highest_bid_date, row.firm_bid) }>
                                                            {row.highest_bid ? numberWithCommas(row.highest_bid) : '-'}
                                                        </TighterTableCell>
                                                        <TighterTableCell className="company-name">{row.company_name}</TighterTableCell>
                                                        <TighterTableCell className={ rcc.dateClassName(row.lowest_ask_date, row.firm_ask) } >
                                                            {row.lowest_ask ? numberWithCommas(row.lowest_ask) : '-'}
                                                        </TighterTableCell>
                                                        <TighterTableCell className='size-column'>{row.ask_size}</TighterTableCell>
                                                    </StyledTableRow>
                                            } 
                                        </React.Fragment>
                                    )
                                } else {
                                    return (<Fragment key={index}></Fragment>)
                                }
                                
                            })
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </Fragment>
        
    )
}