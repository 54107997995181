import { CreateApiAction as _createApiAction } from '../../../utils'

const key = 'LOGOUT';
const CreateApiAction = _createApiAction(key);

export const logoutWithEmail = CreateApiAction('LOGOUT_WITH_EMAIL');
export const createUserMessage = CreateApiAction('CREATE_USER_MESSAGE');
export const getNotifications = CreateApiAction('GET_NOTIFICATIONS');
export const markNotificationAllRead = CreateApiAction('MARK_NOTIFICATION_ALL_READ');
export const markNotificationAllUnRead = CreateApiAction('MARK_NOTIFICATION_ALL_UNREAD');
export const updateNotificationPreferences = CreateApiAction('UPDATE_NOTIFICATION_PREFERENCES');


