import React from 'react';

import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    makeStyles,
    Typography,
    Card,
    Grid,
} from '@material-ui/core';

import { 
    HeaderTableCell, 
    StyledTableRow, 
    recentCellClasses 
} from '../../../orderbook/common/Table';

import {
    MONTH_ARR,
} from '../../../../utils/util';

const useStyles = makeStyles((theme) => ({
    container: {
        // minHeight: 50,
        // maxHeight: 50,
    },
    SubTitle: {
        fontSize: 12,
        // textAlign: 'center',
        display: 'block',
    },
    Card: {
        padding: 16,
        marginTop: 16,
        marginBottom: 16,
    },
    ul: {
        padding: 0
    },
    DateCell: {
        width: '10% !important'
    },
    LabelCell: {
        width: '20% !important'
    }
}));

export default (props) => {
    const classes = useStyles();

    return (
        <React.Fragment>
            {/* <Typography variant="h6">Timeline</Typography> */}
            <TableContainer component={Paper} className={classes.container}>
                <Table stickyHeader size="small" aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <HeaderTableCell className={classes.DateCell}>Date</HeaderTableCell>
                            <HeaderTableCell className={classes.LabelCell}>Stage</HeaderTableCell>
                            <HeaderTableCell>Info / Documentation</HeaderTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            props.dealTimelines.map((item, index) => {
                                const date = new Date(item.timeline_date);
                                let payload = {}
                                try{
                                    payload = JSON.parse(item.timeline_value);
                                } catch(e){
                                    console.log( e );
                                }
                                // console.log( payload );
                                return (
                                    <StyledTableRow key={index}>
                                        <TableCell className={classes.DateCell}>
                                            {
                                                date.getDate() + ' ' + MONTH_ARR[date.getMonth()]
                                            }
                                        </TableCell>
                                        <TableCell className={classes.LabelCell}>
                                            {
                                                payload.title
                                            }
                                        </TableCell>
                                        <TableCell>
                                            {
                                                payload.content && Array.isArray(payload.content) ?
                                                    <ul className={classes.ul}>
                                                        {
                                                            payload.content.map((c_item, c_index) => {
                                                                return (
                                                                    <li key={c_index}>{c_item}</li>
                                                                )
                                                            })
                                                        }
                                                    </ul> : ''
                                            }
                                        </TableCell>
                                    </StyledTableRow>
                                )
                            })
                        }
                    </TableBody>
                </Table>    
                
            </TableContainer>
        </React.Fragment>
    )
}