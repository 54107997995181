import { connect } from 'react-redux';
import { default as Watchlist } from './Watchlist';

import {
    CollapseSidebar,
} from '../../root/Action';

export default connect(
    state => ({
        loginUserData: state.login.reducer.loginUserData,
        appConfig: state.root.reducer.appConfig,
        collapseSidebar: state.root.reducer.collapseSidebar,
    }),
    dispatch => ({
        CollapseSidebar: (payload) => dispatch(CollapseSidebar(payload)),
    })
)(Watchlist);