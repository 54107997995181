import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import {
    Grid,
    Paper,
    Card,
    Tooltip,
} from '@material-ui/core';

import {
    Star,
    Equalizer,
} from '@material-ui/icons';

import {
    feature_flags,
} from '../../../../../config';

import {
    showBlurred,
    numberWithCommas,
    getImpliedVal,
    displayVal,
} from '../../../../../utils/util';

import {
    default as PriceChart,
} from './PriceChart';

export default (props) => {

    const { 
        appConfig, 
        loginUserData, 
        watchlistGroupItem,
        companyDetailType,
        graph_payload,
        historical_pricing,
        funding_rounds,
        shortenedMode,
    } = props;
    const { email } = loginUserData;

    const multi_watchlist_available = feature_flags.feature_is_available(
        appConfig,
        feature_flags.watchlist_flags.Watchlist.flag_name,
        feature_flags.watchlist_flags.Watchlist.attributes.multiple_watchlist_group,
        email
    )

    const watchlist_graph_available = feature_flags.feature_is_available(
        appConfig,
        feature_flags.watchlist_flags.Watchlist.flag_name,
        feature_flags.watchlist_flags.Watchlist.attributes.watchlist_graph,
        email
    )

    const paper_style = { padding: 16, width: '100%' }
    const header_title_style = {
        fontFamily: "'Roboto', sans-serif",
        fontSize: '12px',
        lineHeight: '14px',
        fontWeight: '700',
        color: 'white',
    }
    const item_grid_style = {
        display: 'flex',
        alignItems: 'center',
        cursor: 'default',
    }
    const item_sub_title_style = {
        fontFamily: "'Roboto', sans-serif",
        fontSize: '12px',
        lineHeight: '14px',
        fontWeight: '400',
        color: 'black',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        width: '100%',
        display: 'block',
    }

    const filtered_companies = watchlistGroupItem && watchlistGroupItem.company ? watchlistGroupItem.company.filter(item => item.highest_bid || item.lowest_ask) : []
    
    return (

        <div style={{ marginTop: 16, padding: 8 }}>
                        
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Grid container spacing={2} style={{ background: '#303f9f', borderRadius: '4px' }}>
                        <Grid item xs={!shortenedMode ? 2 : 2}>

                        </Grid>
                        {
                            !shortenedMode ? 
                                <Grid item xs={!shortenedMode ? 2 : 2}>
                                    <span style={header_title_style}>Company</span>
                                </Grid> : ''
                        }
                        
                        {/* <Grid item xs={!shortenedMode ? 1 : 2}>
                            <span style={header_title_style}>Type</span>
                        </Grid>
                        <Grid item xs={2}>
                            <span style={header_title_style}>Price / Size</span>
                        </Grid> */}
                        <React.Fragment>
                            <Grid item xs={!shortenedMode ? 2 : 3}>
                                <span style={header_title_style}>
                                    Highest Bid (Implied Val.)
                                </span>
                            </Grid>
                            <Grid item xs={!shortenedMode ? 2 : 3}>
                                <span style={header_title_style}>
                                    Lowest Offer (Implied Val.)
                                </span>
                            </Grid>
                        </React.Fragment>
                        <Grid item xs={!shortenedMode ? 2 : 3}>
                            <span style={header_title_style}>
                                Last Funding Round (Valuation)
                            </span>
                        </Grid>

                        {
                            !shortenedMode ? <Grid item xs={1}></Grid> : ''
                        }
                        
                    </Grid>
                </Grid>

                {
                    filtered_companies.length > 0 ?
                    filtered_companies.map((item, index) => {

                            if( index < 10 ){
                                // console.log( item );
                                let type_label = '';
                                if (item.my_bid_price || item.my_bid_valuation) type_label += 'Buy';
                                if (item.my_ask_price || item.my_ask_valuation) type_label += type_label.trim() ? ' / Sell' : 'Sell';
                                if (!type_label.trim()) type_label = '-';

                                let target_label = '';
                                if (type_label.indexOf('Buy') > -1) {
                                    target_label += item.my_bid_price ?
                                        `$${numberWithCommas(item.my_bid_price)}` :
                                        item.my_bid_valuation ?
                                            displayVal(item.my_bid_valuation) : '';
                                    if (item.my_bid_size) target_label += `/ ${numberWithCommas(item.my_bid_size)}`;
                                } else if (type_label.indexOf('Sell') > -1) {
                                    target_label += item.my_ask_price ?
                                        `$${numberWithCommas(item.my_ask_price)}` :
                                        item.my_ask_valuation ?
                                            displayVal(item.my_ask_valuation) : '';
                                    if (item.my_ask_size) target_label += `/ ${numberWithCommas(item.my_ask_size)}`;
                                }
                                if (!target_label.trim()) target_label = '-';

                                let highest_bid_label = '-';
                                if (item.highest_bid || item.highest_bid_valuation) {
                                    highest_bid_label = item.highest_bid ? `$${numberWithCommas(item.highest_bid)}` : '-';
                                    if (item.selected_price && item.selected_post_money_valuation && item.highest_bid)
                                        highest_bid_label += ` (${getImpliedVal(item.highest_bid, item.selected_price, item.selected_post_money_valuation)})`;
                                    else if (item.highest_bid_valuation)
                                        highest_bid_label += ` (${displayVal(item.highest_bid_valuation)})`;
                                }


                                let lowest_ask_label = '-';
                                if (item.lowest_ask || item.lowest_ask_valuation) {
                                    lowest_ask_label = item.lowest_ask ? `$${numberWithCommas(item.lowest_ask)}` : '-';
                                    if (item.selected_price && item.selected_post_money_valuation && item.lowest_ask)
                                        lowest_ask_label += ` (${getImpliedVal(item.lowest_ask, item.selected_price, item.selected_post_money_valuation)})`;
                                    else if (item.lowest_ask_valuation)
                                        lowest_ask_label += ` (${displayVal(item.lowest_ask_valuation)})`;
                                }

                                let lfr_label = '-';
                                if (item.selected_price || item.selected_post_money_valuation) {
                                    lfr_label = '';
                                    if (item.selected_price) lfr_label += `$${numberWithCommas(item.selected_price)}`;
                                    if (item.selected_post_money_valuation) lfr_label += ` (${displayVal(item.selected_post_money_valuation)})`;
                                }


                                return (
                                    
                                        <Grid item xs={12} key={index}>
                                            <Card
                                                style={{ cursor: 'pointer', padding: 16, paddingTop: 8, paddingBottom: 8, marginLeft: '-8px', marginRight: '-8px' }}>

                                                <Grid container spacing={2}>
                                                    <Grid item xs={!shortenedMode ? 2 : 2} style={item_grid_style}>
                                                        {
                                                            !showBlurred(item.logo_url) ? <Link to={{
                                                                        pathname: `/${companyDetailType}/company-detail/${item.id}`,
                                                                    }}>
                                                                        <img src={item.logo_url} style={{ maxWidth: 50 }} />
                                                                </Link> : ''
                                                        }
                                                    </Grid>
                                                    {
                                                        !shortenedMode ? 
                                                            <Grid item xs={!shortenedMode ? 2 : 2} style={item_grid_style}>
                                                                <Link to={{
                                                                        pathname: `/${companyDetailType}/company-detail/${item.id}`,
                                                                    }} style={{ display: 'block', width: '100%' }}>
                                                                        <span style={item_sub_title_style}>{item.name}</span>
                                                                </Link>
                                                            </Grid> : ''
                                                    }
                                                    
                                                    {/* <Grid item xs={!shortenedMode ? 1 : 2} style={item_grid_style}>
                                                        <span style={item_sub_title_style}>{type_label}</span>
                                                    </Grid>
                                                    <Grid item xs={2} style={item_grid_style}>
                                                        <span style={item_sub_title_style}>{target_label}</span>
                                                    </Grid> */}
                                                    
                                                    <React.Fragment>
                                                        <Grid item xs={!shortenedMode ? 2 : 3} style={item_grid_style}>
                                                            <span style={item_sub_title_style}>{highest_bid_label}</span>
                                                        </Grid>
                                                        <Grid item xs={!shortenedMode ? 2 : 3} style={item_grid_style}>
                                                            <span style={item_sub_title_style}>{lowest_ask_label}</span>
                                                        </Grid>
                                                    </React.Fragment> 
                                                    
                                                    <Grid item xs={!shortenedMode ? 2 : 3} style={item_grid_style}>
                                                        <span style={item_sub_title_style}>{lfr_label}</span>
                                                    </Grid>
                                                    {
                                                        !shortenedMode ? 
                                                            <Grid item xs={1} style={item_grid_style}>
                                                                <Tooltip title="Remove From Watchlist">
                                                                    <Star style={{ color: "#FFC50D" }}
                                                                        onClick={(event) => props.handleCompanyDeleteDialogOpen(event, item.id, watchlistGroupItem.id)} />
                                                                </Tooltip>
                                                                {
                                                                    watchlist_graph_available ? <Tooltip title="Show graph">
                                                                        <Equalizer style={{}}
                                                                            onClick={(event) => props.handleShowCompGraph(event, item.id)}
                                                                            />
                                                                    </Tooltip> : ''
                                                                }
                                                            </Grid> : ''
                                                    }
                                                    
                                                </Grid>
                                                
                                                {
                                                    graph_payload && graph_payload.company_id == item.id ?
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={12}>
                                                                <PriceChart
                                                                    {...{
                                                                        ...graph_payload,
                                                                        historical_pricing,
                                                                        funding_rounds,
                                                                        companyDetailType,
                                                                        item,
                                                                    }}
                                                                    />
                                                            </Grid>
                                                        </Grid> : ''
                                                }
                                                
                                            </Card>
                                        </Grid>
                                    
                                )
                            } else if ( index == 10 ){
                                return (
                                    <Grid item xs={12} key={index}>
                                        <Card
                                            style={{ cursor: 'pointer', padding: 16, paddingTop: 8, paddingBottom: 8, marginLeft: '-8px', marginRight: '-8px' }}>

                                            <Grid container spacing={2}>
                                                <Grid item xs={12} style={item_grid_style}>
                                                    <Link style={{...item_sub_title_style, textAlign: 'center'}} to={`/orderbook/watchlist`}>More</Link>
                                                </Grid>
                                            </Grid>
                                        </Card>
                                    </Grid>
                                )
                                
                            }
                            
                        }) : 
                            <Grid item xs={12}>
                                <span style={{fontSize: 12}}>No watchlist item</span>
                            </Grid>
                }
            </Grid>
        </div>
        
        
    )
}