

function extract_key(qs){
    const qs_key = Object.keys(qs);

    return qs_key.filter(key => key.indexOf('utm_') == 0);
}

module.exports = {
    extract_key,
}