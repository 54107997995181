import React, { Component } from 'react';

import {
    Typography,
    Grid,
    Divider,
    Box,
    withStyles,
    TextField,
    CircularProgress,
    FormLabel,
    Button,
    IconButton,
} from '@material-ui/core';

import {
    ToggleButton,
    ToggleButtonGroup,
    Autocomplete,
} from '@material-ui/lab';

import AddIcon from '@material-ui/icons/Add';

import {
    sendGAEvent,
} from '../../../../../utils/ga';

import {
    ReScaleForDeviceScale,
} from '../../../../../utils/util';

import {
    global150BodyFont,
    globalBodyFont,
} from '../../../../styles';

import {
    FormStyle,
} from '../../../common/FormStyle';

class PortfolioControl extends Component{
    state = {
        company_not_selectable: false,
        options: [],
        open: false,
        loading: false,
        portfolio_payload: [
            {
                price: undefined,
                date: undefined,
                share_class: undefined,
                noOfShares: undefined,
                share_class_text: undefined,
            }
        ],
        selected_company: {
            company_id: undefined,
            company_name: ''
        },
        share_class_options: [
            'Common',
            'Preferred',
            'Others',
        ]
    }

    constructor(props){
        super(props);
    }

    handleOpen( open, evt ) {
        this.setState({ open: open });
    }

    handleSearchCompany = (evt) => {
        if( evt ){
            const { target: { name, value } } = evt;
            const { getSearchCompany } = this.props;
            if( value && value.trim() ){
                sendGAEvent({
                    type: 'orderbook_portfoliocontrol_search_company',
                    data: {
                        keyword: value
                    }
                });
                getSearchCompany({ qs: { search_string: value } });
            }
        }
    }

    handleSelectCompany = (evt, value) => {
        if( value && value.company_id && value.company_name ){
            sendGAEvent({
                type: 'orderbook_portfoliocontrol_select_company',
                data: {
                    company_id: value.company_id
                }
            });
            this.setState({ selected_company: value });
        } else if ( !value ){
            this.setState({ selected_company: { company_id: 0, company_name: '' } });
        }
    }

    handleAddPortfolioItem = (evt) => {
        const { portfolio_payload } = this.state;

        portfolio_payload.push({
            price: undefined,
            date: undefined,
            share_class: undefined,
            noOfShares: undefined,
            share_class_text: undefined,
        });

        this.setState({ portfolio_payload: portfolio_payload });
    }

    handleInputChange = (evt, index, key, newValue) => {
        const { target } = evt;

        const value_to_apply = newValue ? newValue: target && target.value ? target.value : '';

        const { portfolio_payload } = this.state;

        if( portfolio_payload[index] ){
            portfolio_payload[index][key] = value_to_apply;
        }

        this.setState({ portfolio_payload: portfolio_payload });
    }

    handleSubmitPortfolio = (evt) => {
        const { portfolio_payload, selected_company } = this.state;
        const { postSubmitPortfolio } = this.props;

        let valid_portfolio_payload = portfolio_payload.filter(item => item.date && item.price && item.noOfShares);
        if( valid_portfolio_payload.length ){
            valid_portfolio_payload = valid_portfolio_payload.map(item => {
                item.company_id = selected_company.company_id;
                return item;
            });
            postSubmitPortfolio(valid_portfolio_payload);
        }
    }

    static getDerivedStateFromProps(nextProps, prevState){
        const {
            searchCompanyResponse,
            search_company_loading,
        } = nextProps;

        if( search_company_loading && !prevState.loading ){
            return { loading: true }
        } else if ( !search_company_loading && prevState.loading ){
            return {
                options: searchCompanyResponse.resultList ? 
                            searchCompanyResponse.resultList :
                            [],
                loading: false
            }
        } else {
            return null;
        }
    }

    render(){

        const {
            company_not_selectable,
            open,
            options,
            search_company_loading,
            selected_company,
            share_class_options,
            portfolio_payload,
        } = this.state;

        const {
            classes,
        } = this.props;
        
        return (
            <section className={classes.Box}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Box>
                            <Typography variant="h6">Add / Edit My Portfolio</Typography>
                        </Box>
                    </Grid>

                    {
                        !company_not_selectable ? <Grid item xs={12}>
                            <Autocomplete
                                id="company-name-input"
                                open={open}
                                onOpen={this.handleOpen.bind(this, true)}
                                onClose={this.handleOpen.bind(this, false)}
                                onInputChange={this.handleSearchCompany}
                                onChange={this.handleSelectCompany}
                                getOptionSelected={(option, value) => option.company_name === value.company_name}
                                getOptionLabel={(option) => option.company_name}
                                options={options}
                                loading={search_company_loading}
                                value={selected_company}
                                placeholder="Company Name"
                                size="small"
                                renderInput={(params) => {
                                    return (
                                        <TextField
                                            {...params}
                                            placeholder="Company Name"
                                            className={classes.TextField}
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            value={selected_company.company_name}
                                            variant="outlined"
                                            InputProps={{
                                                ...params.InputProps,
                                                endAdornment: (
                                                    <React.Fragment>
                                                        {search_company_loading ? <CircularProgress color="inherit" size={20} /> : null}
                                                        {params.InputProps.endAdcornment}
                                                    </React.Fragment>
                                                )
                                            }}
                                            />
                                    )
                                }}
                                />
                        </Grid> : ''
                    }

                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Divider />
                            </Grid>

                            <Grid item xs={12}>
                                <Typography variant="h6">Portfolio Detail:</Typography>
                            </Grid>

                            {
                                portfolio_payload.map((item, index) => {
                                    return (
                                        <React.Fragment key={index}>
                                            <Grid item xs={12}>
                                                <FormLabel
                                                    component="legend"
                                                    className={classes.FormLabel}>Date</FormLabel>
                                                <TextField
                                                    id="entry-date"
                                                    type="date"
                                                    placeholder="Entry Date"
                                                    variant="outlined"
                                                    size="small"
                                                    className={classes.TextField}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    onChange={(evt) => this.handleInputChange(evt, index, 'date')}
                                                    />
                                            </Grid>

                                            <Grid item xs={12}>
                                                <FormLabel
                                                    component="legend"
                                                    className={classes.FormLabel}>Price</FormLabel>
                                                <TextField
                                                    id="entry-price"
                                                    placeholder="Entry Price"
                                                    variant="outlined"
                                                    size="small"
                                                    className={classes.TextField}
                                                    InputLabelProps={{
                                                        shrink: true
                                                    }}
                                                    onChange={(evt) => this.handleInputChange(evt, index, 'price')}
                                                    />
                                            </Grid>

                                            <Grid item xs={12}>
                                                <FormLabel
                                                    component="legend"
                                                    className={classes.FormLabel}>No. of shares</FormLabel>
                                                <TextField
                                                    id="noOfShares"
                                                    placeholder="No. of shares"
                                                    variant="outlined"
                                                    size="small"
                                                    className={classes.TextField}
                                                    InputLabelProps={{
                                                        shrink: true
                                                    }}
                                                    onChange={(evt) => this.handleInputChange(evt, index, 'noOfShares')}
                                                    />
                                            </Grid>

                                            <Grid item xs={12}>
                                                <FormLabel
                                                    component="legend"
                                                    className={classes.FormLabel}>Share Class</FormLabel>
                                                <ToggleButtonGroup
                                                    className={classes.ToggleButtonGroup}
                                                    exclusive
                                                    value={item.share_class}
                                                    onChange={(evt, newValue) => this.handleInputChange(evt, index, 'share_class', newValue)}
                                                    >
                                                        {
                                                            share_class_options.map((sc_item, sc_index) => {
                                                                return (
                                                                    <ToggleButton className={classes.ToggleButton} value={sc_item} key={sc_index}>{sc_item}</ToggleButton>
                                                                )
                                                            })
                                                        }
                                                </ToggleButtonGroup>

                                                {
                                                    item.share_class && ['preferred', 'others'].indexOf(item.share_class.toLowerCase()) > -1 ?

                                                        <TextField
                                                            style={{ marginTop: 16 }}
                                                            placeholder={item.share_class.toLowerCase() == 'preferred' ? 'Series (E.g. A, B, C)' : 'Share Class'}
                                                            variant="outlined"
                                                            size="small"
                                                            className={classes.TextField}
                                                            value={item.share_class_text}
                                                            InputLabelProps={{
                                                                shrink: true
                                                            }} 
                                                            /> : ''
                                                }
                                            </Grid>
                                        </React.Fragment>
                                    )
                                })
                            }

                            <IconButton
                                onClick={(evt) => this.handleAddPortfolioItem(evt)}
                                >
                                <AddIcon />
                            </IconButton>
                            
                            <Grid item xs={12}>
                                <Divider />
                            </Grid>
                        </Grid>
                    </Grid>
                    
                    <Grid item xs={12}>
                        <Button
                            className="btn-submit blue-btn"
                            style={{ fontSize: ReScaleForDeviceScale(1.5, global150BodyFont, globalBodyFont) }}
                            onClick={(evt) => this.handleSubmitPortfolio(evt)}>
                                Submit
                            </Button>
                    </Grid>
                </Grid>
            </section>
        )
    }
}

export default withStyles(FormStyle)(PortfolioControl);