import { fromJS, List, Map } from 'immutable';

const initialState = {
    pipelineResponse:[],
    expressInterestError: false,
    expressInterestResponseMessage: '',
    headquarterCountryResponse:[],
    verticalsResponse:[],
    adBanner1Response: []
}

const initialImmutableState = fromJS(initialState)
export default initialImmutableState;