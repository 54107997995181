import React, { Fragment } from 'react';
import CropFreeIcon from '@material-ui/icons/CropFree';
import ReactECharts from 'echarts-for-react';

import { 
    Typography,
    IconButton,
    Grid,
    Tooltip,
    withStyles,
    Checkbox,
} from '@material-ui/core';

import {
    ToggleButtonGroup,
    ToggleButton,
} from '@material-ui/lab';

import { sendGAEvent } from '../../../../utils/ga';

import {
    numberWithCommas,
    ReScaleForDeviceScale,
    getImpliedVal,
    roundNumber,
    getLatestFundingRound,
} from '../../../../utils/util';

import {
    get_first_price,
    handle_missing_data,
} from '../../../../utils/PriceChartUtil';

import {
    globalFontFamily,
    global150BodyFont,
    globalBodyFont,
} from '../../../styles';

import {
    content_placeholders,
} from '../../../../config';
import blurChartImage from '../../../../assets/images/blur_big.png';

const PriceChartStyle = theme => ({
    ToggleButton: {
        fontSize: ReScaleForDeviceScale(1.5, global150BodyFont, globalBodyFont),
        fontFamily: globalFontFamily,
        color: theme.palette.common.black,
        borderColor: theme.palette.common.black,
    },
    Checkbox: {
        padding: 2
    }
});

class PriceChart extends React.Component{
    state = {
        company_id: 0,
        date: [],
        average_bid: [],
        average_offer: [],
        highest_bid: [],
        lowest_offer: [],
        count_bid: [],
        count_offer: [],
        latest_funding_rounds: [],
        latest_funding_rounds_val: [],
        min_y: 0,
        max_y: 0,
        show_latest_funding_rounds: false,
        price_line_type: 'average_price',
        zoom_level: 'all',
        annotation: {
            date: '',
            buy: 0,
            sell: 0,
            lfr_price: 0,
            lfr_val: 0,
        },
        average_bid_visual_map: [],
        average_offer_visual_map: [],
        highest_bid_visual_map: [],
        lowest_offer_visual_map: [],
        lfr_visual_map: [],
        showBlurImage: false,
        showEmptyGraph: false,
    }

    constructor(props){
        super(props);
        this.myChart = React.createRef();
    }

    static getDerivedStateFromProps(nextProps, prevState){
        const {
            company_id,
            historical_pricing,
            funding_rounds,
        } = nextProps;

        if( company_id != prevState.company_id ){
            if( historical_pricing.historical_pricing.length ){
                let payload = {
                    date: [],
                    average_bid: [],
                    average_offer: [],
                    highest_bid: [],
                    lowest_offer: [],
                    count_bid: [],
                    count_offer: [],
                    latest_funding_rounds: [],
                    latest_funding_rounds_val: [],
                    min_y: 0,
                    max_y: 0,
                    average_bid_visual_map: [],
                    average_offer_visual_map: [],
                    highest_bid_visual_map: [],
                    lowest_offer_visual_map: [],
                    lfr_visual_map: [],
                }

                const first_price = get_first_price(historical_pricing.historical_pricing);

                let ave_bid_check_payload = {
                    last_actual_data: first_price.average_bid ? first_price.average_bid : 0,
                    null_index: -1,
                    last_data_checkpoint: 0
                };

                let ave_offer_check_payload = {
                    last_actual_data: first_price.average_offer ? first_price.average_offer : 0,
                    null_index: -1,
                    last_data_checkpoint: 0,
                };

                let highest_bid_check_payload = {
                    last_actual_data: first_price.highest_bid ? first_price.highest_bid : 0,
                    null_index: -1,
                    last_data_checkpoint: 0,
                }

                let lowest_offer_check_payload = {
                    last_actual_data: first_price.lowest_offer ? first_price.lowest_offer : 0,
                    null_index: -1,
                    last_data_checkpoint: 0,
                }

                for( var ii=0; ii< historical_pricing.historical_pricing.length; ii++ ){

                    payload['date'].push(historical_pricing.historical_pricing[ii].date);
                    payload['average_bid'].push( roundNumber(historical_pricing.historical_pricing[ii].average_bid) );
                    payload['average_offer'].push( roundNumber(historical_pricing.historical_pricing[ii].average_offer) );
                    payload['highest_bid'].push( roundNumber(historical_pricing.historical_pricing[ii].highest_bid) );
                    payload['lowest_offer'].push( roundNumber(historical_pricing.historical_pricing[ii].lowest_offer) );
                    payload['count_bid'].push( historical_pricing.historical_pricing[ii].count_bid );
                    payload['count_offer'].push( historical_pricing.historical_pricing[ii].count_offer );

                    let response = handle_missing_data(ii, ave_bid_check_payload, historical_pricing.historical_pricing, payload, '69,97,168', 'average_bid');
                    payload = response['payload'];
                    ave_bid_check_payload = response['check_payload'];

                    response = handle_missing_data(ii, ave_offer_check_payload, historical_pricing.historical_pricing, payload, '252,163,16', 'average_offer');
                    payload = response['payload'];
                    ave_offer_check_payload = response['check_payload'];

                    response = handle_missing_data(ii, highest_bid_check_payload, historical_pricing.historical_pricing, payload, '69,97,168', 'highest_bid');
                    payload = response['payload'];
                    highest_bid_check_payload = response['check_payload'];

                    response = handle_missing_data(ii, lowest_offer_check_payload, historical_pricing.historical_pricing, payload, '252,163,16', 'lowest_offer');
                    payload = response['payload'];
                    lowest_offer_check_payload = response['check_payload'];

                    const lfr_response = getLatestFundingRound(funding_rounds, (fr_item) => fr_item['deal_date'] < historical_pricing.historical_pricing[ii]['date']);
                    if( lfr_response.price && lfr_response.valuation ){
                        payload['latest_funding_rounds'].push(lfr_response.price);
                        payload['latest_funding_rounds_val'].push(lfr_response.valuation);
                    }
                    
                }

                const lfr_visual_map_payload = {
                    color: 'rgba(34,139,34,1)',
                    gte: 0,
                    lt: historical_pricing.historical_pricing.length,
                    symbol: 'none'
                }

                payload['lfr_visual_map'].push(lfr_visual_map_payload);
                payload['showBlurImage'] = !historical_pricing.allow;
                payload['showEmptyGraph'] = !historical_pricing.data;
                return payload;
            } else {
                let payload = {
                    showBlurImage: false,
                    showEmptyGraph: false,
                }
                payload['showBlurImage'] = !historical_pricing.allow;
                payload['showEmptyGraph'] = !historical_pricing.data;
                return payload;
            }
        } else {
            return null;
        }
    }

    getOption = () => {
        const {
            average_bid,
            average_offer,
            highest_bid,
            lowest_offer,
            count_bid,
            count_offer,
            date,
            average_bid_visual_map,
            average_offer_visual_map,
            highest_bid_visual_map,
            lowest_offer_visual_map,
            show_latest_funding_rounds,
            latest_funding_rounds,
            latest_funding_rounds_val,
            lfr_visual_map,
            price_line_type,
        } = this.state;

        const {
            hidefullscreen,
            company_id,
            token,
        } = this.props;

        const option = {
            legend: {
                show: false,
            },
            dataZoom: [
                {
                    type: 'slider',
                    show: true,
                    xAxisIndex: [0,1],
                }
            ],
            xAxis: [
                {
                    type: 'category',
                    boundaryGap: false,
                    data: date,
                    nameTextStyle: {
                        fontFamily: 'Roboto',
                        fontSize: 12
                    },
                    axisLabel: {
                        fontFamily: 'Roboto',
                        fontSize: 12,
                        rich: {
                            fontFamily: 'Roboto',
                            fontSize: 12
                        }
                    }
                },
                {
                    type: 'category',
                    boundaryGap: false,
                    data: date,
                    gridIndex: 1,
                    nameTextStyle: {
                        fontFamily: 'Roboto',
                        fontSize: 12
                    },
                    axisLabel: {
                        fontFamily: 'Roboto',
                        fontSize: 12,
                        rich: {
                            fontFamily: 'Roboto',
                            fontSize: 12
                        }
                    }
                }
            ],
            grid: [
                {
                    bottom: '50%'
                },
                {
                    top: '70%'
                }
            ],
            yAxis: [
                {
                    type: 'value',
                    name: 'Price per share',
                    nameTextStyle: {
                        fontFamily: 'Roboto',
                        fontSize: 12
                    },
                    axisLabel: {
                        formatter: '${value} ',
                        fontFamily: 'Roboto',
                        fontSize: 12,
                        rich: {
                            fontFamily: 'Roboto',
                            fontSize: 12
                        }
                    },
                    axisPointer: {
                        snap: true
                    }
                },
                {
                    type: 'value',
                    name: 'Order Count',
                    interval: 1,
                    gridIndex: 1,
                    nameTextStyle: {
                        fontFamily: 'Roboto',
                        fontSize: 12
                    },
                    axisLabel: {
                        formatter: '{value} ',
                        fontFamily: 'Roboto',
                        fontSize: 12,
                        rich: {
                            fontFamily: 'Roboto',
                            fontSize: 12
                        }
                    },
                    axisPointer: {
                        snap: true
                    }
                }
            ],
            tooltip: {
                trigger: 'axis',
                
                axisPointer: {
                  type: 'cross'
                },
                formatter: function(params){
                    const line_items = params.filter(item => item.componentSubType == 'line');
                    const bar_items = params.filter(item => item.componentSubType == 'bar');

                    if( line_items.length || bar_items.length ){
                        const axisValue = line_items.length ? line_items[0]['axisValue'] : bar_items[0]['axisValue'];
                        sendGAEvent({
                            type: line_items.length ? 'wealth_view_price_chart': 'wealth_view_volume_chart',
                            data: {
                                company_id: company_id,
                                token: token,
                                view_time: axisValue,
                            }
                        });

                        let label = '<div class="chart-tooltip">';
                        label += line_items.length ? `<strong>${axisValue}</strong><br />` : bar_items.length ? `<strong>${axisValue}</strong><br />` : '';
                        label += '<table cellspacing="0" cellpadding="0" border="0">';
                        if( line_items.length ){
                            label += '<tr>';
                            label += '<th></th><th>Price per share</th><th>Implied valuation</th>';
                            label += '</tr>';
    
                        }

                        for( let ii=0; ii<line_items.length; ii++ ){
                            label += '<tr>';
                            label += '<td>';
                            label += `<span class="name-label" style="display: inline-block; min-width: 120px">`;
                            label += `${line_items[ii]['marker']} ${line_items[ii]['seriesName']} `
                            label += `${line_items[ii]['color'].indexOf(',0.2') > -1 ? '(Not live)' : ''}`;
                            label += `</span>`;
                            label += '</td><td>';
                            label += line_items[ii]['componentSubType'] != 'bar' ? `<span class="price-label">$${numberWithCommas(line_items[ii]['value'])}</span>` : `<span class="price-label">${line_items[ii]['value']}</span>`
                            label += '</td><td>';
    
                            if(
                                latest_funding_rounds.length && 
                                latest_funding_rounds_val.length && 
                                latest_funding_rounds[latest_funding_rounds.length-1] && 
                                latest_funding_rounds_val[latest_funding_rounds_val.length-1] && 
                                line_items[ii]['seriesName'].indexOf('LFR') == -1 &&
                                line_items[ii]['componentSubType'] != 'bar'
                            ){
                                label += `<span class="price-label">${getImpliedVal(line_items[ii]['value'], latest_funding_rounds[latest_funding_rounds.length-1], latest_funding_rounds_val[latest_funding_rounds_val.length-1])}</span>`
                            }
                            label += '</td>';
                            label += '</tr>';
                        }

                        for( let iii=0; iii<bar_items.length; iii++ ){
                            label += `<span class="count-label" style="display: inline-block; margin-right: 10px;">${bar_items[iii]['marker']} ${bar_items[iii]['seriesName']}: ${bar_items[iii]['value']}</span>`
                        }
                        label += '</div>';
                        return label;
                    } else {
                        return '';
                    }
                }
            },
            graphic: {
                type: 'group',
                width: '100%',
                height: '100%',
                children: []
            }
        }

        const graphics = [
            {
                type: 'circle',
                id: 'bidcount',
                left: hidefullscreen ? '10%' : 55,
                top: hidefullscreen ? '60%' : 230,
                shape: {
                    r: 7,
                },
                style: {
                    fill: 'rgba(7, 160, 230, 1)'
                }
            },
            {
                type: 'text',
                id: 'bidcounttext',
                left: hidefullscreen ? '11%' : 75,
                top: hidefullscreen ? '60%' : 232,
                style: {
                    text: 'Bid Count',
                    fill: 'rgba(7, 160, 230, 1)'
                }
            },
            {
                type: 'circle',
                id: 'offercount',
                left: hidefullscreen ? '15%' : 140,
                top: hidefullscreen ? '60%' : 230,
                shape: {
                    r: 7,
                },
                style: {
                    fill: 'rgba(46, 65, 112, 1)'
                }
            },
            {
                type: 'text',
                id: 'offercounttext',
                left: hidefullscreen ? '16%' : 160,
                top: hidefullscreen ? '60%' : 232,
                style: {
                    text: 'Offer Count',
                    fill: 'rgba(46, 65, 112, 1)'
                }
            }
        ];

        if( !hidefullscreen ){
            option['tooltip']['position']= ['10%', '-40%'];
        } else {
            option['tooltip']['position'] = ['65%', '-30%'];
            graphics.push(
                {
                    type: 'image',
                    left: '85%',
                    top: 0,
                    style: {
                        image: content_placeholders.logos.gateway,
                        width: 52,
                        height: 55,
                    }
                }
            );
        }

        const visual_map = [];
        const series = [];
        const legend_data = [];

        if( price_line_type == "average_price" ){
            visual_map.push({
                show: false,
                dimension: 0,
                seriesIndex: 0,
                pieces: average_bid_visual_map
            });

            series.push({
                name: 'Ave. Bid', 
                type: 'line', 
                data: average_bid
            });

            visual_map.push({
                show: false,
                dimension: 0,
                seriesIndex: 1,
                pieces: average_offer_visual_map
            });

            series.push({
                name: 'Ave. Offer',
                type: 'line',
                data: average_offer
            });

            // legend_data.push({
            //     name: 'Ave. Bid',
            //     icon: 'circle',
            //     itemStyle: {
            //         color: 'rgba(69,97,168,0.8)'
            //     },
            //     textStyle: {
            //         fontFamily: "'Roboto', sans-serif",
            //         color: 'rgba(69,97,168,0.8)',
            //     }
            // });

            // legend_data.push({
            //     name: 'Ave. Offer',
            //     icon: 'circle',
            //     itemStyle: {
            //         color: 'rgba(252,163,16,0.8)'
            //     },
            //     textStyle: {
            //         fontFamily: "'Roboto', sans-serif",
            //         color: 'rgba(252,163,16,0.8)',
            //     }
            // })

            graphics.push({
                type: 'circle',
                id: 'avebid',
                left: hidefullscreen ? '10%' : 55,
                top: 5,
                shape: {
                    r: 7,
                },
                style: {
                    fill: 'rgba(69,97,168,0.8)'
                }
            });
            
            graphics.push({
                type: 'text',
                id: 'avebidtext',
                left: hidefullscreen ? '11%' : 75,
                top: 7,
                style: {
                    text: 'Ave. Bid',
                    fill: 'rgba(69,97,168,0.8)'
                }
            });

            graphics.push({
                type: 'circle',
                id: 'aveoffer',
                left: hidefullscreen ? '15%' : 140,
                top: 5,
                shape: {
                    r: 7,
                },
                style: {
                    fill: 'rgba(252,163,16,0.8)'
                }
            });
            
            graphics.push({
                type: 'text',
                id: 'aveoffertext',
                left: hidefullscreen ? '16%' : 160,
                top: 7,
                style: {
                    text: 'Ave. Offer',
                    fill: 'rgba(252,163,16,0.8)'
                }
            });

        } else {
            visual_map.push({
                show: false,
                dimension: 0,
                seriesIndex: 0,
                pieces: highest_bid_visual_map
            });

            series.push({
                name: 'Highest Bid',
                type: 'line',
                data: highest_bid
            });

            visual_map.push({
                show: false,
                dimension: 0,
                seriesIndex: 1,
                pieces: lowest_offer_visual_map
            });

            series.push({
                name: 'Lowest Offer',
                type: 'line',
                data: lowest_offer
            });

            // legend_data.push({
            //     name: 'Highest Bid',
            //     icon: 'circle',
            //     itemStyle: {
            //         color: 'rgba(69,97,168,0.8)'
            //     },
            //     textStyle: {
            //         fontFamily: "'Roboto', sans-serif",
            //         color: 'rgba(69,97,168,0.8)'
            //     }
            // });

            // legend_data.push({
            //     name: 'Lowest Offer',
            //     icon: 'circle',
            //     itemStyle: {
            //         color: 'rgba(252,163,16,0.8)'
            //     },
            //     textStyle: {
            //         fontFamily: "'Roboto', sans-serif",
            //         color: 'rgba(252,163,16,0.8)'
            //     }
            // })

            graphics.push({
                type: 'circle',
                id: 'highestbid',
                left: hidefullscreen ? '10%' : 55,
                top: 5,
                shape: {
                    r: 7,
                },
                style: {
                    fill: 'rgba(69,97,168,0.8)'
                }
            });
            
            graphics.push({
                type: 'text',
                id: 'highestbidtext',
                left: hidefullscreen ? '11%' : 75,
                top: 7,
                style: {
                    text: 'Highest Bid',
                    fill: 'rgba(69,97,168,0.8)'
                }
            });

            graphics.push({
                type: 'circle',
                id: 'lowestoffer',
                left: hidefullscreen ? '15%' : 140,
                top: 5,
                shape: {
                    r: 7,
                },
                style: {
                    fill: 'rgba(252,163,16,0.8)'
                }
            });
            
            graphics.push({
                type: 'text',
                id: 'lowestoffertext',
                left: hidefullscreen ? '16%' : 160,
                top: 7,
                style: {
                    text: 'Lowest Offer',
                    fill: 'rgba(252,163,16,0.8)'
                }
            });
        }

        if( show_latest_funding_rounds ){
            visual_map.push({
                show: false,
                dimension: 0,
                seriesIndex: 2,
                pieces: lfr_visual_map
            });

            series.push({
                name: 'LFR price',
                type: 'line',
                lineStyle: {
                    type: 'dashed'
                },
                label: {
                    formatter: '{a} <sup>(4)</sup>'
                },
                data: latest_funding_rounds,
            });

            // legend_data.push({
            //     name: 'LFR price',
            //     icon: 'circle',
            //     itemStyle: {
            //         color: 'rgba(34,139,34,1)'
            //     },
            //     textStyle: {
            //         color: 'rgba(34,139,34,1)'
            //     }
            // });

            graphics.push({
                type: 'circle',
                id: 'lfr',
                left: hidefullscreen ? '20%' : 230,
                top: 5,
                shape: {
                    r: 7,
                },
                style: {
                    fill: 'rgba(34,139,34,1)'
                }
            });
            
            graphics.push({
                type: 'text',
                id: 'lfrtext',
                left: hidefullscreen ? '21%' : 250,
                top: 7,
                style: {
                    text: 'LFR price',
                    fill: 'rgba(34,139,34,1)'
                }
            });
        }

        series.push({
            name: 'Bid Count',
            type: 'bar',
            yAxisIndex: 1,
            xAxisIndex: 1,
            data: count_bid,
            itemStyle: {
                color: 'rgba(7, 160, 230, 1)'
            },
        });

        series.push({
            name: 'Offer Count',
            type: 'bar',
            yAxisIndex: 1,
            xAxisIndex: 1,
            data: count_offer,
            itemStyle: {
                color: 'rgba(46, 65, 112, 1)'
            },
        });
        
        // legend_data.push({
        //     name: 'Bid Count',
        //     icon: 'circle',
        //     itemStyle: {
        //         color: 'rgba(7, 160, 230, 1)'
        //     },
        //     textStyle: {
        //         fontFamily: "'Roboto', sans-serif",
        //         color: 'rgba(7, 160, 230, 1)'
        //     }
        // });

        // legend_data.push({
        //     name: 'Offer Count',
        //     icon: 'circle',
        //     itemStyle: {
        //         color: 'rgba(46, 65, 112, 1)'
        //     },
        //     textStyle: {
        //         fontFamily: "'Roboto', sans-serif",
        //         color: 'rgba(46, 65, 112, 1)'
        //     }
        // })

        option['visualMap'] = visual_map;
        option['series'] = series;
        // option['legend']['data'] = legend_data;
        option['graphic']['children'] = graphics;

        return option;
    }


    handleChangePriceLineType(key, evt, newValue){
        const { price_line_type } = this.state;
        if( ['zoom_level', 'price_line_type'].indexOf(key) > -1 && ( newValue == price_line_type || !newValue ) ) return;
        const { company_id } = this.props;
        sendGAEvent({
            type: 'wealth_click_price_chart',
            data: {
                company_id: company_id,
                metrix_name: key + '_' + newValue
            }
        });

        const new_state = {
            [key]: newValue
        };

        if( key != 'zoom_level' ){
            new_state['zoom_level'] = 'all';
        }
        
        this.setState(new_state, () => {
            if( key == 'zoom_level' ){
                
                if( this.myChart && this.myChart.current && this.myChart.current.getEchartsInstance() ){
                    
                    const { date } = this.state;

                    let startVal, endVal;
                    switch(newValue){
                        case '30d':
                            startVal=date.length-30;
                            endVal=date.length-1;
                            break;
                        case '60d':
                            startVal=date.length-60;
                            endVal=date.length-1;
                            break;
                        case '90d':
                            startVal=date.length-90;
                            endVal=date.length-1;
                            break;
                        default:
                            startVal=date.length-120;
                            endVal=date.length-1;
                    }
                    this.myChart.current.getEchartsInstance().dispatchAction({
                        type: 'dataZoom',
                        startValue: startVal,
                        endValue: endVal,
                    });
                }
            }
        });

    }


    render(){
        const { 
            show_latest_funding_rounds,
            latest_funding_rounds,
            average_bid,
            average_offer,
            price_line_type,
            zoom_level,
            showBlurImage,
            showEmptyGraph,
        } = this.state;

        const {
            hidefullscreen,
            handlePriceChartFullScreen,
            classes,
            isMobile,
            public_mode,
        } = this.props;

        const total_bid = average_bid && average_bid.length ? average_bid.reduce((total, num) => total+num) : 0;
        const total_offer = average_offer && average_offer.length ? average_offer.reduce((total, num) => total+num) : 0;

        const average_classname = price_line_type=='weighted_price' ? 'legend-item orderbook clickable orderbook-price average' : 'legend-item orderbook clickable orderbook-price high-low';
        
        const latest_funding_rounds_classname = show_latest_funding_rounds ? "legend-item orderbook lfr clickable" : "legend-item orderbook lfr clickable dim"; 
        const total_lfr = latest_funding_rounds && latest_funding_rounds.length ? latest_funding_rounds.reduce((total, num) => total+num) : 0;
        
        return showEmptyGraph && (total_bid+total_offer) == 0 && !public_mode ? (
            <section className="price-chart-section">
                <Grid container spacing={2}>
                    {/* <Grid item xs={12}>
                        <Typography variant="h6">Secondary Activity</Typography>
                        <p className="elaborate-text">Data displayed represents live order (offer or bid) prices at that point in time and not executed prices. All data is sourced from Gateway network.</p>
                    </Grid> */}
                    <Grid item xs={12}>
                        <span style={{fontSize: 12}}>Limited market activity in this name. Be the first to place an order.</span>
                    </Grid>
                </Grid>
            </section>
        ) : (
            <section className="price-chart-section orderbook" id="price-chart-section">
                {
                    hidefullscreen ?
                        <Grid item xs={1} style={{
                            height: 70
                        }}>
                            
                        </Grid> : ''
                }
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <div className='legend-wrapper mt-1'>
                            <div className={average_classname}>
                                
                                <ToggleButtonGroup exclusive value={price_line_type} onChange={this.handleChangePriceLineType.bind(this, 'price_line_type')}>
                                    <ToggleButton className={classes.ToggleButton} value="average_price" aria-label="left aligned">
                                        Weighted Average
                                    </ToggleButton>
                                    <ToggleButton className={classes.ToggleButton} value="highest_lowest" aria-label="right aligned">
                                        Highest / Lowest
                                    </ToggleButton>
                                </ToggleButtonGroup>
                                
                            </div>
                            {
                                latest_funding_rounds.length && total_lfr ? <div className={isMobile ? "legend-item orderbook clickable" : "legend-item orderbook lfr clickable"}>
                                        <Checkbox 
                                            className={classes.Checkbox}
                                            checked={show_latest_funding_rounds}
                                            onChange={this.handleChangePriceLineType.bind(this, 'show_latest_funding_rounds')}
                                            style={{
                                                color: 'rgba(34,139,34,1)'
                                            }}
                                            />
                                        <span className="label-text" style={{color: 'rgba(34,139,34,1)'}}>Last Funding Round Price (LFR)</span>
                                    </div> : ''
                            }
                        </div>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <span style={{ marginRight: 5, fontSize: 12 }}>Zoom</span>
                        <ToggleButtonGroup exclusive value={zoom_level} onChange={this.handleChangePriceLineType.bind(this, 'zoom_level')}>
                            <ToggleButton className={classes.ToggleButton} value="30d" aria-label="30d">
                                30d
                            </ToggleButton>
                            <ToggleButton className={classes.ToggleButton} value="60d" aria-label="60d">
                                60d
                            </ToggleButton>
                            <ToggleButton className={classes.ToggleButton} value="90d" aria-label="90d">
                                90d
                            </ToggleButton>
                            <ToggleButton className={classes.ToggleButton} value="all" aria-label="all">
                                all
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <div className="chart-wrapper">
                            {
                                showBlurImage || public_mode
                                ?
                                    <img src={blurChartImage} alt="Chart Image" style={{width: '100%'}} />
                                :
                                <ReactECharts 
                                    ref={this.myChart}
                                    option={this.getOption()} 
                                    style={{height: hidefullscreen ? 600 : 400}}
                                    notMerge={true}
                                    />
                            }
                        </div>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        {/* <span className='chart-disclaimer'><sup>(1)</sup> Price per share, </span> */}
                        {/* <span className='chart-disclaimer'><sup>(2)</sup> No live orders available, </span> */}
                        {/* <span className='chart-disclaimer'><sup>(3)</sup> Implied valuation (estimated),</span> */}
                        {/* <span className='chart-disclaimer'><sup>(4)</sup> Last funding round price </span> */}
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <p className='elaborate-text' style={{marginTop: '10px'}}>The content is provided for informational purposes only. Gateway does not guarantee nor make any representation or warranty as to the accuracy, validity, timeliness, completement or continued availability of the content.</p>
                    </Grid>
                </Grid>
            </section>
        )
    }
}

export default withStyles(PriceChartStyle)(PriceChart);