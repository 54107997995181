import { combineReducers } from "redux";
import {
	getAllCompanies,
	getCompanyInvestors,
	getVerticals,
	getFundingRound,
	getLatestFundingRound,
	getClubDetail,
	getEmployee,
	getPublicNewsArticle,
	getDealLinks,
	getDealTimeline,
	getHistoricalPrice,
	toggleWatchList,
	RequestOnboarding,
	getSimilarCompanies,
} from "./Action";

import { resetSnackBar } from "../../account/Action";

import { default as InitialState } from "./InitialState";


function shuffle(array) {
	let currentIndex = array.length,  randomIndex;
  
	// While there remain elements to shuffle...
	while (currentIndex != 0) {
  
	  // Pick a remaining element...
	  randomIndex = Math.floor(Math.random() * currentIndex);
	  currentIndex--;
  
	  // And swap it with the current element.
	  [array[currentIndex], array[randomIndex]] = [
		array[randomIndex], array[currentIndex]];
	}
  
	return array;
}


export default combineReducers({
	reducer(state = InitialState, action) {
		switch (action.type) {
			case getAllCompanies.request.toString():
			case getAllCompanies.failure.toString():
				return Object.assign({}, state, {
					AllCompaniesResponse: {},
				});
			case getAllCompanies.success.toString():
				return Object.assign({}, state, {
					AllCompaniesResponse: action.payload,
				});
			case getClubDetail.success.toString():
				return Object.assign({}, state, {
					clubDetail: action.payload,
				});
			case getClubDetail.request.toString():
			case getClubDetail.failure.toString():
				return Object.assign({}, state, {
					clubDetail: {},
				});
			case getEmployee.success.toString():
				return Object.assign({}, state, {
					employees: action.payload,
				});
			case getEmployee.request.toString():
			case getEmployee.failure.toString():
				return Object.assign({}, state, {
					employees: [],
				});
			case getPublicNewsArticle.success.toString():
				return Object.assign({}, state, {
					publicNewsArticles: action.payload,
				});
			case getPublicNewsArticle.request.toString():
			case getPublicNewsArticle.failure.toString():
				return Object.assign({}, state, {
					publicNewsArticles: {},
				});
			case getDealLinks.success.toString():
				return Object.assign({}, state, {
					clubDealLinks: action.payload,
				});
			case getDealLinks.request.toString():
			case getDealLinks.failure.toString():
				return Object.assign({}, state, {
					clubDealLinks: [],
				});
			case getVerticals.success.toString():
				return Object.assign({}, state, {
					verticals: action.payload,
				});
			case getVerticals.failure.toString():
				return state;
			case getCompanyInvestors.success.toString():
				return Object.assign({}, state, {
					investors: action.payload,
				});
			case getCompanyInvestors.failure.toString():
				return state;
			case getFundingRound.request.toString():
			case getFundingRound.failure.toString():
				return Object.assign({}, state, {
					fundingRounds: { count: 0, results: [] },
					finishedCollectFundingRounds: false,
				});
			case getFundingRound.success.toString():
				return Object.assign({}, state, {
					fundingRounds: action.payload,
					finishedCollectFundingRounds: true,
				});
			case getLatestFundingRound.request.toString():
				return Object.assign({}, state, {
					latestFundingRound: {}
				});
			case getLatestFundingRound.success.toString():
				return Object.assign({}, state, {
					latestFundingRound: action.payload,
				});
			case getLatestFundingRound.failure.toString():
				return state;
			case getDealTimeline.request.toString():
				return Object.assign({}, state, { clubDealTimelines: [] });
			case getDealTimeline.success.toString():
				return Object.assign({}, state, {
					clubDealTimelines: action.payload,
				});
			case getDealTimeline.failure.toString():
				return Object.assign({}, state, { clubDealTimelines: [] });
			case getHistoricalPrice.request.toString():
				return Object.assign({}, state, { historicalPrice: {} });
			case getHistoricalPrice.success.toString():
				return Object.assign({}, state, {
					historicalPrice: action.payload,
				});
			case getHistoricalPrice.failure.toString():
				return Object.assign({}, state, {
					historicalPrice: {},
				});
			case toggleWatchList.success.toString():
				return Object.assign({}, state, {
					toggleWatchError: false,
					toggleWatchListResponse: action.payload,
					toggleWatchListMessage: action.payload.message
				});
			case toggleWatchList.failure.toString(): 
				return Object.assign({}, state, {
					toggleWatchListError: true,
					toggleWatchListMessage: "There is an error, please try again later.",
				});
			case resetSnackBar.toString():
				return Object.assign({}, state, {
					referralError: false,
					referralResponseMessage: "",
					expressInterestError: false,
					expressInterestResponseMessage: "",
					toggleWatchListError: false,
					toggleWatchListMessage: "",
				});
			case RequestOnboarding.request.toString():
				return Object.assign({}, state, {
					trial_onboarding: { error: false, message: '' }
				});
			case RequestOnboarding.success.toString():
			case RequestOnboarding.failure.toString():
				return Object.assign({}, state, {
					trial_onboarding: { error: action.payload.error, message: action.payload.responseMessage }
				});
			case getSimilarCompanies.request.toString():
			case getSimilarCompanies.failure.toString():
				return Object.assign({}, state, {
					similar_companies: {}
				});
			case getSimilarCompanies.success.toString():
				const results = action.payload.results ? shuffle(action.payload.results) : [];
				
				return Object.assign({}, state, {
					similar_companies: {results: results}
				});
			default:
				return state;
		}
	},
});
