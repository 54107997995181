import { combineReducers } from 'redux';
import {
    ShowLoader,
    HideLoader,
    checkMaintenance,
    CollapseSidebar,
} from './Action';

const InitialStates = {
    loaderState: false,
    collapseSidebar: false,
    maintenance: 0,
    appConfig: []
}

export default combineReducers({

    reducer(state=InitialStates, action){
        switch( action.type ){
            case ShowLoader.toString():
                return Object.assign({}, state, {
                    loaderState: true
                });
            case HideLoader.toString():
                return Object.assign({}, state, {
                    loaderState: false
                });
            case CollapseSidebar.toString():
                return Object.assign({}, state, {
                    collapseSidebar: action.payload
                });
            case checkMaintenance.request.toString():
            case checkMaintenance.failure.toString():
                return Object.assign({}, state, {
                    appConfig: []
                })
            case checkMaintenance.success.toString():
                const { maintenance } = action.payload;
                const new_state = maintenance ? { maintenance: maintenance } : { appConfig: action.payload }
                return Object.assign({}, state, new_state);
            default:
                return state;
        }
    }

});