import { withStyles } from '@material-ui/styles';
import React from 'react';
import { Helmet } from 'react-helmet';

import {
    content_placeholders,
} from '../../../config';

import {
    Box,
    Grid,
    Paper,
    Typography,
} from '@material-ui/core';

import { PipelineSidebar } from '../../common';
import { Styles } from '../../styles';

import {
    PriceChart,
    SectorPerformance,
} from './components';

class Trends extends React.Component{
    state = {}

    constructor(props){
        super(props);
    }

    componentDidMount(){
        const { getTrends } = this.props;
        getTrends({});
    }

    // static getDerivedStateFromProps(nextProps, prevState){

    // }

    render(){
        // const {

        // } = this.state;

        const {
            collapseSidebar,
            trends,
        } = this.props;
        const home_right_class = collapseSidebar ? 'home-right narrow' : 'home-right';
        return (
            <div className='home'>
                <div className='top-padding'></div>
                <Helmet>
                    <title>Investor Portal | {content_placeholders.title_prefix}</title>
                </Helmet>
                <PipelineSidebar
                    tabFocusIndex="0"
                    collapse={true}
                    {...this.props}
                    />
                <div className={home_right_class} id="home-right">
                    <Box m={2}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={7}>
                                <Paper style={{ padding: 16, width: '100%' }}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <Typography variant="h6">Sector Performance</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <PriceChart
                                                {...{
                                                    trends,
                                                }}
                                                />
                                        </Grid>
                                    </Grid> 
                                </Paper>
                            </Grid>
                            <Grid item xs={12} md={5}>
                                <SectorPerformance 
                                    {...{
                                        trends,
                                    }}
                                    />
                            </Grid>
                        </Grid>
                    </Box>
                </div>
            </div>
        )
    }
}

export default withStyles(Styles)(Trends);