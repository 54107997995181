import React, { Fragment, Component } from 'react';
import { PipelineSidebar } from '../../common';
import { Helmet } from 'react-helmet';
import { 
    content_placeholders,
    feature_flags,
} from '../../../config';

import {
    Box,
    Grid,
    Button,
    Typography,
    Paper,
} from '@material-ui/core';

import {
    PriceChart,
    PortfolioControlPositionWrapper,
    CompaniesPanel,
    HoldingStatistics,
    PortfolioDistribution,
} from './components';

class PortfolioHolding extends Component {
    state = {
        my_portfolio_company_ids: [],
        portfolio_control_modal_open: false,
        prompt_portfolio_control: false,
        chart_data_id: 0,
    }

    constructor(props){
        super(props);
    }

    componentDidMount(){
        const { 
            cost_base,
            getMyPortfolio,
        } = this.props;

        getMyPortfolio( {
            qs: {
                company_ids: ( Object.keys(cost_base) ).join(",")
            }
        } )
                
    }

    static getDerivedStateFromProps(nextProps, prevState){
        const { cost_base, } = nextProps;

        const portfolio_company_ids = Object.keys(cost_base);
        if( !portfolio_company_ids.equals(prevState.my_portfolio_company_ids) ){
            return { my_portfolio_company_ids: portfolio_company_ids }
        } else {
            return null;
        }
    }

    shouldComponentUpdate(nextProps, nextState){
        const { cost_base } = nextProps;
        if( ( Object.keys(cost_base) ).length != ( Object.keys(this.props.cost_base) ).length ){
            const { getMyPortfolio } = this.props;
            getMyPortfolio( {
                qs: {
                    company_ids: ( Object.keys(cost_base) ).join(",")
                }
            } )
        }
        return true;
    }

    handleShowPortfolioControl = (evt) => {
        const { portfolio_control_modal_open } = this.state;
        this.setState({ portfolio_control_modal_open: !portfolio_control_modal_open });   
    }

    handlePromptPortfolioControl = (evt, show) => {
        // const { prompt_portfolio_control } = this.state;
        this.setState({ prompt_portfolio_control: show });
    }

    handleUpdateMarkToMarket = (evt, cost_base_id) => {
        this.setState({ chart_data_id: cost_base_id });
    }

    render(){
        const {
            collapseSidebar,
            price_index,
            cost_base,
            company_details,
            appConfig,
            loginUserData: { email },
        } = this.props;

        const {
            portfolio_control_modal_open,
            prompt_portfolio_control,
            chart_data_id,
        } = this.state;

        const home_right_class = collapseSidebar ? 'home-right narrow' : 'home-right';

        return feature_flags.feature_is_available(
                appConfig,
                feature_flags.flags.PortfolioPage.flag_name,
                feature_flags.flags.PortfolioPage.attributes.mtm_view,
                email
                ) && (
                    <div className='home orderbook portfolio-holding'>
                        <div className="top-padding"></div>
                        <Helmet>
                            <title>Portfolio Holding | {content_placeholders.title_prefix}</title>
                        </Helmet>
                        <PipelineSidebar
                            tabFocusIndex="4"
                            collapse={true}
                            {...this.props}
                            />
                        <div className={home_right_class} id="home-right">
                            <Box m={2}>
                                <Grid container spacing={2}>
                                    <Grid item xs={8}>
                                        <PortfolioDistribution
                                            {...{
                                                cost_base,
                                                company_details,
                                                price_index,
                                            }}
                                            />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <HoldingStatistics
                                            {...{
                                                cost_base,
                                                company_details,
                                                price_index,
                                            }}
                                            />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Paper style={{ padding: 16, width: '100%' }}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12}>
                                                    <Typography
                                                        variant="h6">Portfolio Mark to market</Typography>
                                                        <p className="elaborate-text">Data displayed represents daily potential gain / loss of your portfolio against average bid price available on the Gateway platform. Click a holding in the summary table to filter for a single holding.</p>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <PriceChart
                                                        {...{
                                                            price_index,
                                                            company_details,
                                                            cost_base,
                                                            chart_data_id,
                                                        }}
                                                        />
                                                </Grid>
                                            </Grid>
                                        </Paper>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Paper style={{ padding: 16, width: '100%' }}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12}>
                                                    <CompaniesPanel
                                                        handleUpdateMarkToMarket={this.handleUpdateMarkToMarket}
                                                        {...{
                                                            price_index,
                                                            cost_base,
                                                            company_details,
                                                        }}
                                                        />
                                                </Grid>
                                            </Grid>
                                        </Paper>
                                    </Grid>
                                </Grid>
                            </Box>
                        </div>

                        <PortfolioControlPositionWrapper
                            handleShowPortfolioControl={this.handleShowPortfolioControl}
                            open={portfolio_control_modal_open}
                            popOut={prompt_portfolio_control}
                            {...this.props}
                            />

                    </div>
                )
    }
}

export default PortfolioHolding;