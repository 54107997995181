import {
    put,
    takeLatest,
    all,
    fork,
    call,
    delay,
} from 'redux-saga/effects';

import { Unauthorized } from "../../login/Action";

import {
    getFundingRound,
    getAllCompanies,
    // getVerticals,
    getLatestFundingRound,
    getDealLinks,
    getDealTimeline,
    getHistoricalPrice,
    toggleWatchList,
    // getDealOfferDetail,
    requestInformation,
    resetRequestInformation,
    getLiveOffers,
    getLiveDemand,
    getLiveOffersWatchlist,
    getLiveDemandWatchlist,
    getDealHighlight,
    getDealType,
    getGPDetail,
    shareDeal,
    getRiskDisclosure,
    getSharedUser,
    addCount,
} from './Action';

import {
	ShowLoader,
	HideLoader
} from '../../root/Action';

import {
    companies,
    club_company,
    watch_list,
    wealth_company,
    shared_page,
    access_right,
    api_call,
} from '../../../api';

import { content_placeholders } from '../../../config';

import {
    NewRelicError
} from '../../../utils';

import {
    logEventInternallyAndNewRelic
} from '../../../utils/ga';

import {
    compileQueryString,
    handleCatchSagaError,
} from '../../../utils/util';

function* getFundingRoundResponder({ payload }) {
    try {
        const { public_url } = payload;
        const path = public_url ? `/v2/wealth/public/funding-rounds/` : `/v2/wealth/funding-rounds/`;
        const query_string = compileQueryString(payload.qs);
        const { data } = yield call(
                            api_call.get,
                            path,
                            query_string,
                            payload);

        yield put(getFundingRound.success(data));
    } catch (e) {
        yield handleCatchSagaError(e, 'getFundingRoundResponder');
        yield put(getFundingRound.failure(e));
    } finally {}
}

function* getLatestFundingRoundResponder({ payload }) {
    try {
        const query_string = compileQueryString(payload.qs);
        const { data } = yield call(wealth_company.getLatestFundingRound, payload.root_path, payload.sub_path, payload.company_identifier, query_string, payload);
        yield put(getLatestFundingRound.success(data));
    } catch (e) {
        yield handleCatchSagaError(e, 'getLatestFundingRoundResponder');
        yield put(getLatestFundingRound.failure(e));
    } finally {}
}

// function* getVerticalsResponder({ payload }) {
//     try {
//         const { public_url } = payload;
//         const path = public_url ? `` : ``;
//         const { data } = yield call(companies.getVerticals, payload.company_id, payload);
//         yield put(getVerticals.success(data));
//     } catch (e) {
//         yield handleCatchSagaError(e, 'getVerticalsResponder');
//         yield put(getVerticals.failure(e));
//     } finally {

//     }
// }

function* getDealTimelineResponder({ payload }) {
    try {
        const { public_url } = payload;
        const path = public_url ? `/v2/wealth/public/deal-timelines/highlights/` : `/v2/wealth/deal-timelines/highlights/`;
        const query_string = compileQueryString(payload.qs);
        const { data } = yield call(
                            api_call.get,
                            path,
                            query_string, 
                            payload);
        yield put(getDealTimeline.success(data));
    } catch (e) {
        yield handleCatchSagaError(e, 'getDealTimelineResponder');
        yield put(getDealTimeline.failure(e));
    } finally {}
}

function* getDealLinksResponder({ payload }) {
    try {
        const { data } = yield call(club_company.getDealLinks, payload.company_id);
        yield put(getDealLinks.success(data));
    } catch (e) {
        yield handleCatchSagaError(e, 'getDealLinksResponder');
        yield put(getDealLinks.failure(e));
    } finally {}
}

function* getHistoricalPriceResponder({ payload }) {
    try {
        const { public_url } = payload;
        const path = public_url ? `/v2/wealth/public/deals/weighted-prices/` : `/v2/wealth/deals/weighted-prices/`;
        const query_string = compileQueryString(payload.qs);
        const { data } = yield call(
                                api_call.get, 
                                path,
                                query_string, 
                                payload);
        yield put(getHistoricalPrice.success(data));
    } catch (e) {
        // console.log( e );
        yield handleCatchSagaError(e, 'getHistoricalPriceResponder');
        yield put(getHistoricalPrice.failure(e));        
    } finally {}
}

function* getAllCompaniesResponder({ payload }) {
    try {
        const { public_url, company_identifier } = payload;

        const path = public_url ? `/v2/wealth/public/companies/` : `/v2/wealth/companies/${company_identifier}/`;
        const query_string = public_url ? compileQueryString({ token: company_identifier }) : '';
        const { data } = yield call(
                                api_call.get, 
                                path, 
                                query_string, 
                                payload);

        yield put(getAllCompanies.success(data));
    } catch (e) {
        yield handleCatchSagaError(e, 'getAllCompaniesResponder');
        yield put(getAllCompanies.failure(e));
    } finally {}
}

function* toggleWatchListResponder({ payload }) {
    try {
        // yield delay(2000);
        const { data } = yield call(watch_list.toggleWatchList, payload);
        yield put(toggleWatchList.success(data));
    } catch (e) {
        yield handleCatchSagaError(e, 'toggleWatchListResponder');
        yield put(toggleWatchList.failure(e));
    } finally {}
}

// function* getDealOfferDetailResponder({ payload }) {
//     try {
//         const { data } = yield call(wealth_company.getDealOffer, payload.root_path, payload.company_identifier, payload);

//         yield put(getDealOfferDetail.success(data));
//     } catch (e) {
//         yield handleCatchSagaError(e, 'getDealOfferDetailResponder');
//         yield put(getDealOfferDetail.failure(e));
//     } finally {

//     }
// }

function* requestInformationResponder({ payload }){
	// console.log(payload);
	yield put(ShowLoader(true));
	try{
		const { data } = yield call(wealth_company.requestWealthInformation, payload);
		yield put(requestInformation.success(data));
		yield delay(3000);
		yield put(resetRequestInformation());
	} catch(e){
		if(e.response.status == 401){
			yield put(Unauthorized(e));
		}
		yield put(requestInformation.failure(e));
	} finally{
		yield put(HideLoader(true));
	}
}

function* getLiveOffersResponder({ payload }){
    try{
        const { public_url } = payload;
        const path = public_url ? `/v2/wealth/public/offers/` : `/v2/wealth/offers/`;
        const query_string = compileQueryString(payload.qs);
        const { data } = yield call(api_call.get, path, query_string, payload);
        yield put(getLiveOffers.success(data));
    } catch(e){
        yield handleCatchSagaError(e, 'wealthgetLiveOffersResponder');
        yield put(getLiveOffers.failure(e));
    } finally{}
}

function* getLiveDemandResponder({ payload }){
    try{
        const { public_url } = payload;
        const path = public_url ? `/v2/wealth/public/demands/` : `/v2/wealth/demands/`;
        const query_string = compileQueryString(payload.qs);
        const { data } = yield call(api_call.get, path, query_string, payload);
        yield put(getLiveDemand.success(data));
    } catch(e){
        yield handleCatchSagaError(e, 'wealthgetLiveDemandResponder');
        yield put(getLiveDemand.failure(e));
    } finally{}
}

function* getLiveOffersWatchlistResponder({ payload }){
    try{
        //temporary api calling
        const query_string = compileQueryString(payload.qs);
        const { data } = yield call(wealth_company.getLiveOffersWatchlist, payload.root_path, payload.sub_path, payload.company_identifier, query_string, payload);
        yield put(getLiveOffersWatchlist.success(data));
    } catch(e){
        yield handleCatchSagaError(e, 'wealthgetLiveOffersWatchlistResponder')
        yield put(getLiveOffersWatchlist.failure(e));
    } finally {}
}

function* getLiveDemandWatchlistResponder({ payload }){
    try{
        //temporary api calling
        const query_string = compileQueryString(payload.qs);
        const { data } = yield call(wealth_company.getLiveDemandWatchlist, payload.root_path, payload.sub_path, payload.company_identifier, query_string, payload);
        yield put(getLiveDemandWatchlist.success(data));
    } catch(e){
        yield handleCatchSagaError(e, 'wealthgetLiveDemandWatchlistResponder');
        yield put(getLiveDemandWatchlist.failure(e));
    } finally {}
}

function* getDealHighlightResponder({ payload }){
    try{
        const { public_url } = payload;
        const path = public_url ? `/v2/wealth/public/deals/highlights/` : `/v2/wealth/deals/highlights/`;
        const query_string = compileQueryString(payload.qs);
        const { data } = yield call(api_call.get, path, query_string, payload);
        yield put(getDealHighlight.success(data));
    } catch(e){
        yield handleCatchSagaError(e, 'wealthgetDealHighlightResponder');
        yield put(getDealHighlight.failure(e));
    } finally{}
}

function* getDealTypeResponder({ payload }){
    try{
        const { public_url } = payload;
        const path = public_url ? `/v2/wealth/public/deals/type/`  : `/v2/wealth/deals/type/`;
        const query_sting = compileQueryString(payload.qs);
        const { data } = yield call(api_call.get, path, query_sting, payload);
        yield put(getDealType.success(data));
    } catch(e){
        yield handleCatchSagaError(e, 'wealthgetDealTypeResponder');
        yield put(getDealType.failure(e));
    } finally{}
}

function* getGPDetailResponder({ payload }){
    try{
        const { public_url } = payload;
        const path = public_url ? `/v2/wealth/public/sources/gp-highlights/` : `/v2/wealth/sources/gp-highlights/`;
        const query_string = compileQueryString(payload.qs);
        const { data } = yield call(
            api_call.get, 
            path, query_string, 
            payload);
        yield put(getGPDetail.success(data));
    } catch(e){
        yield handleCatchSagaError(e, 'wealthgetGPDetailResponder');
        yield put(getGPDetail.failure(e));
    }
}

function* shareDealResponder({ payload }){
    try{
        const { data } = yield call(wealth_company.shareDeal, payload);
        yield put(shareDeal.success(data));
    } catch(e){
        yield handleCatchSagaError(e, 'wealthshareDealResponder');
        yield put(shareDeal.failure(e));
    }
}

function* getRiskDisclosureResponder({ payload }){
    try{
        const { public_url } = payload;
        const path = public_url ? `/v2/wealth/public/web-contents/search/` : `/v2/wealth/web-contents/search/`;
        const query_sting = compileQueryString({...payload.qs, ...{ single: true, identifier: 'deal-share-agreement' }});
        const { data } = yield call(api_call.get, path, query_sting, payload);
        const response_payload = data.content_text ? JSON.parse(data.content_text) : { "content": [], subject: '' };
        yield put(getRiskDisclosure.success(response_payload));
    } catch(e){
        yield handleCatchSagaError(e, 'wealthgetRiskDisclosureResponder');
        yield put(getRiskDisclosure.failure(e));
    }
}

function* getSharedUserResponder({ payload }){
    try{
        const query_string = compileQueryString(payload.qs);
        const { data } = yield call(shared_page.getSharedUser, query_string, payload);
        yield put(getSharedUser.success(data));
    } catch(e){
        yield handleCatchSagaError(e, 'wealthgetSharedUserResponder');
        yield put(getSharedUser.failure(e))
    }
}

function* addCountResponder({ payload }){
    try{
        const { data } = yield call(access_right.addCount, payload);
        yield put(addCount.success(data));
    } catch(e){
        yield handleCatchSagaError(e, 'addCountResponder');
        yield put(addCount.failure(e));
    }
}

function* saga() {
    yield all([
        takeLatest(getFundingRound.request, getFundingRoundResponder),
        takeLatest(getLatestFundingRound.request, getLatestFundingRoundResponder),
        takeLatest(getAllCompanies.request, getAllCompaniesResponder),
        // takeLatest(getVerticals.request, getVerticalsResponder),
        takeLatest(getDealLinks.request, getDealLinksResponder),
        takeLatest(getHistoricalPrice.request, getHistoricalPriceResponder),
        takeLatest(getDealTimeline.request, getDealTimelineResponder),
        takeLatest(toggleWatchList.request, toggleWatchListResponder),
        // takeLatest(getDealOfferDetail.request, getDealOfferDetailResponder),
        takeLatest(requestInformation.request, requestInformationResponder),
        takeLatest(getLiveOffers.request, getLiveOffersResponder),
        takeLatest(getLiveDemand.request, getLiveDemandResponder),
        takeLatest(getLiveOffersWatchlist.request, getLiveOffersWatchlistResponder),
        takeLatest(getLiveDemandWatchlist.request, getLiveDemandWatchlistResponder),
        takeLatest(getDealHighlight.request, getDealHighlightResponder),
        takeLatest(getDealType.request, getDealTypeResponder),
        takeLatest(getGPDetail.request, getGPDetailResponder),
        takeLatest(shareDeal.request, shareDealResponder),
        takeLatest(getRiskDisclosure.request, getRiskDisclosureResponder),
        takeLatest(getSharedUser.request, getSharedUserResponder),
        takeLatest(addCount.request, addCountResponder),
    ])
}

export default saga;