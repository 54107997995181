import React from 'react';

import {
    TextField,
    CircularProgress,
    withStyles,
    Button,
} from '@material-ui/core';

import {
    Autocomplete
} from '@material-ui/lab';

import {
    ReScaleForDeviceScale
} from '../../../../utils/util';

import { 
    global150BodyFont, 
    globalBodyFont,
    global150LabelFont,
    globalLabelFont,
    globalFontFamily,
} from '../../../styles';

import {
    sendGAEvent
} from '../../../../utils/ga';

import {
    content_placeholders,
} from '../../../../config';

const FormStyle = theme => ({
    FormControlLabel: {
        fontSize: ReScaleForDeviceScale(1.5, global150BodyFont, globalBodyFont),
        marginBottom: 0,
        '& $label': {
            fontSize: ReScaleForDeviceScale(1.5, global150BodyFont, globalBodyFont),
        }
    },
    TextField: {
        fontSize: ReScaleForDeviceScale(1.5, global150BodyFont, globalBodyFont),
        '& $input': {
            fontSize: ReScaleForDeviceScale(1.5, global150BodyFont, globalBodyFont),
            // padding: '9px 0 8px !important',
            '&::placeholder': {
                fontSize: ReScaleForDeviceScale(1.5, global150BodyFont+'px !important', globalBodyFont+'px !important') 
            }
        },
        '&::placeholder': {
            fontSize: ReScaleForDeviceScale(1.5, global150BodyFont, globalBodyFont)
        },
        '& textarea': {
            fontSize: ReScaleForDeviceScale(1.5, global150BodyFont, globalBodyFont), 
            '&::placeholder': {
                fontSize: ReScaleForDeviceScale(1.5, global150BodyFont+'px !important', globalBodyFont+'px !important') 
            }
        },
        '& $label': {
            fontSize: ReScaleForDeviceScale(1.5, global150LabelFont, globalLabelFont)
        },
    },
    ToggleButton: {
        fontSize: ReScaleForDeviceScale(1.5, global150BodyFont, globalBodyFont),
    },
    FormLabel: {
        fontSize: ReScaleForDeviceScale(1.5, global150BodyFont, globalBodyFont)
    },
    Button: {
        fontSize: 8,
        fontFamily: globalFontFamily,
        position: 'absolute',
        top: -20,
        left: -5,
        zIndex: 100,
    },
});


class CompanySearch extends React.Component{
    state = {
        open: false,
        options: [],
        loading: false,
        selected_company: {
            company_id: undefined,
            company_name: ''
        },
        search_value: '',
        buttonYPosition: 0,
        buttonWidth: 0,
        buttonXPosition: 0,
    }

    constructor(props){
        super(props);
        this.timeout = 0;
    }

    componentDidMount(){
        this.updateYPosition()
    }

    componentDidUpdate(prevProps) {
        if (prevProps.searchCompanyResponse !== this.props.searchCompanyResponse) {
            this.updateYPosition()
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const {
            searchCompanyResponse,
            search_company_loading,
            reset_company_search,
        } = nextProps;

        if( search_company_loading && !prevState.loading ){
            return { loading: true }
        } else if ( !search_company_loading && prevState.loading ){
            return {
                options: searchCompanyResponse,
                loading: false
            }
        } else if ( reset_company_search ) {
            return {
                selected_company: {
                    company_id: undefined,
                    company_name: ''
                }
            }
        } else {
            return null;
        }
    }

    // shouldComponentUpdate(nextProps, nextState){
    //     if( 
    //         !nextProps.search_company_loading && 
    //         nextProps.no_match && 
    //         !this.props.no_match
    //     ){
    //         const { clearNoMatch } = this.props;
    //         clearNoMatch();
    //     }

    //     return true;
    // }

    updateYPosition() {
        const { buttonYPosition, buttonWidth, buttonXPosition } = this.state;
        const inputContainer = document.getElementById('autocomplete-input-container');
        let yPosition = inputContainer.offsetTop + inputContainer.clientHeight;
        let xPosition = inputContainer.offsetLeft;
        let width = inputContainer.clientWidth;
        if (buttonWidth !== width) {
            this.setState({ buttonWidth: width })
        }
        if(buttonXPosition !== xPosition){
            this.setState({buttonXPosition: xPosition})
        }
        const autocompletePopper = document.getElementsByClassName('MuiAutocomplete-popper');
        if (autocompletePopper && autocompletePopper.length > 0) {
            yPosition += autocompletePopper[0].clientHeight - 5
        }
        if (buttonYPosition !== yPosition) {
            this.setState({ buttonYPosition: yPosition })
        }
    }

    handleSelectCompany = (evt, value) => {
        if(evt.key == 'Enter'){
            const { options } = this.state;
            const { handleShowWatchlistFromSearchCompany, loginUserData: { activeAccessRight: { access_right } } } = this.props;
            if(options.length > 0){
                const matched =  options.find(element => element.company_name.toLowerCase() === evt.target.value.trim().toLowerCase())
                if(matched){
                    const sub_path = access_right.permission == content_placeholders.user_type.wealth_user ? 'wealth' : 'orderbook';
                    window.location.href = `/${sub_path}/company-detail/${matched.company_id}`;
                }else{
                    handleShowWatchlistFromSearchCompany(evt, evt.target.value.trim())
                }
            }else{
                handleShowWatchlistFromSearchCompany(evt, evt.target.value.trim())
            }
            return
        }
        if( value && value.company_id && value.company_name ){
            this.setState({ selected_company: value });
            const { handleSearchCompany } = this.props;
            sendGAEvent({
                type: 'wealth_search_company_select',
                data: {
                    company_id: value.company_id
                }
            });
            if( handleSearchCompany ){
                handleSearchCompany(evt, value.company_name);
            } else {
                const { loginUserData: { activeAccessRight: { access_right } } } = this.props;
                const sub_path = access_right.permission == content_placeholders.user_type.wealth_user ? 'wealth' : 'orderbook';
                window.location.href=`/${sub_path}/company-detail/${value.company_id}`;
            }
        } else if ( !value ){
            this.setState({ selected_company: { company_id: undefined, company_name: '' } });
            const { handleSearchCompany } = this.props;
            if( handleSearchCompany ){
                handleSearchCompany(evt, "");
            }
        }
    }

    handleSearchCompany = (evt) => {
        if( evt ){
            const { target: { name, value } } = evt;
            // const { getSearchCompany } = this.props;

            if( this.timeout ) clearTimeout( this.timeout );
            sendGAEvent({
                type: 'wealth_search_company_search',
                data: {
                    keyword: value
                }
            });
            const self = this;
            this.setState({ search_value: value }, () => {
                this.timeout = setTimeout(() => {
                    const { search_value } = self.state;
                    const { getSearchCompany } = self.props;
                    if( search_value && search_value.trim() ){
                        getSearchCompany({ qs: { search_string: search_value, sim: 0.5 } });
                    }
                }, 300);        
            });
        }
    }

    handleOpen( open, evt ) {
        this.setState({ open: open });
        if(!open){
            setTimeout(()=>{
                this.setState({ search_value: '' });
            }, 1000);
        }
    }

    render(){
        const {
            open,
            options,
            selected_company,
            search_value,
            buttonYPosition,
            buttonWidth,
            buttonXPosition,
        } = this.state;

        const { 
            search_company_loading, 
            classes,
            no_match,
            handleShowWatchlistFromSearchCompany,
        } = this.props;

        return (
            <div id="autocomplete-input-container" >
                <Autocomplete
                    id="company-name-input"
                    open={open}
                    onOpen={this.handleOpen.bind(this, true)}
                    onClose={this.handleOpen.bind(this, false)}
                    onInputChange={this.handleSearchCompany}
                    onChange={this.handleSelectCompany}
                    getOptionSelected={(option, value) => option.company_name === value.company_name}
                    getOptionLabel={(option) => option.company_name}
                    options={options}
                    filterOptions={(options) => options}
                    freeSolo={true}
                    clearOnBlur={true}
                    loading={search_company_loading}
                    value={selected_company}
                    placeholder="Company Search"
                    size="small"
                    renderInput={(params) => {  
                        return (
                            <TextField
                                {...params}
                                // label="Company Search"
                                placeholder="Company Search"
                                className={classes.TextField}
                                InputLabelProps={{
                                    shrink: true
                                }}
                                value={selected_company.company_name}
                                variant="outlined"
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <React.Fragment>
                                            {search_company_loading ? <CircularProgress color="inherit" size={20} /> : null}
                                            {params.InputProps.endAdornment}
                                        </React.Fragment>
                                    ),
                                }}
                                />
                        )
                    }}
                />
                {
                    (search_value) ?
                        <Button className={classes.Button}
                            style={{
                                fontSize: '12px', fontWeight: '900', lineHeight: '15px', background: 'white', boxShadow: '0 2px 3px #888',
                                borderTopRightRadius: 0, borderTopLeftRadius: 0, textTransform: 'none', paddingLeft: '15px', paddingRight: '15px',
                                position: 'absolute', zIndex: 9999, top: buttonYPosition, width: buttonWidth, left: buttonXPosition
                            }}
                            onClick={(evt) => handleShowWatchlistFromSearchCompany(evt, search_value)}
                        >
                            <strong style={{ color: '#303F9F', textAlign: 'left', width: '100%' }}>Cannot find what you are looking for?&nbsp;
                                <span style={{ textDecoration: 'underline', color: '#303F9F' }}>
                                    Let us know and we will source it for you.
                                </span>
                            </strong>
                        </Button> : ''
                }
            </div>
        )
    }
}

export default withStyles(FormStyle)(CompanySearch);