import React from 'react';

import {
    Tooltip,
    IconButton,
    makeStyles,
    List,
    ListItem,
    ListItemText,
} from '@material-ui/core';

import StarIcon from '@material-ui/icons/Star';
import StarBorderIcon from '@material-ui/icons/StarBorder';

import {
    ReScaleForDeviceScale,
} from '../../../utils/util';

import {
    global150BodyFont,
    globalBodyFont,
} from '../../styles';

const useStyles = makeStyles((theme) => ({
    ListItemText: {
        fontSize: ReScaleForDeviceScale(1.5, global150BodyFont, globalBodyFont),
    },
    ListItem: {
        padding: 0,
        margin: 0,
    },
    List: {
        padding: 0,
    },
    IconButton: {
        padding: 0
    }
}));

export default (props) => {
    const classes = useStyles();
    const watchlist_style = { color: 'rgba(252,163,16,0.8)' };
    const star_icon = props.active_watchlist ? 
                        <StarIcon style={watchlist_style} /> : 
                        <StarBorderIcon style={{ color: 'rgba(252,163,16,0.8)' }} />;
    const items = [props.active_watchlist ? 'Remove from your watchlist' : 'Add to your watchlist'];
    return (
        <Tooltip 
            title={
                <React.Fragment>
                    <List className={classes.List}>
                        {
                            items.map((item, index) => {
                                return (
                                    <ListItem key={index}>
                                        <ListItemText classes={{primary: classes.ListItemText}} primary={item} />
                                    </ListItem>
                                )
                            })
                        }
                    </List>
                </React.Fragment>
            }>
            <IconButton 
                className={classes.IconButton}
                // {...props} 
                aria-label="info">
                {star_icon}
            </IconButton>
        </Tooltip>
    )
}