import {
    ReScaleForDeviceScale,
} from '../../utils/util';

import {
    global150BodyFont,
    globalBodyFont,
    global150LabelFont,
    globalLabelFont,
    globalFontFamily,
} from './index';

const styles = theme => ({
    FormControlLabel: {
        fontSize: ReScaleForDeviceScale(1.5, global150BodyFont, globalBodyFont),
        fontFamily: globalFontFamily,
        marginBottom: 0,
        '& $label': {
            fontSize: ReScaleForDeviceScale(1.5, global150BodyFont, globalBodyFont),
        }
    },
    ToggleButtonGroup: {
        width: '100%'
    },
    ToggleButton: {
        fontSize: ReScaleForDeviceScale(1.5, global150BodyFont, globalBodyFont),
        fontFamily: globalFontFamily,
        width: '100%',
    },
    GPLedDeal: {
        display: 'inline-block',
        background: 'green',
        color: '#FFFFFF',
        fontWeight: 'bolder'
    },
    PrimaryDeal: {
        display: 'inline-block',
        background: 'orange',
        color: '#FFFFFF',
        fontWeight: 'bolder'
    },
    CompLedDeal: {
        display: 'inline-block',
        background: 'rgba(38,138,199,1)',
        color: '#FFFFFF',
        fontWeight: 'bolder'
    },
    DirectSecondaryDeal: {
        display: 'inline-block',
        background: 'rgba(38,63,127,1)',
        color: '#FFFFFF',
        fontWeight: 'bolder'
    }
});

export default styles;
