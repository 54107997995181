import React, { Component } from 'react';
import loaderImg from '../../assets/images/loader.gif';
import logo from '../../assets/images/logo.png';

export class TempLoader extends Component{
    state={}
    constructor(props){
        super(props);
    }

    render(){
        return (
            <div className="temp-loader-container">
                <img className="logo" src={logo} alt="Gateway Private Markets" />
                <img src={loaderImg} className="loader" />
            </div>
        )
    }

}