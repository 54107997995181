import { combineReducers } from "redux";

import {
    getSearchCompany,
    lockCompany,
} from './Action';

import { default as InitialState } from './InitialState';

export default combineReducers({
    reducer( state=InitialState, action ){
        switch( action.type ){
            case getSearchCompany.request.toString():
                return Object.assign({}, state, {
                    searchCompanyResponse: {
                        resultCount: 0,
                        resultList: [],
                    },
                    search_company_loading: true
                });
            case getSearchCompany.failure.toString():
                return Object.assign({}, state, {
                    searchCompanyResponse: {
                        resultCount: 0,
                        resultList: [],
                    },
                    search_company_loading: false
                });
            case getSearchCompany.success.toString():
                return Object.assign({}, state, {
                    searchCompanyResponse: action.payload,
                    search_company_loading: false
                });
            case lockCompany.toString():
                return Object.assign({}, state, {
                    lock_company: action.payload
                })
            default:
                return state;
        }
    }
})