import { combineReducers } from "redux";
import {
	getAllCompanies,
	// getVerticals,
	getFundingRound,
	getLatestFundingRound,
	getDealTimeline,
	getHistoricalPrice,
	toggleWatchList,
	clearWatchlistMessage,
	// getDealOfferDetail,
	requestInformation,
	resetRequestInformation,
	getLiveOffers,
	getLiveDemand,
	getLiveOffersWatchlist,
    getLiveDemandWatchlist,
	getDealHighlight,
	getDealType,
	getGPDetail,
	shareDeal,
	getRiskDisclosure,
	confirmRiskDisclosure,
	getSharedUser,
	addCount,
} from "./Action";
import {
    createWatchlist,
} from '../../common/watchlist/Action';
import { resetSnackBar } from "../../account/Action";

import { default as InitialState } from "./InitialState";


function shuffle(array) {
	let currentIndex = array.length,  randomIndex;
  
	// While there remain elements to shuffle...
	while (currentIndex != 0) {
  
	  // Pick a remaining element...
	  randomIndex = Math.floor(Math.random() * currentIndex);
	  currentIndex--;
  
	  // And swap it with the current element.
	  [array[currentIndex], array[randomIndex]] = [
		array[randomIndex], array[currentIndex]];
	}
  
	return array;
}


export default combineReducers({
	reducer(state = InitialState, action) {
		switch (action.type) {
			case getAllCompanies.request.toString():
			case getAllCompanies.failure.toString():
				return Object.assign({}, state, {
					AllCompaniesResponse: {},
				});
			case getAllCompanies.success.toString():
				return Object.assign({}, state, {
					AllCompaniesResponse: action.payload,
				});
			// case getVerticals.success.toString():
			// 	return Object.assign({}, state, {
			// 		verticals: action.payload,
			// 	});
			// case getVerticals.failure.toString():
			// 	return state;
			case getFundingRound.request.toString():
			case getFundingRound.failure.toString():
				return Object.assign({}, state, {
					fundingRounds: { count: 0, results: [] },
					finishedCollectFundingRounds: false,
				});
			case getFundingRound.success.toString():
				return Object.assign({}, state, {
					fundingRounds: action.payload,
					finishedCollectFundingRounds: true,
				});
			// case getDealOfferDetail.request.toString():
			// 	return Object.assign({}, state, {
			// 		dealOffer: {},
			// 	});
			// case getDealOfferDetail.failure.toString():
			// 	return state;
			// case getDealOfferDetail.success.toString():
			// 	return Object.assign({}, state, {
			// 		dealOffer: action.payload,
			// 	});
			case getLatestFundingRound.request.toString():
				return Object.assign({}, state, {
					latestFundingRound: {}
				});
			case getLatestFundingRound.success.toString():
				return Object.assign({}, state, {
					latestFundingRound: action.payload,
				});
			case getLatestFundingRound.failure.toString():
				return state;
			case getDealTimeline.request.toString():
				return Object.assign({}, state, { dealTimelines: [] });
			case getDealTimeline.success.toString():
				return Object.assign({}, state, {
					dealTimelines: action.payload,
				});
			case getDealTimeline.failure.toString():
				return Object.assign({}, state, { dealTimelines: [] });
			case getHistoricalPrice.request.toString():
			case getHistoricalPrice.failure.toString():
				return Object.assign({}, state, { historicalPrice: { 
					allow: false,
        			data: false,
					historical_pricing: [] } 
				});
			case getHistoricalPrice.success.toString():
				return Object.assign({}, state, {
					historicalPrice: action.payload,
				});
			case getLiveOffers.request.toString():
			case getLiveOffers.failure.toString():
				return Object.assign({}, state, {
					live_offers: { resultCount: 0, resultList: [] }
				});
			case getLiveOffers.success.toString():
				return Object.assign({}, state, {
					live_offers: action.payload
				});
			case getLiveDemand.request.toString():
			case getLiveDemand.failure.toString():
				return Object.assign({}, state, {
					live_demand: { resultCount: 0, resultList: [] }
				});
			case getLiveDemand.success.toString():
				return Object.assign({}, state, {
					live_demand: action.payload
				});
			case toggleWatchList.request.toString():
            case clearWatchlistMessage.toString():
                return Object.assign({}, state, {
                    toggle_watchlist: {
                        error: false,
                        message: ''
                    }
                });
			case toggleWatchList.success.toString():
			case createWatchlist.success.toString():
				const updateWatchlist = state.AllCompaniesResponse.id === action.payload.company_id
				return Object.assign({}, state, {
					// toggleWatchError: false,
					// toggleWatchListResponse: action.payload,
					// toggleWatchListMessage: action.payload.message
					toggle_watchlist: {
                        error: false,
                        message: action.payload.message
                    },
					AllCompaniesResponse: {...state.AllCompaniesResponse, 
						...{ watchlist: updateWatchlist ? 
							!state.AllCompaniesResponse.watchlist : 
							state.AllCompaniesResponse.watchlist } },
				});
			case toggleWatchList.failure.toString(): 
				return Object.assign({}, state, {
					// toggleWatchListError: true,
					// toggleWatchListMessage: "There is an error, please try again later.",
					toggle_watchlist: {
                        error: true,
                        message: "There is an error, please try again later."
                    }
				});
			case resetSnackBar.toString():
				return Object.assign({}, state, {
					referralError: false,
					referralResponseMessage: "",
					expressInterestError: false,
					expressInterestResponseMessage: "",
					toggleWatchListError: false,
					toggleWatchListMessage: "",
					requestInformationtError: false,
        			requestInformationtResponseMessage: "",
					toggle_watchlist: {
                        error: false,
                        message: ''
                    }
				});
			case resetRequestInformation.toString():
			case requestInformation.request.toString():
				return Object.assign({}, state, {
                    requestInformationtError: false,
                    requestInformationtResponseMessage: ''
                })
			case requestInformation.success.toString():
                return Object.assign({}, state, {
                    requestInformationtError: false,
                    requestInformationtResponseMessage: "Thank you for the request. Our team will reach out to discuss further."
                })
			case requestInformation.failure.toString():
                return Object.assign({}, state, {
                    requestInformationtError: true,
                    requestInformationtResponseMessage: "There is an error, please refresh this page and try again."
                })
			case getDealHighlight.request.toString():
				return Object.assign({}, state, {
					dealHighlight: {},
				});
			case getDealHighlight.failure.toString():
				return state;
			case getDealHighlight.success.toString():
				return Object.assign({}, state, {
					dealHighlight: action.payload,
				});
			case getDealType.request.toString():
				return Object.assign({}, state, {
					dealType: {},
				});
			case getDealType.failure.toString():
				return state;
			case getDealType.success.toString():
				return Object.assign({}, state, {
					dealType: action.payload,
				});
			case getGPDetail.request.toString():
			case getGPDetail.failure.toString():
				return Object.assign({}, state, {
					gp_detail: []
				});
			case getGPDetail.success.toString():
				// const gp_detail = action.payload && Array.isArray(action.payload) && action.payload.length ? action.payload[0] : {}
				return Object.assign({}, state, {
					gp_detail: action.payload
				});
			case shareDeal.request.toString():
			case shareDeal.failure.toString():
				return Object.assign({}, state, {
					shareDealResponse: {
						token: ''
					}
				});
			case shareDeal.success.toString():
				return Object.assign({}, state, {
					shareDealResponse: action.payload
				});
			case getLiveOffersWatchlist.request.toString():
            case getLiveOffersWatchlist.failure.toString():
                return Object.assign({}, state, {
                    live_offers_watchlist: 0
                });
            case getLiveOffersWatchlist.success.toString():
                const o_num = action.payload && 
                            action.payload.resultList && 
                            action.payload.resultList.length ? action.payload.resultList[0].deal_size : 0;
                return Object.assign({}, state, {
                    live_offers_watchlist: o_num
                });
            case getLiveDemandWatchlist.request.toString():
            case getLiveDemandWatchlist.failure.toString():
                return Object.assign({}, state, {
                    live_demand_watchlist: 0
                });
            case getLiveDemandWatchlist.success.toString():
                const d_num = action.payload && 
                            action.payload.resultList && 
                            action.payload.resultList.length ? action.payload.resultList[0].deal_size : 0;
                return Object.assign({}, state, {
                    live_demand_watchlist: d_num
                });
			case getRiskDisclosure.request.toString():
			case getRiskDisclosure.failure.toString():
				return Object.assign({}, state, {
					riskDisclosureConfirmed: false,
					riskDisclosureResponse: {
						content: []
					}
				});
			case getRiskDisclosure.success.toString():
				return Object.assign({}, state, {
					riskDisclosureResponse: action.payload
				});
			case confirmRiskDisclosure.toString():
				return Object.assign({}, state, {
					riskDisclosureConfirmed: true
				});
			case getSharedUser.request.toString():
			case getSharedUser.failure.toString():
				return Object.assign({}, state, {
					shared_user: {}
				});
			case getSharedUser.success.toString():
				return Object.assign({}, state, {
					shared_user: action.payload
				});
			case addCount.request.toString():
			case addCount.failure.toString():
			case addCount.success.toString():
				return Object.assign({}, state, {});
			default:
				return state;
		}
	},
});
