import { combineReducers } from "redux";

import {
    getDeal,
    getVerticalsList,
    getRegionList,
    getDealTimeline as getPipelineDealTimeline,
} from '../wealth/pipeline/Action';

import {
    getAllCompanies,
    // getVerticals,
    getFundingRound,
    getLatestFundingRound,
    getDealTimeline,
    getHistoricalPrice,
    // getDealOfferDetail,
    requestInformation,
    getLiveOffers,
    getLiveDemand,
    getDealHighlight,
    getDealType,
    getGPDetail,
    shareDeal,
} from '../wealth/company/Action';

import {
    getOrderbookView,
    getMoreOrderbook,
} from '../orderbook/pipeline/Action';

import {
    getCompanyDetail,
    getFundingRound as OrderBookGetFundingRound,
    getHistoricalSecondaryPricing,
    getLiveOffers as OrderBookGetLiveOffers,
    getLiveDemand as OrderBookGetLiveDemand,
} from '../orderbook/company/Action';

import {
    ShowUserTypeError,
} from '../login/Action';
import {
    GetToken,
} from '../../utils';

import { default as InitialState } from './InitialState';

function processUserTypeError(state, payload){
    
    const return_payload = { UserTypeError: { open: false, message: '' } }
    const { response } = payload;
    if( response ){
        const token_payload = GetToken();
        const { userType } = token_payload;
        const { status } = response;
        if( userType=='staff' && status==403 ){
            return_payload['UserTypeError']['open'] = true;
            return_payload['UserTypeError']['message'] = 'Your session has expired. To start a new session, return to the main dashboard and click the link again.';
        }
    }
    return Object.assign({}, state, return_payload);
}

export default combineReducers({
    reducer(state=InitialState, action){
        switch( action.type ){
            case getOrderbookView.failure.toString():
            case getMoreOrderbook.failure.toString():
            case getCompanyDetail.failure.toString():
            case OrderBookGetFundingRound.failure.toString():
            case getHistoricalSecondaryPricing.failure.toString():
            case OrderBookGetLiveOffers.failure.toString():
            case OrderBookGetLiveDemand.failure.toString():

            case getDeal.failure.toString():
            case getVerticalsList.failure.toString():
            case getRegionList.failure.toString():
            case getPipelineDealTimeline.failure.toString():
            case getAllCompanies.failure.toString():
            // case getVerticals.failure.toString():
            case getFundingRound.failure.toString():
            case getLatestFundingRound.failure.toString():
            case getDealTimeline.failure.toString():
            case getHistoricalPrice.failure.toString():
            // case getDealOfferDetail.failure.toString():
            case requestInformation.failure.toString():
            case getLiveOffers.failure.toString():
            case getLiveDemand.failure.toString():
            case getDealHighlight.failure.toString():
            case getDealType.failure.toString():
            case getGPDetail.failure.toString():
            case shareDeal.failure.toString():
                return processUserTypeError(state, action.payload );
            
            case ShowUserTypeError.toString():
                return showUserTypeError(state)
            default:
                return state;
        }
    }
})

export function showUserTypeError(state){
    const return_payload = { 
        UserTypeError: { 
            open: true, 
            message: 'Your session has expired. To start a new session, return to the main dashboard and click the link again.' 
        } }
    return Object.assign({}, state, return_payload);
}